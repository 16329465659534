import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { BoxoutEndpointService } from '../../services/common/boxout-endpoint.service';
import { CurrentThemeService } from '../../services/common/current-theme.service';
import { SmartyAddressValidationService } from '../../services/common/smarty-address-validation.service';
import { HeaderService } from '../../services/header.service';
import { OtherShipToClientOrPatientAddressesDrawerComponent } from '../other-ship-to-client-or-patient-addresses-drawer/other-ship-to-client-or-patient-addresses-drawer.component';
import { ReusableAddressFormComponent } from '../reusable-address-form/reusable-address-form.component';
import { ShipToCheckoutAddressComponent } from '../ship-to-checkout-address/ship-to-checkout-address.component';
import { ShipToClientOrPatientDrawerComponent } from '../ship-to-client-or-patient-drawer/ship-to-client-or-patient-drawer.component';

@Component({
  selector: 'app-add-ship-to-client-patient-address-drawer',
  templateUrl: './add-ship-to-client-patient-address-drawer.component.html'
})
export class AddShipToClientPatientAddressDrawerComponent implements OnInit, OnDestroy{
  
  @ViewChild(ReusableAddressFormComponent, { static: true })
  public addressFormComponent!: ReusableAddressFormComponent;

  smartySub:Subscription;

  smartyKeyValForAddressValidation:any;
  siteData:any;
  dataFromRecommendedDialogEditButton:any;
  public currentTheme: any;
  user: User;
  userId:any;
  siebelRowId:any;
  apiUrl:any;
  customerRole:any;
  userType:any;
  userSub: Subscription;
  addShipToAddressFormForClientPatient:any;
  themesForScroll: any = this.document.getElementsByClassName('cdk-global-overlay-wrapper');

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private smartyAddressValidationService: SmartyAddressValidationService,
    public dialogRef: MatDialogRef<AddShipToClientPatientAddressDrawerComponent>,
    private headerService:HeaderService,
    private theme: CurrentThemeService,
    public dialog: MatDialog,
    private customHttp: BoxoutEndpointService,
    @Inject(DOCUMENT) private document: Document,
    private windowRef: WindowRef,
    private store: Store<fromApp.AppState>,
    public dialogCheckoutRef: MatDialogRef<ShipToCheckoutAddressComponent>
    ) { }

  ngOnInit(): void {
    this.userSub = this.store.select('auth').subscribe(user => {
      this.user = user && user.user!;
      this.userId = this.user?.uid;
      this.siebelRowId = this.user?.orgUnit?.siebelRowId;
      this.customerRole = this.user?.customerRole;
      this.userType = this.user?.orgUnit?.unitType;
    });

     

    // this.addShipToAddressFormForClientPatient = this.formBuilder.group({
    //   attn: [(this.data?.value?.attn ? this.data?.value?.attn: ""),[Validators.maxLength(30)]],
    //   address: this.addressFormComponent.createAddressForm(this.data?.value?.address),
    //   deliverNote: [(this.data?.value?.deliverNote ? this.data?.value?.deliverNote: ""),[Validators.maxLength(60)]],
    //   defaultShippgTo:[(this.data?.value?.defaultShippgTo ? this.data?.value?.defaultShippgTo: false)],
    //   defaultBillingTo:[(this.data?.value?.defaultBillingTo ? this.data?.value?.defaultBillingTo: false)],
    //   addressType:[(this.data?.value?.addressType ? this.data?.value?.addressType: "")],
    //   addAddressId:[(this.data?.value?.addAddressId ? this.data?.value?.addAddressId: this.siebelRowId)],
    //   componentName:[(this.data?.value?.componentName ? this.data?.value?.componentName: "")],
    //   action: [(this.data?.value?.action ? this.data?.value?.action : "")]
    // });


    this.addShipToAddressFormForClientPatient = this.formBuilder.group({
      attn: [((this.data?.value?.attn) ? (this.data?.value?.attn) :
               (this.data?.value?.addShipToAddressFormForClientPatient?.attn) ?
               (this.data?.value?.addShipToAddressFormForClientPatient?.attn) : "" ),[Validators.maxLength(30)]],

      address: this.addressFormComponent.createAddressForm((this.data?.value?.address) ? (this.data?.value?.address) :
                       (this.data?.value?.addShipToAddressFormForClientPatient?.address) ?
                       (this.data?.value?.addShipToAddressFormForClientPatient?.address) : ""    ),

      deliverNote: [((this.data?.value?.deliverNote) ? (this.data?.value?.deliverNote): 
        (this.data?.value?.addShipToAddressFormForClientPatient?.deliverNote) ?
        (this.data?.value?.addShipToAddressFormForClientPatient?.deliverNote) : "" ),[Validators.maxLength(60)]],

      defaultShippgTo:[((this.data?.value?.defaultShippgTo) ? (this.data?.value?.defaultShippgTo):
        (this.data?.value?.addShipToAddressFormForClientPatient?.defaultShippgTo) ?
                       (this.data?.value?.addShipToAddressFormForClientPatient?.defaultShippgTo): false)],

      defaultBillingTo:[((this.data?.value?.defaultBillingTo) ? (this.data?.value?.defaultBillingTo): 
        (this.data?.value?.addShipToAddressFormForClientPatient?.defaultBillingTo) ?
        (this.data?.value?.addShipToAddressFormForClientPatient?.defaultBillingTo) : false)],

      addressType:[((this.data?.value?.addressType) ? (this.data?.value?.addressType):
        (this.data?.value?.addShipToAddressFormForClientPatient?.addressType) ?
        (this.data?.value?.addShipToAddressFormForClientPatient?.addressType) :   "")],

      addAddressId:[((this.data?.value?.addAddressId) ? (this.data?.value?.addAddressId) : 
        (this.data?.value?.addShipToAddressFormForClientPatient?.addAddressId) ?
        (this.data?.value?.addShipToAddressFormForClientPatient?.addAddressId) : this.siebelRowId)],

      componentName:[((this.data?.value?.componentName) ? (this.data?.value?.componentName):
        (this.data?.value?.addShipToAddressFormForClientPatient?.componentName) ?
        (this.data?.value?.addShipToAddressFormForClientPatient?.componentName) :  "")],

      action: [((this.data?.value?.action ) ? (this.data?.value?.action) :
        (this.data?.value?.addShipToAddressFormForClientPatient?.action) ?
        (this.data?.value?.addShipToAddressFormForClientPatient?.action) :   "")]
    });



    /** Taking themes from themeservice **/
    this.currentTheme = this.theme?.getCurrentTheme();

    //scroll theme for reusable shpping to address dialog
    this.themesForScroll[0].className += " custom-modal";


    //getting the key for smarty address API validation
    this.headerService.localPropertiesConfigData.subscribe((resp:any)=>{
      if(resp){
        this.siteData = resp;
        const smartyKeyVal: any = 'spartacus.config.smarty.key';
        this.smartyKeyValForAddressValidation = this.siteData[smartyKeyVal];
      }
      
    });

    //when user clicks Edit This Address button on recommened address dialog then 
    //below code populates Add Ship To form again with prefilled values

    this.smartyAddressValidationService.receivedRecommendedData$.subscribe((sentData:any)=>{
      
      this.dataFromRecommendedDialogEditButton=sentData;

      if(this.dataFromRecommendedDialogEditButton.editButtonClicked
        && this.dataFromRecommendedDialogEditButton.editAddressData.componentRef === "CreateShipToAddressAccountForClientPatient"){
          let editAddData=this.dataFromRecommendedDialogEditButton.editAddressData;
          editAddData.addressType=editAddData.addressType;
          this.addShipToAddressFormForClientPatient.patchValue(editAddData);
         
    };
    
    this.editAddress();
    
  });

  

//On click of USe this address or Add Ship To  Account success scenerio

this.smartyAddressValidationService.savedFormDataSource.subscribe((data:any)=>{

  if(data && data.componentRef === "CreateShipToAddressAccountForClientPatient" && 
  !(JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("finalAddShipToFormForClientPatientSubmitCalled"))))){
    this.finalFormSubmit(data);
  }
},(error:any)=>{
});

  }

  editAddress(){
    if(!(JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("CreateShipToAccountForClientPatientReopened"))))
    && this.dataFromRecommendedDialogEditButton.editAddressData.componentRef === "CreateShipToAddressAccountForClientPatient"){
    
      this.windowRef.sessionStorage?.setItem("CreateShipToAccountForClientPatientReopened","true");

      if((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("CreateShipToAccountForClientPatientReopened"))))){

        if(this.data?.value?.openedDrawerFrom === "openedAddShipToFromOtherAddress"){

          this.dialog.open(AddShipToClientPatientAddressDrawerComponent,
            {
              panelClass: [this.currentTheme, 'ship-to-client-drawer-for-all'],
              position: {
              right: '0px',
              top: '0px'
            },
              data:{
                value:{
                  action:"addingNewAddressForClientOrPatientShipToAccount",
                  openedDrawerFrom : "openedAddShipToFromOtherAddress",
                  itemData:this.data?.value?.itemData,
                  addAddressId:this.data?.value?.addAddressId,
                  addShipToAddressFormForClientPatient:this.addShipToAddressFormForClientPatient?.value
                }
              } 
            },
            );
        }else if(this.data?.value?.openedDrawerFrom === "openedAddShipToFromShipToClientPatient"){
          this.dialog.open(AddShipToClientPatientAddressDrawerComponent,
            {
              panelClass: [this.currentTheme, 'ship-to-client-drawer-for-all'],
              position: {
              right: '0px',
              top: '0px'
            },
              data:{
                value:{
                  action:"addingNewAddressForClientOrPatientShipToAccount",
                  openedDrawerFrom : "openedAddShipToFromShipToClientPatient",
                  itemData:this.data?.value?.itemData,
                  addAddressId:this.data?.value?.addAddressId,
                  addShipToAddressFormForClientPatient:this.addShipToAddressFormForClientPatient?.value,
                  freeFreight: this.data?.value?.freeFreight
                }
              } 
            },
            );
        }else if(this.data?.value?.openedDrawerFrom === "openedAddShipToFromCheckout"){
          this.dialog.open(AddShipToClientPatientAddressDrawerComponent,
            {
              panelClass: [this.currentTheme, 'ship-to-client-drawer-for-all'],
              position: {
              right: '0px',
              top: '0px'
            },
              data:{
                value:{
                  action:"addingNewAddressForClientOrPatientShipToAccount",
                  openedDrawerFrom : "openedAddShipToFromCheckout",
                  itemData:this.data?.value?.itemData,
                  addAddressId:this.data?.value?.addAddressId,
                  addShipToAddressFormForClientPatient:this.addShipToAddressFormForClientPatient?.value,
                  callShipTo: this.data.value.callShipTo,
                  b2bSiebelId:this.data.value.b2bSiebelId,
                  shipToSiebelId:this.data.value.shipToSiebelId
                }
              } 
            },
            );
        }
          
    }

          if(Object.keys(this.dialogRef).length > 0 ){
            this.dialogRef.close();
          } 
      
      }
  }

  

  //This method is used to create Add Ship To Address on click on ADD Ship To button
  addShipToaddressForClientPatient(){

    if(this.addShipToAddressFormForClientPatient.valid){
      let addressOne= this.addShipToAddressFormForClientPatient.get('address')?.value.addressOne;
      let optionalAddressTwo= this.addShipToAddressFormForClientPatient.get('address')?.value.optionalAddressTwo;
      let cityName= this.addShipToAddressFormForClientPatient.get('address')?.value.cityName;
      let stateIsocode= this.addShipToAddressFormForClientPatient.get('address')?.value.stateName;
      let stateText=this.smartyAddressValidationService.getStateText(stateIsocode);

      let zipCode= this.addShipToAddressFormForClientPatient.get('address')?.value.zipCode;
      let countryText='United States';

      //sending component reference so that when we subscribe subject releted to its specific component
      let componentReference="CreateShipToAddressAccountForClientPatient";

      //close the Add Ship To account form when form is valid and its going for smarty address 
      //validation which either opens recommended address dialog or submits the Add Ship To account form
      this.dialogRef.close();

      //This is used to validate address in Add Ship To account form and submit form or it can also open
      //recommened address dialog in case if there are any recommened addresses
      this.smartyAddressValidationService.addressValidation(addressOne,optionalAddressTwo,cityName,stateIsocode,stateText,zipCode,countryText,this.addShipToAddressFormForClientPatient,this.smartyKeyValForAddressValidation,componentReference);
    }
    else{
      //invalid form alert
      this.addShipToAddressFormForClientPatient.markAllAsTouched();
      const firstElementWithError = document.querySelector('.ng-invalid');

      if (firstElementWithError) {
        firstElementWithError.scrollIntoView({ behavior: 'smooth' });
      }
    }

  }


  //To submit the form and Add Ship To Account
  finalFormSubmit(formData:any){

    this.windowRef.sessionStorage?.setItem("finalAddShipToFormForClientPatientSubmitCalled","true");

    const requestBody=this.formRequestBody(formData);

    
    //BCGI2-796: client or patient ship to address changes
    if(this.data?.value?.openedDrawerFrom === "openedAddShipToFromCheckout"){
      
      if(this.data?.value?.callShipTo){
        this.apiUrl=`users/${this.userId}/clients/${formData.addAddressId}?fields=DEFAULT`;
      }else{
        this.apiUrl=`users/${this.userId}/orgUnits/${formData.addAddressId}/updateAddress?fields=DEFAULT`
      }
      
    }else{
      this.apiUrl=`users/${this.userId}/clients/${formData.addAddressId}?fields=DEFAULT`;
    }
   
    
     

   if((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("finalAddShipToFormForClientPatientSubmitCalled"))))){
    this.customHttp.post(this.apiUrl, requestBody).subscribe(
      (data:any) => {

        this.windowRef.sessionStorage?.removeItem("finalAddShipToFormForClientPatientSubmitCalled");
        //to close recommended address dialog
        if(this.smartyAddressValidationService.openedDialog){
          this.smartyAddressValidationService.openedDialog.close();
        }
        //to refresh the data  page
        //To show success alert message when address is created
        
        if(this.data?.value?.openedDrawerFrom === "openedAddShipToFromCheckout"){
          this.smartyAddressValidationService.saveAddedAddressForCheckoutShipTO(true);
        }else{
         this.smartyAddressValidationService.saveAddedAddressForClientAccountAlert(true);
        }
      },
      (error:any) => {

        this.windowRef.sessionStorage?.removeItem("finalAddShipToFormForClientPatientSubmitCalled");
        //setting form data null for avoiding multiple call 
    this.smartyAddressValidationService.saveFormData(null);

       //   to close recommended address dialog
        if(this.smartyAddressValidationService.openedDialog){
          this.smartyAddressValidationService.openedDialog.close();
        }
        //To show error alert message when address is not created
       if(this.data?.value?.openedDrawerFrom === "openedAddShipToFromCheckout"){
        this.smartyAddressValidationService.saveAddedAddressForCheckoutShipTO(false);
       }else{
        this.smartyAddressValidationService.saveAddedAddressForClientAccountAlert(false);
       }
       

      });
   }

  }


  // This method is used to form request body which will be sent in Add Ship To account POST Call
 formRequestBody(body:any): any{

    if(body){
    return {
      addressType: body?.addressType,
      attendant: body?.attn,
      country: {
                 isocode: body.address.countryName,
                 name: "United States"
               },
      defaultAddress: true,
      deliveryNote: body.deliverNote,
      district: body.address.cityName,
      line1: body.address.addressOne,
      line2: body.address.optionalAddressTwo,
      postalCode: body.address.zipCode,
      region: {
              countryIso: body.address.countryName,
              isocode: body.address.stateName,
              isocodeShort: body.address.stateName,
              name: this.smartyAddressValidationService.getStateText(body.address.stateName)
               },
      town: body.address.cityName,
      visibleInAddressBook: true,
      shippingAddress:true,
      isPrimaryShippingAddress: body.defaultShippgTo,
      isPrimaryBillingAddress: body.defaultBillingTo
      }
    }
    
  }



  
 //this method is used to go back either other address drawer or ShipToClientOrPatientDrawerComponent based on condition  
 backToShipToClientPatientDialogFromAddAddress(){

  this.dialogRef.afterClosed().subscribe((res)=>{
    if(res === 'backToShipToClientPatientDialogFromAddAddress'){

      if(this.data?.value?.openedDrawerFrom === "openedAddShipToFromOtherAddress"){

        this.dialog.open(OtherShipToClientOrPatientAddressesDrawerComponent, {
          position: {
            right: '0px',
            top: '0px'
          },
          panelClass: ['wishlist-drawer', this.currentTheme],
          data: {
            value:{
              itemData:this.data?.value?.itemData,
              addAddressId:this.data?.value?.addAddressId,
              showSuccessForCart:false,
              showFailureForCart:false
              }
          }
        });

      }else if(this.data?.value?.openedDrawerFrom === "openedAddShipToFromShipToClientPatient"){
        if(this.data){
          this.data={};
        }
        this.dialog.open(ShipToClientOrPatientDrawerComponent, {
          position: {
            right: '0px',
            top: '0px'
          },
          panelClass: ['wishlist-drawer', this.currentTheme],
          data: {
            value:{
              showSuccessForCart:false,
              showFailureForCart:false
            }
          }
        });
      }else if(this.data?.value?.openedDrawerFrom === "openedAddShipToFromCheckout"){
        const dialogCheckoutRef = this.dialog.open(ShipToCheckoutAddressComponent, {
          position: {
            right: '0px',
            top: '0px'
          },
          panelClass: ['wishlist-drawer', 'business-account-dialogs-style'],
          data: {
            value:{
              itemData: this.data.value.item,
              callShipTo: this.data.value.callShipTo,
              b2bSiebelId:this.data.value.b2bSiebelId,
              shipToSiebelId:this.data.value.shipToSiebelId
            }
          }
        });
        dialogCheckoutRef.afterClosed().subscribe(result => {
          this.dialogCheckoutRef.close(result);
        });
      }
     
    }
  }, (error)=>{
  })

}

  ngOnDestroy(): void {

    if((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("finalAddShipToFormForClientPatientSubmitCalled"))))){
      this.windowRef.sessionStorage?.removeItem("finalAddShipToFormForClientPatientSubmitCalled");
    }

    if((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("CreateShipToAccountForClientPatientReopened"))))){
      this.windowRef.sessionStorage?.removeItem("CreateShipToAccountForClientPatientReopened");
    }

    if(this.smartySub){
      this.smartySub.unsubscribe();
    }

    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }


    
  

}
