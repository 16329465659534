import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, makeStateKey, OnDestroy, OnInit, Optional, PLATFORM_ID, TransferState } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SiteContextConfig, WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { User } from 'src/app/core/models/user.model';
import { UtilService } from 'src/app/core/services/util.service';
import * as fromApp from 'src/app/core/store/app.reducer';
import { global } from 'src/app/shared/translations/en/global';
import { favIconKeys } from 'src/environments/environment';
import { CustomPageMetaService } from './core/services/custom-page-meta.service';
import { PageLoaderComponent } from './feature/pages/page-loader/page-loader.component';
import { PageLoaderService } from './feature/pages/page-loader/page-loader.service';
import { LoginDialogComponent } from './shared/components/login-dialog/login-dialog.component';
import { CurrentThemeService } from './shared/services/common/current-theme.service';
import { GTMService } from './shared/services/common/gtm.service';
import { SsrService } from './shared/services/common/ssr.service';
import { ScriptService } from './shared/services/script.service';
declare global {
  interface Window {
    grecaptcha: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public pageLoaderComponent = PageLoaderComponent;
  title = 'boxoutstore';
  hideHeaderFooterForRoutes: string[] = [
    '/sales-rep/login',
    '/sales-rep',
    '/sales-rep/my-account',
    '/login',
    '/reset-password'
  ];
  isSalesRep: boolean = false;
  error500 = global.global.resetPassword.errorAlertMsg;
  error: boolean = false;
  errorSub: Subscription;
  favIconKey:any;
  baseSiteName:any;
  baseSiteNameVal:any;
  user: User;
  userSub: Subscription;
  userId: any;
  storeKey: any;
  ssrStoreKey: any;
  theme: any = this.document.getElementsByTagName('app-root')[0].classList;
  themeClass: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private utils: UtilService,
    private config: SiteContextConfig,
    private scriptService: ScriptService,
    private pageLoaderService:PageLoaderService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private store: Store<fromApp.AppState>,
    private window: WindowRef,
    public gtmService: GTMService,
    private customPageMetaService: CustomPageMetaService,
    private SsrService: SsrService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject('forwardUrl') public forwardUrlConst: string,
    @Optional() @Inject('serverFlag') public ssrFlag: string,
    private readonly transferState: TransferState, 
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private currenttheme: CurrentThemeService) {
    this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
          this.SsrService.setSSRKey('false');
          this.error = false;
        }

        /**
         * Hide and show of loader on routing
         */
        switch (true) {
          case event instanceof NavigationStart: {
            break;
          }
  
          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            break;
          }
          default: {
            break;
          }
        }
    });
    this.baseSiteName = this.config?.context?.baseSite;
    if (this.baseSiteName) {
      this.baseSiteNameVal = this.baseSiteName[0];
      this.favIconKey = favIconKeys[this.baseSiteNameVal as keyof typeof favIconKeys];
      this.scriptService.loadFavIcons(this.favIconKey);
    }

    this.storeKey = makeStateKey<string>('forwardUrlKey');
    this.ssrStoreKey = makeStateKey<string>('serverFlagKey');

    this.window.localStorage?.removeItem('isLoggedIn');
    this.window.localStorage?.removeItem('loggedInFrom');
    this.window.localStorage?.removeItem('isLogoutClicked');

    this.themeClass = this.currenttheme?.getCurrentTheme();
  }

  ngOnInit(): void {
    this.document.body.classList.add(this.themeClass);
    if(isPlatformBrowser(this.platformId))//get message from transferState if browser side
    {
        this.forwardUrlConst = this.transferState.get(this.storeKey, 'defaultMessageValue');
        this.ssrFlag = this.transferState.get(this.ssrStoreKey,'false');
    }
    else //server side: get provided message and store in in transfer state
    {
        this.transferState.set(this.storeKey, this.forwardUrlConst);
        this.transferState.set(this.ssrStoreKey, this.ssrFlag);
    }

    this.SsrService.setForwardUrlConst(this.forwardUrlConst);
    this.SsrService.setSSRKey(this.ssrFlag);

    if(this.window.location.hostname?.includes('portal.myboxout')) {
      this.isSalesRep = true;
    } else {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        let isSalesRepRoute = false;
        for (let i = 0; i < this.hideHeaderFooterForRoutes.length; i++) {
          if (this.router.url.includes(this.hideHeaderFooterForRoutes[i])) {
            if (this.router.url.includes('/login/')) {
              let routeUrl =  this.router.url.replace('/login/', '');
              if(routeUrl.length > 0) {
                isSalesRepRoute = false;
                break;
              }
            }
            isSalesRepRoute = true;
            break;
          }
        }
        this.isSalesRep = isSalesRepRoute;
      });
    }

      this.errorSub = this.utils.error500.subscribe(data => {
        if (data) {
          this.error = true;
        }
      })

      //this method is used to set custom page title 
      this.setPageTitle();

      this.userSub = this.store.select('auth').subscribe((user) => {
        this.user = user && user.user!;
        this.userId = this.user?.uid;
      });
  }
  setPageTitle(){
    this.router.events.pipe(  
      filter(event => event instanceof NavigationEnd),  
    ).subscribe(() => {  
      const rt = this.getChild(this.activatedRoute); 
      rt.data.subscribe((data:any) => {  
        this.window?.sessionStorage?.setItem("title", (data?.title ? data?.title + ' | '  : '') + this.utilService.checkBaseSite());
        this.utilService.setMeta(
          {heading: (data?.title ? data?.title + ' | ' : '')  + this.utilService.checkBaseSite() ,
          title: (data?.title ? data?.title + ' | ' : '')  + this.utilService.checkBaseSite()}
        )
      });  
    }); 
  }

  getChild(activatedRoute: ActivatedRoute): any {  
    if (activatedRoute.firstChild) {  
      return this.getChild(activatedRoute.firstChild);  
    } else {  
      return activatedRoute;  
    }  
  
  } 

  removeBackdrop() {
    this.document
      .getElementById('custom-backdrop')
      ?.classList.remove('show-backdrop');
    this.document
      .getElementById('custom-backdrop')
      ?.classList.add('hide-backdrop');
  }

  /**
   * Function to Open the Reusable Login Dialog 
   * use $('#triggenLoginDialog').click();
   * 
   * <div data-loggedinurl="/about-us" hidden id="loginRedirect"></div>
   * 
   * onclick="openLogin()"
   * function openLogin() {
      $('#triggenLoginDialog').click();
      }
   */
  openLoginDialog() {
    const dialogRef = this.dialog.open(LoginDialogComponent,
      {
        panelClass: [this.theme, 'custom-dialog-size'],
        autoFocus: false,
        disableClose: true,
        data: {entryPoint: 'contentPage', redirect: this.document?.getElementById('loginRedirect')?.getAttribute('data-loggedInUrl')}
      });
  }

  /**
   * Function to Open the Reusable Forgot Pwd Dialog 
   * use $('#triggenForgotPwdDialog').click();
   */
  openFrgPwdDialog() {
    const dialogRef = this.dialog.open(LoginDialogComponent,
      {
        panelClass: [this.theme, 'custom-dialog-size'],
        autoFocus: false,
        disableClose: true,
        data: {componentName: 'frgtPwd', entryPoint: 'contentPage'}
      });
  }

  /**
   * Destroy all the subscription
   * 
   */
     ngOnDestroy() {
      if(this.userSub) {
        this.userSub.unsubscribe();
      }
    }
}
