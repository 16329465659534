import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SiteContextConfig, WindowRef } from '@spartacus/core';
import * as fromApp from 'src/app/core/store/app.reducer';
import { BoxoutEndpointService } from '../../services/common/boxout-endpoint.service';
import { CartService } from '../../services/common/cart.service';
import { GTMService } from '../../services/common/gtm.service';
import { InputValidationService } from '../../services/common/input-validation.service';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html'
})
export class CartItemComponent implements OnInit {

  @Input() cartItem: any;
  @Input() componentName:any;
  @Input() indexNum: any;
  @Input() user: any;
  @Output() public sendSuccess = new EventEmitter();
  @Output() public sendError = new EventEmitter();
  @Output() public sendErrorMsg = new EventEmitter();
  @Input() editOrderCartId?: string;
  @Input() workFlowActionCode?: any;
  imageUrl: any;
  siteConfig: any;
  productQty: any;
  showSuccess: boolean;
  showFailure: boolean;
  currentVariant: any;
  quantityInput: any = [];
  maxQuantity: boolean = false;
  showInput: any;
  multipleOrderError: boolean;
  multipleOrder: boolean = true;
  quantityError: boolean;
  makeUpdateCall: boolean = true;
  baseUrl: any;
  baseSiteName:any;
  baseSiteNameVal:any;

  constructor(private config: SiteContextConfig, private route:Router,private store: Store<fromApp.AppState>, private customHttp: BoxoutEndpointService, private cartService: CartService, private windowRef: WindowRef, public cdr: ChangeDetectorRef,
    public gtmService: GTMService,private inputValidationService:InputValidationService) {
    this.siteConfig = this.config;
    this.baseSiteName = this.config?.context?.baseSite;
    if(this.baseSiteName){
      this.baseSiteNameVal= this.baseSiteName[0];
    }
  }

  ngOnInit(): void {
    this.imageUrl = this.siteConfig?.backend?.occ?.baseUrl;
    /** when we need basesite, language and locale in the site URL */
    // this.baseUrl = `${this.config?.context?.baseSite}/${this.config?.context?.language}/${this.config?.context?.currency}`;

    /** when we dont need basesite, language and locale in the site URL - This will not work in local*/
    this.productQty = this.cartItem?.quantity;
    this.currentVariant = this.cartItem?.product?.baseOptions?.length ? this.cartItem.product.baseOptions[0]?.selected : null;
  }

  /**
 * This function will remove the item from cart and refresh the cart details and number of items in cart.
 */
  removeItemFromCart(item: any) {
    this.hideMsg();
    let cartId;
    let apiUrl;
    if (!this.editOrderCartId) {
      cartId = sessionStorage.getItem('cartId');
      apiUrl = `users/current/carts/${cartId}/entries/${item.entryNumber}`;
      return this.customHttp.delete(apiUrl).subscribe(
        (resp: any) => {
          this.sendSuccess.emit(true);
          this.showMsg();
          this.gtmService.setRemoveCartGTMData(item,this.productQty);
          let gaObj = item?.product?.gaItems;
          gaObj = { ...gaObj, discount: null, index: null , price: item?.basePrice?.value , quantity: this.productQty, item_list_id: 'cart', item_list_name: 'Cart'};
          this.gtmService.removeProductFromCart(gaObj);
          gaObj={};
          this.cartService.getCartPageData(this.user?.uid, false);
        }, (err: any) => {
          this.sendError.emit(true);
          this.showMsg();
        })
    } else {
      cartId = this.editOrderCartId;
      apiUrl = `remove?cartId=${cartId}&entryNumber=${item.entryNumber}&fields=DEFAULT&product=${item.product.code}&quantity=0`;
      return this.customHttp.post(apiUrl).subscribe(
        (resp: any) => {
          this.sendSuccess.emit(true);
          this.showMsg();
          this.gtmService.setRemoveCartGTMData(item,this.productQty);
          this.cartService.getEditOrderEnteries(this.user?.uid,this.workFlowActionCode);
        }, (err: any) => {
          this.sendError.emit(true);
          this.showMsg();
        })
    }
   
  }

  /** Update Individual Line Items in Cart */
  updateCart(item?: any, type?: any, prdQty?: any) {
    this.hideMsg();
    let baseMultiplier = this.currentVariant?.quantityMultiplier ? this.currentVariant.quantityMultiplier : 1;
    if (type === 'single') {
      //This is needed for integration
      let cartUpdateObj = {
        orderEntries: [
          {
            product:
            {
              code: item?.product?.baseOptions?.length ? item?.product?.baseOptions[0].selected?.code : item?.product?.baseProduct
            },
              quantity: parseInt(prdQty)
          }
        ]
      }

      if (((this.productQty % baseMultiplier == 0 && baseMultiplier != 1) ||
        (baseMultiplier == 1 && this.productQty > 0)) && this.productQty != 0 && this.makeUpdateCall) {
          let gaAddToCartObj = item?.product?.gaItems;
          gaAddToCartObj = { ...gaAddToCartObj, discount: null, index: null , price: item?.basePrice?.value , quantity: prdQty, item_list_id: 'cart', item_list_name: 'Cart'};
          this.gtmService.addProductToCart(gaAddToCartObj);
          this.gtmService.setCartGTMData(item,prdQty);
          this.updateCartApi(cartUpdateObj);
      }
    }
  }

  /** Update Cart API call */
  updateCartApi(cartUpdateObj: { orderEntries: { product: { code: string; }, quantity: any; }[]; }) {
    this.hideMsg();
    let cartId;
    let apiUrl;
    if(!this.editOrderCartId) {
      cartId = sessionStorage.getItem('cartId');
      apiUrl = `users/current/carts/${cartId}/entries/`;
    } else {
      cartId = this.editOrderCartId;
      apiUrl = `update?cartId=${cartId}&fields=DEFAULT`;
    }
    this.customHttp.put(apiUrl, cartUpdateObj).subscribe((data: any) => {
      if (data.success) {
        this.sendSuccess.emit(true);
        this.showMsg();
        if (!this.editOrderCartId) {
          this.cartService.getCartPageData(this.user?.uid, false);
        } else {
          this.cartService.getEditOrderEnteries(this.user?.uid,this.workFlowActionCode);
        }
      } else if(data.cartModifications[0]?.statusCode === 'maxOrderQuantityExceeded'){
        this.maxQuantity = true;
        this.cdr.detectChanges();
      }
    }, (err: any) => {
        this.sendError.emit(true);
        this.showMsg();
    })
  }

  /** Change of input in quantity box */
  inputQtyChanged() {
    this.maxQuantity = false;
    let baseMultiplier = this.currentVariant?.quantityMultiplier ? this.currentVariant?.quantityMultiplier : 1;

    //To check multiple order scenario
    if (baseMultiplier && baseMultiplier != 1) {
      this.multipleOrderError = false;
      this.multipleOrder = true;
      this.quantityError = false;
      this.makeUpdateCall = false;
    }
    //To check quantity Error
    if (this.productQty == 0 || !this.productQty) {
      this.multipleOrderError = false;
      this.multipleOrder = false;
      this.quantityError = true;
      this.makeUpdateCall = false;
    }
    //To check multiple Order Error 
    if (this.productQty % baseMultiplier != 0 && baseMultiplier != 1) {
      this.multipleOrderError = true;
      this.multipleOrder = false;
      this.quantityError = false;
      this.makeUpdateCall = false;
    }

    if (((this.productQty % baseMultiplier == 0 && baseMultiplier != 1) ||
      (baseMultiplier == 1 && this.productQty > 0)) && this.productQty != 0) {
      this.makeUpdateCall = true;
    }
  }

  /** This function will validate the user should enter only the digits in input field */
  keyPressNumbers(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    else {
      let val = event.target.value.length >= 4 ? false : true;
      return val;
    }
  }

  /** Show the Success and Error Message  */
  showMsg() {
    this.windowRef.nativeWindow?.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  /** Hide the Success and Error Message  */
  hideMsg() {
    this.sendSuccess.emit(false);
    this.sendError.emit(false);
  }
  
  onPaste(event: any) {
    this.inputValidationService.onPaste(event);
  }

  navigateToSEO(item: any) {
    // let url: string = this.windowRef.location.origin + path;
    // this.windowRef.location.href = url;
    let product = item?.product;
    let gaDataForViewItem = product?.gaItems;
    gaDataForViewItem = { ...gaDataForViewItem, discount: null, index: null , price: item?.basePrice?.value , quantity: null, item_list_id: 'cart_list', item_list_name: 'Cart'};
    this.gtmService.setSelectItemData(gaDataForViewItem);
    this.route.navigate([product?.url]);
  }
}
