<div>
    <div class="d-flex align-items-center">
        <div class="mr-auto t-title-l">{{"account.emailModal.heading" | cxTranslate}}</div>
        <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon" >close</mat-icon>

    </div>
    <div class="pt-3 pb-2">
        <div class="py-4 px-1">
            <div class="t-body-s">{{"account.emailModal.subHeading" | cxTranslate}}</div>
            <div class="t-title-m">{{email}}</div>
        </div>
    </div>
    <form [formGroup]="editEmailForm" class="pl-3" (ngSubmit)="onSaveChangesClick()">
        <div class="row">
            <div class="col pl-0">
                <div class="mb-0">
                    <mat-form-field  class="w-100" appearance="outline"  [ngClass]="{'mat-form-field-invalid ng-invalid': !validEmail}">
                        <mat-label>{{"account.emailModal.emailLabel" | cxTranslate}}</mat-label>
                        <input matInput class="" formControlName="email" id="email" maxlength = "50"  (input)="valueChangeEmail()">
                        <mat-error *ngIf="(editEmailForm.get('email')?.touched &&
                        editEmailForm.get('email')?.hasError('required') || emptyEmail) && (editEmailForm.get('email')?.hasError('required'))">{{"account.emailModal.emailRequiredError" | cxTranslate}}
                        </mat-error>
                        <mat-error *ngIf="(editEmailForm.get('email')?.touched &&
                            editEmailForm.get('email')?.hasError('pattern'))">{{"account.emailModal.emailInvalidError" | cxTranslate}}
                        </mat-error>
                    </mat-form-field>
                    <span class="mat-error password-dialog-password-validate"
                        *ngIf="!(editEmailForm.get('email')?.touched &&
                        editEmailForm.get('email')?.hasError('pattern')) && !(editEmailForm.get('email')?.touched &&
                        editEmailForm.get('email')?.hasError('required')) && !validEmail">
                        {{"account.emailModal.emailAlreadyExists" | cxTranslate}}
                    </span>

                    <mat-form-field  class="w-100 mt-3" appearance="outline">
                        <mat-label>{{"account.emailModal.reEnterEmailLabel" | cxTranslate}}</mat-label>
                        <input matInput class="" formControlName="reEnterEmail" id="reEnterEmail" maxlength = "50" onpaste="return false;">
                        <mat-error *ngIf="(editEmailForm.get('reEnterEmail')?.touched &&
                        editEmailForm.get('reEnterEmail')?.hasError('required') || emptyreEnterEmail) && (editEmailForm.get('reEnterEmail')?.hasError('required'))">{{"account.emailModal.reEnterEmailRequiredError" | cxTranslate}}
                        </mat-error>
                        <mat-error  *ngIf="(editEmailForm.get('reEnterEmail')?.touched &&
                        editEmailForm.get('reEnterEmail')?.hasError('pattern'))">{{"account.emailModal.emailInvalidError" | cxTranslate}}
                        </mat-error>
                    </mat-form-field>
                    <span class="mat-error password-dialog-password-validate"
                        *ngIf="
                        editEmailForm.get('reEnterEmail')?.dirty &&
                        editEmailForm.get('reEnterEmail')?.hasError('emailMismatch')"
                    >
                    {{"account.emailModal.reEnterMisMatchError" | cxTranslate}}
                    </span>

                    <div class="d-flex ">
                        <mat-icon class="mt-1" svgIcon="tooltip"></mat-icon>
                        <div>
                            <div class="t-subhead-normal">
                                <u>{{"account.emailModal.toolTip" | cxTranslate}}</u>
                            </div>
                            <div class="t-subhead-normal">
                                <u>{{"account.emailModal.toolTip2" | cxTranslate}}</u>
                            </div>
                        </div>
                    </div>

                    <mat-form-field  class="w-100 mt-3" appearance="outline" [ngClass]="{'mat-form-field-invalid ng-invalid': !validPassword}">
                        <mat-label>{{"account.emailModal.passwordLabel" | cxTranslate}}</mat-label>
                        <input matInput type="password" formControlName="password" id="password" onpaste="return false;" (input)="valueChange()" (keydown)="$event.keyCode!==32?$event:$event.preventDefault()">
                        <mat-error *ngIf="(editEmailForm.get('password')?.touched &&
                        editEmailForm.get('password')?.hasError('required') || emptyPassword ) && (editEmailForm.get('password')?.hasError('required'))">{{"account.emailModal.passwordRequired" | cxTranslate}}
                        </mat-error>
                    </mat-form-field>
                    <span class="mat-error password-dialog-password-validate"
                        *ngIf="!(editEmailForm.get('password')?.touched &&
                        editEmailForm.get('password')?.hasError('required')) && !validPassword">
                        {{"account.emailModal.validPassword" | cxTranslate}}
                    </span>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end my-3">
            <button mat-flat-button color="primary" type="submit" class="t-label-l">
                {{"account.emailModal.saveChanges" | cxTranslate}}
            </button>
      </div>
    </form>
</div>
