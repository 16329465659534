import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WindowRef } from '@spartacus/core';
import { Subject, Subscription } from 'rxjs';
import { LoginDialogComponent } from '../../components/login-dialog/login-dialog.component';
import { WishlistListComponent } from '../../components/wishlist-list/wishlist-list.component';
import { HeaderService } from '../header.service';
import { BoxoutEndpointService } from './boxout-endpoint.service';
import { CartService } from './cart.service';
import { GTMService } from './gtm.service';
import { QuickOrderService } from './quick-order.service';
@Injectable({
  providedIn: 'root'
})
export class ProductService implements OnDestroy {
  subject = new Subject<any>();
  cartId: any;
  cartIdSub: Subscription;
  addListToCartSub: Subscription;
  addToWishlistDialog: any;
  
  constructor(private customHttp: BoxoutEndpointService, private headerService: HeaderService,
    private windowRef: WindowRef,private cartService: CartService,private quickOrderService: QuickOrderService,
    public dialog: MatDialog,public gtmService: GTMService,) { }

    
  //This method is used to add product to cart and open minicart
  addProductToCart(payload: any, userId:any, displayUid:any,product?:any) {
    if (this.windowRef.sessionStorage?.getItem('cartId')) {
      this.cartId = this.windowRef.sessionStorage?.getItem('cartId');
    } else {
      this.cartIdSub = this.cartService.getCart(userId)
        ?.subscribe((resp: any) => {
          this.cartId = resp;
        });
    }

    if (this.cartId && payload.length) {
      //let apiURL = `/orgUsers/${displayUid}/carts/${this.cartId}/entries/`;
      let apiURL = `/users/${userId}/carts/${this.cartId}/addEntries/`;
      this.addListToCartSub = this.customHttp
        .post(apiURL, { orderEntries: payload })
        .subscribe((res: any) => {
          if (res) {
          this.quickOrderService.openMiniCart(userId, res);
          }  
        });
    }
  }

//This method is used increase number products of which need to be added to cart
 increment(index:any, products:any,quantityInput:any){
  let baseMultiplier = products[index]?.variantOptions[0]?.quantityMultiplier;
  if (quantityInput[index] === undefined) {
     quantityInput[index] = 0;
  } else {
    if (quantityInput[index] % baseMultiplier !== 0) {
      quantityInput[index] =
        quantityInput[index] -
        (quantityInput[index] % baseMultiplier);
    }
    quantityInput[index] += baseMultiplier;
  }
 }

 //This method is used decrease number products of which need to be added to cart
 decrement(index:any, products:any,quantityInput:any){
  let baseMultiplier = products[index]?.variantOptions[0]?.quantityMultiplier;
  if (
    quantityInput[index] > 0 &&
    quantityInput[index] > products[index]?.variantOptions[0]?.quantityMultiplier
  ) {
    if (quantityInput[index] % baseMultiplier !== 0) {
      quantityInput[index] =
        quantityInput[index] +
        (baseMultiplier - (quantityInput[index] % baseMultiplier));
    } else quantityInput[index] -= baseMultiplier;
  }
 }

 //This method is add product  to wishlist
 addProductToWishlist(productCode:string,themeClass:any, gaObj?:any){
  if (productCode) {
    this.addToWishlistDialog = this.dialog.open(WishlistListComponent, {
      position: {
        right: '0px',
        top: '0px'
      },
      panelClass: ['wishlist-side-drawer', themeClass],
      data: {
        isEdit: false,
        isCreator: true,
        productCode,
        header: 'account.wishLists.addToListHeader',
        gaItem: [gaObj]
      }
    });
  }
 }

  //This method is login into appplication to see product price
 onLoginClick(themeClass:any){
  this.dialog.open(LoginDialogComponent,
    {
      panelClass: [themeClass, 'custom-dialog-size'],
      autoFocus: false,
      disableClose: true
    });
 }
/**
     * This function will add layer for add to cart. 
     */
 setCartGTMData(familyID:any,variantID:any,qty:any) {
  let dataObj = {
    'event': 'add-to-cart',
    'familyID': familyID,
    'variantID':  variantID,
    'quantity': + qty
  }
  this.gtmService.writeToDataLayer(dataObj);
}
 ngOnDestroy() {

  if (this.addListToCartSub) {
    this.addListToCartSub.unsubscribe();
  }
  if (this.cartIdSub) {
    this.cartIdSub.unsubscribe();
  }
}
  }