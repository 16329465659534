import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WindowRef } from '@spartacus/core';
import { CartCreditModalComponent } from 'src/app/shared/components/cart-credit-modal/cart-credit-modal.component';
import { CartInformativeModalComponent } from 'src/app/shared/components/cart-informative-modal/cart-informative-modal.component';
import { MedicalInfoComponent } from 'src/app/shared/components/medical-info/medical-info.component';
import { CartService } from 'src/app/shared/services/common/cart.service';
import { GTMService } from 'src/app/shared/services/common/gtm.service';
import { global } from 'src/app/shared/translations/en/global';
import { PromotionSliderComponent } from '../promotions/promotion-slider/promotion-slider.component';
import { RegisterService } from '../register-account/register.service';

interface OrderPromotion {
  status?: 'Applied' | 'Available';
  description: string;
  promotion: {
    code: string;
    description: string;
    promotionType: string;
    promoType: string;
    discount: {
      formattedValue: string;
    };
  };
}
@Component({
  selector: 'app-cart-summary',
  templateUrl: './cart-summary.component.html',
})
export class CartSummaryComponent implements OnInit , OnDestroy {
  @Input() cartDetails: any;
  @Input() componentName: any;
  isLinePromoAvailable: boolean = false;
  baseUrl: string;
  shipToNotSelected=false;
  isCurbside:any = false;
  curbside = global.account.order.curbside;
  rewardsApplied: any;
  rewardsDiscount: any;
  dataSource: any;
  eligiblePromoCount: any;

  constructor(
    private cartService: CartService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private registerService:RegisterService,
    @Inject(DOCUMENT) private document: Document,
    private windowRef: WindowRef,
    private gtmService: GTMService
  ) {
    /** when we need basesite, language and locale in the site URL */
    //this.baseUrl = `${this.config?.context?.baseSite}/${this.config?.context?.language}/${this.config?.context?.currency}`;

    /** when we dont need basesite, language and locale in the site URL - this will not work in local*/
    this.baseUrl = ``;
  }


  ngOnInit(): void {
    // this.pageLoaderService.show();
      this.cartService.getCartData().subscribe((cart) => {
        // this.pageLoaderService.hide();
        this.cartDetails = cart;
        this.rewardsDiscount = this.cartDetails?.rewardsDiscount;
        this.rewardsApplied = this.cartDetails?.appliedRewards;
        this.isCurbside = this.cartDetails?.deliveryMode && this.cartDetails?.deliveryMode?.code === 'vendor-dropship-overweight' ? true : false;
        this.eligiblePromoCount = this.cartDetails?.freeFreightPromotion?.isCoachForwardPromo ? this.cartDetails.potentialOrderPromotions?.length-1  : this.cartDetails.potentialOrderPromotions?.length;
        // For getting whether any Line Promotion present or not
        if (this.cartDetails?.entries?.length) {
          for (let i = 0; i < this.cartDetails.entries.length; i++) {
            if (this.cartDetails.entries[i]?.bogoPromotionDetails) {
              this.isLinePromoAvailable = true;
              break;
            }
          }
        }
        this.cdr.detectChanges();
      },(error)=>{
       // this.pageLoaderService.hide();
      });

      this.registerService.shipToSelectedInCart$.subscribe((data)=>{
        if(data){
          this.registerService.showErrorMessageInCartPage(false);
          this.shipToNotSelected=false;
        }
        else{
          this.shipToNotSelected=true;
          this.registerService.showErrorMessageInCartPage(true);
        }
      });
  }

  /** Function to open the shipping and taxes modal */
  openTaxesShippingModal(value:any) {
    const dialogRef = this.dialog.open(CartInformativeModalComponent, {
      panelClass: 'cart-info-dialog',
      autoFocus: false,
      data: value,
    });
  }

  openCreditModal(value:any) {
    const dialogRef = this.dialog.open(CartCreditModalComponent, {
      panelClass: 'cart-credit-dialogue',
      autoFocus: false,
      data: {
        cartDetails: this.cartDetails,
        rewardsDiscount: this.rewardsDiscount,
        rewardsApplied: this.rewardsApplied,
      },
    });
  }

  // For opening promotion slider
  openPromotionSlider = () => {
    // Mapping promotions with status (Applied/Available)
    const appliedOrderPromotions =
      this.cartDetails?.appliedOrderPromotions?.map((e: OrderPromotion) => {
        return { ...e, status: 'Applied' };
      }) || [];
    const potentialOrderPromotions =
      this.cartDetails?.potentialOrderPromotions?.map((e: OrderPromotion) => {
        return { ...e, status: 'Available' };
      }) || [];

    // Check if there are no promotions to show
    if (!appliedOrderPromotions?.length && !potentialOrderPromotions?.length) {
      if (
        this.isLinePromoAvailable &&
        this.document?.getElementById('scroll-to-view-this-promotion')
      ) {
        this.document
          ?.getElementById('scroll-to-view-this-promotion')
          ?.scrollIntoView();
  
        this.gtmService?.callViewPromotion();
      }
    } else {
      // For opening promotion slider, including Free Freight Promotions
      let orderLevelPromo = [];
      let promoCount = 0;
      orderLevelPromo = [...appliedOrderPromotions, ...potentialOrderPromotions];
      promoCount = this.cartDetails?.freeFreightPromotion?.isCoachForwardPromo ? orderLevelPromo?.length-1  : orderLevelPromo?.length;
      this.dialog.open(PromotionSliderComponent, {
        panelClass: 'promotion-slider-container',
        autoFocus: false,
        data: {orderLevelPromo, eligiblePromoCount: promoCount}
      });
    }
  };

  medicalInformationCheck = () => {
    let medicalData = {
      value : {
        //upim: '',
        npiData: '',
        address: {}
      }
    }

    this.windowRef.sessionStorage?.setItem('medicalInformationFormRef' , 'cart')
    // if(this.componentName === 'reorder') {
    //   medicalInfoData = this.componentName;
    // } else {
    //   medicalInfoData = 'cart';
    // }
    const dialogRef = this.dialog.open(MedicalInfoComponent, {
      panelClass: ['medical-info-dialog'],
      autoFocus: false,
      data: medicalData
    });
  }

  ngOnDestroy = () => {
    if(this.windowRef.sessionStorage?.getItem("medicalInformationFormRef")) {
      this.windowRef.sessionStorage?.removeItem("medicalInformationFormRef")
    }
  }
}