import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { RecommendedAddressComponent } from 'src/app/shared/components/recommended-address/recommended-address.component';
import { CurrentThemeService } from './current-theme.service';

@Injectable({
  providedIn: 'root'
})
export class SmartyAddressValidationService {

  openedDialog:any;
  addressData:any;
  builtAddressData:any=[];
  result:any=[];
  addressSecondaryData:any;
  theme:any;

  

  statelistdata:any;


  public savedFormData = new Subject<any>();
  savedFormDataSource: Observable<any> = this.savedFormData.asObservable();  


  private recommendedDataSource= new Subject<any>();
  public receivedRecommendedData$=this.recommendedDataSource.asObservable();

  private saveBusinessAccountAlertData= new Subject<any>();
  public saveBusinessAccountAlertData$=this.saveBusinessAccountAlertData.asObservable();

  private saveClientAccountAlertData= new Subject<any>();
  public saveClientAccountAlertData$=this.saveClientAccountAlertData.asObservable();


  //796:When new ship to address created for cart
  private saveAddedAddressForClientAccountAlertData= new Subject<any>();
  public saveAddedAddressForClientAccountAlertData$=this.saveAddedAddressForClientAccountAlertData.asObservable();

  //when new address added for ship to checkout
  private saveAddedAddressForCheckoutShipTOData= new Subject<any>();
  public saveAddedAddressForCheckoutShipTOData$=this.saveAddedAddressForCheckoutShipTOData.asObservable();

  //when new address added for bill to checkout
  private saveAddedAddressForCheckoutBillTOData= new Subject<any>();
  public saveAddedAddressForCheckoutBillTOData$=this.saveAddedAddressForCheckoutBillTOData.asObservable();

  private saveAddressForBusinessAccountAlertData= new Subject<any>();
  public saveAddressForBusinessAccountAlertData$=this.saveAddressForBusinessAccountAlertData.asObservable();

  private saveAddressForAddedViewAddressAlertData= new Subject<any>();
  public saveAddressForAddedViewAddressAlertData$=this.saveAddressForAddedViewAddressAlertData.asObservable();
  //796:When ship to address is selected  for cart
  private addressSelectedForShipToCartData= new Subject<any>();
  public addressSelectedForShipToCartData$=this.addressSelectedForShipToCartData.asObservable();

  //declared for saving alert message fpor cart ship to business account
  private saveBusinessAccountAlertForCartData= new Subject<any>();
  public saveBusinessAccountAlertForCartData$=this.saveBusinessAccountAlertForCartData.asObservable();

  private saveAddedAddressForBusinessAccountAlertData= new Subject<any>();
  public saveAddedAddressForBusinessAccountAlertData$=this.saveAddedAddressForBusinessAccountAlertData.asObservable();

  //to set the widget id to undefine in register page as soon as address validation fails(recommended dailog opens)  
  public recommendDialogDataForRegister = new Subject<any>();
  recommendDialogDataForRegister$: Observable<any> = this.recommendDialogDataForRegister.asObservable(); 

  private medicalInfoSource= new Subject<any>();
  public medicalInfoData$=this.medicalInfoSource.asObservable();

  private editOrderSource= new Subject<any>();
  public editOrderData$=this.editOrderSource.asObservable();

  constructor(private http:HttpClient, public dialog: MatDialog,private themes: CurrentThemeService, 
    ) { }

    
    //to set the widget id to undefine in register page as soon as address validation fails(recommended dailog opens)  
 
   setDailogDataToRegister(){
  this.recommendDialogDataForRegister.next(true);
   } 

  sendRecommendedData(data:any){
    this.recommendedDataSource.next(data);
  }

  sendStateList(data:any){
    this.statelistdata=data;
  }

  saveFormData(formData:any){
    this.savedFormData.next(formData);
  }

  saveBusinessAccountAlert(val:any){
    this.saveBusinessAccountAlertData.next(val);
  }

  saveClientAccountAlert(val:any){
    this.saveClientAccountAlertData.next(val);
  }


  //reusable add shipto new
   //796:When ship to address is selected  for cart
  saveAddressSelectedForShipToCartData(val:any){
    this.addressSelectedForShipToCartData.next(val);
  }

  //796:When new ship to address created for cart
  saveAddedAddressForClientAccountAlert(val:any){
    this.saveAddedAddressForClientAccountAlertData.next(val);
  }

  //when new address added for ship to checkout
  saveAddedAddressForCheckoutShipTO(val:any){
    this.saveAddedAddressForCheckoutShipTOData.next(val);
  }

  //when new address added for bill to checkout
  saveAddedAddressForCheckoutBillTO(val:any){
    this.saveAddedAddressForCheckoutBillTOData.next(val);
  }
  
  saveAddressForBusinessAccountAlert(val:any){
    this.saveAddressForBusinessAccountAlertData.next(val);
  }

  saveAddressForAddedViewAddressAlert(val:any){
    this.saveAddressForAddedViewAddressAlertData.next(val);
  }
////
  //added for saving alert message fpor cart ship to business account
  saveBusinessAccountAlertForCart(val:any){
    this.saveBusinessAccountAlertForCartData.next(val);
}

  saveAddedAddressForBusinessAccountAlert(val:any){
    this.saveAddedAddressForBusinessAccountAlertData.next(val);
  }

  sendMedicalInfo(data:any){
    this.medicalInfoSource.next(data);
  }

  sendEditOrder(data:any){
    this.editOrderSource.next(data);
  }

  addressValidation(addressOne:any,optionalAddressTwo:any,cityName:any,stateIsocode:any,stateText:any,zipCode:any,countryText:any,passedForm:any,keyVal:any,componentRef:any){

    
    let addressValidUrl=`https://us-street.api.smartystreets.com/street-address?key=${encodeURIComponent(keyVal)}&street=${encodeURIComponent(addressOne)}&street2=${encodeURIComponent(optionalAddressTwo)}&city=${encodeURIComponent(cityName)}&state=${encodeURIComponent(stateText)}&zipcode=${encodeURIComponent(zipCode)}&match=enhanced&license=us-core-enterprise-cloud`;

    this.http.get(addressValidUrl).subscribe((data)=>{

      const copyData:any=data;
     
      if(data && copyData.length>0){

        if((copyData[0]?.analysis?.dpv_match_code == "Y") || (copyData[0]?.analysis?.dpv_match_code == "S" && copyData[0]?.analysis?.active == 'Y')){

          if((copyData[0]?.components?.zipcode == zipCode || (copyData[0]?.components?.zipcode+copyData[0]?.components?.plus4_code) == zipCode) 
               && (copyData[0]?.components?.city_name.toLowerCase() == cityName.toLowerCase() || copyData[0]?.components?.default_city_name.toLowerCase() == cityName.toLowerCase()) 
               && copyData[0]?.components?.state_abbreviation == stateIsocode.replace("USA-","")){

                //scenerio 1 happy path 
                //create data obj with existing form 
                //make post call

                //Adding addressType to form
                passedForm.value.addressType=copyData[0]?.metadata?.rdi;
                passedForm.value.componentRef=componentRef;
                this.saveFormData(passedForm.value);
                //return address object

               }else{
                 
                //scenerio three
                //lookup call with address 1 and 2
                //create data obj with existing form 
                //make post call in recommmended comp use this address button

                //Adding addressType to form
                passedForm.value.addressType=copyData[0].metadata.rdi;
                passedForm.value.componentRef=componentRef;
                 this.suggestedAddressFirst(addressOne,optionalAddressTwo,cityName,stateIsocode,stateText,zipCode,countryText,passedForm,keyVal);
               
               }

              }
              else{
                
                 //scenerio three
                 //lookup call with address 1 
                  //create data obj with existing form 
                  //make post call in recommmended comp use this address button

                //Adding addressType to form
                passedForm.value.addressType=copyData[0].metadata.rdi;
                passedForm.value.componentRef=componentRef;
                this.suggestedAddress(addressOne,optionalAddressTwo,cityName,stateIsocode,stateText,zipCode,countryText,passedForm,keyVal);

                
              }

      }else{

         //scenerio 2
        //open popup 
        //button1: Edit this Address -> open previous popup with prefilled data
        //button2: Use this Address -> post call with data obj created


        passedForm.value.componentRef=componentRef;
        ////This opens the Recommended Address dialog and send form data 
        this.openRecommendedAddressDialog(passedForm); 
      }

    },
    (error)=>{
    })
  }


  suggestedAddress(addressOne:any,optionalAddressTwo:any,cityName:any,stateIsocode:any,stateText:any,zipCode:any,countryText:any,passedForm:any,keyVal:any){

    
    
    let apiUrl=`https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${encodeURIComponent(keyVal)}&search=${encodeURIComponent(addressOne)}&license=us-autocomplete-pro-cloud`;

    this.http.get(apiUrl).subscribe((data)=>{
      this.result=[];
      this.builtAddressData=[];
      this.addressData=[];
      this.addressData=data;
      if(this.addressData && this.addressData.suggestions){

        this.addressData.suggestions.forEach((suggestion: any) => {

          if(suggestion.secondary && suggestion.entries>1){
            
            let urlSecondary=`https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${encodeURIComponent(keyVal)}&search=${encodeURIComponent(addressOne+" "+optionalAddressTwo)}&selected=${encodeURIComponent(suggestion.street_line+" "+suggestion.secondary+" ("+suggestion.entries+") "+suggestion.city+" "+suggestion.state+" "+suggestion.zipcode)}&license=us-autocomplete-pro-cloud`;

               this.http.get(urlSecondary).subscribe((resp)=>{

                this.addressSecondaryData=[];
                this.addressSecondaryData=resp;

                if(this.addressSecondaryData && this.addressSecondaryData.suggestions){
                 this.addressSecondaryData.suggestions.forEach((suggestions: any) => {

                 this.builtAddressData?.push(this.buildAddress(suggestions));
                 this.result.push(suggestions);

                  });
                }
                else{
                  this.addressSecondaryData=[];
                }
               }, (error)=>{
               })

          }else{
            this.builtAddressData?.push(this.buildAddress(suggestion));
            this.result.push(suggestion);

          }
          
        });

      }else{
        this.addressData=[];
        this.builtAddressData=[];
        this.result=[];
      }
       //This opens the Recommended Address dialog and send form data 

       this.openRecommendedAddressDialog(passedForm); 
      
    },(error)=>{
    })
  }

  suggestedAddressFirst(addressOne:any,optionalAddressTwo:any,cityName:any,stateIsocode:any,stateText:any,zipCode:any,countryText:any,passedForm:any,keyVal:any){

    
    
    let apiUrl=`https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${encodeURIComponent(keyVal)}&search=${encodeURIComponent(addressOne+" "+optionalAddressTwo)}&license=us-autocomplete-pro-cloud`;

    this.http.get(apiUrl).subscribe((data)=>{
      this.result=[];
      this.builtAddressData=[];
      this.addressData=[];
      this.addressData=data;
      if(this.addressData && this.addressData.suggestions){

        this.addressData.suggestions.forEach((suggestion: any) => {

          if(suggestion.secondary && suggestion.entries>1){
            
            let urlSecondary=`https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${encodeURIComponent(keyVal)}&search=${encodeURIComponent(addressOne+" "+optionalAddressTwo)}&selected=${encodeURIComponent(suggestion.street_line+" "+suggestion.secondary+" ("+suggestion.entries+") "+suggestion.city+" "+suggestion.state+" "+suggestion.zipcode)}&license=us-autocomplete-pro-cloud`;

               this.http.get(urlSecondary).subscribe((resp)=>{

                this.addressSecondaryData=[];
                this.addressSecondaryData=resp;

                if(this.addressSecondaryData && this.addressSecondaryData.suggestions){
                 this.addressSecondaryData.suggestions.forEach((suggestions: any) => {

                 this.builtAddressData?.push(this.buildAddress(suggestions));
                 this.result.push(suggestions);

                  });
                }
                else{
                  this.addressSecondaryData=[];
                }
               }, (error)=>{
               })

          }else{
            this.builtAddressData?.push(this.buildAddress(suggestion));
            this.result.push(suggestion);

          }
        
         
         
          
        });
        
      }else{
        this.addressData=[];
        this.builtAddressData=[];
        this.result=[];
      }
        //This opens the Recommended Address dialog and send form data 

        this.openRecommendedAddressDialog(passedForm); 
      
    },(error)=>{
    })
  }

  
  //This method is used to build addresses in string format and finally sent to Recommended address dialog
  buildAddress(suggestion:any){
    let whiteSpace = "";
    if (suggestion.secondary) {
      if (suggestion.entries > 1) {
        suggestion.secondary += " (" + suggestion.entries + " entries)";
      }
      whiteSpace = " ";
    }
    return suggestion.street_line + whiteSpace + suggestion.secondary + " " + suggestion.city + ", " + suggestion.state + " " + suggestion.zipcode;
  }




  openRecommendedAddressDialog(passedForm:any){
    this.setDailogDataToRegister();
    this.theme=this.themes.getCurrentTheme();

    //BCGI2-796: client or patient ship to address changes
    if(passedForm?.value?.action === 'addingNewClientOrPatientShipToAccount' || 
       passedForm?.value?.action === 'addingNewAddressForClientOrPatientShipToAccount' || 
       passedForm?.value?.action === 'addingNewAddressForBusinessAccount' ||
       passedForm?.value?.componentName === 'cartShipToBusinessComponent'){
      this.openedDialog=this.dialog.open(RecommendedAddressComponent,
        {
          panelClass: [this.theme, 'ship-to-client-drawer-for-all'],
          width:'368px',
          height:'100%',
          position: {
            right: '0px',
            top: '0px',
          },
          data:  {
             submittedForm: passedForm.value,
             submitedAddress: passedForm.get('address')?.value,
             recommendedAddress:this.builtAddressData,
             recommendedAddressList: this.result,
          }
        });
    }else{
      this.openedDialog=this.dialog.open(RecommendedAddressComponent,
        {
          panelClass: [this.theme, 'business-account-dialogs-style'],
          autoFocus: false,
          disableClose: true,
          data:  {
             submittedForm: passedForm.value,
             submitedAddress: passedForm.get('address')?.value,
             recommendedAddress:this.builtAddressData,
             recommendedAddressList: this.result,
          }
        });
    }
    
     
  }


// to get the text of state code
getStateText(stateCode:any):any{
  let stateTextval="";
    this.statelistdata.forEach((ele:any)=>{
      if(ele.isocode === stateCode){
        stateTextval= ele.name;
      }
    });
    return stateTextval;
}

//To get the formatted smarty address 
returnFormattedAddress(address:any){
  let shownAddress="";
    if(address?.line1){
      shownAddress=shownAddress +(address?.line1 + ", ");
    }
    if(address?.line2){
      shownAddress=shownAddress +(address?.line2 + ", ");
    }
    if(address?.district){
      shownAddress=shownAddress +(address?.district + ", ");
    }
    if(address?.region && address?.region?.isocode){
      shownAddress=shownAddress + ((address?.region?.isocode).replace("USA-","")+ " ");
    }
    if(address?.postalCode){
      shownAddress=shownAddress +(address?.postalCode + " ");
    }
    if(address?.country && address?.country?.isocode){
      shownAddress=shownAddress +(address?.country?.isocode);
    }
    return shownAddress;
}

}
