import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InputValidationService {


  constructor() { }

  onPaste(event: any) {
    var regex = new RegExp("^(0|[1-9][0-9]*)$");
    let clipboardData = event.clipboardData || event.originalEvent.clipboardData;
    let pastedData = clipboardData.getData('text/plain');
    if (!regex.test(pastedData) || (pastedData?.length + event?.target?.value.length) > 4) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  onPasteNpiData(event: any) {
    var regex = new RegExp("^(0|[1-9][0-9]*)$");
    let clipboardData = event.clipboardData || event.originalEvent.clipboardData;
    let pastedData = clipboardData.getData('text/plain');
    if (!regex.test(pastedData) || (pastedData?.length + event?.target?.value.length) > 10) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  checkSpecChar(event: any) {
    let clipboardData = event?.clipboardData || event?.originalEvent?.clipboardData;
    let pastedData = clipboardData?.getData('text/plain');
    if ((event.key === '<' || event.key === '>' || event.key === '&' || event.key === '%')
      || (pastedData?.includes('<') || pastedData?.includes('>') || pastedData?.includes('&') || pastedData?.includes('%'))) {
      event.preventDefault();
      return false;
    }
    else {
      return true;
    }
  }

  checkRegisterSpecChar(event: any) {
    let clipboardData = event?.clipboardData || event?.originalEvent?.clipboardData;
    let pastedData = clipboardData?.getData('text/plain');
    if ((event.key === '&' || event.key === '%')
      || (pastedData?.includes('&') || pastedData?.includes('%'))) {
      event.preventDefault();
      return false;
    }
    else {
      return true;
    }
  }
}
