import { MediaMatcher } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { User } from 'src/app/core/models/user.model';
import { UtilService } from 'src/app/core/services/util.service';
import * as fromApp from 'src/app/core/store/app.reducer';
import { ReusableSwitchAcctComponent } from 'src/app/shared/components/reusable-switch-acct/reusable-switch-acct.component';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { GTMService } from 'src/app/shared/services/common/gtm.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { PageLoaderService } from '../page-loader/page-loader.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html'
})
export class MyAccountComponent implements OnInit,OnDestroy{
  toggleStatus:any ={data:false};
  orderList = [
    { title: 'Order History', img_url: '../../../assets/my_account_image/orderhistory.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: 'orderHistory', isShow: false },
    { title: 'Manufacturer Rebates', img_url: '../../../assets/my_account_image/rewards.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: 'rewards-credits', isShow: false },
    { title: 'Order Approvals', img_url: '../../../assets/my_account_image/approval.svg', role: [], accountTypes: ['PARENT','DIRECT','DROPSHIP'], userTypes: ['Admin', 'Approver'], redirectUrl: 'approvalOrders', isShow: false },
    { title: 'Backorders', img_url: '../../../assets/my_account_image/backorder.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: 'backorderSearch', isShow: false },
    { title: 'Proof of Delivery (POD)', img_url: '../../../assets/my_account_image/pod.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: 'bulkPODExport', isShow: false },
    // { title: 'Returns', img_url: '../../../assets/my_account_image/return.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: '', isShow: false } <!-- Needed for future release -->
  ]
  accountList:any = [
    { title: 'My Business Accounts', img_url: '../../../assets/my_account_image/account.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: 'businessAccounts', isShow: false },
    { title: 'My Clients & Addresses', img_url: '../../../assets/my_account_image/address.svg', role: [], accountTypes: ['PARENT'], userTypes: ['Admin', 'Approver', 'Purchaser'], redirectUrl: 'clients-addresses', isShow: false },
  ]
  toolList = [
    // { title: 'Order Builder', img_url: '../../../assets/my_account_image/order_builder.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: '', isShow: false }, <!-- Needed for future release -->
    { title: 'Quick Order', img_url: '../../../assets/my_account_image/rapid.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: 'quickOrder', isShow: false },
    { title: 'Rapid Order', img_url: '../../../assets/my_account_image/rapid.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: 'rapidOrder', isShow: false },
    { title: 'Favorites', img_url: '../../../assets/my_account_image/favorites.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: 'favoriteLists', isShow: false },
    { title: 'Frequently Purchased', img_url: '../../../assets/my_account_image/frequently_purchased.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: 'frequentlyPurchased', isShow: false },
    { title: 'Returns', img_url: '../../../assets/my_account_image/return.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: 'returns', isShow: false },
  ]
  reportList = [
    { title: 'Report: Item Purchase Activity', img_url: '../../../assets/my_account_image/order_report.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: 'itemPurchaseActivity', isShow: false },
    { title: 'Report: Backorders', img_url: '../../../assets/my_account_image/order_report.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: 'backorderSearch', isShow: false },
    { title: 'Report: Advanced eBill Details', img_url: '../../../assets/my_account_image/order_report.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: 'advanced-ebill', isShow: false },
    { title: 'Proof of Delivery Bulk Export', img_url: '../../../assets/my_account_image/proof_of_delivery.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: 'bulkPODExport', isShow: false },
    { title: 'Downloads: Reports & POD', img_url: '../../../assets/my_account_image/downloads.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: 'download-reports', isShow: false },
  ]
  paymentList = [
    { title: 'Payment Types', img_url: '../../../assets/my_account_image/payment_type.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: 'payment-details', isShow: false },
    { title: 'Online Bill Pay', img_url: '../../../assets/my_account_image/online_pay.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: 'onlineBillPay', isShow: false },
  ]
  settingList = [
    { title: 'Manage Account', img_url: '../../../assets/my_account_image/manage.svg', role: ['B2B Admin Group'], accountTypes: ['PARENT','DIRECT','DROPSHIP'], userTypes: ['Admin'], redirectUrl: 'manageAccount', isShow: false },
    { title: 'User ID & Preferences ', img_url: '../../../assets/my_account_image/User.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: 'userIdPreference', isShow: false },
    { title: 'Sign-In & Security', img_url: '../../../assets/my_account_image/security.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: 'profileSecurity', isShow: false },
    { title: 'Users Admin', img_url: '../../../assets/my_account_image/User.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: 'userAdmin', isShow: false },
    // { title: 'Support Tickets', img_url: '../../../assets/my_account_image/support.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: '', isShow: false }, <!-- Needed for future release -->
    // { title: 'Ratings & Reviews', img_url: '../../../assets/my_account_image/review.svg', role: [], accountTypes: [], userTypes: [], redirectUrl: '', isShow: false }, <!-- Needed for future release -->
  ]
  leftPanel = [
    {title:'Orders',subList:this.orderList},
    {title:'Account & Addresses',subList:this.accountList},
    {title:'Order Tools',subList:this.toolList},
    {title:'Report & Analytics',subList:this.reportList},
    {title:'Payments & Invoices',subList:this.paymentList},
    {title:'Settings',subList:this.settingList}
    ]
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  userId: string;
  displayUid: string;
  orgCustomerId: any;
  accountInfo:any;
  accountprofileinfo: any;
  user: User;
  userSub: Subscription;
  accountDetails: any;
  currentUrl: any;
  isAdmin:any;
  accountType: any;
  userType: any;
  currentB2bUnit: any;
  showSwitchBtn: boolean = false;
  showBackorderReport: boolean = false;
  showPurchaseActivityReport: boolean = false;
  showRewardsDetails: boolean = false;
  showSuperBillReport: boolean = false;
  showPODReport: boolean = false;
  currentSiebelRowId:any;
  baseSiteName: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private headerService : HeaderService,
    private customHttp: BoxoutEndpointService,
    private router: Router,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    private windowRef:WindowRef,
    private store: Store<fromApp.AppState>,
    private pageLoaderService:PageLoaderService,
    private utilService: UtilService,
    public gtmService: GTMService
    ) {
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event:any) => {
        this.currentUrl = event?.url;
      });
      this.mobileQuery = media.matchMedia('(max-width: 1200px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    if(this.userSub) {
      this.userSub.unsubscribe();
    }
  }


  ngOnInit(): void {
    this.headerService.sideNavToggle.subscribe(response => {
      this.toggleStatus = response;
      this.changeDetectorRef.detectChanges();
    });

    this.userSub = this.store.select('auth').subscribe(user => {
      this.user = user && user.user!;
      this.currentSiebelRowId = this.user?.orgUnit?.siebelRowId;
      this.userId = this.user?.uid!;
      this.displayUid = this.user?.displayUid!;
      this.currentB2bUnit = this.user?.orgUnit?.uid;
      if (this.userId) {
        this.getDetails(this.userId).subscribe(resp => {
          if (resp) {
            this.headerService.saveAccountMainData(resp);
          }
        },(error)=>{
        }
        );
        this.accountprofileinfo = this.user;
      }
      if(this.user){
        this.accountType = this.user?.orgUnit?.unitType;
        this.userType = this.user?.customerRole || 'Approver';
        if(this.user?.roles && this.user?.roles.length){
          this.isAdmin = this.user.roles.includes('B2B Admin Group') || (this.user?.customerRole)?.toUpperCase() === "ADMIN";
          this.headerService.saveIsAdminFlag(this.isAdmin);
        }
        this.showBackorderReport = this.user?.orgUnit?.showBackorderReport;
        this.showPurchaseActivityReport = this.user?.orgUnit?.showPurchaseActivityReport;
        this.showRewardsDetails = this.user?.ricEnabled && this.user?.orgUnit?.eligibleForRIC ? true : false ;
        this.showSuperBillReport = this.user?.orgUnit?.showSuperBillReport;
        this.showPODReport = this.user?.orgUnit?.showPODReport;
        this.setFlag();
        this.getFilteredList();
      }
      this.changeDetectorRef.detectChanges();
    });
    //To get the user Id info
    // let userID;
    // this.headerService.isLoggedIn.subscribe(response => {
    //   this.userData = response;
    // });
    // this.userData?.subscribe((data: any) => {
      // userID = data?.uid;
      // this.userId = data?.displayUid;
      // this.currentB2bUnit = data?.orgUnit?.uid;
      // if (data?.uid !== undefined) {
      //   this.getDetails(data?.uid).subscribe(resp => {
      //     if (resp) {
      //       this.headerService.saveAccountMainData(resp);
      //     }
      //   }
      //   );
      //   this.accountprofileinfo = data;
      // }
      // if(data){
      //   this.accountType = data?.orgUnit?.unitType;
      //   this.userType = data.customerRole || 'Approver';
      //   if(data?.roles && data?.roles.length){
      //     this.isAdmin = data?.roles.includes('B2B Admin Group');
      //     this.headerService.saveIsAdminFlag(this.isAdmin);
      //   }
      //   this.setFlag();
      //   this.getFilteredList();
      // }
      // this.changeDetectorRef.detectChanges();
    // });

    if(this.windowRef.localStorage?.getItem('multipleB2bUnitFlag')) {
      this.showSwitchBtn = this.windowRef.localStorage.getItem('multipleB2bUnitFlag') === 'true' ? true : false;
      this.changeDetectorRef.detectChanges();
    }

  }

//This method is used to navigate to manage business account 
  manageBusinessAccount(){
    this.router.navigate(['/my-account/manageAccount'],{queryParams:{id:this.currentSiebelRowId}});
  }

  // Making Api call to get user data
  getDetails(userId: any){
    let apiURL = `users/${userId}/orgCustomers/${userId}`;
    return this.customHttp.get(apiURL);
  }

  toggleSideNav(value:any):void{
    this.document.body.style.overflowY = 'auto';
    this.toggleStatus = value;
    this.headerService.changeSideNavToggle(this.toggleStatus);
  }

  isShow(list:any) : boolean{

    let role = 'B2B Admin Group';

    if(list.role.length && this.isAdmin) {
      let val = list.role.includes(role);
      return val;
    } else if(list.role.length && !this.isAdmin) {
      return false;
    } else {
      return true;
    }
  }
  setFlag() {
    this.leftPanel.forEach((element, index) => {
      element.subList.map((res: any, ind: any) => {
        if (res.title === 'My Clients & Addresses' || res.title === 'Manage Account' || res.title === 'Order Approvals') {
          if (res.accountTypes?.includes(this.accountType) && res.userTypes?.includes(this.userType))
            this.leftPanel[index].subList[ind].isShow = true;
        }
        else if(res.title === 'Report: Item Purchase Activity') {
          if(this.showPurchaseActivityReport) {
            this.leftPanel[index].subList[ind].isShow = true;
          }
        }
        else if(res.title === 'Report: Backorders' || res.title === 'Backorders') {
          if(this.showBackorderReport) {
            this.leftPanel[index].subList[ind].isShow = true;
          }
        }
        else if(res.title === 'Report: Advanced eBill Details') {
          if(this.showSuperBillReport) {
            this.leftPanel[index].subList[ind].isShow = true;
          }
        }
        else if(res.title === 'Proof of Delivery Bulk Export' || res.title === 'Proof of Delivery (POD)') {
          if(this.showPODReport) {
            this.leftPanel[index].subList[ind].isShow = true;
          }
        }
        else if(res.title === 'Downloads: Reports & POD') {
          if(this.showPODReport || this.showBackorderReport || this.showSuperBillReport || this.showPurchaseActivityReport) {
            this.leftPanel[index].subList[ind].isShow = true;
          }
        }
        else if(res.title === 'Manufacturer Rebates') {
          if(this.showRewardsDetails) {
            this.leftPanel[index].subList[ind].isShow = true;
          }
        }
        else {
          this.leftPanel[index].subList[ind].isShow = true;
        }
      })
    });

    // For Admin Routes only
    const userAdminRouteIndex = this.settingList?.findIndex(element=>element?.title === 'Users Admin');
    if(this.userType ==='Admin') {
      this.settingList[userAdminRouteIndex].isShow = true;
    }else {
      this.settingList[userAdminRouteIndex].isShow = false;
    }
  }
  getFilteredList() {
     this.leftPanel.filter((element:any) => {
      element.subList = element.subList.filter((subElement:any) => {
        return subElement.isShow === true;
      });
      if (element.subList.length) return element;
    });
    
  }

  // function to open the switch account dialog box
  openManageModal() {
    this.dialog.open(ReusableSwitchAcctComponent,{
      data:  {userId: this.displayUid, b2bunit: this.currentB2bUnit},
      panelClass: ['custom-dialog-size', 'reusable-switch-dialog'],
      width:'730px',
    });
  }
  redirectPage(url:any,list:any){
    if(url == 'quickOrder'){
      this.router.navigate(['/quickOrder']);
    }
    if(url == 'rapidOrder'){
      this.router.navigate(['/rapidOrder']);
    }
    if(url === 'manageAccount'){
      this.manageBusinessAccount();
    }
    if(url === 'returns'){
      this.router.navigate(['/returns']);
    }
    this.toggleSideNav(false);
    this.gtmService.setGTMOnLoadData(true,this.user,list?.title);
  }
}


