import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService, WindowRef } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthGuardService } from './auth-guard.service';

@Injectable({
  providedIn: 'root',
})
export class ObMuBoAuthGuard implements CanActivate {
  constructor(
    private route: Router,
    protected authService: AuthService,
    private authGuardService: AuthGuardService,
    private windowRef: WindowRef,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    // For navigating the route
    // He tried to access while he was not logged in

    return this.authService.isUserLoggedIn().pipe(
      map((isLoggedIn) => {
        if (state.url.includes('utm_medium=email')) {
            this.windowRef?.localStorage?.setItem('isEmailUrl', state.url);
          }
        if (!this.authGuardService.isObagiOrMuSite() ||
          ( this.route?.url === "/sales-rep/login/omregister") ||
          (this.route?.url === "/sales-rep/request-account") ||
          (this.route?.url === "/login/omregister") ||
          (this.route?.url === "/account-request") ||
          (this.route?.url === "/new-account")) {
          return true;
        };
        const isAsmUserLoggedIn = this.windowRef?.localStorage?.getItem(
          'asmLoggedInUserEmail'
        );

        if (!isPlatformBrowser(this.platformId)) {
          this.route.navigate(['/ssr-intermittent'], {
            skipLocationChange: true,
          });
          return false;
        } else {
          if (
            !isLoggedIn &&
            !isAsmUserLoggedIn &&
            this.authGuardService.isObagiOrMuSite()
          ) {
            if (
              !state.url.includes('productDetails') &&
              !state.url.includes('categoryCode') &&
              !state.url.includes('brand') &&
              !state.url.includes('contentpage') && !this.windowRef?.localStorage?.getItem('loggedOut')) {
                this.windowRef?.localStorage?.setItem('goToUrl', state.url);
              } else {
                this.windowRef?.localStorage?.setItem('goToUrl', '/');
              }
            this.route.navigate(['/login']);
            return false;
          }
          return true;
        }
      })
    );
  }
}
