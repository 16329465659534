import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SiteContextConfig } from '@spartacus/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  urlPath: any;
  isSalesRep: boolean = false;
  urlForLogin: any;
  urlForHome: any;
  hideFooterSignupBlockForRoutes: string[] = ['/reset-password'];
  showSignupBlock: boolean = true;

  constructor(private router: Router, private config: SiteContextConfig) {}

  ngOnInit(): void {
    /** when we need basesite, language and locale in the site URL */
    // this.urlForLogin = `${this.config?.context?.baseSite}/${this.config?.context?.language}/${this.config?.context?.currency}/login`;
    // this.urlForHome = `${this.config?.context?.baseSite}/${this.config?.context?.language}/${this.config?.context?.currency}/`;

    /** when we dont need basesite, language and locale in the site URL  - This will not work in local*/
    this.urlForLogin = `/login`;
    this.urlForHome = `/`;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.urlPath = event.url;
        if (this.router.url.includes(this.hideFooterSignupBlockForRoutes[0])) {
          this.showSignupBlock = false;
        }
      }
    });
  }
}
