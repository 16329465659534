import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { User } from '@spartacus/core';
import { Subscription } from 'rxjs';
import * as fromApp from 'src/app/core/store/app.reducer';
import { PageLoaderService } from 'src/app/feature/pages/page-loader/page-loader.service';
import { OrderService } from 'src/app/shared/services/common/order.service';
import { global } from 'src/app/shared/translations/en/global';
import { BoxoutEndpointService } from '../../services/common/boxout-endpoint.service';
import { CartService } from '../../services/common/cart.service';

@Component({
  selector: 'app-reusable-order-approval-modal',
  templateUrl: './reusable-order-approval-modal.component.html'
})
export class ReusableOrderApprovalModalComponent implements OnInit {
  @Output() orderRejected: EventEmitter<any> = new EventEmitter();
  approvalData: any;
  comments: string;
  showApiError=false;
  directApproval: boolean;
  account = global.account.order;
  showApiErrorMsg='';
  cartId:any;
  user: User;
  userSub: Subscription;
  userId: string;
  orderDetails: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customHttp: BoxoutEndpointService,
    private dialogRef: MatDialogRef<ReusableOrderApprovalModalComponent>,
    private orderService: OrderService,
    private store: Store<fromApp.AppState>,
    private cdr: ChangeDetectorRef,
    private cartService:CartService,
    private pageLoaderService:PageLoaderService
  ) { }

  ngOnInit(): void {
    this.userSub = this.store.select('auth').subscribe(user => {
      this.user = user && user.user!;
      this.userId = this.user?.uid!;
      if (this.data) {
        this.approvalData = this.data?.obj;
        this.directApproval = this.data?.directApproval;
        this.cartId = this.data?.cartId;
      }

    });
  }

  approval(decision: string){
    this.approvalApi(decision).subscribe((data: any) => {
        if (data.success === true) {
          this.showApiError=false;
          this.orderService.setPagHeadingInstance(this.account.approvalHeading);
          this.dialogRef.close({
            message: 'success',
            orderCode: data.orderCode
          })         
        } else{
          this.showApiError=true;
          this.showApiErrorMsg=data.errorMsg;
        }
    },
    () => {
      this.dialogRef.close({
        message: 'fail',
        pageHeading: this.account.somethingWentWrong
      })  
    });
  }

  approvalApi(decision: string){
    let req = {
      additionalComments: this.comments,
      approverSelectedDecision: decision,
      orderCode: this.approvalData.order,
      workFlowActionCode: this.approvalData.workFlowActionCode,
      cartId:this.cartId
    }
    let apiUrl=`order/approvalDecision/`;
    return this.customHttp.post(apiUrl,req);
  }

}
