import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { SiteContextConfig } from '@spartacus/core';

@Injectable({
  providedIn: 'root'
})
export class CurrentThemeService {

  theme : any;
  constructor(private config: SiteContextConfig, @Inject(DOCUMENT) private document: Document) {
    this.theme = this.document.getElementsByTagName('app-root')[0].classList[0];
   }

  getCurrentTheme(): string | undefined {
    if(this.config && this.config?.context && this.config?.context?.theme) {
     return this.config?.context?.theme[0];
    }
    else {
      return this.theme;
    }
  }
}
