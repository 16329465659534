import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wroMask'
})

export class WroMaskPipe implements PipeTransform {

  // This Pipe is for mask the wro id on wro success page.
  // If the Wro Id: 00899600 so as per figma we need to display three number from begining and rest XXXXXX.
  transform(value: string): string {
    if (value.length > 3) {
      // Keep the first 3 characters as is and replace the rest with asterisks
      return value.substring(0, 3) + 'X'.repeat(value.length -3 );
    }
    return value;
  }

}
