import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class SectionLoaderService {
  isLoading=false;
  isLoadingWithWidth=false;


  constructor() {}

  //commenting the loader changes as loader has been implemented globally 
  show() {
    // this.isLoading=true;
  }

  hide() {
    // this.isLoading=false;
  }

  showLoader(){
    // this.isLoadingWithWidth=true;
  }

  hideLoader(){
    // this.isLoadingWithWidth=false;
  }

 
}
