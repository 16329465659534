import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@spartacus/core';
import { GenericLinkModule } from '@spartacus/storefront';
import { CookieService } from 'ngx-cookie-service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
// import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { BoxoutMaterialModule } from '../custom/boxout-material/boxout-material.module';
import { addDollarCustomCSSDataPipe } from './pipes/add-custom-css-with-dollar';
import { CartPromoComponent } from '../feature/components/cart-promo/cart-promo.component';
import { CartSummaryComponent } from '../feature/components/cart-summary/cart-summary.component';
import { ClearCartComponent } from '../feature/components/clear-cart/clear-cart.component';
import { MayAlsoLikeProductComponent } from '../feature/components/may-also-like-product/may-also-like-product.component';
import { EditPaymentMethodComponent } from '../feature/components/my-account/edit-payment-method/edit-payment-method.component';
import { EditPoComponent } from '../feature/components/my-account/edit-po/edit-po.component';
import { MyAccountRepDialogComponent } from '../feature/components/my-account/my-account-rep-dialog/my-account-rep-dialog.component';
import { OrderAddressCardComponent } from '../feature/components/my-account/order-address-card/order-address-card.component';
import { OrderDetailListComponent } from '../feature/components/my-account/order-detail-list/order-detail-list.component';
import { OrderDetailPromotionComponent } from '../feature/components/my-account/order-detail-promotion/order-detail-promotion.component';
import { OrderDetailRevisionHistoryComponent } from '../feature/components/my-account/order-detail-revision-history/order-detail-revision-history.component';
import { OrderDetailStatusComponent } from '../feature/components/my-account/order-detail-status/order-detail-status.component';
import { OrderInfoCardComponent } from '../feature/components/my-account/order-info-card/order-info-card.component';
import { OrderTotalCardComponent } from '../feature/components/my-account/order-total-card/order-total-card.component';
import { LinePromotionComponent } from '../feature/components/promotions/line-promotion/line-promotion.component';
import { MixMatchPromotionComponent } from '../feature/components/promotions/mix-match-promotion/mix-match-promotion.component';
import { OrderPromotionComponent } from '../feature/components/promotions/order-promotion/order-promotion.component';
import { OrderCreditComponent } from '../feature/components/promotions/order-credit/order-credit.component';
import { PromotionSliderComponent } from '../feature/components/promotions/promotion-slider/promotion-slider.component';
import { RecentlyViewedProductComponent } from '../feature/components/recently-viewed-product/recently-viewed-product.component';
import { LoaderComponent } from '../feature/pages/loader/loader.component';
import { AddEditFavoriteListComponent } from './components/add-edit-favorite-list/add-edit-favorite-list.component';
import { AddShipToCartbusinessAddressComponent } from './components/add-ship-to-cartbusiness-address/add-ship-to-cartbusiness-address.component';
import { AddShipToClientPatientAddressDrawerComponent } from './components/add-ship-to-client-patient-address-drawer/add-ship-to-client-patient-address-drawer.component';
import { BillToCheckoutAddressComponent } from './components/bill-to-checkout-address/bill-to-checkout-address.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BusinessAddressesComponent } from './components/business-addresses/business-addresses.component';
import { CartInformativeModalComponent } from './components/cart-informative-modal/cart-informative-modal.component';
import { CartCreditModalComponent } from './components/cart-credit-modal/cart-credit-modal.component';
import { CartItemComponent } from './components/cart-item/cart-item.component';
import { CartShipToModalComponent } from './components/cart-ship-to-modal/cart-ship-to-modal.component';
import { ShipToAddNewAddressModalComponent } from './components/cartShipto-business/ship-to-add-new-address-modal/ship-to-add-new-address-modal.component';
import { ShipToAddressModalComponent } from './components/cartShipto-business/ship-to-address-modal/ship-to-address-modal.component';
import { ShipToBusinessAccountModalComponent } from './components/cartShipto-business/ship-to-business-account-modal/ship-to-business-account-modal.component';
import { ShipToNewBusinessAccountModalComponent } from './components/cartShipto-business/ship-to-new-business-account-modal/ship-to-new-business-account-modal.component';
import { ShipToViewAddressModalComponent } from './components/cartShipto-business/ship-to-view-address-modal/ship-to-view-address-modal.component';
import { CreateClientAcctComponent } from './components/create-client-acct/create-client-acct.component';
import { CustomBannerComponent } from './components/custom-banner/custom-banner.component';
import { CustomMediaComponent } from './components/custom-media/custom-media.component';
import { CustomParagraphComponent } from './components/custom-paragraph/custom-paragraph.component';
import { DefaultTemplateComponent } from './components/default-template/default-template.component';
import { ItemsAddedToCartComponent } from './components/items-added-to-cart/items-added-to-cart.component';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { MedicalInfoComponent } from './components/medical-info/medical-info.component';
import { NotifyStockSliderComponent } from './components/notify-stock-slider/notify-stock-slider.component';
import { OrderHistoryCardComponent } from './components/order-history-card/order-history-card.component';
import { OrderMedicalInfoComponent } from './components/order-medical-info/order-medical-info.component';
import { OtherShipToClientOrPatientAddressesDrawerComponent } from './components/other-ship-to-client-or-patient-addresses-drawer/other-ship-to-client-or-patient-addresses-drawer.component';
import { RecommendedAddressComponent } from './components/recommended-address/recommended-address.component';
import { ReportsSearchFormComponent } from './components/reports-search-form/reports-search-form.component';
import { ReusableAddShipToAddressComponent } from './components/reusable-add-ship-to-address/reusable-add-ship-to-address.component';
import { ReusableAddressFormComponent } from './components/reusable-address-form/reusable-address-form.component';
import { ReusableEditEmailModalComponent } from './components/reusable-edit-email-modal/reusable-edit-email-modal.component';
import { ReusableEditShipToAddressComponent } from './components/reusable-edit-ship-to-address/reusable-edit-ship-to-address.component';
import { ReusableMiniCartComponent } from './components/reusable-mini-cart/reusable-mini-cart.component';
import { ReusableOrderApprovalModalComponent } from './components/reusable-order-approval-modal/reusable-order-approval-modal.component';
import { ReusableRejectOrderModalComponent } from './components/reusable-reject-order-modal/reusable-reject-order-modal.component';
import { ReusableSignInSecurityComponent } from './components/reusable-sign-in-security/reusable-sign-in-security.component';
import { ReusableSwitchAcctComponent } from './components/reusable-switch-acct/reusable-switch-acct.component';
import { SalesRepCallMeDrawerComponent } from './components/sales-rep-call-me-drawer/sales-rep-call-me-drawer.component';
import { ProductFacetsComponent } from './components/search/product-facets/product-facets.component';
import { ProductRowViewComponent } from './components/search/product-row-view/product-row-view.component';
import { ProductTileViewComponent } from './components/search/product-tile-view/product-tile-view.component';
import { SearchHeaderComponent } from './components/search/search-header/search-header.component';
import { SelectClientTypeComponent } from './components/select-client-type/select-client-type.component';
import { ShipToCheckoutAddressComponent } from './components/ship-to-checkout-address/ship-to-checkout-address.component';
import { ShipToClientOrPatientDrawerComponent } from './components/ship-to-client-or-patient-drawer/ship-to-client-or-patient-drawer.component';
import { SwitchAccountDialogComponent } from './components/switch-account-dialog/switch-account-dialog.component';
import { WishlistListComponent } from './components/wishlist-list/wishlist-list.component';
import { formatNumberDirective } from './directive/format-number';
import { ResizeColumnDirective } from './directive/resize-column-directive';
import { emailMaskDataPipe } from './pipes/email-mask-data';
import { formatCurrencyPipe } from './pipes/format-currency';
import { formatPhonePipe } from './pipes/format-phone';
import { formatWarehoseDataPipe } from './pipes/format-warehouse-data';
import { maskDataPipe } from './pipes/mask-data';
import { CustomTooltipComponent } from './components/custom-tooltip/custom-tooltip.component';
import { OrderRewardCreditsComponent } from '../feature/components/my-account/order-reward-credits/order-reward-credits.component';
import { BxdReusableFilterComponent } from '../feature/components/portal-boxout/bxd-reusable-filter/bxd-reusable-filter.component';
import { RemoveAriaOwnsDirective } from './directive/remove-aria-owns';
import { WroMaskPipe } from './pipes/wro-mask.pipe';
import { BxdReusableDownloadLabelComponent } from '../feature/components/portal-boxout/bxd-reusable-download-label/bxd-reusable-download-label.component';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { QRCodeModule } from 'angularx-qrcode';
import { FreeFreightDialogComponent } from '../feature/components/free-freight-dialog/free-freight-dialog.component';

@NgModule({
  declarations: [
    FreeFreightDialogComponent,
    LoginDialogComponent,
    CartPromoComponent,
    SwitchAccountDialogComponent,
    maskDataPipe,
    formatPhonePipe,
    emailMaskDataPipe,
    addDollarCustomCSSDataPipe,
    formatCurrencyPipe,
    formatWarehoseDataPipe,
    formatNumberDirective,
    RemoveAriaOwnsDirective,
    ReusableAddressFormComponent,
    BusinessAddressesComponent,
    RecommendedAddressComponent,
    ReusableSwitchAcctComponent,
    ReusableEditShipToAddressComponent,
    ReusableAddShipToAddressComponent,
    SelectClientTypeComponent,
    CreateClientAcctComponent,
    ReusableMiniCartComponent,
    ResizeColumnDirective,
    ReportsSearchFormComponent,
    AddEditFavoriteListComponent,
    WishlistListComponent,
    ReusableSignInSecurityComponent,
    ReusableEditEmailModalComponent,
    ItemsAddedToCartComponent,
    ProductFacetsComponent,
    SearchHeaderComponent,
    ProductTileViewComponent,
    ProductRowViewComponent,
    CartItemComponent,
    CartInformativeModalComponent,
    OrderHistoryCardComponent,
    CartShipToModalComponent,
    ShipToClientOrPatientDrawerComponent,
    OtherShipToClientOrPatientAddressesDrawerComponent,
    SalesRepCallMeDrawerComponent,
    ShipToNewBusinessAccountModalComponent,
    ShipToBusinessAccountModalComponent,
    ShipToAddressModalComponent,
    ShipToAddNewAddressModalComponent,
    ShipToViewAddressModalComponent,
    AddShipToClientPatientAddressDrawerComponent,
    OrderDetailStatusComponent,
    ReusableOrderApprovalModalComponent,
    ReusableRejectOrderModalComponent,
    AddShipToCartbusinessAddressComponent,
    NotifyStockSliderComponent,
    RecentlyViewedProductComponent,
    MayAlsoLikeProductComponent,
    ShipToCheckoutAddressComponent,
    BillToCheckoutAddressComponent,
    OrderInfoCardComponent,
    OrderAddressCardComponent,
    OrderTotalCardComponent,
    OrderDetailListComponent,
    EditPaymentMethodComponent,
    OrderDetailPromotionComponent,
    EditPoComponent,
    BreadcrumbComponent,
    LoaderComponent,
    // PageLoaderComponent,
    CustomParagraphComponent,
    CartSummaryComponent,
    ClearCartComponent,
    OrderPromotionComponent,
    OrderCreditComponent,
    LinePromotionComponent,
    CartCreditModalComponent,
    OrderPromotionComponent,
    OrderDetailRevisionHistoryComponent,
    PromotionSliderComponent,
    MixMatchPromotionComponent,
    MyAccountRepDialogComponent,
    DefaultTemplateComponent,
    CustomMediaComponent,
    CustomBannerComponent,
    MedicalInfoComponent,
    OrderMedicalInfoComponent,
    CustomTooltipComponent,
    OrderRewardCreditsComponent,
    BxdReusableFilterComponent,
    BxdReusableDownloadLabelComponent,
    WroMaskPipe,
    FreeFreightDialogComponent
  ],
  imports: [
    I18nModule,
    // NgxUiLoaderModule,
    CommonModule,
    BoxoutMaterialModule,
    NgxPaginationModule,
    RouterModule,
    InfiniteScrollModule,
    GenericLinkModule,
    NgxBarcode6Module,
    QRCodeModule
    // LoaderModule,
  ],
  exports: [
    maskDataPipe,
    CartPromoComponent,
    ReusableAddShipToAddressComponent,
    formatPhonePipe,
    emailMaskDataPipe,
    addDollarCustomCSSDataPipe,
    formatCurrencyPipe,
    OrderDetailRevisionHistoryComponent,
    formatWarehoseDataPipe,
    formatNumberDirective,
    RemoveAriaOwnsDirective,
    OrderDetailPromotionComponent,
    ReusableAddressFormComponent,
    BusinessAddressesComponent,
    RecommendedAddressComponent,
    ReusableMiniCartComponent,
    ResizeColumnDirective,
    ReportsSearchFormComponent,
    ProductFacetsComponent,
    SearchHeaderComponent,
    ProductTileViewComponent,
    ProductRowViewComponent,
    InfiniteScrollModule,
    NgbAccordionModule,
    NgbModule,
    NgxSpinnerModule,
    OrderHistoryCardComponent,
    CartItemComponent,
    OrderDetailStatusComponent,
    OrderInfoCardComponent,
    OrderAddressCardComponent,
    OrderTotalCardComponent,
    OrderDetailListComponent,
    EditPaymentMethodComponent,
    EditPoComponent,
    BreadcrumbComponent,
    LoaderComponent,
    CustomParagraphComponent,
    CartSummaryComponent,
    ClearCartComponent,
    OrderPromotionComponent,
    LinePromotionComponent,
    OrderPromotionComponent,
    PromotionSliderComponent,
    MixMatchPromotionComponent,
    RecentlyViewedProductComponent,
    CartCreditModalComponent,
    MayAlsoLikeProductComponent,
    CustomMediaComponent,
    CustomBannerComponent,
    OrderMedicalInfoComponent,
    CustomTooltipComponent,
    OrderRewardCreditsComponent,
    // LoaderModule,,
    BxdReusableFilterComponent,
    BxdReusableDownloadLabelComponent,
    WroMaskPipe
  ],
  providers: [
    CookieService,
    maskDataPipe,
    formatPhonePipe,
    emailMaskDataPipe,
    addDollarCustomCSSDataPipe,
    formatCurrencyPipe,
  ],
})
export class SharedModule {}
