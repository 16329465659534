import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, PageMetaService } from '@spartacus/core';
import { FooterModule } from './footer/footer.module';
import { AuthGuardService } from './guards/auth-guard.service';
import { HeaderModule } from './header/header.module';
import { CustomPageMetaService } from './services/custom-page-meta.service';

@NgModule({
  declarations: [],
  imports: [CommonModule,
    HeaderModule,
    FooterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        // CART MODULES STARTS //
        cartProductListComponent: {
          component: () => import('./../feature/components/cart-product/cart-product.component').then(m => m.CartProductComponent)
        },
        cartSummaryComponent: {
          component: () => import('./../feature/components/cart-summary/cart-summary.component').then(m => m.CartSummaryComponent)
        },
        // CART MODULES ENDS //

        // Reorder MODULES STARTS //
        ReorderComponent: {
          component: () => import('./../feature/components/reorder-cart-product/reorder-cart-product.component').then(m => m.ReorderCartProductComponent)
        },
        // Reorder MODULES ENDS //

        // CHECKOUT MODULES STARTS //
        checkoutSummaryComponent: {
          component: () => import('./../feature/components/product-checkout-page/product-checkout-page.component').then(m => m.ProductCheckoutPageComponent)
        },
        // CHECKOUT MODULES ENDS //

        // RAPID ORDER MODULE STARTS //
        BoxoutRapidOrderComponent: {
          component: () => import('./../feature/components/my-account/rapid-order-category/rapid-order-category.component').then(m => m.RapidOrderCategoryComponent)
        },
        // RAPID ORDER MODULE ENDS //

        //REGISTER PAGE MODULE STARTS
        RegisterPageForm: {
          component: () => import('./../feature/components/register-account/register-main/register-main.component').then(m => m.RegisterMainComponent)

        },
        //REGISTER PAGE MODULE ENDS

        //ITEM PURCHASE REPORT STARTS
        itemPurchaseActivity: {
          component: () => import('./../feature/components/item-purchase-search-form/item-purchase-search-form.component').then(m => m.ItemPurchaseSearchFormComponent)
        },
        //ITEM PURCHASE REPORTS ENDS

        //ADVANCED EBILL REPORTS STARTS
        advancedEbillReportQuery: {
          component: () => import('./../feature/components/advanced-ebill-search-form/advanced-ebill-search-form.component').then(m => m.AdvancedEbillSearchFormComponent)
        },
        //ADVANCED EBILL REPORTS ENDS

        //REPORTS DOWNLOAD STARTS
        downloadReportPage: {
          component: () => import('./../feature/components/download-reports/download-reports.component').then(m => m.DownloadReportsComponent)
        },
        //REPORTS DOWNLOAD ENDS

        //CATEGORY LISTING PAGE STARTS
        BoxoutCategoryListPageComponent: {
          component: () => import('./../feature/components/category-content/category-content.component').then(m => m.CategoryContentComponent)
        },
        //CATEGORY LISTING PAGE ENDS

        //BRAND PAGE STARTS
        brandPageFlex: {
          component: () => import('./../feature/components/brand-list/brand-list.component').then(m => m.BrandListComponent)
        },
       CMSParagraphComponent: {
          component: () => import('./../shared/components/custom-paragraph/custom-paragraph.component').then(m => m.CustomParagraphComponent)
        },
        MediaComponent: {
          component: () => import('./../shared/components/custom-media/custom-media.component').then(m => m.CustomMediaComponent)
        },
        SimpleResponsiveBannerComponent: {
          component: () => import('./../shared/components/custom-banner/custom-banner.component').then(m => m.CustomBannerComponent)
        },
        BannerComponent: {
          component: () => import('./../shared/components/custom-banner/custom-banner.component').then(m => m.CustomBannerComponent)
        },
        SimpleBannerComponent: {
          component: () => import('./../shared/components/custom-banner/custom-banner.component').then(m => m.CustomBannerComponent)
        },
        //BRAND PAGE ENDS

        //SALESREP PAGE STARTS
        SalesRepLoginForm: {
          component: () => import('./../feature/pages/sales-rep/sr-login-page/sr-login-page.component').then(m => m.SrLoginPageComponent)
        },
        //SALESREP GRAPH
        SalesRepHomepageFlexComponent: {
         component: () => import('./../feature/pages/sales-rep/sr-home/sr-home.component').then(m => m.SrHomeComponent)
       },
        // SALESREP SEARCH
        SalesRepAccountSearchResults: {
         component: () => import('./../feature/components/sales-rep/sr-search/sr-search.component').then(m => m.SrSearchComponent)
       },
        //SALESREP PAGE ENDS


        //BACKORDERED SEARCH
        backordersReportQueryPageFlexComponent: {
          component: () => import('./../feature/components/backorder-search-form/backorder-search-form.component').then(m => m.BackorderSearchFormComponent)
        },

        //POD Bulk Export
        bulkPODSearchPageFlexComponent: {
          component: () => import('./../feature/components/pod-bulk-export-search/pod-bulk-export-search.component').then(m => m.PodBulkExportSearchComponent)
        },
        // BOXOUT, OBAGI, MURAD and INTERMEDIATE LOGIN PAGE
        LoginForm: {
          component: () => import('./../feature/pages/login/login.component').then(m => m.LoginComponent)
        },
        // PAGE NOT FOUND MODULE STARTS //
        errorDetailsSummaryFlexSlot: {
          component: () => import('./../feature/pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
        },

        advancedEbillReportResults: {
          component: () => import('./../feature/pages/advanced-ebill/advanced-ebill.component').then(m => m.AdvancedEbillComponent)
        },
        backordersReportResultsFlexComponent: {
          component: () => import('./../feature/pages/backorder-report/backorder-report.component').then(m => m.BackorderReportComponent)
        },
        ProductFacetFlexComponent: {
          component: () => import('./../feature/pages/brand-search/brand-search.component').then(m => m.BrandSearchComponent)
        },
        searchPageFlex: {
          component: () => import('./../feature/pages/product-search/product-search.component').then( m => m.ProductSearchComponent)
        },
        categorySearchPageFlex: {
          component: () => import('./../feature/pages/product-search-category/product-search-category.component').then( m => m.ProductSearchCategoryComponent)
        },
        brandSearchPageFlex: {
          component: () => import('./../feature/pages/brand-search/brand-search.component').then( m => m.BrandSearchComponent)
        },
        categoryListingPageFlex: {
          component: () => import('./../feature/pages/category-listing-page/category-listing-page.component').then( m => m.CategoryListingPageComponent)
        },
        BoxoutPortalHomepageFlexComponent: {
          component: () => import('./../feature/components/portal-boxout/portal-bxd-drawer/portal-bxd-drawer-component').then( m => m.PortalBxdDrawerComponent)
        },
        BoxoutPortalLandingPageFlexComponent: {
          component: () => import('./../feature/components/portal-boxout/portal-bxd-drawer/portal-bxd-drawer-component').then( m => m.PortalBxdDrawerComponent)
        },
        BoxoutLandingPageHeaderFlexComponent: {
          component: () => import('../feature/components/portal-boxout/portal-bxd-contentpage/portal-bxd-contentpage.component').then( m => m.PortalBxdContentpageComponent)
        }
        // PAGE NOT FOUND MODULE ENDS //
      }
    }as CmsConfig),
  ],
  exports: [HeaderModule,FooterModule],
  providers: [ {provide: PageMetaService, useClass: CustomPageMetaService}, AuthGuardService]
})
export class CoreModule {}
