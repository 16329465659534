<app-asm-header *ngIf="isAsmUserLoggedIn"></app-asm-header>
<header *ngIf="!isShowCheckoutHeader">
  <div class="header">
    <div class="ProfileTopHeaderSlot w-100">
      <app-profile-top-header></app-profile-top-header>
    </div>

    <!--Binding content slot for site links-->
    <cx-page-slot
      position="SiteLinks"
      class="SiteLinkFlexSlot w-100 d-none d-lg-flex"
    >
    </cx-page-slot>

    <div class="HeaderPlaceholderFlexSlot headerMiddleBarWrapper w-100">
      <app-header-middle-bar></app-header-middle-bar>
    </div>
    <div class="d-none d-lg-flex w-100 navigation-section">
      <cx-page-slot position="NavigationBar" class="d-flex">
      </cx-page-slot>
      <app-support-chat></app-support-chat>
    </div>
    <div class="d-block d-lg-none w-100 MobileSearchBarSlot">
      <app-header-searchbar></app-header-searchbar>
    </div>
    <app-header-stickybar class="w-100"></app-header-stickybar>
  </div>
</header>

<header class="checkout-main-section" *ngIf="isShowCheckoutHeader">
  <div class="d-flex flex-row">
    <div class="d-flex align-items-center checkout-left-section px-3">
      <div class="cursor-pointer" (click)="backToCart()">
        <mat-icon svgIcon="back-white" aria-label="Back" class=""></mat-icon>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center pl-3 w-100">
      <div class="t-title-inverse-l checkout-header">{{ "checkout.title" | cxTranslate }}</div>
      <div class="mr-3 boxout-logo d-flex align-items-center">
        <cx-page-slot position="MobileSiteLogoSlot"></cx-page-slot>
      </div>
    </div>
  </div>
</header>
