import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SsrService {
  //Order Id for edit order
  ssrKey: any;
  frwdUrlKey: any;

  setSSRKey(data: string) {
    this.ssrKey = data;
  }

  getSSRKey() {
    return this.ssrKey;
  }

  setForwardUrlConst(data: string) {
    this.frwdUrlKey = data;
  }

  getForwardUrlConst() {
    return this.frwdUrlKey;
  }
}