import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
// import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { CreateBusinessAcctComponent } from 'src/app/feature/components/my-account/create-business-acct/create-business-acct.component';
import { SectionLoaderService } from 'src/app/feature/pages/loader/section-loader.service';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { CartService } from 'src/app/shared/services/common/cart.service';
import { CurrentThemeService } from 'src/app/shared/services/common/current-theme.service';
import { SmartyAddressValidationService } from 'src/app/shared/services/common/smarty-address-validation.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { AddShipToCartbusinessAddressComponent } from '../../add-ship-to-cartbusiness-address/add-ship-to-cartbusiness-address.component';
import { ShipToViewAddressModalComponent } from '../ship-to-view-address-modal/ship-to-view-address-modal.component';

@Component({
  selector: 'app-ship-to-business-account-modal',
  templateUrl: './ship-to-business-account-modal.component.html',
})
export class ShipToBusinessAccountModalComponent implements OnInit, OnDestroy {
  currentTheme: any;
  totalRecords: number = 0;
  searchParam: string = '';
  user: User;
  userSub: Subscription;
  userId: any;
  isAdmin: any;
  userType: any;
  customerRole: any;
  page: number = 1;
  addresses: any = [];
  totalRecordsOnPage = 15;
  totalPages: number = 0;
  addNewBusiness = false;
  apiErrorMessage=false;
  addNewShipToAddress: boolean = false;

  constructor(
    public dialog: MatDialog,
    private theme: CurrentThemeService,
    private customHttp: BoxoutEndpointService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private headerService: HeaderService,
    public dialogRef: MatDialogRef<ShipToViewAddressModalComponent>,
    private dialogBusinessRef: MatDialogRef<ShipToBusinessAccountModalComponent>,
    private smartyAddressValidationService: SmartyAddressValidationService,
    private store: Store<fromApp.AppState>,private cartService:CartService,
    private sectionLoaderService:SectionLoaderService
    // private ngxLoader:NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    /** Taking themes from themeservice **/
    this.currentTheme = this.theme?.getCurrentTheme();
    this.headerService.isAdmin.subscribe((response) => {
      this.isAdmin = response;
    });
    this.userSub = this.store.select('auth').subscribe((user) => {
      this.user = user && user.user!;
      this.userId = this.user?.uid;
      this.customerRole = this.user?.customerRole;
      this.userType = this.user?.orgUnit?.unitType;
      if (this.userId) {
        this.getAddressData(this.page, this.searchParam);
      }
    });

    this.getNewlyAddedAddressOnAccount();
    // this.getNewlyAddedViewAddressOnAccount();
  }

  //To fetch all the related accounts on page load
  getAddressData(page?: number, searchParam?: string) {
    // this.ngxLoader.startLoader('loader-01');
    this.sectionLoaderService.show();
    this.page = page ? page : 1;
    page ? (page = page - 1) : page;
    var searchKeyword = this.searchParam ? this.searchParam : '';
    let apiURL = `/users/${this.userId}/getBusinessAccounts?fields=DEFAULT&page=${page}&show=Page&q=${searchKeyword}`;
    this.customHttp.get(apiURL).subscribe((data: any) => {
      this.sectionLoaderService.hide();
      if (data) {
        this.addresses = data.results;
        this.page = data.pagination.page + 1;
        this.totalRecords = data.pagination.totalCount;
        this.totalPages = data.pagination.totalPages;
        this.cdr.detectChanges();
      }
    },(error)=>{
      this.sectionLoaderService.hide()
    });
  }

  //To fetch all the addresses related to parent or subaccount
  getViewAddress(item: any) {
    // this.dialogRef.close();
    this.dialogBusinessRef.close();
    this.dialog.open(ShipToViewAddressModalComponent, {
      position: {
        right: '0px',
        top: '0px',
      },
      panelClass: ['wishlist-drawer', this.currentTheme],
      data: {
        value: {
          itemData: item,
          addAddressId: item.id,
          showSuccessForCart:false,
          showFailureForCart:false
        },
      },
    });
  }

  //clear selected search
  clearSearch() {
    this.searchParam = '';
    this.getAddressData(this.page,this.searchParam);
  }

  addNewBusinessAccount() {
    this.dialogBusinessRef.close();
    this.addNewBusiness = true;
    this.smartyAddressValidationService.saveFormData(null);
    this.dialog.open(CreateBusinessAcctComponent, {
      panelClass: [this.currentTheme, 'ship-to-client-drawer-for-all', 'cart-add-business'],
      width: '368px',
      height:'100%',
      position: {
        right: '0px',
        top: '0px',
      },
      data: {
        componentName: 'shipTocartBusinessAccount',
      },
    });
  }
  ngOnDestroy(): void {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

  addNewAddress(item: any) {
    this.addNewShipToAddress = true;
    this.smartyAddressValidationService.saveFormData(null);
    
    let defaultShippgTo = (item?.accountType === "DROPSHIP" && !(item?.shipTo)) || 
    (item?.accountType === "PARENT" && !(item?.shipTo)) || 
    (item?.accountType === "DIRECT" && !(item?.shipTo)) ? true : false; 
    
    let defaultBillingTo = (item?.accountType === "PARENT" && !(item?.shipTo)) || 
    (item?.accountType === "DIRECT" && !(item?.shipTo)) ? true : false;

    
    this.dialog.open(AddShipToCartbusinessAddressComponent, {
      panelClass: [this.currentTheme, 'ship-to-client-drawer-for-all'],
      width: '368px',
      position: {
        right: '0px',
        top: '0px',
      },
      data: {
        value: {
          action:"addingNewAddressForBusinessAccount",
          componentName: 'cartShipToBusinessAccountComponent',
          addAddressId: item.id,
          openedDrawerFrom:'shipToBusinessAccount',
          defaultShippgTo:defaultShippgTo,
          defaultBillingTo: defaultBillingTo
        },
      },
    });
  }

  close() {
    this.dialogBusinessRef.close();
  }
  searchInput(){
    this.getAddressData(0,this.searchParam);
  }


  
//to get newly added address on account
getNewlyAddedAddressOnAccount(){
  // if(this.data){
  //   this.data={};saveAddressForAddedViewAddressAlertData
  // }
  
  this.smartyAddressValidationService.saveAddressForBusinessAccountAlertData$.subscribe((val)=>{
    if (!this.dialog?.openDialogs?.length){
      this.dialog.open(ShipToBusinessAccountModalComponent, {
        position: {
          right: '0px',
          top: '0px'
        },
        panelClass: ['wishlist-drawer', this.currentTheme],
        data: {
          value:{
            showSuccessForCart:val,
            showFailureForCart:!val
          }
        }
      }); 
  }
  
});
}




  
//to get newly added address on account
getNewlyAddedViewAddressOnAccount(){
  // if(this.data){
  //   this.data={};saveAddressForAddedViewAddressAlertData
  // }
  this.smartyAddressValidationService.saveAddressForAddedViewAddressAlertData$.subscribe((val)=>{
  
    this.dialog.open(ShipToViewAddressModalComponent, {
      position: {
        right: '0px',
        top: '0px'
      },
      panelClass: ['wishlist-drawer', this.currentTheme],
      data: {
        value:{
          showSuccessForCart:val,
          showFailureForCart:!val
        }
      }
    });
  
});
}
shipToSelectedAddress(siebelId:any,id:any){
  // this.ngxLoader.startLoader('loader-01');
  this.sectionLoaderService.show();
let cartId = sessionStorage.getItem('cartId');
  let payloadData={"siebelRowId":siebelId,"addressSiebelRowID":id,"isShipTo":false};
 
 
  let apiUrl=`users/current/carts/${cartId}/addressDetails/?fields=DEFAULT`
  this.customHttp.post(apiUrl,payloadData).subscribe((res)=>{
    this.sectionLoaderService.hide();
    // this.ngxLoader.stopLoader('loader-01');
    if(res?.isSuccess){

      this.apiErrorMessage=false;
    this.dialogBusinessRef.close();
    this.cartService.getCartPageData(this.userId, true);
    }else{
      this.apiErrorMessage=true;

    }
  },(error)=>{
    this.apiErrorMessage=true;
    this.sectionLoaderService.hide();
  })
}

showBusinessAddress(address: any){
  return this.smartyAddressValidationService.returnFormattedAddress(address);
}

}
