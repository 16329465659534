import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { account } from 'src/app/feature/pages/my-account/translations/en/account';
import { PageLoaderService } from 'src/app/feature/pages/page-loader/page-loader.service';
import { OrderService } from 'src/app/shared/services/common/order.service';
import { BoxoutEndpointService } from '../../services/common/boxout-endpoint.service';

@Component({
  selector: 'app-reusable-reject-order-modal',
  templateUrl: './reusable-reject-order-modal.component.html'
})
export class ReusableRejectOrderModalComponent implements OnInit {
  @Output() orderRejected: EventEmitter<any> = new EventEmitter();
  approvalData: any;
  comments: string;
  uid: any;
  account = account.account.order;
  isPromotionApplied: boolean = false;
  isRewardsApplied: boolean = false;
  showApiError=false;
  showApiErrorMsg='';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customHttp: BoxoutEndpointService,
    private dialogRef: MatDialogRef<ReusableRejectOrderModalComponent>,
    private orderService: OrderService,
    private pageLoaderService:PageLoaderService
    ) { }

  ngOnInit(): void {
    if(this.data){
      this.approvalData = this.data?.obj
      this.uid = this.data?.obj?.uid;
      this.isPromotionApplied = this.data?.isPromotionApplied;
      this.isRewardsApplied = this.data?.isRewardsApplied;
    }
    
  }
  rejectAndClose(decision: string){
    this.rejectApi(decision).subscribe((data: any) => {
        if (data.success === true) {
          this.showApiError=false;
          this.orderService.setPagHeadingInstance(this.account.rejectHeadingClosed);
          this.dialogRef.close({
            message: 'success',
            orderCode: data.orderCode
          })     
        } else{
          this.showApiError=true;
          this.showApiErrorMsg=data.errorMsg;
        }
    },
    () => {
      this.dialogRef.close({
        message: 'fail'
      })  
    });
  }

  rejectAndEdit(decision: string) {
    this.rejectApi(decision).subscribe((data: any) => {
      if (data.success === true) {
        this.orderService.setPagHeadingInstance(this.account.rejectHeading);
        this.dialogRef.close({
          message: 'success',
          orderCode: data.orderCode
        })     
      }
    },
    () => {
      this.dialogRef.close({
        message: 'fail',
        pageHeading: this.account.somethingWentWrong
      })  
    });
  }

  rejectApi(decision: string){
    let req = {
      additionalComments: this.comments,
      approverSelectedDecision: decision,
      orderCode: this.approvalData.order,
      workFlowActionCode: this.approvalData.workFlowActionCode
    }
    let apiUrl=`order/approvalDecision/`;
    return this.customHttp.post(apiUrl,req);
  }
}
