import {
  Component,
  Inject,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { Breadcrumbs } from 'src/app/core/constants/breadcrumbs.constants';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
})
export class CustomTooltipComponent implements OnInit {
  @Input() tooltip: boolean;
  @Input() brandProgramId: any;
  @Output() showTooltip = new EventEmitter<boolean>();
  @Input() index: number;
  @Input() message: string;
  tooltipMessage: string;
  tooltipIndex: any;
  tooltipId: any;
  constructor(public dialog: MatDialog) {}

  ngOnInit() {
    this.tooltipMessage = this.message;
    this.tooltipIndex = this.index;
    this.tooltipId = 'custom-tooltip-' + this.tooltipIndex;
  }
  handleTooltip(show: boolean) {
    show === false &&
      document.getElementById(this.tooltipId)?.classList.remove('show');
  }
}
