import { Component, OnInit } from '@angular/core';
import { CmsService } from '@spartacus/core';

@Component({
  selector: 'app-support-chat',
  templateUrl: './support-chat.component.html'
})
export class SupportChatComponent implements OnInit {

  liveChat:any;
  phoneNumber: any;
  callNumber: any;

  constructor(private cmsService: CmsService) { }

  ngOnInit(): void {
    this.cmsService.getComponentData('LiveSupportLink').subscribe( data => this.liveChat = data);
    this.cmsService.getComponentData('PhoneNumberParagraphComponent').subscribe( data => this.phoneNumber = data);
    this.callNumber = this.phoneNumber?.content.replace(/\D/g, '');
  }

}
