import { AccountManagerDetails } from "./account-manager-details.model";
import { Address } from "./address.model";
import { Approver } from "./approver.model";
import { Currency } from "./currency.model";
import { Language } from "./language.model";
import { Notification } from "./notification.model";
import { OrgUnit } from "./org-unit.model";

export class User {
  public type?: string;
  public name?: string;
  public uid?: string;
  public active?: boolean;
  public approvers?: Approver[];
  public celebrosPreference?: string;
  public currency?: Currency;
  public displayUid?: string;
  public firstName?: string;
  public lastName?: string;
  public notification?: Notification[];
  public orgUnit?: OrgUnit;
  public roles?: string[];
  public selected?: boolean;
  public serviceEmail?: string;
  public servicePhone?: string;
  public customerRole?: string;
  public ricEnabled?: boolean;
  public accountManagerDetails?: AccountManagerDetails;
  public customerId?: string;
  public deactivationDate?: any;
  public defaultAddress?: Address;
  public email?: string;
  public language?: Language;
  public orderApprovedEmail?: string;
  public orderConfirmationEmail?: string;
  public orderNeedsApprovalmail?: string;
  public shipConfirmationEmail?: string;
  public title?: string;
  public titleCode?: string;
  public isViewInventoryGroup?: boolean;
  public phone?:string;
  public customerRoles?:string[];
  public isNotifyMeButtonEnabled?: boolean;
  public programTitle?: string;
  public summary?: string;
  public tplProgramActive?: string;
  public tplProgramDesc?: string;
  public tplProgramId?: string;
  public tplProgramName?: string;

  
  constructor(json?: any) {
    if (json) {
      Object.assign(this, json);
    }
  }
}