import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CmsService, SiteContextConfig } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { CurrentThemeService } from 'src/app/shared/services/common/current-theme.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { ScriptService } from 'src/app/shared/services/script.service';
import { googleSiteKeys, googleTagKeys } from 'src/environments/environment';
import * as fromApp from '../../../../core/store/app.reducer';
import { HeaderSitelinksComponent } from '../header-sitelinks/header-sitelinks.component';

@Component({
  selector: 'app-header-middle-bar',
  templateUrl: './header-middle-bar.component.html'
})
export class HeaderMiddleBarComponent implements OnInit, OnDestroy {
  logo: any;
  user: User;
  userSub: Subscription;
  mobileLogo: any;
  phoneNumber: any;
  mobileQuery: any;
  siteLinksDialog: any;
  themeClass:any;
  isOpened = true;
  @ViewChild('dialogRef')
  dialogRef!: TemplateRef<any>;
  siteKey: any;
  baseSiteName: any;
  baseSiteNameVal: any;
  // private _mobileQueryListener: () => any;
  private dataUrl = '/config?fields=DEFAULT';
  GtaSiteKey:any;

  constructor(private cmsService: CmsService, private headerService: HeaderService, 
    public dialog: MatDialog, private cdr: ChangeDetectorRef,
    public breakpointObserver: BreakpointObserver,private router :Router, public http: HttpClient,
    @Inject(DOCUMENT) private document: Document, private theme: CurrentThemeService, private customHttp: BoxoutEndpointService,   
    private scriptService: ScriptService,
    private config: SiteContextConfig,
    private store: Store<fromApp.AppState>) {
      /** Taking themes from themeservice **/
      this.themeClass = this.theme?.getCurrentTheme();
      //calling recaptcha
     
      this.baseSiteName = this.config?.context?.baseSite;
    if (this.baseSiteName) {
      this.baseSiteNameVal = this.baseSiteName[0];
      // fetching and assigning site keys with the help of base site name
      this.GtaSiteKey = googleTagKeys[this.baseSiteNameVal  as keyof typeof googleTagKeys];
      this.siteKey = googleSiteKeys[this.baseSiteNameVal  as keyof typeof googleSiteKeys];
      this.loadGTAScript();
    }      
   }

  ngOnInit(): void {
    
    this.userSub = this.store.select('auth').subscribe(user => { this.user = user?.user! });
    this.document.body.classList.add(this.themeClass); // adding the theme class to body tag
    this.cmsService.getComponentData('HeaderLogoComponent').subscribe(data => {
      this.logo = data;
    });

    this.cmsService.getComponentData('MobileLogoComponent').subscribe(data => {
      this.headerService.saveheaderMobileLogo(data);
      this.mobileLogo = data;
    });

    //Recaptcha flag assignment
    this.headerService.localPropertiesConfigData.subscribe((resp:any)=>{
      if(resp){
        let recaptchaFlag = resp;  // Removing JSON.parse as the API response is changed from string to JSON in 2211
        this.headerService.recaptchaData(recaptchaFlag?.['spartacus.config.enableRecaptcha'])
      }
    })
    // this.headerService.isLoggedIn.subscribe(response => {
    //   this.userLoggedIn = response;
    //   this.cdr.detectChanges();
    // });

    // this.userLoggedIn.subscribe ((data:any) => {
    //   this.loggedInJson = data;
    // });

    this.cmsService.getContentSlot('PhoneNumberSlot').subscribe((data) => {
      data.components?.forEach((resp) => {
        this.cmsService.getComponentData(resp.uid + '').subscribe((response:any) => {
          this.phoneNumber=response?.url;
          this.headerService.savephoneNumberParagraph(this.phoneNumber);
        });
      });
    });
     

    this.breakpointObserver.observe(['(min-width: 1024px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        document.body.style.overflowY = 'auto';
        if(this.siteLinksDialog) {
          this.siteLinksDialog.close();
        }       
      }
    });

      this.customHttp.get(this.dataUrl).subscribe((data:any) => {
        this.headerService.sendLocalPropertiesConfigData(data);
      });      
  }

  openSiteLinksDialog() {
    if(!this.user){
      this.siteLinksDialog = this.dialog.open(HeaderSitelinksComponent, {
        position: {
          right: '0px',
          top: '0px'
        },
        panelClass: ['header-links', this.themeClass]
      });
    }else{
      document.body.style.overflowY = 'hidden';
      this.headerService.changeSideNavToggle(this.isOpened);
      this.router.navigate(['/my-account']);
    }
    
  }

  removeBackdrop() {
    this.document.getElementById('custom-backdrop')?.classList.remove('show-backdrop');
    this.document.getElementById('custom-backdrop')?.classList.add('hide-backdrop');
  }
  /**
   * This function will load the GTM script 
   */
  loadGTAScript() {
    let srcHead = `(function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "${this.GtaSiteKey}");`
    let srcBody = `<iframe src="https://www.googletagmanager.com/ns.html?id=${this.GtaSiteKey}" height="0" width="0"
    style="display:none;visibility:hidden"></iframe>`
    this.scriptService.loadGTAScript(srcHead, 'head', 'script');
    this.scriptService.loadGTAScript(srcBody, 'body', 'noscript')
  }
    

  ngOnDestroy() {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

}
