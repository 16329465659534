import { Component, Inject, Input } from "@angular/core";
import { DOCUMENT } from '@angular/common';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'bxd-reusable-download-label',
  templateUrl: './bxd-reusable-download-label.component.html'
})

export class BxdReusableDownloadLabelComponent {
  @Input() wroSubmitData: any;
  @Input() programName: any;
  @Input() codeData: any;
  @Input() userEmail: any;

  constructor(
    @Inject(DOCUMENT) private document: Document
  ){}

  // This below function is used to download the creatd wro reciept which is a PDF file that has Barcode and QR code available.
  exportToPDF() {
    const htmlWidth = this.document.getElementById('print')?.clientWidth!;
    const htmlHeight = this.document.getElementById('print')?.clientHeight!;
    const topLeftMargin = 15;
  
    let pdfWidth = 816;
    let pdfHeight = 1056;
    const scale = 3;
  
    const canvasImageWidth = htmlWidth * scale;
    const canvasImageHeight = htmlHeight * scale; 
    const totalPDFPages = 2;
  
    const data = this.document.getElementById('print')!;
    html2canvas(data, { 
      allowTaint: true, 
      scale: scale
    }).then(canvas => {
  
      canvas.getContext('2d');
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jsPDF('p', 'pt', [pdfWidth, pdfHeight]);
      pdf.addImage(imgData, 'png', topLeftMargin, topLeftMargin, canvasImageWidth / scale, canvasImageHeight / scale);
  
      for (let i = 1; i < totalPDFPages; i++) {
        pdf.addPage([pdfWidth, pdfHeight], 'p');
        pdf.addImage(imgData, 'png', topLeftMargin, - (pdfHeight * i) + (topLeftMargin * 4), canvasImageWidth / scale, canvasImageHeight / scale);
      }
  
      pdf.save(`Document ${new Date().toLocaleString()}.pdf`);
    });
  }
}