import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SiteContextConfig, WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { GTMService } from 'src/app/shared/services/common/gtm.service';

@Component({
  selector: 'app-order-detail-list',
  templateUrl: './order-detail-list.component.html',
})
export class OrderDetailListComponent implements OnInit, OnDestroy {
  @Input() orderEntries: any;
  @Input() returnStatus: any;
  siteConfig: any;
  imageUrl: any;
  baseUrl: string;
  subscription: Subscription;
  windowObj: any;
  baseSiteName:any;
  baseSiteNameVal:any;

  constructor(
    private config: SiteContextConfig,
    private route: Router,
    private customHttp: BoxoutEndpointService,
    @Inject(DOCUMENT) private document: Document,
    private windowRef: WindowRef,
    private gtmService: GTMService
  ) {
    this.siteConfig = this.config;
    this.windowObj = this.document.defaultView;
    this.baseSiteName = this.config?.context?.baseSite;
    if(this.baseSiteName){
      this.baseSiteNameVal= this.baseSiteName[0];
    }
  }

  ngOnInit() {
    this.imageUrl = this.siteConfig?.backend?.occ?.baseUrl;
    /** when we need basesite, language and locale in the site URL */
    //this.baseUrl = `${this.config?.context?.baseSite}/${this.config?.context?.language}/${this.config?.context?.currency}`;

    /** when we dont need basesite, language and locale in the site URL - This will not work in local*/
    this.baseUrl = ``;
  }

  handlePOD(item: any) {
    if(item?.consignmentCarrier?.toUpperCase() === "UPS" || item?.consignmentCarrier?.toUpperCase() === "FEDEX"
        || item?.consignmentCarrier?.toUpperCase() === "ONTRAC"){
          this.windowObj.open((item?.consignmentTrackingUrl)+(item?.consignmentTrackingID), '_blank');
    } else if(item?.consignmentCarrier?.toUpperCase() === "GLS") {
        this.windowObj.open((item?.consignmentTrackingUrl), '_blank');
    } else{
      let podDownloadUrl = `users/current/reports/download-pod?trackingId=${item?.consignmentTrackingID}`;
      this.subscription = this.customHttp
        .post(podDownloadUrl)
        .subscribe((podUrls) => {
          if (podUrls && podUrls.length !== 0) {
            podUrls.forEach((resp: any) => {
              this.windowObj.open(resp, '_blank');
            });
          }
        });
    }
    
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription?.unsubscribe();
    }
  }

  navigateToSEO(item: any) {
    // let url: string = this.windowRef.location.origin + path;
    // this.windowRef.location.href = url;
    let gaDataForViewItem = item?.product?.gaItems;
    gaDataForViewItem = { ...gaDataForViewItem, discount: null, index: null , price: item?.basePrice?.value , quantity: 1, item_list_id: 'order_detail_list', item_list_name: 'Order Detail'};
    this.gtmService.setSelectItemData(gaDataForViewItem);
    this.route.navigate([item?.product?.url]);
  }
}
