import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService, SiteContextConfig } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { take } from 'rxjs/operators';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  userPresent:boolean=false;
  user$:any;
  userData:any;
  baseSiteName: any;
  user: User;
  userType: any;
  customerRole:any;
  nonLoggedInUser:boolean=false;

  constructor(private config: SiteContextConfig,
              private store: Store<fromApp.AppState>, private auth: AuthService,
              private userAccount: UserAccountFacade) { // OOTB spartacus has changes the userService functions to UserAccountFacades in sparatcus 6

    this.getBaseSiteName();
  }

   //to get the site name 
  getBaseSiteName(){
    
    let siteName =this.config?.context?.baseSite;
    if(siteName){
      this.baseSiteName = siteName[0];
    }
  }

  //To check if user is not logged-In
  isNonLoggedInUser(){
    this.auth.isUserLoggedIn().pipe(take(1)).subscribe((data:any)=>{
       this.nonLoggedInUser=data;
    });
    return  this.nonLoggedInUser;
  }

  //To check if site is Obagi
  isObagiOrMuSite(){
    if(this.baseSiteName?.toUpperCase() === "OMSITE" || this.baseSiteName?.toUpperCase() === "MUSITE" || this.baseSiteName?.toUpperCase() === "BXDSITE" || this.baseSiteName?.toUpperCase() === "MESITE"){
      return true;
    }else{
      return false;
    }
  }

}
