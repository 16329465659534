<div class="order-credit pt-3">
  <div class="all-credit p-1 cursor-pointer">
    <div
      class="inner-body d-flex p-3 justify-content-start align-items-center"
    >
      <div class="credit-icon-container p-2 text-center d-flex align-items-center justify-content-center">
        <mat-icon
          class="promotion-icon m-1"
          svgIcon="order_credit"
        ></mat-icon>
      </div>

      <div class="content-section">
        <div class="t-order_promo-heading">
          <span class="promo-text text-capitalize">{{ "cart.orderCredit.heading" | cxTranslate }}</span>
        </div>
        <div class="t-order_promo-desc">
          <ng-container>
            {{ rewardsDiscount?.formattedValue }} {{ "cart.orderCredit.applied" | cxTranslate }}
          </ng-container>
        </div>
      </div>
      <div
        class="promotional-slider-cta d-flex flex-column align-items-end justify-content-center"
      >
        <button
          mat-flat-button
          class="apply-remove-credit text-capitalize t-subcategory-normal"
        >
        {{ "cart.orderCredit.view" | cxTranslate }}
        </button>
        <div class="arrow cursor-pointer">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div>
</div>
