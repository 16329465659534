import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { WindowRef } from '@spartacus/core';
import { User } from 'src/app/core/models/user.model';
import { UtilService } from 'src/app/core/services/util.service';
import { account } from 'src/app/feature/pages/my-account/translations/en/account';
import { PageLoaderService } from 'src/app/feature/pages/page-loader/page-loader.service';
import { MedicalInfoComponent } from 'src/app/shared/components/medical-info/medical-info.component';
import { ReusableOrderApprovalModalComponent } from 'src/app/shared/components/reusable-order-approval-modal/reusable-order-approval-modal.component';
import { ReusableRejectOrderModalComponent } from 'src/app/shared/components/reusable-reject-order-modal/reusable-reject-order-modal.component';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { CurrentThemeService } from 'src/app/shared/services/common/current-theme.service';
import { OrderService } from 'src/app/shared/services/common/order.service';
import { SmartyAddressValidationService } from 'src/app/shared/services/common/smarty-address-validation.service';
import { global } from 'src/app/shared/translations/en/global';
import { EditPaymentMethodComponent } from './../edit-payment-method/edit-payment-method.component';
import { EditPoComponent } from './../edit-po/edit-po.component';

@Component({
  selector: 'app-order-detail-status',
  templateUrl: './order-detail-status.component.html'
})
export class OrderDetailStatusComponent implements OnInit, OnChanges, OnDestroy {
  @Input() orderStatus: any;
  @Input() orderDetails: any;
  @Input() workflowActionCode: any;
  @Input() user: User;
  @Input() returnStatus:any;
  @Input() isCreator: boolean;
  @Input() modalObj?: any;
  @Input() directApproval?: boolean;
  @Input() editOrderFlag?: boolean; 
  @Input() editOrderCartId?: any;
  @Input() isBillToInvoice?: boolean;
  @Output() triggerPrint: EventEmitter<any> = new EventEmitter();
  @Output() pageHeading: EventEmitter<any> = new EventEmitter();
  @Output() triggerApi: EventEmitter<any> = new EventEmitter();
  @Output() editPOSuccess: EventEmitter<any> = new EventEmitter();
  @Output() editPOFail: EventEmitter<any> = new EventEmitter();
  @Output() editPaymentSuccess: EventEmitter<any> = new EventEmitter();
  @Output() editPaymentFail: EventEmitter<any> = new EventEmitter();
  showEditPO: boolean = false;
  showEditPayment: boolean = false;
  showEditBtn: boolean = false;
  showApproveBtn: boolean = false;
  showRejectBtn: boolean = false;
  showCloseBtn: boolean = false;
  showCancelBtn: boolean = false;
  showResubmitBtn: boolean = false;
  orderConstants = account.account.order;
  status: any;
  currentTheme: any;
  editPODialogRef: any;
  editPaymentDialogRef: any;
  account = account.account.order;
  workFlowAction: any;
  routeSub: any;
  windowObj: any;
  success: boolean = false;
  noPOD: boolean =false;

  translations = global.account.order;
  noPodMsg:any= "";
  medicalInfoSub: any;
  medicalInfoPopupTriggeredFrom: any = '';
  
  constructor(
    public dialog: MatDialog,
    private theme: CurrentThemeService,  
    private customHttp: BoxoutEndpointService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private orderService: OrderService,
    @Inject(DOCUMENT) private document: Document,
    private pageLoaderService:PageLoaderService,
    private utilService: UtilService,
    private smartyAddressValidationService: SmartyAddressValidationService,
    private windowRef: WindowRef
    ) {this.windowObj = this.document.defaultView; }

  ngOnInit(): void { 
    this.status = this.orderStatus?.toLowerCase().replace('.', ' ');
    this.showRequiredButtons();
    this.currentTheme = this.theme?.getCurrentTheme();
    this.routeSub = this.activatedRoute.params.subscribe((params) => {
      if(params)
      this.workFlowAction = params["workflowActionCode"];
    });


    this.medicalInfoSub = this.smartyAddressValidationService.editOrderData$.subscribe((sentData:any)=>{
      if (!(JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("isApprovalPopupOpened"))))) {
        if(sentData && sentData.componentName === 'edit-order' && this.medicalInfoPopupTriggeredFrom === 'approve') {
          this.windowRef.sessionStorage?.setItem("isApprovalPopupOpened", "true");
          this.approveOrder();
        }

        if(sentData && sentData.componentName === 'edit-order' && this.medicalInfoPopupTriggeredFrom === 'resubmit') {
          this.resubmitOrder();
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.orderStatus && changes.orderStatus.currentValue) {
      this.status = this.orderStatus?.toLowerCase().replace('.', ' ');
      this.showRequiredButtons();
    }
  }

  print() {
    this.triggerPrint.emit(true);
  }

  //DO NOT DELETE any commented code - needed for further development
  showRequiredButtons() {
    if (this.status === this.orderConstants.unapproved) {
      if (this.user?.displayUid === this.orderDetails?.orderedBy) {
        this.pageHeading.emit(this.orderConstants.reviseOrCloseOrder);
        this.showAndHideButtons(false, false, true, false, false, false, true, false);
        if(this.editOrderFlag) {
          this.editOrder();
        }
      } else {
        this.pageHeading.emit(this.orderConstants.orderInfo);
        this.showAndHideButtons(false, false, false, false, false, false, false, false);
      }
    } else if (this.status === this.orderConstants.pendingApproval) {
      if (this.user?.customerRole === this.orderConstants.purchaser) {
        this.pageHeading.emit(this.orderConstants.orderInfo);
      } else if (this.user?.customerRole === this.orderConstants.approver) {
        if (this.isCreator) {
          this.pageHeading.emit(this.orderConstants.orderInfo);
        } else {
          this.pageHeading.emit(this.orderConstants.orderRequiringApproval);
          if(this.orderDetails?.isPromotionApplied || this.orderDetails?.isRewardsApplied) {
            this.showAndHideButtons(true, false, false, true, true, false, false, false);
          } else {
            this.showAndHideButtons(true, false, true, true, true, false, false, false);
          }
          if(this.editOrderFlag) {
            this.editOrder();
          }
        }
      } else if (this.user?.customerRole === this.orderConstants.admin) {
        if (this.isCreator) {
          this.pageHeading.emit(this.orderConstants.orderInfo);
        } else {
          this.pageHeading.emit(this.orderConstants.orderRequiringApproval);
          if(this.orderDetails?.isPromotionApplied || this.orderDetails?.isRewardsApplied) {
            this.showAndHideButtons(true, false, false, true, true, false, false, false);
          } else {
            this.showAndHideButtons(true, false, true, true, true, false, false, false);
          }
          if (this.editOrderFlag) {
            this.editOrder();
          }
        }
      }
    } else {
      this.pageHeading.emit(this.orderConstants.orderInfo);
      this.showAndHideButtons(false, false, false, false, false, false, false, false);
    }
  }

  editOrder() {
    if (this.router.url === `/my-account/orderApprovalDetails/${this.orderDetails?.code}/${this.workflowActionCode}`) {
      this.orderService.setPreviousPage('approval');
    } else if (this.router.url === `/my-account/order/${this.orderDetails?.code}`) {
      this.orderService.setPreviousPage('order');
    }
    if (this.status === this.orderConstants.unapproved) {
      if(this.workflowActionCode){
        this.router.navigate(['my-account/order/editApprovalOrderDetails', this.orderDetails?.code, this.workflowActionCode]);
        this.showAndHideButtons(true, true, false, false, false, true, false, true);
      }

    } else if (this.status === this.orderConstants.pendingApproval) {
      if(this.workflowActionCode){
        this.router.navigate(['my-account/order/editApprovalOrderDetails', this.orderDetails?.code, this.workflowActionCode]);
        this.showAndHideButtons(true, true, false, true, false, true, false, false);
      }
    }
  }

  rejectOrder() {
    const dialogRef = this.dialog.open(ReusableRejectOrderModalComponent, {
      panelClass: [this.currentTheme],
      width: "551px",
      autoFocus: false,
      data: { obj: this.modalObj, isPromotionApplied: this.orderDetails?.isPromotionApplied, isRewardsApplied: this.orderDetails?.isRewardsApplied }
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result.message === this.account.success){
        this.router.navigate([`my-account/order/${result.orderCode}`]);
      } else if(!result.success){
        // error message to be implemented
      }
    });
  }

  approveOrder() {
    const dialogRef = this.dialog.open(ReusableOrderApprovalModalComponent, {
      panelClass: [this.currentTheme],
      width: "551px",
      autoFocus: false,
      disableClose: true,
      data: { obj: this.modalObj, directApproval: this.directApproval, cartId: this.editOrderCartId }
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      this.windowRef.sessionStorage?.removeItem("isApprovalPopupOpened");
      if(result.message === this.account.success){
        this.router.navigate([`my-account/order/${result.orderCode}`]);
      } else if(result.message === this.account.fail){
        // error message to be implemented
      }
    });
  }

  cancelOrder() {
    this.approvalDecisionApi('CANCEL').subscribe((data: any) => {
        if (data.success === true) {;
          this.triggerOrdersApi();
        }
    });
  }

  resubmitOrder() {
    this.approvalApi('EDITANDAPPROVE').subscribe((data: any) => {
        if (data.success) {
          this.router.navigate([`my-account/order/${data?.orderCode}`]);     
        }
    },
    () => {
    });
  }

  approvalApi(decision: string){
    let req = {
      additionalComments: '',
      approverSelectedDecision: decision,
      orderCode: this.orderDetails?.code,
      workFlowActionCode: this.workflowActionCode,
      cartId: this.editOrderCartId
    }
    let apiUrl=`order/approvalDecision/`;
    return this.customHttp.post(apiUrl,req);
  }

  closeOrder() {
    const previousPage = this.orderService.getPreviousPage();
    if(previousPage === 'approval') {
      this.router.navigate(['my-account/orderApprovalDetails', this.orderDetails?.code, this.workflowActionCode])
    } else if(previousPage === 'order') {
      this.router.navigate(['my-account/order', this.orderDetails?.code]);
    } else {
      this.router.navigate(['my-account/approvalOrders']);
    }
  }

  openEditPoModal() {
    this.editPODialogRef = this.dialog.open(EditPoComponent, {
      width: "550px",
      panelClass: ['edit-po-modal', this.currentTheme],
      data: {
        purchaseOrderNumber: this.orderDetails?.purchaseOrderNumber,
        orderCode: this.editOrderFlag ? this.editOrderCartId : this.orderDetails?.code,
        workFlowActionCode: this.workflowActionCode,
        pageType: this.editOrderFlag ? this.orderConstants.editOrder : this.orderConstants.reqApproval
      }
    });
    this.editPODialogRef.afterClosed().subscribe((result:any) => {
      if(result.message === this.account.success){
        this.editPOSuccess.emit(`Your Purchase Order has been updated from ${result.currentPO} to ${result.revisedPO}`);
        this.triggerOrdersApi();
      } else if(result.message === this.account.fail){
        this.editPOFail.emit(this.account.somethingWentWrong);
      }
    });
  }

  openEditPaymentModal() {
    this.editPaymentDialogRef = this.dialog.open(EditPaymentMethodComponent, {
      width: "550px",
      panelClass: ['edit-payment-modal', this.currentTheme],
      data: {
        userId: this.user?.uid,
        orderCode: this.editOrderFlag ? this.editOrderCartId : this.orderDetails?.code,
        pageType: this.editOrderFlag ? this.orderConstants.editOrder : this.orderConstants.reqApproval,
        paymentMethod: this.orderDetails?.paymentMethod,
        paymentDetails: this.orderDetails?.paymentInfo,
        isBillToInvoice: this.isBillToInvoice
      }
    });

    this.editPaymentDialogRef.afterClosed().subscribe((result:any) => {
      if(result.message === this.account.success){
        this.editPaymentSuccess.emit(this.account.paymentMethodUpdated);
        this.triggerOrdersApi();
      } else if(result.message === this.account.fail){
        this.editPaymentFail.emit(this.account.somethingWentWrong);
      }
    });
  }

  approvalDecisionApi(decision: string){
    let req = {
      approverSelectedDecision: decision,
      orderCode: this.orderDetails?.code,
      workFlowActionCode: this.workflowActionCode
    }
    let apiUrl=`order/approvalDecision/`;
    return this.customHttp.post(apiUrl,req);
  }

  triggerOrdersApi() {
    this.triggerApi.emit(true);
  }
  
  //reorder 
  reOrder(){
    this.router.navigate(['/reorder', this.orderDetails?.code]);
  }

  showAndHideButtons(
    editPO:boolean,
    editPayment:boolean,
    edit:boolean,
    approve:boolean,
    reject:boolean,
    close:boolean,
    cancel:boolean,
    resubmit:boolean
    ) {
  this.showEditPO = editPO;
  this.showEditPayment = editPayment;
  this.showEditBtn = edit;
  this.showApproveBtn = approve;
  this.showRejectBtn = reject;
  this.showCloseBtn = close;
  this.showCancelBtn = cancel;
  this.showResubmitBtn = resubmit;
  }

  podDownload() {
    this.noPOD=false;
    this.success = false
    let podDownloadUrl = `users/current/reports/download-pod?orderCode=${this.orderDetails.code}`;
    this.customHttp.post(podDownloadUrl).subscribe((podUrls)=> {
      if(podUrls && podUrls.length !== 0) {
        podUrls.forEach((resp:any) => {
          this.windowObj.open(resp, '_blank');
        })
      }else if(podUrls && podUrls.length === 0){
        this.noPOD = true;
        this.noPodMsg=this.translations.noPodMessageOne +this.utilService.checkBaseSiteForEmailSupport() + this.translations.noPodMessageTwo;
      }
    });
  }

  email(){
    this.noPOD=false;
    this.success = false
    let emailUrl =`/triggerOrderPlacedNotification?orderCode=${this.orderDetails?.code}`;
    this.customHttp.get(emailUrl).subscribe((data:any)=> {
      if(data.success === true){
        this.success = true
      }
    });
  }

  medicalInformationCheck = (trigger:any) => {

    this.medicalInfoPopupTriggeredFrom = trigger;
    this.windowRef.sessionStorage?.setItem('medicalInformationFormRef' , 'edit-order');
    this.windowRef.sessionStorage?.setItem('medicalInformationCartId' , this.orderDetails?.cartId);

    const dialogRef = this.dialog.open(MedicalInfoComponent,
      {
        panelClass: ['medical-info-dialog'],
        autoFocus: false,
        disableClose: true
      },
    );
  }

  ngOnDestroy() {
    if(this.medicalInfoSub) {
      this.medicalInfoSub.unsubscribe();
    }
    
    if(this.windowRef.sessionStorage?.getItem("medicalInformationCartId")) {
      this.windowRef.sessionStorage?.removeItem("medicalInformationCartId")
    }

    if(this.windowRef.sessionStorage?.getItem("medicalInformationFormRef")) {
      this.windowRef.sessionStorage?.removeItem("medicalInformationFormRef")
    }
  }

}


