import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'formatWarehoseData'
})
export class formatWarehoseDataPipe implements PipeTransform {
    transform(originalData: string): string {
      let splitData = originalData?.split("_");
      let transormData = splitData[0].toUpperCase();
      return transormData;
    }
}