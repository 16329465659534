<div class="edit-po">
  <div class="d-flex flex-row justify-content-between mb-4">
    <div class="t-title-l mb-0">{{ "account.po.header" | cxTranslate }} {{ orderNumber }}</div>
    <div class="mb-0 pt-1">
      <button mat-flat-button color="primary" [mat-dialog-close]="true" class="close sitelinks-close">
        <mat-icon> {{'header.close' | cxTranslate }}</mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="currentPO && revisedPO && currentPO === revisedPO" class="rounded">
    <reusable-alert-box
      [alertType]="'warningAlertBox'"
      alertMsg="{{
        'account.po.equalMessage' | cxTranslate
      }}"
    ></reusable-alert-box>
  </div>
  
  <div *ngIf="currentPO">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{'account.po.currentPO' | cxTranslate}}</mat-label>
      <input matInput id="currentPO" [(ngModel)]="currentPO" maxlength="50" (paste)="checkSpecChar($event)" (keypress)="checkSpecChar($event)">
    </mat-form-field>
    <mat-error *ngIf="!currentPO?.length">{{ "account.po.currentPOMessage" | cxTranslate}}</mat-error>
  </div>
  
  <div class="mt-4">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{'account.po.revisedPO' | cxTranslate}}</mat-label>
      <input matInput id="revisedPO" [(ngModel)]="revisedPO" maxlength="50" (paste)="checkSpecChar($event)" (keypress)="checkSpecChar($event)">
    </mat-form-field>
    <mat-error *ngIf="revisedPO !== undefined && !revisedPO?.length">{{ "account.po.revisedPOMessage" | cxTranslate}}</mat-error>
  </div>
  <div class="d-flex flex-row-reverse mt-4 mb-3">
    <div>
      <button mat-flat-button color="primary" (click)="saveChanges()"
        class="">
        {{"account.po.saveChanges"| cxTranslate}}
      </button>
    </div>
    <div class="mr-5 mt-2 mb-5 pt-1">
      <a [mat-dialog-close]="true" class="cursor-pointer">{{"account.po.cancel"| cxTranslate}}</a>
    </div>
  </div>
</div>