import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject, BehaviorSubject } from 'rxjs';
import { SiteContextConfig, WindowRef } from '@spartacus/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class PortalBxdServiceService {
  public valueSource = new BehaviorSubject<any>(null);
  public responsiveValueSource = new BehaviorSubject<any>(null);
  // currentValue = this.valueSource.asObservable();
  constructor(private windowRef: WindowRef, private cookieService: CookieService,) {
  }

  // getSessionStorageValue(key: string): any {
  //   console.log('openSideNav in service', Boolean(this.windowRef.sessionStorage?.getItem('openSidenav')));
  //   return Boolean(this.windowRef.sessionStorage?.getItem('openSidenav'));
  // }

  public emitReusbaleFilter = new BehaviorSubject<any>(null);
  public bxdDynamicColumn = new BehaviorSubject<any>(null);
  public redirectToInventory = new BehaviorSubject<any>(null);

  changeValue(value: any) {
    this.valueSource.next(value);
  }
  changeResponsiveness(value: any) {
    this.responsiveValueSource.next(value);
  }

  resetStorage() {
    this.windowRef.localStorage?.removeItem('bxdLoggedInUserEmail');
    this.windowRef.localStorage?.removeItem('b2bUnit');
    this.windowRef.localStorage?.removeItem('isLoggedIn');
    this.windowRef.localStorage?.removeItem('loggedInFrom');
    this.windowRef.sessionStorage?.removeItem('cartId');
    this.windowRef.sessionStorage?.removeItem('viewBy');
    this.windowRef.localStorage?.removeItem('multipleB2bUnitFlag');
    this.windowRef.localStorage?.removeItem('goToUrl');
    this.cookieService.delete('ACCESS_TOKEN');
  }
}
