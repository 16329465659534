import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { ClearCartComponent } from 'src/app/feature/components/clear-cart/clear-cart.component';
import { SectionLoaderService } from 'src/app/feature/pages/loader/section-loader.service';
import { AddressService } from 'src/app/shared/services/common/address.service';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { CurrentThemeService } from '../../services/common/current-theme.service';
import { SmartyAddressValidationService } from '../../services/common/smarty-address-validation.service';
import { ReusableAddShipToAddressComponent } from '../reusable-add-ship-to-address/reusable-add-ship-to-address.component';
@Component({
  selector: 'app-business-addresses',
  templateUrl: './business-addresses.component.html'
})
export class BusinessAddressesComponent implements OnInit, OnDestroy{
  @Input() page:number;
  @Input() totalRecordsOnPage:number;
  @Input() manageClientType:any;
   viewAddress=false;
   isAdmin: boolean = false;
   isManageAccount: any;
   openAddress:any;
   viewAddresses:any=[];
  //  private _dataUrl = '../../../../../assets/mock-json/view-address.json';
   @Input() addresses: any=[];
   @Input() uid:any={};
   @Input() isPaginate:boolean;
   @Input() componentName:any;
   @Input() totalPages:any;
   @Input() manageAcctSiebelRowId: any;
   @Input() manageAccountType:any;
   @Input() editBillTo:any;
   user: User;
   userSub: Subscription;
   userId: string;
   siebelRowId: string;
   orgUnitId: string;
   siteName: string | string[];
   viewAddressId: any;
   viewAddressAccountType:any;

   showSuccess:boolean=false;
   showFailure:boolean=false;
   editThisAddress:any;
   closeEditAddressBlockVal:boolean=false;

   isShipTo:boolean=false;
   isBillTo:boolean=false;

   currentTheme: any;
   @Input() manageAccountData:any;
   selection = new SelectionModel<any>(true, []);
   @Output() public sendError = new EventEmitter();
   @Output() public refreshData = new EventEmitter();

  constructor(
    private headerService: HeaderService, 
    private cdr: ChangeDetectorRef,
    private router: Router,
    private sectionLoaderService:SectionLoaderService,
    private addressService: AddressService,
    private customHttp:BoxoutEndpointService,
    private smartyAddressValidationService: SmartyAddressValidationService,
    public dialog: MatDialog,
    private theme: CurrentThemeService,
    private windowRef: WindowRef,
    private store: Store<fromApp.AppState>
    ) { }
   


  ngOnInit(): void {
    this.userSub = this.store.select('auth').subscribe(user => { 
      this.user = user && user.user!;
      this.userId = this.user?.uid!;
      this.siebelRowId = this.user?.orgUnit?.siebelRowId!;
      this.orgUnitId = this.user?.orgUnit?.uid!;
    });
    this.headerService.isAdmin.subscribe(response => {
      this.isAdmin = response;
    });

    this.headerService.isManageAccount.subscribe((response) => {
      this.isManageAccount = response;
    });
    // this.headerService.isLoggedIn.subscribe((response) => {
    //   this.userData = response;
    // });

    this.siteName=this.headerService.getCurrentSite();

    this.smartyAddressValidationService.saveBusinessAccountAlertData$.subscribe((val)=>{
      this.showSuccess=val;
      this.showFailure=!val;

      // to hide the address on success call
      if(this.showSuccess){
        this.onHideAddressClick();
      }

      //to scroll to top of window if we get any alert message
    if (this.showSuccess || this.showFailure) {
      this.windowRef.nativeWindow?.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
    });

    this.smartyAddressValidationService.saveClientAccountAlertData$.subscribe((val)=>{
      this.showSuccess=val;
      this.showFailure=!val;
      
      // to hide the address on success call
      if(this.showSuccess){
        this.onHideAddressClick();
      }

      //to scroll to top of window if we get any alert message
    if (this.showSuccess || this.showFailure) {
        this.windowRef.nativeWindow?.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
    }
    });

    
    
      /** Taking themes from themeservice **/
      this.currentTheme = this.theme?.getCurrentTheme();

      // this.userData?.subscribe((data: any) => {
      //   this.userDataDetails = data;
      // });
  }



  redirectToOrderHistory(accountsData:any){
    
   
    let orderHistoryData={
      accountData: 
      {id: accountsData.uid, name: accountsData.name,isShipToClient:false},
      dateRange:'',
      endDate:'',
      orderNumber:'',
      orderStatus:'',
      startDate:''
    }
    if(this.componentName==='myClientAddress'){
      orderHistoryData.accountData.isShipToClient=true;
    }
    sessionStorage.setItem('orderHistorySearch',JSON.stringify(orderHistoryData));
  this.router.navigate(["my-account/orderHistory"])

  }
  onManageAcctClick(acct: any) {
    this.windowRef.sessionStorage?.setItem('acctId', acct.id);
    if(this.componentName === 'myClientAddress'){
      this.router.navigate(['/my-account/manageClientsAccount'],{queryParams:{id:acct?.id}});
    }else{
      this.router.navigate(['/my-account/manageAccount'],{queryParams:{id:acct?.id}});
    }
  }

  onViewAddressClick(item?:any){
    this.viewAddressId=item?.id;
    this.viewAddressAccountType=item?.accountType;
    // this.userData?.subscribe((data: any) => {
      // this.userId = data?.uid;
      // this.siebelRowId =data?.orgUnit?.siebelRowId;
      // this.orgUnitId=data?.orgUnit?.uid;
      
        if((this.componentName == 'myClientAddress') || (this.componentName == 'manageClientAddress')){
          this.getClientViewAddress(item);
        } else if (this.componentName == 'manageAcct') {
          this.getManageAcctAddress(item);
        }else{
          if(this.userId ){
            
    this.sectionLoaderService.show();
            let apiURL=`users/${this.userId}/orgUnits/${item.id}/addresses`;
          // let apiURL=`users/mark.rivers@rustic-hw.com/orgUnits/boxout Custom Retail/addresses`     
            this.customHttp.get(apiURL).subscribe((resp:any) => {
          if(resp) {   
            
    this.sectionLoaderService.hide();
            //Filtering out the shipto and bill to
            resp.addresses = resp.addresses.filter((resp:any)=> resp.id != item?.shipTo?.id);
            if(item?.billTo?.id){
              resp.addresses = resp.addresses.filter((resp:any)=> resp.id != item?.billTo?.id);
            }
            this.viewAddresses =  resp.addresses;
            this.openAddress = item.id;
            if(this.viewAddresses){
              this.viewAddress=true;
            }
            this.cdr.detectChanges();
          }
        },(error)=>{
          
    this.sectionLoaderService.hide();
        });
      }
      }
      
    // });
    
  }
  onHideAddressClick() {
    this.viewAddress = false;
    this.openAddress = null;
    this.viewAddresses = [];
  }

  getPage(page: any) {
  }
  getClientViewAddress(item: any) {
    
    this.sectionLoaderService.show();
    let apiURL = `users/${this.userId}/orgUnits/${this.siebelRowId}/clients/${item.id}/addresses`;
    this.addressService
      .getClientViewAddresses(apiURL)
      .subscribe((data: any) => {
        if (data) {
          
    this.sectionLoaderService.hide();
          //Filtering out the shipto and bill to
          this.openAddress = item.id;
          data.addresses = data.addresses.filter((resp:any)=> resp.id != item?.shipTo?.id);
          if(item?.billTo?.id){
            data.addresses = data.addresses.filter((resp:any)=> resp.id != item?.billTo?.id);
          }
          this.viewAddresses =  data.addresses;
          if(this.viewAddresses){
            this.viewAddress=true;
          }
          this.cdr.detectChanges();
        }
      },(error)=>{
        
    this.sectionLoaderService.hide();
      });
  }

  getManageAcctAddress(item: any) {
    
    this.sectionLoaderService.show();
          if(this.userId && this.manageAcctSiebelRowId){
            let apiURL=`users/${this.userId}/orgUnits/${this.manageAcctSiebelRowId}/addresses`;
          // let apiURL=`users/mark.rivers@rustic-hw.com/orgUnits/boxout Custom Retail/addresses`     
            this.customHttp.get(apiURL).subscribe((data:any) => {
          if(data) {   
            
    this.sectionLoaderService.hide();
            //Filtering out the shipto and bill to
            data.addresses = data.addresses.filter((resp:any)=> resp.id != item?.shipTo?.id);
            if(item?.billTo?.id){
              data.addresses = data.addresses.filter((resp:any)=> resp.id != item?.billTo?.id);
            }
            this.viewAddresses =  data.addresses;    
            this.openAddress = item.id || this.manageAcctSiebelRowId;
            if(this.viewAddresses){
              this.viewAddress=true;
            }
            this.cdr.detectChanges();
          }
        },(error)=>{
          
    this.sectionLoaderService.hide();
        });
      }
  }


  // this method is used to open add ship to address dialog when user clicks on button Add ShipTo
  openAddShipToAddressDialog(item:any){
      //setting form data null for avoiding multiple call 
    this.smartyAddressValidationService.saveFormData(null);
    let defaultShippgTo = (this.componentName === "myClientAddress" && !(item?.shipTo)) ||
      (this.componentName === "manageClientAddress" && !(item?.shipTo)) ||
      (item?.accountType === "DROPSHIP" && !(item?.shipTo)) ||
      (this.manageAccountData?.accountType === "DROPSHIP" && !(item?.shipTo)) ||
      (item?.accountType === "PARENT" && !(item?.shipTo)) ||
      (this.manageAccountData?.accountType === "PARENT" && !(item?.shipTo)) ||
      (item?.accountType === "DIRECT" && !(item?.shipTo)) ||
      (this.manageAccountData?.accountType === "DIRECT" && !(item?.shipTo)) ? true : false;
    
    let defaultBillingTo = (item?.accountType === "PARENT" && !(item?.shipTo)) ||
      (this.manageAccountData?.accountType === "PARENT" && !(item?.shipTo)) ||
      (item?.accountType === "DIRECT" && !(item?.shipTo)) ||
      (this.manageAccountData?.accountType === "DIRECT" && !(item?.shipTo)) ? true : false;

      
      this.dialog.open(ReusableAddShipToAddressComponent,
        {
          panelClass: [this.currentTheme, 'business-account-dialogs-style'],
          autoFocus: false,
          disableClose: true,
          data:  {
            value:{
              addAddressId:item.id ? item.id : this.manageAcctSiebelRowId,
              componentName:this.componentName,
              accountType: item.accountType,
              manageAccountType: this.manageAccountType,
              defaultShippgTo : defaultShippgTo,
              defaultBillingTo : defaultBillingTo
            }
         }
        });

  }

  // this method is used to open section below address block with prefilled data to enable user to edit ship To address
  editShipToAddressButtonClicked(item:any){

    //setting form data null for avoiding multiple call 
    this.smartyAddressValidationService.saveFormData(null);

    this.editThisAddress=item.shipTo.id;
    this.isShipTo=true;
    this.closeEditAddressBlockVal=false;
  }

// this method is used to open section below address block with prefilled data to enable user to edit bill To address
  editBillToAddressButtonClicked(item:any){

    //setting form data null for avoiding multiple call 
    this.smartyAddressValidationService.saveFormData(null);

    this.editThisAddress=item.billTo.id;
    this.isBillTo=true;
    this.closeEditAddressBlockVal=false;
  }

  // this method is used to open section below address block with prefilled data to enable user to addresses inside View Addresses
  editAddressButtonClicked(item:any){

    //setting form data null for avoiding multiple call 
    this.smartyAddressValidationService.saveFormData(null);
    
    this.editThisAddress=item.id;
    this.closeEditAddressBlockVal=false;
  }

  closeEditAddressBlock(e:any){
    this.closeEditAddressBlockVal=e;
    this.isShipTo=false;
    this.isBillTo=false;
  }

  closeViewAddress(e:any){
    this.onHideAddressClick();
  }

  removeShipTo(item: any) {
    let shipToId = [];
    shipToId.push({'id': item.id});
    const dialogRef = this.dialog.open(ClearCartComponent, {
      panelClass: 'clear-cart-dialog',
      data: {
        cartId: sessionStorage.getItem('cartId'),
        userId: this.user?.uid,
        componentname: 'Client',
        orgUnit: this.siebelRowId,
        shipTo: shipToId
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.selection.clear();
      if(result.status === 'success' && result.data && result.data.isSuccess) {
        this.refreshData.emit();
      } else if(result.status === 'failure') {
        this.sendError.emit();
      }
    })
  }


  ngOnDestroy() {
    this.showSuccess=false;
      this.showFailure=false;
      this.closeEditAddressBlockVal=false;
      this.isShipTo=false;
      this.isBillTo=false;
  }


}
