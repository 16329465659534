import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-cart-informative-modal',
  templateUrl: './cart-informative-modal.component.html',
})
export class CartInformativeModalComponent {
  warehouseModal: boolean = true;
  shippingModal: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public modaltype: any) {
    if (modaltype === 'wh') {
      this.warehouseModal = true;
      this.shippingModal = false;
    } else if (modaltype === 'shipping' || modaltype === 'tax') {
      this.shippingModal = true;
      this.warehouseModal = false;
    }
  }
}
