<section class="order-info-card">
<div [ngClass]=" componentName === 'orderConfirmation' ? 'order-confirm-width' : 'w-100' ">
<div class="d-flex flex-column flex-md-row order-detail-sub pl-2 pl-md-0 justify-content-between">

  <div class="d-flex ml-md-2 flex-column pb-3">
    <div>
      <p class="t-label-s mb-0 mt-3">
        {{ "account.order.orderNo" | cxTranslate }}
      </p>
      <p class="t-title-m">{{ orderDetails?.code || '-'}}</p>
    </div>
    <div>
      <p class="t-label-s mb-0">
        {{ "account.order.orderedOn" | cxTranslate }}
      </p>
      <p class="t-title-m">{{orderDetails?.created| date: 'mediumDate'}} at {{orderDetails?.created| date: 'shortTime'}}</p>
    </div>
    <div>
      <p class="t-label-s mb-2">
        {{ "account.order.orderedVia" | cxTranslate }} {{orderDetails?.orderdVia || '-'}}
      </p>
      <p *ngIf="orderDetails?.siebelOrderNumber" class="t-label-s mb-0">
        {{ "account.order.sysOrderId" | cxTranslate }} {{orderDetails?.siebelOrderNumber}}
      </p>
    </div>
  </div>
  <div class="d-flex flex-column pb-3">
    <div *ngIf="orderDetails?.purchaseOrderNumber">
      <p class="t-label-s mb-0 mt-3">
        {{ "account.order.purchaseOrder" | cxTranslate }}
      </p>
      <p class="t-title-m m-0">{{orderDetails?.purchaseOrderNumber}}</p>
    </div>
    <div>
      <p class="t-label-s mb-0 mt-3">
        {{ "account.order.paymentMethod" | cxTranslate }}
      </p>
      <p class="t-title-m m-0" *ngIf="orderDetails?.paymentMethod?.toLowerCase() === 'bill'">{{ "account.order.invoice" | cxTranslate }}</p>
      <p class="t-title-m m-0" *ngIf="orderDetails?.paymentMethod?.toLowerCase() === 'credit card' && orderDetails?.paymentInfo?.cardType">
        {{ orderDetails?.paymentInfo?.cardType?.name + ' ' + orderDetails?.paymentInfo?.cardNumber?.substr(orderDetails?.paymentInfo?.cardNumber?.length - 4) }}
      </p>
      <p class="t-title-m m-0" *ngIf="!orderDetails?.paymentMethod || 
      (!orderDetails?.paymentInfo?.cardType && orderDetails?.paymentMethod?.toLowerCase() !== 'bill') ">
        {{ '-' }}
      </p>
    </div>
    <div>
      <p class="t-label-s mb-0 mt-3">
        {{ "account.order.orderedBy" | cxTranslate }}
      </p>
      <!-- changes for BCGI2-3498 is below-->
      <!-- <p class="t-title-s m-0">{{orderDetails?.user?.name || '-'}}</p> -->
      <p class="t-title-s m-0">{{orderDetails?.orderedBy || '-'}}</p>
    </div>
  </div>
  <div class="d-flex mr-3 flex-column pb-3">
    <div *ngIf="orderDetails?.clientPONumber">
      <p class="t-label-s mb-0 mt-3">
        {{ "account.order.clientPO" | cxTranslate }}
      </p>
      <p class="t-title-m m-0">{{orderDetails?.clientPONumber}}</p>
    </div>
    <div *ngIf="orderDetails?.clientSalesOrderNumber">
      <p class="t-label-s mb-0 mt-3">
        {{ "account.order.clientSalesOrder" | cxTranslate }}
      </p>
      <p class="t-title-m m-0">{{orderDetails?.clientSalesOrderNumber}}</p>
    </div>
    <div *ngIf="orderDetails?.approvedBy">
      <p class="t-label-s mb-0 mt-3">
        {{ approvedBy }}
      </p>
      <p *ngIf="orderDetails?.approvedName" class="t-title-s m-0">{{orderDetails?.approvedName}}</p>
      <p class="t-title-s m-0">{{orderDetails?.approvedBy}}</p>
    </div>
    <div *ngIf="orderDetails?.rejectedBy">
      <p class="t-label-s mb-0 mt-3">
        {{ rejectedBy }}
      </p>
      <p *ngIf="orderDetails?.rejectedName" class="t-title-s m-0">{{orderDetails?.rejectedName}}</p>
      <p class="t-title-s m-0">{{orderDetails?.rejectedBy}}</p>
    </div>
  </div>
</div>
</div>
</section>