<div class="d-flex flex-column warning-container align-items-center justify-content-center p-4">

  <div class="warning-icon mb-3">
    <mat-icon
    svgIcon="warningIcon"
    class="warning-icon-style"
    aria-label="Warning Icon"
  ></mat-icon>
  </div>

  <div class="t-headline-m mb-3">{{"account.manageFavoriteList.warningTitle" | cxTranslate}}</div>

  <div class="warning-description t-title-m mb-3 d-flex justify-content-center">{{"account.createFavoriteList.warningMsgOne" | cxTranslate}} {{totalItemToBeRemoved}} {{"account.createFavoriteList.warningMsgTwo" | cxTranslate}}</div>

  <div class="d-flex flex-row">
    <div>
      <button mat-flat-button color="primary" type="button" class="mr-3 " [mat-dialog-close]="true"> {{"account.createFavoriteList.cancelButton" | cxTranslate}}</button>
    </div>
    <div>
      <button mat-flat-button color="white" type="button" (click)="removeFavoriteProducts()">
        {{"account.registerForm.continue" | cxTranslate}}
      </button>
    </div>
  </div>

</div>