<div
  class="line-promotion d-flex flex-grow-0 flex-wrap flex-sm-nowrap px-3 py-4 ml-3 mr-3 mb-4"
>
  <mat-icon
    class="promotion-icon align-self-start"
    svgIcon="promotion"
  ></mat-icon>
  <div class="content-section">
    <div class="t-line_promo-heading mb-2">
      <ng-container *ngIf="!canApply; else appliedPromo">
        <div class="d-flex flex-column">
          <span class="promo-text text-capitalize">{{ "cart.linePromo.promoAvailable" | cxTranslate }}</span>
          <span class="mt-1">{{ promotion.title }}</span>
        </div>
      </ng-container>
      <ng-template #appliedPromo>
        <span class="promo-text text-capitalize">{{
          (promotion.status === "Applied" 
            ? "cart.linePromo.promoApplied" 
            : "cart.linePromo.promo"
            ) | cxTranslate 
          }}</span> 
          | {{ promotion.title }}
      </ng-template>
    </div>    
    <div class="t-line_promo-desc mt-1">
      <span *ngIf="promotion?.description" [innerHTML] = "promotion.description"></span> <span *ngIf="promotion?.description2"> | </span>
      <span *ngIf="promotion?.description2" [innerHTML] = "promotion.description2"></span>
    </div>
  </div>
  <button
  *ngIf="!canApply"
  mat-flat-button
  color="primary"
  class="apply-remove-promo t-label-l text-nowrap align-self-center ml-auto mr-0 m-sm-auto"
  (click)="updateCart()"
  >
  {{ "cart.linePromo.add" | cxTranslate }} {{ promotion.buyQuantity - cartItemQty }} {{ "cart.linePromo.moreToCart" | cxTranslate }}
  </button>

  <button
    *ngIf="canApply"
    mat-flat-button
    color="primary"
    class="apply-remove-promo text-uppercase t-label-l align-self-center ml-auto mr-0 m-sm-auto"
    [ngClass]="{ 'promo-outlined': promotion.status === 'Applied' }"
    (click)="handlePromoStatusChange()"
  >
    {{
      (promotion.status === "Applied"
        ? "cart.linePromo.remove"
        : "cart.linePromo.applyPromo"
      ) | cxTranslate
    }}
  </button>
</div>
