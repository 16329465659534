<div class="edit-favorite-list-container add-edit-container">
  <div class="add-edit d-flex flex-row justify-content-between align-items-center">
      <div class="t-headerLink-normal">
        {{ (isEdit ? 'account.createFavoriteList.editList' : 'account.createFavoriteList.createList') | cxTranslate }}
      </div>
      <div class="mt-1">
          <button mat-flat-button color="primary" [mat-dialog-close]="true" class="sitelinks-close">
              <mat-icon> {{'header.close' | cxTranslate }}</mat-icon>
          </button>
      </div>
  </div>

  <!--  to show the error/success on click of save button on save edit : start-->
  <div *ngIf="editedFavListFailure" class="px-3 pt-3 rounded">
    <reusable-alert-box
      [alertType]="'warningAlertBox'"
      alertMsg="{{
        'account.signInSecurityForm.errorAlertMsg' | cxTranslate
      }}"
    ></reusable-alert-box>
  </div>


  <div *ngIf="editedFavListSuccess" class="px-3 pt-3 rounded">
    <reusable-alert-box
      [alertType]="'successAlertBox'"
      alertMsg="{{
        'account.signInSecurityForm.successAlertMsg' | cxTranslate
      }}"
    ></reusable-alert-box>
  </div>
  <!--  to show the error/success on click of save button : end-->

  <!--  to show the error/success on click of save button : start-->
  <div *ngIf="errorResponse" class="px-3 pt-3 rounded">
    <reusable-alert-box
      [alertType]="'warningAlertBox'"
      alertMsg="{{
        'account.createFavoriteList.somethingWentWrongMsg' | cxTranslate
      }}"
    ></reusable-alert-box>
  </div>

  <div *ngIf="favListExists && !errors.length" class="px-3 pt-3 rounded">
    <reusable-alert-box
      [alertType]="'warningAlertBox'"
      alertMsg="{{
        'account.createFavoriteList.errorAlertMsg' | cxTranslate
      }}"
    ></reusable-alert-box>
  </div>

  <div *ngIf="errors && errors.length && favListExists" class="px-3 pt-3 rounded">
    <reusable-alert-box *ngFor="let error of errors" [alertType]="'warningAlertBox'" alertMsg='{{error?.message}}' ></reusable-alert-box>
  </div>

  <div *ngIf="successResponse" class="px-3 pt-3 rounded">
    <reusable-alert-box
      [alertType]="'successAlertBox'"
      alertMsg="{{
        'account.createFavoriteList.successAlertMsg' | cxTranslate
      }}"
    ></reusable-alert-box>
  </div>
  <!--  to show the error/success on click of save button : end-->

  <form [formGroup]="editFavListForm" (submit)="saveList()">
    <div class="">
      <div class="edit-list-name-input mt-3 mx-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'account.createFavoriteList.listName' | cxTranslate }}</mat-label>
          <input
            matInput
            type="text"
            class=""   
            formControlName="name"
            maxlength="75"
          />
          <mat-error
           *ngIf="editFavListForm.get('name')?.touched &&
           editFavListForm.get('name')?.hasError('required')">
           {{ "account.createFavoriteList.enterListName" | cxTranslate }}
         </mat-error>
        </mat-form-field>
      </div>
      
      <div class="mt-3 mx-3">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{'account.createFavoriteList.description' | cxTranslate }}</mat-label>
            <textarea matInput
                      formControlName="description"
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="3"
                      cdkAutosizeMaxRows="3" 
                      maxlength="240"></textarea>
          </mat-form-field>
      </div>

      <div class="px-2 mx-3 mb-3">
        <p class="t-body-s">{{240 - editFavListForm.get('description')?.value?.length}}
          {{ "account.createFavoriteList.charactersRemaining" | cxTranslate }}
        </p>
      </div>
      
      <div class="advance-list-style">
    
        <div class="t-productTile-description m-3">
          {{'account.createFavoriteList.advancedListFeatures' | cxTranslate }}
        </div>
    
        <div class="m-3">
          <div class="t-productTile-description" *ngIf="unitType === 'PARENT'">
            <mat-checkbox color="primary" formControlName="isShared">{{'account.createFavoriteList.share' | cxTranslate }}</mat-checkbox>
          </div>
          <div class="t-productTile-description" *ngIf="isCreator">
            <mat-checkbox color="primary" formControlName="isLocked">{{'account.createFavoriteList.lock' | cxTranslate }}</mat-checkbox>
          </div>
        </div>
    
        <!-- below div will be changed to button on anchor tag as  part of edit list story -->
        <div *ngIf="isEdit" class="t-productTile-description m-3 delete-fav-list">
          <a (click)="deleteFavoriteList()">{{'account.createFavoriteList.deleteList' | cxTranslate }}</a>
        </div>
    
        <div class="d-flex flex-row justify-content-end m-3">
    
          <div class="w-50 text-right pl-4">
            <button  mat-flat-button color="white" type="button"  class="w-100" [mat-dialog-close]="true"> 
              {{ "account.createFavoriteList.cancelButton" | cxTranslate }}
            </button>
          </div>
          <div class="w-50 text-right pl-4">
            <button mat-flat-button color="primary" type="submit" class="w-100" 
            [disabled]="!editFavListForm.valid">
            {{ "account.createFavoriteList.saveButton" | cxTranslate }}
            </button>
          </div>
        </div>
    
      </div>
    </div>
  </form>
 

</div>