<div class="custom-tooltip" id="custom-tooltip-{{tooltipIndex}}">
  <div
    class="credit__info-container d-flex flex-column justify-content-center align-items-center"
  >
    <p class="t-order_promo-heading m-0">
      {{ "account.rewards.creditInfo" | cxTranslate }}
    </p>
    <span class="t-rewards-tooltip-text">{{ tooltipMessage }}</span>
    <div class="close-btn t-label-l">
      <button class="px-5" (click)="handleTooltip(false)">
        {{ "account.rewards.closeBtn" | cxTranslate }}
      </button>
    </div>
  </div>
</div>
