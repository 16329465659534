import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appRemoveAriaOwns]'
})
export class RemoveAriaOwnsDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.el.nativeElement.parentElement.removeAttribute('aria-owns');
  }
}



