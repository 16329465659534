import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SiteContextConfig, WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import { UtilService } from 'src/app/core/services/util.service';
import * as fromApp from 'src/app/core/store/app.reducer';
import { LoginDialogComponent } from 'src/app/shared/components/login-dialog/login-dialog.component';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { CurrentThemeService } from 'src/app/shared/services/common/current-theme.service';
import { GTMService } from 'src/app/shared/services/common/gtm.service';
import { InputValidationService } from 'src/app/shared/services/common/input-validation.service';
import { QuickOrderService } from 'src/app/shared/services/common/quick-order.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { global } from 'src/app/shared/translations/en/global';
import { NotifyStockSliderComponent } from '../../notify-stock-slider/notify-stock-slider.component';
import { SalesRepCallMeDrawerComponent } from '../../sales-rep-call-me-drawer/sales-rep-call-me-drawer.component';
import { WishlistListComponent } from '../../wishlist-list/wishlist-list.component';
import { SearchServiceService } from '../search-service.service';


declare let CelebrosAnalytics: any;
@Component({
  selector: 'app-product-row-view',
  templateUrl: './product-row-view.component.html'
})
export class ProductRowViewComponent implements OnInit, OnChanges, OnDestroy {

  @Input() products: any;
  @Input() page: number;
  @Input() totalCount: number;
  @Input() totalPages: number;
  @Output() paginationData: EventEmitter<any> = new EventEmitter();
  @Input() price:any;
  @Input() celebrosLogHandle:any;
  @Input() celebrosSessionId:any;
  @Input() celebrosSiteKey:any;
  quantityInput: any = [];
  user: User;
  userId: string;
  cartId: any;
  userSub: Subscription;
  request: { product: { code: string; }; quantity: number; };
  maxQuantity: any = [];
  showInput: any;
  showCart: boolean = false;
  showOptions: boolean = false;
  variantOptions: any = [];
  currentSite: any = this.headerService.getCurrentSite()[0];
  siteConfig: any;
  imageUrl: any;
  multipleOrder: any = [];
  quantityError: any = [];
  multipleOrderError: any= [];
  quantityInputValue: number;
  addToWishlistDialog: any;
  themeClass: any;
  cartDisable: boolean;
  showError: any; // to show errors on input add to cart
  pageLoadFlag: boolean = true;
  notifyStockDialog: any;
  salesUnit: any = [];
  selectableAtrribute: any;
  salesSingleUnit: any = [];
  baseSiteName: any;
  translations = global.account.breadCrumb;
  productCodes:any=[];
  getProductsPriceSub:Subscription;
  prices: any;
  tempEntery:any=[];
  showCalculating:any=true;
  isNotifyFlag:any = true;
  baseSiteNameVal: any;
  totalRecordsOnPage=12;

  constructor(
    private config: SiteContextConfig,
    private cdr: ChangeDetectorRef,
    private headerService: HeaderService,
    private quickOrderService: QuickOrderService,
    public dialog: MatDialog,
    public currentTheme: CurrentThemeService,
    private searchService: SearchServiceService,
    private store: Store<fromApp.AppState>,
    private router: Router,
    private customHttp:BoxoutEndpointService,
    public gtmService: GTMService,
    public windowRef: WindowRef,
    @Inject(DOCUMENT) private document: Document,
    private inputValidationService:InputValidationService,
    private utilService: UtilService
  ) {
    this.siteConfig = this.config;
    this.themeClass = this.currentTheme?.getCurrentTheme();
  }
  ngOnInit(): void {
    this.imageUrl = this.siteConfig?.backend?.occ?.baseUrl;
    this.themeClass = this.currentTheme.getCurrentTheme();

    this.userSub = this.store.select('auth').subscribe(user => {
      this.user = user && user.user!;
      this.userId = this.user?.uid!;
      this.cartDisable = !this.user;
      if(this.user?.isNotifyMeButtonEnabled !== undefined && !this.user?.isNotifyMeButtonEnabled){
        this.isNotifyFlag = this.user?.isNotifyMeButtonEnabled
      }
    });

    this.baseSiteName = this.config?.context?.baseSite;
    if (this.baseSiteName) {
      this.baseSiteNameVal = this.baseSiteName[0];
    } 
    this.baseSiteName = this.utilService.checkBaseSite();
  }

  //choose option functionality
  chooseButtonClick(id: any) {
  }

  //Add to cart functionality
  addToCart(id: any, index: any, variantIndex?: any) {
    let maxError : any;
    let variantMultiplier = this.variantOptions[variantIndex]?.quantityMultiplier;
    let baseMultiplier = this.products[index]?.quantityMultiplier;
    //to make page load flag false 
    this.pageLoadFlag = false;
    this.showError = id;
    this.multipleOrderError[id] = false;
    this.multipleOrder[id] = false;
    this.quantityError[id] = false;
    this.maxQuantity[id] = false;
    if(variantIndex){
      this.maxQuantity[id] = false;
    }
    let obj = {
      "product": {
        "code": id
      },
      "quantity": + this.quantityInput[id]
    }

    //Condition to check variant size for specific product
    if (this.products[index]?.variantsSize === 1) {
      //To check multiple order scenario
      if (baseMultiplier && baseMultiplier !== 1) {
        this.multipleOrderError[id] = false;
        this.multipleOrder[id] = true;
        this.quantityError[id] = false;
        this.maxQuantity[id] = false;
      }
      //To check quantity Error
      if (this.quantityInput[id] === 0 || !this.quantityInput[id]) {
        this.multipleOrderError[id] = false;
        this.multipleOrder[id] = false;
        this.quantityError[id] = true;
        this.maxQuantity[id] = false;
      }
      //To check multiple Order Error 
      if (this.quantityInput[id] % baseMultiplier !== 0 && baseMultiplier !== 1) {
        this.multipleOrderError[id] = true;
        this.multipleOrder[id] = false;
        this.quantityError[id] = false;
        this.maxQuantity[id] = false;
      }
      if (((this.quantityInput[id] % baseMultiplier === 0 && baseMultiplier !== 1) ||
        (baseMultiplier === 1 && this.quantityInput[id] > 0)) && this.quantityInput[id] !== 0 && this.quantityInput[id]) {
        this.windowRef?.sessionStorage?.removeItem('gaItemsForCartUpdate');
        this.windowRef.sessionStorage?.removeItem('totalForCartUpdate');
        let gaItem:any = {};
        gaItem = this.products[index]?.gaItems;
        gaItem = { ...gaItem, discount: null, index: null , price: this.getPriceFromHTML(this.products[index])[0]?.finalPrice?.replace('$','').replace(',','') , quantity: this.quantityInput[id], item_list_id: 'row_view_list', item_list_name: 'Row View List'};
        this.windowRef.sessionStorage?.setItem('gaItemsForCartUpdate' , JSON.stringify([gaItem]));
        this.windowRef.sessionStorage?.setItem('totalForCartUpdate' , JSON.stringify(gaItem?.price * parseInt(gaItem?.quantity)));
        this.quickOrderService.getCartIdSpecificProduct(obj, 'search', this.userId);
        maxError = this.quickOrderService.specificProduct$.subscribe((resp: any) => {
          if (resp.statusCode == "maxOrderQuantityExceeded") {
            this.multipleOrderError[id] = false;
            this.multipleOrder[id] = false;
            this.quantityError[id] = false;
            this.maxQuantity[id] = true;
            maxError.unsubscribe();
          }
          else{
            this.maxQuantity[id] = false;
          }
        })
      }
    }
    //Condition to check variant size for family of variant product
    if (this.products[index]?.variantsSize > 1) {
      if (variantMultiplier && variantMultiplier != 1) {
        this.multipleOrderError[id] = false;
        this.multipleOrder[id] = true;
        this.quantityError[id] = false;
        this.maxQuantity[id] = false;
      }

      if (this.quantityInput[id] == 0 || !this.quantityInput[id]) {
        this.multipleOrderError[id] = false;
        this.multipleOrder[id] = false;
        this.quantityError[id] = true;
        this.maxQuantity[id] = false;
      }
      if (this.quantityInput[id] % variantMultiplier != 0 && variantMultiplier != 1) {
        this.multipleOrderError[id] = true;
        this.multipleOrder[id] = false;
        this.quantityError[id] = false;
        this.maxQuantity[id] = false;
      }
      if (((this.quantityInput[id] % variantMultiplier == 0 && variantMultiplier != 1) ||
        (variantMultiplier == 1 && this.quantityInput[id] > 0)) && this.quantityInput[id] != 0 && this.quantityInput[id]) {
        this.windowRef?.sessionStorage?.removeItem('gaItemsForCartUpdate');
        this.windowRef.sessionStorage?.removeItem('totalForCartUpdate');
        let gaItem:any = {};
        gaItem = this.products[index]?.gaItems;
        gaItem = { ...gaItem, discount: null, index: null , price: this.getPriceFromHTML(this.products[index])[0]?.finalPrice?.replace('$','').replace(',','')  , quantity: this.quantityInput[id], item_list_id: 'row_view_list', item_list_name: 'Row View List'};
        this.windowRef.sessionStorage?.setItem('gaItemsForCartUpdate' , JSON.stringify([gaItem]));
        this.windowRef.sessionStorage?.setItem('totalForCartUpdate' , JSON.stringify(gaItem?.price * parseInt(gaItem?.quantity)));
        this.quickOrderService.getCartIdSpecificProduct(obj, 'search', this.userId);
        maxError = this.quickOrderService.specificProduct$.subscribe((resp: any) => {
          if (resp.statusCode == "maxOrderQuantityExceeded") {
            this.multipleOrderError[id] = false;
            this.multipleOrder[id] = false;
            this.quantityError[id] = false;
            this.maxQuantity[id] = true;
            maxError.unsubscribe();
          }
          else{
            this.maxQuantity[id] = false;
          }
        })
      }
    }
  }

  // quantity increamnet and decreament  functionality
  increment(id: any, index: any, variantIndex?: any) {
    let variantMultiplier = this.variantOptions[variantIndex]?.quantityMultiplier;
    let baseMultiplier = this.products[index]?.quantityMultiplier;
    if (this.quantityInput[id] === undefined) {
      this.quantityInput[id] = 0;
    }
    //Condition for specific product
    else {
      if (this.products[index]?.variantsSize === 1) {
        // if existing quantity value is not a multiple
        if (this.quantityInput[id] % baseMultiplier !== 0) {
          this.quantityInput[id] = this.quantityInput[id] - (this.quantityInput[id] % baseMultiplier);
        }
        this.quantityInput[id] += baseMultiplier;
      }
      //Condition for family of variant product 
      else {
        // if existing quantity value is not a multiple
        if (this.quantityInput[id] % variantMultiplier !== 0) {
          this.quantityInput[id] = this.quantityInput[id] - (this.quantityInput[id] % variantMultiplier);
        }
        this.quantityInput[id] += variantMultiplier;
      }
    }
  }

  decrement(id: any, index: any, variantIndex?: any) {
    let variantMultiplier = this.variantOptions[variantIndex]?.quantityMultiplier;
    let baseMultiplier = this.products[index]?.quantityMultiplier;
    //Condition for specific product
    if ((this.products[index]?.variantsSize === 1) && (this.quantityInput[id] > 0) && (this.quantityInput[id] > baseMultiplier)) {
      // if existing quantity value is not a multiple
      if (this.quantityInput[id] % baseMultiplier !== 0) {
        this.quantityInput[id] = this.quantityInput[id] + (baseMultiplier - (this.quantityInput[id] % baseMultiplier));
      }
      this.quantityInput[id] -= baseMultiplier;
    }
    //Condition for family of variant product 
    if ((this.products[index]?.variantsSize > 1) && (this.quantityInput[id] > 0) && (this.quantityInput[id] > variantMultiplier)) {
      // if existing quantity value is not a multiple
      if (this.quantityInput[id] % variantMultiplier !== 0) {
        this.quantityInput[id] = this.quantityInput[id] + (variantMultiplier - (this.quantityInput[id] % variantMultiplier));
      }
      this.quantityInput[id] -= variantMultiplier;
    }
  }

  showOption(id: any) {
    this.showCalculating =  true;
    this.productCodes =[];
    //APi call for get variants
    this.searchService.getVariantApi(id).subscribe((resp: any) => {
      this.selectableAtrribute = resp?.selectableAtrribute;
      this.variantOptions = resp?.variantOptions;
      //assigning multiplier values
      this.variantOptions?.forEach((element: any, index: any) => {
        this.productCodes?.push(element?.code);
        this.maxQuantity[element?.code] = false;
        if(!element?.quantityMultiplier){
          element.quantityMultiplier = 1;
          this.multipleOrder[element?.code] = false;
        }
        else {
          this.quantityInput[element?.code] = element?.quantityMultiplier;
          if(this.quantityInput[element?.code] !== 1){
            this.multipleOrder[element?.code] = true;
          }
          else
          this.multipleOrder[element?.code] = false;
        }
      });
      this.showOptions = true;
      this.showInput = id;
      this.getPrice();
      this.cdr.detectChanges();
    })
  }
  getPrice(){
    const apiURL = `orgProducts/realTimePriceForSearch?isRapidOrderPage=true&productCodes=${this.productCodes}`;
    this.getProductsPriceSub = this.getProduct(apiURL)
        .subscribe((data: any) => {
          if(data?.errorflag == false){
            this.prices = data?.response;
            this.prices?.forEach((item:any,index:any) => {
              let ind = this.variantOptions.findIndex((product:any) => product?.code === item?.productCode);
              this.variantOptions[ind].priceData.formattedValue = item?.price;
              this.variantOptions[ind].priceData.value = Number(item?.price?.replace('$','').replace(',',''));
              let strikeThroughRef = this.document.getElementById(`${this.variantOptions[ind].code}-strike`);
              if(strikeThroughRef && item?.discount && item?.retailPrice?.formattedValue ) {
                strikeThroughRef.classList.remove('d-none');
                strikeThroughRef!.innerHTML = `<span class="t-userid-text save-amount px-2 py-1 mr-2">Save $${item.discount.toFixed(2)}</span>
                <span class="t-userid-text-bold">Was ${item.retailPrice.formattedValue}
                    <span>/ ${this.variantOptions[ind].unit}</span>
                </span>`
              }
          })
        }
        this.showCalculating =  false;
        this.cdr.detectChanges();
        })
  }
  getProduct(apiURL: any) {
    return this.customHttp.get(apiURL);
  }
  closeOption() {
    this.showOptions = false;
    this.showInput = null;
    this.salesUnit = [];
  }

  getNewPageData(page:any) {
    if (this.products?.length < this.totalCount) {
      this.paginationData.emit(page-1);
    }
  }
  //recieves product details from product search component when pagination data response comes
  ngOnChanges(changes: SimpleChanges) {
    if (changes.products?.currentValue?.length > changes.products?.previousValue?.length) {
      this.products = this.products.filter((data: any) => data?.code !== changes?.products?.currentValue);
    }
    if (changes['products']) {
      let variableChange = changes['products'];
      this.products = variableChange.currentValue;
      this.products?.forEach((element: any) => {
        // to check variant size for single variant
        if (element?.variantsSize === 1 && element?.variantOptions && element?.variantOptions.length) {
          this.maxQuantity[element?.variantOptions[0]?.code] = false;
          if(!element?.quantityMultiplier){
            element.quantityMultiplier = 1;
            this.multipleOrder[element?.variantOptions[0]?.code] = false;
          } else {
            this.quantityInput[element?.variantOptions[0]?.code] = element?.quantityMultiplier;
            if(this.quantityInput[element?.variantOptions[0]?.code] !== 1){
              this.multipleOrder[element?.variantOptions[0]?.code] = true;
            }
            else
            this.multipleOrder[element?.variantOptions[0]?.code] = false;
          }
        }
      });
    }
    // to close show options when coming back on same results
    this.closeOption();
  }

  addProductToWishlist(product: any, isBaseProduct: any) {
    let variantCode = '';
    let gaObj:any = {};
    if ((product?.variantOptions?.length && product.variantOptions[0].code) && isBaseProduct) {
      gaObj = product.gaItems;
      gaObj = { ...gaObj, discount: null, index: null , price: this.getPriceFromHTML(product)[0]?.finalPrice?.replace('$','').replace(',','') , quantity: 1, item_list_id: 'row_view_list', item_list_name: 'Row View List'};
      variantCode = product.variantOptions[0].code;
    } else if(!isBaseProduct) {
      gaObj = product.gaItems;
      gaObj = { ...gaObj, discount: null, index: null , price: product?.priceData?.value , quantity: 1, item_list_id: 'row_view_list', item_list_name: 'Row View List'};
      variantCode = product.code;
    }

    this.addToWishlistDialog = this.dialog.open(WishlistListComponent, {
      position: {
        right: '0px',
        top: '0px'
      },
      panelClass: ['wishlist-side-drawer', this.themeClass],
      data: {
        isEdit: false,
        isCreator: true,
        productCode: variantCode,
        header: 'account.wishLists.addToListHeader',
        gaItem: [gaObj]
      }
    });
  }

  //Login Dialog
  onLoginClick() {
    this.dialog.open(LoginDialogComponent,
      {
        panelClass: [this.themeClass, 'custom-dialog-size'],
        autoFocus: false,
        disableClose: true
      });
  }

   //This method is used to open sales rep call me drawer when clicked on MY REP CALL ME 
   openSalesRepCallMeDrawer(item:any){
    this.dialog.open(SalesRepCallMeDrawerComponent, {
      position: {
        right: '0px',
        top: '0px'
      },
      panelClass: ['wishlist-drawer', this.themeClass],
      data: {
        product: item,
        user:this.user
      }
    });
  }

    //This method is used to open sales rep call me drawer when clicked on MY REP CALL ME 
    openSalesRepCallMeDrawerForVariant(item:any,baseProduct:any){
      this.dialog.open(SalesRepCallMeDrawerComponent, {
        position: {
          right: '0px',
          top: '0px'
        },
        panelClass: ['wishlist-drawer', this.themeClass],
        data: {
          baseProductName:baseProduct?.name,
          baseProductBrandData:baseProduct?.brandData?.name,
          baseProductCode: baseProduct?.code,
          product: item,
          user:this.user
        }
      });
    }

  keepMeUpdated(item: any, baseProduct?:any) {
    let baseSiteName = this.config?.context?.baseSite;
    let baseSiteNameVal;
    if (baseSiteName) {
      baseSiteNameVal = baseSiteName[0];
    }
    let price = this.getPriceFromHTML(item);
    let code = item?.code;
    let data: any = {
        product: item,
        user: this.user,
        qty: this.quantityInput[code],
        price : price,
        baseSiteNameVal : baseSiteNameVal,
        imageUrl:this.imageUrl
    }
    if(baseProduct){
      data.baseProduct = baseProduct;
      data.price = this.formatCurrency(item?.priceData?.formattedValue);
    }
    this.notifyStockDialog = this.dialog.open(NotifyStockSliderComponent, {
      position: {
        right: '0px',
        top: '0px'
      },
      panelClass: ['header-links', this.themeClass],
      data
    });
  }

  formatCurrency(formattedPrice: string) {
    const currency = formattedPrice?.charAt(0) || '$';
    const price = formattedPrice.split('.')[0].slice(1) || '00';
    const cent = formattedPrice.split('.')[1] || '00';
    return [{
      currency,
      price,
      cent
    }];
  }

  redirectPDP(code:any,varient?:any){
    this.sendCelebrosAnalytics(code,varient);
  }

  //api for sales Unit data
  salesunitApi(variant: any){
    if(variant?.salesUnitDetailsDataSize !== 0){
      let apiUrl= `/orgProducts/${variant?.code}/getSalesUnitData/${this.quantityInput[variant?.code]}/${variant?.priceData?.formattedValue}?fields=DEFAULT`;
      this.customHttp.post(apiUrl).subscribe((data:any)=>{
        if(data){
          this.salesUnit[variant?.code] = data;
          this.cdr.detectChanges();
        }
      });
    }
  }
  //Celebros Analytics call
  sendCelebrosAnalytics(code: any,varient?:any) {
    let price = this.getPriceFromHTML(code);
    if(typeof CelebrosAnalytics !== 'undefined' && typeof CelebrosAnalytics !== undefined) {
      CelebrosAnalytics.ShowComments = false;
      CelebrosAnalytics.dopost = false;
      CelebrosAnalytics.customerid = this.celebrosSiteKey;
      CelebrosAnalytics.pagereferrer = document.referrer;
      CelebrosAnalytics.productsku = code.code;
      CelebrosAnalytics.websessionid = this.celebrosSessionId;
      CelebrosAnalytics.productname = code.name;
      CelebrosAnalytics.productprice = price[0].finalPrice;
      CelebrosAnalytics.issecured = true;
      CelebrosAnalytics.qwisersearchsessionid = this.celebrosSessionId;
      CelebrosAnalytics.AI_LogProduct();
    }
    let updatedUrl = code?.url;
    let gaDataForViewItem = code?.gaItems;
    gaDataForViewItem = { ...gaDataForViewItem, discount: null, index: null , price: price[0].finalPrice?.replace('$','').replace(',','') , quantity: 1, item_list_id: 'row_view_list', item_list_name: 'Row View'};
    // sending the select_item event to GA 4
    this.gtmService.setSelectItemData(gaDataForViewItem);
    this.router.navigate([updatedUrl]);
    //this.windowRef.location.href = this.windowRef.location.origin + updatedUrl;
	}

  ngOnDestroy(): void {
    if(this.userSub){
      this.userSub.unsubscribe();
    }
    if(this.getProductsPriceSub){
      this.getProductsPriceSub.unsubscribe();
    }
  }
  getPriceFromHTML(item: any): any {
    let priceRef = this.document.getElementById(item.code)?.getElementsByClassName('product-tile-sigleVariantPrice')[0]
    let currency = priceRef?.getElementsByClassName('t-price-tile product-tile-currency')[0]?.textContent || '$';
    let price = priceRef?.getElementsByClassName('t-price-currenyTile product-tile-figure')[0]?.textContent || 0;
    let cent = priceRef?.getElementsByClassName('t-price-currenyFractionTile ml-1 product-tile-currency')[0]?.textContent || 0;
    let finalPrice = currency + price + '.' + cent;
    let priceData = [];
    priceData.push({
      currency: currency,
      price: price,
      cent: cent,
      finalPrice: finalPrice
    })
    return priceData;
  }
  onPaste(event: any) {
    this.inputValidationService.onPaste(event);
  }

  salesUnitApiSingleVariant(variant: any){
    if(variant?.variantOptions[0]?.salesUnitDetailsDataSize && variant?.variantOptions[0]?.salesUnitDetailsDataSize > 1){
      let price = this.getPriceFromHTML(variant)[0];
      let apiUrl= `/orgProducts/${variant?.variantOptions[0].code}/getSalesUnitData/${this.quantityInput[variant?.variantOptions[0]?.code]}/${price?.finalPrice}?fields=DEFAULT`;
        this.customHttp.post(apiUrl).subscribe((data:any)=>{
          if(data){
            this.salesSingleUnit[variant?.code] = data;
            this.cdr.detectChanges();
          }
        });
    }
  }
  checkDisable(item: any){
    let priceRef = this.document.getElementById(item.code)?.getElementsByClassName('product-tile-sigleVariantPrice')[0]
    let currency = priceRef?.getElementsByClassName('t-price-tile product-tile-currency')[0]?.textContent || '$';
    let price = priceRef?.getElementsByClassName('t-price-currenyTile product-tile-figure')[0]?.textContent || 0;
    let cent = priceRef?.getElementsByClassName('t-price-currenyFractionTile ml-1 product-tile-currency')[0]?.textContent || 0;
    let finalPrice = currency + price + '.' + cent;
    if(!item?.isSampleVariant && !item?.maxVariantPrice?.value){
      return (!item?.isSampleVariant && (finalPrice === '$0.0' || finalPrice === '$0.00' || finalPrice === '$00.0' || finalPrice === '$00.00')) ? true : false;
    }else{
      return false;
    }
  }
}
