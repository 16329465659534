import { Component, OnInit } from "@angular/core";
import { SiteContextConfig } from "@spartacus/core";
import { PageLoaderService } from "./page-loader.service";

@Component({
  selector: "app-page-loader",
  templateUrl: "./page-loader.component.html",
})
export class PageLoaderComponent implements OnInit {
  visible = false;
  showLoader=false;
  showLoading=false;

  baseSiteName: any;
  baseSiteNameVal: string;
 
  constructor(private config: SiteContextConfig,public pageLoaderService: PageLoaderService) {}

  ngOnInit() {

    // this.pageLoaderService.loader.subscribe((res)=>{
    //   this.showLoading=res;
    // })
    this.baseSiteName = this.config?.context?.baseSite;
    if(this.baseSiteName){
      this.baseSiteNameVal=this.baseSiteName[0];
    }  
   

  }

}
