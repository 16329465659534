<!--infiniteScrollDistance refers to trigger scroll when scroll bar has 0.1*10=1% left in the screen-->
<!-- please refer https://www.npmjs.com/package/ngx-infinite-scroll for more info-->
<!-- <div
  class="search-results"
  infiniteScroll
  [infiniteScrollDistance]="1"
  [infiniteScrollUpDistance]="2"
  [infiniteScrollThrottle]="2000"
  (scrolled)="onScroll()"
> -->

<div *ngIf="totalCount > 1" class="mb-4 has-text-centered position-relative paginate">
    <p class="t-title-m d-none position-absolute d-md-block total">
      {{ totalCount }} Records
    </p>
    <pagination-controls 
    *ngIf="totalCount>totalRecordsOnPage"
    (pageChange)="getNewPageData($event)"
      previousLabel=""
      nextLabel=""
    ></pagination-controls>
  </div>

<div class="product-tile-view d-lg-flex d-md-flex flex-lg-row d-sm-flex flex-md-row flex-sm-row flex-column justify-content-sm-around justify-content-md-around justify-content-lg-between flex-wrap px-3 px-md-4 px-lg-0">
    <div class="product-tile mb-5" *ngFor="let item of products | paginate
    : { itemsPerPage: totalRecordsOnPage, currentPage: page+1, totalItems: totalCount}; let i=index">
        <div class="product-tile-section py-3">
            <div class="row w-100 justify-content-between m-0 px-3">
                <!-- Needed for future release -->
                <!-- <div>
                    <mat-checkbox class="p-0 t-productTile-productGroup">
                        {{ 'account.productTile.compare' | cxTranslate }}
                    </mat-checkbox>
                </div> -->
                <div>
                    <mat-icon svgIcon="new-arrival" 
                        class="product-tile-new-arrival t-productTile-iconAlert d-none">
                    </mat-icon>                     
                </div>
            </div>
            <div class="text-center product-tile-image px-3">
                <img *ngIf="item?.images && item?.images.length > 0 && item?.images[0].url !== ''" class="cursor-pointer" 
                defaultImage="../../../../../assets/MissingImage.svg" lazyLoad="{{imageUrl}}{{item?.images[0]?.url}}" alt="mainImage" (click)="redirectPDP(item)">
                <img *ngIf="item?.images === undefined || item?.images[0].url === '' || !item?.images " class="cursor-pointer"
                defaultImage="../../../../../assets/MissingImage.svg" lazyLoad="../../../../../assets/MissingImage.svg" alt="MissingImage" (click)="redirectPDP(item)">
                <a 
                    mat-flat-button 
                    class="product-tile-hover-viewDetails-button" 
                    type="button"
                    (click)="redirectPDP(item)">
                    {{ 'account.productTile.viewHoverDetail' | cxTranslate }}
                </a>
            </div>
    
            <div class="product-tile-description px-3">
                <section class="t-productTile-description description m-0 cursor-pointer"
                    [innerHTML]="item?.name" (click)="redirectPDP(item)">
                </section>
                <h3 class="t-productTile-brand brand mb-1" [innerHTML]="item?.brandData?.name">
                </h3>
                <p class="t-productTile-productGroup group d-none">
                    {{ 'account.productTile.item' | cxTranslate }}{{item?.code}}
                </p>
                <p class="t-productTile-productGroup product-tile-lastPurchased d-none">
                    {{ 'account.productTile.lastPurchased' | cxTranslate }}{{item?.lastPurchased}}
                </p>
                <div class="globalInventory" *ngIf="!!isViewInventoryGroup">
                    <div class="product-tile-globalInventory" *ngIf="item?.stock?.stockLevelMap && user?.isViewInventoryGroup">
                        <p class="t-productTile-productGroup">
                            {{ 'account.productTile.available' | cxTranslate }}{{item?.stock?.stockLevel}}
                        </p>
                        <p class="t-productTile-productGroup">
                            {{ 'account.productTile.inv' | cxTranslate }}
                            <span *ngFor="let stockLevel of item?.stock?.stockLevelMap">
                                <span> {{ stockLevel?.key | formatWarehoseData }} [{{ stockLevel?.value }}]</span>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="product-tile-summary teaser px-3">
                <hr>
                <p class="t-productTile-teaser teaser" [innerHTML]="item?.summary">
                </p>
            </div>
            <hr class="mx-3">
            <div *ngIf="!user" class="px-3">
                <p class="t-productTile-description cursor-pointer" (click)="onLoginClick()">Login To View Price</p>
                <p class="t-productTile-teaser">please login to access your price</p>
            </div>
            <div class="mt-3 pb-5 px-3" id="{{item?.code}}">
                <div class="flag-position">
                    <span class="product-tile-sigleVariantPrice" *ngIf="user">
                        <p class="t-productTile-description">{{ 'account.productTile.calculating' | cxTranslate }}</p>
                        <p class="t-productTile-priceTeaser">{{ 'account.productTile.proffesionalPrice' | cxTranslate }}</p>
                    </span>
                    <div class="d-none mt-2 strike-through-price" *ngIf="user && baseSiteNameVal !== 'mmsite'">
                    </div>

                    <div class="product-tile-calculatingPrice d-none">
                        <p class="t-productTile-description">{{ 'account.productTile.calculating' | cxTranslate }}</p>
                        <p class="t-productTile-priceTeaser">{{ 'account.productTile.proffesionalPrice' | cxTranslate }}</p>
                    </div>
                    <div *ngIf="!user" class="d-none">
                        <div class="product-tile-flag">
                            <div class="product-tile-inStock d-flex" *ngIf="'inStock' === item?.stock?.stockLevelStatus">
                                <mat-icon svgIcon="inStock" class="product-tile-inStock-icon mr-2" aria-label="In Stock"></mat-icon>
                                <span class="t-productTile-availability-Instock product-tile-inStock-text mr-1">
                                    {{ 'account.productTile.inStock' | cxTranslate }}
                                </span>
                                <span class="t-productTile-availability-Instock-description mr-1">
                                    {{ 'account.productTile.readyToShip' | cxTranslate }}
                                </span>
                            </div>
                            <div class="product-tile-backOrdered d-flex" *ngIf="'outOfStock' === item?.stock?.stockLevelStatus">
                                <mat-icon svgIcon="backordered" class="product-tile-backOrdered-icon mr-2" aria-label="Back Ordered"></mat-icon>
                                <span class="t-productTile-availability-Instock product-tile-backOrdered-text">
                                    {{ 'account.productTile.outOfStock' | cxTranslate }}
                                </span>
                                <span class="t-productTile-availability-Instock-description mr-1" *ngIf="item?.outOfStockETA">
                                    {{ 'account.productTile.pipe' | cxTranslate }}{{ 'account.productTile.eta' | cxTranslate }}{{item?.outOfStockETA}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="user">
                        <div class="product-tile-flag product-tile-inStock">
                            <div class="product-tile-inStock d-flex" *ngIf="'inStock' === item?.stock?.stockLevelStatus && !item?.isBackOrder && !item?.isDiscontinued && !item?.vendorDropShipSpecialOrder && item?.sellable === 'sellable'">
                                <mat-icon svgIcon="inStock" class="product-tile-inStock-icon mr-2" aria-label="In Stock"></mat-icon>
                                <span class="t-productTile-availability-Instock product-tile-inStock-text mr-1">
                                    {{ 'account.productTile.inStock' | cxTranslate }}
                                </span>
                                <span class="t-productTile-availability-Instock-description mr-1">
                                    {{ 'account.productTile.readyToShip' | cxTranslate }}
                                </span>
                            </div>
                            <div class="product-tile-backOrdered d-flex" *ngIf="'outOfStock' === item?.stock?.stockLevelStatus && !item?.isBackOrder && !item?.isDiscontinued && !item?.vendorDropShipSpecialOrder && item?.sellable === 'sellable'">
                                <mat-icon svgIcon="backordered" class="product-tile-backOrdered-icon mr-2" aria-label="Back Ordered"></mat-icon>
                                <span class="t-productTile-availability-Instock product-tile-backOrdered-text">
                                    {{ 'account.productTile.outOfStock' | cxTranslate }}
                                </span>
                                <span class="t-productTile-availability-Instock-description mr-1" *ngIf="item?.outOfStockETA">
                                    {{ 'account.productTile.pipe' | cxTranslate }}{{ 'account.productTile.eta' | cxTranslate }}{{item?.outOfStockETA}}
                                </span>
                            </div>
                            <div class="product-tile-backOrdered d-flex" *ngIf="item?.isBackOrder && !item?.isDiscontinued && !item?.vendorDropShipSpecialOrder && item?.sellable === 'sellable'">
                                <mat-icon svgIcon="backordered" class="product-tile-backOrdered-icon mr-2" aria-label="Back Ordered"></mat-icon>
                                <span class="t-productTile-availability-Instock product-tile-backOrdered-text">
                                    {{ 'account.productTile.backOrdered' | cxTranslate }}
                                </span>
                                <span class="t-productTile-availability-Instock-description mr-1" *ngIf="item?.mfgBackorderETA">
                                    {{ 'account.productTile.pipe' | cxTranslate }}{{ 'account.productTile.eta' | cxTranslate }}{{item?.mfgBackorderETA}}
                                </span>
                            </div>
                            <div class="product-tile-discontinued d-flex" *ngIf="item?.isDiscontinued">
                                <mat-icon svgIcon="discontinued" class="product-tile-discontinued-icon mr-2" aria-label="Discontinued"></mat-icon>
                                <span class="t-productTile-availability-Instock product-tile-discontinued-text">
                                    {{ 'account.productTile.discontinued' | cxTranslate }}
                                </span>
                            </div>
                            <div class="product-tile-specialorder d-flex" *ngIf="(item?.vendorDropShipSpecialOrder || item?.sellable === 'exempt') && !item?.isDiscontinued">
                                <mat-icon svgIcon="specialorder" class="product-tile-specialorder-icon mr-2" aria-label="Special Order"></mat-icon>
                                <span class="t-productTile-availability-Instock product-tile-specialorder-text">
                                    {{ 'account.productTile.specialOrder' | cxTranslate }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="product-tile-promo d-none">
                        <mat-icon svgIcon="promo" class="product-tile-promo-icon" aria-label="Promo"></mat-icon>
                        <span class="t-productTile-availability-Instock product-tile-promo-text">
                            {{ 'account.productTile.promo' | cxTranslate }}
                        </span>
                        <span class="t-productTile-availability-Instock-description">
                            {{ 'account.productTile.pipe' | cxTranslate }}Buy 12 get 2 Free
                        </span>
                    </div>
                    <div class="add-to-cart"  *ngIf="user">
                        <div *ngIf="showInput !== i && (!item?.variantSiebelRowIds || item?.variantSiebelRowIds.length === 1) && item?.variantOptions?.length">
                            <div class="min-mul-flags">
                                <div class="text-center mx-auto product-tile-multiple-order" *ngIf="item?.quantityMultiplier && item?.quantityMultiplier !== 1 && multipleOrder[i] && !maxQuantity[i] &&
                                (('inStock' === item?.stock?.stockLevelStatus || 'outOfStock' === item?.stock?.stockLevelStatus || item?.vendorDropShipSpecialOrder) && !item?.isBackOrder && !item?.isDiscontinued && item?.sellable === 'sellable')">
                                    <p class="t-productTile-boldPrice">{{ 'account.productTile.orderInMultiples' | cxTranslate }}{{item?.quantityMultiplier}}</p>
                                </div>
                                <div class="text-center mx-auto product-tile-quantity-error mt-3" *ngIf="quantityError[item?.variantOptions?.length && item.variantOptions[0].code]">
                                    <p class="t-productTile-boldPrice">{{ 'account.productTile.quantityError' | cxTranslate }}</p>
                                </div>
                                <div class="text-center mx-auto product-tile-quantity-error mt-3" *ngIf="multipleOrderError[item?.variantOptions?.length && item.variantOptions[0].code]">
                                    <p class="t-productTile-boldPrice">{{ 'account.productTile.multipleError' | cxTranslate }}{{item?.quantityMultiplier}}</p>
                                </div>
                                <div class="text-center mx-auto product-tile-max-quantity" *ngIf="maxQuantity[i]">
                                    <p class="t-productTile-boldPrice">
                                        {{ 'account.productTile.maxQuantity' | cxTranslate }}
                                    </p>
                                </div>
                            </div>
                            <div class="quantity d-flex" 
                                *ngIf="('inStock' === item?.stock?.stockLevelStatus || 'outOfStock' === item?.stock?.stockLevelStatus || item?.vendorDropShipSpecialOrder) && !item?.isBackOrder && !item?.isDiscontinued && item?.sellable === 'sellable'">
                                <button (click)="decrement(i)" class="quantity__minus" role="button" aria-label="minus">
                                    <mat-icon svgIcon="minusIcon" class="product-tile-minusIcon-icon"></mat-icon>
                                </button>
                                <input type="number" onKeyPress="if(this.value.length >= 4 || this.value < 0) return false;" name="counter" class="quantity__input mx-1" [(ngModel)]="quantityInput[i]"
                                (paste)="onPaste($event)" aria-label="quantity">
                                <button (click)="increment(i)" class="quantity__plus" aria-label="plus">
                                    <mat-icon svgIcon="plus" class="product-tile-plus-icon"></mat-icon>
                                </button>
                                <button 
                                mat-flat-button 
                                class="product-tile-addToCart-button ml-1" 
                                color="primary"
                                (click)="addToCart(item?.variantOptions?.length && item.variantOptions[0].code,i)"
                                [disabled]="cartDisable || checkDisable(item)"
                                role="button">
                                + Cart
                            </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <ng-container *ngIf="isNotifyFlag">
                            <div class="product-tile-keepmeUpdated"
                                *ngIf="(item?.isBackOrder || 'outOfStock' === item?.stock?.stockLevelStatus) && !item?.isDiscontinued && item?.sellable === 'sellable' && !item?.vendorDropShipSpecialOrder && user && item?.variantsSize === 1">
                                <button mat-flat-button class="product-tile-keepmeUpdated-button w-100" type="button"
                                    (click)="keepMeUpdated(item,i)">
                                    <mat-icon svgIcon="bell" class="product-tile-bell-icon" aria-label="Bell"></mat-icon>
                                    {{ 'account.productTile.keepMeUpdated' | cxTranslate }}
                                </button>
                            </div>
                    </ng-container>

                        <div class="product-tile-keepmeUpdated" *ngIf="user && (item?.isDiscontinued || item?.vendorDropShipSpecialOrder || item?.sellable === 'exempt') && item?.variantsSize === 1">
                            <button 
                            mat-flat-button 
                            (click)="openSalesRepCallMeDrawer(item)"
                            class="product-tile-keepmeUpdated-button w-100"
                            type="button">
                            {{ 'account.productTile.callMe' | cxTranslate }}
                        </button>
                        </div>
                    </div>
                </div>
                <div class="choose-option-button">
                    <div class="product-tile-chooseOption" *ngIf="item?.variantSiebelRowIds?.length > 1">
                        <a 
                        mat-flat-button
                        (click)="redirectPDP(item)"
                        class="product-tile-chooseOption-button w-100" 
                        type="button">
                        {{ 'account.productTile.chooseOptions' | cxTranslate }}
                    </a>
                    </div>
                </div>
                <div class="product-tile-consultation d-none">
                    <button 
                    mat-flat-button 
                    class="product-tile-button w-100" 
                    type="button">
                    {{ 'account.productTile.requestConsultation' | cxTranslate }}
                </button>
                </div>
                <div class="product-tile-substitutes d-none">
                    <button 
                    mat-flat-button 
                    class="product-tile-button w-100" 
                    type="button">
                    {{ 'account.productTile.substitution' | cxTranslate }}
                </button>
                </div>
                <div class="product-tile-options pb-3 mb-1 d-none">
                    <button 
                    mat-flat-button 
                    class="product-tile-button w-100" 
                    type="button">
                    {{ 'account.productTile.checkOutOptions' | cxTranslate }}
                </button>
                </div>
            </div>
            <div class="product-tile-viewDetails w-100 px-3 text-center product-tile-choose-option-button d-flex justify-content-center align-items-baseline">
                <a 
                mat-flat-button 
                (click)="redirectPDP(item)"
                class="product-tile-viewDetails-button" 
                type="button">
                {{ 'account.productTile.viewDetails' | cxTranslate }}
                </a>
                <mat-icon *ngIf="item?.variantSiebelRowIds && item?.variantSiebelRowIds.length === 1 && user" (click)="addProductToWishlist(item)"
                svgIcon="like" class="product-tile-like-icon cursor-pointer" aria-label="Like"></mat-icon>
            </div>
        </div>
        <div class="text-center product-tile-global-message px-3" *ngIf="shippingMessage">
            <p class="t-productTile-productGroup" [innerHtml]="shippingMessage"></p>
        </div>
    </div>
    <div class="product-tile mb-5" *ngIf="products.length % 3 !== 0">
    </div>
</div>

<div *ngIf="totalCount > 1" class="has-text-centered position-relative paginate">
    <p class="t-title-m d-none m-0 position-absolute d-md-block total">
      {{ totalCount }} Records
    </p>
    <pagination-controls 
    *ngIf="totalCount>totalRecordsOnPage"
    (pageChange)="getNewPageData($event)"
      previousLabel=""
      nextLabel=""
    ></pagination-controls>
  </div>

<!-- </div> -->
<!-- </div>
</div>
</div> -->
