import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CmsService, SiteContextConfig, WindowRef } from '@spartacus/core';
import { UtilService } from 'src/app/core/services/util.service';
import { LoginDialogComponent } from 'src/app/shared/components/login-dialog/login-dialog.component';
import { LinkTargetService } from 'src/app/shared/services/common/link-target.service';


@Component({
  selector: 'app-header-sitelinks',
  templateUrl: './header-sitelinks.component.html'
})
export class HeaderSitelinksComponent implements OnInit,OnDestroy {

  siteLinksArray:any = []; 
  userLoggedIn1: any;
  userDetails: any;
  setLinkTarget: any;
  theme: any = this.document.getElementsByTagName('app-root')[0].classList;
  baseSiteName: any;
  baseSiteNameVal: any;
  siteName: any;

  constructor(private cmsService: CmsService, private link: LinkTargetService, private cdr: ChangeDetectorRef, public dialog: MatDialog, private utilService: UtilService,
    public dialogRef:MatDialogRef<HeaderSitelinksComponent>,
    @Inject(DOCUMENT) private document: Document,
    private config: SiteContextConfig,
    private windowRef: WindowRef,
    private router: Router) {
    this.setLinkTarget = link.getTarget;
   }

  ngOnInit(): void {
    this.userDetails = this.cmsService.getContentSlot('SiteLinks').subscribe(data => {
      this.siteLinksArray = [];
      data.components?.forEach((resp,i) => {    
          this.cmsService.getComponentData(resp.uid+'').subscribe(response => {
          if(this.siteLinksArray.length <= i) {
            this.siteLinksArray.push(response);
          }
        });
      });
      this.cdr.detectChanges();
    });

    this.baseSiteName = this.config?.context?.baseSite;
    if (this.baseSiteName) {
      this.baseSiteNameVal = this.baseSiteName[0];
      this.siteName = this.utilService?.checkBaseSite();
    }
  }

  //sign in click function to trigger login dialog
  signInClick(){
  /** Removing the b2bUnit from session storage on signin click*/
    if(this.windowRef.localStorage?.getItem("b2bUnit")) {
      this.windowRef.localStorage.removeItem("b2bUnit");
    }

    if(this.windowRef.localStorage?.getItem("multipleB2bUnitFlag")) {
      this.windowRef.localStorage.removeItem("multipleB2bUnitFlag");
    }
    
    const dialogRef = this.dialog.open(LoginDialogComponent,
      {
        panelClass: [this.theme, 'custom-dialog-size'],
        autoFocus: false,
        disableClose: true
      });
      if(Object.keys(this.dialogRef).length !== 0){
        this.dialogRef.close();
      }      
  }

  ngOnDestroy() {
    this.siteLinksArray = [];
    this.userDetails.unsubscribe();
  }

}
