import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'emailMaskData'
})
export class emailMaskDataPipe implements PipeTransform {
    transform(originalData: string, visibleDataAtStart:number, visibleDataAtEnd: number): string {
      let splitData = originalData?.split("@");
      let maskedData, firstData, visibleData;
      if(splitData[0].length > 1 ) {
        maskedData = splitData[0]?.slice(1, -visibleDataAtEnd);
        firstData = splitData[0]?.slice(0, visibleDataAtStart);
        visibleData = splitData[0]?.slice(-visibleDataAtEnd);
        return firstData+maskedData.replace(/./g, '*') + visibleData + '@' + splitData[1];
      }
      else {        
        firstData = splitData[0]?.slice(0, visibleDataAtStart);
        return firstData + '@' + splitData[1];
      }

    }
  }