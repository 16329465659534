import { Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import { global } from 'src/app/shared/translations/en/global';

@Component({
  selector: 'app-order-total-card',
  templateUrl: './order-total-card.component.html'
})
export class OrderTotalCardComponent implements OnChanges{

  @Input() orderDetails: any;
  @Input() orderEntriesItems: any;
  @Input() returnStatus:any
  isCurbside: boolean = false;
  curbside = global.account.order.curbside;
 
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.orderDetails && changes.orderDetails.currentValue) {
      if (changes.orderDetails.currentValue.deliveryMode &&
        changes.orderDetails.currentValue.deliveryMode.code &&
        changes.orderDetails.currentValue.deliveryMode.code === 'vendor-dropship-overweight') {
        this.isCurbside = true;
      } else {
        this.isCurbside = false;
      }
    }
  }
 
}
