import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { RegisterService } from 'src/app/feature/components/register-account/register.service';
import { BoxoutEndpointService } from '../../services/common/boxout-endpoint.service';
import { SmartyAddressValidationService } from '../../services/common/smarty-address-validation.service';
import { HeaderService } from '../../services/header.service';
import { ReusableAddressFormComponent } from '../reusable-address-form/reusable-address-form.component';

@Component({
  selector: 'app-reusable-edit-ship-to-address',
  templateUrl: './reusable-edit-ship-to-address.component.html'
})
export class ReusableEditShipToAddressComponent implements OnInit, OnDestroy {

  @ViewChild(ReusableAddressFormComponent, { static: true })
  public addressFormComponent!: ReusableAddressFormComponent;

  @Input() item:any;
  @Input() componentName:any;
  @Input() manageAcctSiebelRowId:any;
  @Input() isPrimaryShipTo:any;
  @Input() isPrimaryBillTo:any;
  @Output() closeEditAddress: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeViewAddress: EventEmitter<any> = new EventEmitter<any>();
  @Input() viewAddressId:any;
  @Input() viewAddressAccountType:any;
  @Input() manageAccountType:any;
  @Input() isShipTo:boolean;
  @Input() isBillTo:boolean;
  smartyKeyValForAddressValidation:any;
  siteData:any;
  dataFromRecommendedDialogEditButton:any;
  editSubs:Subscription;
  user: User;
  userSub: Subscription;
  userId:any;
  siebelRowId:any;
  apiUrl:any;
  customerRole:any;
  data:any={value:{
    addAddressId:"",
    id:"",
    addressType:"",
    attn:"",
    primaryBilling:false,
    primaryShipping:false,
    address:{}
  }};

  editShipToAddressForm:any;
  payloadData: { addressSiebelRowID: any; isShippingClient: boolean; siebelRowId: any; };
  currentRouteURL: string;
  itemId: any;

  constructor(
    private formBuilder: FormBuilder,
    private smartyAddressValidationService: SmartyAddressValidationService,
    private headerService:HeaderService, 
    private customHttp: BoxoutEndpointService,
    private windowRef: WindowRef,
    private activatedRoute:ActivatedRoute,
    private store: Store<fromApp.AppState> ,
    private route:Router,
    
    
    private registerService:RegisterService   
    ) { }

  ngOnInit(): void {
    this.currentRouteURL=this.route.url;
    this.userSub = this.store.select('auth').subscribe(user => {
      this.user = user && user.user!;
      this.userId = this.user?.uid;
      this.customerRole= this.user?.customerRole;
      this.siebelRowId = this.user?.orgUnit?.siebelRowId;
    });
    //to get userid 
    // this.headerService.isLoggedIn.subscribe(response => {
    //   this.userData = response;
    // });
    // this.userData?.subscribe((data: any) => {
    //   this.userId = data?.uid;
    //   this.customerRole= data?.customerRole;
    //   this.siebelRowId = data?.orgUnit?.siebelRowId;
    // });

    if(this.item){
      
     if(this.item.shipTo && this.isShipTo){
      this.data.value.addAddressId=this.item.id ? this.item.id : this.manageAcctSiebelRowId;
      this.data.value.id=this.item.shipTo.id ? this.item.shipTo.id : "";
      this.data.value.addressType=this.item.shipTo?.addressType ? this.item.shipTo.addressType : "";
      this.data.value.attn=this.item.shipTo.attendant ? this.item.shipTo.attendant : "";
      this.data.value.primaryBilling=this.item.shipTo?.isPrimaryBillingAddress ? this.item.shipTo.isPrimaryBillingAddress : false;
      this.data.value.primaryShipping=this.item.shipTo?.isPrimaryShippingAddress ? this.item.shipTo.isPrimaryShippingAddress : false;
      this.data.value.address={
        addressOne: this.item.shipTo.line1 ? this.item.shipTo.line1 : "",
        optionalAddressTwo: this.item.shipTo.line2 ? this.item.shipTo.line2 : "",
        cityName: this.item.shipTo.town ? this.item.shipTo.town : "",
        stateName: this.item.shipTo.region.isocode ? this.item.shipTo.region.isocode : "",
        zipCode: this.item.shipTo.postalCode ? this.item.shipTo.postalCode : "",
        countryName:this.item.shipTo.country?.isocode ? this.item.shipTo.country.isocode : "",
      }
     }
     else if(this.item.billTo && this.isBillTo){
      this.data.value.addAddressId=this.item.id ? this.item.id : this.manageAcctSiebelRowId;
      this.data.value.id=this.item.billTo.id ? this.item.billTo.id : "";
      this.data.value.addressType=this.item.billTo.addressType ? this.item.billTo.addressType : "";
      this.data.value.attn=this.item.billTo.attendant ? this.item.billTo.attendant : "";
      this.data.value.primaryBilling=this.item.billTo.isPrimaryBillingAddress ? this.item.billTo.isPrimaryBillingAddress : false;
      this.data.value.primaryShipping=this.item.billTo.isPrimaryShippingAddress ? this.item.billTo.isPrimaryShippingAddress : false;
      this.data.value.address={
        addressOne: this.item.billTo.line1 ? this.item.billTo.line1 : "",
        optionalAddressTwo: this.item.billTo.line2 ? this.item.billTo.line2 : "",
        cityName: this.item.billTo.town ? this.item.billTo.town : "",
        stateName: this.item.billTo.region?.isocode ? this.item.billTo.region.isocode : "",
        zipCode: this.item.billTo.postalCode ? this.item.billTo.postalCode : "",
        countryName:this.item.billTo.country?.isocode ? this.item.billTo.country.isocode : "",
      }
     }
     else{
      this.data.value.addAddressId=this.manageAcctSiebelRowId ? this.manageAcctSiebelRowId : this.viewAddressId;
      this.data.value.id=this.item.id ? this.item.id : "";
      this.data.value.addressType=this.item.addressType ? this.item.addressType : "";
      this.data.value.attn=this.item.attendant ? this.item.attendant : "";
      this.data.value.primaryBilling=this.item.isPrimaryBillingAddress ? this.item.isPrimaryBillingAddress : false;
      this.data.value.primaryShipping=this.item.isPrimaryShippingAddress ? this.item.isPrimaryShippingAddress : false;
      this.data.value.address={
        addressOne: this.item.line1 ? this.item.line1 : "",
        optionalAddressTwo: this.item.line2 ? this.item.line2 : "",
        cityName: this.item.town ? this.item.town : "",
        stateName: this.item.region?.isocode ? this.item.region.isocode : "",
        zipCode: this.item.postalCode ? this.item.postalCode : "",
        countryName:this.item.country?.isocode ? this.item.country.isocode : "",
      }
     }

    }

    this.editShipToAddressForm = this.formBuilder.group({
      attn: [(this.data?.value?.attn ? this.data?.value?.attn: ""),[Validators.maxLength(30)]],
      address: this.addressFormComponent.createAddressForm(this.data?.value?.address ? this.data?.value?.address : ""),
      defaultShippgTo:[(this.data?.value?.primaryShipping ? this.data?.value?.primaryShipping : false)],
      defaultBillingTo:[(this.data?.value?.primaryBilling ? this.data?.value?.primaryBilling : false)],
      addressType:[(this.data.value.addressType ? this.data.value.addressType : "")],
      id:[(this.data.value.id ? this.data.value.id : "")],
      addAddressId:[(this.data.value.addAddressId ? this.data.value.addAddressId : "")],
      componentName:[(this.componentName ? this.componentName : "")]
    });


    //getting the key for smarty address API validation
    this.headerService.localPropertiesConfigData.subscribe((resp:any)=>{
      if(resp){
        this.siteData = resp;
        const smartyKeyVal: any = 'spartacus.config.smarty.key';
        this.smartyKeyValForAddressValidation = this.siteData[smartyKeyVal];
      }
      
    });

      //when user clicks Edit This Address button on recommened address dialog then 
    //below code populates edit ship to form again with prefilled values

    this.smartyAddressValidationService.receivedRecommendedData$.subscribe((sentData)=>{

    this.dataFromRecommendedDialogEditButton=sentData;

    if(!(JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("EditAccountReopened")))) && this.dataFromRecommendedDialogEditButton.editButtonClicked
    && this.dataFromRecommendedDialogEditButton.editAddressData.componentRef === "EditedShipToAddressAccount"){
    
      this.windowRef.sessionStorage?.setItem("EditAccountReopened","true");

      if((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("EditAccountReopened"))))){
        let editAddData=this.dataFromRecommendedDialogEditButton.editAddressData;
        editAddData.addressType=editAddData.addressType;
        this.editShipToAddressForm.patchValue(editAddData);
      }
    }
  });

  //On click of USe this address or Edit Ship To Account success scenerio

this.editSubs=this.smartyAddressValidationService.savedFormDataSource.subscribe((data)=>{

  if(data && data.componentRef === "EditedShipToAddressAccount" && 
  !(JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("finalEditShipToFormSubmitCalled"))))){
    this.finalFormSubmit(data);
  }
},(error)=>{
});

  
  }

  

  //This method is called to save edited ship To address
  saveEditedShipToAddress(){

    if(this.editShipToAddressForm.valid){
      let addressOne= this.editShipToAddressForm.get('address')?.value.addressOne;
      let optionalAddressTwo= this.editShipToAddressForm.get('address')?.value.optionalAddressTwo;
      let cityName= this.editShipToAddressForm.get('address')?.value.cityName;
      let stateIsocode= this.editShipToAddressForm.get('address')?.value.stateName;
      let stateText=this.smartyAddressValidationService.getStateText(stateIsocode);

      let zipCode= this.editShipToAddressForm.get('address')?.value.zipCode;
      let countryText='United States';

      //sending component reference so that when we subscribe subject releted to its specific component
      let componentReference="EditedShipToAddressAccount";

      //This is used to validate address in Edit ship To account form and submit form or it can also open
      //recommened address dialog in case if there are any recommened addresses
      this.smartyAddressValidationService.addressValidation(addressOne,optionalAddressTwo,cityName,stateIsocode,stateText,zipCode,countryText,this.editShipToAddressForm,this.smartyKeyValForAddressValidation,componentReference);
    }
    else{
      //invalid form alert
      this.editShipToAddressForm.markAllAsTouched();
      const firstElementWithError = document.querySelector('.ng-invalid');

      if (firstElementWithError) {
        firstElementWithError.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  
  //To submit the form and Edit Ship To Account
  finalFormSubmit(formData:any){

    this.windowRef.sessionStorage?.setItem("finalEditShipToFormSubmitCalled","true");

    const requestBody=this.formRequestBody(formData);

    //api url for Edit ship to  account
    if(formData.componentName && (formData.componentName === 'myClientAddress' || formData.componentName === 'manageClientAddress')){
      this.apiUrl=`users/${this.userId}/clients/${formData.addAddressId}?fields=DEFAULT`;
    }
    else{
    this.apiUrl=`users/${this.userId}/orgUnits/${formData.addAddressId}/updateAddress?fields=DEFAULT`;
    }
    
   if((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("finalEditShipToFormSubmitCalled"))))){

     this.customHttp.post(this.apiUrl, requestBody).subscribe(
      (data:any) => {
      this.windowRef.sessionStorage?.removeItem("finalEditShipToFormSubmitCalled");
       //to close recommended address dialog
       if(this.smartyAddressValidationService.openedDialog){
          this.smartyAddressValidationService.openedDialog.close();
       }
        //to refresh the data  page
        //To show success alert message when address is created
        if(formData.componentName && (formData.componentName === 'myClientAddress' || formData.componentName === 'manageClientAddress')){
          this.smartyAddressValidationService.saveClientAccountAlert(true);
         }
         else{
          this.smartyAddressValidationService.saveBusinessAccountAlert(true);
         }

         //close the edit drawer when address is edited successfully
         this.closeEditAddress.emit(true);

      },
      (error:any) => {     

        this.windowRef.sessionStorage?.removeItem("finalEditShipToFormSubmitCalled");
        //setting form data null for avoiding multiple call 
        this.smartyAddressValidationService.saveFormData(null);
         //   to close recommended address dialog
         if(this.smartyAddressValidationService.openedDialog){
          this.smartyAddressValidationService.openedDialog.close();
        }

        //To show error alert message when address is not created
        if(formData.componentName && (formData.componentName === 'myClientAddress' || formData.componentName === 'manageClientAddress')){
           this.smartyAddressValidationService.saveClientAccountAlert(false);
       }
        else{
         this.smartyAddressValidationService.saveBusinessAccountAlert(false);
        }
     });
   }
  }


  // This method is used to form request body which will be sent in Edit Ship To account POST Call
 formRequestBody(body:any): any{

    if(body){
    return {
      addressType: body?.addressType,
      id: body?.id,
      attendant: body?.attn,
      country: {
                 isocode: body.address.countryName,
                 name: "United States"
               },
               defaultAddress: true,
               deliveryNote: body.deliverNote,
               district: body.address.cityName,
               line1: body.address.addressOne,
               line2: body.address.optionalAddressTwo,
               postalCode: body.address.zipCode,
               region: {
                 countryIso: body.address.countryName,
                 isocode: body.address.stateName,
                 isocodeShort: body.address.stateName,
                 name: this.smartyAddressValidationService.getStateText(body.address.stateName)
               },
               town: body.address.cityName,
               visibleInAddressBook: true,
     shippingAddress:true,
     isPrimaryShippingAddress: body.defaultShippgTo,
     isPrimaryBillingAddress: body.defaultBillingTo
     }
    
    }
    
    }

  //On click on cancel , this opened edit address block should close
  closeThisShipToEditAddress(){
    this.closeEditAddress.emit(true);
  }

  ngOnDestroy(): void {
    if((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("finalEditShipToFormSubmitCalled"))))){
      this.windowRef.sessionStorage?.removeItem("finalEditShipToFormSubmitCalled");
    }

    this.item=null;
    this.editSubs.unsubscribe();

    if((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("EditAccountReopened"))))){
      this.windowRef.sessionStorage?.removeItem("EditAccountReopened");
    }

    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

  removeAddress(){
    const apiUrl=`/users/current/removeAddress?fields=DEFAULT`;
    if(this.viewAddressId){
    this.payloadData= {
      "addressSiebelRowID": this.item.id,
      "isShippingClient": false,
      "siebelRowId": this.viewAddressId
    }
  }
  if(this.manageAcctSiebelRowId){
    this.payloadData= {
      "addressSiebelRowID": this.item.id,
      "isShippingClient": false,
      "siebelRowId": this.manageAcctSiebelRowId
    }
  }

  if(this.currentRouteURL.includes('clients-addresses') || this.currentRouteURL.includes('manageClientsAccount')){
    this.payloadData.isShippingClient=true;
  }
  
  this.customHttp.post(apiUrl,this.payloadData).subscribe((res)=>{
    this.closeEditAddress.emit(true);
    this.closeViewAddress.emit(true);
    if(res?.isSuccess){   
      if(this.currentRouteURL.includes('clients-addresses')){
       this.registerService.removeClientAddressFromAccount('clients-address');
      }
      else if(this.currentRouteURL.includes('businessAccounts')){
        this.registerService.removeBusinessAddressFromAccount('businessAccounts');
      }
      else if(this.currentRouteURL.includes('manageAccount')){
        this.registerService.removeManageAcctFromAccount(this.manageAcctSiebelRowId);
      }
      else if(this.currentRouteURL.includes('manageClientsAccount')){
        this.activatedRoute.queryParams.subscribe((data)=>{
          this.itemId=data['id'];
        })  
        this.registerService.removeManageClientAcctFromAccount(this.itemId);
    }
    }
  });

}
}
