import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { PageLoaderService } from 'src/app/feature/pages/page-loader/page-loader.service';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { CartService } from 'src/app/shared/services/common/cart.service';
import { GTMService } from 'src/app/shared/services/common/gtm.service';
import { MixMatchPromotionComponent } from '../mix-match-promotion/mix-match-promotion.component';

interface OrderPromotion {
  description: string;
  promotion: {
    code: string;
    title:string;
    description: string;
    promotionType: string;
    combinedFlag: boolean;
    hasMixMatchItems?: boolean;
    isCoachForwardPromo?: boolean;
    isFreeFreightPromo?: boolean;
    promoType: string;
    discount: {
      formattedValue: string;
    };
  };
}

interface MixMatchPromo {
  promoTitle: string;
  promoCode: string;
  description: string;
  totalPointsEarned: number;
  offers: {
    selectedPoints: number;
    earnedPoints: number;
    products: {
      itemNumber: string; // SKU Number
      brandName: string;
      quantity: number;
      itemTitle: string;
      listPrice: number;
    }[];
  }[];
}
@Component({
  selector: 'app-promotion-slider',
  templateUrl: './promotion-slider.component.html',
})
export class PromotionSliderComponent implements OnInit, OnDestroy {
  orderPromotions?: OrderPromotion[] = [];
  user: User;
  isLoading: boolean = false;
  subscription: Subscription[] = [];
  gaItemsArray: any[]=[];
  dataSource: any;
  freeFreight: any;
  eligiblePromoCount: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private matDialog: MatDialog,
    private cartService: CartService,
    private store: Store<fromApp.AppState>,
    private customHttp: BoxoutEndpointService,
    private pageLoaderService:PageLoaderService,
    private gtmService: GTMService,
    private httpClient: HttpClient
  ) {
    console.log(this.data);
    this.eligiblePromoCount = this.data?.eligiblePromoCount || 0;
    this.orderPromotions = this.data?.orderLevelPromo || [];
    this.gaItemsArray = [];
    this.orderPromotions?.forEach((promo:any) => {
      let gaObj = {
        'promotion_id': promo?.promotion?.code,
        'promotion_name': promo?.promotion?.title,
        'creative_name': '',
        'creative_slot': '',
        'location_id': ''
      }
      this.gaItemsArray.push(gaObj);
    });
    this.gtmService.sendViewPromotionsData(this.gaItemsArray);
  }
  
  ngOnInit(): void {
    this.subscription.push(
      this.store.select('auth').subscribe((user) => {
        this.user = user && user.user!;
      })
    );

    // For getting updated Applied/Potential Order Promotions
    this.subscription.push(
      this.cartService.getCartData().subscribe((cart) => {
        if (cart) {
          // Mapping promotions with status (Applied/Available)
          const appliedOrderPromotions =
            cart?.appliedOrderPromotions?.map((e: OrderPromotion) => {
              return { ...e, status: 'Applied' };
            }) || [];
          const potentialOrderPromotions =
            cart?.potentialOrderPromotions?.map((e: OrderPromotion) => {
              return { ...e, status: 'Available' };
            }) || []; 
          // Handling free freight promotion
          let orderLevelPromo = [];
          orderLevelPromo = [...appliedOrderPromotions, ...potentialOrderPromotions]
    
          // Combine all promotions
          this.orderPromotions = orderLevelPromo;
        }
      })
    );
  }

  // For getting Mix-Match/Discount+Free-Product Promotion details
  getMixMatchPromoDetails = (
    promo: OrderPromotion,
    type?: 'APPLY' | 'EDIT' | 'CANCEL'
  ): Observable<MixMatchPromo> => {
    let cartId = sessionStorage.getItem('cartId');
    const getMixMatch = `/users/${
      this.user?.uid
    }/carts/${cartId}/select-mix-match/${promo?.promotion?.code}?edit=${
      type === 'EDIT'
    }&fields=DEFAULT`;

    return this.customHttp.get(getMixMatch);
  };

  // For handling promotion status change
  handlePromotionStatus = (
    promo: OrderPromotion,
    isApplied: boolean,
    type: 'CANCEL' | 'APPLY' | 'EDIT',
    isMixMatch: boolean
  ) => {
    this.isLoading = true;
    if ((isMixMatch && type === 'CANCEL') || !isMixMatch) {
      let cartId = sessionStorage.getItem('cartId');
      let applyPromotion = `/users/${this.user?.uid}/carts/${cartId}/applyPromotion/${promo?.promotion?.code}`;

      if(promo?.promotion?.combinedFlag !== undefined) {
        applyPromotion += `?isCombinable=${promo?.promotion?.combinedFlag}`
      }
      const removePromotion = `/users/${this.user?.uid}/carts/${cartId}/removePromotion/${promo?.promotion?.code}`;

      // Changin Promotion Status
      this.subscription.push(
        this.customHttp
          .post(isApplied ? removePromotion : applyPromotion)
          .subscribe((data: any) => {
            this.cartService.getCartPageData(this.user?.uid, false);
            this.isLoading = false;
            if(!isApplied) {
              let gaPromoObj = {
                'promotion_id': promo?.promotion?.code,
                'promotion_name': promo?.promotion?.title,
                'creative_name': '',
                'creative_slot': '',
                'location_id': ''
              }
              this.gtmService.sendSelectPromotionsData([gaPromoObj]);
            }

          })
      );
    } else {
      // Getting Mix-Match/Discount+Free-Product data
      // And opening mix-match promo modal
      this.subscription.push(
        this.getMixMatchPromoDetails(promo, type).subscribe(
          (mixMatchPromoData: MixMatchPromo) => {
            // Opening only when the promo data come
            if (mixMatchPromoData) {
              this.matDialog.open(MixMatchPromotionComponent, {
                panelClass: 'mix-match-promotion-container',
                autoFocus: false,
                data: {
                  ...mixMatchPromoData,
                  promoCode: promo?.promotion?.code,
                  combinedFlag: promo?.promotion?.combinedFlag,
                  description: promo?.description,
                },
              });
              this.isLoading = false;
            }
          }
        )
      );
    }
  };

  ngOnDestroy(): void {
    if (this.subscription?.length) {
      this.subscription.forEach((sub: Subscription) => sub.unsubscribe());
    }
  }
}
