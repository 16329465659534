import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromApp from 'src/app/core/store/app.reducer';
import { User } from '../../../core/models/user.model';
import { AddressService } from '../../services/common/address.service';
import { BoxoutEndpointService } from '../../services/common/boxout-endpoint.service';
import { CurrentThemeService } from '../../services/common/current-theme.service';
import { SmartyAddressValidationService } from '../../services/common/smarty-address-validation.service';
import { AddShipToCartbusinessAddressComponent } from '../add-ship-to-cartbusiness-address/add-ship-to-cartbusiness-address.component';
@Component({
  selector: 'app-bill-to-checkout-address',
  templateUrl: './bill-to-checkout-address.component.html',
})
export class BillToCheckoutAddressComponent implements OnInit {
  currentTheme: any;
  userId: any;
  siebelRowId: any;
  user: User;
  userSub: Subscription;
  viewAddresses: any = [];
  displayUid: any;
  shippingAddressSelectedSuccess: boolean = false;
  shippingAddressSelectedFailure: boolean = false;
  constructor(public dialog: MatDialog, private theme: CurrentThemeService,
    private smartyAddressValidationService: SmartyAddressValidationService,
    @Inject(MAT_DIALOG_DATA) public data: any, private addressService: AddressService,
    private store: Store<fromApp.AppState>, private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<BillToCheckoutAddressComponent>,
    private customHttp: BoxoutEndpointService) { }

  ngOnInit(): void {
    /** Taking themes from themeservice **/
    this.currentTheme = this.theme?.getCurrentTheme();
    this.userSub = this.store.select('auth').subscribe(user => {
      this.user = user && user.user!;
      this.userId = this.user?.uid;
      this.displayUid = this.user?.displayUid;
      this.siebelRowId = this.user?.orgUnit?.siebelRowId;
      if (this.userId && this.siebelRowId) {
        let otherAddress = this.data?.value?.itemData;
        let callShipTo =  this.data?.value?.callShipTo;
        this.getClientViewAddress(otherAddress,callShipTo);
      }
    });

    this.billToCheckoutAddressAdded();
  }

  //This method is used to show all ship to other addresses in Other Ship Address drawer
  getClientViewAddress(item: any,callShipTo:any) {
    let apiURL = `users/current/orgUnits/${this.siebelRowId}/addresses`;
    this.addressService
      .getClientViewAddresses(apiURL)
      .subscribe((data: any) => {
        if (data) {
          //Filtering out the shipto and bill to
          data.addresses = data.addresses.filter((resp: any) => resp.id != item?.shipTo?.id);
          if (item?.billTo?.id) {
            data.addresses = data.addresses.filter((resp: any) => resp.id != item?.billTo?.id);
          }
          this.viewAddresses = data.addresses;
          this.cdr.detectChanges();
        }
      });
  }



  //This method is used to select address for cart ship when user selects address from other addresses block
  selectAddress(item: any) {
    this.shippingAddressSelectedSuccess = false;
    this.shippingAddressSelectedFailure = false;
    let cartId = sessionStorage.getItem('cartId');
    let requestBody = {
      addressSiebelRowID: item?.id,
      cartId:cartId,
      isBillTo: true,
      isShipTo: false,
      siebelRowId: this.siebelRowId
    };

    let apiUrl = `users/current/carts/${cartId}/setCheckAddresses/?fields=DEFAULT`;
    this.customHttp.post(apiUrl, requestBody).subscribe(
      (resp: any) => {
        let obj= {'isShip' : false ,value : resp}
        this.dialogRef.close();
        this.addressService.setSelectShipAddressData(obj)
      },
      (error: any) => {
        this.shippingAddressSelectedSuccess = false;
        this.shippingAddressSelectedFailure = true;
      }
    );
  }
  // This method opens up add ship to address drawer
  openAddShipToDialog(item: any) {
    this.dialogRef.close();
    //setting form data null for avoiding multiple call 
    this.smartyAddressValidationService.saveFormData(null);
    this.dialog.open(AddShipToCartbusinessAddressComponent,
      {
        width: '368px',
        panelClass: [this.currentTheme, 'ship-to-client-drawer-for-all'],
        position: {
          right: '0px',
          top: '0px'
        },
        data: {
          value: {
            action: "addingNewAddressForClientOrPatientShipToAccount",
            openedDrawerFrom: "openedAddBillToFromCheckout",
            itemData: item,
            addAddressId: item?.id,
            callShipTo: this.data?.value?.callShipTo,
            b2bSiebelId: this.data?.value?.b2bSiebelId,
            shipToSiebelId: this.data?.value?.shipToSiebelId
          }
        }
      });
  }



  //if bill to checkout address is added on checkout page 
  billToCheckoutAddressAdded(){
    this.smartyAddressValidationService.saveAddedAddressForCheckoutBillTOData$.subscribe((val)=>{
     
     if (!this.dialog?.openDialogs?.length){
 
       this.dialog.open(BillToCheckoutAddressComponent, {
         position: {
           right: '0px',
           top: '0px'
         },
         panelClass: ['wishlist-drawer', 'business-account-dialogs-style'],
         data: {
           value:{
             showSuccessForCart:val,
             showFailureForCart:!val,
             itemData: this.data?.value?.item,
             callShipTo: this.data?.value?.callShipTo,
             b2bSiebelId:this.data?.value?.b2bSiebelId,
             shipToSiebelId:this.data?.value?.shipToSiebelId
           }
         }
       });
     }
   });
   }


}
