
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { SearchServiceService } from '../search-service.service';

@Component({
  selector: 'app-product-facets',
  templateUrl: './product-facets.component.html'
})
export class ProductFacetsComponent implements OnInit,OnDestroy, OnChanges {
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;
  private _dataUrl = '../../../../../assets/mock-json/search-page.json';
  searchSubscription:any;
  public sidebarShow: boolean = false;
   sliderOn: boolean;
  @Input() facetData: any;
  @Output() facetDataObj:EventEmitter<object>=new EventEmitter<object>();
  @Output() closeSlider: EventEmitter<any> = new EventEmitter();
  @Output() clearSelectedFilters:EventEmitter<any>=new EventEmitter();
  @Input() searchParam='';
  filteredItems:any=[];
  isExpanded: any = {};
  facetStatus:boolean;
  facetQuery:any='';
  
  constructor(private windowRef: WindowRef, private searchService:SearchServiceService, 
    @Inject(DOCUMENT) private document: Document) {
 
  }

  ngOnInit(): void {
    this.searchSubscription=
    this.searchService.sharedData$
    .subscribe(sharedData => this.sliderOn = sharedData);
}
  
//selected facet status will set here
facetChecked(e:any,itemSelected:any){
  if(e.target.checked){
    this.facetStatus=true;
    this.facetQuery=itemSelected.code;
    this.filteredItems.push(itemSelected);

  }else{
    this.facetStatus=false;
    this.facetQuery=itemSelected.code;
    this.filteredItems=this.filteredItems.filter((item:any)=>item?.code!==itemSelected?.code)
  }
  this.facetDataObj.emit({'facetStatus':this.facetStatus,'facetQueryCode':this.facetQuery});
  this.isExpanded = {};
  this.windowRef.nativeWindow?.scrollTo(0,0);
}

  onShowMoreClick(index:any){
    this.isExpanded[index]=true;
  }
  onShowLessClick(index:any){
    this.isExpanded[index]=false;
  }
  //removes the selected facet when user clicks on close button in chip
  remove(selectedItem:any){
    this.facetStatus=false;
    this.facetDataObj.emit({'facetStatus':this.facetStatus,'facetQueryCode':selectedItem.code});
    this.filteredItems=this.filteredItems.filter((item:any)=>item!==selectedItem);
    
  }
  onCloseFilters(){
    this.searchService.setData(false);
    this.document.body.style.overflowY = 'auto';
    //to scroll to top on closing filters
    this.document.getElementsByClassName('displaySlider')[0].scrollTop = 0;
  }
  ngOnDestroy(): void {
    this.searchSubscription.unsubscribe();
  }

  //clears all the facet filters selected by user and emit the flag to product-search component
  clearAllFilters(){
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
    this.filteredItems=[];
    this.clearSelectedFilters.emit(true);

  }
  ngOnChanges(changes: SimpleChanges){
    if(changes?.searchParam?.currentValue){
      this.filteredItems=[];
    }
  }
}
