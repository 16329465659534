<div class="business-addresses">
  <!--  to show the error/success on Business account creation: start-->
  
  <div *ngIf="showFailure" class="mt-3">
    <reusable-alert-box
      [alertType]="'warningAlertBox'"
      alertMsg="{{ 'account.businessAccounts.infoUpdateError' | cxTranslate }}"
    ></reusable-alert-box>
  </div>

  <div *ngIf="showSuccess" class="mt-3">
    <reusable-alert-box
      [alertType]="'successAlertBox'"
      alertMsg="{{
        'account.businessAccounts.infoUpdateSuccess' | cxTranslate
      }}"
    ></reusable-alert-box>
  </div>
  <!--  to show the error/success on Business account creation : End-->
  <div>
    <div *ngIf="addresses && addresses.length">
      <div
        class="card mt-3"
        *ngFor="
          let item of addresses
            | paginate
              : {
                  itemsPerPage: totalRecordsOnPage,
                  currentPage: page,
                  totalItems: totalPages
                };
          let i = index
        "
      >
        <div class="card-body d-flex-column justify-content-between p-2">
          <div class="d-flex p-1 mb-1 justify-content-between business-addresses-header align-items-center"
            *ngIf="componentName !== 'manageClientAddress'">
             <!-- Hiding the remove selected shipto checkbox as part of BCGI2-2837 **** 
              *** uncomment the below commented code when this functionality has to be used -->
            <div *ngIf="componentName === 'myClientAddress'" class="d-flex align-items-center business-addresses-header-checkbox">
              <!-- <mat-checkbox class="mr-2" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(item) : null"
              [checked]="selection.isSelected(item)"></mat-checkbox> -->
              <p class="m-0 t-title-m text-center">{{ item?.name }}</p>
            </div>
            <p *ngIf="componentName !== 'myClientAddress'" class="m-0 t-title-m text-center">{{ item?.name }}</p>
            <p class="m-0 t-label-m text-center">
              {{ "account.businessAccounts.acct" | cxTranslate }}: {{ item.id }}
            </p>
          </div>
          <div
            class="d-flex p-2 justify-content-between business-addresses-sub-header"
            *ngIf="componentName !== 'manageClientAddress'"
          >
            <p
              class="m-0 t-label-s text-center"
              *ngIf="
                item?.accountType?.toUpperCase() === 'PARENT' ||
                item?.accountType?.toUpperCase() === 'DIRECT'
              "
            >
              {{ "account.businessAccounts.primary" | cxTranslate }}
            </p>
            <p
              class="m-0 t-label-s text-center"
              *ngIf="item?.accountType?.toUpperCase() === 'DROPSHIP'"
            >
              {{ "account.businessAccounts.subAccountTo" | cxTranslate }}
              {{ item?.parentUnit?.name }} | {{ item?.parentUnit?.id }}
            </p>
            <p
              class="m-0 t-label-s text-center"
              *ngIf="componentName === 'myClientAddress'"
            >
              {{ "account.clientAndAddress.ribbenHeading" | cxTranslate }}
            </p>
            <p class="m-0 t-label-s text-center">
              {{ "account.businessAccounts.created" | cxTranslate }}:
              {{ item?.createdDate | date: "MM/dd/y" }}
            </p>
          </div>

          <!--Ship To-->
          <div *ngIf="item?.shipTo">
            <div
              class="p-0.75 mt-1 d-flex justify-content-between business-addresses-sub-header-2"
            >
              <div class="d-flex">
                <p class="m-0 px-2 py-1 t-label-s business-addresses-number">
                  {{ 1 }}
                </p>
                <p class="m-0 p-1 t-label-s text-center">
                  {{ "account.businessAccounts.created" | cxTranslate }}
                  {{
                    item?.shipTo?.createdDate || item?.shipTo?.creationTime
                      | date: "MM/dd/y"
                  }}
                </p>
              </div>
              <div>
                <p class="m-0 p-1 t-label-s text-center">
                  {{ "account.businessAccounts.id" | cxTranslate }}:
                  {{ item?.shipTo?.id ? item?.shipTo?.id : "" }}
                </p>
              </div>
            </div>
            <div class="address-border">
              <div>
                <div class="d-flex p-1">
                  <p class="m-0 business-addresses-line-design"></p>
                  <div class="d-flex flex-column pl-3 mb-2 mt-2 w-100">
                    <div *ngIf="item?.shipTo" class="d-flex flex-row">
                      <div
                        *ngIf="item?.shipTo?.isPrimaryShippingAddress"
                        class="outlined-badge-success t-address-type mr-2"
                      >
                        {{
                          "account.businessAccounts.primaryShipTo" | cxTranslate
                        }}
                      </div>
                      <div
                        *ngIf="item?.shipTo?.isPrimaryBillingAddress"
                        class="outlined-badge-success t-address-type mr-2"
                      >
                        {{
                          "account.businessAccounts.primaryBillTo" | cxTranslate
                        }}
                      </div>

                      <div
                        class="outlined-badge-secondary-2 t-address-type"
                        *ngIf="item?.shipTo?.addressType"
                      >
                        {{
                          (item?.shipTo?.addressType
                            ? item?.shipTo?.addressType
                            : ""
                          ) | titlecase
                        }}
                      </div>
                    </div>
                    <p
                      class="mt-2 mb-0 t-body-s"
                      *ngIf="item?.shipTo?.attendant"
                    >
                      Attn: {{ item?.shipTo?.attendant | titlecase }}
                    </p>
                    <p class="mt-1 mb-0 t-body-s">
                      {{ item?.shipTo?.line1 ? item.shipTo.line1 : "" }}
                    </p>
                    <p class="m-0 t-body-s">
                      {{ item?.shipTo?.line2 ? item.shipTo.line2 : "" }}
                    </p>
                    <p class="mb-0 mt-0 t-body-s">
                      {{ item?.shipTo?.town ? item.shipTo.town : "" }},
                      {{(item?.shipTo?.region?.isocode) ? ((item?.shipTo?.region?.isocode).replace("USA-","")+ " ") : ""}}
                      {{
                        item?.shipTo?.postalCode ? item.shipTo.postalCode : ""
                      }}
                      {{
                        item?.shipTo?.country?.isocode
                          ? item.shipTo.country.isocode
                          : ""
                      }}
                    </p>

                    <!-- Needed for future release -->
                    <div class="mt-3 d-flex align-self-md-end">
                     <!--  <span class="dashed p-2 t-label-m">{{
                        "account.businessAccounts.viewOrderHistory"
                          | cxTranslate
                      }}</span> -->
                      <!-- Needed for future release -->
                      <!-- <button
                        mat-flat-button
                        color="primary"
                        class="mx-4 t-label-m createShipment"
                      >
                        <mat-icon svgIcon="create_ship"></mat-icon>
                        {{
                          "account.businessAccounts.createShipment"
                            | cxTranslate
                        }}
                      </button> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Section to show edit Address block for ship To addresses when user clicks on edit address button-->
            <div
              class="address-border mb-3"
              *ngIf="
                item.shipTo.id &&
                editThisAddress === item.shipTo.id &&
                !closeEditAddressBlockVal
              "
            >
              <app-reusable-edit-ship-to-address
                [item]="item"
                [componentName]="componentName"
                [manageAcctSiebelRowId]="manageAcctSiebelRowId"
                [isShipTo]="isShipTo"
                [manageAccountType]="manageAccountType"
                [isPrimaryShipTo]="item?.shipTo?.isPrimaryShippingAddress"
                [isPrimaryBillTo]="item?.billTo?.isPrimaryBillingAddress"
                (closeEditAddress)="closeEditAddressBlock($event)"
                (closeViewAddress)="closeViewAddress($event)"
              ></app-reusable-edit-ship-to-address>
            </div>

            
            <!--Edit address button should not be shown for Parent/Direct account with Approver/Purchaser customer role having Bill To address-->
            <ng-container>
              <div
                class="mb-3"
                *ngIf="
                  !(
                    item.shipTo.id &&
                    editThisAddress === item.shipTo.id &&
                    !closeEditAddressBlockVal
                  ) 
                  && !(
                    (user?.customerRole === 'Approver' || user?.customerRole === 'Purchaser') &&
                       (item?.shipTo?.isPrimaryBillingAddress)
                  ) 
                "
              >
                <span
                  class="cursor-pointer p-2 editAddress t-label-s" 
                  (click)="editShipToAddressButtonClicked(item)"
                >
                 {{ "account.businessAccounts.editAddress" | cxTranslate }}
                  <mat-icon svgIcon="edit_address"></mat-icon
                ></span>
              </div>
            </ng-container>
          </div>
          <!--Bill To-->
          <div *ngIf="item?.billTo">
            <div
              class="p-0.75 mt-1 d-flex justify-content-between business-addresses-sub-header-2"
            >
              <div class="d-flex">
                <p class="m-0 px-2 py-1 t-label-s business-addresses-number">
                  {{ item?.shipTo ? 2 : 1 }}
                </p>
                <p class="m-0 p-1 t-label-s text-center">
                  {{ "account.businessAccounts.created" | cxTranslate }}
                  {{
                    item?.billTo?.createdDate || item?.billTo?.creationTime
                      | date: "MM/dd/y"
                  }}
                </p>
              </div>
              <div>
                <p class="m-0 p-1 t-label-s text-center">
                  {{ item?.billTo?.id ? item?.billTo?.id : "" }}
                </p>
              </div>
            </div>
            <div class="address-border">
              <div>
                <div class="d-flex p-1">
                  <p class="m-0 business-addresses-line-design"></p>
                  <div class="d-flex flex-column pl-3 mb-2 mt-2 w-100">
                    <div
                      class="d-flex flex-row"
                      *ngIf="
                        item?.billTo?.isPrimaryBillingAddress ||
                        item.billTo?.addressType
                      "
                    >
                      <div
                        *ngIf="item?.billTo?.isPrimaryBillingAddress"
                        class="outlined-badge-success t-address-type mr-2"
                      >
                        {{
                          "account.businessAccounts.primaryBillTo" | cxTranslate
                        }}
                      </div>
                      <div
                        *ngIf="item?.billTo?.addressType"
                        class="outlined-badge-secondary-2 t-address-type"
                      >
                        {{
                          item.billTo?.addressType
                            ? item.billTo.addressType
                            : ("" | titlecase)
                        }}
                      </div>
                    </div>
                    <p
                      class="mt-2 mb-0 t-body-s"
                      *ngIf="item?.billTo?.attendant"
                    >
                      Attn: {{ item?.billTo?.attendant | titlecase }}
                    </p>
                    <p class="mt-1 mb-0 t-body-s">
                      {{ item?.billTo?.line1 ? item.billTo.line1 : "" }}
                    </p>
                    <p class="m-0 t-body-s">
                      {{ item?.billTo?.line2 ? item.billTo.line2 : "" }}
                    </p>
                    <p class="mb-1 mt-0 t-body-s">
                      {{ item?.billTo?.town ? item.billTo.town : "" }},
                      {{(item?.billTo?.region?.isocode) ? ((item?.billTo?.region?.isocode).replace("USA-","")+ " ") : ""}}
                      {{
                        item?.billTo?.postalCode ? item.billTo.postalCode : ""
                      }}
                      {{
                        item?.billTo?.country?.isocode
                          ? item.billTo.country.isocode
                          : ""
                      }}
                    </p>

                    <!-- Needed for future release -->
                    <div class="d-flex align-self-md-end">
                     <!--  <span class="dashed p-2 t-label-m">{{
                        "account.businessAccounts.viewOrderHistory"
                          | cxTranslate
                      }}</span> -->
                      <!-- Needed for future release -->
                      <!-- <button
                        mat-flat-button
                        color="primary"
                        class="mx-4 t-label-m createShipment"
                      >
                        <mat-icon svgIcon="create_ship"></mat-icon>
                        {{
                          "account.businessAccounts.createShipment"
                            | cxTranslate
                        }}
                      </button> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--Section to show edit Address block for Bill To addresses when user clicks on edit address button-->
            <div
              class="address-border mb-3"
              *ngIf="
                item.billTo.id &&
                editThisAddress === item.billTo.id &&
                !closeEditAddressBlockVal
              "
            >
              <app-reusable-edit-ship-to-address
                [item]="item"
                [componentName]="componentName"
                [manageAcctSiebelRowId]="manageAcctSiebelRowId"
                [isBillTo]="isBillTo"
                [isPrimaryShipTo]="item?.shipTo?.isPrimaryShippingAddress"
                [isPrimaryBillTo]="item?.billTo?.isPrimaryBillingAddress"
                [manageAccountType]="manageAccountType"
                (closeEditAddress)="closeEditAddressBlock($event)"
                (closeViewAddress)="closeViewAddress($event)"
              ></app-reusable-edit-ship-to-address>
            </div>

            <!--Edit address button should not be shown for Parent/Direct account with Approver/Purchaser customer role having Bill To address-->
            <ng-container
              *ngIf="
                !(
                  user.orgUnit?.unitType === 'PARENT' &&
                  (user.customerRole === 'Approver' ||
                    user.customerRole === 'Purchaser') &&
                  item.billTo
                ) &&
                !(
                  user.orgUnit?.unitType === 'DIRECT' &&
                  (user.customerRole === 'Approver' ||
                    user.customerRole === 'Purchaser') &&
                  item.billTo
                )
              "
            >
              <div
                class="mb-3"
                *ngIf="
                  !(
                    editThisAddress === item.billTo.id &&
                    !closeEditAddressBlockVal
                  ) && (item.editBillTo || editBillTo)
                "
              >
                <span
                  class="p-2 editAddress t-label-s"
                  (click)="editBillToAddressButtonClicked(item)"
                >
               {{ "account.businessAccounts.editAddress" | cxTranslate }}
                  <mat-icon svgIcon="edit_address"></mat-icon
                ></span>
              </div>
            </ng-container>
          </div>

          <!--View Addresses-->
          <div
            *ngIf="
              viewAddresses &&
              viewAddresses.length &&
              (openAddress === item.id || openAddress === manageAcctSiebelRowId)
            "
          >
            <div *ngFor="let viewItem of viewAddresses; let j = index">
              <div
                class="p-0.75 mt-1 d-flex justify-content-between business-addresses-sub-header-2"
              >
                <div class="d-flex">
                  <p class="m-0 px-2 py-1 t-label-s business-addresses-number">
                    {{ item?.shipTo && item?.billTo ? j + 3 : j + 2 }}
                  </p>
                  <p class="m-0 p-1 t-label-s text-center">
                    {{ "account.businessAccounts.created" | cxTranslate }}
                    {{ viewItem.creationTime }}
                  </p>
                </div>
                <div>
                  <p class="m-0 p-1 t-label-s text-center">
                    {{ "account.businessAccounts.id" | cxTranslate }}:
                    {{ viewItem.id }}
                  </p>
                </div>
              </div>
              <div class="address-border">
                <div>
                  <div class="d-flex p-1">
                    <p class="m-0 business-addresses-line-design"></p>
                    <div class="d-flex flex-column pl-3 mb-2 mt-3 w-100">
                      <div *ngIf="viewItem.addressType" class="d-flex flex-row">
                        <div class="outlined-badge-secondary-2 t-address-type">
                          {{
                            viewItem.addressType
                              ? viewItem.addressType
                              : ("" | titlecase)
                          }}
                        </div>
                      </div>
                      <p class="mt-2 mb-0 t-body-s" *ngIf="viewItem?.attendant">
                        Attn: {{ viewItem?.attendant | titlecase }}
                      </p>

                      <p class="mt-1 mb-0 t-body-s">
                        {{ viewItem?.line1 ? viewItem.line1 : "" }}
                      </p>
                      <p class="m-0 t-body-s">
                        {{ viewItem?.line2 ? viewItem.line2 : "" }}
                      </p>
                      <p class="mb-0 mt-0 t-body-s">
                        {{ viewItem?.town ? viewItem.town : "" }},
                        {{(viewItem?.region?.isocode) ? ((viewItem?.region?.isocode).replace("USA-","")+ " ") : ""}}
                        {{ viewItem?.postalCode ? viewItem.postalCode : "" }}
                        {{
                          viewItem?.country?.isocode
                            ? viewItem.country.isocode
                            : ""
                        }}
                      </p>

                      <!-- Needed for future release -->
                      <div class="mt-3 d-flex align-self-md-end">
                        <!-- <span class="dashed p-2 t-label-m">{{
                          "account.businessAccounts.viewOrderHistory"
                            | cxTranslate
                        }}</span> -->
                        <!-- Needed for future release -->
                        <!-- <button
                          mat-flat-button
                          color="primary"
                          class="mx-4 createShipment"
                        >
                          <mat-icon svgIcon="create_ship"></mat-icon>
                          {{
                            "account.businessAccounts.createShipment"
                              | cxTranslate
                          }}
                        </button> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--Section to show edit Address block for addresses when user clicks on edit address button inside View Addresses section-->
              <div
                class="address-border mb-3"
                *ngIf="
                  viewItem.id &&
                  editThisAddress === viewItem.id &&
                  !closeEditAddressBlockVal
                "
              >
                <app-reusable-edit-ship-to-address
                  [item]="viewItem"
                  [componentName]="componentName"
                  [manageAcctSiebelRowId]="manageAcctSiebelRowId"
                  [viewAddressId]="viewAddressId"
                  [isPrimaryShipTo]="viewItem.isPrimaryShippingAddress"
                  [viewAddressAccountType]="viewAddressAccountType"
                  [manageAccountType]="manageAccountType"
                  (closeEditAddress)="closeEditAddressBlock($event)"
                  (closeViewAddress)="closeViewAddress($event)"
                ></app-reusable-edit-ship-to-address>
              </div>

              <!--Edit address button should not be shown for Parent/Direct account with Approver/Purchaser customer role having Bill To address-->
              <ng-container
                *ngIf="
                  !(
                    user.orgUnit?.unitType === 'PARENT' &&
                    (user.customerRole === 'Approver' ||
                      user.customerRole === 'Purchaser') &&
                    viewItem.billTo
                  ) &&
                  !(
                    user.orgUnit?.unitType === 'DIRECT' &&
                    (user.customerRole === 'Approver' ||
                      user.customerRole === 'Purchaser') &&
                    viewItem.billTo
                  ) 
                "
              >
                <div
                  class="mb-3"
                  *ngIf="
                    !(
                      editThisAddress === viewItem.id &&
                      !closeEditAddressBlockVal
                    )
                  "
                >
                  <span
                    class="cursor-pointer p-2 editAddress t-label-s"
                    (click)="editAddressButtonClicked(viewItem)"
                  >
                   {{ "account.businessAccounts.editAddress" | cxTranslate }}
                    <mat-icon svgIcon="edit_address"></mat-icon
                  ></span>
                </div>
              </ng-container>
            </div>
          </div>

          <!--Action buttons-->

          <div class="d-flex justify-content-between actionButton mt-3">
            <button
              mat-flat-button
              color="white"
              class="t-label-l shipTo"
              (click)="openAddShipToAddressDialog(item)"
            >
              <mat-icon svgIcon="ship_to"></mat-icon>
              {{ "account.businessAccounts.addShipTo" | cxTranslate }}
            </button>
            <button
              mat-flat-button
              *ngIf="componentName!=='manageAcct'"
              color="white"
              (click)="redirectToOrderHistory(item)"
              class="t-label-l orderHistory"
              [ngClass]="{
                'only-one-address': item?.addressCount < 2,
                'one-address-and-one-account':
                  item?.addressCount < 2 && componentName === 'myClientAddress',
                'only-one-account': componentName === 'myClientAddress'
              }"
            >
             {{ "account.businessAccounts.orderHistory" | cxTranslate }}
            </button>
            <button
              mat-flat-button
              *ngIf="componentName==='manageAcct'"
              color="white"
              (click)="redirectToOrderHistory(uid)"
              class="t-label-l orderHistory"
              [ngClass]="{
                'only-one-address': item?.addressCount < 2,
                'one-address-and-one-account':
                  item?.addressCount < 2 && componentName === 'myClientAddress',
                'only-one-account': componentName === 'myClientAddress'
              }"
            >
             {{ "account.businessAccounts.orderHistory" | cxTranslate }}
            </button>
            <ng-container *ngIf="item.addressCount > 0 && (item.accountType==='PARENT' || manageAccountType==='PARENT' || manageClientType==='PARENT')">
              <button
                mat-flat-button
                color="white"
                *ngIf="
                  (!(openAddress === item.id) &&
                    componentName !== 'manageAcct') ||
                  (componentName === 'manageAcct' &&
                    openAddress !== manageAcctSiebelRowId)
                "
                class="t-label-l viewAddress"
                (click)="onViewAddressClick(item)"
              >
                {{ "account.businessAccounts.viewAddresses" | cxTranslate }}
                <span
                  *ngIf="item?.addressCount > 0"
                  class="viewAddressCircle"
                  >{{ item?.addressCount }}</span
                >
              </button>
            </ng-container>

            <!-- making address count logic similar for parent and sirect account as be has changed there logic. STill keeping 2 conditions in case we need to revert -->
            <ng-container *ngIf="item.addressCount > 0 && (item.accountType!=='PARENT' && manageAccountType!=='PARENT' &&  manageClientType!=='PARENT')">
              <button
                mat-flat-button
                color="white"
                *ngIf="
                  (!(openAddress === item.id) &&
                    componentName !== 'manageAcct') ||
                  (componentName === 'manageAcct' &&
                    openAddress !== manageAcctSiebelRowId)
                "
                class="t-label-l viewAddress"
                (click)="onViewAddressClick(item)"
              >
                {{ "account.businessAccounts.viewAddresses" | cxTranslate }}
                <span
                  *ngIf="item?.addressCount > 0"
                  class="viewAddressCircle"
                  >{{ item?.addressCount }}</span
                >
              </button>
            </ng-container>

            <button
              mat-flat-button
              color="white"
              *ngIf="
                viewAddress &&
                (openAddress === item.id ||
                  openAddress === manageAcctSiebelRowId)
              "
              class="t-label-l hideAddress d-none d-md-flex"
              (click)="onHideAddressClick()"
            >
              {{ "account.businessAccounts.hideAddresses" | cxTranslate }}
              <mat-icon svgIcon="up_arrow" class="up-arrow"></mat-icon>
            </button>
            <button
              mat-flat-button
              color="white"
              *ngIf="
                componentName !== 'myClientAddress' &&
                isAdmin &&
                !isManageAccount &&
                componentName !== 'manageClientAddress'
              "
              class="t-label-l manageUsers"
              routerLink="/my-account/userAdmin"
              [queryParams]="{ siebelRowId: item?.id, query: 'present'}"
            >
              <mat-icon svgIcon="manage_users"></mat-icon>
              {{ "account.businessAccounts.manageUsers" | cxTranslate }}
            </button>
            <button
              [class.button-client-acc]="componentName === 'myClientAddress'"
              mat-flat-button
              color="white"
              class="t-label-l manageAcct"
              *ngIf="
                isAdmin &&
                !isManageAccount &&
                componentName !== 'manageClientAddress'
              "
              (click)="onManageAcctClick(item)"
            >
              <mat-icon svgIcon="manage_acct"></mat-icon>
              {{ "account.businessAccounts.manageAcct" | cxTranslate }}
            </button>
            <button mat-flat-button color="white"
              class="t-label-l removeAcct"
              *ngIf="componentName === 'myClientAddress'"
              (click)="removeShipTo(item)"
              aria-label="Remove ShipTo"
            >
            <mat-icon svgIcon="favRemove" class="remove-icon-style remove-shipTo" aria-label="Remove Client Ship To"></mat-icon>
            <span class="d-block d-md-none">Remove ShipTo</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
