import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SiteContextConfig, WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { LoginDialogComponent } from 'src/app/shared/components/login-dialog/login-dialog.component';
import { WishlistListComponent } from 'src/app/shared/components/wishlist-list/wishlist-list.component';
import { CurrentThemeService } from 'src/app/shared/services/common/current-theme.service';
import { GTMService } from 'src/app/shared/services/common/gtm.service';
import { InputValidationService } from 'src/app/shared/services/common/input-validation.service';
import { QuickOrderService } from 'src/app/shared/services/common/quick-order.service';
import { NotifyStockSliderComponent } from '../../notify-stock-slider/notify-stock-slider.component';
import { SalesRepCallMeDrawerComponent } from '../../sales-rep-call-me-drawer/sales-rep-call-me-drawer.component';

declare let CelebrosAnalytics: any;
@Component({
  selector: 'app-product-tile-view',
  templateUrl: './product-tile-view.component.html'
})
export class ProductTileViewComponent implements OnInit,OnChanges, OnDestroy {

  @Input() products:any;
  @Input() page:number;
  @Input() totalCount:number;
  @Input() totalPages:number;
  @Input() celebrosLogHandle:any;
  @Input() celebrosSessionId:any;
  @Input() celebrosSiteKey:any;
  @Output() paginationData: EventEmitter<any> = new EventEmitter();
  @Input() shippingMessage: any;
  @Input() price:any;
  notEmptyPost=true;
  notScrolly=true;
  quantityInput: any = [];
  maxQuantity: any = [];
  showInput: any;
  showCart: boolean = false;
  imageUrl: any;
  siteConfig: any;
  user: User;
  userId: string;
  cartId: any;
  userSub: Subscription;
  cartIdSub: any;
  request: { product: { code: string; }; quantity: number; };
  multipleOrder: any = [];
  quantityError: any = [];
  multipleOrderError: any= [];
  quantityInputValue: any;
  addToWishlistDialog: any;
  themeClass: any;
  cartDisable: boolean;
  notifyStockDialog: any;
  isViewInventoryGroup: boolean | undefined;
  isNotifyFlag:any = true;
  baseSiteNameVal: string;
  totalRecordsOnPage=12;
  
  constructor(
    private cdr: ChangeDetectorRef,
    private quickOrderService: QuickOrderService,
    public dialog: MatDialog,
    public currentTheme: CurrentThemeService,
    private config: SiteContextConfig,
    private store: Store<fromApp.AppState>,
    private router: Router,
    public windowRef: WindowRef,
    @Inject(DOCUMENT) private document: Document,
    private inputValidationService:InputValidationService,
    public gtmService: GTMService,
    ) 
  { 
    this.siteConfig = this.config;
    this.themeClass = this.currentTheme?.getCurrentTheme();
    this.imageUrl = this.siteConfig?.backend?.occ?.baseUrl;
  }

  //Add to cart functionality
  addToCart(id: any, index:any){
    let maxError : any;
    this.maxQuantity[index] = false;
    this.multipleOrderError[id] = false;
    this.multipleOrder[index] = false;
    this.quantityError[id] = false;
    let baseMultiplier = this.products[index]?.quantityMultiplier; 
    let obj = {
      "product": {
        "code": id
      },
      "quantity": + this.quantityInput[index]
    }
    this.showCart = true;
    this.showInput = id;
    //To check multiple order scenario
    if(baseMultiplier && baseMultiplier != 1){
      this.multipleOrderError[id] = false;
      this.multipleOrder[index] = true;
      this.quantityError[id] = false;
      this.maxQuantity[index] = false;
    }
    //To check quantity Error
    if(this.quantityInput[index] == 0 || !this.quantityInput[index])
    {
      this.multipleOrderError[id] = false;
      this.multipleOrder[index] = false;
      this.quantityError[id] = true;
      this.maxQuantity[index] = false;
    } 
    //To check multiple Order Error 
    if(this.quantityInput[index] % baseMultiplier !== 0 && baseMultiplier !== 1){
      this.multipleOrderError[id] = true;
      this.multipleOrder[index] = false;
      this.quantityError[id] = false;
      this.maxQuantity[index] = false;
    }
    if(((this.quantityInput[index] % baseMultiplier === 0 && baseMultiplier !== 1) || 
      (baseMultiplier === 1 &&  this.quantityInput[index] > 0)) && this.quantityInput[index] !== 0 && this.quantityInput[index]){
      this.windowRef?.sessionStorage?.removeItem('gaItemsForCartUpdate');
      this.windowRef.sessionStorage?.removeItem('totalForCartUpdate');
      let gaItem : any = {};
      gaItem = this.products[index]?.gaItems;
      gaItem = { ...gaItem, discount: null, index: null , price: this.getPriceFromHTML(this.products[index])[0]?.finalPrice?.replace('$','').replace(',',''), quantity: this.quantityInput[index], item_list_id: 'tile_view_list', item_list_name: 'Tile View List'};
      this.windowRef.sessionStorage?.setItem('gaItemsForCartUpdate' , JSON.stringify([gaItem]));
      this.windowRef.sessionStorage?.setItem('totalForCartUpdate' , JSON.stringify(gaItem?.price * parseInt(gaItem?.quantity)));
      this.quickOrderService.getCartIdSpecificProduct(obj , 'search', this.userId);

      maxError = this.quickOrderService.specificProduct$.subscribe((resp:any)=>{
        if(resp.statusCode =="maxOrderQuantityExceeded") {
          this.multipleOrderError[id] = false;
          this.multipleOrder[index] = false;
          this.quantityError[id] = false;
          this.maxQuantity[index] = true;
          maxError.unsubscribe();
        }
        else{
          this.maxQuantity[index] = false;
        }
      })
    }
  }
  // quantity increamnet and decreament  functionality
  increment(index: any){
    let baseMultiplier = this.products[index]?.quantityMultiplier; 
    if(this.quantityInput[index] === undefined){
      this.quantityInput[index] = 0;
    } else {
      if(this.quantityInput[index] % baseMultiplier !==0 ){
        this.quantityInput[index] = this.quantityInput[index] - (this.quantityInput[index] % baseMultiplier);
      }
      this.quantityInput[index] += baseMultiplier;
    }
  }

  decrement(index: any){
    let baseMultiplier = this.products[index]?.quantityMultiplier; 
    if(this.quantityInput[index] > 0 && this.quantityInput[index] > this.products[index]?.quantityMultiplier){
      if(this.quantityInput[index] % baseMultiplier !==0 ){
        this.quantityInput[index] = this.quantityInput[index] + (baseMultiplier - (this.quantityInput[index] % baseMultiplier));
      }
      else
      this.quantityInput[index] -= baseMultiplier;
    }
    if(this.quantityInput[index] == 0 ){
      this.showCart = false;
      this.showInput = null;
    }
  }


  ngOnInit(): void {
    this.themeClass = this.currentTheme.getCurrentTheme();
    this.userSub = this.store.select('auth').subscribe(user => { 
      this.user = user && user.user!;
      this.userId = this.user?.uid!;
      this.cartDisable = !this.user;
      this.isViewInventoryGroup = this.user?.isViewInventoryGroup;
      if(this.user?.isNotifyMeButtonEnabled !== undefined && !this.user?.isNotifyMeButtonEnabled){
        this.isNotifyFlag = this.user?.isNotifyMeButtonEnabled
      }
    });
  }

  getNewPageData(page:any) {
    if (this.products?.length < this.totalCount) {
      this.paginationData.emit(page-1);
    }
  }

  //recieves product details from product search component when pagination data response comes
  ngOnChanges(changes: SimpleChanges){
    if(changes?.products?.currentValue>changes?.products?.previousValue){
    this.products=this.products.filter((data:any)=>data?.code!==changes?.products?.currentValue);
    }
    if (changes['products']) {
      let variableChange = changes['products'];
      this.products = variableChange.currentValue;
      this.products?.forEach((element:any,index :any) => {
        this.maxQuantity[index] = false;
        if(!element?.quantityMultiplier){
          element.quantityMultiplier = 1;
          this.multipleOrder[index] = false;
        }
        else{
          this.quantityInput[index]  = element?.quantityMultiplier;
          if(this.quantityInput[index] !== 1){
            this.multipleOrder[index] = true;
          }
          else
          this.multipleOrder[index] = false;
        }
      });
    }
    this.cdr.detectChanges();
  }

  addProductToWishlist(product: any) {
  if(product?.variantOptions?.length && product.variantOptions[0].code) {
    let gaObj = product?.gaItems;
    gaObj = { ...gaObj, discount: null, index: null , price: this.getPriceFromHTML(product)[0]?.finalPrice?.replace('$','').replace(',','') , quantity: 1, item_list_id: 'tile_view_list', item_list_name: 'Tile View'};

    this.addToWishlistDialog = this.dialog.open(WishlistListComponent, {
      position: {
        right: '0px',
        top: '0px'
      },
      panelClass: ['wishlist-side-drawer', this.themeClass],
      data: {
        isEdit: false,
        isCreator: true,
        productCode: product.variantOptions[0].code,
        header: 'account.wishLists.addToListHeader',
        gaItem: [gaObj]
      }
    });
  }
}

  onLoginClick(){
    this.dialog.open(LoginDialogComponent,
      {
        panelClass: [this.themeClass, 'custom-dialog-size'],
        autoFocus: false,
        disableClose: true
      });
  }

  ngOnDestroy() {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }
  redirectPDP(code:any){
    this.sendCelebrosAnalytics(code);
  }

  //This method is used to open sales rep call me drawer when clicked on MY REP CALL ME 
  openSalesRepCallMeDrawer(item:any){
    this.dialog.open(SalesRepCallMeDrawerComponent, {
      position: {
        right: '0px',
        top: '0px'
      },
      panelClass: ['wishlist-drawer', this.themeClass],
      data: {
        product: item,
        user:this.user
      }
    });
  }
  keepMeUpdated(item: any,index:any) {
    let baseSiteName = this.config?.context?.baseSite;
    let baseSiteNameVal;
    if (baseSiteName) {
      baseSiteNameVal = baseSiteName[0];
      this.baseSiteNameVal = baseSiteName[0];
    }
   let finalPrice = this.getPriceFromHTML(item);
    this.notifyStockDialog = this.dialog.open(NotifyStockSliderComponent, {
      position: {
        right: '0px',
        top: '0px'
      },
      panelClass: ['header-links', this.themeClass],
      data: {
        product: item,
        user: this.user,
        qty: this.quantityInput[index],
        price : finalPrice,
        baseSiteNameVal : baseSiteNameVal,
        imageUrl:this.imageUrl
      }
    });
  }
  //Celebros Analytics call
  sendCelebrosAnalytics(code: any) {
    let price = this.getPriceFromHTML(code);
    if(typeof CelebrosAnalytics !== 'undefined' && typeof CelebrosAnalytics !== undefined) {
      CelebrosAnalytics.ShowComments = false;
      CelebrosAnalytics.dopost = false;
      CelebrosAnalytics.customerid = this.celebrosSiteKey;
      CelebrosAnalytics.pagereferrer = document.referrer;
      CelebrosAnalytics.productsku = code.code;
      CelebrosAnalytics.websessionid = this.celebrosSessionId;
      CelebrosAnalytics.productname = code.name;
      CelebrosAnalytics.productprice = price[0].finalPrice;
      CelebrosAnalytics.issecured = true;
      CelebrosAnalytics.qwisersearchsessionid = this.celebrosSessionId;
      CelebrosAnalytics.AI_LogProduct();
    }

    let updatedUrl = code?.url;
    let gaDataForViewItem = code?.gaItems;
    gaDataForViewItem = { ...gaDataForViewItem, discount: null, index: null , price: code?.variantsSize ===1 ? price[0].finalPrice?.replace('$','').replace(',','') :  null, quantity: 1, item_list_id: 'tile_view_list', item_list_name: 'Tile View'};
    // sending the select_item event to GA 4
    this.gtmService.setSelectItemData(gaDataForViewItem);
    this.router.navigate([updatedUrl]);
    //this.windowRef.location.href = this.windowRef.location.origin + updatedUrl;
	}

  getPriceFromHTML(item: any): any {
    let priceRef = this.document.getElementById(item.code)?.getElementsByClassName('product-tile-sigleVariantPrice')[0]
    let currency = priceRef?.getElementsByClassName('t-price-tile product-tile-currency')[0]?.textContent || '$';
    let price = priceRef?.getElementsByClassName('t-price-currenyTile product-tile-figure')[0]?.textContent || 0;
    let cent = priceRef?.getElementsByClassName('t-price-currenyFractionTile ml-1 product-tile-currency')[0]?.textContent || 0;
    let finalPrice = currency + price + '.' + cent;
    let priceData = [];
    priceData.push({
      currency: currency,
      price: price,
      cent: cent,
      finalPrice: finalPrice
    })
    return priceData;
  }
  onPaste(event: any) {
    this.inputValidationService.onPaste(event);
  }
  checkDisable(item: any){
    let priceRef = this.document.getElementById(item.code)?.getElementsByClassName('product-tile-sigleVariantPrice')[0]
    let currency = priceRef?.getElementsByClassName('t-price-tile product-tile-currency')[0]?.textContent || '$';
    let price = priceRef?.getElementsByClassName('t-price-currenyTile product-tile-figure')[0]?.textContent || 0;
    let cent = priceRef?.getElementsByClassName('t-price-currenyFractionTile ml-1 product-tile-currency')[0]?.textContent || 0;
    let finalPrice = currency + price + '.' + cent;
    if(!item?.isSampleVariant && !item?.maxVariantPrice?.value){
      return (!item?.isSampleVariant && (finalPrice === '$0.0' || finalPrice === '$0.00' || finalPrice === '$00.0' || finalPrice === '$00.00')) ? true : false;
    }else{
      return false;
    }

  }
}
