<div class="cart-info">
    <div class="d-flex">
        <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon cart-info-dialog-close ml-auto" color="white">close</mat-icon>
    </div>

    <div *ngIf="warehouseModal"
        class=" cart-info-msg d-flex flex-column align-items-center justify-content-center pt-2 pb-3">

        <div class="t-headline-m mb-3 mx-4 w-75 cart-info-dialog-header">{{'cart.shippingFromWarehouse' | cxTranslate}}
        </div>
        <div class="warning-description t-title-m mb-3 d-flex justify-content-center">
            {{'cart.shippingWarehouseMsg' | cxTranslate}}</div>
        <div class="py-3">
            <button mat-flat-button color="primary" type="button" class="mr-3" [mat-dialog-close]="true">
                {{"cart.closeBtn" | cxTranslate}}</button>
        </div>

    </div>

    <div *ngIf="shippingModal">

        <div class="t-headline-m mb-3 mx-4 cart-info-dialog-header">{{'cart.fee' | cxTranslate}}</div>

        <div class="shipping-description t-title-m mb-3 text-center">
            <ng-container *ngIf="modaltype === 'shipping'">
                <p class="mb-4">{{'cart.shippingFee' | cxTranslate}}</p>
                <p class="mb-4">{{'cart.shippingFeeDesc' | cxTranslate}}</p>
            </ng-container>
            <ng-container *ngIf="modaltype ==='tax'">
                <p class="mb-4">{{'cart.taxes' | cxTranslate}}</p>
                <p class="mb-4">{{'cart.taxesShortDesc' | cxTranslate}}</p>
                <p class="mb-4">{{'cart.taxesDesc' | cxTranslate}}</p>
            </ng-container>
        </div>
        <div class="text-center py-3">
            <button mat-flat-button color="primary" type="button" class="mr-3" [mat-dialog-close]="true">
                {{"cart.closeBtn" | cxTranslate}}</button>
        </div>

    </div>
</div>