
<!--Product Details Page - Products Recently Viewed-->

<section *ngIf="products && products?.length > 0 " class="t-title-l mb-3 mx-2">
  {{ "account.recentlyViewedProducts.title" | cxTranslate }}
</section>

<div class="d-flex flex-row flex-wrap recently-viewed-products-wrapper">
  <div *ngFor="let product of products; let i=index" class="mb-3">
    <div class="d-flex flex-column recently-viewed-product p-3 mr-2">
      <!--This is removed from design, so commenting out-->
      <!-- <div class="d-flex align-items-center justify-content-center mb-2">
        <div class="product-tag d-flex align-items-center justify-content-center">
          NEW ARRIVAL
        </div>
      </div> -->

      <div class="text-center product-tile-image mb-1">
        <img class="cursor-pointer"  *ngIf="(product?.productImage?.url)" lazyLoad="{{imageUrl + product?.productImage?.url}}" 
        defaultImage="../../../../../assets/MissingImage.svg" alt="{{product?.altText?.url}}"  (click)="navigateToPDP(product)"/>
        <img class="cursor-pointer" *ngIf="product?.productImage?.url === undefined || product?.productImage?.url === '' || !(product?.productImage?.url)"  lazyLoad="../../../../../assets/MissingImage.svg" 
        defaultImage="../../../../../assets/MissingImage.svg" alt="MissingImage" (click)="navigateToPDP(product)"/>
        <a          mat-flat-button
                    class="product-tile-hover-viewDetails-button"
                    type="button"
                    (click)="navigateToPDP(product)">
                    {{ 'account.productTile.viewHoverDetail' | cxTranslate }}
                </a>
      </div>
      <div class="t-productTile-description description mb-1 cursor-pointer"  (click)="navigateToPDP(product)" [innerHTML]="product?.name"></div>

      <div class="t-productTile-brand brand mb-3">{{product?.brandData?.name}}</div>

      <p class="t-productTile-productGroup group d-none">
        {{ 'account.productTile.item' | cxTranslate }}{{product?.code}}
      </p>

      <div class="d-flex flex-column mb-2" *ngIf="!user">
        <div class="t-productTile-description cursor-pointer" (click)="onLoginClick()">{{ "account.recentlyViewedProducts.loginTitle" | cxTranslate }}</div>
        <div class="t-productTile-teaser">{{ "account.recentlyViewedProducts.loginDescription" | cxTranslate }}</div>
      </div>

      <div class="d-flex flex-row mb-2" *ngIf="user">
        <div *ngIf="product?.variantsSize === 1">
            <sup class="t-price-tile product-tile-currency">$</sup>
            <sup class="t-price-currenyTile product-tile-figure">{{product?.price?.value?.toString().split('.')[0] | number: '1.0-0'}}</sup>
            <sup class="t-price-currenyFractionTile ml-1 product-tile-currency">
              <ng-container *ngIf="product?.price?.value?.toString().split('.')[1] as cents;else defaultCents">
                {{cents?.length === 2 ? cents : cents+'0'}}
              </ng-container>
              <ng-template #defaultCents>
                00
              </ng-template>
            </sup>
            <sup class="t-product-details-availability-Instock product-tile-figure">/ {{ product?.baseOptions[0]?.selected?.unit || product?.salesUnit }}</sup>
        </div>
        <div *ngIf="product?.variantsSize > 1">
             <sup class="t-price-tile product-tile-currency">$</sup>
             <sup class="t-price-currenyTile product-tile-figure">{{product?.priceRange?.minPrice?.value?.toString().split('.')[0] | number: '1.0-0'}}</sup>
             <sup class="t-price-currenyFractionTile ml-1 product-tile-currency">
              <ng-container *ngIf="product?.priceRange?.minPrice?.value?.toString().split('.')[1] as cents;else defaultCents">
                {{cents?.length === 2 ? cents : cents+'0'}}
              </ng-container>
              <ng-template #defaultCents>
                00
              </ng-template>
            </sup>
             -
             <sup class="t-price-tile product-tile-currency">$</sup>
             <sup class="t-price-currenyTile product-tile-figure">{{product?.priceRange?.maxPrice?.value?.toString().split('.')[0] | number: '1.0-0'}}</sup>
             <sup class="t-price-currenyFractionTile ml-1 product-tile-currency">
              <ng-container *ngIf="product?.priceRange?.maxPrice?.value?.toString().split('.')[1] as cents;else defaultCents">
                {{cents?.length === 2 ? cents : cents+'0'}}
              </ng-container>
              <ng-template #defaultCents>
                00
              </ng-template>
            </sup>

             <sup class="t-product-details-availability-Instock product-tile-figure"> / {{ product?.salesUnit }}</sup>
        </div>
      </div>

      <!-- Commenting out privious save price as not developed yet-->
      <!-- <div class="d-flex flex-row">
        <div class="t-productTile-boldPrice product-tile-boldPrice rvp-offer d-flex align-items-center justify-content-center">{{ "account.recentlyViewedProducts.save" | cxTranslate }} $150</div>
        <div class="t-price-was product-tile-was d-flex align-items-center justify-content-center text-center">{{ "account.recentlyViewedProducts.was" | cxTranslate }} $XX.XX / Box</div>
      </div> -->

      <div *ngIf="!user" class="d-none">
        <div class="product-tile-flag">
            <div class="product-tile-inStock d-flex" *ngIf="'inStock' === product?.stock?.stockLevelStatus">
                <mat-icon svgIcon="inStock" class="product-tile-inStock-icon mr-2" aria-label="In Stock"></mat-icon>
                <span class="t-productTile-availability-Instock product-tile-inStock-text mr-1">
                    {{ 'account.productTile.inStock' | cxTranslate }}
                </span>
                <span class="t-productTile-availability-Instock-description mr-1">
                    {{ 'account.productTile.readyToShip' | cxTranslate }}
                </span>
            </div>
            <div class="product-tile-backOrdered d-flex" *ngIf="'outOfStock' === product?.stock?.stockLevelStatus">
                <mat-icon svgIcon="backordered" class="product-tile-backOrdered-icon mr-2" aria-label="Back Ordered"></mat-icon>
                <span class="t-productTile-availability-Instock product-tile-backOrdered-text">
                    {{ 'account.productTile.outOfStock' | cxTranslate }}
                </span>
                <span class="t-productTile-availability-Instock-description mr-1" *ngIf="product?.outOfStockETA">
                    {{ 'account.productTile.pipe' | cxTranslate }}{{ 'account.productTile.eta' | cxTranslate }}{{product?.outOfStockETA}}
                </span>
            </div>
        </div>
    </div>

      <div *ngIf="user">
        <div class="product-tile-flag product-tile-inStock mb-4">
          <div class="product-tile-inStock d-flex" *ngIf="'inStock' === product?.stock?.stockLevelStatus && !product?.isBackOrder && !product?.isDiscontinued && !product?.vendorDropShipSpecialOrder && product?.sellable === 'sellable'">
              <mat-icon svgIcon="inStock" class="product-tile-inStock-icon mr-2" aria-label="In Stock"></mat-icon>
              <span class="t-productTile-availability-Instock product-tile-inStock-text">
                  {{ 'account.productTile.inStock' | cxTranslate }}
              </span>
              <span class="t-productTile-availability-Instock-description mr-1">
                  {{ 'account.productTile.readyToShip' | cxTranslate }}
              </span>
          </div>
          <div class="product-tile-backOrdered d-flex" *ngIf="'outOfStock' === product?.stock?.stockLevelStatus && !product?.isBackOrder && !product?.isDiscontinued && !product?.vendorDropShipSpecialOrder && product?.sellable === 'sellable'">
              <mat-icon svgIcon="backordered" class="product-tile-backOrdered-icon mr-2" aria-label="Back Ordered"></mat-icon>
              <span class="t-productTile-availability-Instock product-tile-backOrdered-text">
                  {{ 'account.productTile.outOfStock' | cxTranslate }}
              </span>
              <span class="t-productTile-availability-Instock-description mr-1" *ngIf="product?.outOfStockETA">
                  {{ 'account.productTile.pipe' | cxTranslate }}{{ 'account.productTile.eta' | cxTranslate }}{{product?.outOfStockETA}}
              </span>
          </div>
          <div class="product-tile-backOrdered d-flex" *ngIf="product?.isBackOrder && !product?.isDiscontinued && !product?.vendorDropShipSpecialOrder && product?.sellable === 'sellable'">
              <mat-icon svgIcon="backordered" class="product-tile-backOrdered-icon mr-2" aria-label="Back Ordered"></mat-icon>
              <span class="t-productTile-availability-Instock product-tile-backOrdered-text">
                  {{ 'account.productTile.backOrdered' | cxTranslate }}
              </span>
              <span class="t-productTile-availability-Instock-description mr-1" *ngIf="product?.mfgBackorderETA">
                  {{ 'account.productTile.pipe' | cxTranslate }}{{ 'account.productTile.eta' | cxTranslate }}{{product?.mfgBackorderETA}}
              </span>
          </div>
          <div class="product-tile-discontinued d-flex" *ngIf="product?.isDiscontinued">
              <mat-icon svgIcon="discontinued" class="product-tile-discontinued-icon mr-2" aria-label="Discontinued"></mat-icon>
              <span class="t-productTile-availability-Instock product-tile-discontinued-text">
                  {{ 'account.productTile.discontinued' | cxTranslate }}
              </span>
          </div>
          <div class="product-tile-specialorder d-flex" *ngIf="(product?.vendorDropShipSpecialOrder || product?.sellable === 'exempt') && !product?.isDiscontinued">
              <mat-icon svgIcon="specialorder" class="product-tile-specialorder-icon mr-2" aria-label="Special Order"></mat-icon>
              <span class="t-productTile-availability-Instock product-tile-specialorder-text">
                  {{ 'account.productTile.specialOrder' | cxTranslate }}
              </span>
          </div>
        </div>
      </div>

    <div *ngIf="product?.variantsSize && product?.variantsSize === 1 && (('inStock' === product?.stock?.stockLevelStatus || 'outOfStock' === product?.stock?.stockLevelStatus || product?.vendorDropShipSpecialOrder) && !product?.isBackOrder && !product?.isDiscontinued && product?.sellable === 'sellable')">
      <div *ngIf="qtyRequired && showInput === product?.code" class="t-label-s rvp-qty-error-box-enter-qty d-flex align-items-center justify-content-center mb-1">{{'account.favoriteProduct.quantityTitle' | cxTranslate}}</div>
      <div *ngIf="maxQtyReached && showInput === product?.code"  class="t-label-s rvp-qty-error-box-max d-flex align-items-center justify-content-center mb-1">{{'account.favoriteProduct.maxQuantityTitle' | cxTranslate}}</div>

       <div *ngIf="(!(orderMultiplerRequired && showInput === product?.code) && ((product?.baseOptions && product?.baseOptions[0] && product?.baseOptions[0]?.selected?.quantityMultiplier && product?.baseOptions[0]?.selected?.quantityMultiplier !== 1) ||
                (product?.variantOptions && product?.variantOptions[0] && product?.variantOptions[0]?.quantityMultiplier && product?.variantOptions[0]?.quantityMultiplier !==1))) "  class="t-label-s rvp-qty-error-box-multiple d-flex align-items-center justify-content-center mb-1">
       {{'account.favoriteProduct.quantityMultiplierTitle' | cxTranslate}}{{product?.baseOptions[0]?.selected?.quantityMultiplier || product?.variantOptions[0]?.quantityMultiplier || 1}}</div>


      <div *ngIf="(orderMultiplerRequired && showInput === product?.code)"  class="t-label-s add-cart-rvp-qty-error-box-multiple d-flex align-items-center justify-content-center mb-1">
        {{'account.favoriteProduct.quantityMultiplierTitle' | cxTranslate}}{{product?.baseOptions[0]?.selected?.quantityMultiplier || product?.variantOptions[0]?.quantityMultiplier || 1}}</div>
    </div>


    <div *ngIf="product?.variantsSize && product?.variantsSize === 1 && (('inStock' === product?.stock?.stockLevelStatus || 'outOfStock' === product?.stock?.stockLevelStatus || product?.vendorDropShipSpecialOrder) && !product?.isBackOrder && !product?.isDiscontinued && product?.sellable === 'sellable')"  class="d-flex flex-row align-items-center justify-content-center mb-2">
        <div class="d-flex">
          <button class="quantity__minus" role="button" (click)="decrement(i)" aria-label="minus">
            <mat-icon
              svgIcon="minusIcon"
              class="product-tile-minusIcon-icon"
            ></mat-icon>
          </button>
          <input
            type="number" [(ngModel)]="quantityInput[i]"
            onKeyPress="if(this.value.length >= 4 || this.value < 0 || event?.charCode === 46) return false;"
            name="counter"
            class="quantity__input mx-1" (paste)="onPaste($event)" aria-label="quantity"
          />
          <button class="quantity__plus" (click)="increment(i)" aria-label="plus">
            <mat-icon
              svgIcon="plus"
              class="product-tile-plus-icon"
            ></mat-icon>
          </button>
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <button
            mat-flat-button
            class="product-tile-addToCart-button ml-1"
            color="primary"
            [disabled]="!user || (!product?.variantOptions[0].isSampleVariant && product?.variantsSize === 1 && (product?.price?.formattedValue  === '$0.0' || product?.price?.formattedValue === '$0.00' || product?.price?.formattedValue === '$00.0' || product?.price?.formattedValue === '$00.00'))"
            role="button" (click)="addTocart(product, i)"
          >
           {{ "account.productTile.cart" | cxTranslate }}
          </button>
        </div>
    </div>

    <div *ngIf="product?.variantsSize && product?.variantsSize > 1" class="mb-2 mt-2">
        <button
          mat-flat-button
          class="product-tile-choose-options-button w-100"
          role="button"  (click)="navigateToPDP(product)"
        >
          {{ "account.productTile.chooseOptions" | cxTranslate }}
        </button>
    </div>

    <div class="d-flex flex-row justify-content-center">
        <div>
          <a
            mat-flat-button
            (click)="navigateToPDP(product)"
            class="product-tile-viewDetails-button"
            type="button"
          >
            {{ "account.productTile.viewDetails" | cxTranslate }}
          </a>
        </div>
        <div *ngIf="user && product?.variantsSize && product?.variantsSize === 1 && (('inStock' === product?.stock?.stockLevelStatus || 'outOfStock' === product?.stock?.stockLevelStatus || product?.vendorDropShipSpecialOrder) && !product?.isBackOrder && !product?.isDiscontinued && product?.sellable === 'sellable')" class="d-flex align-items-center justify-content-center">
          <mat-icon (click)="addProductToWishlist(product)"
            svgIcon="like"
            class="product-tile-like-icon cursor-pointer"
            aria-label="Like"
          ></mat-icon>
        </div>
  </div>
  </div>
  </div>
</div>

