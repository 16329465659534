import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { SectionLoaderService } from 'src/app/feature/pages/loader/section-loader.service';
import { AddressService } from '../../services/common/address.service';
import { BoxoutEndpointService } from '../../services/common/boxout-endpoint.service';
import { CurrentThemeService } from '../../services/common/current-theme.service';
import { SmartyAddressValidationService } from '../../services/common/smarty-address-validation.service';
import { AddShipToClientPatientAddressDrawerComponent } from '../add-ship-to-client-patient-address-drawer/add-ship-to-client-patient-address-drawer.component';
import { OtherShipToClientOrPatientAddressesDrawerComponent } from '../other-ship-to-client-or-patient-addresses-drawer/other-ship-to-client-or-patient-addresses-drawer.component';
import { SelectClientTypeComponent } from '../select-client-type/select-client-type.component';
import { SiteContextConfig } from '@spartacus/core';

@Component({
  selector: 'app-ship-to-client-or-patient-drawer',
  templateUrl: './ship-to-client-or-patient-drawer.component.html'
})
export class ShipToClientOrPatientDrawerComponent implements OnInit {

  currentTheme: any;

  searchParam: string;
  addresses: any = [];
  flag = false;
  userId:any;
  siebelRowId:any;
  user: User;
  userSub: Subscription;
  page:any;
  totalRecords:any;
  currentPage =0;
  totalPages:number;
  totalRecordsOnPage=15;
  displayUid:any;
  shippingAddressSelectedSuccess:boolean=false;
  shippingAddressSelectedFailure:boolean=false;
  baseSiteNameVal: string;

  constructor(public dialog: MatDialog,private theme: CurrentThemeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private smartyAddressValidationService: SmartyAddressValidationService,
    private store: Store<fromApp.AppState>,
    private cdr: ChangeDetectorRef,
    private config: SiteContextConfig,
    public dialogRef: MatDialogRef<ShipToClientOrPatientDrawerComponent>,
    private sectionLoaderService:SectionLoaderService,
    private addressService: AddressService, private customHttp: BoxoutEndpointService) { }

  ngOnInit(): void {
    this.baseSiteNameVal = (this.config as any)?.context?.baseSite[0];
    /** Taking themes from themeservice **/
    this.currentTheme = this.theme?.getCurrentTheme();

    this.userSub = this.store.select('auth').subscribe(user => { 
      this.user = user && user.user!;
      this.userId = this.user?.uid;
      this.displayUid = this.user?.displayUid;
      this.siebelRowId = this.user?.orgUnit?.siebelRowId;
      if (this.userId && this.siebelRowId) {
        this.getAddressData();
      }
    });

    //to get newly added address on account
    this.getNewlyAddedAddressOnAccount();
  }



//to get newly added address on account
  getNewlyAddedAddressOnAccount(){
    this.smartyAddressValidationService.saveAddedAddressForClientAccountAlertData$.subscribe((val)=>{
      
      if (!this.dialog?.openDialogs?.length){
        this.dialog.open(ShipToClientOrPatientDrawerComponent, {
          position: {
            right: '0px',
            top: '0px'
          },
          panelClass: ['wishlist-drawer', this.currentTheme],
          data: {
            value:{
              showSuccessForCart:val,
              showFailureForCart:!val
            }
          }
        });
      }
    
  });
  }

//To get all ship clients addresses
  getAddressData(val?: any) {
    this.sectionLoaderService.show();
    this.currentPage = val ? val - 1 : 0;
    let apiURL;
    if (this.searchParam) {
      apiURL = `users/current/orgUnits/${this.siebelRowId}/clients?currentPage=${this.currentPage}&search=${this.searchParam}&fields=DEFAULT&pageSize=15`;
    } else {
      apiURL = `users/current/orgUnits/${this.siebelRowId}/clients?currentPage=${this.currentPage}&fields=DEFAULT&pageSize=15`;
    }
    this.addressService
      .getAddresses(apiURL, this.searchParam)
      .subscribe((data: any) => {
        this.sectionLoaderService.hide();
        if (data) {
          this.flag = true;
          this.addresses = data?.results;
          this.page = data?.pagination?.page + 1;
          this.totalRecords = data?.pagination?.totalCount;
          this.totalPages = data?.pagination?.totalPages;
          this.cdr.detectChanges();
        }
      },(error)=>{
        this.sectionLoaderService.hide()
      });
  }

//This opens up create client or patient drawer
  createNewClientAccount(){

    if(this.data){
      this.data.value={};
    }

  //setting form data null for avoiding multiple call 
  this.smartyAddressValidationService.saveFormData(null);

  //BCGI2-796: client or patient ship to address changes
  this.dialog.open(SelectClientTypeComponent, {
    panelClass: [this.currentTheme, 'select-client-type-dialogs-style'],
    width:'368px',
    height:'100%',
    position: {
      right: '0px',
      top: '0px'
    },
    data:  {
      value:{
        action:'addingNewClientOrPatientShipToAccount',
      }
   }
  });
  }

  
//This method opens up Ad ship to address drawer
  openAddShipToDialog(item:any){
    if(this.data){
      this.data.value={};
    }

     //setting form data null for avoiding multiple call 
     this.smartyAddressValidationService.saveFormData(null);
     let defaultShippgTo = (!(item?.shipTo)) ? true : false ;
     this.dialog.open(AddShipToClientPatientAddressDrawerComponent,
       {
         panelClass: [this.currentTheme, 'ship-to-client-drawer-for-all'],
         position: {
          right: '0px',
          top: '0px'
        },
         data:  {
           value:{
            action:"addingNewAddressForClientOrPatientShipToAccount",
             openedDrawerFrom:"openedAddShipToFromShipToClientPatient",
             addAddressId:item.id,
             defaultShippgTo:defaultShippgTo
           }
        }
       });

  }

  //This method is used to open Other addresses drawer when clicked on other Addresses button
  openOtherAddressDrawer(item:any){
    if(this.data){
      this.data.value={};
    }
     this.dialog.open(OtherShipToClientOrPatientAddressesDrawerComponent, {
      position: {
        right: '0px',
        top: '0px'
      },
      panelClass: ['wishlist-drawer', this.currentTheme],
      data: {
        value:{
          itemData:item,
          addAddressId:item?.id,
          showSuccessForCart:false,
          showFailureForCart:false
          }
      }
    });
  }

//clears search param when clicked on cross on search bar
  clearSearch() {
    this.searchParam = '';
    this.getAddressData();
  }


//This method is used to select ship to address for cart
  shipToThisAddress(item:any){
    this.sectionLoaderService.show();
    this.shippingAddressSelectedSuccess=false;
    this.shippingAddressSelectedFailure=false;

    let cartId = sessionStorage.getItem('cartId');
    let requestBody = {
                      siebelRowId:item?.id,
                      addressSiebelRowID:item?.shipTo?.id,
                      isShipTo:true
                    };

    let apiUrl=`users/current/carts/${cartId}/addressDetails/?fields=DEFAULT`;

    this.customHttp.post(apiUrl, requestBody).subscribe(
      (resp: any) => {
        this.sectionLoaderService.hide();
        if(resp?.isSuccess === true){
          this.shippingAddressSelectedSuccess=true;
          this.shippingAddressSelectedFailure=false;
          this.dialogRef.close();
          this.smartyAddressValidationService.saveAddressSelectedForShipToCartData(true);
          
        }else if(resp?.isSuccess === false){
          this.shippingAddressSelectedSuccess=false;
          this.shippingAddressSelectedFailure=true;
        }
      },
      (error:any) => {
        this.shippingAddressSelectedSuccess=false;
        this.shippingAddressSelectedFailure=true;
        this.sectionLoaderService.hide();
      }
    );
  }

  showClientAddress(address: any){
    return this.smartyAddressValidationService.returnFormattedAddress(address);
  }

}
