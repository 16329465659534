import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';

@Component({
  selector: 'app-order-reward-credits',
  templateUrl: './order-reward-credits.component.html',
})
export class OrderRewardCreditsComponent {
  user: User;
  userSub: Subscription;
  userId: any;
  orderCredits: any;

  @Input() orderRewards: any;

  constructor(
    private customHttp: BoxoutEndpointService,
    private store: Store<fromApp.AppState>
  ) {}
  
}
