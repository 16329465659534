<section>
  <div class="d-flex justify-content-between">
    <span class="t-title-l mb-1 pb-3">{{ "medicalInfo.medicalDropshipInfo" | cxTranslate }}</span>
    <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon clear-cart-modal-close"
      color="white">close</mat-icon>
  </div>

  <div class="medical-dropship-form">
    <div *ngIf="showErrorMsg">
      <reusable-alert-box [alertType]="'warningAlertBox'" alertMsg="Please enter a valid NPI number">
      </reusable-alert-box>
    </div>
    <form [formGroup]="medicalInfoForm" (ngSubmit)="medicalInfoFormSubmit()">
      <!-- Do not delete -> May be needed for future UPIN section -->
      <!-- <div class="row">
        <div class="col-12">
          <div class="t-title-l mb-3">{{ "medicalInfo.upim" | cxTranslate }}</div>
          <mat-form-field class="pb-0 w-100" appearance="outline">
            <mat-label>{{"medicalInfo.patientUnique" | cxTranslate}}</mat-label>
            <input matInput type="text" formControlName="upim" id="upim" maxlength="100"/>
            <mat-error *ngIf="
              medicalInfoForm.get('upim')?.touched &&
              (medicalInfoForm.get('upim')?.hasError('required') || medicalInfoForm.get('upim')?.hasError('maxlength')) 
              ">
              {{"medicalInfo.validUpim" | cxTranslate}}
            </mat-error>
          </mat-form-field>
        </div>
      </div> -->

      <div class="row">
        <div class="col-12">
          <div class="t-body-l mb-1 pb-1 mt-3">{{"medicalInfo.npi" | cxTranslate}} </div>
          <mat-form-field class="pb-0 w-100" appearance="outline">
            <mat-label>{{"medicalInfo.npiInfoHeader" | cxTranslate}}</mat-label>
            <input inputmode="numeric" matInput formControlName="npiData" minlength="10" maxlength="10" id="npiData"
              (input)="npiChanged($event)"  (keypress)="keyPressNumbers($event)"
              (paste)="onPaste($event)"/>
            <mat-error *ngIf="
              medicalInfoForm.get('npiData')?.touched &&
              medicalInfoForm.get('npiData')?.hasError('required')
              ">
              {{"medicalInfo.npiRequired" | cxTranslate}}
            </mat-error>
            <mat-error *ngIf="
            medicalInfoForm.get('npiData')?.touched &&
            (medicalInfoForm.get('npiData')?.hasError('minlength') || medicalInfoForm
            .get('npiData')?.hasError('pattern'))">{{"medicalInfo.npiValidError" | cxTranslate}}
            </mat-error>
          </mat-form-field> 
          <div class="mat-error npi-error t-body-s" *ngIf=" medicalInfoForm.get('npiData')?.touched && showNPIError">{{"medicalInfo.npiError" | cxTranslate}}</div>
        </div>
      </div>

      <!-- Do not delete -> May be needed for future Address section -->
      <!-- <div>
        <div class="t-title-l mb-3 mt-3"> {{"medicalInfo.doctorsAddress" | cxTranslate}}</div>
        <app-reusable-address-form></app-reusable-address-form>
      </div> -->

      <div class="d-flex justify-content-end mt-3">
        <div>
          <button mat-flat-button mat-dialog-close color="white" type="button" class="mr-2">
            {{"medicalInfo.cancel" | cxTranslate}}
          </button>
        </div>
        <div class="d-none d-md-block">
          <button mat-flat-button color="primary" type="submit">
            {{"medicalInfo.saveContinue" | cxTranslate}}
          </button>
        </div>
        <div class="d-block d-md-none">
          <button mat-flat-button color="primary" type="submit">
            {{"medicalInfo.saveAndContinue" | cxTranslate}}
          </button>
        </div>
      </div>
    </form>
  </div>
</section>
