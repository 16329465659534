<section class="cart-summary">
    <div class="cart-summary-section p-3">
        <div class="cart-summary-header d-flex p-2 align-items-center mr-auto">
            <div class="pr-2">
                <mat-icon svgIcon="cart"></mat-icon>
            </div>
            <div class="t-cart-heading">{{'cart.summary' | cxTranslate}}</div>
        </div>
        <ng-container *ngIf="cartDetails?.appliedOrderPromotions?.length || eligiblePromoCount">
          <app-order-promotion
            [promotions]="cartDetails?.appliedOrderPromotions || cartDetails?.potentialOrderPromotions"
            [eligiblePromoCount]="eligiblePromoCount"
            [isApplied]="cartDetails?.appliedOrderPromotions?.length"
            [freeFreight]="cartDetails?.freeFreightPromotion"
            (openOrderPromotionModal)="openPromotionSlider()"
            ></app-order-promotion>
        </ng-container>
        <ng-container *ngIf="rewardsDiscount?.value > 0 && rewardsApplied">
              <app-order-credit 
              (click)="openCreditModal('credits')"
              [cartDetails]="cartDetails"
              [rewardsDiscount]="rewardsDiscount"
              [rewardsApplied]="rewardsApplied"
            >
            </app-order-credit>
        </ng-container>
        <div class="my-4 mx-3 cart-summary-seperator"></div>
        <div class="cart-summary-enteries my-1">
            <div class="pb-3 d-flex justify-content-between">
                <span class="t-title-m-regular">{{'cart.products' | cxTranslate}}</span>
                <span class="t-title-m-bold">{{cartDetails?.subTotal?.formattedValue}}</span>
            </div>
            <ng-container  *ngIf="eligiblePromoCount || cartDetails?.appliedOrderPromotions?.length || isLinePromoAvailable || cartDetails?.totalDiscounts?.value">
              <div class="pb-3 d-flex justify-content-between">
                <span class="t-title-m-regular">{{ "cart.promotional" | cxTranslate }}</span>
                <span
                  class="promotion-discount t-title-m-bold rounded px-2"
                  [class.cursor-pointer]="!cartDetails?.totalDiscounts?.value"
                  (click)="
                    !cartDetails?.totalDiscounts?.value && openPromotionSlider()
                  "
                  >{{
                    cartDetails?.totalDiscounts?.value
                      ? "-" + cartDetails?.totalDiscounts?.formattedValue
                      : ("cart.viewPromos" | cxTranslate)
                  }}
                </span>
              </div>
            </ng-container>
            <div class="pb-3 d-flex justify-content-between" *ngIf="cartDetails?.hazardousFee && cartDetails?.hazardousFee?.value">
                <span class="t-title-m-regular">{{'cart.hazardous' | cxTranslate}}</span>
                <span class="t-title-m-bold">{{cartDetails?.hazardousFee?.formattedValue}}</span>
            </div>
            <div class="pb-3 d-flex justify-content-between" *ngIf="cartDetails?.surcharge?.value">
                <span class="t-title-m-regular">{{'cart.surcharge' | cxTranslate}}</span>
                <span class="t-title-m-bold">{{cartDetails?.surcharge?.formattedValue}}</span>
            </div>
            <div class="pb-3 d-flex justify-content-between">
                <span class="t-title-m-regular d-flex justify-content-between">
                    <span *ngIf="isCurbside">{{'cart.estimated' | cxTranslate}}</span> 
                    {{'cart.estShipping' | cxTranslate}}
                    <mat-icon svgIcon="ques_mark" (click)="openTaxesShippingModal('shipping')" class="cursor-pointer ml-1 shipping-icon"></mat-icon>
                </span>
                <span class="t-title-m-bold">{{'cart.pending' | cxTranslate}}</span>
            </div>
            <div class="pb-3 d-flex justify-content-between">
                <span class="t-title-m-regular d-flex justify-content-between">
                    <span *ngIf="isCurbside">{{'cart.estimated' | cxTranslate}}</span> 
                    {{'cart.estTaxes' | cxTranslate}}
                    <mat-icon svgIcon="ques_mark" (click)="openTaxesShippingModal('tax')" class="cursor-pointer ml-1 shipping-icon"></mat-icon>
                </span>
                <span class="t-title-m-bold">{{'cart.pending' | cxTranslate}}</span>
            </div>
            <div *ngIf="cartDetails?.rewardsDiscount !== undefined && cartDetails?.rewardsDiscount?.value > 0" >
                <div class="my-4 mx-3 cart-summary-seperator"></div>
                <div>
                    <span class="t-title-m-regular d-flex justify-content-between">
                    {{'cart.creditsApplied' | cxTranslate}}
                    <span class="t-title-m-bold">({{cartDetails?.rewardsDiscount?.formattedValue}})</span>
                    </span>
                </div>
            </div>
        </div>
        <div class="mx-3 my-4 cart-summary-seperator"></div>
        <div class="d-flex justify-content-between pb-3 mb-1">
            <span class="t-product-details-availability-Instock">
                <span *ngIf="isCurbside">{{'cart.estimated' | cxTranslate}}</span>
                {{'cart.estTotal' | cxTranslate}}
            </span>
            <span>
                <sup class="t-price-tile">$</sup>
                <span class="t-price-currenyTile">{{cartDetails?.totalPrice?.formattedValue?.toString().split('.')[0].replace('$','')}}</span>
                <sup class="t-price-currenyFractionTile ml-1">{{cartDetails?.totalPrice?.formattedValue?.toString().split('.')[1]}}</sup>
            </span>
        </div>
        <div class="d-flex justify-content-center pb-3 mb-2 w-100">
            <span class="t-title-m-regular">{{'cart.estWeight' | cxTranslate}} {{cartDetails?.totalShippingWeight}} {{'cart.lbs' | cxTranslate}}</span>
        </div>
        <div class="pb-3">
            <button *ngIf="cartDetails?.isOrderLevelMDIEnabled && 
            ((cartDetails?.medicalDropshipError && cartDetails?.medicalDropshipError.length > 0) 
            && (!cartDetails?.medicalDropshipInformation)); else showCheckoutBtn" mat-flat-button color="primary" class="t-headerLink-normal w-100 p-3" [disabled]="cartDetails?.entries?.length === 0 || shipToNotSelected"
            (click)="medicalInformationCheck()" >{{'cart.secureCheckout' | cxTranslate}}</button>

            <ng-template #showCheckoutBtn>
                <button mat-flat-button color="primary" class="t-headerLink-normal w-100 p-3" [disabled]="cartDetails?.entries?.length === 0 || shipToNotSelected"
                [routerLink]="['/checkout/multi']" >{{'cart.secureCheckout' | cxTranslate}}</button>
            </ng-template>

        </div>
        <div class="pb-4 mb-1 text-center w-100 d-flex justify-content-center">
            <a href="{{baseUrl}}/homepage" mat-flat-button color="white" class="d-flex go-back-icon align-items-center m-auto">
                <mat-icon svgIcon="goBack"></mat-icon>
                <span>
                    {{'cart.goBack' | cxTranslate}}
                </span>
            </a>
        </div>
        <div class="t-title-m-regular text-center">
            <span>{{'cart.agreement' | cxTranslate}}</span>
            <a [routerLink]="['/terms-of-use']" target="_blank" class="text-decoration-underline cursor-pointer">{{'cart.termsOfUse' | cxTranslate}}</a>
            <a [routerLink]="['/terms']" target="_blank" class="text-decoration-underline cursor-pointer">{{'cart.conditionOfsale' | cxTranslate}}</a>
            <span>{{'cart.and' | cxTranslate}}</span>
            <a [routerLink]="['/privacy']" target="_blank" class="text-decoration-underline cursor-pointer">{{'cart.privacy' | cxTranslate}}</a>
        </div>
    </div>
</section>