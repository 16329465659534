import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-powered-by-boxout',
  templateUrl: './footer-powered-by-boxout.component.html'
})
export class FooterPoweredByBoxoutComponent {


}
