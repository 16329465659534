
<img
  *ngIf="media?.src"
  [attr.src]="media?.src"
  [attr.srcset]="media?.srcset"
  [attr.alt]="media?.alt"
  [attr.role]="media?.role"
  [attr.loading]="'lazy'"
  (load)="loadHandler()"
  (error)="errorHandler()"
/>