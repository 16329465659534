import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-free-freight-dialog',
  templateUrl: './free-freight-dialog.component.html',
})
export class FreeFreightDialogComponent {
  // currentDeliveryMode: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FreeFreightDialogComponent>
  ) {
  }

  cancel() {
    this.dialogRef.close({action:'close', initiator: this.data?.initiator}); // Pass 'close' as the result
  }

  continue() {
    this.dialogRef.close({action:'continue', initiator: this.data?.initiator}); // Pass 'continue' as the result
  }
}
