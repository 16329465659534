import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { AuthService, SiteContextConfig, WindowRef } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, of, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { User } from '../models/user.model';
import { Store } from '@ngrx/store';
import * as fromApp from 'src/app/core/store/app.reducer';
import * as AuthActions from 'src/app/core/store/actions/auth.actions';
import { GTMService } from 'src/app/shared/services/common/gtm.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  isShowCheckoutHeader: boolean = false;
  baseSiteName: any;
  baseSiteNameVal: any;
  isAsmUserLoggedIn: boolean = false;
  subscription: Subscription;

  user$: Observable<any | undefined>;
  showB2BUnitArrow: any = false;
  userId: any;
  currentB2bUnit: any;
  currentUser: any;
  currentCart: any;
  user: User;
  userSub: Subscription;
  constructor(
    private router: Router,
    private config: SiteContextConfig,
    private csAgentAuthService: CsAgentAuthService,
    private windowRef: WindowRef,
    private auth: AuthService,
    private userAccount: UserAccountFacade,
    private store: Store<fromApp.AppState>,
    public gtmService: GTMService
  ) {}

  /** this function will check the route and if its checkout page then
   * it will hide other header
   * and show the checkout header
   * and vise-versa */
  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.isShowCheckoutHeader = this.router.url.includes('/checkout/multi')
          ? true
          : false;
          if(this.isShowCheckoutHeader){
            this.userSub = this.store.select('auth').subscribe((user) => {
            this.user = user && user.user!;
            if(!this.user){
              this.user$ = this.auth.isUserLoggedIn().pipe(
                switchMap((isUserLoggedIn) => {
                  if (isUserLoggedIn) {
                    return this.userAccount.get();
                  } else {
                    return of(undefined);
                  }
                })
              );
              this.currentUser = this.user$.subscribe((data) => {
                this.user = data;
                if (this.user) {
                  this.store.dispatch(AuthActions.loginSuccess({ user: this.user }));
                  this.gtmService.setGTMUserData(this.user);
                }        
          
              });
            }
            });
          }
      });

    // For checking ASM user logged in status
    this.subscription = this.csAgentAuthService
      .isCustomerSupportAgentLoggedIn()
      .subscribe((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          this.isAsmUserLoggedIn = true;
        } else {
          this.isAsmUserLoggedIn = false;
          // For removing logged in asm user email(Used for current replacement condition)
          if (this.windowRef?.localStorage?.getItem('asmLoggedInUserEmail')) {
            this.windowRef?.localStorage?.removeItem('asmLoggedInUserEmail');
          }
        }
      });

    this.baseSiteName = this.config?.context?.baseSite;
    if (this.baseSiteName) {
      this.baseSiteNameVal = this.baseSiteName[0];
    }
  }
  /** this function will redirect back to cart on click of back arrow from checkout header */
  backToCart() {
    this.router.navigate(['/cart']);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if(this.userSub) {
      this.userSub.unsubscribe();
    }
  }
    
}
