<div class="ship-to-client-patient-container">
  <app-loader></app-loader>
  <div
    class="ship-to-address-header-border d-flex flex-row justify-content-between align-items-center"
  >
    <div class="t-headerLink-normal">{{'account.shipToClientPatientAddresses.title' | cxTranslate }}</div>
    <div class="mt-1">
      <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon" color="white">close</mat-icon>
    </div>
  </div>

    <!--  to show the error/success on client/patient account creation or on address addtion on client/patient account: start-->
    <div *ngIf="data?.value?.showFailureForCart" class="m-2">
      <reusable-alert-box
        [alertType]="'warningAlertBox'"
        alertMsg="{{ 'account.businessAccounts.infoUpdateError' | cxTranslate }}"
      ></reusable-alert-box>
    </div>
  
    <div *ngIf="data?.value?.showSuccessForCart" class="m-2">
      <reusable-alert-box
        [alertType]="'successAlertBox'"
        alertMsg="{{
          'account.businessAccounts.infoUpdateSuccess' | cxTranslate
        }}"
      ></reusable-alert-box>
    </div>
  
    <!--  to show the error/success on client/patient account creation or on address addtion on client/patient account: End-->

 <!--  to show the error/success when address is selected for shipping: start-->
 <div *ngIf="shippingAddressSelectedFailure" class="m-2">
  <reusable-alert-box
    [alertType]="'warningAlertBox'"
    alertMsg="{{ 'account.businessAccounts.infoUpdateError' | cxTranslate }}"
  ></reusable-alert-box>
</div>

<div *ngIf="shippingAddressSelectedSuccess" class="m-2">
  <reusable-alert-box
    [alertType]="'successAlertBox'"
    alertMsg="{{
      'account.businessAccounts.infoUpdateSuccess' | cxTranslate
    }}"
  ></reusable-alert-box>
</div>

<!--  to show the error/success when address is selected for shipping: End-->

  <div class="p-2 ship-to-client-addresses-scroll mb-5">
    <div class="d-flex flex-row justify-content-center align-items-center mb-3 mt-1">

      <div class="add-address-icon mr-1">+</div>
      <div [mat-dialog-close]="true" (click)="createNewClientAccount()">
        <a>
        <u *ngIf="baseSiteNameVal !== 'mesite'">{{'account.shipToClientPatientAddresses.addNewClientPatientAddress' | cxTranslate }}</u>
        <u *ngIf="baseSiteNameVal === 'mesite'">{{'account.shipToClientPatientAddresses.addNewClientPatientAddressForMesite' | cxTranslate }}</u>
        </a>
      </div>
    </div>

    <div class="d-flex position-relative mb-3">
      <input *ngIf="baseSiteNameVal !== 'mesite'"
        type="text"
        class="t-label-l rounded inputBox createClientButton"
        placeholder="Search for Client / Patient"
        id="keyfilter"
        [(ngModel)]="searchParam"
        (keyup)="getAddressData()"
        (keydown.enter)="getAddressData()"
      />
      <input *ngIf="baseSiteNameVal === 'mesite'"
        type="text"
        class="t-label-l rounded inputBox createClientButton"
        placeholder="Search for Member / Guest"
        id="keyfilter"
        [(ngModel)]="searchParam"
        (keyup)="getAddressData()"
        (keydown.enter)="getAddressData()"
      />
      <mat-icon
        *ngIf="
          searchParam !== '' &&
          searchParam !== undefined &&
          searchParam !== null
        "
        (click)="clearSearch()"
        class="closeIcon"
        svgIcon="cancel-icon"
      ></mat-icon>
      <mat-icon class="searchIcon" svgIcon="search_icon" (click)="getAddressData()"></mat-icon>
    </div>


    <div *ngFor="let item of addresses | paginate:{itemsPerPage:totalRecordsOnPage,currentPage:page,totalItems:totalRecords };" class="ship-to-address-block p-2 d-flex flex-column mb-3">
      <div class="t-tableData-mobile-content mb-2">{{item?.name? item.name: ''}}</div>
      <div class="t-productTile-iconAlert mb-2">{{'account.shipToClientPatientAddresses.dropShip' | cxTranslate }}  {{item?.id ? item?.id : ""}}</div>
      <div class="d-flex flex-row mb-1">
        <div *ngIf="item?.shipTo?.isPrimaryShippingAddress" class="outlined-badge-success t-address-type mr-2">
          {{ "account.businessAccounts.primaryShipTo" | cxTranslate }}
        </div>
        <div *ngIf="item?.shipTo?.addressType" class="outlined-badge-secondary-2 t-address-type">
          {{ item?.shipTo?.addressType ? item?.shipTo?.addressType : ("" | titlecase) }}
        </div>
      </div>
     
      <div class="t-body-s" *ngIf="item?.shipTo?.attendant">{{'account.shipToClientPatientAddresses.attn' | cxTranslate }} {{item?.shipTo?.attendant}}</div>
      <div class="t-body-s">{{showClientAddress(item?.shipTo)}}</div>
      <div class="t-body-s mb-1"><a class="phoneLink" href="tel:{{item?.phone }}" aria-label="Call On">{{(item?.phone ? item.phone: '') | formatPhone}}</a></div>

      <div class="ship-to-address-block-margin mb-2"></div>

      <div class="d-flex flex-row justify-content-between align-items-center">
        <div>
          <button class="ship-to-this-address-button" mat-flat-button color="primary" type="button" (click)="shipToThisAddress(item)">
            <u>{{'account.shipToClientPatientAddresses.shipToThisAddress' | cxTranslate }}</u>
          </button>
        </div>
        <div [mat-dialog-close]="true" (click)="openAddShipToDialog(item)" 
        *ngIf="item.addressCount=='0' || !(item.addressCount)"
          class="d-flex flex-row justify-content-center align-items-center"
        >
          <div 
            class="single-addresses-count-style d-flex justify-content-center align-items-center mr-1"
          >
            +
          </div>
          <div>
            <a> <u>{{'account.shipToClientPatientAddresses.addAddress' | cxTranslate }}</u> </a>
          </div>
        </div>

        <div [mat-dialog-close]="true"  (click)="openOtherAddressDrawer(item)"
        *ngIf="item.addressCount && item.addressCount > 0 && item.accountType!=='PARENT'"
          class="d-flex flex-row justify-content-center align-items-center"
        >
          <div
            class="other-addresses-count-style d-flex justify-content-center align-items-center mr-1"
          >
          {{item?.addressCount}}
          </div>
          <div><a><u>{{'account.shipToClientPatientAddresses.otherAddresses' | cxTranslate }}</u></a></div>
        </div>

        <div [mat-dialog-close]="true"  (click)="openOtherAddressDrawer(item)"
        *ngIf="item.addressCount && item.addressCount > 0 && item.accountType==='PARENT'"
          class="d-flex flex-row justify-content-center align-items-center"
        >
          <div
            class="other-addresses-count-style d-flex justify-content-center align-items-center mr-1"
          >
          {{item?.addressCount}}
          </div>
          <div><a><u>{{'account.shipToClientPatientAddresses.otherAddresses' | cxTranslate }}</u></a></div>
        </div>
      </div>
    </div>
  </div>

  <!--Pagination Starts-->
  <div class="paginationPosition">
    <div  *ngIf="totalRecords > 1" class="has-text-centered position-relative paginate mt-5">
      <p class="t-title-m d-none m-0 position-absolute d-md-block total">
        {{ totalRecords }} {{'account.shipToClientPatientAddresses.records' | cxTranslate }}
      </p>
      <pagination-controls
        *ngIf="totalRecords>totalRecordsOnPage && addresses && addresses.length"
        (pageChange)="getAddressData($event)"
        previousLabel=""
        nextLabel=""
      ></pagination-controls>
    </div>
  </div>
  <!--Pagination Ends-->
</div>
