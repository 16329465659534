// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  occBaseUrl: 'https://localhost:9002' || 'https://dvapi.myboxout.com' || 'https://qaapi.myboxout.com' || 'https://api.cvj2zvhmts-boxoutllc1-d1-public.model-t.cc.commerce.ondemand.com' || 'https://api.cvj2zvhmts-boxoutllc1-s1-public.model-t.cc.commerce.ondemand.com' || 'https://prodapi.myboxout.com' || 'https://boxout.local:9002' ,
  occApiPrefix: '/occ/v2/',
  mediaBaseUrl:  'https://localhost:9002' || 'https://dvapi.myboxout.com' || 'https://qaapi.myboxout.com' || 'https://api.cvj2zvhmts-boxoutllc1-d1-public.model-t.cc.commerce.ondemand.com' || 'https://api.cvj2zvhmts-boxoutllc1-s1-public.model-t.cc.commerce.ondemand.com' || 'https://prodapi.myboxout.com' || 'https://boxout.local:9002' ,
  smartEditAllowOrigin: 'localhost:9002,dvbackoffice.myboxout.com:443,qabackoffice.myboxout.com:443,prodbackoffice.myboxout.com:443',
  smartEditPreviewUrl: 'cx-preview'
}
/**
 * This is GOOGLE TAG MANAGER key for different sites
 */
export const googleTagKeys = {
  musite: "GTM-M6JRGPW",
  fwsite: "GTM-TF5W8XN",
  chsite: "GTM-55C346P",
  spasite: "GTM-TBBG6R2",
  ptsite: "GTM-P4X4Z6Z",
  omsite: "GTM-P4HG6FX",
  mmsite: "GTM-WTVM5V8",
  bxdsite: "GTM-53VNFZ5",
  bxdportal: "GTM-WPXHTJ99",
  mesite: "GTM-N8PDXL7J"
}
export const googleSiteKeys = {
  musite: "6LdAG90fAAAAAMu_3hznvymYppIYzhvwWB60PuFW",
  fwsite: "6LdAGd0fAAAAAJv8HK_FS4aKVJrnrhWtfMjV2jVf",
  chsite: "6LeQd1kaAAAAAGj-alX9L4Dd4kUCzmPIippHIbgH",
  spasite: "6LdWNNwfAAAAALz-HlpyfPuEkelyXUOd0HKqVWK6",
  ptsite: "6LcDM9wfAAAAAJsQcqA-d6DKSR3o5sT7vByMgylS",
  omsite: "6Ld1Gt0fAAAAACuc2Z6s983QKt2qGU7oUJvvj0tW",
  mmsite: "6LfUGN0fAAAAAPFYLrtd_Eqozkx1QwRMyIcTC2tS",
  bxdsite: "6LddAeAfAAAAAB0CrfL2-QeUQLfTFNRDzGaIBm8D",
  bxdportal: "GTM-WPXHTJ99",
  mesite: "6LdafJokAAAAAH9ahylcBr8Qn-xt9-NWRmkecrJW"
}

// For support contact number for each site
export const supportPhone = {
  musite: "1-833-509-0245",
  fwsite: "1-800-537-5512",
  chsite: "1-800-472-4221",
  spasite: "1-888-450-1040",
  ptsite: "1-866-528-2144",
  omsite: "1-855-330-2211",
  mmsite: "1-800-532-1356",
  bxdsite: "1-833-462-7746",
  bxdportal: "1-833-462-7746",
  mesite: "1-888-400-0348"
}


export const favIconKeys = {
  musite: "mu-favicon.ico",
  fwsite: "fw-favicon.ico",
  chsite: "ch-favicon.ico",
  spasite: "spa-favicon.ico",
  ptsite: "pt-favicon.ico",
  omsite: "om-favicon.ico",
  mmsite: "mm-favicon.ico",
  bxdsite: "boxout-favicon.ico",
  mesite: "me-favicon.ico"
}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
