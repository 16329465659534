<!--This displays block to edit Ship To addresses-->
<div class="d-flex flex-column container p-3 edit-ship-to-block">
  
  <div class="d-flex flex-column mt-3">
    <form [formGroup]="editShipToAddressForm" (submit)="saveEditedShipToAddress()">



      <div class="mb-3 edit-ship-to-input">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "account.createAccount.attnTitle" | cxTranslate }}
          </mat-label>
          <input
            matInput
            type="text"
            class=""
            formControlName="attn"
            maxlength="30"
          />
          <mat-error></mat-error>
        </mat-form-field>
      </div>

      <div class="edit-ship-to-reusable-address-form">
        <app-reusable-address-form></app-reusable-address-form>
      </div>

      
       <div class="d-flex flex-row mb-3">

        <!--Default Ship To toggle should  be disabled if it is already ON -->
        
        <div class="mr-3 d-flex">
          <div class="t-body-s edit-ship-to-toggle-ship">{{ "account.createAccount.defaultShippingToTitle" | cxTranslate }}</div>
            <mat-slide-toggle formControlName="defaultShippgTo" [disabled]="data && data.value && data.value.primaryShipping"
            [(ngModel)]="editShipToAddressForm.get('defaultShippgTo').value"
             class="edit-mat-toggle-status"
             color="primary">{{editShipToAddressForm.get('defaultShippgTo').value ? 'On' : 'Off'}}</mat-slide-toggle>      
  
        </div>

        <!--Default Bill To toggle should not be visible for client page addresses and if customer role is Purchaser or Approver-->
  
        <!--Default Bill To toggle should not be visible for subAccount i.e; DROPSHIP account type-->
        <!--Default Bill To toggle should  be disabled if it is already ON -->
        <div class="d-flex" *ngIf="!(item.accountType === 'DROPSHIP' || viewAddressAccountType === 'DROPSHIP' || manageAccountType === 'DROPSHIP') && !(componentName === 'myClientAddress' || componentName === 'manageClientAddress')
        && !(customerRole === 'Approver' || customerRole === 'Purchaser')">
          <div class="t-body-s edit-ship-to-toggle-bill">{{ "account.createAccount.defaultBillToTitle" | cxTranslate }}</div>
            <mat-slide-toggle formControlName="defaultBillingTo" [disabled]="data && data.value && data.value.primaryBilling"
            [(ngModel)]="editShipToAddressForm.get('defaultBillingTo').value"
             class="edit-mat-toggle-status"
             color="primary">{{editShipToAddressForm.get('defaultBillingTo').value ? 'On' : 'Off'}}</mat-slide-toggle>      
  
        </div>
       </div>
     
<div class="d-flex justify-content-between pt-4 align-items-baseline">
  <div>
  <span *ngIf="!(isPrimaryShipTo || isPrimaryBillTo)" (click)="removeAddress()" class="cursor-pointer t-body-l pl-0 ml-0 pl-md-4 ml-md-2"> {{ "account.order.remove" | cxTranslate }}</span>
</div>

  <div class="d-flex justify-content-end mb-3">

        <div class="mr-3 edit-ship-to-cancel-button">
          <button
            mat-flat-button color="white"
            type="button"
            (click)="closeThisShipToEditAddress()"
          
          >
          {{ "account.createAccount.cancelA" | cxTranslate }}
          </button>
        </div>
        <div>
          <button
            mat-flat-button color="primary"
            type="submit"
          >
          {{ "account.createAccount.saveA" | cxTranslate }}
          </button>
        </div>
      </div>
    </div>
    </form>
  </div>
</div>
