import { Component } from '@angular/core';
import { FooterNavigationComponent } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'footer-custom-navigation',
  templateUrl: './footer-custom-navigation.component.html',
})
export class FooterCustomNavigationComponent extends FooterNavigationComponent {
  node$: Observable<any> = this.service.getNavigationNode(
    this.componentData.data$
  );
  
}
