import { Injectable, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { WindowRef } from '@spartacus/core';
import { Observable, Subject, Subscribable, Subscription } from 'rxjs';
import * as fromApp from 'src/app/core/store/app.reducer';
import { PageLoaderService } from 'src/app/feature/pages/page-loader/page-loader.service';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { CurrentThemeService } from 'src/app/shared/services/common/current-theme.service';
import { User } from '../../../core/models/user.model';
import { ReusableMiniCartComponent } from '../../components/reusable-mini-cart/reusable-mini-cart.component';
import { HeaderService } from '../header.service';
import { CartService } from './cart.service';
import { GTMService } from './gtm.service';
@Injectable({
  providedIn: 'root'
})
export class QuickOrderService implements OnDestroy {
  // userId: any;
  // userData: any;
  quickOrderReq: any = { "orderEntries": [] };
  cartIdSub: any;
  // isLoggedInSub: any;
  // userDataSub: any;
  cartId: any;
  isAddBtnShow: any = true;
  quickOrderForm:FormGroup;
  miniCartDialog:any;
  themeClass:any;
  closeQuickOrderNav = new Subject<any>();
  isLoggedIn: any;
  cartIdspecific: any;
  userSub: Subscription;
  user: User;
  addToCartSub: any;
  gaItemsArray: any[];
  gaAddToCartObj: any = {};

  constructor(private fb: FormBuilder, private headerService: HeaderService,
    private cartService: CartService, private customHttp: BoxoutEndpointService,
    private pageLoaderService:PageLoaderService,
    private windowRef: WindowRef,
    public dialog: MatDialog, private theme: CurrentThemeService,private store: Store<fromApp.AppState>,
    public gtmService: GTMService, private router:Router) { 
      this.themeClass = this.theme?.getCurrentTheme();
    }

    private specificProduct: Subject<any> = new Subject<any>();
    specificProduct$: Observable<any> = this.specificProduct.asObservable();
  /**
   * This function will initialize the quick order form and get the cart Id.
   */
  initForm(quickOrderForm: FormGroup, intialRow: any, userId?: string) {
    // this.isLoggedInSub = this.headerService.isLoggedIn.subscribe((response) => {
    //   this.userData = response;
    // });
    // this.userDataSub = this.userData?.subscribe((data: any) => {
    //   this.userId = data?.uid;
    this.quickOrderForm = quickOrderForm;
    this.addQuickOrder(intialRow);
    this.userSub = this.store.select('auth').subscribe(user => {
      this.user = user && user.user!;
      let userId = this.user?.uid!;
      if (userId) {
        if (userId && this.windowRef.sessionStorage?.getItem('cartId')) {
          this.cartId = this.windowRef.sessionStorage?.getItem('cartId');
        }
      }
    });
  }
  /*
    This getCart service will provide the cartId if the cart is already available otherwise will create a
    new cart and return the cartId of newly created cart.
  */
  //Will remove after proper testing
  // getCartId(userId: string, intialRow?: any) {
  //   this.cartIdSub = this.cartService.getCart(userId)?.subscribe((resp: any) => {
  //     this.cartId = resp;
  //     return this.cartId;
  //   })
  // }
  /*
   This function will iterate through the each form array field and validate the item and quantity that
   it should not be null or undefined and set the form error attribute to true if it's null or undefined. 
 */
  onFormSubmit(userId: string): any {
    this.quickOrderReq.orderEntries = [];
    this.getArrayControls().forEach((element, index) => {
      const controlItem = element.get('item');
      const controlQty = element.get('qty');
      if ((element.value.item && !element.value.qty) || (!element.value.item && element.value.qty)) {
        if (!element.value.item) {
          controlItem?.setErrors({ 'itemError': true });
          controlItem?.markAsTouched({ onlySelf: true })
        } else if (!element.value.qty) {
          controlQty?.setErrors({ 'qtyError': true });
          controlQty?.markAsTouched({ onlySelf: true })
        }
      } else if (element.value.item && element.value.qty && this.quickOrderForm.valid) {
        let obj = {
          "product": {
            "code": element.value.item
          },
          "quantity": + element.value.qty
        }
        this.quickOrderReq.orderEntries.push(obj)
      }
      controlQty?.valueChanges.subscribe(data => {
        controlItem?.setErrors({ 'itemError': null });
        controlItem?.updateValueAndValidity();
      })
      controlItem?.valueChanges.subscribe(data => {
        if(!data && !element.value.qty && controlQty?.hasError('qtyError')){
          controlQty?.setErrors({ 'qtyError': null });
          controlQty?.updateValueAndValidity();
        }
        
      })
    });
    if (this.quickOrderForm.valid && this.quickOrderReq?.orderEntries?.length) {
      this.addToCart(this.quickOrderReq, userId);
    }else{
      return this.quickOrderForm.valid ? true: false;
    }
  }
  getArrayControls() {
    return (this.quickOrderForm?.get('quickOrder') as FormArray)?.controls;
  }
  /* 
    This function will push the object with intial values into form array 
  */
  initGroup() {
    let quickOrder = this.quickOrderForm.get('quickOrder') as FormArray;
    quickOrder.push(this.fb.group({
      item: [null],
      qty: [null, Validators.pattern('^(0|[1-9][0-9]*)$')],
    }));
    this.isAddBtnShow = this.quickOrderForm.value.quickOrder?.length >= 24 ? false : true;
    return this.isAddBtnShow;
  }
  //This function will add 5 item in form array.
  addQuickOrder(intialRow: any) {
    [...Array(intialRow)].forEach((_, index) => { this.initGroup() });
    return this.isAddBtnShow;
  }

  /**
   * This function will add the item in cart if every line item and quantity is valid else 
   * it will return error without adding any item in cart.
   */
  addToCart(request: any, userId: string) {
    if (userId && this.windowRef.sessionStorage?.getItem('cartId')) {
      this.cartId = this.windowRef.sessionStorage?.getItem('cartId');
      this.addToQuickCart(userId,request);
    } else if (userId && !this.windowRef.sessionStorage?.getItem('cartId')) {
      this.cartIdSub = this.cartService.getCart(userId)?.subscribe((resp: any) => {
        this.cartId = resp;
        this.addToQuickCart(userId,request);
      })
     
    }
    
  }

  addToQuickCart(userId: any,request: any){
    let apiURL = `/users/${userId}/carts/${this.cartId}/quickOrder/?bulkMode=false`;
    this.gaAddToCartObj = {};
    this.windowRef.sessionStorage?.removeItem('gaItemsForCartUpdate');
    this.windowRef.sessionStorage?.removeItem('totalForCartUpdate');
    this.gaItemsArray = [];
    let updateCartTotal:any = 0;
    this.addToCartSub = this.customHttp.post(apiURL, request).subscribe((resp: any) => {
      if (resp.success) {
        this.quickOrderForm.reset();
        this.closeQuickOrderNav.next(true);
        if(resp && resp.cartModifications && resp.cartModifications.length) {
          resp.cartModifications.forEach((cartObj: any, index: any) => {
            this.gaAddToCartObj = cartObj?.entry?.product?.gaItems;
            this.gaAddToCartObj = { ...this.gaAddToCartObj, discount: null, index: index , price: cartObj?.entry?.basePrice?.value , quantity: cartObj?.quantityAdded, item_list_id: 'quick order', item_list_name: 'Quick Order'};
            updateCartTotal = updateCartTotal + (this.gaAddToCartObj?.price * parseInt(this.gaAddToCartObj?.quantity));
            this.gaItemsArray.push(this.gaAddToCartObj);
          })
        }
        if(this.router.url === '/cart') {
          this.gtmService.addProductToCart(this.gaAddToCartObj);
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
          this.router.navigate(['/cart']));
        } else {
          this.windowRef.sessionStorage?.setItem('gaItemsForCartUpdate' , JSON.stringify(this.gaItemsArray));
          this.windowRef.sessionStorage?.setItem('totalForCartUpdate', updateCartTotal);
          this.openMiniCart(userId);
        }
        this.gtmService.setCartGTMData(resp?.cartModifications[0]?.entry,resp?.cartModifications[0]?.quantityAdded);
      } else {
        this.validateQuickOrder(resp.cartModifications);
      }
    }, (error) => {
    });
    return this.closeQuickOrderNav.asObservable();
  }
  /*
  *This function will iterate the quick order response and check for the error for each item and append
  *error variable and message in form field to show the error message.
  */
  validateQuickOrder(resp: any) {
    resp.forEach((ele: any, ind: any) => {
      this.getArrayControls().forEach((element, index) => {
        const controlItem = element.get('item');
        if (element.value.item.toLowerCase() == ele.entry.product.code.toLowerCase() && element.value.qty == ele.quantity && ele.statusCode !== 'success') {
          controlItem?.setErrors({ 'isError': true, message: ele.statusMessage });
          controlItem?.markAsTouched({ onlySelf: true })
        }
      });
    });
  }
  /*
    This function will unsubscribe all the subscription.  
  */
  ngOnDestroy() {
    (this.quickOrderForm?.get('quickOrder') as FormArray)?.clear();
    this.quickOrderForm?.reset();
    this.cartIdSub?.unsubscribe();
    this.isLoggedIn?.unsubscribe();
    this.cartIdspecific?.unsubscribe();
    if(this.addToCartSub) {
      this.addToCartSub.unsubscribe();
    }
  }

  /**
   * This function will open the mini cart silder on click of add to cart CTA 
   */
  openMiniCart(userId: string, error?:any){
        this.miniCartDialog = this.dialog.open(ReusableMiniCartComponent, {
          position: {
            right: '0px',
            top: '0px'
          },
          panelClass: ['header-links', this.themeClass],
          data: {
            cartError: error,
            userID : userId
          }
        });
      }

  /**
   * To get card Id for ordering specific product
   */
  getCartIdSpecificProduct(request:any, component:any, userId: string){
    // this.isLoggedIn = this.headerService.isLoggedIn.subscribe((response) => {
    //   this.userData = response;
    // });
    // this.userDataSub = this.userData?.subscribe((data: any) => {
    //   this.userId = data?.uid;
        if (userId && this.windowRef.sessionStorage?.getItem('cartId')) {
          this.cartId = this.windowRef.sessionStorage?.getItem('cartId');
          if(component === 'reports') {
            this.addReportsProductsToCart(request, userId);
          } else {
            this.addToCartSpecificProduct(request, userId);
          }
        } 
        else if (userId && !this.windowRef.sessionStorage?.getItem('cartId')) {
          this.cartIdspecific = this.cartService.getCart(userId)?.subscribe((resp: any) => {
            this.cartId = resp;
            if(component === 'reports') {
              this.addReportsProductsToCart(request, userId);
            } else {
              this.addToCartSpecificProduct(request, userId);
            }
        });
        }
    // });
  }
  
  addReportsProductsToCart(request: any, userId: string) {
    let apiURL = `/orgUsers/${userId}/carts/${this.cartId}/entries/?fields=DEFAULT`;
    this.customHttp.post(apiURL,request).subscribe((resp: any) => {
      if (resp) {
        this.openMiniCart(userId, resp);
      } 
    }, (error) => {
    });
  }
  /**
   * To add specific product to cart 
   */
  addToCartSpecificProduct(request:any, userId: string){
    let apiURL = `/users/${userId}/carts/${this.cartId}/addToCart/?fields=DEFAULT`;
    this.customHttp.post(apiURL,request).subscribe((resp: any) => {
      this.specificProduct.next(resp);
      if (resp.statusCode == "success") {
        if(this.router.url === '/cart') {
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
          this.router.navigate(['/cart']));
        } else {
          this.openMiniCart(userId);
        }
      } 
    }, (error) => {
    });
  }

  reorderSelected(request: any, userId: string) {
    this.getCartIdSpecificProduct(request, 'reports', userId);
  }
  

}
