import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SmartyAddressValidationService } from 'src/app/shared/services/common/smarty-address-validation.service';

@Component({
  selector: 'app-recommended-address',
  templateUrl: './recommended-address.component.html'
})
export class RecommendedAddressComponent implements OnInit {

  themesForScroll: any = this.document.getElementsByClassName('cdk-global-overlay-wrapper');


  constructor(public dialogRef: MatDialogRef<RecommendedAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private smartyAddressValidationService: SmartyAddressValidationService,
    @Inject(DOCUMENT) private document: Document
    ) { }
    
    sentData:any;
    submittedAddress:any;
    submittedFormAddress:any;

  ngOnInit(): void {
    //Data is getting received which was sent from Create bussinnes account dialog
    if(this.data){
      this.sentData=this.data;
      this.submittedAddress=this.data.submitedAddress.addressOne+ " "+this.data.submitedAddress.optionalAddressTwo+" "+this.data.submitedAddress.cityName+ ", "+
      this.data.submitedAddress.stateName.replace("USA-","")+ " "+ this.data.submitedAddress.zipCode;
      this.submittedFormAddress=this.data.submittedForm.address;
    }

    //scroll theme for Recommended address dialog
    this.themesForScroll[0].className += " custom-modal";
    
  }


  editThisAddress(){
   this.dialogRef.afterClosed().subscribe((res)=>{
     if(res === 'editButtonClicked'){
      this.smartyAddressValidationService.sendRecommendedData(
        {
          editButtonClicked:true,
          editAddressData:this.sentData.submittedForm
        }
      );
     }
   }, (error)=>{
   })
    
  }

  //This method is used to submit form with Submitted address data when user clicks on Use This Address button
  useSubmittedAddress(){
  
    //submitting form and post call 
    this.smartyAddressValidationService.saveFormData(this.data.submittedForm);
  }
  
  //This method is used to submit form with Recommended address data when user clicks on Use This Address button
  useRecommendedAddress(recomAdd: any){
    this.submittedFormAddress.addressOne=recomAdd.street_line;
    this.submittedFormAddress.cityName=recomAdd.city;
    this.submittedFormAddress.optionalAddressTwo=recomAdd.secondary;
    this.submittedFormAddress.stateName="USA-"+recomAdd.state;
     this.submittedFormAddress.zipCode=recomAdd.zipcode;

    //submitting form and post call 
    this.smartyAddressValidationService.saveFormData(this.data.submittedForm);
  }

  //This method is used to format address in string which is shown on UI
  buildAddress(recomAdd:any){
    let whiteSpace = "";
    if (recomAdd.secondary) {
      if (recomAdd.entries > 1) {
        recomAdd.secondary += " (" + recomAdd.entries + " entries)";
      }
      whiteSpace = " ";
    }
    return recomAdd.street_line + whiteSpace + recomAdd.secondary + " " + recomAdd.city + ", " + recomAdd.state + " " + recomAdd.zipcode;
  }

  closeREcommendedAddressPopUp(){
    this.smartyAddressValidationService.setDailogDataToRegister();
  }

  
}
