import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { BoxoutEndpointService } from '../../services/common/boxout-endpoint.service';
import { CurrentThemeService } from '../../services/common/current-theme.service';
import { InputValidationService } from '../../services/common/input-validation.service';
import { SmartyAddressValidationService } from '../../services/common/smarty-address-validation.service';
import { HeaderService } from '../../services/header.service';
import { ReusableAddressFormComponent } from '../reusable-address-form/reusable-address-form.component';
import { ShipToClientOrPatientDrawerComponent } from '../ship-to-client-or-patient-drawer/ship-to-client-or-patient-drawer.component';

interface ClentType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-create-client-acct',
  templateUrl: './create-client-acct.component.html',
})
export class CreateClientAcctComponent implements OnInit, OnDestroy {

  clientTypeList: ClentType[] = [
    { value: "consumer", viewValue: "Consumer" },
    { value: "business", viewValue: "Business" }
  ];

  @ViewChild(ReusableAddressFormComponent, { static: true })
  public addressFormComponent!: ReusableAddressFormComponent;

  createClientAccountForm: any;
  public currentTheme: any;
  user: User;
  userSub: Subscription;
  userId: any;
  themesForScroll: any = this.document.getElementsByClassName('cdk-global-overlay-wrapper');
  smartyKeyValForAddressValidation: any;
  siteData: any;
  dataFromRecommendedDialogEditButton: any;
  newlyCreatedAccountSub:Subscription;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private smartyAddressValidationService: SmartyAddressValidationService,
    public dialogRef: MatDialogRef<CreateClientAcctComponent>,
    public dialog: MatDialog,
    private headerService: HeaderService,
    private theme: CurrentThemeService,
    private customHttp: BoxoutEndpointService,
    @Inject(DOCUMENT) private document: Document,
    private windowRef: WindowRef,
    private store: Store<fromApp.AppState>,
    private inputValidationService:InputValidationService
  ) { }

  ngOnInit(): void {

    this.userSub = this.store.select('auth').subscribe(user => {
      this.user = user && user.user!;
      this.userId = this.user?.uid;
    });

    this.createClientAccountForm = this.formBuilder.group({
      clientType: [(this.data?.value?.clientType ? this.data?.value?.clientType : ""), [Validators.required]],
      firstName: [(this.data?.value?.firstName ? this.data?.value?.firstName : ""), [Validators.required, Validators.maxLength(20)]],
      lastName: [(this.data?.value?.lastName ? this.data?.value?.lastName : ""), [Validators.required, Validators.maxLength(20)]],
      businessName: [(this.data?.value?.businessName ? this.data?.value?.businessName : "")],
      attn: [(this.data?.value?.attn ? this.data?.value?.attn : ""), [Validators.maxLength(30)]],
      address: this.addressFormComponent.createAddressForm(this.data?.value?.address),
      phoneNumber: [(this.data?.value?.phoneNumber ? this.data?.value?.phoneNumber : ""), [Validators.minLength(10), Validators.maxLength(10)]],
      mobileNumber: [(this.data?.value?.mobileNumber ? this.data?.value?.mobileNumber : ""), [Validators.minLength(10), Validators.maxLength(10)]],
      deliverNote: [(this.data?.value?.deliverNote ? this.data?.value?.deliverNote : ""), [Validators.maxLength(60)]],
      addressType: [(this.data?.value?.addressType ? this.data?.value?.addressType : "")],
      //BCGI2-796: client or patient ship to address changes
      action: [(this.data?.value?.action ? this.data?.value?.action : "")]
    });

    //scroll theme for reusable shpping to address dialog
    this.themesForScroll[0].className += " custom-modal";

    //getting the key for smarty address API validation
    this.headerService.localPropertiesConfigData.subscribe((resp: any) => {
      if (resp) {
        this.siteData = resp;
        const smartyKeyVal: any = 'spartacus.config.smarty.key';
        this.smartyKeyValForAddressValidation = this.siteData[smartyKeyVal];
      }

    });

    /** Taking themes from themeservice **/
    this.currentTheme = this.theme?.getCurrentTheme();


    //to get userid 
    // this.headerService.isLoggedIn.subscribe(response => {
    //   this.userData = response;
    // });
    // this.userData?.subscribe((data: any) => {
    //   this.userId = data?.uid;
    // });

    //Applying validator on business name field when create client account form loads

    if (this.createClientAccountForm.controls["clientType"].value === "business") {
      this.createClientAccountForm.controls["businessName"].setValidators([Validators.required, Validators.maxLength(50)]);
      this.createClientAccountForm.controls["businessName"].updateValueAndValidity();
    }
    else {
      this.createClientAccountForm.controls["businessName"].clearValidators();
      this.createClientAccountForm.controls["businessName"].updateValueAndValidity();
    }

    //Applying validator on business name field when clientType values changes after create client account form loaded

    this.createClientAccountForm.controls["clientType"].valueChanges
      .subscribe((value: any) => {
        if (value === "business") {
          this.createClientAccountForm.controls["businessName"].setValidators([Validators.required, Validators.maxLength(50)]);
          this.createClientAccountForm.controls["businessName"].updateValueAndValidity();
        } else {
          this.createClientAccountForm.controls["businessName"].clearValidators();
          this.createClientAccountForm.controls["businessName"].updateValueAndValidity();

        }
      });

    //when user clicks Edit This Address button on recommened address dialog then 
    //below code populates create client account form again with prefilled values

    this.smartyAddressValidationService.receivedRecommendedData$.subscribe((sentData) => {

      this.dataFromRecommendedDialogEditButton = sentData;

      if (this.dataFromRecommendedDialogEditButton.editButtonClicked
        && this.dataFromRecommendedDialogEditButton.editAddressData.componentRef === "CreateClientAccount") {
        let editAddData = this.dataFromRecommendedDialogEditButton.editAddressData;
        editAddData.addressType = editAddData.addressType;
        this.createClientAccountForm.patchValue(editAddData);

      };

      if (!(JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("CreateClientAccountReopened"))))
        && this.dataFromRecommendedDialogEditButton.editAddressData.componentRef === "CreateClientAccount") {

        this.windowRef.sessionStorage?.setItem("CreateClientAccountReopened", "true");

        //BCGI2-796: client or patient ship to address changes
       if(this.data?.value?.action === 'addingNewClientOrPatientShipToAccount'){
        if ((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("CreateClientAccountReopened"))))) {
          this.dialog.open(CreateClientAcctComponent,
            {
              panelClass: [this.currentTheme, 'ship-to-client-drawer-for-all'],
              width:'368px',
              height:'100%',
              position: {
                right: '0px',
                top: '0px'
             },
              data: this.createClientAccountForm
            },
          );
          if (Object.keys(this.dialogRef).length > 0) {
            this.dialogRef.close();
          }
        }
       }else{
        if ((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("CreateClientAccountReopened"))))) {
          this.dialog.open(CreateClientAcctComponent,
            {
              panelClass: [this.currentTheme, 'business-account-dialogs-style'],
              autoFocus: false,
              disableClose: true,
              data: this.createClientAccountForm
            },
          );
          if (Object.keys(this.dialogRef).length > 0) {
            this.dialogRef.close();
          }
        }
       }
      }
    });

    //On click of USe this address or Create client Account success scenerio

    this.smartyAddressValidationService.savedFormDataSource.subscribe((data) => {

      if (data && data.componentRef === "CreateClientAccount" &&
        !(JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("finalClientFormSubmitCalled"))))) {
          if(this.data && this.data.value){
            this.data.value.action=data?.action;
          }
        this.finalFormSubmit(data);
      }
    }, (error) => {
    });
  }

  createClientAccount() {
    if (this.createClientAccountForm.valid) {
      let addressOne = this.createClientAccountForm.get('address')?.value.addressOne;
      let optionalAddressTwo = this.createClientAccountForm.get('address')?.value.optionalAddressTwo;
      let cityName = this.createClientAccountForm.get('address')?.value.cityName;
      let stateIsocode = this.createClientAccountForm.get('address')?.value.stateName;
      let stateText = this.smartyAddressValidationService.getStateText(stateIsocode);

      let zipCode = this.createClientAccountForm.get('address')?.value.zipCode;
      let countryText = 'United States';

      //sending component reference so that when we subscribe subject releted to its specific component
      let componentReference = "CreateClientAccount";

      //close the create client account form when form is valid and its going for smarty address 
      //validation which either opens recommended address dialog or submits the create businness account form
      this.dialogRef.close();

      //This is used to validate address in Create client account form and submit form or it can also open
      //recommened address dialog in case if there are any recommened addresses
      this.smartyAddressValidationService.addressValidation(addressOne, optionalAddressTwo, cityName, stateIsocode, stateText, zipCode, countryText, this.createClientAccountForm, this.smartyKeyValForAddressValidation, componentReference);
    }
    else {
      //invalid form alert
      this.createClientAccountForm.markAllAsTouched();
      const firstElementWithError = document.querySelector('.ng-invalid');

      if (firstElementWithError) {
        firstElementWithError.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  //To submit the form and create client Account
  finalFormSubmit(formData: any) {
    this.windowRef.sessionStorage?.setItem("finalClientFormSubmitCalled", "true");
    const requestBody = this.formRequestBody(formData);
    //api url for create client account
    let apiUrl = `users/${this.userId}/shipToClient?fields=DEFAULT`;

    if ((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("finalClientFormSubmitCalled"))))) {
      this.customHttp.post(apiUrl, requestBody).subscribe(
        (data: any) => {

          this.windowRef.sessionStorage?.removeItem("finalClientFormSubmitCalled")
          //to close recommended address dialog when Client account is  created
          if (this.smartyAddressValidationService.openedDialog) {
            this.smartyAddressValidationService.openedDialog.close();
          }
          //to refresh the data on my business page
          if(this.data?.value?.action === 'addingNewClientOrPatientShipToAccount'){
            //To show success alert message on shipto client or patient drawer(open this drawer) when account is created
            this.getNewlyCreatedAccount(true);
          }else{
            //To show success alert message on My Client Accont page when business account is created
            this.smartyAddressValidationService.saveClientAccountAlert(true);
          }
          
        },
        (error: any) => {
          //to close recommended address dialog when Client account is not created
          if (this.smartyAddressValidationService.openedDialog) {
            this.smartyAddressValidationService.openedDialog.close();
          }
          if(this.data?.value?.action === 'addingNewClientOrPatientShipToAccount'){
           //To show error alert message on shipto client or patient drawer(open this drawer) when account is not created
            this.getNewlyCreatedAccount(false);
          }else{
          //To show error alert message on My Client Accont page when Client account is not created
            this.smartyAddressValidationService.saveClientAccountAlert(false);
          }
        });
    }
  }

  // This method is used to form request body which will be sent in create client account POST Call
  formRequestBody(body: any): any {

    if (body) {
      return {
        addresses: [
          {
            addressType: body?.addressType,
            attendant: body?.attn,
            country: {
              isocode: body.address.countryName,
              name: "United States"
            },
            defaultAddress: true,
            deliveryNote: body.deliverNote,
            district: body.address.cityName,
            line1: body.address.addressOne,
            line2: body.address.optionalAddressTwo,
            postalCode: body.address.zipCode,
            region: {
              countryIso: body.address.countryName,
              isocode: body.address.stateName,
              isocodeShort: body.address.stateName,
              name: this.smartyAddressValidationService.getStateText(body.address.stateName)
            },
            town: body.address.cityName,
            visibleInAddressBook: true
          }
        ],
        businessName: body.businessName,
        clientType: body.clientType,
        firstName: body.firstName,
        lastName: body.lastName,
        mobile: body.mobileNumber,
        phone: body.phoneNumber,

      }
    }
  }

  ngOnDestroy(): void {
    if ((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("finalClientFormSubmitCalled"))))) {
      this.windowRef.sessionStorage?.removeItem("finalClientFormSubmitCalled");
    }

    if ((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("CreateClientAccountReopened"))))) {
      this.windowRef.sessionStorage?.removeItem("CreateClientAccountReopened");
    }

    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }


    //to get the updated account on client account creation for cart
    getNewlyCreatedAccount(val:any){
      
          this.dialog.open(ShipToClientOrPatientDrawerComponent, {
            position: {
              right: '0px',
              top: '0px'
            },
            panelClass: ['wishlist-drawer', this.currentTheme],
            data: {
              value:{
                showSuccessForCart:val,
                showFailureForCart:!val
              }
            }
          });
    
    }

    checkSpecChar(event:any){
      this.inputValidationService.checkRegisterSpecChar(event);
    }

}
