import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Breadcrumbs } from 'src/app/core/constants/breadcrumbs.constants';
import { Router } from '@angular/router';
import { User } from 'src/app/core/models/user.model';
import { Store } from '@ngrx/store';
import * as fromApp from 'src/app/core/store/app.reducer';
import { Subscription } from 'rxjs';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';

@Component({
  selector: 'app-rewards-credits-main',
  templateUrl: './rewards-credits-main.component.html',
})
export class RewardsCreditsMainComponent implements OnInit, OnDestroy {
  breadcrumb = Breadcrumbs.rewards;
  private rewardsCreditMockJson = 'assets/mock-json/rewards-credits.json';
  creditRewards: any;
  user: User;
  userType: any;
  userId: any;
  userSub: Subscription;
  showWarning : boolean = false; 
  rewardTitle : any ;
  rewardSummary : any;
 
  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store<fromApp.AppState>,
    private customHttp:BoxoutEndpointService,
    private cdr: ChangeDetectorRef,
    ) { }

  ngOnInit() {
    this.userSub = this.store.select('auth').subscribe((user) => {
      this.user = user && user.user!;
      if (this.user) {
        this.rewardTitle = this.user.programTitle;
        this.rewardSummary = this.user.summary;
        const apiUrl = `/users/${this.user?.email}/rewards-credits?fields=DEFAULT`;
        this.customHttp.get(apiUrl).subscribe(
          (data) => {
            if (data) {
              this.creditRewards = data;
            } else {
              this.showWarning = true;
              this.cdr.detectChanges();
            }
            this.cdr.detectChanges();
          },
          (error: any) => {
            this.showWarning = true;
            this.cdr.detectChanges();
          }
        );
      }
    });
  }

  //Navigating to the Reward Brand Specific Page
  navigateToDetails(brandCode: any, brandName: any) {
    const url = `/my-account/rewards-credits/details/${brandCode}`;
    this.router.navigateByUrl(url,{ state: {name: brandName, code: brandCode }});
  }

  //Function to convert a string to number
  parseInteger(value: string) {
    return parseInt(value, 10);
  }

  ngOnDestroy(): void {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }
}
