import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CurrentThemeService } from 'src/app/shared/services/common/current-theme.service';
import { ShipToBusinessAccountModalComponent } from '../ship-to-business-account-modal/ship-to-business-account-modal.component';
import { ShipToViewAddressModalComponent } from '../ship-to-view-address-modal/ship-to-view-address-modal.component';

@Component({
  selector: 'app-ship-to-add-new-address-modal',
  templateUrl: './ship-to-add-new-address-modal.component.html'
})
export class ShipToAddNewAddressModalComponent implements OnInit {
  currentTheme: any='';
  addAddressId:any;
  constructor(public dialog: MatDialog,private theme: CurrentThemeService, @Inject(MAT_DIALOG_DATA) public data:any,public dialogRef: MatDialogRef<ShipToAddNewAddressModalComponent>) { }

  ngOnInit(): void {
    this.currentTheme = this.theme?.getCurrentTheme();
    this.addAddressId=this.data?.value?.addAddressId? this.data.value.addAddressId: '';
  }

  closeDialog(){
    this.dialogRef.close();
    if(this.data?.value?.drawerName==='shipToViewAddress'){
    this.dialog.open(ShipToViewAddressModalComponent, {
      position: {
        right: '0px',
        top: '0px'
      },
      panelClass: ['wishlist-drawer', this.currentTheme],
      data: {
        value:{
          addAddressId:this.data?.value?.addAddressId? this.data.value.addAddressId: '',
        }
      }
    });
  }
else if(this.data?.value?.drawerName==='shipToBusinessAccount'){
  this.dialog.open(ShipToBusinessAccountModalComponent, {
    position: {
      right: '0px',
      top: '0px'
    },
    panelClass: ['wishlist-drawer', this.currentTheme],
    
  });
}
  }

}
