<div class="ship-to-business-account-container">
  <div class="ship-to-business">
    <div class="ship-to-business-header pb-3 d-flex flex-row justify-content-between align-items-center">
      <div class="d-flex flex-row align-items-center justify-content-center"
        [mat-dialog-close]="'backToShipToClientPatientDialogFromOtherAddress'">
        <div class="t-headerLink-normal">
          {{'account.otherShipToClientPatientAddresses.back' | cxTranslate }}
        </div>
      </div>
      <div class="mt-1">
        <button mat-flat-button color="primary" [mat-dialog-close]="true" class="sitelinks-close">
          <mat-icon> {{ "header.close" | cxTranslate }}</mat-icon>
        </button>
      </div>
    </div>
    <!--  to show the error/success  on address addtion on business account: start-->

    <div *ngIf="data?.value?.showFailureForCart" class="m-2">
      <reusable-alert-box [alertType]="'warningAlertBox'"
        alertMsg="{{ 'account.businessAccounts.infoUpdateError' | cxTranslate }}"></reusable-alert-box>
    </div>

    <div *ngIf="data?.value?.showSuccessForCart" class="m-2">
      <reusable-alert-box [alertType]="'successAlertBox'" alertMsg="{{
      'account.businessAccounts.infoUpdateSuccess' | cxTranslate
    }}"></reusable-alert-box>
    </div>


    <!--  to show the error/success on address addtion on client/patient account: start-->

    <!--  to show the error/success when address is selected for shipping: start-->
    <div *ngIf="shippingAddressSelectedFailure" class="m-2">
      <reusable-alert-box [alertType]="'warningAlertBox'"
        alertMsg="{{ 'account.businessAccounts.infoUpdateError' | cxTranslate }}"></reusable-alert-box>
    </div>

    <div *ngIf="shippingAddressSelectedSuccess" class="m-2">
      <reusable-alert-box [alertType]="'successAlertBox'" alertMsg="{{
        'account.businessAccounts.infoUpdateSuccess' | cxTranslate
      }}"></reusable-alert-box>
    </div>

    <div class="ship-to-business-add p-2 d-flex justify-content-center align-items-baseline"
    (click)="openAddShipToDialog(data?.value)" >
      <mat-icon svgIcon="addButton"></mat-icon>
      <p mat-flat-button  class="t-acctno-text-regular m-0" type="button">
        <u>{{ "account.cartShipToBusiness.addNewBillTo" | cxTranslate }} </u>
      </p>
    </div>

    <div *ngIf="viewAddresses && viewAddresses.length">
      <div class="p-2" *ngFor="let item of viewAddresses">
        <div class="ship-to-business-address p-2">
          <div class="ship-to-business-address-block">
            <p *ngIf="item.isPrimaryShippingAddress" class="outlined-badge-success t-address-type w-25 mb-2">
              {{ "account.businessAccounts.primaryShipTo" | cxTranslate }}
            </p>
            <p class="t-body-s m-0">
              {{
              item?.formattedAddress
              ? item.formattedAddress.replaceAll(",", "")
              : ""
              }}
            </p>
            <p class="t-body-s m-0 ship-to-business-address-end pb-2">
              <a class="phoneLink" href="tel:{{item?.phone }}" aria-label="Call On"> {{(item?.phone ? item.phone: '') | formatPhone}}</a>
            </p>
          </div>
          <div class="d-flex justify-content-between ship-to-business-address-action px-2 pt-2">
            <button mat-flat-button class="t-acctno-text-regular" color="primary" (click)="selectAddress(item)">
              <u>{{ "account.cartShipToBusiness.select" | cxTranslate }}</u>
            </button>
            <button mat-flat-button class="d-none t-acctno-text-regular" color="white">
              <u>{{ "account.cartShipToBusiness.edit" | cxTranslate }}</u>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Load add shipto new address dialog-->
</div>