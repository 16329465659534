import { Component } from '@angular/core';
import { ImageLoadingStrategy, MediaComponent, MediaService } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-media',
  templateUrl: './custom-media.component.html'
})
export class CustomMediaComponent extends MediaComponent {
  
  constructor(protected mediaService: MediaService) {
    super(mediaService);
  }
// TODO: Remove string return type (#14236)
  /**
   * Indicates whether the browser should lazy load the image.
   * @deprecated since 4.2. use ImageLoadingStrategy or null return types only
   */
   get loadingStrategy(): any {
    return this.mediaService.loadingStrategy === ImageLoadingStrategy.LAZY
      ? ImageLoadingStrategy.LAZY
      : null;
  }

}
