import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class PageLoaderService {
  isLoading:boolean=false;

  loader=new BehaviorSubject<boolean>(false);

  constructor() {}
  //commenting the loader changes as loader has been implemented globally 
  show() {
    this.isLoading=true;
  }

  hide() {
    this.isLoading=false;
  }

 
}
