import { Component, EventEmitter, Input, Output } from '@angular/core';

interface OrderPromotion {
  description: string;
  promotion: {
    code: string;
    description: string;
    promotionType: string;
  };
}

@Component({
  selector: 'app-order-promotion',
  templateUrl: './order-promotion.component.html',
})
export class OrderPromotionComponent {
  @Input() promotions?: OrderPromotion[] = [];
  @Input() eligiblePromoCount: number = 0;
  @Input() isApplied: boolean = false;
  @Input() freeFreight: any;

  // For handling promotion status change
  @Output() openOrderPromotionModal: EventEmitter<OrderPromotion> =
    new EventEmitter<OrderPromotion>();

  // Output event for opening order promotions slider
  handlePromotionSlider = (promo?: OrderPromotion) => {
    this.openOrderPromotionModal.emit(promo);
  };
}
