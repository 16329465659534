import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { BoxoutEndpointService } from './boxout-endpoint.service';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  currentUrl: any;
  private checkoutSelectAddrDataSource = new Subject<any>();
  constructor(private http: HttpClient, private router: Router, private customHttp: BoxoutEndpointService) {
    this.currentUrl = this.router.url;
  }
  
  getAddresses(apiURL:any,searchValue?:any){
    return this.customHttp.get(apiURL);
  }
  getClientViewAddresses(apiURL:any){
    return this.customHttp.get(apiURL);
  }
  setSelectShipAddressData(data: any) {
    this.checkoutSelectAddrDataSource.next(data);
  }
  getSelectShipAddressData(): Observable<any> {
    return this.checkoutSelectAddrDataSource.asObservable();
  }
}
