import { TranslationChunksConfig, TranslationResources } from '@spartacus/core';
import { en } from './en/index';

export const globalTranslations: TranslationResources = {
  en,
};

export const globalTranslationChunksConfig: TranslationChunksConfig = {
  global: ['global', 'header','brands','orderApproval','notifyStock','checkout','cart','quickOrder','product','account', 'bxdPortal', 'salesrep', 'bulkPodExport','medicalInfo']
};
