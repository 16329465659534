import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClientJsonpModule
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  BrowserModule
} from '@angular/platform-browser';
import { UrlSerializer } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AsmModule } from '@spartacus/asm';
import {
  ConfigModule,
  I18nConfig, provideConfig
} from '@spartacus/core';
import { AppRoutingModule } from '@spartacus/storefront';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { CookieService } from 'ngx-cookie-service';
import { AppInterceptor } from './app-interceptor';
import { AppComponent } from './app.component';
import { BoxoutRoutingModule } from './boxout-routing.module';
import { CoreModule } from './core/core.module';
import * as fromApp from './core/store/app.reducer';
import { CustomLayoutConfig } from './custom-layout.config';
import CustomUrlSerializer from './custom-serializer.service';
import { BoxoutMaterialModule } from './custom/boxout-material/boxout-material.module';
import { BoxoutServicesModule } from './custom/boxout-services/boxout-services.module';
import { SsrIntermittentComponent } from './feature/components/ssr-intermittent/ssr-intermittent.component';
import { formatCurrencyPipe } from './shared/pipes/format-currency';
import { formatPhonePipe } from './shared/pipes/format-phone';
import {
  globalTranslationChunksConfig,
  globalTranslations
} from './shared/translations/translations';
import { SpartacusStorefrontCustomModule } from './spartacus/spartacus-storefront-custom.module';
import { SpartacusModule } from './spartacus/spartacus.module';
import { RewardsCreditsModule } from './feature/pages/my-account/sub-pages/rewards-credits/rewards-credits.module';

//configurations added for loader

@NgModule({
  declarations: [AppComponent, SsrIntermittentComponent],
  imports: [
    //CommonModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    //TransferHttpCacheModule, // This module was causing a cache of api call responses , Hence removing this. As we are only rendering homepage from SSR in non logged it scenarios its not impacting the SSR
    // NgxPaginationModule,
    //LayoutModule,
    HttpClientJsonpModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    //FormsModule,
    //ReactiveFormsModule,
    StoreModule.forRoot(fromApp.appReducer),
    EffectsModule.forRoot([]),
    SpartacusModule,
    BoxoutRoutingModule,
    BoxoutMaterialModule,
    BoxoutServicesModule,
    //ReusableElementsModule,
    //BoxoutConfigurationsModule,
    //BoxoutComponentsModule,
    //NoopAnimationsModule,
    //I18nModule,
    //HttpClientJsonpModule,
    SpartacusStorefrontCustomModule,
    //FeatureModule,
    //SharedModule,
    //NgxSpinnerModule,
    //BrowserTransferStateModule,
    //BrowserAnimationsModule,
    AsmModule,
    NgHttpLoaderModule.forRoot(),
    //LoaderModule,
    ConfigModule.withConfig(CustomLayoutConfig),
    RewardsCreditsModule
    
  ],
  providers: [
    CookieService,
    formatPhonePipe,
    formatCurrencyPipe,
    { provide: Window, useValue: Window },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },{ provide: UrlSerializer, useClass: CustomUrlSerializer },
    provideConfig(<I18nConfig>{
      i18n: {
        resources: globalTranslations,
        chunks: globalTranslationChunksConfig,
      },
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
