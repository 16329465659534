import { Component, Input, OnInit } from '@angular/core';
import { SiteContextConfig } from '@spartacus/core';
import { Routes } from 'src/app/core/constants/breadcrumbs.constants';
import { global } from 'src/app/shared/translations/en/global';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {

  @Input() breadcrumbMapping: any;
  @Input() dynamicParam: string;
  @Input() dynamicRouteParam: string;
  @Input() workflowActionCode: string;
  @Input() page: string;
  breadcrumbs: any = [];
  baseSiteName: any;
  translations = global.account.breadCrumb;

  constructor(
    private config: SiteContextConfig
  ) {}

  ngOnInit(): void {
    this.baseSiteName = this.config?.context?.baseSite;
    this.checkBaseSite();
    if(this.page === 'PDP') {
      this.createPDPBreadCrumb();
    } else {
      this.createBreadCrumb();
    }
  }

  // check base site
  checkBaseSite() {
    switch (this.baseSiteName[0]) {
      case 'mmsite':
        this.baseSiteName = this.translations.mmsite
        break;
      case 'ptsite':
        this.baseSiteName = this.translations.ptsite
        break;
      case 'spasite':
        this.baseSiteName = this.translations.spasite
        break;
      case 'fwsite':
        this.baseSiteName = this.translations.fwsite
        break;
      case 'omsite':
        this.baseSiteName = this.translations.omsite
        break;
      case 'musite':
        this.baseSiteName = this.translations.musite
        break;
      case 'chsite':
        this.baseSiteName = this.translations.chsite
        break;
      case 'mesite':
        this.baseSiteName = this.translations.mesite
        break;
      case 'bxdsite':
        this.baseSiteName = this.translations.boxout
        break;
      default:
        this.baseSiteName = this.translations.boxout
        break;
    }
  }

  createPDPBreadCrumb() {
    this.breadcrumbs.push({
      label: this.baseSiteName.charAt(0).toUpperCase() + this.baseSiteName.slice(1),
      url: 'homepage'
    });
    if(this.breadcrumbMapping && this.breadcrumbMapping.length) {
      for(let item of this.breadcrumbMapping) {
        const crumb = {
          label: item.name,
          url: '/' + item?.url
        };
        this.breadcrumbs.push(crumb);
      }
      if(this.dynamicParam){
        this.breadcrumbs.push({
          label: this.dynamicParam,
          url: ''
        })
      }
    }
  }

  createBreadCrumb() {
    this.breadcrumbs.push({
      label: this.baseSiteName.charAt(0).toUpperCase() + this.baseSiteName.slice(1),
      url: 'homepage'
    });
    let arr = this.breadcrumbMapping.split(',');
    let crumb;
    for (let item of arr) {
      if (item === this.translations.advancedeBill) {
        crumb = {
          label: this.translations.advancedEbill,
          url: Routes[item]
        }
      } else if (item === this.translations.advancedeBillReport) {
        crumb = {
          label: this.translations.advancedEbillReport,
          url: Routes[item]
        }
      } else if (item === this.translations.userIdAndPreferences) {
        crumb = {
          label: this.translations.userIdPreferences,
          url: Routes[item]
        }
      } else if (item === this.translations.signInAndSecurity) {
        crumb = {
          label: this.translations.signInSecurity,
          url: Routes[item]
        }
      } else if (item === this.translations.ordersRequiringApproval) {
        crumb = {
          label: this.translations.ordersRequiringApprovalLabel,
          url: Routes[item] + `/${this.dynamicRouteParam}/${this.workflowActionCode}`
        }
      } else if (item === this.translations.orderDetail) {
        crumb = {
          label: this.translations.orderDetailLabel,
          url: Routes[item] + `/${this.dynamicRouteParam}`
        }
      } else if (item === this.translations.orders || item === this.translations.order || item === this.translations.tranHist) {
        crumb = {
          label: item,
          url: Routes[this.translations.orders]
        }
      } else if (item === this.translations.products || item === this.translations.product) {
        crumb = {
          label: item,
          url: Routes[this.translations.products]
        }
      } else if (item === this.translations.inventory) {
        crumb = {
          label: this.translations.inventory,
          url: Routes[item]
        }
      } else if (item === this.translations.receiving) {
        crumb = {
          label: this.translations.receiving,
          url: Routes[item]
        }
      } else if (item === this.translations.user || item === this.translations.settings) {
        crumb = {
          label: item,
          url: Routes[this.translations.user]
        }
      } else if (item === this.translations.reports) {
        crumb = {
          label: this.translations.reports,
          url: Routes[item]
        }
      } else if (item === this.translations.manufacturerSKU) {
        // this does not route to any link, its only to prevent spacing between upper case letters
        crumb = {
          label: this.translations.manufacturerSKU,
          url: Routes[item]
        }
      } else {
        const result = item.replace(/([A-Z])/g, " $1");
        crumb = {
          label: result.charAt(0).toUpperCase() + result.slice(1),
          url: Routes[item]
        } as any;
      }

      this.breadcrumbs.push(crumb);
    }
  }

  buildRoutingArray(crumb: any, isLast: any) {
    if (isLast) {
      return;
    } else {
      return '/' + crumb?.url;
    }
  }

}
