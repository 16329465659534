<!-- This Component displays Footer Custom Navigation Titles and corresponding link -->
<div class="d-none d-lg-block py-3 pl-4 custom-navigation-container">
  <!-- For Navigation Titles less than 4-->

  <div
    class="container d-flex flex-column flex-md-row flex-wrap justify-content-around"
    *ngIf="node?.children?.length < 4 || node?.children?.length === 4"
  >
    <div *ngFor="let child of node?.children">
      <h2 class="t-title-m navigation-title pb-2">
        {{ child.title }}
      </h2>
      <div *ngFor="let navLink of child?.children" class="pb-2">
        <cx-generic-link
          [url]="navLink.url"
          [target]="setLinkTarget(navLink)"
          class="t-title-light-m footer-nav-link"
        >
          {{ navLink.title }}
        </cx-generic-link>
      </div>
    </div>
  </div>

  <!-- For Navigation Titles more  than 4   class="container footer-nav-grid" -->

    <div class="container footer-nav-grid" *ngIf="node?.children?.length > 4">
    <div *ngFor="let child of node?.children" class="mb-3">
      <h2 class="t-title-m navigation-title pb-2">
        {{ child.title }}
      </h2>
      <div *ngFor="let navLink of child?.children" class="pb-2">
        <cx-generic-link
          [url]="navLink.url"
          [target]="setLinkTarget(navLink)"
          class="t-title-light-m footer-nav-link"
        >
          {{ navLink.title }}
        </cx-generic-link>
      </div>
    </div>
  </div>
</div>
