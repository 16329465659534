import { Component, OnInit } from '@angular/core';
import { PageLoaderService } from '../../pages/page-loader/page-loader.service';

@Component({
  selector: 'app-ssr-intermittent',
  templateUrl: './ssr-intermittent.component.html'
})
export class SsrIntermittentComponent {
  baseSiteName: string[] | undefined;
  baseSiteNameVal: string;

  constructor(private pageloaderService: PageLoaderService) { 
  }

}
