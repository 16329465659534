<div class="other-ship-to-client-patient-container">
  <app-loader></app-loader>
  <div class="other-ship-to-address-header-border d-flex flex-row justify-content-between align-items-center">
      <div class="d-flex flex-row align-items-center justify-content-center" 
      [mat-dialog-close]="'backToShipToClientPatientDialogFromOtherAddress'" (click)="backToShipToClientPatientDialogFromOtherAddress()">
        <div class="mr-1"><mat-icon svgIcon="back"></mat-icon></div>
      <div class="t-headerLink-normal" >
         {{'account.otherShipToClientPatientAddresses.back' | cxTranslate }}
      </div>
      </div>
      
      <div class="mt-1">
        <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon" color="white">close</mat-icon>
      </div>
  </div>

  <!--  to show the error/success  on address addtion on client/patient account: start-->
  <div *ngIf="data?.value?.showFailureForCart" class="m-2">
    <reusable-alert-box
      [alertType]="'warningAlertBox'"
      alertMsg="{{ 'account.businessAccounts.infoUpdateError' | cxTranslate }}"
    ></reusable-alert-box>
  </div>

  <div *ngIf="data?.value?.showSuccessForCart" class="m-2">
    <reusable-alert-box
      [alertType]="'successAlertBox'"
      alertMsg="{{
        'account.businessAccounts.infoUpdateSuccess' | cxTranslate
      }}"
    ></reusable-alert-box>
  </div>

  <!--  to show the error/success on address addtion on client/patient account: start-->

   <!--  to show the error/success when address is selected for shipping: start-->
 <div *ngIf="shippingAddressSelectedFailure" class="m-2">
  <reusable-alert-box
    [alertType]="'warningAlertBox'"
    alertMsg="{{ 'account.businessAccounts.infoUpdateError' | cxTranslate }}"
  ></reusable-alert-box>
</div>

<div *ngIf="shippingAddressSelectedSuccess" class="m-2">
  <reusable-alert-box
    [alertType]="'successAlertBox'"
    alertMsg="{{
      'account.businessAccounts.infoUpdateSuccess' | cxTranslate
    }}"
  ></reusable-alert-box>
</div>

<!--  to show the error/success when address is selected for shipping: End-->

  <div class="p-2">

    <div class="d-flex flex-column justify-content-between align-items-center">

        <div class="d-flex flex-row mb-3" [mat-dialog-close]="true" (click)="openAddShipToDialog(data?.value)">
          <div class="add-address-icon mr-1">+</div>
         <div>
           <a>
             <u> {{'account.otherShipToClientPatientAddresses.addNewShipToAdd' | cxTranslate }}</u>
           </a>
          </div>
        </div>
         
  
          <div class="t-headerLink-normal mb-3">  {{ data?.value?.itemData?.name ? (data.value.itemData.name | titlecase) : "" }} Addresses</div>
    </div>

    

    <div  *ngFor="let item of viewAddresses" class="other-ship-to-address-block p-2 d-flex flex-column mb-3">

      <div class="d-flex flex-row mb-1">
        <div *ngIf="item?.isPrimaryShippingAddress" class="outlined-badge-success t-address-type mr-2">
          {{ "account.businessAccounts.primaryShipTo" | cxTranslate }}
        </div>
        <div *ngIf="item?.addressType" class="outlined-badge-secondary-2 t-address-type">
          {{ item?.addressType ? item?.addressType : ("" | titlecase) }}
        </div>
      </div>


      <div class="t-body-s mb-1">  {{ showOtherClientAddress(item)}}</div>

        <div class="other-ship-to-address-block-margin mb-2"></div>

        <div class="d-flex flex-row justify-content-between other-ship-to-client-patient-button">
        
          <button
          mat-flat-button
          class="t-acctno-text-regular other-ship-to-client-patient-action"
          color="primary" (click)="selectClientPatientShipToAddressFromOtherAddresses(item)"
        >
          <u>{{'account.otherShipToClientPatientAddresses.select' | cxTranslate }}</u>
        </button>
        <button mat-flat-button class="d-none t-acctno-text-regular other-ship-to-client-patient-action" color="white">
          <u>{{'account.otherShipToClientPatientAddresses.edit' | cxTranslate }}</u>
        </button>
        </div>

    </div>
  </div>

</div>