<div
  class="header-search px-2 px-lg-0 d-flex align-items-center d-lg-block"
  #myButton
>
  <input
    type="text"
    class="header-searchbar t-title-light-m"
    placeholder="{{ 'header.search' | cxTranslate }}"
    aria-label="Search Bar"
    [(ngModel)]="searchText"
    (keydown.enter)="getSearchtextData()"
    autocomplete="off"
    [formControl]="queryField"
    id="autoSearch"
  />
  <span class="header-search-icon cursor-pointer" (click)="getSearchtextData()"> <mat-icon svgIcon="header-search"></mat-icon> </span>
</div>
<div class="search-backdrop" [ngClass]="{'asm-user-active': isAsmUserLoggedIn$ | async}" *ngIf="isOpen" (click)="closeModal()" [class.sticky-search-backdrop]="isSticky"></div>
<div
  class="header-searchbar-modal-main"
  *ngIf="isOpen"
  [class.header-searchbar-modal-main-logout]="!user"
  [ngClass]="{'header-searchbar-modal-main-logout':!user,
  'sticky-search-modal-main': isSticky}"
>
  <div class="header-searchbar-modal">
    <div class="side">
      <h2 class="t-title-m pl-0 text-color-primary">Top Keywords Searched</h2>
      <mat-nav-list>
        <a
          mat-list-item
          *ngFor="let link of searchResult"
          class="t-title-s text-color-primary"
          (mouseenter)="getrecomendations(link)"
          href="/search?text={{ link }}"
        >
          {{ link }}
          <img class="ml-3" src="../../../assets/arrow.svg" alt="" />
        </a>
      </mat-nav-list>
    </div>
    <div class="main" *ngIf="isProducShow">
      <h2 class="t-title-m pl-0 mb-3 text-color-primary">Product Recomendations</h2>
      <div
        *ngFor="let link of productRecomendations"
        class="recomendations-main d-flex align-items-center py-2"
      >
        <div class="my-1">
          <img class="product-img" src="{{imageUrl}}{{ link?.ImageUrl }}" alt="" />
        </div>
        <div class="my-1">
          <a href="{{ link.ProductPageUrl }}"
            ><p class="t-label-l mb-1 text-color-primary" [innerHTML]="link.Name"></p
          ></a>
          <p class="t-label-m mb-1 text-color-primary" [innerHTML]="link.brandName[0].Value"></p>
          <u class="d-flex text-decoration-none">
            <p class="t-label-s">SKU #</p>&nbsp;
            <p class="t-label-s text-underline" [innerHTML]="link.Sku"></p>
          </u>
        </div>
      </div>
    </div>
  </div>
</div>
