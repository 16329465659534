<div class="order-detail-total">
  <p *ngIf="returnStatus!=='RETURN'" class="t-productTile-orderDetailHeader p-2 order-detail-total-head">
    {{ "account.order.orderTotal" | cxTranslate }}
  </p>
  <p *ngIf="returnStatus==='RETURN'" class="t-productTile-orderDetailHeader p-2 order-detail-total-head">
    {{ 'cart.returnTotals' | cxTranslate }}
  </p>

  <div class="order-parameters d-flex justify-content-end p-3 mb-4">
    <div class="order-parameter-keys d-flex flex-column align-items-end">
      <p class="m-0">
        {{ "account.order.subTotal" | cxTranslate }}({{orderEntriesItems}} {{"account.order.items" | cxTranslate }})
      </p>
      <p class="m-0" *ngIf="orderDetails?.totalDiscounts?.value">
        {{ "account.order.promotionalSavings" | cxTranslate }}
      </p>
      <p class="m-0" *ngIf="orderDetails?.hazardousFee?.value">
        {{ "account.order.hazardousFee" | cxTranslate }}
      </p>
      <p class="m-0" *ngIf="orderDetails?.surcharge?.value">
        {{ "account.order.surcharge" | cxTranslate }}
      </p>
      <p class="m-0">
        <span *ngIf="isCurbside">{{ "account.order.estimated" | cxTranslate }}</span>
        {{ "account.order.shippingCap" | cxTranslate }}
      </p>
      <p class="m-0">
        <span *ngIf="isCurbside">{{ "account.order.estimated" | cxTranslate }}</span>
        {{ "account.order.taxes" | cxTranslate }}
      </p>
      <p class="m-0" *ngIf="orderDetails?.rewardsDiscount?.formattedValue">
        {{ "account.rewards.rebatesLabel" | cxTranslate }}
      </p>
      <p class="m-0 order"></p>
      <p *ngIf="returnStatus!=='RETURN'" class="m-0 t-productTile-orderDetailHeader">
        <span *ngIf="isCurbside">{{ "account.order.estimated" | cxTranslate }}</span>
        {{ "account.order.orderTotalFinal" | cxTranslate }}
      </p>
      <p *ngIf="returnStatus==='RETURN'" class="m-0 t-productTile-orderDetailHeader">
        <span *ngIf="isCurbside">{{ "account.order.estimated" | cxTranslate }}</span>
        {{ 'cart.returnTotal' | cxTranslate }}
      </p>
    </div>
    <div class="order-parameter-values d-flex flex-column align-items-end">
      <span class="pr-4" *ngIf="orderDetails?.subTotal?.formattedValue">
        <p class="m-0 font-weight-bold" [innerHTML]="orderDetails?.subTotal?.formattedValue | formatCurrency"></p>
      </span>
      <span class="pr-4" *ngIf="orderDetails?.totalDiscounts?.value">
        <p class="m-0 font-weight-bold rounded px-2 promotional-savings pt-1" [innerHTML]="'- '+(orderDetails?.totalDiscounts?.formattedValue | formatCurrency:'PROMO')"></p>
      </span>
      <span class="pr-4" *ngIf="orderDetails?.hazardousFee?.value">
        <p class="m-0 font-weight-bold" [innerHTML]="orderDetails?.hazardousFee?.formattedValue | formatCurrency"></p>
      </span>
      <span class="pr-4" *ngIf="orderDetails?.surcharge?.value">
        <p class="m-0 font-weight-bold" [innerHTML]="orderDetails?.surcharge?.formattedValue | formatCurrency"></p>
      </span>
      <span class="pr-4" *ngIf="orderDetails?.deliveryCost?.formattedValue">
        <p *ngIf="!isCurbside && !(orderDetails?.freeFreightPromotion && orderDetails?.freeFreightPromotion?.status ==='Applied')" class="m-0 font-weight-bold" [innerHTML]="orderDetails?.deliveryCost?.formattedValue | formatCurrency">
        </p>
        <p *ngIf="!isCurbside && (orderDetails?.freeFreightPromotion && orderDetails?.freeFreightPromotion?.status ==='Applied')" class="m-0 font-weight-bold"> {{ 'cart.freeFreight.freeText' | cxTranslate }}
        </p>
        <p *ngIf="isCurbside" class="m-0 font-weight-bold">{{ curbside }}</p>
      </span>

      <span class="pr-4" *ngIf="orderDetails?.totalTax?.formattedValue">
        <p class="m-0 font-weight-bold" [innerHTML]="orderDetails?.totalTax?.formattedValue | formatCurrency"></p>
      </span>
      <span class="pr-4 d-flex flex-row t-price-reward-tile" *ngIf="orderDetails?.rewardsDiscount?.formattedValue">
        (<p class="m-0 font-weight-bold" [innerHTML]="orderDetails?.rewardsDiscount?.formattedValue | formatCurrency"></p>)
      </span>
      <p class="m-0 order-detail-tax"></p>
      <span class="pr-4 totalPriceVal" *ngIf="orderDetails?.totalPrice?.formattedValue">
        <p class="m-0 font-weight-bold" [innerHTML]="orderDetails?.totalPrice?.formattedValue | formatCurrency">
        </p>
      </span>
    </div>
  </div>
</div>
