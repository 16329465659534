<div class="d-lg-none mobile-sitelinks">
  <div class="d-flex align-items-center mobile-sitelinks-main px-3 pt-3 pb-4">
      <div class="mr-auto t-title-l ml-1">{{'header.account' | cxTranslate}}</div>
      <div class="mr-1">
        <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon" >close</mat-icon>
          <!-- <button class="close" mat-button >X</button> -->
      </div>
  </div>
  <div class="mobile-sitelinks-action mb-3 px-3 mx-1">
      <div class="action-section signin d-flex align-items-center"  (click)="signInClick()">
          <span class="action-icon d-flex align-items-center justify-content-center">
              <mat-icon svgIcon="account"></mat-icon>
          </span>
          <span class="ml-2 t-label-l action-text" role="button" tabindex="0">{{'header.signIn' | cxTranslate}}</span>
      </div>
      <div class="action-section register d-flex align-items-center">
          <span class="action-icon d-flex align-items-center justify-content-center">
              <mat-icon svgIcon="register_account"></mat-icon>
          </span>
          <a *ngIf="baseSiteNameVal !== 'mmsite'" [routerLink]="['/register']" class="ml-2 t-label-l action-text">{{'header.registerAccount' | cxTranslate}}</a>
          <a *ngIf="baseSiteNameVal === 'mmsite'" [routerLink]="['/request-account']" class="ml-2 t-label-l action-text">{{'header.registerAccount' | cxTranslate}}</a>
      </div>
  </div>
  <div class="mobile-sitelinks-action link-section">
      <div class="header-sitelinks about-boxout d-flex align-items-center px-2">
          <div class="header-sitelinks-link mx-1 w-100">
              <cx-generic-link class="t-menucategory-normal d-flex justify-content-between w-100 align-items-center" url="/" target="_blank">
                  <span>{{'header.aboutboxout' | cxTranslate : {siteName: siteName} }}</span>
                  <mat-icon svgIcon="right_arrow"></mat-icon>
              </cx-generic-link>
              <!-- <a [href]="link?.url" [target]="link?.target"></a> -->
          </div>
      </div>
      <div *ngFor="let link of siteLinksArray" >
          <div *ngIf="link?.uid !== 'SignInLink' && link?.uid !== 'RegisterAccountLink' && link.uid !== 'SiteLinkUIFlexComponent'" class="header-sitelinks d-flex align-items-center px-2">
              <div class="header-sitelinks-link mx-1 w-100">
                  <cx-generic-link class="t-subcategory-normal d-flex justify-content-between w-100 align-items-center" [url]="link?.url" [target]="setLinkTarget(link)">
                      <!-- <span>{{ link?.name }}</span> -->
                      <a [href]="link?.url" [target]="link?.target">{{ link?.name }}</a>
                      <mat-icon svgIcon="right_arrow"></mat-icon>
                  </cx-generic-link>
                  <!-- <a [href]="link?.url" [target]="link?.target"></a> -->
              </div>
          </div>
      </div>
  </div>
</div>