import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { GenericLinkModule, PageSlotModule } from '@spartacus/storefront';
import { BoxoutMaterialModule } from 'src/app/custom/boxout-material/boxout-material.module';
import { CustomNavigationUiComponent } from './components/custom-navigation-ui/custom-navigation-ui.component';
import { FooterContactUsComponent } from './components/footer-contact-us/footer-contact-us.component';
import { FooterCustomNavigationComponent } from './components/footer-custom-navigation/footer-custom-navigation.component';
import { FooterDistributionSignUpComponent } from './components/footer-distribution-sign-up/footer-distribution-sign-up.component';
import { FooterLinksInfoComponent } from './components/footer-links-info/footer-links-info.component';
import { FooterPoweredByBoxoutComponent } from './components/footer-powered-by-boxout/footer-powered-by-boxout.component';
import { FooterComponent } from './footer.component';



@NgModule({
  declarations: [
    FooterComponent,
    FooterDistributionSignUpComponent,
    CustomNavigationUiComponent,
    FooterCustomNavigationComponent,
    FooterContactUsComponent,
    FooterPoweredByBoxoutComponent,
    FooterLinksInfoComponent
  ],
  imports: [
    CommonModule,
    GenericLinkModule,
    BoxoutMaterialModule,
    PageSlotModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        FooterNavigationComponent: {
          component: FooterCustomNavigationComponent,
        },
      },
    } as CmsConfig),
  ],
  exports:[FooterComponent]
})
export class FooterModule { }
