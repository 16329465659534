import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-order-medical-info',
  templateUrl: './order-medical-info.component.html'
})
export class OrderMedicalInfoComponent {

  @Input() medicalInfo:any;
  constructor() { }

}
