<!--This Component displays Footer Distribution Sign Up contents-->
<div class="d-none d-lg-block footer-dist-sign-up-container">
  <div
    class="container py-3 px-xl-5 px-0 d-flex flex-column justify-content-between flex-md-row"
  >
    <div class="t-title-m sign-up-email-distribution mt-3">
      <cx-page-slot position="FooterSignUP"></cx-page-slot>
    </div>
    <div class="footer-dist-sign-up-container-sign-up-email-input ml-1 mr-3">
      <mat-form-field appearance="outline">
        <mat-label class="t-body-m email-label">{{
          "global.footerSignUp.emailAddressLabel" | cxTranslate
        }}</mat-label>
        <input
          matInput
          placeholder="{{
            'global.footerSignUp.emailAddressLabel' | cxTranslate
          }}"
        />
      </mat-form-field>
    </div>
    <div class="t-label-l sign-up-submit-button mt-3 mr-2">
      <button mat-flat-button type="submit">
        {{ "global.footerSignUp.emailTitle" | cxTranslate }}
      </button>
    </div>

    <div class="t-label-m m-2 sign-up-terms-condition mt-3 d-flex flex-column align-items-start pt-1">
      <a [routerLink]="['/privacy']">{{ "global.footerSignUp.footerPrivacyPolicy" | cxTranslate }}</a>
      <a [routerLink]="['/terms-of-use']">{{ "global.footerSignUp.footerTermsCond" | cxTranslate }}</a>
    </div>
  </div>
</div>


<!-- CODE WHICH HAVE BEEN CONFIGURED IN BACKOFFICE FOR THIS COMPONENT 
 Keeping the commented code just for future reference PLEASE DO NOT DELETE THIS-->

<!-- <div class="d-none d-lg-block footer-dist-sign-up-container py-4">
  <div class="container px-xl-4 px-0 d-flex flex-column justify-content-between flex-md-row d-flex">
    <div class="t-title-m sign-up-email-distribution d-flex align-items-center" style="width: 40%">
      <p class="m-0">Save 10% Today*, when you sign up to receive Boxout Distribution Solutions emails.</p>
    </div>
    <div class="footer-dist-sign-up-container-sign-up-email-input ml-1 mr-3  d-flex align-items-center">
      <div class="email-signup-form-wrapper">
        <div class="email-signup-form">
          <form action="https://cl.s6.exct.net/DEManager.aspx" name="subscribeForm" method="post" id="subscribeForm" class="d-flex align-items-center">
            <input type="hidden" name="_clientID" value="6147126" /> <input type="hidden" name="_deExternalKey"
              value="WebsiteOptinWC" /> <input type="hidden" name="Company" value="70" /> <input type="hidden"
              name="Division" value="SPA" /> <input type="hidden" name="CustomerNumber" value="" /> <input type="hidden"
              name="_action" value="add" /> <input type="hidden" name="_returnXML" value="0" /> <input type="hidden"
              name="_successURL" value="https://www.meyerspa.com/etsuccess" /> <input type="hidden" name="_errorURL"
              value="https://www.meyerspa.com/eterror" /> <input type="text" class="emailaddress mr-3 p-2" id="emailaddress" aria-label="Enter your Email Address" style="width: 275px; height: 45px; border-radius: 3px;"
              placeholder="Email Address" name="EmailAddress"
              onfocus="if (placeholder == 'Email Address') {value=''}"
              onblur="if (value== '') {placeholder='Email Address'}" /> <span
              class="subscriptionError" style="display: none;">Please enter a valid email.</span>
            <div class="t-label-l sign-up-submit-button"><button mat-flat-button="" type="submit"
                class="mat-focus-indicator mat-flat-button mat-button-base subscribe wbc-spa-btn m-0"><span
                  class="mat-button-wrapper"> Sign Up For Email
                </span><span matripple="" class="mat-ripple mat-button-ripple"></span><span
                  class="mat-button-focus-overlay"></span></button></div>
          </form>
        </div>
        <script type="text/javascript">
          $(document).ready(function () {
            $(".subscribe").click(function (event) {
              event.preventDefault();
              var email = $("#emailaddress").val();
              if (email != "" && email != "Sign up for Meyer SPA emails" && null != email.match(/\S+@\S+\.\S+/)) {
                $(".subscriptionError").hide();
                $("#subscribeForm").submit();
              }
              else {
                $(".form_field_footer_err").hide();
                $(".subscriptionError").show();
              }
            });

          });
        </script>
      </div>
    </div>
    <div class="t-label-m m-2 sign-up-terms-condition d-flex flex-column align-items-start"><a
        href="/boxout/en/USD/privacy">Privacy Policy</a><a href="/boxout/en/USD/terms-of-use">Terms &amp; Conditions</a>
    </div>
  </div>
</div> -->

<!-- CODE WHICH HAVE BEEN CONFIGURED IN BACKOFFICE FOR THIS COMPONENT 
 Keeping the commented code just for future reference -->

