<div class="profile-header w-100 d-flex justify-content-end justify-content-lg-normal" *ngIf="user">
    <div class="header-rep mr-auto d-none d-lg-flex align-items-center" *ngIf="user?.accountManagerDetails">
        <img src="assets\rep.svg" class="pr-1" alt="{{ 'header.salesRepIcon' | cxTranslate}}">
        <span class="t-title-m pr-1 d-none d-xl-block">{{'header.yourRep' | cxTranslate}}</span>
	    <span class="t-title-light-m header-rep-name"><span>{{user.accountManagerDetails?.accManagerName | uppercase }}</span>&nbsp;<span *ngIf="user?.accountManagerDetails?.accManagerExt">{{user.accountManagerDetails?.accManagerExt}}</span></span>
    </div>
    <div id="user-details" class="d-none">
        <span>B2B Customer Name = {{user.firstName | uppercase }} </span>
        <span>B2B Unit Name = {{user.orgUnit?.name | uppercase }}</span>
    </div>
    <div class="header-user d-flex align-items-center">
        <div class="header-user-name d-flex" *ngIf="user?.name">
            <img src="assets\acc_profile.svg" alt="{{ 'header.accountProfile' | cxTranslate}}" class="pr-1">
            <span class="header-user-data t-title-m pr-2 d-none">{{user.firstName | uppercase }}</span> 
            <!-- d-md-block -->
        </div>

        <cx-generic-link class="t-title-light-m" (click)="logout()">{{'header.logout' | cxTranslate}}</cx-generic-link>

        <div class="vertical-line" *ngIf="user?.orgUnit?.name"></div>
        <div class="unit-name t-title-m d-none" *ngIf="user?.orgUnit?.name"> {{user.orgUnit?.name | uppercase }}</div>
        <!-- d-lg-block -->

        <div class="vertical-line d-none" *ngIf="user?.orgUnit?.siebelRowId"></div>
        <!-- d-lg-block -->
        <div class="header-user-account d-flex align-items-center" role="button" tabindex="0" *ngIf="user?.orgUnit?.siebelRowId" >
            <span class="account-label t-title-light-m">{{'header.acct' | cxTranslate}}</span>
            <span class="account-num t-title-m ml-1 mr-2 mr-lg-0">{{user.orgUnit?.siebelRowId}}</span>
            <span *ngIf="showB2BUnitArrow"><mat-icon svgIcon="down_arrow_withoutspace" class="pr-lg-0 pl-lg-2 pr-1 pl-1 ml-1 ml-lg-0 d-flex align-items-center"  (click)="openB2bUnitModal()"></mat-icon></span>
        </div>
    </div>
</div>








