import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { SiteContextConfig } from '@spartacus/core';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { Observable, of, Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import { GTMService } from 'src/app/shared/services/common/gtm.service';
import * as fromApp from '../../../../core/store/app.reducer';
import { HeaderService } from '../../../../shared/services/header.service';

@Component({
  selector: 'app-header-searchbar',
  templateUrl: './header-searchbar.component.html'
})
export class HeaderSearchbarComponent implements OnInit, OnDestroy {
  queryField: FormControl = new FormControl();
  @ViewChild('myButton', { static: false }) public myButtonRef: ElementRef;
  searchResult: any;
  isOpen = false;
  searchText=''
  productRecomendations: any;
  cacheSearched:any=[];
  user: User;
  userSub: Subscription;
  principles:any;
  isProducShow = true;
  searchApiUrl:any;
  productApiUrl:any;
  imageUrl : any;
  siteConfig: any;
  isAsmUserLoggedIn$: Observable<boolean> = of(false);
  @Input() isSticky:any
  constructor(public dialog: MatDialog, private eRef: ElementRef, private http: HttpClient,
    private ngxXml2jsonService: NgxXml2jsonService, private cdr: ChangeDetectorRef,
    private headerService:HeaderService,public breakpointObserver: BreakpointObserver,
    private route:Router,private config: SiteContextConfig, private store: Store<fromApp.AppState>,
    @Inject(DOCUMENT) private document: Document, private csAgentAuthService:CsAgentAuthService, private gtmService: GTMService) {
      this.siteConfig = this.config;
    }

  ngOnInit(): void {
    this.userSub = this.store.select('auth').subscribe(user => { this.user = user?.user! });
    this.imageUrl = this.siteConfig?.backend?.occ?.baseUrl;
    const currentSite = this.headerService.getCurrentSite();
    this.headerService.localPropertiesConfigData.subscribe((resp:any)=>{
      if(resp){
        const searchApi: any = 'spartacus.config.celebros.suggestion.url.'+ currentSite;
        const productApi: any = 'spartacus.config.celebros.principles.url.'+ currentSite;
        this.searchApiUrl = resp[searchApi];  // Removing JSON.parse as the API response is changed from string to JSON in 2211
        this.productApiUrl = resp[productApi];  // Removing JSON.parse as the API response is changed from string to JSON in 2211
      }
    })
    this.queryField.valueChanges.subscribe(
      result => {
        if (result.length >= 2) {
          this.isOpen = true;
          this.searchResult = [];
          this.productRecomendations = [];
          this.getSearchData(result);
          this.document.body.style.overflowY = 'hidden';
        }else{
          if(!result.length && this.isOpen){
            this.isOpen =  false;
            document.body.style.overflowY = 'auto';
          }

        }
      });
      // this.headerService.isLoggedIn.subscribe(response => {
      //   this.userLoggedIn = response;
      // });

      this.breakpointObserver.observe(['(max-width: 500px)']).subscribe((state: BreakpointState) => {
        this.isProducShow = state.matches ? false : true;
      });

      // For backdrop css
      this.isAsmUserLoggedIn$ = this.csAgentAuthService.isCustomerSupportAgentLoggedIn();
  }
  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (this.eRef.nativeElement.contains(event.target)) {
      //this.isOpen = true;
    } else {
      if(event.target.id !== 'autoSearch'){
        this.isOpen = false;
        this.queryField.reset("");
        this.searchResult = [];
        this.cacheSearched = [];
        this.productRecomendations = [];
      }
    }
  }
  getSearchData(result: any) {
    this.headerService.getAutoSearchData(result,this.searchApiUrl).subscribe(resp => {
      const jsonResp = this.xmlToJsonConverter(resp);
      this.formatJson(jsonResp,result);
    })
  }

  getSearchtextData(){
    if(this.searchText !== ''){
      this.gtmService.setSearchData(this.searchText);
      this.route.navigate(['/search'],{
        queryParams:{
          text:this.searchText
        }
      });
    }
   this.closeModal();
  }
  getrecomendations(result: any) {
    this.principles = this.user?.celebrosPreference ? this.user.celebrosPreference : 'NORESTRICTION';
    // if(this.user){
    //   this.userLoggedIn.subscribe((response:any) =>{
    //     this.principles = this.user.celebrosPreference;
    //   });
    // }else{
    //   this.principles = 'NORESTRICTION';
    // }
    this.productRecomendations = [];
    let isDataPresent = this.isDataPresent(this.cacheSearched,result);
    if(!isDataPresent){
    this.headerService.getProductRecommendations(result,this.principles,this.productApiUrl).subscribe((resp: any) => {
      this.productRecomendations = resp.DoSearchResult.Products.slice(0, 5);
      this.productRecomendations.forEach((element:any,index:any) => {
        let brandName = this.productRecomendations[index].AddtionalFields.filter((item:any)=> item.Name == 'BrandName');
        this.productRecomendations[index].brandName = brandName
      });
      this.cacheSearched.push({[result]:this.productRecomendations});
      this.cdr.detectChanges();
    })
  }else{
    this.productRecomendations =  this.getRecomendationsValue(this.cacheSearched,result);
    this.cdr.detectChanges();
  }
  }
  xmlToJsonConverter(xmlData: any) {
    const parser = new DOMParser();
    const xml = parser.parseFromString(xmlData.data, 'text/xml');
    const jsonObj = this.ngxXml2jsonService.xmlToJson(xml);
    return jsonObj;
  }
  formatJson(json: any,search:any) {
    let queryResponse :any= [];
    let queries:any = [];
    var data = json.AutoCompleteResultSet !== undefined ? json.AutoCompleteResultSet : null;

    if (data.SuggestionGroups !== undefined && data.SuggestionGroups !== null) {
      if (data.SuggestionGroups.ArrayOfSug !== undefined && data.SuggestionGroups.ArrayOfSug !== null && data.SuggestionGroups.ArrayOfSug !== "") {
        if (data.SuggestionGroups.ArrayOfSug[0] !== undefined && data.SuggestionGroups.ArrayOfSug[0] !== null) {
          queryResponse = data.SuggestionGroups.ArrayOfSug[0].sug;
        }
        if (data.SuggestionGroups.ArrayOfSug.sug !== undefined && data.SuggestionGroups.ArrayOfSug.sug !== null) {
          if (data.SuggestionGroups.ArrayOfSug.sug[0]["@xsi:type"] === "fqs") {
            queryResponse = data.SuggestionGroups.ArrayOfSug.sug;
          }
        }
      }
      var i;
      if (queryResponse !== undefined && queryResponse !== null && queryResponse.length) {
        for (i = 0; (i < queryResponse.length && i < 6); i++) {
          if (queryResponse[i] && queryResponse[i].dc) {
            queries.push(this.toProperCase(queryResponse[i].dc));
          } else {
            queries[i] = null;
          }
        }
      }
    }

    if (queries.length) {
      queries.shift();
      this.searchResult = queries;
      this.getrecomendations(this.searchResult[0]);
    } else {
      this.searchResult = [];
      this.getrecomendations(search);
    }
    this.cdr.detectChanges();
  }
  toProperCase(str: any) {
    return str.replace(/[\w&]\S*/g, function (txt: any) {
      if (txt.charAt(0) === "&" && txt.charAt(txt.length - 1) === ";") {
        return txt;
      }
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  isDataPresent(array:any, key:any) {
    return array.some((obj:any) => obj.hasOwnProperty(key));
  }
  getRecomendationsValue(array:any, key:any) {
    return array.find((obj:any) => obj[key])?.[key];
  }
  updateSearch(){
    this.productRecomendations.forEach((element:any,index:any) => {
      this.searchResult[index] = {topKeyword : this.searchResult[index],url:this.productRecomendations.ProductPageUrl}
    });
  }
  closeModal(){
    this.document.body.style.overflowY = 'auto';
    this.isOpen = false;
  }

  ngOnDestroy() {
    if(this.userSub) {
      this.userSub.unsubscribe();
    }
  }
}
