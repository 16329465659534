<div class="order-detail-total">
  <div class="t-productTile-orderDetailHeader order-detail-total-head p-2">
    {{"medicalInfo.medicalOrderDetails" | cxTranslate}}
  </div>
  <div class="p-3 mb-3">
    <!-- <p class="mt-2 mb-0">
        <span class="t-title-m">{{"medicalInfo.upim" | cxTranslate }}: </span> <span class="medical-info-upim">{{medicalInfo?.patientUid ? medicalInfo.patientUid : ""}}</span>
    </p> -->
    <p class="mb-0">
        <span class="t-title-m">{{"medicalInfo.npiHeader" | cxTranslate }}: </span> <span>{{medicalInfo?.npiInformation ? medicalInfo.npiInformation : ""}}</span>
    </p>

    <!-- DO NOT DELETE - Doctor's Address Section -->

    <!-- <p class="t-title-m-bold mb-0 mt-3">{{"medicalInfo.doctorsAddress" | cxTranslate }}:</p>

    <p class="mt-1 mb-0 t-title-m">
        {{medicalInfo?.doctorAddress?.line1 ? medicalInfo.doctorAddress.line1 : "" }}
    </p>
    
    <p class="m-0 t-title-m">
        {{medicalInfo?.doctorAddress?.line2 ? medicalInfo.doctorAddress.line2 : "" }}
    </p>
    <p class="mb-1 mt-0 t-title-m">
        {{ medicalInfo?.doctorAddress?.town ? medicalInfo.doctorAddress.town : "" }},
        {{(medicalInfo?.doctorAddress?.region?.isocode) ? ((medicalInfo.doctorAddress.region.isocode).replace("USA-","")+ " ") : ""}}
        {{
        medicalInfo?.doctorAddress?.postalCode ? medicalInfo.doctorAddress.postalCode :
        ""
        }}
        {{
        medicalInfo?.doctorAddress?.country?.isocode
        ? medicalInfo.doctorAddress.country.isocode
        : ""
        }}
    </p> -->

    <!-- DO NOT DELETE - Doctor's Address Section -->
</div>
</div>