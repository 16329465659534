

<div class="d-flex flex-column container recommended-address-dialog">
  <div class="d-flex flex-row justify-content-between mb-4">
    <div>
      <section class="t-title-l"> {{
        "global.recommendedAddress.title" | cxTranslate
      }}</section>
    </div>
    <div>
      <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon" (click)="closeREcommendedAddressPopUp()">close</mat-icon>

    </div>
  </div>

  <div  class="t-title-m address-error-text mb-4">
    {{
      "global.recommendedAddress.errorOne" | cxTranslate
    }}
  </div>


  <div *ngIf="submittedAddress" class="mb-4">
    <div class="address-status-block t-label-l mb-4 d-flex align-items-center justify-content-center"> {{
      "global.recommendedAddress.addressSubmitted" | cxTranslate
    }}</div>

    
    <div class="address-box-container d-flex align-items-center justify-content-center mb-3">   
      <div class="address-box p-2 t-title-l">
        {{ submittedAddress }}
      </div>    
    </div>      
    

    <div class="d-flex flex-row justify-content-center">
      <div>
        <button mat-flat-button color="primary" type="button" class="mr-2" (click)="editThisAddress()" mat-dialog-close
       [mat-dialog-close]="'editButtonClicked'"> {{
          "global.recommendedAddress.editAddress" | cxTranslate
        }}</button>
      </div>
      <div class="" [mat-dialog-close]="true"> 
        <button mat-flat-button color="white" type="button" (click)="useSubmittedAddress()" class="use-class-button"> {{
          "global.recommendedAddress.useAddress" | cxTranslate
        }}</button>
      </div>
    </div>
  </div>

  
  <div *ngIf="data.recommendedAddress.length > 0" class="mb-2">

    <div class="address-status-block d-flex align-items-center justify-content-center t-label-l mb-4"> {{
      "global.recommendedAddress.addressRecommended" | cxTranslate
    }}</div>
 
    <div *ngFor="let recomAdd of data.recommendedAddressList" class="mb-4">
      <div class="address-box-container d-flex flex-row d-flex align-items-center justify-content-center mb-3">
        <div class="address-box  t-title-l p-2">
          {{buildAddress(recomAdd)}}
        </div>
      </div>
        <div class="d-flex flex-row justify-content-center" [mat-dialog-close]="true">
          <button mat-flat-button color="white" type="button" (click)="useRecommendedAddress(recomAdd)"> {{
            "global.recommendedAddress.useAddress" | cxTranslate
          }}</button>
        </div>
      
    </div>
  </div>

  <div  class="t-title-m address-error-text address-error-border p-3">
    {{
      "global.recommendedAddress.errorTwo" | cxTranslate
    }}
  </div>
</div>
