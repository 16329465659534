<div class="ship-to-business-account-container">
  <app-loader></app-loader>
  <div class="ship-to-business pb-3 mb-4" *ngIf="!addNewBusiness">
    <div class="ship-to-business-header pb-3 d-flex flex-row justify-content-between align-items-center">
      <div class="t-headerLink-normal pt-2 d-flex align-items-center">{{ "account.cartShipToBusiness.selectBusiness" | cxTranslate }}</div>
      <div class="mt-1">
        <mat-icon (click)="close()" class="close-icon dialogCloseIcon mt-2 mr-2" color="white">close</mat-icon>
      </div>
    </div>

    <div class="ship-to-business-add p-2 d-flex justify-content-center align-items-baseline"
      (click)="addNewBusinessAccount()">
      <mat-icon svgIcon="addButton"></mat-icon>
      <a mat-flat-button class="t-acctno-text-regular" type="button">
        <u>{{
          "account.cartShipToBusiness.addnewBusiness"
          | cxTranslate
          }}
        </u></a>
    </div>

    <div class="ship-to-business-search d-flex position-relative m-2">
      <input type="text" class="t-label-l rounded inputBox createClientButton" placeholder="Search for Account, Address"
        id="filter_keywords" [(ngModel)]="searchParam" (keyup)="searchInput()" (keydown.enter)="searchInput()" />
      <mat-icon *ngIf="
          searchParam !== '' &&
          searchParam !== undefined &&
          searchParam !== null
        " (click)="clearSearch()" class="closeIcon" svgIcon="cancel-icon"></mat-icon>
      <mat-icon class="searchIcon" (click)="searchInput()" svgIcon="search_icon"></mat-icon>
    </div>
    <!--  to show the error/success  on address addtion on business account: start-->

    <div *ngIf="data?.value?.showFailureForCart" class="m-2">
      <reusable-alert-box [alertType]="'warningAlertBox'"
        alertMsg="{{ 'account.businessAccounts.infoUpdateError' | cxTranslate }}"></reusable-alert-box>
    </div>

    <div *ngIf="data?.value?.showSuccessForCart" class="m-2">
      <reusable-alert-box [alertType]="'successAlertBox'" alertMsg="{{
      'account.businessAccounts.infoUpdateSuccess' | cxTranslate
    }}"></reusable-alert-box>
    </div>

    <div *ngIf="apiErrorMessage" class="m-2">
      <reusable-alert-box [alertType]="'warningAlertBox'"
        alertMsg="{{ 'account.businessAccounts.infoUpdateError' | cxTranslate }}"></reusable-alert-box>
    </div>
    <!--  to show the error/success on address addtion on client/patient account: start-->
    <div class="p-2"
      *ngFor="let item of addresses | paginate:{itemsPerPage:totalRecordsOnPage,currentPage:page,totalItems:totalRecords };">
      <div class="ship-to-business-address  p-2">
        <div class="ship-to-business-address-block ">
          <p class="t-tableData-mobile-content m-0 pb-2">{{item?.name? item.name: ''}}</p>
          <p class="t-productTile-iconAlert m-0 pb-2" *ngIf="item.accountType.toUpperCase()==='PARENT'">{{
            "account.cartShipToBusiness.primary" | cxTranslate }} | {{item?.id? item.id:''}}</p>
          <p class="t-productTile-iconAlert m-0 pb-2" *ngIf="item.accountType.toUpperCase()==='DROPSHIP'">{{
            "account.cartShipToBusiness.subAccount" | cxTranslate }} | {{item?.id? item.id:''}}
          </p>
          <p class="t-body-s m-0" *ngIf="item?.shipTo?.attendant">{{
            "account.createAccount.attnTitle"
            | cxTranslate
            }} : {{item?.shipTo?.attendant? item.shipTo.attendant:''}} </p>
          <p class="t-body-s m-0">{{showBusinessAddress(item?.shipTo)}}</p>
          <p class="t-body-s m-0 ship-to-business-address-end pb-2"><a class="phoneLink" href="tel:{{item?.phone }}" aria-label="Call On"> {{(item?.phone ? item.phone: '') | formatPhone}}</a></p>
        </div>
        <div class="d-flex justify-content-between ship-to-business-address-action px-2 pt-2">
          <button mat-flat-button class="t-acctno-text-regular" color="primary" (click)="shipToSelectedAddress(item?.id ,item?.shipTo?.id)"><u>{{
              "account.cartShipToBusiness.shipToaddress" | cxTranslate }}</u></button>



          <div class="d-flex align-items-center" [mat-dialog-close]="true" (click)="addNewAddress(item)" *ngIf="item.addressCount===0 || !(item.addressCount)">
            <div class="ship-to-business-address-new d-flex justify-content-center align-items-center">
              +
            </div>
            <div>
              <a mat-flat-button class="t-acctno-text-regular" type="button" >
                <u> {{
                  "account.cartShipToBusiness.address"
                  | cxTranslate
                  }}</u>
              </a>
            </div>
          </div>

          <div class="d-flex align-items-center" *ngIf="item.addressCount && item.addressCount>0 && item.accountType==='PARENT'">
            <div class="ship-to-business-address-count d-flex justify-content-center align-items-center">
              {{item?.addressCount? item.addressCount :''}}
            </div>
            <div>
              <a mat-flat-button class="t-acctno-text-regular" type="button" (click)="getViewAddress(item)">
                <u> {{
                  "account.cartShipToBusiness.otherAddress"
                  | cxTranslate
                  }}</u>
              </a>
            </div>
          </div>


          <div class="d-flex align-items-center" *ngIf="item.addressCount && item.addressCount>0 && item.accountType!=='PARENT'">
            <div class="ship-to-business-address-count d-flex justify-content-center align-items-center">
              {{item?.addressCount? item.addressCount :''}}
            </div>
            <div>
              <a mat-flat-button class="t-acctno-text-regular" type="button" (click)="getViewAddress(item)">
                <u> {{
                  "account.cartShipToBusiness.otherAddress"
                  | cxTranslate
                  }}</u>
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="paginationPosition">
    <div *ngIf="totalRecords > 1" class="has-text-centered position-relative paginate">
      <p class="t-title-m d-none m-0 position-absolute d-md-block total">
        {{ totalRecords }} {{ "account.userAdmin.paginationRecords" | cxTranslate }}
      </p>
      <pagination-controls *ngIf="totalRecords>totalRecordsOnPage && addresses && addresses.length"
        (pageChange)="getAddressData($event)" previousLabel="" nextLabel=""></pagination-controls>
    </div>
  </div>
</div>