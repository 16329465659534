import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-distribution-sign-up',
  templateUrl: './footer-distribution-sign-up.component.html'
})
export class FooterDistributionSignUpComponent {


}
