import { OverlayModule } from '@angular/cdk/overlay';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NguCarousel, NguCarouselDefDirective, NguCarouselNextDirective, NguCarouselPrevDirective, NguItemComponent, NguTileComponent } from '@ngu/carousel';
import { I18nModule } from '@spartacus/core';
import { LazyLoadImageModule } from 'ng-lazyload-image'; // <-- include ScrollHooks
import { ReusableAlertBoxComponent } from './reusable-alert-box/reusable-alert-box.component';
//import { ReusableInputComponent } from './reusable-input/reusable-input.component';
//import { ReusableMaterialComponent } from './reusable-material/reusable-material.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReusableMenuComponent } from './reusable-menu/reusable-menu.component';
import { ReusableSlideToggleComponent } from './reusable-slide-toggle/reusable-slide-toggle.component';
import { ReusableWarningModalComponent } from './reusable-warning-modal/reusable-warning-modal.component';
const MaterialsModules = [
  MatChipsModule,
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatInputModule,
  MatRadioModule,
  MatStepperModule,
  MatDatepickerModule,
  MatIconModule,
  // MatDividerModule,
  MatSelectModule,
  MatNativeDateModule,
  MatMenuModule,
  //A11yModule,
  //ClipboardModule,
  //CdkStepperModule,
  CdkTableModule,
  //CdkTreeModule,
  // DragDropModule,
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  //MatChipsModule,
  //MatStepperModule,
  MatDatepickerModule,
  MatDialogModule,
  //MatDividerModule,
  MatExpansionModule,
  //MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatSlideToggleModule,
  //MatProgressBarModule,
  //MatProgressSpinnerModule,
  MatRadioModule,
  //MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  //MatSliderModule,
  //MatSlideToggleModule,
  //MatSnackBarModule,
  //MatSortModule,
  MatTableModule,
  MatTooltipModule,
  //MatTabsModule,
  //MatToolbarModule,
  //MatTooltipModule,
  //MatTreeModule,
  OverlayModule,
  //PortalModule,
  //ScrollingModule,
  ReactiveFormsModule,
  FormsModule,
  I18nModule,
  // IvyCarouselModule,
  NguCarousel,
  NguTileComponent,  
  NguCarouselDefDirective,
  NguCarouselNextDirective,
  NguCarouselPrevDirective,
  NguItemComponent,
  LazyLoadImageModule
];

@NgModule({
  declarations: [
    //ReusableMaterialComponent,
    ReusableSlideToggleComponent,
   // ReusableInputComponent,
    ReusableMenuComponent,
    ReusableAlertBoxComponent,
    ReusableWarningModalComponent,
  ],
  imports: [...MaterialsModules, CommonModule],
  exports: [
    ...MaterialsModules,
  //ReusableMaterialComponent, // These components are not used
  //ReusableInputComponent, // These components are not used
    ReusableMenuComponent,
    ReusableSlideToggleComponent,
    ReusableAlertBoxComponent,
    ReusableWarningModalComponent,
  ],
  providers: [{ provide: MatDialogRef, useValue: {} }],
})
export class BoxoutMaterialModule {
  private path: string = 'assets';
  constructor(
    private domSanitizer: DomSanitizer,
    public matIconRegistry: MatIconRegistry,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if(isPlatformBrowser(this.platformId)){
    this.matIconRegistry
      .addSvgIcon('signin', this.setPath(`${this.path}/signin-nav.svg`))
      //.addSvgIcon('minicart', this.setPath(`${this.path}/mini_cart.svg`))
      //.addSvgIcon('chat', this.setPath(`${this.path}/chat.svg`))
      //.addSvgIcon('down_arrow', this.setPath(`${this.path}/down_arrow.svg`))
      //.addSvgIcon('account', this.setPath(`${this.path}/account_circle.svg`))
      //.addSvgIcon('phone', this.setPath(`${this.path}/local_phone.svg`))
      .addSvgIcon(
        'register_account',
        this.setPath(`${this.path}/account_register.svg`)
      )
      .addSvgIcon('right_arrow', this.setPath(`${this.path}/right_arrow.svg`))
      //.addSvgIcon('hamburger', this.setPath(`${this.path}/hamburger_menu.svg`))
      .addSvgIcon('check_icon', this.setPath(`${this.path}/check-icon.svg`))
      .addSvgIcon('create_ship', this.setPath(`${this.path}/createShip.svg`))
      .addSvgIcon('ship_to', this.setPath(`${this.path}/shipTo.svg`))
      .addSvgIcon('manage_users', this.setPath(`${this.path}/manageUsers.svg`))
      .addSvgIcon('manage_acct', this.setPath(`${this.path}/manageAcct.svg`))
      .addSvgIcon('edit_address', this.setPath(`${this.path}/editaddress.svg`))
      .addSvgIcon('ship_to', this.setPath(`${this.path}/shipTo.svg`))
      .addSvgIcon('modal_close', this.setPath(`${this.path}/modal_close.svg`))
      .addSvgIcon('tooltip', this.setPath(`${this.path}/tooltip.svg`))
      .addSvgIcon('up_arrow', this.setPath(`${this.path}/upArrow.svg`))
      .addSvgIcon('search_icon', this.setPath(`${this.path}/searchicon.svg`))
      .addSvgIcon(
        'down_arrow_withoutspace',
        this.setPath(`${this.path}/down_arrow_withoutspace.svg`)
      )
      .addSvgIcon(
        'profile_outline',
        this.setPath(`${this.path}/profile_outline.svg`)
      )
      .addSvgIcon('cancel-icon', this.setPath(`${this.path}/cancel-icon.svg`))
      .addSvgIcon('drop-down', this.setPath(`${this.path}/dropdown.svg`))
      //.addSvgIcon('grid-view', this.setPath(`${this.path}/grid-view.svg`))
      //.addSvgIcon('list-view', this.setPath(`${this.path}/list-view.svg`))
      //.addSvgIcon('filter-facet', this.setPath(`${this.path}/filterRows.svg`))
      .addSvgIcon('drop-downUp', this.setPath(`${this.path}/dropdownUp.svg`))
      //.addSvgIcon(
      //   'right-arrow',
      //   this.setPath(`${this.path}/keyboardRightArrow.svg`)
      // )
      //.addSvgIcon('back-arrow', this.setPath(`${this.path}/left_arrow.svg`))
      .addSvgIcon('maestro', this.setPath(`${this.path}/cards/maestro.svg`))
      .addSvgIcon('master', this.setPath(`${this.path}/cards/master.svg`))
      .addSvgIcon('amex', this.setPath(`${this.path}/cards/amex.svg`))
      .addSvgIcon('discover', this.setPath(`${this.path}/cards/discover.svg`))
      .addSvgIcon('diners', this.setPath(`${this.path}/cards/diners.svg`))
      .addSvgIcon('visa', this.setPath(`${this.path}/cards/visa.svg`))
      //.addSvgIcon('new-arrival', this.setPath(`${this.path}/new-arrival.svg`))
      //.addSvgIcon('inStock', this.setPath(`${this.path}/inStock.svg`))
      //.addSvgIcon('backordered', this.setPath(`${this.path}/backordered.svg`))
      .addSvgIcon('discontinued', this.setPath(`${this.path}/discontinued.svg`))
      .addSvgIcon('specialorder', this.setPath(`${this.path}/specialorder.svg`))
      //.addSvgIcon('promo', this.setPath(`${this.path}/promo.svg`))
      .addSvgIcon('like', this.setPath(`${this.path}/like.svg`))
      .addSvgIcon('bell', this.setPath(`${this.path}/bell.svg`))
      //.addSvgIcon('minusIcon', this.setPath(`${this.path}/minusIcon.svg`))
      //.addSvgIcon('plus', this.setPath(`${this.path}/plus.svg`))
      .addSvgIcon('cart-text', this.setPath(`${this.path}/cart-text.svg`))
      .addSvgIcon('downArrow', this.setPath(`${this.path}/downArrow.svg`))
      .addSvgIcon('up-arrow', this.setPath(`${this.path}/up-arrow.svg`))
      //.addSvgIcon('backToTop', this.setPath(`${this.path}/backToTop.svg`))
      .addSvgIcon(
        'boxout-logo',
        this.setPath(`${this.path}/Boxout-Distribution-Solution-Logo.svg`)
      )
      .addSvgIcon('crossIcon', this.setPath(`${this.path}/Cross-Icon.svg`))
      .addSvgIcon(
        'green-tick-box',
        this.setPath(`${this.path}/greenTickBox.svg`)
      )
      .addSvgIcon('add-to-cart', this.setPath(`${this.path}/add-to-cart.svg`))
      .addSvgIcon('exclamation', this.setPath(`${this.path}/exclamation.svg`))
      .addSvgIcon('expand-table', this.setPath(`${this.path}/expandTable.svg`))
      .addSvgIcon('action-dots', this.setPath(`${this.path}/actionDots.svg`))
      .addSvgIcon(
        'collapse-table',
        this.setPath(`${this.path}/collapseTable.svg`)
      )
      .addSvgIcon(
        'modify-search',
        this.setPath(`${this.path}/modify-search.svg`)
      )
      .addSvgIcon(
        'favProductEdit',
        this.setPath(`${this.path}/favProductEdit.svg`)
      )
      .addSvgIcon('favRemove', this.setPath(`${this.path}/favRemove.svg`))
      .addSvgIcon('warningIcon', this.setPath(`${this.path}/warningIcon.svg`))
      .addSvgIcon('new', this.setPath(`${this.path}/new.svg`))
      //.addSvgIcon(
      //   'header-search',
      //   this.setPath(`${this.path}/HeaderSearch.svg`)
      // )
      .addSvgIcon('reorder', this.setPath(`${this.path}/reorder.svg`))
      .addSvgIcon('pod', this.setPath(`${this.path}/pod.svg`))
      .addSvgIcon(
        'order_details',
        this.setPath(`${this.path}/order_details.svg`)
      )
      //.addSvgIcon(
      //   'header-search',
      //   this.setPath(`${this.path}/HeaderSearch.svg`)
      // )
      .addSvgIcon('edit-order', this.setPath(`${this.path}/editOrder.svg`))
      .addSvgIcon(
        'proof-of-delivery',
        this.setPath(`${this.path}/proofOfDelivery.svg`)
      )
      .addSvgIcon('startReturn', this.setPath(`${this.path}/startReturn.svg`))
      .addSvgIcon('order-again', this.setPath(`${this.path}/order_again.svg`))
      .addSvgIcon('email', this.setPath(`${this.path}/email.svg`))
      .addSvgIcon('print', this.setPath(`${this.path}/print.svg`))
      .addSvgIcon('prodImage', this.setPath(`${this.path}/productImage.svg`))
      .addSvgIcon('promoOrder', this.setPath(`${this.path}/promoOrder.svg`))
      //.addSvgIcon('Close', this.setPath(`${this.path}/Close.svg`))
      .addSvgIcon('regError', this.setPath(`${this.path}/register_error.svg`))
      .addSvgIcon(
        'chevron_right',
        this.setPath(`${this.path}/chevron_right.svg`)
      )
      .addSvgIcon('chevron_down', this.setPath(`${this.path}/chevron_down.svg`))
      .addSvgIcon('palletized', this.setPath(`${this.path}/palletized.svg`))
      .addSvgIcon('parcel', this.setPath(`${this.path}/parcel.svg`))
      .addSvgIcon('container', this.setPath(`${this.path}/container.svg`))
      .addSvgIcon('done-step', this.setPath(`${this.path}/done-step.svg`))
      .addSvgIcon('chevron_down_white', this.setPath(`${this.path}/chevron_down_white.svg`))
      .addSvgIcon('collapse', this.setPath(`${this.path}/collapse.svg`))
      .addSvgIcon('expand', this.setPath(`${this.path}/expand.svg`))
      .addSvgIcon('filter', this.setPath(`${this.path}/filter.svg`))
      .addSvgIcon('export_selected', this.setPath(`${this.path}/export_selected.svg`))
      .addSvgIcon('calendar', this.setPath(`${this.path}/calendar.svg`))
      .addSvgIcon('approved', this.setPath(`${this.path}/approved.svg`))
      .addSvgIcon('awaiting', this.setPath(`${this.path}/awaiting.svg`))
      .addSvgIcon('completed', this.setPath(`${this.path}/completed.svg`))
      .addSvgIcon('pick-pack', this.setPath(`${this.path}/pick-pack.svg`))
      .addSvgIcon(
        'shipping_enroute',
        this.setPath(`${this.path}/shippingEnroute.svg`)
      )
      .addSvgIcon(
        'product-delivery',
        this.setPath(`${this.path}/product-delivery.svg`)
      )
      //.addSvgIcon('produc-fav', this.setPath(`${this.path}/produc-fav.svg`))
      .addSvgIcon('warranty', this.setPath(`${this.path}/Warranty.svg`))
      .addSvgIcon(
        'multipleVideo',
        this.setPath(`${this.path}/multipleVideo.svg`)
      )
      .addSvgIcon('videoImage', this.setPath(`${this.path}/videoImage.svg`))
      .addSvgIcon('warning65', this.setPath(`${this.path}/warning65.svg`))
      //.addSvgIcon(
      //   'carousel_enabled',
      //   this.setPath(`${this.path}/carousel_enabled.svg`)
      // )
      .addSvgIcon(
        'carousel_disabled',
        this.setPath(`${this.path}/carousel_disabled.svg`)
      )
      .addSvgIcon(
        'accordion-arrow',
        this.setPath(`${this.path}/accordion-arrow.svg`)
      )
      .addSvgIcon('cart', this.setPath(`${this.path}/cart.svg`))
      .addSvgIcon(
        'carouselPrev',
        this.setPath(`${this.path}/carousel-prev.svg`)
      )
      .addSvgIcon(
        'carouselNext',
        this.setPath(`${this.path}/carousel-next.svg`)
      )
      .addSvgIcon('videoPlay', this.setPath(`${this.path}/video-play.svg`))
      .addSvgIcon('download_btn', this.setPath(`${this.path}/download_btn.svg`))
      .addSvgIcon('download_btn_black', this.setPath(`${this.path}/download_btn_black.svg`))
      .addSvgIcon('factory', this.setPath(`${this.path}/factory.svg`))
      .addSvgIcon('note', this.setPath(`${this.path}/note.svg`))
      .addSvgIcon('cart-green', this.setPath(`${this.path}/cart-green.svg`))
      .addSvgIcon(
        'shipping-from',
        this.setPath(`${this.path}/shippingFrom.svg`)
      )
      .addSvgIcon('ques_mark', this.setPath(`${this.path}/ques_mark.svg`))
      .addSvgIcon('alert-free-freight', this.setPath(`${this.path}/alert-free-freight.svg`))
      .addSvgIcon('ship_to', this.setPath(`${this.path}/ship_to.svg`))
      .addSvgIcon('editShipTo', this.setPath(`${this.path}/editShipTo.svg`))
      .addSvgIcon('clearShipTo', this.setPath(`${this.path}/clearShipTo.svg`))
      .addSvgIcon('ups_logo', this.setPath(`${this.path}/ups_logo.svg`))
      .addSvgIcon('clear_cart', this.setPath(`${this.path}/clear_cart.svg`))
      .addSvgIcon('update_cart', this.setPath(`${this.path}/update_cart.svg`))
      .addSvgIcon('remove_item', this.setPath(`${this.path}/remove_item.svg`))
      .addSvgIcon(
        'client_patient_shipTo',
        this.setPath(`${this.path}/client_patient_shipTo.svg`)
      )
      .addSvgIcon(
        'business_shipTo',
        this.setPath(`${this.path}/business_shipTo.svg`)
      )
      .addSvgIcon('addButton', this.setPath(`${this.path}/addButton.svg`))
      .addSvgIcon('back', this.setPath(`${this.path}/back.svg`))
      .addSvgIcon(
        'orderStartReturn',
        this.setPath(`${this.path}/orderStartReturn.svg`)
      )
      .addSvgIcon('promotion', this.setPath(`${this.path}/promotion.svg`))
      .addSvgIcon(
        'order_promotion',
        this.setPath(`${this.path}/order_promotion.svg`)
      )
      .addSvgIcon(
        'applied_promo',
        this.setPath(`${this.path}/applied_promo.svg`)
      )
      .addSvgIcon('addShipTo', this.setPath(`${this.path}/addShipTo.svg`))
      .addSvgIcon('promo_green', this.setPath(`${this.path}/promo_green.svg`))
      .addSvgIcon('close_white', this.setPath(`${this.path}/close_white.svg`))
      .addSvgIcon('percent', this.setPath(`${this.path}/percent.svg`))
      .addSvgIcon('check_white', this.setPath(`${this.path}/check_white.svg`))
      .addSvgIcon('edit_white', this.setPath(`${this.path}/edit_white.svg`))
      .addSvgIcon('cancel', this.setPath(`${this.path}/cancel.svg`))
      .addSvgIcon('approve', this.setPath(`${this.path}/approve.svg`))
      .addSvgIcon('cart-edit', this.setPath(`${this.path}/cart-edit.svg`))
      .addSvgIcon('po', this.setPath(`${this.path}/po.svg`))
      .addSvgIcon(
        'miniCartBlack',
        this.setPath(`${this.path}/miniCartBlack.svg`)
      )
      .addSvgIcon('billShipAddr', this.setPath(`${this.path}/billShipAddr.svg`))
      .addSvgIcon(
        'paymentType',
        this.setPath(`${this.path}/my_account_image/payment_type.svg`)
      )
      .addSvgIcon('futureDate', this.setPath(`${this.path}/futureDate.svg`))
      .addSvgIcon(
        'signatureDelivery',
        this.setPath(`${this.path}/signatureDelivery.svg`)
      )
      .addSvgIcon('notifyClient', this.setPath(`${this.path}/notifyClient.svg`))
      .addSvgIcon(
        'onlinePay',
        this.setPath(`${this.path}/my_account_image/online_pay.svg`)
      )
      .addSvgIcon('applePay', this.setPath(`${this.path}/applePay.svg`))
      .addSvgIcon('payPal', this.setPath(`${this.path}/payPal.svg`))
      .addSvgIcon('affrim', this.setPath(`${this.path}/affrim.svg`))
      .addSvgIcon('orderSummary', this.setPath(`${this.path}/orderSummary.svg`))
      //.addSvgIcon("boxout-logo",this.setPath(`${this.path}/boxout-logo.svg`))
      .addSvgIcon('back-white', this.setPath(`${this.path}/back-white.svg`))
      .addSvgIcon(
        'deliveryOption',
        this.setPath(`${this.path}/deliveryOption.svg`)
      )
      .addSvgIcon(
        'profile_white',
        this.setPath(`${this.path}/profile_white.svg`)
      )
      .addSvgIcon('search_white', this.setPath(`${this.path}/search_white.svg`))
      .addSvgIcon('sr_home', this.setPath(`${this.path}/sr_home.svg`))
      .addSvgIcon('sr_org', this.setPath(`${this.path}/sr_org.svg`))
      .addSvgIcon('sr_training', this.setPath(`${this.path}/sr_training.svg`))
      .addSvgIcon('sr_promotion', this.setPath(`${this.path}/sr_promotion.svg`))
      .addSvgIcon('sr_help', this.setPath(`${this.path}/sr_help.svg`))
      .addSvgIcon('sr_logout', this.setPath(`${this.path}/sr_logout.svg`))
      .addSvgIcon('sr_profile_help', this.setPath(`${this.path}/sr_profile_help.svg`))
      .addSvgIcon('sr_profile_phone', this.setPath(`${this.path}/sr_profile_phone.svg`))
      .addSvgIcon('sr_profile_setting', this.setPath(`${this.path}/sr_profile_setting.svg`))
      .addSvgIcon('sr_profile_logout', this.setPath(`${this.path}/sr_profile_logout.svg`))
      .addSvgIcon('sr_profile_white', this.setPath(`${this.path}/sr_profile_white.svg`))
      .addSvgIcon('sr_bill', this.setPath(`${this.path}/sr_bill.svg`))
      .addSvgIcon('sr_primary_bill', this.setPath(`${this.path}/sr_primary_bill.svg`))
      .addSvgIcon('sr_primary_ship', this.setPath(`${this.path}/sr_primary_ship.svg`))
      .addSvgIcon('sr_license', this.setPath(`${this.path}/sr_license.svg`))
      .addSvgIcon('sr_toggle', this.setPath(`${this.path}/sr_toggle.svg`))
      .addSvgIcon('sr_warning', this.setPath(`${this.path}/sr_warning.svg`))
      .addSvgIcon('materialData', this.setPath(`${this.path}/materialData.svg`))
      .addSvgIcon('closeFacet', this.setPath(`${this.path}/close-facet.svg`))
      .addSvgIcon('medicalInfo', this.setPath(`${this.path}/medical_info.svg`))
      .addSvgIcon('bxd_orders', this.setPath(`${this.path}/bxd_orders.svg`))
      .addSvgIcon('bxd_products', this.setPath(`${this.path}/bxd_products.svg`))
      .addSvgIcon('bxd_inventory', this.setPath(`${this.path}/bxd_inventory.svg`))
      .addSvgIcon('bxd_receiving', this.setPath(`${this.path}/bxd_receiving.svg`))
      .addSvgIcon('bxd_returns', this.setPath(`${this.path}/bxd_returns.svg`))
      .addSvgIcon('bxd_reports', this.setPath(`${this.path}/bxd_reports.svg`))
      .addSvgIcon('bxd_help', this.setPath(`${this.path}/bxd_help.svg`))
      .addSvgIcon('bxd_settings', this.setPath(`${this.path}/bxd_settings.svg`))
      .addSvgIcon('bxd_user-settings', this.setPath(`${this.path}/bxd_user-settings.svg`))
      .addSvgIcon('bxd_switch-account', this.setPath(`${this.path}/bxd_switch-account.svg`))
      .addSvgIcon('bxd_collapse-arrow', this.setPath(`${this.path}/collapse_arrow.svg`))
      .addSvgIcon('bxd_prop_65', this.setPath(`${this.path}/bxd_prop_65.svg`))
      .addSvgIcon('bxd_prop_65_bg', this.setPath(`${this.path}/bxd_prop_65_bg.svg`))
      .addSvgIcon('bxd_hazardous', this.setPath(`${this.path}/bxd_hazardous.svg`))
      .addSvgIcon('bxd_rx_icon', this.setPath(`${this.path}/bxd_rx_icon.svg`))
      .addSvgIcon('bxd_dotPermit', this.setPath(`${this.path}/bxd_dotPermit.svg`))
      .addSvgIcon('bxd_lithiumBattery', this.setPath(`${this.path}/bxd_lithiumBattery.svg`))
      .addSvgIcon('bxd_ormd', this.setPath(`${this.path}/bxd_ormd.svg`))
      .addSvgIcon('bxd_return-order', this.setPath(`${this.path}/bxd_return-order.svg`))
      //.addSvgIcon('profileLogin', this.setPath(`${this.path}/profileIconlogin.svg`))

      .addSvgIcon('goBack', this.setPath(`${this.path}/go_back.svg`))
      .addSvgIcon(
        'order_credit',
        this.setPath(`${this.path}/order_credit.svg`)
      )
      .addSvgIcon('rewardsCreditUp', this.setPath(`${this.path}/rewards_credits_up.svg`))
      .addSvgIcon('rewardsCreditDown', this.setPath(`${this.path}/rewards_credits_down.svg`))
      .addSvgIcon('filterTop', this.setPath(`${this.path}/filterTop.svg`))
      .addSvgIcon('chipClose', this.setPath(`${this.path}/chipClose.svg`))
      .addSvgIcon('in-transit', this.setPath(`${this.path}/in-transit.svg`))
      .addSvgIcon('step_1', this.setPath(`${this.path}/step_1.svg`))
      .addSvgIcon('bxd_wroInfo', this.setPath(`${this.path}/bxd_wro_submit_info.svg`))
      .addSvgIcon('bxd_downloadLabel', this.setPath(`${this.path}/wro_download_label.svg`))
      .addSvgIcon('addWro', this.setPath(`${this.path}/addWro.svg`))
      .addSvgIcon('removeWRO', this.setPath(`${this.path}/removeWRO.svg`))
      .addSvgIcon('delete', this.setPath(`${this.path}/delete.svg`))
      .addSvgIcon('upload', this.setPath(`${this.path}/drag-upload.svg`))
      .addSvgIcon('pentagonCrossIcon', this.setPath(`${this.path}/pentagonCrossIcon.svg`))
      .addSvgIcon('gls', this.setPath(`${this.path}/bxd_gls_logo.svg`))
      .addSvgIcon('ontrac', this.setPath(`${this.path}/bxd_ontrac_logo.svg`))
      .addSvgIcon('ups', this.setPath(`${this.path}/bxd_ups_logo.svg`))
      .addSvgIcon('fedex', this.setPath(`${this.path}/bxd_fedex_logo.svg`))
      .addSvgIcon('usps', this.setPath(`${this.path}/bxd_usps_logo.svg`))
      .addSvgIcon('cancelled', this.setPath(`${this.path}/bxd_cancelled_logo.svg`))
      .addSvgIcon('bxd_backordered', this.setPath(`${this.path}/bxd_backordered_logo.svg`))
      .addSvgIcon('releasedToWarehouse', this.setPath(`${this.path}/bxd_releasedToWarehouse_logo.svg`))
      .addSvgIcon('awaiting_ship', this.setPath(`${this.path}/bxd_awaiting_ship_logo.svg`))
      .addSvgIcon('processing', this.setPath(`${this.path}/bxd_processing_logo.svg`))
      .addSvgIcon('bxd_open', this.setPath(`${this.path}/bxd_open_logo.svg`));

      matIconRegistry.addSvgIconSet(this.setPath(`${this.path}/sprite.svg`));
    }
  }
  private setPath(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
}
}
