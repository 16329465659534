import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AsmComponentService } from '@spartacus/asm/components';
import { AsmAuthService } from '@spartacus/asm/root';
import { WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { UserState } from 'src/app/core/store/reducers/auth.reducer';
import { HeaderService } from '../../services/header.service';
import * as AuthActions from 'src/app/core/store/actions/auth.actions';

@Component({
  selector: 'app-asm-header',
  templateUrl: './asm-header.component.html',
})
export class AsmHeaderComponent implements OnInit, OnDestroy {
  user: User;
  subscription: Subscription[] = [];

  constructor(
    private asmAuthService: AsmAuthService,
    private router: Router,
    private store: Store<fromApp.AppState>,
    private windowRef: WindowRef,
    private asmComponentService: AsmComponentService,
    private headerService: HeaderService,
  ) {}

  ngOnInit(): void {
    this.subscription.push(
      this.store.select('auth').subscribe((user: UserState) => {
        this.user = user?.user || {};
      })
    );
  }

  // Logout user which is logged in by ASM/Sales-rep user
  logoutUser = () => {
    // For removing b2bUnit for impersonated user from localStorage
    this.windowRef?.localStorage?.removeItem('b2bUnit');
    this.headerService?.setAddCardCount(null);
    this.windowRef.sessionStorage?.removeItem('cartId');
    this.windowRef.sessionStorage?.removeItem('viewBy');
    this.windowRef.localStorage?.removeItem('multipleB2bUnitFlag');
    this.windowRef.sessionStorage?.removeItem('promotionsMap');

    // For removing uid and email for impersonated user from localStorage
    this.windowRef?.sessionStorage?.removeItem('impersonatedUserId');
    this.windowRef?.sessionStorage?.removeItem('impersonatedUserEmail');

    // For logout impersonated user
    this.asmComponentService.logoutCustomer();
    // For removing 'ASM_ENABLED_LOCAL_STORAGE_KEY' from localStorage
    this.asmComponentService.unload();
    // For checking impersonated user login status
    this.subscription.push(
      this.asmAuthService.isUserLoggedIn().subscribe((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          // For redirecting to salesrep search page with previous search params
          const queryParam = JSON.parse(
            this.windowRef?.localStorage?.getItem('previousAsmRouteQuery') || ''
          )?.split('=')[1];
          this.router.navigate(['/sales-rep/account-search'], {
            queryParams: { searchFor: decodeURIComponent(queryParam) },
          });
        }
      })
    );
    this.store.dispatch(AuthActions.logout());
  };

  ngOnDestroy(): void {
    if (this.subscription?.length) {
      this.subscription.forEach((sub: Subscription) => sub.unsubscribe());
    }
  }
}
