<!-- <div
  class="search-results"
  infiniteScroll
  [infiniteScrollDistance]="1"
  [infiniteScrollUpDistance]="3"
  [infiniteScrollThrottle]="2000"
  (scrolled)="onScroll()"
> -->

<div *ngIf="totalCount > 1" class="has-text-centered position-relative paginate mb-4">
    <p class="t-title-m d-none m-0 position-absolute d-md-block total">
      {{ totalCount }} Records
    </p>
    <pagination-controls 
    *ngIf="totalCount>totalRecordsOnPage"
    (pageChange)="getNewPageData($event)"
    previousLabel=""
    nextLabel=""
    ></pagination-controls>
  </div>

<div class="row-view mb-4" *ngFor="let item of products | paginate
: { itemsPerPage: totalRecordsOnPage, currentPage: page+1, totalItems: totalCount }; let i=index">
    <div class="row-view-section p-3">
        <div class="row w-100 justify-content-between m-0">
            <div>
                <mat-icon 
                    svgIcon="new-arrival" 
                    class="row-view-new-arrival t-productTile-iconAlert d-none">
                </mat-icon>
            </div>
            <!-- Needed for future release -->
            <!-- <div>
                <mat-checkbox class="p-0 t-productTile-productGroup">
                    {{ 'account.productTile.compare' | cxTranslate }}
                </mat-checkbox>
            </div> -->
        </div>
        <div class="d-flex flex-row">
            <div class="row-view-image">
                <img *ngIf="item?.images && item?.images.length > 0 && item?.images[0].url !== ''" 
                      class="cursor-pointer"  defaultImage="../../../../../assets/MissingImage.svg" lazyLoad="{{imageUrl}}{{item?.images[0]?.url}}" alt="mainImage" (click)="redirectPDP(item)">
                <img *ngIf="item?.images === undefined || item?.images[0].url === '' || !item?.images "  class="cursor-pointer"
                     defaultImage="../../../../../assets/MissingImage.svg"   lazyLoad="../../../../../assets/MissingImage.svg" alt="MissingImage" (click)="redirectPDP(item)">
                <a 
                    mat-flat-button 
                    class="product-tile-hover-viewDetails-button" 
                    type="button"
                    (click)="redirectPDP(item)">
                    {{ 'account.productTile.viewHoverDetail' | cxTranslate }}
                </a>
            </div>
            <div class="row-view-line"></div>
            <div class="flex-column row-view-flex-margin">
                <section class="t-productTile-description m-0 cursor-pointer"
                [innerHTML]="item?.name" (click)="redirectPDP(item)">
                </section>
                <h3 class="t-productTile-brand mb-1 row-view-brand" [innerHTML]="item?.brandData?.name">
                </h3>   
                <p class="t-productTile-productGroup mb-1 d-none">
                    {{ 'account.productTile.item' | cxTranslate }}{{item?.code}}
                </p>
                <p class="t-productTile-productGroup mb-2 row-view-globalInventory" *ngIf="item?.stock?.stockLevelMap && user?.isViewInventoryGroup">
                    {{ 'account.productTile.inv' | cxTranslate }}
                    <span *ngFor="let stockLevel of item?.stock?.stockLevelMap">
                        <span> {{ stockLevel?.key | formatWarehoseData }} [{{ stockLevel?.value }}]</span>
                    </span>
                </p>
                <hr>
                <p class="t-productTile-teaser" [innerHTML]="item?.summary">
                </p>
                <hr class="mb-1" *ngIf="item?.summary">
            </div>
            <div  class="flex-column row-view-right-section">
                <div *ngIf="!user">
                    <p class="t-productTile-description cursor-pointer" (click)="onLoginClick()">Login To View Price</p>
                    <p class="t-productTile-teaser">please login to access your price</p>
                </div>
                <div class="row-view-price-margin" id="{{item?.code}}"  *ngIf="user">
                    <span class="product-tile-sigleVariantPrice">
                        <p class="t-productTile-description">{{ 'account.productTile.calculating' | cxTranslate }}</p>
                        <p class="t-productTile-priceTeaser">{{ 'account.productTile.proffesionalPrice' | cxTranslate }}</p>
                    </span>

                    <div class="d-none mt-2 strike-through-price" *ngIf="user && baseSiteNameVal !== 'mmsite'">
                    </div>
                    <!-- <sup class="t-price-purchaseUomTile product-tile-figure"  *ngIf="user"> / {{item?.salesUnit | lowercase}}</sup> -->
                </div>
                <div *ngIf="!user" class="d-none">
                    <div class="row-view-inStock d-flex" *ngIf="'inStock' === item?.stock?.stockLevelStatus">
                        <mat-icon svgIcon="inStock" class="product-tile-inStock-icon mr-2" aria-label="In Stock"></mat-icon>
                        <span class="t-productTile-availability-Instock row-view-inStock-text mr-1">
                            {{ 'account.productTile.inStock' | cxTranslate }}
                        </span>
                        <span class="t-productTile-availability-Instock-description mr-1">
                            {{ 'account.productTile.readyToShip' | cxTranslate }}
                        </span>
                    </div>
                    <div class="product-tile-backOrdered d-flex" *ngIf="'outOfStock' === item?.stock?.stockLevelStatus">
                        <mat-icon svgIcon="backordered" class="product-tile-backOrdered-icon mr-2" aria-label="Back Ordered"></mat-icon>
                        <span class="t-productTile-availability-Instock product-tile-backOrdered-text mr-1">
                            {{ 'account.productTile.outOfStock' | cxTranslate }}
                        </span>
                        <span class="t-productTile-availability-Instock-description mr-1" *ngIf="item?.outOfStockETA">
                            {{ 'account.productTile.pipe' | cxTranslate }}{{ 'account.productTile.eta' | cxTranslate }}{{item?.outOfStockETA}}
                        </span>
                    </div>
                </div>
                <div *ngIf="user">
                    <div class="row-view-inStock d-flex" *ngIf="'inStock' === item?.stock?.stockLevelStatus && !item?.isBackOrder && !item?.isDiscontinued && !item?.vendorDropShipSpecialOrder && item?.sellable === 'sellable'">
                        <mat-icon svgIcon="inStock" class="product-tile-inStock-icon mr-2" aria-label="In Stock"></mat-icon>
                        <span class="t-productTile-availability-Instock row-view-inStock-text mr-1">
                            {{ 'account.productTile.inStock' | cxTranslate }}
                        </span>
                        <span class="t-productTile-availability-Instock-description mr-1">
                            {{ 'account.productTile.readyToShip' | cxTranslate }}
                        </span>
                    </div>
                    <div class="product-tile-backOrdered d-flex" *ngIf="'outOfStock' === item?.stock?.stockLevelStatus && !item?.isBackOrder && !item?.isDiscontinued && !item?.vendorDropShipSpecialOrder && item?.sellable === 'sellable'">
                        <mat-icon svgIcon="backordered" class="product-tile-backOrdered-icon mr-2" aria-label="Back Ordered"></mat-icon>
                        <span class="t-productTile-availability-Instock product-tile-backOrdered-text mr-1">
                            {{ 'account.productTile.outOfStock' | cxTranslate }}
                        </span>
                        <span class="t-productTile-availability-Instock-description mr-1" *ngIf="item?.outOfStockETA">
                            {{ 'account.productTile.pipe' | cxTranslate }}{{ 'account.productTile.eta' | cxTranslate }}{{item?.outOfStockETA}}
                        </span>
                    </div>
                    <div class="product-tile-backOrdered d-flex" *ngIf="item?.isBackOrder && !item?.isDiscontinued && !item?.vendorDropShipSpecialOrder && item?.sellable === 'sellable'">
                        <mat-icon svgIcon="backordered" class="product-tile-backOrdered-icon mr-2" aria-label="Back Ordered"></mat-icon>
                        <span class="t-productTile-availability-Instock product-tile-backOrdered-text mr-1">
                            {{ 'account.productTile.backOrdered' | cxTranslate }}
                        </span>
                        <span class="t-productTile-availability-Instock-description mr-1" *ngIf="item?.mfgBackorderETA">
                            {{ 'account.productTile.pipe' | cxTranslate }}{{ 'account.productTile.eta' | cxTranslate }}{{item?.mfgBackorderETA}}
                        </span>
                    </div>
                    <div class="product-tile-discontinued d-flex" *ngIf="item?.isDiscontinued">
                        <mat-icon svgIcon="discontinued" class="product-tile-discontinued-icon mr-2" aria-label="Discontinued"></mat-icon>
                        <span class="t-productTile-availability-Instock product-tile-discontinued-text mr-1">
                            {{ 'account.productTile.discontinued' | cxTranslate }}
                        </span>
                    </div>
                    <div class="product-tile-specialorder d-flex" *ngIf="(item?.vendorDropShipSpecialOrder || item?.sellable === 'exempt') && !item?.isDiscontinued">
                        <mat-icon svgIcon="specialorder" class="product-tile-specialorder-icon mr-2" aria-label="Special Order"></mat-icon>
                        <span class="t-productTile-availability-Instock product-tile-specialorder-text mr-1">
                            {{ 'account.productTile.specialOrder' | cxTranslate }}
                        </span>
                    </div>
                </div>
                <div *ngIf="showInput !== i && (!item?.variantSiebelRowIds || item?.variantSiebelRowIds.length === 1) && item?.variantOptions?.length">
                    <div class="text-center mx-auto product-tile-multiple-order" *ngIf="item?.quantityMultiplier && item?.quantityMultiplier !== 1 && multipleOrder[item?.variantOptions?.length && item.variantOptions[0].code] && !maxQuantity[item?.variantOptions?.length && item.variantOptions[0].code] &&
                    (('inStock' === item?.stock?.stockLevelStatus || 'outOfStock' === item?.stock?.stockLevelStatus || item?.vendorDropShipSpecialOrder) && !item?.isBackOrder && !item?.isDiscontinued && item?.sellable === 'sellable')  && (pageLoadFlag || (!pageLoadFlag))">
                        <p class="t-productTile-boldPrice">{{ 'account.productTile.orderInMultiples' | cxTranslate }}{{item?.quantityMultiplier}}</p>
                    </div>
                    <div class="text-center mx-auto product-tile-quantity-error mt-3" *ngIf="quantityError[item?.variantOptions?.length && item.variantOptions[0].code]">
                        <p class="t-productTile-boldPrice">{{ 'account.productTile.quantityError' | cxTranslate }}</p>
                    </div>
                    <div class="text-center mx-auto product-tile-quantity-error mt-3" *ngIf="multipleOrderError[item?.variantOptions?.length && item.variantOptions[0].code]">
                        <p class="t-productTile-boldPrice">{{ 'account.productTile.multipleError' | cxTranslate }}{{item?.quantityMultiplier}}</p>
                    </div>
                    <div class="text-center mx-auto product-tile-max-quantity" *ngIf="maxQuantity[item?.variantOptions?.length && item.variantOptions[0].code]">
                        <p class="t-productTile-boldPrice">
                            {{ 'account.productTile.maxQuantity' | cxTranslate }}
                        </p>
                    </div>
                    <div class="quantity d-flex" 
                        *ngIf="item?.variantOptions && item?.variantOptions?.length && (('inStock' === item?.stock?.stockLevelStatus || 'outOfStock' === item?.stock?.stockLevelStatus || item?.vendorDropShipSpecialOrder) && !item?.isBackOrder  && !item?.isDiscontinued && item?.sellable === 'sellable')">
                        <button (click)="decrement(item?.variantOptions[0]?.code,i)" class="quantity__minus" role="button" aria-label="minus">
                            <mat-icon svgIcon="minusIcon" class="product-tile-minusIcon-icon"></mat-icon>
                        </button>
                        <input type="number" onKeyPress="if(this.value.length >= 4 || this.value < 0) return false;" name="counter" class="quantity__input mx-1" [(ngModel)]="quantityInput[item?.variantOptions[0]?.code]"
                        (paste)="onPaste($event)" (keyup)="salesUnitApiSingleVariant(item)" aria-label="quantity">
                        <button (click)="increment(item?.variantOptions[0]?.code,i)" class="quantity__plus" aria-label="plus">
                            <mat-icon svgIcon="plus" class="product-tile-plus-icon"></mat-icon>
                        </button>
                        <button 
                        mat-flat-button 
                        class="row-view-addToCart-button ml-1" 
                        color="primary"
                        (click)="addToCart(item?.variantOptions?.length && item.variantOptions[0].code,i)"
                        role="button"
                        [disabled]="cartDisable || checkDisable(item)"
                        >
                        + Cart
                    </button>
                    </div>
                </div>
                <div class="btn-group product-tile-viewDetails" *ngIf="showInput !== item?.code && item?.variantSiebelRowIds && item?.variantSiebelRowIds.length > 1">
                    <button type="button" class="btn row-view-show-option-button t-label-l" (click)="showOption(item?.code)">Show {{item?.variantSiebelRowIds?.length}} Options</button>
                    <button tabindex="-1" type="button" class="btn row-view-down-arrow p-0" aria-label="Account Down Arrow">
                        <mat-icon svgIcon="downArrow" class="row-view-downArrow-icon" aria-label="Account Down Arrow"></mat-icon>
                    </button>
                </div>
                <div class="btn-group product-tile-viewDetails" (click) ="closeOption()" role="button" *ngIf="showInput === item.code && showOptions" >
                    <button type="button" class="btn row-view-close-option-button t-label-l">
                        Close Options
                    </button>
                    <button type="button" class="btn row-view-up-arrow p-0" aria-label="Account Up Arrow">
                        <mat-icon svgIcon="up-arrow" class="row-view-up-arrow-icon" aria-label="Account Up Arrow"></mat-icon>
                    </button>
                </div>
                <ng-container *ngIf="isNotifyFlag">
                    <div class="product-tile-keepmeUpdated"
                        *ngIf="(item?.isBackOrder || 'outOfStock' === item?.stock?.stockLevelStatus) && !item?.isDiscontinued  && item?.sellable === 'sellable' && !item?.vendorDropShipSpecialOrder && user && item?.variantsSize === 1">
                        <button mat-flat-button class="row-view-keepmeUpdated-button w-100" type="button" (click)="keepMeUpdated(item)">
                            <mat-icon svgIcon="bell" class="product-tile-bell-icon" aria-label="Bell"></mat-icon>
                            {{ 'account.productTile.keepMeUpdated' | cxTranslate }}
                        </button>
                    </div>
                </ng-container>
                <div class="product-tile-keepmeUpdated" *ngIf="user && (item?.isDiscontinued || item?.vendorDropShipSpecialOrder || item?.sellable === 'exempt') && item?.variantsSize === 1">
                    <button 
                    mat-flat-button 
                    (click)="openSalesRepCallMeDrawer(item)"
                    class="row-view-keepmeUpdated-button w-100"
                    type="button">
                    {{ 'account.productTile.callMe' | cxTranslate }}
                </button>
                </div>
                <div class="product-tile-viewDetails d-flex align-items-center" *ngIf="item?.variantSiebelRowIds && item?.variantSiebelRowIds.length === 1">
                    <a 
                    mat-flat-button 
                    (click)="redirectPDP(item)"
                    class="row-view-viewDetails-button" 
                    type="button">
                    {{ 'account.productTile.viewDetails' | cxTranslate }}
                </a>
                    <mat-icon *ngIf="user" (click)="addProductToWishlist(item, true)"
                     svgIcon="like" class="product-tile-like-icon cursor-pointer" aria-label="Like"></mat-icon>
                </div>
            </div>
        </div>
        <ng-container *ngIf="user && item?.variantOptions">
            <div class="d-flex justify-content-end row-view-card-drop-ship-two mt-1" *ngIf="item?.variantOptions && item?.variantOptions[0]?.salesUnitDetailsDataSize && item?.variantOptions[0]?.salesUnitDetailsDataSize !== 0">
                <div class="d-flex">
                    <p class="m-0 pl-2 pr-0 text-center" *ngFor="let salesUnit of item?.variantOptions[0]?.salesUnitDetailsData; let i = index">
                        <span class="t-body-s">{{salesUnit?.salesUnitType}}{{ 'account.productTile.colon' | cxTranslate }}</span>
                        <span class="t-body-s-bold mr-1" *ngIf="i === 0">{{salesUnit?.salesUnitQty}}{{ 'account.productTile.count' | cxTranslate }}</span>
                        <span class="t-body-s-bold mr-1" *ngIf="i">{{salesUnit?.salesUnitQty}} {{item?.variantOptions[0]?.salesUnitDetailsData[i-1].salesUnitType}}</span>
                        <span class="row-view-card-separator mx-2" *ngIf="item?.variantOptions[0]?.salesUnitDetailsDataSize > 1"></span>
                    </p>
                    <p class="m-0 pl-1 pr-0 text-center" *ngIf="item?.variantOptions[0]?.salesUnitDetailsDataSize > 1">
                        <span class="t-body-s">{{ 'account.productTile.add' | cxTranslate }}</span>
                        <span class="t-body-s-bold">{{salesSingleUnit[item?.code]?.additionalQty || '-'}} </span>
                        <span class="t-body-s">{{ 'account.productTile.toReceieve' | cxTranslate }}</span>
                        <span class="t-body-s-bold">{{salesSingleUnit[item?.code]?.quantity || '-'}} </span>
                        <span class="t-body-s-bold">{{salesSingleUnit[item?.code]?.unitValue || '-'}}</span>
                    </p>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="card row-view-card ml-4 mr-0" *ngIf="showInput === item.code && showOptions" >
        <div class="card-body sub-cards d-flex-column justify-content-between pr-0 py-3 pl-3" *ngFor="let variant of variantOptions; let y=index">
            <div class="d-flex">
                <div class="d-flex justify-content-start business-addresses-sub-header-2 w-100 mr-2">
                    <p class="m-0 px-2 text-center business-addresses-number">
                        <span class="business-addresses-number t-body-s-bold">
                            {{y + 1}}
                        </span>
                    </p>
                    <p class="m-0 pl-2 pr-0 text-center cursor-pointer" (click)="redirectPDP(variant,item)">
                        <span class="t-label-s">
                            {{ 'account.productTile.siteSku' | cxTranslate : {baseSiteName: baseSiteName | titlecase } }}
                        </span>
                        <span class="t-body-s-bold">
                            {{variant?.code}}
                        </span>
                    </p>
                    <p class="m-0 pl-3 pr-0 text-center cursor-pointer" (click)="redirectPDP(variant,item)">
                        <span class="t-label-s">
                            {{ 'account.productTile.mfgSku' | cxTranslate }}
                        </span>
                        <span class="t-body-s-bold">
                            {{variant?.manufacturerAid}}
                        </span>
                    </p>
                    <p class="m-0 pl-3 pr-0 text-center cursor-pointer" (click)="redirectPDP(variant,item)">
                        <span class="t-label-s">
                            {{ 'account.productTile.hcpcs' | cxTranslate }}
                        </span>
                        <span class="t-body-s-bold">
                            {{variant?.hcpc}}
                        </span>
                    </p>
                </div>
                <div>
                    <mat-icon *ngIf="user" (click)="addProductToWishlist(variant, false)"
                     svgIcon="like" class="mr-2 cursor-pointer" aria-label="Like"></mat-icon>
                </div>
            </div>
            <div>
                <div class="d-flex justify-content-between pr-3">
                    <div class="d-flex">
                        <div class="row-view-margin-top">
                            <p class="m-0 row-view-line-design"></p>
                        </div>
                        <div class="d-flex flex-column row-view-padding-left row-view-margin-top">
                            <p class="t-body-s"
                            *ngFor="let variantOptionQualifiers of variant?.variantOptionQualifiers">
                            <ng-container *ngIf="selectableAtrribute?.includes(variantOptionQualifiers?.qualifier)">
                                <span class="t-body-s">
                                    {{variantOptionQualifiers?.name}}
                                </span>
                                <span class="t-body-s-bold">
                                    {{variantOptionQualifiers?.value}}
                                </span>
                            </ng-container>
                            </p>
                        </div>
                    </div>
                    <div *ngIf="user?.isViewInventoryGroup" class="d-flex">
                        <div class="row-view-margin-top">
                            <p class="m-0 row-view-line-design"></p>
                        </div>
                        <div class="d-flex flex-column row-view-padding-left-two row-view-margin-top">
                            <p class="t-body-s"> 
                                <span class="t-body-s-bold">
                                    {{ 'account.productTile.inventoryLevel' | cxTranslate }}
                                </span>
                            </p>
                            <div class="d-flex">
                                <div class="d-flex flex-column pr-4 mr-3">
                                    <p class="t-body-s" *ngFor="let stockLevel of variant?.stock?.stockLevelMap"> 
                                        <span class="t-body-s">
                                            {{ stockLevel?.key | formatWarehoseData }}: 
                                        </span>
                                        <span class="t-body-s-bold">
                                            {{ stockLevel?.value }}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex row-view-right-section">
                        <div class="d-flex flex-column">
                            <div *ngIf="!user" class="mt-1">
                                <p class="t-productTile-description cursor-pointer" (click)="onLoginClick()">Login To View Price</p>
                                <p class="t-productTile-teaser">please login to access your price</p>
                            </div>
                            <div class="row-view-price-margin" id="variant?.code"  *ngIf="user && showCalculating">
                                <span class="product-tile-sigleVariantPrice">
                                    <p class="t-productTile-description">{{ 'account.productTile.calculating' | cxTranslate }}</p>
                                    <p class="t-productTile-priceTeaser">{{ 'account.productTile.proffesionalPrice' | cxTranslate }}</p>
                                </span>
                            </div>
                            <div class="row-view-sigleVariantPrice"  *ngIf="user && !showCalculating">
                                <span [innerHTML]="variant?.priceData?.formattedValue | formatCurrency">
                                </span>
                                <sup class="t-price-purchaseUomTile product-tile-figure"> / {{variant?.unit}}</sup>
                            </div>
                            <div class="d-none mt-2 strike-through-price" id="{{variant?.code}}-strike" *ngIf="user && baseSiteNameVal !== 'mmsite'">
                            </div>

                            <div *ngIf="!user" class="d-none">
                                <div class="row-view-inStock" *ngIf="'inStock' === variant?.stock?.stockLevelStatus">
                                    <mat-icon svgIcon="inStock" class="product-tile-inStock-icon mr-2" aria-label="In Stock"></mat-icon>
                                    <span class="t-productTile-availability-Instock row-view-inStock-text mr-1">
                                        {{ 'account.productTile.inStock' | cxTranslate }}
                                    </span>
                                    <span class="t-productTile-availability-Instock-description">
                                        {{ 'account.productTile.readyToShip' | cxTranslate }}
                                    </span>
                                </div>
                                <div class="product-tile-backOrdered" *ngIf="'outOfStock' === variant?.stock?.stockLevelStatus">
                                    <mat-icon svgIcon="backordered" class="product-tile-backOrdered-icon" aria-label="Back Ordered"></mat-icon>
                                    <span class="t-productTile-availability-Instock product-tile-backOrdered-text">
                                        {{ 'account.productTile.outOfStock' | cxTranslate }}
                                    </span>
                                    <span class="t-productTile-availability-Instock-description" *ngIf="variant?.outOfStockETA">
                                        {{ 'account.productTile.pipe' | cxTranslate }}{{ 'account.productTile.eta' | cxTranslate }}{{variant?.outOfStockETA}}
                                    </span>
                                </div>
                            </div>
                            <div *ngIf="user">
                                <div class="row-view-inStock d-flex" *ngIf="'inStock' === variant?.stock?.stockLevelStatus && !variant?.isBackOrder && !variant?.isDiscontinued && !variant?.vendorDropShipSpecialOrder && variant?.sellable === 'sellable'">
                                    <mat-icon svgIcon="inStock" class="product-tile-inStock-icon mr-2" aria-label="In Stock"></mat-icon>
                                    <span class="t-productTile-availability-Instock row-view-inStock-text mr-1">
                                        {{ 'account.productTile.inStock' | cxTranslate }}
                                    </span>
                                    <span class="t-productTile-availability-Instock-description mr-1">
                                        {{ 'account.productTile.readyToShip' | cxTranslate }}
                                    </span>
                                </div>
                                <div class="product-tile-backOrdered d-flex" *ngIf="'outOfStock' === variant?.stock?.stockLevelStatus && !variant?.isBackOrder && !variant?.isDiscontinued && !variant?.vendorDropShipSpecialOrder && variant?.sellable === 'sellable'">
                                    <mat-icon svgIcon="backordered" class="product-tile-backOrdered-icon mr-2" aria-label="Back Ordered"></mat-icon>
                                    <span class="t-productTile-availability-Instock product-tile-backOrdered-text mr-1">
                                        {{ 'account.productTile.outOfStock' | cxTranslate }}
                                    </span>
                                    <span class="t-productTile-availability-Instock-description mr-1" *ngIf="variant?.outOfStockETA">
                                        {{ 'account.productTile.pipe' | cxTranslate }}{{ 'account.productTile.eta' | cxTranslate }}{{variant?.outOfStockETA}}
                                    </span>
                                </div>
                                <div class="product-tile-backOrdered d-flex" *ngIf="variant?.isBackOrder && !variant?.isDiscontinued && !variant?.vendorDropShipSpecialOrder && variant?.sellable === 'sellable'">
                                    <mat-icon svgIcon="backordered" class="product-tile-backOrdered-icon mr-2" aria-label="Back Ordered"></mat-icon>
                                    <span class="t-productTile-availability-Instock product-tile-backOrdered-text mr-1">
                                        {{ 'account.productTile.backOrdered' | cxTranslate }}
                                    </span>
                                    <span class="t-productTile-availability-Instock-description mr-1" *ngIf="variant?.mfgBackorderETA">
                                        {{ 'account.productTile.pipe' | cxTranslate }}{{ 'account.productTile.eta' | cxTranslate }}{{variant?.mfgBackorderETA}}
                                    </span>
                                </div>
                                <div class="product-tile-discontinued d-flex" *ngIf="variant?.isDiscontinued">
                                    <mat-icon svgIcon="discontinued" class="product-tile-discontinued-icon mr-2" aria-label="Discontinued"></mat-icon>
                                    <span class="t-productTile-availability-Instock product-tile-discontinued-text mr-1">
                                        {{ 'account.productTile.discontinued' | cxTranslate }}
                                    </span>
                                </div>
                                <div class="product-tile-specialorder d-flex" *ngIf="(variant?.vendorDropShipSpecialOrder || variant?.sellable === 'exempt') && !variant?.isDiscontinued">
                                    <mat-icon svgIcon="specialorder" class="product-tile-specialorder-icon mr-2" aria-label="Special Order"></mat-icon>
                                    <span class="t-productTile-availability-Instock product-tile-specialorder-text mr-1">
                                        {{ 'account.productTile.specialOrder' | cxTranslate }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div class="text-center mx-auto product-tile-multiple-order" *ngIf="variant?.quantityMultiplier && variant?.quantityMultiplier !== 1 && multipleOrder[variant?.code] && !maxQuantity[variant?.code]  &&
                                (('inStock' === variant?.stock?.stockLevelStatus || 'outOfStock' === variant?.stock?.stockLevelStatus || variant?.vendorDropShipSpecialOrder) && !variant?.isBackOrder && !variant?.isDiscontinued && variant?.sellable === 'sellable')  && (pageLoadFlag || (!pageLoadFlag))">
                                    <p class="t-productTile-boldPrice">{{ 'account.productTile.orderInMultiples' | cxTranslate }}{{variant?.quantityMultiplier}}</p>
                                </div>
                                <div class="text-center mx-auto product-tile-quantity-error mt-3" *ngIf="quantityError[variant?.code]">
                                    <p class="t-productTile-boldPrice">{{ 'account.productTile.quantityError' | cxTranslate }}</p>
                                </div>
                                <div class="text-center mx-auto product-tile-quantity-error mt-3" *ngIf="multipleOrderError[variant?.code]">
                                    <p class="t-productTile-boldPrice">{{ 'account.productTile.multipleError' | cxTranslate }}{{variant?.quantityMultiplier}}</p>
                                </div>
                                <div class="text-center mx-auto product-tile-max-quantity" *ngIf="maxQuantity[variant?.code]">
                                    <p class="t-productTile-boldPrice">
                                        {{ 'account.productTile.maxQuantity' | cxTranslate }}
                                    </p>
                                </div>
                                <div class="quantity d-flex"
                                *ngIf="(('inStock' === variant?.stock?.stockLevelStatus || 'outOfStock' === variant?.stock?.stockLevelStatus || variant?.vendorDropShipSpecialOrder) && !variant?.isBackOrder && !variant?.isDiscontinued && variant?.sellable === 'sellable' && user) ||
                                (('inStock' === variant?.stock?.stockLevelStatus || 'outOfStock' === variant?.stock?.stockLevelStatus || variant?.vendorDropShipSpecialOrder) && !user)">
                                    <button (click)="decrement(variant?.code,i,y)" class="quantity__minus" role="button" aria-label="minus">
                                        <mat-icon svgIcon="minusIcon" class="product-tile-minusIcon-icon"></mat-icon>
                                    </button>
                                    <input type="number" onKeyPress="if(this.value.length >= 4 || this.value < 0) return false;" name="counter" class="quantity__input mx-1" [(ngModel)]="quantityInput[variant?.code]" (keyup)="salesunitApi(variant)"
                                    (paste)="onPaste($event)" aria-label="quantity">
                                    <button (click)="increment(variant?.code,i,y)" class="quantity__plus" aria-label="plus">
                                        <mat-icon svgIcon="plus" class="product-tile-plus-icon"></mat-icon>
                                    </button>
                                    <button 
                                    mat-flat-button 
                                    class="product-tile-addToCart-button ml-1" 
                                    color="primary"
                                    (click)="addToCart(variant?.code,i,y)"
                                    role="button"
                                    [disabled]="cartDisable || (!variant?.isSampleVariant && (variant?.priceData?.formattedValue  === '$0.0' || variant?.priceData?.formattedValue === '$0.00' || variant?.priceData?.formattedValue === '$00.0' || variant?.priceData?.formattedValue === '$00.00'))">
                                    + Cart
                                </button>
                                </div>
                            </div>
                            <div class="d-none">
                                <div class="row-view-card-error-notification text-center w-100">
                                    <p class="t-body-s">
                                        {{ 'account.productTile.error' | cxTranslate }}
                                        {{ 'account.productTile.orderInMultiples' | cxTranslate }}
                                    </p>
                                </div>
                            </div>
                            <div class="product-tile-specialorder d-none">
                                <mat-icon svgIcon="specialorder" class="product-tile-specialorder-icon" aria-label="Special Order"></mat-icon>
                                <span class="t-productTile-availability-Instock product-tile-specialorder-text">
                                    {{ 'account.productTile.specialOrder' | cxTranslate }}
                                </span>
                            </div>
                            <div class="product-tile-promo d-none">
                                <mat-icon svgIcon="promo" class="product-tile-promo-icon" aria-label="Promo"></mat-icon>
                                <span 
                                    class="t-productTile-availability-Instock ml-1 product-tile-promo-text">
                                    {{ 'account.productTile.promo' | cxTranslate }}
                                </span>
                                <span class="t-productTile-availability-Instock-description">
                                    {{ 'account.productTile.pipe' | cxTranslate }}Buy 12 get 2 Free
                                </span>
                            </div>
                            <div class="product-tile-discontinued mb-2" *ngIf="'discontinued' === variant?.stock?.stockLevelStatus">
                                <mat-icon svgIcon="discontinued" class="product-tile-discontinued-icon" aria-label="Discontinued"></mat-icon>
                                <span class="t-productTile-availability-Instock product-tile-discontinued-text">
                                    {{ 'account.productTile.discontinued' | cxTranslate }}
                                </span>
                            </div>
                            <ng-container *ngIf="isNotifyFlag">
                                <div class="row-view-card-keepmeUpdated d-flex flex-row-reverse"
                                    *ngIf="(variant?.isBackOrder || 'outOfStock' === variant?.stock?.stockLevelStatus)  && !variant?.isDiscontinued && variant?.sellable === 'sellable' && !variant?.vendorDropShipSpecialOrder && user">
                                    <button mat-flat-button class="row-view-card-keepmeUpdated-button w-100" type="button"
                                        (click)="keepMeUpdated(variant, item)">
                                        <mat-icon svgIcon="bell" class="row-view-card-bell-icon" aria-label="Bell"></mat-icon>
                                        {{ 'account.productTile.keepMeUpdated' | cxTranslate }}
                                    </button>
                                </div>
                            </ng-container>
                            <div class="row-view-card-keepmeUpdated" *ngIf="user && (variant?.isDiscontinued || variant?.vendorDropShipSpecialOrder || variant?.sellable === 'exempt')">
                                <button 
                                mat-flat-button 
                                (click)="openSalesRepCallMeDrawerForVariant(variant,item)"
                                class="row-view-card-keepmeUpdated-button w-100"
                                type="button">
                                {{ 'account.productTile.callMe' | cxTranslate }}
                            </button>
                            </div>
                            <div class="product-tile-substitutes" *ngIf="'discontinued' === variant?.stock?.stockLevelStatus">
                                <button 
                                mat-flat-button 
                                class="product-tile-button w-100" 
                                type="button">
                                {{ 'account.productTile.substitution' | cxTranslate }}
                            </button>
                            </div>
                            <div class="product-tile-viewDetails">
                                <a 
                                mat-flat-button 
                                (click)="redirectPDP(variant,item)"
                                class="row-view-viewDetails-button" 
                                type="button">
                                {{ 'account.productTile.viewDetails' | cxTranslate }}
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="user">
                <div class="d-flex justify-content-end row-view-card-drop-ship-two" *ngIf="variant?.salesUnitDetailsDataSize !== 0">
                    <div class="d-flex">
                        <p class="m-0 pl-2 pr-0 text-center" *ngFor="let salesUnit of variant?.salesUnitDetailsData; let i = index">
                            <span class="t-body-s">{{salesUnit?.salesUnitType}}{{ 'account.productTile.colon' | cxTranslate }}</span>
                            <span class="t-body-s-bold mr-1" *ngIf="i === 0">{{salesUnit?.salesUnitQty}}{{ 'account.productTile.count' | cxTranslate }}</span>
                            <span class="t-body-s-bold mr-1" *ngIf="i">{{salesUnit?.salesUnitQty}} {{variant?.salesUnitDetailsData[i-1].salesUnitType}}</span>
                            <span class="row-view-card-separator mx-2" *ngIf="variant?.salesUnitDetailsDataSize > 1"></span>
                        </p>
                        <p class="m-0 pl-1 pr-0 text-center" *ngIf="variant?.salesUnitDetailsDataSize > 1">
                            <span class="t-body-s">{{ 'account.productTile.add' | cxTranslate }}</span>
                            <span class="t-body-s-bold">{{salesUnit[variant?.code]?.additionalQty || '-'}} </span>
                            <span class="t-body-s">{{ 'account.productTile.toReceieve' | cxTranslate }}</span>
                            <span class="t-body-s-bold">{{salesUnit[variant?.code]?.quantity || '-'}} </span>
                            <span class="t-body-s-bold">{{salesUnit[variant?.code]?.unitValue || '-'}}</span>
                        </p>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<div *ngIf="totalCount > 1" class="has-text-centered position-relative paginate">
    <p class="t-title-m d-none m-0 position-absolute d-md-block total">
      {{ totalCount }} Records
    </p>
    <pagination-controls 
    *ngIf="totalCount>totalRecordsOnPage"
    (pageChange)="getNewPageData($event)"
      previousLabel=""
      nextLabel=""
    ></pagination-controls>
  </div>
<!-- </div> -->