import { Component, OnInit } from '@angular/core';
import { SiteContextConfig } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { SectionLoaderService } from './section-loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html'
})
export class LoaderComponent implements OnInit {
  visible = false;
  showLoader=false;

  private pageLoaderStateChanged: Subscription;
  baseSiteName: any;
  baseSiteNameVal: string;
 
  constructor(private config: SiteContextConfig,public sectionLoaderService: SectionLoaderService) {}

  ngOnInit() {

    this.baseSiteName = this.config?.context?.baseSite;
    if(this.baseSiteName){
      this.baseSiteNameVal=this.baseSiteName[0];
    }  
  
  }



}
