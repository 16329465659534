<div class="order-status mt-2 mt-md-0">
  <div class="order-status-border"></div>
  <div class="d-flex flex-column flex-md-row m-2 justify-content-between align-items-center">

    <div class="d-flex flex-column order-status-one">
      <div class="d-flex flex-row order-status-head w-100">
        <p *ngIf="returnStatus!=='RETURN'" class="t-label-s mb-0 w-100 other-statuses" [ngClass]="status">
          {{ "account.order.orderStatus" | cxTranslate }}
        </p>
        <p *ngIf="returnStatus==='RETURN'" class="t-label-s mb-0 w-100 other-statuses" [ngClass]="status">
          {{'cart.returnStatus' | cxTranslate}}
        </p>
      </div>
      <div class="d-flex flex-row order-status-body w-100">
        <p class="t-title-m mb-0 px-3 py-1 w-100 other-statuses" [ngClass]="status">
          {{ status | titlecase }}
        </p>
      </div>
    </div>
    <div class="d-none d-md-flex flex-row-reverse order-status-fixedField order-status-fixedField-flex-wrap m-md-0">

      <!-- Resubmit Button -->
      <div *ngIf="orderDetails?.isOrderLevelMDIEnabled && 
                        ((orderDetails?.medicalDropshipError && orderDetails?.medicalDropshipError.length > 0) 
                        && (!orderDetails?.medicalDropshipInformation)); else resubmitOrderBtn">
        <button *ngIf="showResubmitBtn && returnStatus!=='RETURN'" (click)="medicalInformationCheck('resubmit')" mat-flat-button
          class="mr-2 t-label-l approve-cta m-1" color="primary">
          <mat-icon class="mr-1" svgIcon="approve"></mat-icon>{{ "account.order.resubmit" | cxTranslate }}
        </button>
      </div>
      <ng-template #resubmitOrderBtn>
        <button *ngIf="showResubmitBtn && returnStatus!=='RETURN'" (click)="resubmitOrder()" mat-flat-button
          class="mr-2 t-label-l approve-cta m-1" color="primary">
          <mat-icon class="mr-1" svgIcon="approve"></mat-icon>{{ "account.order.resubmit" | cxTranslate }}
        </button>
      </ng-template>


      <!-- Close Button -->
      <button *ngIf="showCloseBtn && returnStatus!=='RETURN'" (click)="closeOrder()" mat-flat-button class="mr-2 t-label-l cancel-order-cta m-1"
        color="primary">
        <mat-icon class="mr-1" svgIcon="cancel"></mat-icon>{{ "account.order.close" | cxTranslate }}
      </button>

      <!-- Edit Button -->
      <button *ngIf="showEditBtn && returnStatus!=='RETURN'" (click)="editOrder()" mat-flat-button class="mr-2 t-label-l orderAgain edit-cta m-1"
        color="primary">
        <mat-icon class="mr-1" svgIcon="edit-order"></mat-icon>{{ "account.order.edit" | cxTranslate }}
      </button>

      <!-- Reject Button -->
      <button *ngIf="showRejectBtn && returnStatus!=='RETURN'" (click)="rejectOrder()" mat-flat-button class="mr-2 t-label-l reject-cta m-1"
        color="primary">
        <mat-icon class="mr-1" svgIcon="cancel"></mat-icon>{{ "account.order.reject" | cxTranslate }}
      </button>

      <!-- Approve Button -->
      <div *ngIf="orderDetails?.isOrderLevelMDIEnabled && 
                        ((orderDetails?.medicalDropshipError && orderDetails?.medicalDropshipError.length > 0) 
                        && (!orderDetails?.medicalDropshipInformation)); else approveOrderBtn">
        <button *ngIf="showApproveBtn && returnStatus!=='RETURN'" (click)="medicalInformationCheck('approve')" mat-flat-button
          class="mr-2 t-label-l approve-cta medical-info m-1" color="primary">
          <mat-icon class="mr-1" svgIcon="approve"></mat-icon>{{ "account.order.approve" | cxTranslate }}
        </button>
      </div>
      
      <ng-template #approveOrderBtn>
        <button *ngIf="showApproveBtn && returnStatus!=='RETURN'" (click)="approveOrder()" mat-flat-button
          class="mr-2 t-label-l approve-cta m-1" color="primary">
          <mat-icon class="mr-1" svgIcon="approve"></mat-icon>{{ "account.order.approve" | cxTranslate }}
        </button>
      </ng-template>

      <!-- Cancel Order Button -->
      <button *ngIf="showCancelBtn && returnStatus!=='RETURN'" (click)="cancelOrder()" mat-flat-button class="mr-2 t-label-l cancel-order-cta m-1"
        color="primary">
        <mat-icon class="mr-1" svgIcon="cancel"></mat-icon>{{ "account.order.cancelOrder" | cxTranslate }}
      </button>

      <!-- Edit Payment button -->
      <button *ngIf="showEditPayment && returnStatus!=='RETURN'" (click)="openEditPaymentModal()" mat-flat-button
        class="mr-2 t-label-l orderAgain m-1" color="white">
        <mat-icon class="mr-1" svgIcon="cart-edit"></mat-icon>{{ "account.order.editPayment" | cxTranslate }}
      </button>

      <!-- Edit PO button -->
      <button *ngIf="showEditPO && returnStatus!=='RETURN'" (click)="openEditPoModal()" mat-flat-button class="mr-2 t-label-l orderAgain m-1"
        color="white">
        <mat-icon class="mr-1" svgIcon="cart-edit"></mat-icon>{{ "account.order.editPO" | cxTranslate }}
      </button>

      <!-- Reorder Button -->
      <button mat-flat-button class="mr-2 t-label-l m-1" (click)="reOrder()" *ngIf="!editOrderFlag && !showEditBtn && returnStatus!=='RETURN'"
        color="primary">
        <mat-icon class="mr-1" svgIcon="order-again"></mat-icon>{{ "account.order.reOrderItems" | cxTranslate }}
      </button>

      <!-- Proof of delivery Button -->
      <button mat-flat-button class="t-label-l proofOfDelivery m-1 mr-md-2" *ngIf="
         (status === orderConstants.shipped ||
          status === orderConstants.completed ||
          status === orderConstants.partiallyShipped ||
          status === orderConstants.awaitingReturn) && returnStatus!=='RETURN'
        " color="white" (click)="podDownload()">
        <mat-icon class="mr-1" svgIcon="proof-of-delivery"></mat-icon>{{ "account.order.proofOfDelivery" | cxTranslate
        }}
      </button>

      <!-- DO NOT DELETE - Functionality does not exist as of now, might be taken in future -->
      <!-- <button
        mat-flat-button
        class="mr-2 t-label-l startReturn "
        *ngIf="
          status === orderConstants.shippingStat ||
          status === orderConstants.completed ||
          status === orderConstants.partiallyShipped ||
          status === orderConstants.awaitingReturn
        "
        color="white"
      >
        <mat-icon class="mr-1" svgIcon="orderStartReturn "></mat-icon
        >{{ "account.order.startReturn" | cxTranslate }}
      </button> -->

      <!-- Email Button -->
      <button mat-flat-button class="mr-2 t-label-l m-1" (click)="email()" color="white"
        *ngIf="!editOrderFlag && returnStatus!=='RETURN' && !(status === orderConstants.pendingApproval || status === orderConstants.unapproved)">
        <mat-icon class="mr-1" svgIcon="email"></mat-icon>{{ "account.order.email" | cxTranslate }}
      </button>

      <!-- Print Button -->
      <button mat-flat-button class="mr-2 t-label-l m-1" (click)="print()" color="white">
        <mat-icon class="mr-1 " svgIcon="print"></mat-icon>{{ "account.order.print" | cxTranslate }}
      </button>

    </div>

    <!--mobile screen-->
    <div class="d-md-none order-status-mobileFixedField m-md-0">
      <div class="py-2 d-flex justify-content-between">
        <!-- Print Button -->
        <button mat-flat-button class="mr-2 t-label-l m-1 w-100 printbtn" (click)="print()" color="white">
          <mat-icon class="mr-1 " svgIcon="print"></mat-icon>{{ "account.order.print" | cxTranslate }}
        </button>
        <!-- Email Button -->
        <button mat-flat-button class="t-label-l m-1 w-100" (click)="email()" color="white" 
          *ngIf="!editOrderFlag && returnStatus!=='RETURN' && !(status === orderConstants.pendingApproval || status === orderConstants.unapproved)">
          <mat-icon class="mr-1" svgIcon="email"></mat-icon>{{ "account.order.email" | cxTranslate }}
        </button>
      </div>
      <div *ngIf="returnStatus!=='RETURN'" class="mr-2">

        <!-- Resubmit Button -->
        <div *ngIf="orderDetails?.isOrderLevelMDIEnabled && 
        ((orderDetails?.medicalDropshipError && orderDetails?.medicalDropshipError.length > 0) 
        && (!orderDetails?.medicalDropshipInformation)); else resubmitOrderBtn">
          <button *ngIf="showResubmitBtn" (click)="medicalInformationCheck('resubmit')" mat-flat-button
            class="mr-2 w-100 t-label-l approve-cta m-1" color="primary">
            <mat-icon class="mr-1" svgIcon="approve"></mat-icon>{{ "account.order.resubmit" | cxTranslate }}
          </button>
        </div>
        <ng-template #resubmitOrderBtn>
          <button *ngIf="showResubmitBtn" (click)="resubmitOrder()" mat-flat-button
            class="mr-2 w-100 t-label-l approve-cta m-1" color="primary">
            <mat-icon class="mr-1" svgIcon="approve"></mat-icon>{{ "account.order.resubmit" | cxTranslate }}
          </button>
        </ng-template>

        <!-- Close Button -->
        <button *ngIf="showCloseBtn" (click)="closeOrder()" mat-flat-button
          class="mr-2 w-100 t-label-l cancel-order-cta m-1" color="primary">
          <mat-icon class="mr-1" svgIcon="cancel"></mat-icon>{{ "account.order.close" | cxTranslate }}
        </button>

        <!-- Edit Button -->
        <button *ngIf="showEditBtn" (click)="editOrder()" mat-flat-button
          class="mr-2 w-100 t-label-l orderAgain edit-cta m-1" color="primary">
          <mat-icon class="mr-1" svgIcon="edit-order"></mat-icon>{{ "account.order.edit" | cxTranslate }}
        </button>

        <!-- Reject Button -->
        <button *ngIf="showRejectBtn" (click)="rejectOrder()" mat-flat-button
          class="mr-2 w-100 t-label-l reject-cta m-1" color="primary">
          <mat-icon class="mr-1" svgIcon="cancel"></mat-icon>{{ "account.order.reject" | cxTranslate }}
        </button>

        <!-- Approve Button -->
        <div *ngIf="orderDetails?.isOrderLevelMDIEnabled && 
                        ((orderDetails?.medicalDropshipError && orderDetails?.medicalDropshipError.length > 0) 
                        && (!orderDetails?.medicalDropshipInformation)); else approveOrderBtn">
            <button *ngIf="showApproveBtn" (click)="medicalInformationCheck('approve')" mat-flat-button
              class="mr-2 w-100 t-label-l approve-cta m-1" color="primary">
              <mat-icon class="mr-1" svgIcon="approve"></mat-icon>{{ "account.order.approve" | cxTranslate }}
            </button>
        </div>
        <ng-template #approveOrderBtn>
          <button *ngIf="showApproveBtn" (click)="approveOrder()" mat-flat-button
            class="mr-2 w-100 t-label-l approve-cta m-1" color="primary">
            <mat-icon class="mr-1" svgIcon="approve"></mat-icon>{{ "account.order.approve" | cxTranslate }}
          </button>
        </ng-template>

        <!-- Cancel Order Button -->
        <button *ngIf="showCancelBtn" (click)="cancelOrder()" mat-flat-button
          class="mr-2 w-100 t-label-l cancel-order-cta m-1" color="primary">
          <mat-icon class="mr-1" svgIcon="cancel"></mat-icon>{{ "account.order.cancelOrder" | cxTranslate }}
        </button>

        <!-- Edit Payment button -->
        <button *ngIf="showEditPayment" (click)="openEditPaymentModal()" mat-flat-button
          class="mr-2 t-label-l orderAgain m-1 w-100" color="white">
          <mat-icon class="mr-1" svgIcon="cart-edit"></mat-icon>{{ "account.order.editPayment" | cxTranslate }}
        </button>

        <!-- Edit PO button -->
        <button *ngIf="showEditPO" (click)="openEditPoModal()" mat-flat-button
          class="mr-2 t-label-l orderAgain m-1 w-100" color="white">
          <mat-icon class="mr-1" svgIcon="cart-edit"></mat-icon>{{ "account.order.editPO" | cxTranslate }}
        </button>

        <!-- Reorder Button -->
        <button mat-flat-button class="t-label-l w-100 m-1 mr-2" (click)="reOrder()" *ngIf="!editOrderFlag && !showEditBtn && returnStatus!=='RETURN'" color="primary">
          <mat-icon class="mr-1" svgIcon="order-again"></mat-icon>{{ "account.order.reOrderItems" | cxTranslate }}
        </button>

        <!-- Proof of delivery Button -->
        <button mat-flat-button class=" t-label-l proofOfDelivery m-1 mr-2 w-100" *ngIf="
              status === orderConstants.shipped ||
              status === orderConstants.completed ||
              status === orderConstants.partiallyShipped ||
              status === orderConstants.awaitingReturn
            " color="white" (click)="podDownload()">
          <mat-icon class="mr-1" svgIcon="proof-of-delivery"></mat-icon>{{ "account.order.proofOfDelivery" | cxTranslate
          }}
        </button>
      </div>
    </div>
  </div>
  <div class="order-status-border"></div>

</div>

<div *ngIf="success" class="py-3 mx-3">
  <reusable-alert-box [alertType]="'successAlertBox'" alertMsg="{{ 'orderApproval.emailSuccess' | cxTranslate }}"></reusable-alert-box>
</div>

<div *ngIf="noPOD" class="py-3 mx-3">
  <reusable-alert-box [alertType]="'warningAlertBox'" alertMsg={{noPodMsg}}></reusable-alert-box>
</div>



