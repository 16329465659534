import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthStorageService, OccEndpointsService } from '@spartacus/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class BoxoutEndpointService extends BaseService {
  constructor(
    public httpClient: HttpClient,
    public occEndpointsService: OccEndpointsService,
    public authStorageService: AuthStorageService
  ) {
    super(httpClient, occEndpointsService, authStorageService);
  }
}
