import { Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { CartService } from 'src/app/shared/services/common/cart.service';
import { GTMService } from 'src/app/shared/services/common/gtm.service';

interface MixMatchPromo {
  promoTitle: string;
  promoCode: string;
  combinedFlag: boolean;
  description: string;
  totalPointsEarned: number;
  offers: {
    product: any;
    selectedPoints: number;
    earnedPoints: number;
    products: {
      itemNumber: string; // SKU Number
      brandName: string;
      quantity: number;
      itemTitle: string;
      listPrice: number;
    }[];
  }[];
}
@Component({
  selector: 'app-mix-match-promotion',
  templateUrl: './mix-match-promotion.component.html',
})
export class MixMatchPromotionComponent implements OnInit, OnDestroy {
  mmPromoData: MixMatchPromo;
  productQty: any;
  currentStep: number = 1;
  offersCount: number = 1;
  earnedPoints: number = 0;
  selectedPoints: number = 0;
  currentProducts: any[] = [];
  user: User;
  isLoading: boolean = false;
  subscription: Subscription[] = [];
  inputValue: string = '';
  productId: string;
  inputChangeEvent = new EventEmitter<string>();
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: MixMatchPromo,
    private matDialogRef: MatDialogRef<MixMatchPromotionComponent>,
    private cartService: CartService,
    private store: Store<fromApp.AppState>,
    private customHttp: BoxoutEndpointService,
    private gtmService: GTMService
  ) {
    this.mmPromoData = this.data || {};
  }

  ngOnInit(): void {
    this.offersCount = this.mmPromoData?.offers?.length || 1;
    this.updateState();

    this.subscription.push(
      this.store.select('auth').subscribe((user) => {
        this.user = user && user.user!;
      })
    );
  }

  // For handling selected quantity change
  handleQuantityChange = (product: any, addSub: 'ADD' | 'SUB') => {
    if (this.selectedPoints >= 0) {
      this.productId = product?.itemNumber;
      if (addSub === 'ADD') {
        product.quantity += 1;
        this.selectedPoints += 1;
      } else {
        if (product.quantity > 0){
          product.quantity -= 1;
          this.selectedPoints -= 1;
        }
      }
      // For updating selected product count for shown offer
      this.selectedPoints =
        this.currentProducts
          .map((a) => a.quantity)
          ?.reduce((a, b) => a + b, 0) || 0;
      this.mmPromoData.offers[this.currentStep - 1].selectedPoints =
        this.selectedPoints;
    }
  };

  keyPressNumbers(event: any) {
    if (!event) return false;
  
    const charCode = event.which || event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      const targetValue = event.target?.value;
      const val = targetValue && targetValue.length >= 4 ? false : true;
      return val;
    }
  }
  
  onInputChange(value: string, product: any) {
    if (!product) return;
  
    const numValue = product.quantity || 0;
    this.productId = product.itemNumber || '';
    this.inputValue = numValue.toString();
    this.inputChangeEvent.emit(this.inputValue);
    this.selectedPoints =
      (this.currentProducts?.map((a) => a.quantity)?.reduce((a, b) => a + (b || 0), 0)) || 0;
    const currentOffer = this.mmPromoData?.offers?.[this.currentStep - 1];
    if (currentOffer) {
      currentOffer.selectedPoints = this.selectedPoints;
    }
  }
  

  // For handing step navigations
  handleStepper = (navigation: 'NEXT' | 'PREV') => {
    if (navigation === 'NEXT') {
      this.currentStep = this.currentStep + 1;
    } else {
      this.currentStep = this.currentStep - 1;
    }
    this.updateState();
  };

  // For updating state for shown offer (currentProducts, earnedPoints, selectedPoints)
  updateState = () => {
    this.currentProducts =
      this.mmPromoData?.offers[this.currentStep - 1]?.products || [];
    this.earnedPoints =
      this.mmPromoData?.offers[this.currentStep - 1]?.earnedPoints || 0;
    this.selectedPoints =
      this.currentProducts.map((a) => a.quantity)?.reduce((a, b) => a + b, 0) ||
      0;
    this.mmPromoData.offers[this.currentStep - 1].selectedPoints =
      this.selectedPoints;
  };

  // For Applying Mix-Match/Discount+Free-Product Promotion
  applyPromotion = () => {
    this.isLoading = true;
    let cartId = sessionStorage.getItem('cartId');
    let applyPromotion = `/users/${
      this.user?.uid
    }/carts/${cartId}/apply-mix-match/${
      this.mmPromoData?.promoCode
    }?fields=DEFAULT`;

    if (this.data && this.data.offers) {
      this.data.offers.forEach(offer => {
        if (offer.products) {
          offer.products.forEach(product => {
            if (product.quantity === null || product.quantity === undefined) {
              product.quantity = 0;
            }
          });
        }
      });
    }
    
    if(this.mmPromoData?.combinedFlag !== undefined) {
      applyPromotion += `&isCombinable=${this.mmPromoData?.combinedFlag}`
    }

    // For applying Promotion
    this.subscription.push(
      this.customHttp
        .post(applyPromotion, this.mmPromoData)
        .subscribe((data: any) => {
          this.cartService.getCartPageData(this.user?.uid, false);
          this.isLoading = false;
          this.matDialogRef.close(true);
          let gaPromoObj = {
            'promotion_id': this.mmPromoData?.promoCode,
            'promotion_name': this.mmPromoData?.promoTitle,
            'creative_name': '',
            'creative_slot': '',
            'location_id': ''
          }
          this.gtmService.sendSelectPromotionsData([gaPromoObj]);
        })
    );
  };

  ngOnDestroy(): void {
    if (this.subscription?.length) {
      this.subscription.forEach((sub: Subscription) => sub.unsubscribe());
    }
  }
}
