import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { GTMService } from '../../services/common/gtm.service';
import { HeaderService } from '../../services/header.service';

@Component({
  selector: 'app-add-edit-favorite-list',
  templateUrl: './add-edit-favorite-list.component.html'
})
export class AddEditFavoriteListComponent implements OnInit, OnDestroy {

  editedFavListSuccess:boolean=false;
  editedFavListFailure:boolean=false;

  displayUid: string;
  user: User;
  unitType: string;
  userSub: Subscription;
  @Input() isEdit: boolean = false;
  editFavListForm: any;
  errorResponse: boolean = false;
  successResponse: boolean = false;
  favListExists: boolean = false;
  productCode: string;
  getWishlistsSub: Subscription;
  createListAndAddProductSub: Subscription;
  createListSub: Subscription;
  errors: any[] =[];
  isCreator: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private formBuilder: FormBuilder, 
    private customHttp: BoxoutEndpointService,
    private dialogRef: MatDialogRef<AddEditFavoriteListComponent>,
    private router: Router,
    private store: Store<fromApp.AppState>,
    private headerService: HeaderService,
    private gtmService: GTMService
    ) { }

  ngOnInit(): void {

    this.userSub = this.store.select('auth').subscribe(user => { 
      this.user = user && user.user!;
      this.displayUid = this.user?.displayUid!;
      this.unitType = this.user?.orgUnit?.unitType!;
    });

    // to get userID
    // this.isLoggedInSub=this.headerService.isLoggedIn.subscribe((response) => {
    //   this.userData = response;
    // });

    //to get the user info
    // this.userDataSub=this.userData?.subscribe((data: any) => {
    //   this.displayUid = data?.displayUid;
    // });

    this.isEdit = this.data && this.data.isEdit;
    this.productCode = this.data && this.data.productCode;
    this.isCreator = this.data && this.data.isCreator;
    this.createForm();
  }

  createForm() {
    this.editFavListForm = this.formBuilder.group({
      name: [(this.isEdit ? this.data?.editFavListData?.name : ""), [Validators.required]],
      description: [(this.isEdit ? this.data?.editFavListData?.description : ""), [Validators.maxLength(240)]],
      isShared: [(this.isEdit ? this.data?.editFavListData?.isShared  : false),],
      isLocked: [(this.isEdit ? this.data?.editFavListData?.isLocked : false)]
    });
  }

  saveList() {
    let req = this.editFavListForm.getRawValue();
    if (req && req.hasOwnProperty('isShared') && !req.isShared) {
      delete req.isShared;
    }
    if (req && req.hasOwnProperty('isLocked') && !req.isLocked) {
      delete req.isLocked;
    }

    if (this.isEdit) {
      this.saveExistingList();
    } 
    else if(!this.isEdit && this.data?.action === "copySelectedToFavoriteToNewList"){
      this.copySelectedToFavoriteToNewList();
    }

    else {
      if (this.productCode) {
      this.createListAndAddProductSub = this.createListAndAddProduct(req)
          .subscribe((res: any) => {
            if (res && res.success && res.success === 'Success') {
              this.gtmService.setAddToWishlist(this.data?.gaItems);
              this.displayAlerts(true, false, false);
              this.dialogRef.close();
            } else if (res && res.success && res.success === 'Fail') {
              if(res.errors?.errors?.length > 0){
                this.errors = res.errors.errors;
                this.displayAlerts(false, false, true);
              }else {
                this.errors = [];
                this.displayAlerts(false, false, true);
              }
            }
          }, (error) => {
            this.displayAlerts(false, true, false);
          });
      } else {
        this.createListSub = this.createList(req)
          .subscribe((res: any) => {
            if (res && res.success && res.success === 'Success') {
              this.displayAlerts(true, false, false);
              this.dialogRef.close();
            } else if (res && res.success && res.success === 'Fail') {
              if(res.errors?.errors?.length > 0){
                this.errors = res.errors.errors;
                this.displayAlerts(false, false, true);
              }else {
                this.errors = [];
                this.displayAlerts(false, false, true);
              }
            }
          }, (error) => {
            this.displayAlerts(false, true, false);
          });
      }
    }
  }

  createList(request: any) {
    const apiURL = `users/current/wishlists/create?fields=DEFAULT`;
    return this.customHttp.post(apiURL, request);
  }

  // This method is used to save edited favorite list
  saveExistingList() {

    const req = this.editFavListForm.getRawValue();
    req.id = this.data?.editFavListData?.id;

    //save edited Fav api call

  let apiURL = `users/${this.displayUid}/wishlists/editWishlist/${encodeURIComponent(this.data?.editFavListData?.name)}?fields=DEFAULT`;
  this.customHttp.post(apiURL, req).subscribe((resp: any) => {
    if(resp?.success === "Success"){
      this.editedFavListSuccess=true;
      this.editedFavListFailure=false;
      this.dialogRef.close({name:req?.name, action:"editedExistingListSaved" });
    }else if(resp?.success === "Fail"){
      this.editedFavListSuccess=false;
      this.editedFavListFailure=true;
    }
  },
  (error)=>{
    
  });
    
  }


// This method is used to copy selected favorite products to new wishlist
  copySelectedToFavoriteToNewList(){

    const req = this.editFavListForm.getRawValue();
    req.entries = this.data?.entries ? this.data?.entries : "";

    const apiURL = `users/${this.displayUid}/wishlists/copyWishList/${encodeURIComponent(this.editFavListForm?.value?.name)}?fields=DEFAULT`;
    
    this.customHttp.post(apiURL, req).subscribe((resp: any) => {
      if(resp?.success === "Success"){
        this.editedFavListSuccess=true;
        this.editedFavListFailure=false;
        this.headerService.setCopySelectedToFavoriteToNewList({name:this.editFavListForm?.value?.name, action: "copySelectedToFavoriteToNewListSaved"});
      }else if(resp?.success === "Fail"){
        this.editedFavListSuccess=false;
        this.editedFavListFailure=true;
      }
   },
   (error:any)=>{
     
   });
  }

  createListAndAddProduct(request: any) {
    const apiURL = `users/current/wishlists/create?fields=DEFAULT&productcode=${this.productCode}`;
    return this.customHttp.post(apiURL, request);
  }

  displayAlerts(success: boolean, error: boolean, listExists: boolean) {
    this.successResponse = success;
    this.errorResponse = error;
    this.favListExists = listExists;
  }

  ngOnDestroy(): void {
    if(this.getWishlistsSub) {
      this.getWishlistsSub.unsubscribe();
    }
    if(this.createListAndAddProductSub) {
      this.createListAndAddProductSub.unsubscribe();
    }
    if(this.createListSub) {
      this.createListSub.unsubscribe();
    }
    if(this.userSub) {
      this.userSub.unsubscribe();
    }
  }


  //This method is used to delete existing Favorite list
  deleteFavoriteList(){
    let apiUrl=`users/${this.displayUid}/wishlists/deleteWishList/${this.data?.editFavListData?.name}?fields=DEFAULT`
    this.customHttp.get(apiUrl).subscribe((resp: any) => {

    //if list is deleted then user should be at favorite home page
    if(resp?.success === "Success"){
      this.editedFavListSuccess=true;
      this.editedFavListFailure=false;
      this.router.navigate(['my-account/favoriteLists']);
    }else if(resp?.success === "Fail"){
      this.editedFavListSuccess=false;
      this.editedFavListFailure=true;
    }
  },
  (error)=>{
    
     });
  }

}
