import { ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NguCarouselConfig } from '@ngu/carousel';
import { SiteContextConfig, User, WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import * as fromApp from 'src/app/core/store/app.reducer';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { CartService } from 'src/app/shared/services/common/cart.service';
import { CurrentThemeService } from 'src/app/shared/services/common/current-theme.service';
import { GTMService } from 'src/app/shared/services/common/gtm.service';
import { InputValidationService } from 'src/app/shared/services/common/input-validation.service';
import { ProductDetailService } from 'src/app/shared/services/common/product-detail.service';
import { ProductService } from 'src/app/shared/services/common/product.service';
import { QuickOrderService } from 'src/app/shared/services/common/quick-order.service';


@Component({
  selector: 'app-may-also-like-product',
  templateUrl: './may-also-like-product.component.html'
})
export class MayAlsoLikeProductComponent implements OnInit,OnChanges,OnDestroy{

  carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 2, md: 3, lg: 3, all: 0 },
    load: 3,
    loop: true,
    touch: true,
    velocity: 0.2,
    point: {
      visible: false,
      hideOnSingleSlide: false
    }
  }


  quantityInput:any=[];
  displayUid: string;
  userId: string;
  userSub: Subscription;
  user: User;
  imageUrl:any;
  siteConfig:any;
  
  showInput:any;
  qtyRequired:boolean=false;
  maxQtyReached:boolean=false;
  orderMultiplerRequired:boolean=false;
  products: any=[];
  addToWishlistDialog: any;
  themeClass: any;
  productServiceSub:Subscription;
  gaProductArray: any;
  productCodeFromRoute: any;


   constructor(private customHttp: BoxoutEndpointService, private cdr: ChangeDetectorRef ,private config: SiteContextConfig,
    private router: Router,
    private windowRef: WindowRef,private cartService: CartService,private store: Store<fromApp.AppState>,
    private quickOrderService: QuickOrderService,public dialog: MatDialog,public currentTheme: CurrentThemeService, 
    private productService: ProductDetailService, private productSer: ProductService,private inputValidationService:InputValidationService,
    private gtmService: GTMService) {
    //for image url
    this.siteConfig = this.config;

  }


  ngOnInit(): void {
    this.themeClass = this.currentTheme.getCurrentTheme();
    if(this.router.url.includes('productDetails') ) {
      this.productCodeFromRoute = this.router.url.split('/productDetails/')[1];
    }

    this.userSub = this.store.select('auth').subscribe(user => {
      this.user = user && user.user!;
      this.displayUid = this.user?.displayUid!;
      this.userId = this.user?.uid!;
      this.cdr.detectChanges();
    });

    this.imageUrl = this.siteConfig?.backend?.occ?.baseUrl;

   this.getProductCodeFromPdp();
    
  }

  getProductCodeFromPdp(){
    this.productServiceSub=this.productService.getProdutData().subscribe((resp: any) => {
      if(resp){

        let youMayLikeCode = resp?.baseOptions?.length ? resp?.baseProduct : resp?.code ;
        if(this.productCodeFromRoute === youMayLikeCode || this.productCodeFromRoute === resp?.code) {
          this.getYouMayLikeProducts(youMayLikeCode);
        }
      }
    });
  }

   //This method is used to get recently viewed products
   getYouMayLikeProducts(productCode:any){
    this.products=[];
     let apiUrl=`/orgProducts/youMayAlsoLike/${productCode}`;
     
      this.customHttp.get(apiUrl).subscribe((res:any)=>{
        let gaObj = {}
        this.gaProductArray = [];
        if(res){
        this.products=res;
        this.products?.forEach((element:any,index :any) => {
          if( element?.variantOptions && element?.variantOptions[0] && (element?.variantOptions[0]?.quantityMultiplier)){
            this.quantityInput[index]  = element?.variantOptions[0]?.quantityMultiplier;
          }else if( element?.variantOptions && element?.variantOptions[0] && !(element?.variantOptions[0]?.quantityMultiplier)){
            this.quantityInput[index]  = 1;
          }
          gaObj = element.gaItems;
          gaObj = { ...gaObj, discount: null, index: index , price: element?.variantsSize === 1 ? element?.price?.value : null, quantity: null,  item_list_id: 'related_list', item_list_name: 'Related List'};
          this.gaProductArray.push(gaObj);
          gaObj ={};

        });

        this.setYouMayAlsoLikeGA4Data();
        this.cdr.detectChanges();
        }
      },
      (error:any)=>{
        
      })
    }
  
  
  //This method is used to add selected may also like product to cart
  addTocart(product: any, index:any){
    this.showInput = product?.code;
    if (
      this.quantityInput[index] === 0 ||
      this.quantityInput[index] === '' ||
      this.quantityInput[index] === null ||
      this.quantityInput[index] === undefined
    ) {
      this.qtyRequired = true;
      this.maxQtyReached = false;
      this.orderMultiplerRequired = false;
    } else if ((product?.maxAllowedQuantity) && (this.quantityInput[index] > product?.maxAllowedQuantity) ) {
      this.maxQtyReached = true;
      this.qtyRequired = false;
      this.orderMultiplerRequired = false;
    } else if (
      product?.variantOptions[0]?.quantityMultiplier &&
      this.quantityInput[index] %
      product?.variantOptions[0]?.quantityMultiplier !==
        0
    ) {
      this.orderMultiplerRequired = true;
      this.maxQtyReached = false;
      this.qtyRequired = false;
    } else {
      this.orderMultiplerRequired = false;
      this.maxQtyReached = false;
      this.qtyRequired = false;

      let entries = [];
      let obj = {
        "product": {
          "code": product?.variantsSize === 1 ? product?.variantOptions[0]?.code : product?.code
        },
        "quantity": + this.quantityInput[index]
      }
      entries.push(obj);

      this.windowRef?.sessionStorage?.removeItem('gaItemsForCartUpdate');
      this.windowRef.sessionStorage?.removeItem('totalForCartUpdate');
      let gaItem:any = {};
      gaItem = product?.gaItems;
      gaItem = { ...gaItem, discount: null, index: null , price: product?.variantsSize === 1 ? product?.price?.value : null , quantity: this.quantityInput[index], item_list_id: 'related_list', item_list_name: 'Related List'};
      this.windowRef.sessionStorage?.setItem('gaItemsForCartUpdate' , JSON.stringify([gaItem]));
      this.windowRef.sessionStorage?.setItem('totalForCartUpdate' , JSON.stringify(gaItem?.price * parseInt(gaItem?.quantity)));

       //calling addProductToCart method from cart service
       this.quickOrderService.getCartIdSpecificProduct(obj, 'search', this.userId);
       this.quickOrderService.specificProduct$.subscribe((resp: any) => {
        if (resp?.statusCode == "maxOrderQuantityExceeded") {
          this.maxQtyReached = true;
          this.qtyRequired = false;
          this.orderMultiplerRequired = false;
          this.cdr.detectChanges();
        }
      })
    }
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.products.currentValue>changes.products.previousValue){
    this.products=this.products.filter((data:any)=>data?.code!==changes?.products?.currentValue);
    }
  }

    // quantity incremnet and decrement  functionality
    increment(index: any){
      this.productSer.increment(index, this.products,this.quantityInput);
    }
  
    decrement(index: any){
      this.productSer.decrement(index, this.products,this.quantityInput);
      if(this.quantityInput[index] == 0 ){
        this.showInput = null;
      }
    }

    //This method is used to redirect to PDP page 
    navigateToPDP(product:any){
      // let url: string = this.windowRef.location.origin + decodeURIComponent(path);
      // this.windowRef.location.href = url;
      let gaDataForViewItem = product?.gaItems;
      gaDataForViewItem = { ...gaDataForViewItem, discount: null, index: null , price: product?.variantsSize === 1 ? product?.price?.value: null , quantity: null, item_list_id: 'related_list', item_list_name: 'Related List'};
      this.gtmService.setSelectItemData(gaDataForViewItem);
      this.router.navigate([product?.url]);

    }

    //This method is used to add product to wishList
    addProductToWishlist(product: any) {
      if(product.variantOptions?.length && product.variantOptions[0]?.code) {
        let gaObj = product.variantOptions[0].gaItems;
        gaObj = { ...gaObj, discount: null, index: null , price: product?.variantsSize === 1 ? product.price?.value : null , quantity: 1, item_list_id: 'related_list', item_list_name: 'Related List'};
        this.productSer.addProductToWishlist(product?.variantOptions[0]?.code, this.themeClass, gaObj);
      }
    }

    onLoginClick(){
      this.windowRef.localStorage?.setItem('loginClickedFromPDP', 'true');
      this.productSer.onLoginClick(this.themeClass);
    }
    onPaste(event: any) {
      this.inputValidationService.onPaste(event);
    }

    setYouMayAlsoLikeGA4Data() {
      let dataObj:any;
        dataObj = {
          'event': 'view_item_list',
          'ecommerce': {
            'item_list_id': "related_list",
            'item_list_name': "Related List",
            items: this.gaProductArray
        }
      }
      this.gtmService.removeDataLayer();
      this.gtmService.writeToDataLayer(dataObj);
    }

    ngOnDestroy(): void {
      
      if(this.userSub) {
        this.userSub.unsubscribe();
      }
    
      if(this.productServiceSub){
        this.productServiceSub.unsubscribe();
      }

      this.windowRef.localStorage?.removeItem('loginClickedFromPDP');
    }
}
