/*
 * Breadcrumbs constants
 */

export const Breadcrumbs: any = {
  myAccount: 'myAccount',
  rapidOrder: 'Rapid Order',
  orderHistory: 'myAccount,orderHistory',
  approvalOrders: 'myAccount,orderApprovals',
  businessAccounts: 'myAccount,myBusinessAccounts',
  clientsAddresses: 'myAccount,myClients&Addresses',
  quickOrder: 'quickOrder',
  favoriteLists: 'myAccount,favoriteLists',
  itemPurchaseActivity: 'myAccount,itemsPurchaseActivity',
  itemPurchaseActivityReport: 'myAccount,itemsPurchaseActivity,itemsPurchaseActivityReport',
  advancedEbill: 'myAccount,advancedeBill',
  advancedEbillReport: 'myAccount,advancedeBill,advancedeBillReport',
  downloadReports: 'myAccount,reportDownloads',
  userIdAndPreferences: 'myAccount,userIdAndPreferences',
  signInAndSecurity: 'myAccount,signInAndSecurity',
  usersAdmin: 'myAccount,userAdmin',
  createNewUser:'myAccount,userAdmin,createNewUser',
  manageUser:'myAccount,userAdmin,manageUser',
  paymentTypes: 'myAccount,paymentTypes',
  manageFavoritesProductList: 'myAccount,favoriteLists',
  cart: 'cart',
  categoryListing: 'allCategories',
  brandsListing: 'brands',
  manageAccount: 'myAccount,manageAccount',
  orderDetail: 'myAccount,orderHistory',
  orderRequiringApproval: 'myAccount,orderApprovals',
  editOrderOther: 'myAccount,orderApprovals,ordersRequiringApproval',
  editOrderUnapproved: 'myAccount,orderHistory,orderDetail',
  backorder: 'myAccount,backorder',
  backorderReport: 'myAccount,backorder,backorderReport',
  bulkPODExport: 'myAccount,proofOfDeliveryBulkExport',
  onlineBillPay:'myAccount,onlineBillPay',
  frequentlyPurchased:'myAccount,frequentlyPurchased',
  categorySpecific:'categoryCode',
  rewards: 'myAccount,rebatesIncentives &Rewards',
  rewardsDetails:'myAccount,rebatesIncentives &Rewards',
  accountSettings: 'myAccount,Settings,AccountSettings',
  help: 'Help',
  returns: 'Orders, Returns',
  'my-account': "My Account",
  changePassword: 'myAccount,User,Settings,Password',
  products: 'Products,ProductList',
  notFound: 'Not Found',
  home:'Home',
  product: 'Product',
  inventoryByLocation:'Inventory,Inventory by Location',
  inventoryByStatus:'Inventory,Inventory by Status',
  manufacturerSKU:'Inventory,Manufacturer SKU Inventory',
  inventoryByTransaction:'Inventory, Inventory Transaction History',
  receiving: 'Receiving,openWarehouseReceivingOrders',
  orders: 'Orders,Transaction History',
  reports: 'Reports, List',
  quarantineProduct:'Inventory, Quarantine Product',
  receivingReceipt: 'Receiving,warehouseReceipts',
  wroDetails: 'Receiving,Open Warehouse Receiving Orders',
  newItemSetup: 'Products,New Item Setup',
  orderDetails: 'Order, Transaction History',
  wroList: 'Receiving, Open Warehouse Receiving Orders',
}

export const Routes: any = {
  myAccount: 'my-account',
  orderHistory: 'my-account/orderHistory',
  orderDetail: 'my-account/order',
  orderApprovals: 'my-account/approvalOrders',
  userAdmin: 'my-account/userAdmin',
  favoriteLists: 'my-account/favoriteLists',
  itemsPurchaseActivity: 'my-account/itemPurchaseActivity',
  advancedeBill: 'my-account/advanced-ebill',
  ordersRequiringApproval: 'my-account/orderApprovalDetails',
  backorder: 'my-account/backorderSearch',
  frequentlyPurchased:'my-account/frequentlyPurchased',
  'Manufacturer Rebates': 'my-account/rewards-credits',
  Orders: 'orders',
  Products: 'product-list',
  Inventory: 'inventory',
  Receiving: 'receiving',
  User: 'account-configuration',
  Reports: 'reports'

}