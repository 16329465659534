import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { formatPhonePipe } from 'src/app/shared/pipes/format-phone';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { CustomValidationService } from 'src/app/shared/services/common/custom-validation.service';

interface AccountRepDiscussType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-my-account-rep-dialog',
  templateUrl: './my-account-rep-dialog.component.html',
})
export class MyAccountRepDialogComponent implements OnInit {
  user: User;
  userSub: Subscription;
  themesForScroll: any = this.document.getElementsByClassName(
    'cdk-global-overlay-wrapper'
  );

  accountRepDiscussList: AccountRepDiscussType[] = [
    { value: 'accountQues', viewValue: 'Accounts Questions' },
    { value: 'billingOrPaymentQues', viewValue: 'Billing/ Payment Questions' },
    { value: 'orderStatusQues', viewValue: 'Order Status Questions' },
    {
      value: 'productOrAvailabilityQues',
      viewValue: 'Product/ Availability Questions',
    },
    { value: 'returnQues', viewValue: 'Returns Questions' },
    {
      value: 'shippingOrDeliveryQues',
      viewValue: 'Shipping / Delivery Questions',
    },
    { value: 'vendorQues', viewValue: 'Vendor Questions' },
    { value: 'otherQues', viewValue: 'Other/ Miscellaneous Questions' },
  ];

  myAccountRepForm: any;

  constructor(
    private formBuilder: FormBuilder,
    private customValidator: CustomValidationService,
    private dialogRef: MatDialogRef<MyAccountRepDialogComponent>,
    private customHttp: BoxoutEndpointService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(DOCUMENT) private document: Document,
    private store: Store<fromApp.AppState>,
    private formatNumber: formatPhonePipe,
  ) {}

  ngOnInit(): void {

    this.userSub = this.store.select('auth').subscribe(user => { 
      this.user = user && user.user!;
    });

    this.createMyAccountRepForm();

    //scroll theme for reusable shpping to address dialog
    this.themesForScroll[0].className += ' custom-modal';
  }

  createMyAccountRepForm() {
    this.myAccountRepForm = this.formBuilder.group(
      {
        firstName: ['', [Validators.required]],
        accountRepDiscuss: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.maxLength(50)]],

        phoneNumber: ['', [Validators.required, Validators.minLength(10),Validators.maxLength(10)]],
        mobileNumber: ['',[Validators.minLength(10),Validators.maxLength(10)]],
        comments: ['', [Validators.required]],
      },
      {
        validator: [this.customValidator.validEmail('email')],
      }
    );
  }

  myAccountRepSubmit() {
    if (this.myAccountRepForm?.valid) {
      this.submitFinalForm();
    } else {
      this.myAccountRepForm?.markAllAsTouched();
      const firstElementWithError = document.querySelector('.ng-invalid');

      if (firstElementWithError) {
        firstElementWithError.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  submitFinalForm() {
    const apiURL = `/contactUs`;
    let payload={
      accountName: this.user?.name ? this.user?.name : "",
      accountNumber: this.user?.orgUnit?.siebelRowId ? this.user?.orgUnit?.siebelRowId : "" ,
      comments: this.myAccountRepForm.get('comments')?.value,
      email: this.myAccountRepForm.get('email')?.value,
      firstName: this.myAccountRepForm.get('firstName')?.value,
      message: this.myAccountRepForm.get('accountRepDiscuss')?.value,
      phone: this.formatNumber.transform(this.myAccountRepForm.get('phoneNumber')?.value),
      salesRepEmail : (this.data?.value?.salesRepEmail) ? (this.data?.value?.salesRepEmail) : ""
    }
    this.customHttp.post(apiURL, payload ).subscribe(
      (resp: any) => {
        if (resp?.success === 'Success') {
          this.dialogRef?.close();
        } else if (resp?.success === 'Fail' || resp?.isSuccess === false) {
        }
      },
      (error: any) => {}
    );
  }
}
