import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BoxoutEndpointService } from '../../services/common/boxout-endpoint.service';
import { CustomValidationService } from '../../services/common/custom-validation.service';
@Component({
  selector: 'app-notify-stock-slider',
  templateUrl: './notify-stock-slider.component.html',
})
export class NotifyStockSliderComponent implements OnInit {
  notifyStockForm: any;
  emailRegex = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[A-Za-z]{2,15}';
  checked: any = false;
  user: any;
  productData: any;
  notifyMeCallSuccess:boolean=false;
  notifyMeCallFailure:boolean=false;
  code:any="";
  baseProduct: any;

  constructor(private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
    private customHttp: BoxoutEndpointService, private customValidator: CustomValidationService,
    private dialogRef: MatDialogRef<NotifyStockSliderComponent>,) { }

  ngOnInit(): void {
    this.productData = this.data?.product;
    this.user = this.data?.user;
    this.code =  this.data?.product?.variantsSize && this.data?.product?.variantsSize === 1 ? (this.data?.product?.variantOptions ? this.data?.product?.variantOptions[0]?.code : this.data?.product?.baseOptions?.length !==0 ?  this.data?.product?.baseOptions[0]?.selected?.code:this.data?.product?.code):this.data?.product?.code;
    this.baseProduct = this.data?.baseProduct;
    if(this.productData?.images && this.productData?.images?.length ===0 && this.baseProduct){
      this.productData.images = this.baseProduct.images;
    }
    this.createSalesRepCallMeForm();
  }
  createSalesRepCallMeForm() {
    this.notifyStockForm = this.formBuilder.group({
      email: [(this.user.displayUid || ''), [Validators.required, Validators.pattern(this.emailRegex)]],
      comments: [(""), [Validators.maxLength(240)]],
    },
    {
      validator: [
        this.customValidator.validEmail(
          'email'
        )
      ],
    });
  }
  submitNotifyForm() {

    if (this.notifyStockForm?.valid) {
      this.callNotifyMe();
    } else {
      this.notifyStockForm.markAllAsTouched();
    }

  }
  callNotifyMe() {
    this.notifyMeCallSuccess = false;
    this.notifyMeCallFailure = false;
    let formsValue = this.notifyStockForm.getRawValue();
    let obj = {
      "brandName": this.baseProduct?.brandData?.name || this.data?.product?.brandData?.name,
      "comments": formsValue?.comments,
      "email": formsValue?.email,
      "price": this.data?.price[0]?.price,
      "productCode": this.code,
      "productImage": (this.productData?.imageGallery && this.productData?.imageGallery[0] && this.productData?.imageGallery[0]?.primaryImage) ? this.productData?.imageGallery[0]?.primaryImage : (this.productData?.images && this.productData?.images[0] && this.productData?.images[0]?.url) ? this.productData?.images[0]?.url : (this.baseProduct?.imageGallery?.length ? this.baseProduct?.imageGallery[0]?.primaryImage: ''),
      "quantityEntered": this.data?.qty,
      "salesRepNotifyFlag": this.checked,
      "salesUnit":  this.productData?.unit ? this.productData?.unit : (this.productData?.baseOptions && this.productData?.baseOptions[0] 
                     && this.productData?.baseOptions[0]?.selected?.unit) ? this.productData?.baseOptions[0]?.selected?.unit : this.productData?.salesUnit,
      "productName": this.baseProduct?.name || this.data?.product?.name,
      "url": this.productData?.url || '',
      "currency":  this.data?.price[0]?.currency,
      "cent":  this.data?.price[0]?.cent,
      "family" : this.baseProduct?.code || this.data?.product?.code
    }
    if(obj.productImage){
      obj.productImage = this.data?.imageUrl + obj.productImage;
    }
    const apiURL = `/orgProducts/notify-user?fields=DEFAULT`;
    this.customHttp.post(apiURL, obj).subscribe((resp: any) => {
      if (resp?.success === "Success") {
        this.notifyMeCallSuccess = true;
        this.notifyMeCallFailure = false;
        this.dialogRef.close();
      } else if (resp?.success === "Fail" || resp?.isSuccess === false) {
        this.notifyMeCallSuccess = false;
        this.notifyMeCallFailure = true;
      }
    },
      (error: any) => {
        this.notifyMeCallSuccess = false;
        this.notifyMeCallFailure = true;
      });
   }
}
