import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { CmsNavigationComponent } from '@spartacus/core';
import { CmsComponentData, NavigationNode } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CustomNavigationService } from 'src/app/shared/services/navigation.service';

@Component({
  selector: 'app-custom-category-navigation',
  templateUrl: './custom-category-navigation.component.html',
})
export class CustomCategoryNavigationComponent {

  navBarData: any;
  setLinkTarget: any;


  node$: Observable<NavigationNode> = this.service.getNavigationNode(
    this.componentData.data$
  );

  data$: Observable<CmsNavigationComponent> = this.componentData.data$;

  constructor(
    protected componentData: CmsComponentData<CmsNavigationComponent>, 
    protected service: CustomNavigationService,  @Inject(DOCUMENT) private document: Document
  ) {
  }

  removeBackdrop() {
    this.document.getElementById('custom-backdrop')?.classList.remove('show-backdrop');
    this.document.getElementById('custom-backdrop')?.classList.add('hide-backdrop');
  }

}
