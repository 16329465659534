import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class CustomValidationService {
  constructor() { }

  /* This method is used to check if two email matches */
  emailMatchValidator(email: string, reEnterEmail: string) {
    return (formGroup: FormGroup) => {
      const emailControl = formGroup.controls[email];
      const reEnterEmailControl = formGroup.controls[reEnterEmail];

      if (!emailControl || !reEnterEmailControl) {
        return null;
      }

      if (
        reEnterEmailControl.errors &&
        !reEnterEmailControl.errors.emailMismatch
      ) {
        return null;
      }

      if (emailControl.value.toLowerCase() !== reEnterEmailControl.value.toLowerCase()) {
        reEnterEmailControl.setErrors({ emailMismatch: true });
        return true;
      } else {
        reEnterEmailControl.setErrors(null);
        return null;
      }
    };
  }

  /* This method is used to check if two password matches */
  passwordMatchValidator(password: string, confirmPassword: string) {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls[password];
      const confirmPasswordControl = formGroup.controls[confirmPassword];

      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }

      if (!passwordControl.value || !confirmPasswordControl.value) {
        return null;
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
        return true;
      } else {
        confirmPasswordControl.setErrors(null);
        return null;
      }
    };
  }

  /* This method id used to check if email is valid */
  validEmail(email: string) {
    return (formGroup: FormGroup) => {
      const emailControl = formGroup.controls[email];

      const emailValue = emailControl.value;
      if (!emailValue) {
        return null;
      }

      const invalidEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,15}$/.test(emailValue);

      if (!invalidEmail) {
        emailControl.setErrors({
          emailIsInvalid: invalidEmail,

        });
        return true;
      } else {
        emailControl.setErrors(null);
        return null;
      }
    };


  }


  /* This method id used to check strength of entered password  */
  validPassword(password: string) {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls[password];

      const passValue = passwordControl.value;
      if (!passValue) {
        return null;
      }

      const hasUpperCase = /[A-Z]+/.test(passValue);

      const hasLowerCase = /[a-z]+/.test(passValue);

      const hasNumeric = /[0-9]+/.test(passValue);

      const hasRequiredLength = passValue.length >= 8 && passValue.length <= 20;

      const hasSpecialChar = /[*.!?@#$%^&/~_+-=|)({}[:;<>,.]+/.test(passValue);

      const passwordValid =
        hasUpperCase &&
        hasLowerCase &&
        hasRequiredLength &&
        (hasNumeric || hasSpecialChar);

      if (!passwordValid) {
        passwordControl.setErrors({
          passwordHasUpperCase: hasUpperCase,
          passwordHasLowerCase: hasLowerCase,
          passwordHasNumeric: hasNumeric,
          passwordHasRequiredLength: hasRequiredLength,
          passwordHasSpecialChar: hasSpecialChar,
        });
        return true;
      } else {
        passwordControl.setErrors(null);
        return null;
      }
    };
  }

  //ValidateCardConnectTokenService.tokenizeCardNumber
  validatePaymentCard(token: string) {
    return () => {
      if (token === '') {
        return { 'cardNumberInvalid': true };
      } else {
        return null;
      }
    }
  }

  validatePaymentCardType(cardType: any) {
    return () => {
      if (!cardType) {
        return { 'cardTypeInvalid': true };
      } else {
        return null;
      }
    }
  }

  validEndDate(startDate: any, endDate: any) {
    return (formGroup: FormGroup) => {
      const endDateControl = formGroup.controls[endDate];
      const starteDateControl = formGroup.controls[startDate];

      if (endDateControl?.value && starteDateControl?.value) {
        if (endDateControl?.value < starteDateControl?.value) {
          endDateControl?.setErrors({ notValid: true });
          return true;
        } else {
          starteDateControl?.setErrors(null);
          endDateControl?.setErrors(null);
          return null;
        }

      } else {
        endDateControl?.setErrors(null);
        starteDateControl?.setErrors(null);
        return null;
      }
    }
  }

  validDate(startDate: any, endDate: any) {
    return (formGroup: FormGroup) => {
      const endDateControl = formGroup.controls[endDate];
      const starteDateControl = formGroup.controls[startDate];

      if (endDateControl?.value && starteDateControl?.value) {
        if (endDateControl?.value < starteDateControl?.value) {
          endDateControl?.setErrors({ notValid: true });
          return true;
        } else if (endDateControl?.value?.getTime() - starteDateControl?.value?.getTime() > 0) {
          let Difference_In_Time = endDateControl?.value?.getTime() - starteDateControl?.value?.getTime();
          // To calculate the no. of days between two dates
          let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
          if (Difference_In_Days > 31) {
            starteDateControl?.setErrors({ rangeIncorrect: true });
            endDateControl?.setErrors({ rangeIncorrect: true });
            return true;
          } else {
            starteDateControl?.setErrors(null);
            endDateControl?.setErrors(null);
            return null
          }
        }
        else {
          starteDateControl?.setErrors(null);
          endDateControl?.setErrors(null);
          return null;
        }

      } else {
        if(!endDateControl?.value) {
          endDateControl?.setErrors({ required: true });
        } else if(!starteDateControl?.value) {
          starteDateControl?.setErrors({ required: true });
        } else{
          endDateControl?.setErrors({ required: true });
          starteDateControl?.setErrors({ required: true });
        }

        return null;
      }
    }
  }

  //check if expiry moth and year are valid
  expiryCheckValidator(expMonth: any, expYear: any) {
    return (formGroup: FormGroup) => {
      const expMonthControl = formGroup.controls[expMonth];
      const expYearControl = formGroup.controls[expYear];
      let currentYear = new Date().getFullYear();
      //check if exp year is greater than current year
      if ((parseInt(expMonthControl.value) < (new Date().getMonth() +1)) && (parseInt(expYearControl.value) === currentYear)) {
        expMonthControl?.setErrors({ expNotValid: true });
        return true;
      }
      else if ((parseInt(expYearControl.value) < currentYear)) {
        expYearControl?.setErrors({ expNotValid: true });
        return true;
      }else if ((parseInt(expMonthControl.value) >= (new Date().getMonth() +1)) && (parseInt(expYearControl.value) >= currentYear)) {
        expMonthControl?.setErrors(null);
        expYearControl?.setErrors(null);
        return null;
      } else {
        expMonthControl?.setErrors(null);
        expYearControl?.setErrors(null);
        return null;
      }
    }
  }
}
