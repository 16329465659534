import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AsmGuard implements CanActivate {
  constructor(
    private csAgentAuthService: CsAgentAuthService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  canActivate(): Observable<boolean> {
    return this.csAgentAuthService.isCustomerSupportAgentLoggedIn().pipe(
      map((isAsmUserLoggedIn: boolean) => {
        if (!isPlatformBrowser(this.platformId)) {
          this.router.navigate(['/ssr-intermittent'], {
            skipLocationChange: true,
          });
          return false;
        } else {
          if (!isAsmUserLoggedIn) {
            this.router.navigate(['/sales-rep/login']);
            return false;
          }
          return true;
        }
      })
    );
  }
}
