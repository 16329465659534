<div class="switch-account-dialog" *ngIf="accountList">
    <div class="row">
        <div class="d-flex justify-content-between pl-0 text-center px-2 w-100">
            <div class="img-logo">
                <img src="../../../../../assets/logo/{{baseSiteNameVal}}.svg" alt="BoxOut-logo">
            </div>
                <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon" >close</mat-icon>
          </div>
    </div>
    <div class="row px-5">
        <div class="col text-center">
            <p class="mt-4 pt-2 t-headline-m">{{"global.switchAcoount.headline" | cxTranslate}}</p>
            <p class="mb-0 t-title-m">
                {{"global.switchAcoount.paraText" | cxTranslate}}
                {{accountList?.length}}
                {{"global.switchAcoount.paraTextOne" | cxTranslate}}
            </p>
            <p class="mb-0 t-title-m">{{"global.switchAcoount.paraSubText" | cxTranslate}}</p>
        </div>
    </div>
    <form class="px-5">
        <div class="row mt-4 pt-1">
            <div class="col">
                <p class="switch-account-dialog-field-alignment">
                    <mat-form-field #materialFormField appearance="outline" class="switch-account-dialog-tab-padding">
                        <mat-label appRemoveAriaOwns class="ml-2">Account</mat-label>
                        <mat-icon svgIcon="profile_outline" matPrefix></mat-icon>
                        <mat-select 
                            [(value)]="selectedCode"
                            disableOptionCentering 
                            panelClass="switch-account-dialog-myTopClass"
                        >
                            <mat-option class="switch-account-dialog-option-custom-left-padding" *ngFor="let a of accountList" [value]="a.code">
                              {{a?.siebelRowId}} | {{a?.name}}
                            </mat-option>
                          </mat-select>
                    </mat-form-field>
                </p>
                <p class="text-center mb-3">
                    <button 
                      mat-flat-button 
                      class="switch-account-dialog-continue-Button" 
                      color="primary"
                      type="button"
                      (click) = "onContinueClick()">
                      Continue
                    </button>
                </p>
            </div>
        </div>
    </form>
</div>
