import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CurrentThemeService } from '../../services/common/current-theme.service';
import { SmartyAddressValidationService } from '../../services/common/smarty-address-validation.service';
import { CreateClientAcctComponent } from '../create-client-acct/create-client-acct.component';

interface ClentType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-select-client-type',
  templateUrl: './select-client-type.component.html',
})
export class SelectClientTypeComponent implements OnInit {

  clientTypeList: ClentType[]=[
    {value:"consumer",viewValue:"Consumer"},
    {value:"business",viewValue:"Business"}
  ];

  selectClientTypeForm:any;
  currentTheme: any;

  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SelectClientTypeComponent>,
    public dialog: MatDialog,private theme: CurrentThemeService,
    private smartyAddressValidationService: SmartyAddressValidationService,
    @Inject(MAT_DIALOG_DATA) public data:any) { }

  ngOnInit(): void {

    this.selectClientTypeForm = this.formBuilder.group({
      clientType:["",[Validators.required]]
    });

    /** Taking themes from themeservice **/
    this.currentTheme = this.theme?.getCurrentTheme();

  }

  selectClientType(){

    if(this.selectClientTypeForm.valid){

    //setting form data null for avoiding multiple call 
    this.smartyAddressValidationService.saveFormData(null);

    this.dialogRef.close();
    
      this.dialogRef.afterClosed().subscribe((res)=>{

        //BCGI2-796: client or patient ship to address changes
        if(this.data?.value?.action === 'addingNewClientOrPatientShipToAccount'){
          this.dialog.open(CreateClientAcctComponent,
            {
              panelClass: [this.currentTheme, 'ship-to-client-drawer-for-all','cart-add-business'],
              width:'368px',
              height:'100%',
              position: {
                right: '0px',
                top: '0px'
             },
              data:  {
                value:{
                  action:this.data?.value?.action,
                  clientType:this.selectClientTypeForm?.value?.clientType
                }
             }
            });
        }else{
          this.dialog.open(CreateClientAcctComponent,
            {
              panelClass: [this.currentTheme, 'business-account-dialogs-style'],
              autoFocus: false,
              disableClose: true,
              data:  {
                value:{
                  clientType:this.selectClientTypeForm?.value?.clientType
                }
             }
            });
        }
        
      });

    }else{
       //invalid form alert
       this.selectClientTypeForm.markAllAsTouched();
       const firstElementWithError = document.querySelector('.ng-invalid');

      if (firstElementWithError) {
        firstElementWithError.scrollIntoView({ behavior: 'smooth' });
      }
    }

  }

}
