import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { BoxoutMaterialModule } from 'src/app/custom/boxout-material/boxout-material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { RewardsCreditsComponent } from '../rewards-credits/rewards-credits.component';
import { PageSlotModule } from '@spartacus/storefront';
import { RewardsCreditsMainComponent } from 'src/app/feature/components/my-account/rewards-credits-main/rewards-credits-main.component';


const routes: Routes = [
  {
    path: '',
    data: { pageLabel: 'rewards-credits' },
    component: RewardsCreditsComponent,
    children: []
  },
];

@NgModule({
  declarations: [
    RewardsCreditsComponent, RewardsCreditsMainComponent
  ],
  imports: [
    CommonModule,
    PageSlotModule,
    I18nModule,
    BoxoutMaterialModule,
    SharedModule,
    RouterModule.forChild(routes),
    NgxPaginationModule,
    FormsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        RewardsAndCredits: {
          component: () => import('../../../../../feature/components/my-account/rewards-credits-main/rewards-credits-main.component').then(m => m.RewardsCreditsMainComponent)
        },
        RewardsAndCreditsDetails: {
          component: () => import('../../../../../feature/components/my-account/rewards-credits-details-main/rewards-credits-details-main.component').then(m => m.RewardsCreditsDetailsMainComponent)
        }
      }
    } as CmsConfig),
    
  ],
  exports: [
    RewardsCreditsComponent , RewardsCreditsMainComponent
  ]
})
export class RewardsCreditsModule { }
