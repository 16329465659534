import { ChangeDetectorRef, Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject, BehaviorSubject } from 'rxjs';
import { SiteContextConfig, WindowRef } from '@spartacus/core';
import { CookieService } from 'ngx-cookie-service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class FullScreenToggleService {
  constructor(
    private windowRef: WindowRef,
    private cookieService: CookieService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  toggleScreen(value: any) {
    const isFullScreen = value;
    if (value) {
      this.document
        .getElementsByClassName('lgScreen')[0]
        ?.classList.remove('expand-table');
      this.document
        .getElementsByClassName('lgScreen')[0]
        ?.classList.remove('p-4');
      this.document.getElementsByClassName('sr-home-layout')[0]?.classList.remove('d-none');

      this.document
        .getElementsByTagName('body')[0]
        .classList.remove('hide-scroll');
    } else {
      this.document
        .getElementsByClassName('lgScreen')[0]
        ?.classList.add('expand-table');
      this.document.getElementsByClassName('lgScreen')[0]?.classList.add('p-4');
      // this is done to hide the top nav so that it wont overlap in iPhones
      this.document.getElementsByClassName('sr-home-layout')[0]?.classList.add('d-none');

      this.document
        .getElementsByTagName('body')[0]
        .classList.add('hide-scroll');
    }
  }
}
