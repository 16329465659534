<div class="warning-container">
    
    <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon clear-cart-modal-close" color="white">close</mat-icon>
    <div class="d-flex flex-column align-items-center justify-content-center pt-2 pb-3">
        <div class="warning-icon mb-3">
            <mat-icon svgIcon="warningIcon" class="warning-icon-style" aria-label="Warning Icon"></mat-icon>
        </div>

        <div class="t-headline-m mb-3">{{'cart.warning' | cxTranslate}}</div>

        <div *ngIf="componentName === 'Cart'" class="warning-description t-title-m mb-3 d-flex justify-content-center px-4">
            {{'cart.clearCartWarningMsg' | cxTranslate}}</div>
        <div *ngIf="componentName === 'Client'" class="warning-description t-title-m mb-3 d-flex justify-content-center px-4">
            {{'cart.clearShipToMsg' | cxTranslate}}</div>

        <div class="d-flex flex-row">
            <div>
                <button *ngIf="componentName === 'Cart'"  mat-flat-button color="primary" type="button" class="mr-3 t-label-l" [mat-dialog-close]="true" (click)="clearCart()">
                    {{'cart.continue' | cxTranslate}}</button>
                <button *ngIf="componentName === 'Client'"  mat-flat-button color="primary" type="button" class="mr-3 t-label-l" (click)="clearShipTo()">
                    {{'cart.continue' | cxTranslate}}</button>
            </div>
            <div>
                <button mat-flat-button color="white" type="button" class="t-label-l" [mat-dialog-close]="true" >
                    {{'cart.cancel' | cxTranslate}}
                </button>
            </div>
        </div>

    </div>

</div>