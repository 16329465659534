<div class="add-ship-to-client-patient-container">
  <div
    class="add-ship-to-address-header-border d-flex flex-row justify-content-between align-items-center mb-2"
  >
  <div class="d-flex flex-row align-items-center justify-content-center" [mat-dialog-close]="'backToShipToClientPatientDialogFromAddAddress'" 
       (click)="backToShipToClientPatientDialogFromAddAddress()">
    <div class="mr-1"><mat-icon svgIcon="back"></mat-icon></div>
    <div class="t-headerLink-normal">Add New Ship To Address</div>
  </div>
    <div class="mt-1">
      <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon" color="white">close</mat-icon>
    </div>
  </div>


  <div class="d-flex flex-column p-2">
    <form [formGroup]="addShipToAddressFormForClientPatient" (submit)="addShipToaddressForClientPatient()">

      <div class="create-business-account-input-display mb-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "account.createAccount.attnTitle" | cxTranslate }}
          </mat-label>
          <input
            matInput
            type="text"
            class="create-business-account-input-outline"
            formControlName="attn"
            maxlength="30"
          />
          <mat-error></mat-error>
        </mat-form-field>
      </div>

      <div>
        <app-reusable-address-form [action]="data?.value?.action"></app-reusable-address-form>
      </div>


      <div class="create-business-account-input-display">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "account.createAccount.deliverNotesTitle" | cxTranslate }}
          </mat-label>
          <input
            matInput
            type="text"
            class="create-business-account-input-outline"
            formControlName="deliverNote"
            maxlength="60"
          />
          <mat-error></mat-error>
        </mat-form-field>
      </div>

      <div class="d-flex flex-row mb-3">

        <div class="d-flex mr-3">
          <div class="t-body-s edit-ship-to-toggle-ship">{{ "account.createAccount.defaultShippingToTitle" | cxTranslate }}</div>
            <mat-slide-toggle formControlName="defaultShippgTo" 
            [disabled]="data && data.value && data.value.defaultShippgTo"
            [(ngModel)]="addShipToAddressFormForClientPatient.get('defaultShippgTo').value"
              class="edit-mat-toggle-status"
              color="primary">{{addShipToAddressFormForClientPatient.get('defaultShippgTo').value ? 'On' : 'Off'}}</mat-slide-toggle>      
        </div>

        <!-- Default Bill To toggle should be shown for PARENT with customer role Admin and on client pages-->
        <!-- <div class="d-flex" *ngIf="userType?.toUpperCase() === 'PARENT' && customerRole === 'Admin'
        && !(data?.value?.componentName === 'myClientAddress' || data?.value?.componentName === 'manageClientAddress')
        && !(data?.value?.accountType === 'DROPSHIP' || data?.value?.manageAccountType === 'DROPSHIP')">
          <div class="t-body-s edit-ship-to-toggle-bill">{{ "account.createAccount.defaultBillToTitle" | cxTranslate }}</div>
            <mat-slide-toggle formControlName="defaultBillingTo"
            [(ngModel)]="addShipToAddressFormForClientPatient.get('defaultBillingTo').value"
             class="edit-mat-toggle-status"
            color="blue">{{addShipToAddressFormForClientPatient.get('defaultBillingTo').value ? 'On' : 'Off'}}</mat-slide-toggle>      
        </div> -->

      </div>

      <div class="d-flex justify-content-end">
        <div>
          <button
            mat-flat-button mat-dialog-close
            type="button"
            
            class="mr-2"
          >
          {{ "account.createAccount.cancelA" | cxTranslate }}
          </button>
        </div>
        <div class="add-shipTo-client-address-submit-button">
          <button
            mat-flat-button
            type="submit"
          >
          {{ "account.createAccount.addAddress" | cxTranslate }}
          </button>
        </div>
      </div>
    </form>
  </div>

  

</div>

