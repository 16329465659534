<!-- Login Component- SignIn Page -->

<div class="login-dialog" *ngIf=boolSignIn>
    <div class="row w-100 justify-content-between m-0">
      <div>
        <img  src="../../../../../assets/logo/{{baseSiteNameVal}}.svg" class="login-dialog-image" alt="BoxOut-logo">
      </div>
      <div
        (click)="closeDialog()"><mat-icon class="close-icon cursor-pointer dialogCloseIcon" >close</mat-icon>
      </div>
    </div>
    <div class="row">
      <div class="col pb-0 text-center">
        <p class="mt-4 pt-2 t-headline-m">
          {{"global.signIn.signInText" | cxTranslate}}
        </p>
        <p class="mb-0 t-title-m mx-auto login-dialog-main-heading">{{"global.signIn.paraText" | cxTranslate : {siteName: siteName} }}</p>
      </div>
    </div>
    <form [formGroup]="signIn" (ngSubmit)="onSignInClick()">
      <div class="row mt-3">
        <div class="col pb-0">
          <p class="mb-0 text-center">
            <mat-form-field
              [ngClass]="{'mat-form-field-invalid ng-invalid': !validEmail}"
              class="login-dialog-field-width pb-0"
              appearance="outline">
              <mat-label>{{"global.signIn.emailLabel" | cxTranslate}}</mat-label>
              <input
                matInput
                class="login-dialog-input-outline"
                placeholder="Email or user ID *"
                formControlName="userId"
                id="userId"
                (input)="valueChangeEmail()"
              >
              <mat-error *ngIf="(signIn.get('userId')?.touched &&
                signIn.get('userId')?.hasError('required') || emptyUserId) &&
                (signIn.get('userId')?.hasError('required')) ">{{"global.signIn.emailRequiredError" | cxTranslate}}
              </mat-error>
              <mat-error *ngIf="(signIn.get('userId')?.touched &&
              signIn.get('userId')?.hasError('pattern'))">{{"global.signIn.emailInvalidError" | cxTranslate}}
            </mat-error>
          </mat-form-field>
        </p>
        <div class="mat-error login-dialog-emailValidate" *ngIf="!(signIn.get('userId')?.touched &&
          signIn.get('userId')?.hasError('pattern')) && !(signIn.get('userId')?.touched &&
          signIn.get('userId')?.hasError('required')) && !validEmail"
        >{{"global.signIn.emailInvalidError" | cxTranslate}}
      </div>
        <div class="text-center mb-3">
            <div
            style="visibility:hidden;"
              id="g-recaptcha"
              class="g-recaptcha"
              [attr.data-sitekey]=recaptchaKey
              data-callback="onSubmitRecaptcha">
            </div>
            <button
              mat-flat-button
              class="login-dialog-sign-In-Button"
              color="primary"
              type="submit">
              {{"global.signIn.signIn" | cxTranslate}}
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col pb-0 text-center">
        <p class="mb-0 t-title-m-regular">{{"global.signIn.subTextOne" | cxTranslate}}</p>
        <p class="mb-0 t-title-m-regular">{{"global.signIn.subTextTwo" | cxTranslate}}</p>
        <p class="mb-0 t-title-m-regular">{{"global.signIn.subTextThree" | cxTranslate}}</p>
        <p class="mb-0 t-title-m-regular">{{"global.signIn.subTextFour" | cxTranslate}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col pb-0 text-center mt-3" (click)="isForgotPassword()">
        <p class="mb-0 t-title-m-regular">
          <a
            href="javascript:void(0);"
            class="login-dialog-send-Link"
          >
          <u>
            {{"global.signIn.forgotPassword" | cxTranslate}}
          </u>
          </a>
        </p>
        <a
          href="javascript:void(0);"
          class="login-dialog-send-Link"
        >
        <u>
          {{"global.signIn.sendLink" | cxTranslate}}
        </u>
        </a>
      </div>
    </div>
    <hr class="mb-0 login-dialog-custom-margin">
    <div class="row">
      <div class="col pb-0 text-center mt-3 mb-4 pb-2">
        <p class="mb-0 t-title-m-regular">
          {{"global.signIn.newTo" | cxTranslate : {siteName: siteName} }}
        </p>
        <!-- <p class="mb-0 t-title-m-regular">
          {{"global.signIn.solution" | cxTranslate}}
        </p> -->
        <a *ngIf="!(baseSiteNameForRegister === 'MMSITE')" href="{{ baseUrl }}/register" class="login-dialog-send-Link">
          {{"global.signIn.createAccount" | cxTranslate}}
        </a>
        <a *ngIf="baseSiteNameForRegister === 'MMSITE'" href="{{ baseUrl }}/request-account" class="login-dialog-send-Link">
          {{"global.signIn.createAccount" | cxTranslate}}
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col pb-0 text-center">
        <p class="t-label-s mx-3 px-3 mb-0 login-dialog-recaptcha-text">
          <span>
            {{"global.signIn.recaptcha" | cxTranslate}}
          </span>
          <a [routerLink]="['/privacy']" target="_blank" class="forgot-password-dialog-text-decoration-underline cursor-pointer">{{"global.signIn.privacy" | cxTranslate}}</a>
          <span>{{'global.signIn.and' | cxTranslate}}</span>
          <a [routerLink]="['/terms-of-use']" target="_blank" class="forgot-password-dialog-text-decoration-underline cursor-pointer">{{"global.signIn.termsOfUse" | cxTranslate}}</a>
        </p>
      </div>
    </div>
  </div>

  <!-- Password Page -->

  <div class="password-dialog" *ngIf=boolPassword>
    <div class="row w-100 justify-content-between m-0">
      <div>
        <img src="../../../../../assets/logo/{{baseSiteNameVal}}.svg" class="password-dialog-image" alt="BoxOut-logo">
      </div>
      <div (click)="closeDialog()">
        <mat-icon class="close-icon cursor-pointer dialogCloseIcon" color="white">close</mat-icon>
      </div>
    </div>
    <div class="row">
      <div class="col pb-0 text-center">
        <p class="mt-4 pt-2 t-headline-m">{{"global.password.passwordText" | cxTranslate}}</p>
        <p class="mb-0 t-title-m">{{"global.password.paraText" | cxTranslate}}</p>
        <p class="mb-0 t-title-m">{{this.signIn.value.userId}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col pb-0 text-center mt-3">
        <a href="javascript:void(0);" (click)="onChangeUserIdClick()">
          {{"global.password.changeUser" | cxTranslate}}
        </a>
      </div>
    </div>
    <form [formGroup]="signIn" (ngSubmit)="onContinueclick()">
      <div class="row mt-3">
        <div class="col pb-0">
          <p class="mb-0 text-center">
            <mat-form-field [ngClass]="{'mat-form-field-invalid ng-invalid': !validPassword}" class="password-dialog-field-width pb-0" appearance="outline">
              <mat-label>{{"global.password.passwordLabel" | cxTranslate}}</mat-label>
              <input
                matInput
                class="password-dialog-input-outline"
                placeholder="Password"
                formControlName="password"
                type="password"
                id="password"
                onpaste="return false;"
                (input)="valueChangePassword()"
                (keydown)="$event.keyCode!==32?$event:$event.preventDefault()"
              >
              <mat-error *ngIf="signIn.get('password')?.touched &&
                signIn.get('password')?.hasError('required') || emptyPassword">{{"global.password.passwordRequiredError" | cxTranslate}}
              </mat-error>
            </mat-form-field>
          </p>
          <div
            class="mat-error password-dialog-passwordValidate"
            *ngIf="!(signIn.get('password')?.touched &&
            signIn.get('password')?.hasError('required')) && !validPassword"
          >{{"global.password.passwordInvalidError" | cxTranslate}}
        </div>
          <p class="text-center mb-3">
            <button
              mat-flat-button
              class="password-dialog-password-Button"
              color="primary"
              type="submit"
              >{{"global.password.continueText" | cxTranslate}}
            </button>
          </p>
        </div>
      </div>
    </form>
    <h4 class="text-center d-flex flex-row  password-dialog-custom-padding">
      <span>{{"global.password.or" | cxTranslate}}
      </span>
    </h4>
    <div class="row">
      <div class="col pb-0 text-center mt-3" (click)="isForgotPassword()">
        <p class="mb-0 t-title-m-regular">
          <a
            href="javascript:void(0);"
            class="login-dialog-send-Link"
          >
          <u>{{"global.password.forgotPassword" | cxTranslate}}
          </u>
          </a>
        </p>
        <a
          href="javascript:void(0);"
          class="login-dialog-send-Link"
        >
        <u>{{"global.password.sendLink" | cxTranslate}}
        </u>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col pb-0 text-center">
        <p class="mt-4 pt-2 mb-0 t-label-s">
          {{"global.password.sslLabel" | cxTranslate}}
        </p>
        <p class="mb-0 t-label-s">{{"global.password.sslLabelOne" | cxTranslate}}</p>
      </div>
    </div>
    <hr class="mb-3 pb-3 password-dialog-custom-margin">
    <div class="row">
      <div class="col pb-0 text-center">
        <p class="t-label-s mx-3 px-3 mb-0 password-dialog-recaptcha-text">
          <span>
            {{"global.password.recaptcha" | cxTranslate}}
          </span>
          <a [routerLink]="['/privacy']" target="_blank" class="forgot-password-dialog-text-decoration-underline cursor-pointer">{{"global.password.privacy" | cxTranslate}}</a>
          <span>{{'global.password.and' | cxTranslate}}</span>
          <a [routerLink]="['/terms-of-use']" target="_blank" class="forgot-password-dialog-text-decoration-underline cursor-pointer">{{"global.password.termsOfUse" | cxTranslate}}</a>
        </p>
      </div>
    </div>
  </div>

  <!-- Forgot Password -->

  <div class="forgot-password-dialog" *ngIf=boolForgotPassword>
    <div class="row w-100 justify-content-between m-0">
      <div>
        <img src="../../../../../assets/logo/{{baseSiteNameVal}}.svg" class="forgot-password-dialog-image" alt="BoxOut-logo">
      </div>
      <div (click)="closeDialog()">
        <mat-icon class="close-icon cursor-pointer dialogCloseIcon" >close</mat-icon>
      </div>
    </div>
    <div class="row">
      <div class="col pb-0 text-center">
        <p class="mt-4 pt-2 t-headline-m">
          {{"global.forgotPassword.forgotPasswordText" | cxTranslate}}
        </p>
        <p class="mb-0 t-title-m">{{"global.forgotPassword.paraText" | cxTranslate}}</p>
        <p class="mb-0 t-title-m">{{"global.forgotPassword.paraSubText" | cxTranslate}}</p>
      </div>
    </div>
    <form [formGroup]="forgotPassword" (ngSubmit)="onSendLink()">
      <div class="row mt-3">
        <div class="col pb-0">
          <p class="mb-0 text-center">
            <mat-form-field
              [ngClass]="{'mat-form-field-invalid ng-invalid': !validEmail}"
              class="forgot-password-dialog-field-width pb-0" appearance="outline">
              <mat-label>{{"global.forgotPassword.emailLabel" | cxTranslate}}</mat-label>
              <input
                matInput
                class="forgot-password-dialog-input-outline"
                placeholder="Email or user ID *"
                formControlName="userId"
                id="userId"
                (input)="valueChangeEmail()"
              >
              <mat-error *ngIf="(forgotPassword.get('userId')?.touched &&
                forgotPassword.get('userId')?.hasError('required') || emptyUserId)
                && forgotPassword.get('userId')?.hasError('required')">{{"global.forgotPassword.emailRequiredError" | cxTranslate}}
              </mat-error>
              <mat-error *ngIf="(forgotPassword.get('userId')?.touched &&
                forgotPassword.get('userId')?.hasError('pattern'))">{{"global.forgotPassword.emailInvalidError" | cxTranslate}}
              </mat-error>
            </mat-form-field>
          </p>
          <div class="mat-error forgot-password-dialog-emailValidate" *ngIf="!(forgotPassword.get('userId')?.touched &&
            forgotPassword.get('userId')?.hasError('pattern')) && !(forgotPassword.get('userId')?.touched &&
            forgotPassword.get('userId')?.hasError('required')) && !validEmail"
          >{{"global.forgotPassword.emailInvalidError" | cxTranslate}}
          </div>
          <p class="text-center mb-3">
            <button
              mat-flat-button
              class="forgot-password-dialog-forgotPassword-Button"
              color="primary"
              type="submit"
              >
              <span class="forgot-password-dialog-custom-font-size">{{"global.forgotPassword.sendText" | cxTranslate}}</span>
            </button>
          </p>
        </div>
      </div>
      <h4 *ngIf="!data?.isThroughSalesRep" class="text-center d-flex flex-row  forgot-password-dialog-custom-padding">
        <span>
          {{"global.forgotPassword.or" | cxTranslate}}
        </span>
      </h4>
    </form>
    <div *ngIf="!data?.isThroughSalesRep" class="row">
      <div class="col pb-0 text-center my-3">
        <p class="mb-0 t-title-m-regular forgot-password-dialog-custom-font-size">{{"global.forgotPassword.rememberPass" | cxTranslate}}
          <a
            href="javascript:void(0);"
            class="forgot-password-dialog-send-Link"
            (click)="onLogIn()">
            {{"global.forgotPassword.logIn" | cxTranslate}}
          </a>
        </p>
      </div>
    </div>
    <hr *ngIf="!myBoxoutPortal" class="forgot-password-dialog-custom-margin mb-3">
    <div class="row">
      <div class="col pb-0 text-center">
        <p class="mb-3 pb-3 t-title-m">{{"global.forgotPassword.tips" | cxTranslate}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col pb-0 text-center">
        <ul class="t-body-m pb-3 pl-0">
          <li [ngClass]="{'bxd-style': myBoxoutPortal}">{{"global.forgotPassword.tipsTextOne" | cxTranslate}}</li>
          <li [ngClass]="{'bxd-style': myBoxoutPortal}">{{"global.forgotPassword.tipsTextTwo" | cxTranslate}}</li>
            <li [ngClass]="{'bxd-style': myBoxoutPortal}">
              <span>
                {{"global.forgotPassword.trouble" | cxTranslate}}
                <a
                [ngClass]="{'bxd-style-underline': myBoxoutPortal}"
                  [routerLink]="['/password-support']"
                  class="forgot-password-dialog-send-Link">
                  {{"global.forgotPassword.instructons" | cxTranslate}}
                </a>
              </span>
            </li>
        </ul>
      </div>
    </div>
    <div class="row" *ngIf="!myBoxoutPortal">
      <div class="col pb-0 text-center">
        <p class="t-label-s mx-3 px-3 mb-0 forgot-password-dialog-recaptcha-text">
          <span>
            {{"global.forgotPassword.recaptcha" | cxTranslate}}
          </span>
          <a [routerLink]="['/privacy']" target="_blank" class="forgot-password-dialog-text-decoration-underline cursor-pointer">{{"global.forgotPassword.privacy" | cxTranslate}}</a>
          <span>{{'global.forgotPassword.and' | cxTranslate}}</span>
          <a [routerLink]="['/terms-of-use']" target="_blank" class="forgot-password-dialog-text-decoration-underline cursor-pointer">{{"global.forgotPassword.termsOfUse" | cxTranslate}}</a>
        </p>
      </div>
    </div>
  </div>

  <div class="forgot-password-dialog" *ngIf=backScreen>
    <div class="row w-100 justify-content-between m-0">
      <div>
        <img src="../../../../../assets/logo/{{baseSiteNameVal}}.svg" class="forgot-password-dialog-image" alt="BoxOut-logo">
      </div>
      <div
        (click)="closeDialog()">
        <img src="../../../../../assets/Cross-Icon.svg" role="button" alt="Cancel">
      </div>
    </div>
    <div class="row">
      <div class="col pb-0 text-center">
        <p class="mt-4 pt-2 t-headline-m">
          {{"global.forgotPassword.requestRecieved" | cxTranslate}}
        </p>
        <p class="mb-0 t-title-m">{{"global.forgotPassword.requestText" | cxTranslate}}</p>
        <p class="mb-0 t-title-m">{{this.forgotPassword.value.userId}}</p>
      </div>
    </div>
    <form (ngSubmit)="closeDialog()">
      <div class="row mt-3">
        <div class="col pb-0">
          <p class="text-center mb-3">
            <button
              mat-flat-button
              class="forgot-password-dialog-forgotPassword-Button"
              color="primary"
              type="submit"
              >
              <span class="forgot-password-dialog-custom-font-size">{{"global.forgotPassword.backBtn" | cxTranslate}}</span>
            </button>
          </p>
        </div>
      </div>
    </form>
    <div class="row px-3 mt-4">
      <div class="col pb-0 text-center">
        <ul class="t-body-m pb-3 pl-0">
          <li>{{"global.forgotPassword.tipsTextOne" | cxTranslate}}</li>
          <li>{{"global.forgotPassword.tipsTextTwo" | cxTranslate}}</li>
        </ul>
      </div>
    </div>
    <div class="row" *ngIf="!myBoxoutPortal">
      <div class="col pb-0 text-center">
        <p class="t-label-s mx-3 px-3 mb-0 forgot-dialog-recaptcha-text">
          <span>
            {{"global.forgotPassword.recaptcha" | cxTranslate}}
          </span>
          <a [routerLink]="['/privacy']" target="_blank" class="forgot-password-dialog-text-decoration-underline cursor-pointer">{{'global.forgotPassword.privacy' | cxTranslate}}</a>
          <span>{{'global.forgotPassword.and' | cxTranslate}}</span>
          <a [routerLink]="['/terms-of-use']" target="_blank" class="forgot-password-dialog-text-decoration-underline cursor-pointer">{{'global.forgotPassword.termsOfUse' | cxTranslate}}</a>
        </p>
      </div>
    </div>
  </div>
