<div class="reports-search p-3 mb-2 d-none d-md-block" *ngIf="component === '' && component.length === 0">
  <div class="reports-search-head t-title-l mb-3 pb-1">{{'account.itemPurchaseReport.reportFormHeader' | cxTranslate}}</div>

  <form class="reports-search-form" [formGroup]="reportSearchForm" (submit)="getReportsData()">

      <mat-form-field #materialFormField appearance="outline" class="w-100">
          <mat-label appRemoveAriaOwns>{{'account.itemPurchaseReport.dateRangeHeader' | cxTranslate}}</mat-label>
          <mat-select formControlName="dateRange" [value]=""
              disableOptionCentering panelClass="reports-search-select">
              <mat-option class="reports-search-option"  (onSelectionChange)="getSelectedRange(range, $event)"
                *ngFor="let range of dateRangeDropdown" [value]="range?.value">
                {{range?.name}}
              </mat-option>
          </mat-select>
      </mat-form-field>

      <div class="t-body-m">{{'account.itemPurchaseReport.orText' | cxTranslate}}</div>

      <div class="d-flex justify-content-between mt-2 pt-1">    
          <mat-form-field appearance="outline" class="startdate-input width-48">
              <mat-label> {{'account.itemPurchaseReport.startDateHeader' | cxTranslate}}
              </mat-label>
              <input matInput [matDatepicker]="startDatePicker" formControlName="startDate" [max]="maxDate" (dateChange)="addEvent('change', $event, 'start')" readonly>
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
              <mat-error class="mt-1"
                *ngIf="(reportSearchForm?.get('startDate')?.touched && reportSearchForm?.get('startDate')?.hasError('notValid'))">
                {{'account.itemPurchaseReport.dateRangeError' | cxTranslate}}
            </mat-error>
          </mat-form-field>
      
          <div class="d-flex flex-column width-48">
              <mat-form-field appearance="outline" class="enddate-input w-100" >
                  <mat-label> {{'account.itemPurchaseReport.endDateHeader' | cxTranslate}}
                  </mat-label>
                  <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" [max]="maxDate" (dateChange)="addEvent('change', $event, 'end')" readonly>
                  <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #endDatePicker></mat-datepicker>
                  <mat-error class="mt-1"
                    *ngIf="(reportSearchForm?.get('endDate')?.touched && reportSearchForm?.get('endDate')?.hasError('notValid'))">
                    {{'account.itemPurchaseReport.dateRangeError' | cxTranslate}}
                </mat-error>
              </mat-form-field>
          </div>
      </div>
  
      <div class="d-flex justify-content-between pt-2">
          <mat-form-field #materialFormField appearance="outline" class="width-48">
            <mat-label appRemoveAriaOwns>{{'account.itemPurchaseReport.accountsHeader' | cxTranslate}}</mat-label>
            <mat-select #accountDropdownId formControlName="accountName" disableOptionCentering panelClass="reports-search-select select-virtual-scroll"
                (openedChange)="openChange($event)">
                <cdk-virtual-scroll-viewport itemSize="50" class="cdk-viewport">
                    <mat-option value="" selected
                        (onSelectionChange)="getSelectedAccount(null, $event)" class="cdk-item">{{'account.itemPurchaseReport.allText' |
                        cxTranslate}}</mat-option>
                    <mat-option class="reports-search-option cdk-item" (onSelectionChange)="getSelectedAccount(acc, $event)"
                        *cdkVirtualFor="let acc of accountsDropdown" [value]="acc?.id">
                        {{acc?.name}}
                    </mat-option>
                </cdk-virtual-scroll-viewport>
            </mat-select>
        </mat-form-field>
      
          <mat-form-field #materialFormField appearance="outline" class="width-48">
              <mat-label appRemoveAriaOwns>{{'account.itemPurchaseReport.barndsHeader' | cxTranslate}}</mat-label>
              <mat-select formControlName="brandName"
                  disableOptionCentering panelClass="reports-search-select">
                  <mat-option value="" selected (onSelectionChange)="getSelectedBrand(null, $event)">{{'account.itemPurchaseReport.allText' | cxTranslate}}</mat-option>
                  <mat-option class="reports-search-option" (onSelectionChange)="getSelectedBrand(b, $event)"
                      *ngFor="let b of brandsDropdown" [value]="b?.code">
                      {{b?.name}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
      </div>
  
      <div class="d-flex justify-content-between pt-2">
          <mat-form-field class="w-100" appearance="outline" class="width-48">
              <mat-label>{{'account.itemPurchaseReport.invoiceHeader' | cxTranslate}}</mat-label>
              <input matInput class="" formControlName="orderNumber" id="invoice">
          </mat-form-field>
      
          <mat-form-field class="w-100" appearance="outline" class="width-48">
              <mat-label>{{'account.itemPurchaseReport.itemHeader' | cxTranslate}}</mat-label>
              <input matInput class="" formControlName="itemName" id="item">
          </mat-form-field>
      </div>

      <div class="d-flex justify-content-end my-3 pt-4">
          <button mat-flat-button type="button" class="mr-2 t-label-l" (click)="resetForm()">
              {{ 'account.cancel' | cxTranslate }}
          </button>

          <button mat-flat-button color="primary" type="submit" class="t-label-l" >
              {{ 'account.search' | cxTranslate }}
          </button>
      </div>
  
  </form>
</div>

<div *ngIf="component && component.length > 1" class="d-none d-md-block">
    <div class="table-reports-query mt-3">
        <form class="reports-search-form" [formGroup]="reportSearchForm" (submit)="getReportsData()">
            <div class="d-flex table-reports-query-row1">
                <mat-form-field #materialFormField appearance="outline" class="range-dropdown">
                    <mat-label appRemoveAriaOwns>{{'account.itemPurchaseReport.dateRangeHeader' | cxTranslate}}</mat-label>
                    <mat-select formControlName="dateRange"
                        disableOptionCentering panelClass="reports-search-select">
                        <mat-option class="reports-search-option" (onSelectionChange)="getSelectedRange(range, $event)"
                            *ngFor="let range of dateRangeDropdown" [value]="range?.value">
                            {{range?.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="t-body-m mx-2 px-1 mt-4">{{'account.itemPurchaseReport.orText' | cxTranslate}}</div>

                <div class="d-flex justify-content-between calender-date-section">    
                    <mat-form-field appearance="outline" class="startdate-input">
                        <mat-label> {{'account.itemPurchaseReport.startDateHeader' | cxTranslate}}
                        </mat-label>
                        <input matInput [matDatepicker]="startDatePicker" formControlName="startDate" [max]="maxDate" (dateChange)="addEvent('change', $event, 'start')" readonly>
                        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker></mat-datepicker>
                    </mat-form-field>
                
                    <div class="d-flex flex-column end-date-section">
                        <mat-form-field appearance="outline" class="enddate-input" >
                            <mat-label> {{'account.itemPurchaseReport.endDateHeader' | cxTranslate}}
                            </mat-label>
                            <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" [max]="maxDate" (dateChange)="addEvent('change', $event, 'end')" readonly>
                            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #endDatePicker></mat-datepicker>
                            <mat-error class="mt-1"
                             *ngIf="(reportSearchForm?.get('endDate')?.touched && reportSearchForm?.get('endDate')?.hasError('notValid'))">
                             {{'account.itemPurchaseReport.dateRangeError' | cxTranslate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>    

        
            <div class="d-flex justify-content-between table-reports-query-row2">
                <mat-form-field #materialFormField appearance="outline">
                    <mat-label appRemoveAriaOwns>{{'account.itemPurchaseReport.accountsHeader' | cxTranslate}}</mat-label>
                    <mat-select formControlName="accountName"
                        disableOptionCentering panelClass="reports-search-select select-virtual-scroll modify-search-scroll" (openedChange)="openChange($event)">
                        <cdk-virtual-scroll-viewport itemSize="50" class="cdk-viewport">
                            <mat-option value="" selected
                                (onSelectionChange)="getSelectedAccount(null, $event)" class="cdk-item">{{'account.itemPurchaseReport.allText' |
                                cxTranslate}}</mat-option>
                            <mat-option class="reports-search-option cdk-item" (onSelectionChange)="getSelectedAccount(acc, $event)" 
                                *cdkVirtualFor="let acc of accountsDropdown" [value]="acc?.id">
                                {{acc?.name}}
                            </mat-option>
                        </cdk-virtual-scroll-viewport>
                    </mat-select>
                </mat-form-field> 
            
                <mat-form-field #materialFormField appearance="outline" >
                    <mat-label appRemoveAriaOwns>{{'account.itemPurchaseReport.barndsHeader' | cxTranslate}}</mat-label>
                    <mat-select formControlName="brandName"
                        disableOptionCentering panelClass="reports-search-select">
                        <mat-option value=""  (onSelectionChange)="getSelectedBrand(null, $event)">{{'account.itemPurchaseReport.allText' | cxTranslate}}</mat-option>
                        <mat-option class="reports-search-option" (onSelectionChange)="getSelectedBrand(b, $event)"
                            *ngFor="let b of brandsDropdown" [value]="b?.code">
                            {{b?.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" >
                    <mat-label>{{'account.itemPurchaseReport.invoiceHeader' | cxTranslate}}</mat-label>
                    <input matInput class="" formControlName="orderNumber" id="invoice">
                </mat-form-field>
            
                <mat-form-field appearance="outline">
                    <mat-label>{{'account.itemPurchaseReport.itemHeader' | cxTranslate}}</mat-label>
                    <input matInput class="" formControlName="itemName" id="item">
                </mat-form-field>
            </div>
        
     
            <div class="d-flex justify-content-end my-3 pb-3">
                <button mat-flat-button type="button" class="mr-2 t-label-l cancel-btn" (click)="closeSearchQuerySection()">
                    {{ 'account.cancel' | cxTranslate }}
                </button>
      
                <button mat-flat-button color="primary" type="submit" class="t-label-l search-btn">
                    {{ 'account.search' | cxTranslate }}
                </button>
            </div>
        
        </form>
    </div>
</div>

<div class="d-md-none" *ngIf="component === '' && component.length === 0">
   <div class="no-table-data text-center py-4 mb-3">
        <div class="no-table-data-msg t-label-l">{{'account.itemPurchasetable.notSupported' | cxTranslate}}</div>
    </div>
</div>
