import { createAction, props } from '@ngrx/store';
import { User } from '../../models/user.model';

export const LOGIN_SUCCESS = '[auth page] login Success';
export const UPDATE_USER = '[auth page] update user';
export const LOGOUT = '[auth page] logout';

export const loginSuccess = createAction(LOGIN_SUCCESS, props<{ user: User }>());

export const updateUser = createAction(UPDATE_USER, props<{ displayUid: string }>());

export const logout = createAction(LOGOUT);
