import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HeaderService } from '../header.service';

@Injectable({
  providedIn: 'root'
})
export class ValidateCardConnectTokenService {

  constructor(
    public http: HttpClient,
    private headerService:HeaderService
  ) { }

  tokenizeCardNumber(cardNumber: string): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'});
    let options = { headers: headers };
    let dataObj = {
      account: cardNumber,
      unique: false
    }
    let apiCard:any;
    this.headerService.localPropertiesConfigData.subscribe((resp:any)=>{
      if(resp){
        apiCard = resp['spartacus.config.cardconnect.tokenise.url'] // JSON.parse is removed due to the API respoinse changed in 2211
      }
    })
    return this.http.post(apiCard,dataObj,options);
  }
}
