<div class="d-lg-none mobile-sitelinks">
    <div class="d-flex align-items-center mobile-sitelinks-main px-2 py-3 border-bottom-2">
        <div class="mr-auto t-headerLink-normal ml-1">{{'header.menu' | cxTranslate}}</div>
        <div class="mr-1">
            <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon" >close</mat-icon>
        </div>
    </div>
    <div class="mobile-sitelinks-action">
        <!-- <div class="category-section">
            <nav class="d-flex align-items-center">
                <span class="d-lg-block d-flex justify-content-between align-items-center w-100 px-lg-3 px-2 mx-1 t-menucategory-normal">
                    {{'header.categories' | cxTranslate}}
                    <mat-icon svgIcon="right_arrow" class="d-lg-none"></mat-icon>
                </span>
            </nav>
        </div> -->
        <cx-page-slot position="NavigationBar"></cx-page-slot>
    </div>
</div>