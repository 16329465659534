<div class="header-main justify-content-lg-between" (mouseenter)="removeBackdrop()" (click)="removeBackdrop()">
  <div class="header-hamburger d-lg-none">
      <app-custom-hamburger></app-custom-hamburger>
  </div>
  <div class="header-main-logo mr-0 mr-auto mr-lg-0 mx-lg-0 mx-md-auto mx-auto">
      <div id="main-logo" class="d-none d-lg-block">
          <!-- <cx-generic-link class="t-title-m" url="/" target="">
              <cx-media class="main-logo-colored" [container]="logo?.media" alt="Site Logo">
              </cx-media>
          </cx-generic-link> -->
          <cx-page-slot position="SiteLogo"></cx-page-slot>
      </div>
      <div id="mobile-logo" class="d-lg-none pl-2">
          <!-- <cx-generic-link class="t-title-m" url="/" target="">
              <cx-media class="main-logo-dark" [container]="mobileLogo?.media" >
              </cx-media>
          </cx-generic-link> -->
          <cx-page-slot position="MobileSiteLogoSlot"></cx-page-slot>
      </div>
  </div>
  <div class="header-main-search d-none d-lg-block">
      <app-header-searchbar></app-header-searchbar>
  </div>
  <div class="d-flex align-items-center">
      <div *ngIf="user" class="header-main-dropdowns d-none d-lg-block">
          <app-header-dropdown-links></app-header-dropdown-links>
      </div>
      <div class="header-main-right-section d-flex ml-1">
          <a  href={{phoneNumber}} aria-label="phone" class="d-lg-none"><mat-icon svgIcon="phone" role="button" class="d-lg-none mr-4"></mat-icon></a>
          <mat-icon svgIcon="account" role="button" class="d-lg-none mr-4" (click)="openSiteLinksDialog()" ></mat-icon>
          <div class="header-main-minicart">
              <app-header-minicart></app-header-minicart>
          </div>
      </div>
  </div>
</div>
