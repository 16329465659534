import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { SectionLoaderService } from 'src/app/feature/pages/loader/section-loader.service';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { CartService } from 'src/app/shared/services/common/cart.service';
import { CurrentThemeService } from 'src/app/shared/services/common/current-theme.service';
import { SmartyAddressValidationService } from 'src/app/shared/services/common/smarty-address-validation.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { AddShipToCartbusinessAddressComponent } from '../../add-ship-to-cartbusiness-address/add-ship-to-cartbusiness-address.component';
import { ShipToAddNewAddressModalComponent } from '../ship-to-add-new-address-modal/ship-to-add-new-address-modal.component';
import { ShipToBusinessAccountModalComponent } from '../ship-to-business-account-modal/ship-to-business-account-modal.component';

@Component({
  selector: 'app-ship-to-view-address-modal',
  templateUrl: './ship-to-view-address-modal.component.html'
})
export class ShipToViewAddressModalComponent implements OnInit,OnDestroy {
  currentTheme: any;
  totalRecords:number=0;
  searchParam: string='';
  @Input() accountId:any;
  user: User;
  userSub: Subscription;
  userId: any;
  apiErrorMessage=false;
  isAdmin: any;
  userType: any;
  customerRole:any;
  page: number;
  addresses:any=[];
  totalPages:number=0;
  viewAddresses:any=[];
  openAddress: any;
  addNewShipToAddress=false;
  constructor(public dialog: MatDialog,private theme: CurrentThemeService,
    private customHttp:BoxoutEndpointService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr:ChangeDetectorRef,
    private headerService:HeaderService,
    public dialogRef: MatDialogRef<ShipToViewAddressModalComponent>,
    public dialogRefTwo: MatDialogRef<ShipToAddNewAddressModalComponent>,
    private smartyAddressValidationService: SmartyAddressValidationService,
    private store: Store<fromApp.AppState>,
    private sectionLoaderService:SectionLoaderService,
    private cartService:CartService) { }

  ngOnInit(): void {
    this.currentTheme = this.theme?.getCurrentTheme();
    this.headerService.isAdmin.subscribe(response => {
      this.isAdmin = response;
    })


    this.userSub = this.store.select('auth').subscribe(user => { 
      this.user = user && user.user!;
      this.userId = this.user?.uid;
      this.customerRole = this.user?.customerRole;
      this.userType = this.user?.orgUnit?.unitType;
      if(this.userId){
       this.getViewAddress(this.data?.value?.addAddressId);
      }
    });
  }
  
    getViewAddress(item:any){
      this.sectionLoaderService.show();
      let apiURL=`users/${this.userId}/orgUnits/${item}/addresses`;     
      this.customHttp.get(apiURL).subscribe((resp:any) => {
        this.sectionLoaderService.hide();
     if(resp) { 
      this.viewAddresses =  resp.addresses;
      this.cdr.detectChanges();
    }
  },(error)=>{
    this.sectionLoaderService.hide()
  });
}
close(){
  this.dialogRef.close();
  this.dialogRefTwo.close();
  
}
addNewAddress(){
  this.dialogRef.close();
  this.addNewShipToAddress=true;
  this.smartyAddressValidationService.saveFormData(null);
  
  let defaultShippgTo = (this.data?.value?.itemData?.accountType === "DROPSHIP" && !(this.data?.value?.itemData?.shipTo)) || 
  (this.data?.value?.itemData?.accountType === "PARENT" && !(this.data?.value?.itemData?.shipTo)) || 
  (this.data?.value?.itemData?.accountType === "DIRECT" && !(this.data?.value?.itemData?.shipTo)) ? true : false; 
  
  let defaultBillingTo = (this.data?.value?.itemData?.accountType === "PARENT" && !(this.data?.value?.itemData?.shipTo)) ||
   (this.data?.value?.itemData?.accountType === "DIRECT" && !(this.data?.value?.itemData?.shipTo)) ? true : false;
     
  this.dialog.open(AddShipToCartbusinessAddressComponent,
       {
         panelClass: [this.currentTheme, 'ship-to-client-drawer-for-all'],
         width:'368px',
         position: {
          right: '0px',
          top: '0px'
        },
         data:  {
           value:{
             componentName:'cartShipToViewAddressComponent',
             addAddressId:this.data.value.addAddressId,
             openedDrawerFrom:'shipToViewAddress',
             action:"addingNewAddressForBusinessAccount",
             itemData: this.data?.value?.itemData,
             defaultShippgTo: defaultShippgTo,
             defaultBillingTo: defaultBillingTo


           }
        }
       });
      

}

goBack(){
  this.dialogRef.close();
  if(this.userType!=='DROPSHIP' && this.userType!=='DIRECT'){
  this.dialog.open(ShipToBusinessAccountModalComponent, {
      position: {
        right: '0px',
        top: '0px'
      },
      panelClass: ['wishlist-drawer', this.currentTheme],
      
    });
  }else{
    this.dialogRef.close();
  }
}
ngOnDestroy(): void {    
  if(this.userSub) {
    this.userSub.unsubscribe();
  }
}

selectAddress(itemId:any){
  this.sectionLoaderService.show();
  let cartId = sessionStorage.getItem('cartId');
   let payloadData={"siebelRowId":this.data?.value?.addAddressId,"addressSiebelRowID":itemId,"isShipTo":false};
  let apiUrl=`users/current/carts/${cartId}/addressDetails/?fields=DEFAULT`
  this.customHttp.post(apiUrl,payloadData).subscribe((res)=>{
    this.sectionLoaderService.hide();
    if(res?.isSuccess){
    this.dialogRef.close();
    this.cartService.getCartPageData(this.userId, true);
    this.apiErrorMessage=false;
    }else{
      this.apiErrorMessage=true;
    }
  },(error)=>{
    this.apiErrorMessage=true;
    this.sectionLoaderService.hide()
  })
}

showOtherBusinessAddress(address: any){
  return this.smartyAddressValidationService.returnFormattedAddress(address);
}

}
