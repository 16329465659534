<!--This Component displays Footer Info Links -->
<div class="footer-info-link-container">
  <div class="container py-3 px-3 d-flex justify-content-between flex-row">
    <div class="d-flex flex-row align-items-center">
      <div *ngFor="let child of footerInfoLinkArray">
        <div *ngIf="child.uid === 'LegalLink'">
          <cx-generic-link
            class="t-subhead-normal mr-3 footer-info-link-display"
            [url]="child.url"
            [target]="setLinkTarget(child)"
          >
            {{ child.name }}
          </cx-generic-link>
        </div>

        <div *ngIf="child.uid === 'PrivacyLink'">
          <cx-generic-link
            class="t-subhead-normal mr-3 footer-info-link-display"
            [url]="child.url"
            [target]="setLinkTarget(child)"
          >
            {{ child.name }}
          </cx-generic-link>
        </div>

        <div *ngIf="child.uid === 'DoNotSellMyPersonalInformationLink'">
          <cx-generic-link
            class="t-subhead-normal mr-3 d-none d-md-block footer-info-link-display"
            [url]="child.url"
            [target]="setLinkTarget(child)"
          >
            {{ child.name }}
          </cx-generic-link>
        </div>

        <div *ngIf="child.uid === 'AccessibilityLink'">
          <cx-generic-link
            class="t-subhead-normal mr-3 d-none d-sm-block footer-info-link-display"
            [url]="child.url"
            [target]="setLinkTarget(child)"
          >
            {{ child.name }}
          </cx-generic-link>
        </div>

        <div *ngIf="child.uid === 'Co-BrowseLink'">
          <cx-generic-link
            class="t-subhead-normal mr-3 d-none d-md-block footer-info-link-display"
            [url]="child.url"
            [target]="setLinkTarget(child)"
          >
            {{ child.name }}
          </cx-generic-link>
        </div>
      </div>
    </div>
    <!-- commenting serverid for now -->
    <!-- <div class="d-flex justify-content-end footer-info-link-display">
      <a href="" target="_blank" class="footer-info-server t-body-s"
        >{{releaseId}} | ServerID</a
      >
    </div> -->
  </div>
</div>
