import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-items-added-to-cart',
  templateUrl: './items-added-to-cart.component.html'
})
export class ItemsAddedToCartComponent{

  constructor(
    public dialogRef: MatDialogRef<ItemsAddedToCartComponent>,
    private router: Router
    ) { }

  navigateToCartPage() {
    this.router.navigate(['/cart']);
  }

  close() {
    this.dialogRef.close();
  }

}
