import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SiteContextConfig, WindowRef } from '@spartacus/core';
import { PageLoaderService } from 'src/app/feature/pages/page-loader/page-loader.service';
import { BoxoutEndpointService } from '../../services/common/boxout-endpoint.service';

// this component is used for reusable switch account dialog 
@Component({
  selector: 'app-reusable-switch-acct',
  templateUrl: './reusable-switch-acct.component.html'
})

export class ReusableSwitchAcctComponent implements OnInit {

  uid: any;
  accountList: any;
  selectedCode: string;
  count: number = 0;
  baseSiteName: any;
  baseSiteNameVal: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  private pageLoaderService:PageLoaderService,
  private customHttp: BoxoutEndpointService,private changeDetectorRef: ChangeDetectorRef, private config: SiteContextConfig,
  @Inject(DOCUMENT) private document: Document,
  private windowRef: WindowRef) {
    this.uid = data?.userId;
    this.selectedCode = data?.b2bunit
  }

  ngOnInit(): void {
    this.getB2bUnits();
    this.baseSiteName = this.config?.context?.baseSite;
    if(this.baseSiteName){
      this.baseSiteNameVal=this.baseSiteName[0];
    }
  }

  // this function with fetch all the b2b units associated with the logged in acct
  getB2bUnits() {
    this.customHttp.get(`orgUsers/${this.uid}/getB2bUnits`).subscribe((data: any) => {
      this.accountList = data?.b2bUnits?.b2bUnitList;
      this.count = this.accountList?.length;
      this.changeDetectorRef.detectChanges();
    });
  }

  //this function will set the selected b2b units in the session and BE
  onContinueClick() {
    let selectUrl = 'setB2bUnitId';
    this.windowRef.localStorage?.setItem("b2bUnit", this.selectedCode); // Saving the b2bUnit in local storage to send it to BE in all http request headers
    this.customHttp.put(selectUrl, {uid: this.selectedCode}).subscribe((data: any) => {
      /** when we need basesite, language and locale in the site URL */
      // location.pathname=`/${this.config?.context?.baseSite}/${this.config?.context?.language}/${this.config?.context?.currency}/my-account`;

      /** when we dont need basesite, language and locale in the site URL - This will not work in local*/
      if(this.baseSiteNameVal !== 'bxdportal') {
        this.document.location.pathname=`/my-account`;
      } else {
        this.document.location.pathname=`/`;
      }

    },(error)=>{
    });
  }
}
