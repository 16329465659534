import { createReducer, on } from '@ngrx/store';
import * as MyAccountActions from '../actions/my-account.actions';

export interface State {
  myAccountDetails: any;
}

const initialState: State = {
  myAccountDetails: null
};

export const accountDetailsReducer = createReducer(
  initialState,
  on(MyAccountActions.myAccountDetails, (state, { accountDetails }) => {
    // const updatedUser = user;
    const updatedAccountDetails = accountDetails;
    return {
      ...state,
      myAccountDetails: updatedAccountDetails
    };
  }))
