<div class="items-added-to-cart">
  <div class="d-flex justify-content-center mb-3">
    <mat-icon svgIcon="cart-green" class="items-added-to-cart-icon" aria-label="Cart"></mat-icon>
  </div>

  <div class="d-flex justify-content-center">
    <p class="t-headline-m">{{ 'account.rapidOrder.itemsAddedToCart' | cxTranslate }}</p>
  </div>

  <div class="d-flex justify-content-center">
    <p class="t-title-m text-center">{{ 'account.rapidOrder.itemsAddedToCartDescription' | cxTranslate }}</p>
  </div>

  <div class="d-flex justify-content-center">
    <button class="mat-flat-button mr-2 items-added-to-cart-action-btn" (click)="close()">
      {{"account.rapidOrder.close"| cxTranslate}}
    </button>
    <button mat-flat-button class="mat-flat-button ml-2 items-added-to-cart-action-btn" color="primary" (click)="navigateToCartPage()">
      {{"account.rapidOrder.goToCart"| cxTranslate}}
    </button>
  </div>
</div>