import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import { WindowRef } from '@spartacus/core';
import { BoxoutEndpointService } from '../../services/common/boxout-endpoint.service';
import { CustomValidationService } from '../../services/common/custom-validation.service';
@Component({
  selector: 'app-reports-search-form',
  templateUrl: './reports-search-form.component.html',
})
export class ReportsSearchFormComponent implements OnInit {
  //private _dataUrlBrands = '../../../../../assets/mock-json/itemPurchaseBrands.json'; // This has to be replaced with api details shared by BE
  //private _dataUrlAccounts = '../../../../../assets/mock-json/itemPurchaseAccounts.json'; // This has to be replaced with api details shared by BE
  private _dataUrlBrands = 'users/current/getAllBrands';
  private _dataUrlAccounts = 'users/current/getAccountsList';
  private _dataRange =
    '../../../../../assets/mock-json/itemPurchaseDateRange.json';
  reportSearchForm: any;
  dateRangeDropdown: any;
  accountsDropdown: any;
  brandsDropdown: any;
  maxDate: Date;
  searchTxt: any;
  @Input() existingQuery: any;
  @Input() component: any;
  @Input() redirectTo:any;
  @Output() public closeSearchQuery = new EventEmitter();
  @Output() public updateQuery = new EventEmitter();
  @Output() public accDropdownData = new EventEmitter();
  @Output() public brandsDropdownData = new EventEmitter();
  @Output() public rangeDropdownData = new EventEmitter();
  @Input() brands: any;
  @Input() acc: any;
  @Input() rangeDropdown: any;
  selectedStartDate: any = null;
  selectedEndDate: any = null;
  selectedBrand: {};
  selectedAccount: any;
  selectedDropdown: any;
  queriedSelectedAcc: { id?: any; name?: any;isShipToClient?:boolean };
  @ViewChild(CdkVirtualScrollViewport, { static: false })
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private customHttp: BoxoutEndpointService,
    private customValidator: CustomValidationService,
    private router: Router,
    private window: WindowRef
  ) {}

  ngOnInit(): void {

    this.reportSearchForm = this.formBuilder.group(
      {
        dateRange: this.existingQuery?.dateRange
          ? this.existingQuery?.dateRange
          : null,
        startDate: this.existingQuery?.startDate
          ? new Date(this.existingQuery?.startDate)
          : null,
        endDate: this.existingQuery?.endDate
          ? new Date(this.existingQuery?.endDate)
          : null,
        accountName: this.existingQuery?.accountData?.id
          ? this.existingQuery?.accountData?.id
          : '',
        brandName: this.existingQuery?.brandData?.code
          ? this.existingQuery?.brandData?.code
          : '',
        orderNumber: this.existingQuery?.orderNumber
          ? this.existingQuery?.orderNumber
          : '',
        itemName: this.existingQuery?.itemName
          ? this.existingQuery?.itemName
          : '',
      },
      { validator: [this.customValidator.validEndDate('startDate', 'endDate')] }
    );

    this.maxDate = this.getDatepickerMaxDate();

    if (!this.component && this.component.length === 0) {
      this.customHttp.get(this._dataUrlAccounts).subscribe((data: any) => {
        this.accountsDropdown = data?.accountsDataList;
        this.window.sessionStorage?.setItem('accountLength', data?.accountsDataList?.length);
      });
      this.customHttp.get(this._dataUrlBrands).subscribe((data: any) => {
        this.brandsDropdown = data?.brandsList;
      });
      this.http
      .get(this._dataRange)
      .subscribe((data) => {this.dateRangeDropdown = data;});
    } else {
      if (this.acc && this.acc.length) {
        this.accountsDropdown = this.acc;
        this.setExistingQueryData();
      } else {
        this.customHttp.get(this._dataUrlAccounts).subscribe((data: any) => {
          this.accountsDropdown = data?.accountsDataList;
          this.window.sessionStorage?.setItem('accountLength', data?.accountsDataList?.length);
          this.accDropdownData.emit(this.accountsDropdown);   
          this.setExistingQueryData();
        });
      }

      if (this.brands && this.brands.length) {
        this.brandsDropdown = this.brands;
        this.setExistingQueryData();
      } else {
        this.customHttp.get(this._dataUrlBrands).subscribe((data: any) => {
          this.brandsDropdown = data?.brandsList;  
          this.brandsDropdownData.emit(this.brandsDropdown); 
          this.setExistingQueryData();       
        });
      }

      if (this.rangeDropdown && this.rangeDropdown.length) {
        this.dateRangeDropdown = this.rangeDropdown;
        this.setExistingQueryData();
      } else {
        this.http
        .get(this._dataRange)
        .subscribe((data) => {
          this.dateRangeDropdown = data;
          this.rangeDropdownData.emit(this.dateRangeDropdown);   
          this.setExistingQueryData(); 
        });
      }
    }

    if(this.existingQuery?.startDate) {
      this.selectedStartDate = this.existingQuery?.startDate;
    }
    if(this.existingQuery?.endDate) {
      this.selectedEndDate = this.existingQuery?.endDate;
    }
    if(this.existingQuery?.accountData?.id) {
      let accountsLength =  this.window.sessionStorage?.getItem('accountLength') ? this.window.sessionStorage?.getItem('accountLength') : this.accountsDropdown?.length;
      if(this.accountsDropdown?.length > accountsLength) {
        this.accountsDropdown?.shift();
      }
      this.accountsDropdown?.unshift(this.existingQuery?.accountData);
    }
  }

  //setting up the existingQuery
  setExistingQueryData() {
    if (this.existingQuery && this.existingQuery?.accountData) {
      this.queriedSelectedAcc = this.existingQuery?.accountData;
    }

    if (this.existingQuery && this.existingQuery?.brandData) {
      this.selectedBrand = this.existingQuery?.brandData;
    }

    if (this.existingQuery && this.existingQuery?.dateRange) {
      this.dateRangeDropdown?.forEach((range: any) => {
        if(range.value === this.existingQuery?.dateRange) {
          this.selectedDropdown = range;
        }
      });
    }
  }

  // Getting the max date for datepicket for validations
  getDatepickerMaxDate(date = new Date()) {
    const previous = new Date(date?.getTime());
    previous.setDate(date?.getDate() - 1);

    return previous;
  }

  // resetting the form to initial values on click of clear
  resetForm() {
    this.reportSearchForm.reset();
    this.reportSearchForm.patchValue({
      dateRange: '',
      startDate: null,
      endDate: null,
      accountName: '',
      brandName: '',
      orderNumber: '',
      itemName: '',
    });
    this.selectedStartDate = null;
    this.selectedEndDate = null;
    this.queriedSelectedAcc = {};
    this.selectedBrand = {};
  }

  //This function is still WIP -- Invalid date validation to be added
  addEvent(type: string, event: MatDatepickerInputEvent<Date>, Inputtype: any) {
    if (event.value === null) {
      this.selectedStartDate = null;
      this.selectedEndDate = null;
      if (Inputtype === 'start') {
        this.reportSearchForm?.controls['startDate']?.reset();
      } else if (Inputtype === 'end') {
        this.reportSearchForm?.controls['endDate']?.reset();
      }
    } else {
      var val = new Date(event.value);
      if (Inputtype === 'start') {
        this.selectedStartDate = `${
          val?.getMonth() + 1
        }/${val?.getDate()}/${val?.getFullYear()}`;
      } else if (Inputtype === 'end') {
        this.selectedEndDate = `${
          val?.getMonth() + 1
        }/${val?.getDate()}/${val?.getFullYear()}`;
      }
    }
  }

  // emitting the event to close the search form in reports data table
  closeSearchQuerySection() {
    this.closeSearchQuery.emit(false);
  }

  // sending the query to the data table using route
  getReportsData() {
    if (this.reportSearchForm.valid) {
      const query = {
        dateRange: this.reportSearchForm?.value?.dateRange,
        startDate: this.selectedStartDate,
        endDate: this.selectedEndDate,
        accountData: this.queriedSelectedAcc,
        brandData: this.selectedBrand,
        orderNumber: this.reportSearchForm?.value?.orderNumber,
        itemName: this.reportSearchForm?.value?.itemName,
      };

      // if search is done from reportSearch Page sending the data from search form component to data table component
      if (!this.component?.length) {
        this.router.navigate([this.redirectTo], {
          state: {
            data: query,
            acc: this.accountsDropdown,
            brands: this.brandsDropdown,
            selectedRange: this.selectedDropdown
          },
        });
      } else {
        const sendQuery = {
          query: query,
          selectedRange: this.selectedDropdown,
        };
        //if the search is performated from data table page so need to send the updated query to table component
        this.updateQuery.emit(sendQuery);
      }
    }
  }

  // selected brand obj
  getSelectedBrand(brand: any, evt:any) {
    if (evt.source?.selected) {
      this.selectedBrand = brand;
    }
  }

  // selected account obj
  getSelectedAccount(acc: any , evt:any) {
    if (evt.source?.selected) {
      this.selectedAccount = acc;
      let obj = {
        id: acc?.id,
        name: acc?.name,
        isShipToClient:acc?.isShipToClient?acc?.isShipToClient:''
      };
      this.queriedSelectedAcc = obj;
    }
  }

  // selected range obj
  getSelectedRange(range: any, evt:any) {
    if (evt.source?.selected) {
      this.selectedDropdown = range;
    }
  }

  openChange($event: boolean) {
    if ($event) {
      this.cdkVirtualScrollViewPort?.scrollToIndex(0);
      this.cdkVirtualScrollViewPort?.checkViewportSize();
    }
  }
}
