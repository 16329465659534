import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-order-detail-promotion',
  templateUrl: './order-detail-promotion.component.html'
})
export class OrderDetailPromotionComponent {
@Input() appliedOrderPromotions:any;
@Input() appliedProductPromotions:any;
  constructor() {
   }
}
