<div
  class="displaySlider"
  [ngClass]="{ 'sidebar-slider': sliderOn, 'sidebar-slide-in': sliderOn }"
>
  <div class="facet-container">
    <div
      *ngIf="filteredItems.length"
      class="p-3 filter t-searchResults-facet d-flex justify-content-between"
    >
      <span class="pt-2 mt-1 pb-1 t-searchResults-facet">{{
        "account.searchResults.selectedFilters" | cxTranslate
      }}</span>
      <button mat-flat-button class="clearFilter" (click)="clearAllFilters()">
        {{ "account.searchResults.clearFilters" | cxTranslate }}
      </button>
    </div>
    <div>
      <mat-chip-listbox aria-hidden="true">
        <mat-chip-option
          *ngFor="let selectedItem of filteredItems"
          (removed)="remove(selectedItem)"
        >
        <span [innerHtml]="selectedItem.name">
        </span>
          <button matChipRemove>
            <mat-icon svgIcon="closeFacet" aria-label="Back"></mat-icon>
          </button>
        </mat-chip-option>
      </mat-chip-listbox>
    </div>
    <div
      class="p-3 mt-2 filter t-searchResults-facet d-flex justify-content-between"
    >
  <!-- <span> ==> {{isExpanded | json}}</span> -->
      <span>{{ "account.searchResults.filter" | cxTranslate }}</span>
      <mat-icon svgIcon="Close" 
      aria-label="close"
        class="d-flex d-xl-none"
       (click)="onCloseFilters()">
      </mat-icon>
    </div>
    <div class="accordion" id="accordionExample">
      <div class="card" *ngFor="let item of facetData; let i = index">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">
            <button
              class="btn btn-link t-searchResults-facet"
              type="button"
              data-toggle="collapse"
              [attr.data-target]="'#facet_' + i"
              aria-expanded="true"
            >
              {{ item.name }}
            </button>
          </h5>
        </div>

        <div
          id="facet_{{ i }}"
          class="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
        >
        <div *ngIf="!isExpanded[item.name]">
          <div *ngFor="let res of item.topValues; let i = index">
              <div class="card-body d-flex justify-content-between">
                <div class="facets-checkbox">
                  <input
                    #checkboxes
                    type="checkbox"
                    [checked]="res.selected"
                    class="m-0"
                    [id]="res?.code"
                    (change)="facetChecked($event, res)"
                  />
                  <label class="facetItem pt-1 cursor-pointer t-nav-subCategory" [for]="res?.code" [innerHtml]="res?.name"></label>
                </div>
                <span
                  class="d-flex align-items-center facetItemCount t-nav-subCategory"
                  >{{ res?.count }}</span
                >
              </div>
          </div>
        </div>
          <div *ngIf="isExpanded[item.name]">
            <div
              *ngFor="let expandedItem of item.values; let i = index"
            >
              <div class="card-body d-flex justify-content-between">
                <div>
                  <input
                    type="checkbox"
                    [checked]="expandedItem?.selected"
                    class="m-0"
                    (change)="facetChecked($event, expandedItem)"
                    [id]="expandedItem?.code"
                  />
                  <label class="facetItem t-nav-subCategory pt-1 cursor-pointer" [for]="expandedItem?.code"
                    [innerHtml]="expandedItem?.name">
                </label>
                </div>
                <span
                  class="d-flex align-items-center facetItemCount t-nav-subCategory"
                  >{{ expandedItem.count }}</span
                >
              </div>
            </div>
          </div>
          <div *ngIf="!isExpanded[item.name] && item?.values" class="pb-3">
            <span
              class="showMore t-searchResults-showMore"
              (click)="onShowMoreClick(item.name)"
              >{{ "account.searchResults.showMore" | cxTranslate }}</span
            >
          </div>
          <div *ngIf="isExpanded[item.name]" class="pb-3">
            <span
              class="showMore t-searchResults-showMore"
              (click)="onShowLessClick(item.name)"
              >{{ "account.searchResults.showLess" | cxTranslate }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="search-filter-backdrop" *ngIf="sliderOn" (click)="onCloseFilters()"></div>