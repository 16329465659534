import { MediaMatcher } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { SiteContextConfig } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { CustomValidationService } from 'src/app/shared/services/common/custom-validation.service';
import { HeaderService } from 'src/app/shared/services/header.service';

@Component({
  selector: 'app-reusable-sign-in-security',
  templateUrl: './reusable-sign-in-security.component.html'
})
export class ReusableSignInSecurityComponent implements OnInit, OnDestroy {
  errorMessage:string='';
  user: User;
  userSub: Subscription;
  email: string = "";
  uid: any;
  newPasswordEmpty: boolean=false;
  reEnteredNewPasswordEmpty:boolean=false;
  formValid: boolean = true;
  formUpdated: boolean = false;
  signInSecurityForm: FormGroup;
  themesForScroll: any = this.document.getElementsByClassName('cdk-global-overlay-wrapper');
  showSuccessMsg: boolean = false;
  baseSiteName: any;
  baseSiteNameVal: any;

  
  constructor(
    private formBuilder: FormBuilder,
    private customValidator: CustomValidationService,
    private customHttp: BoxoutEndpointService,
    private headerService: HeaderService,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<ReusableSignInSecurityComponent>,
    @Inject(DOCUMENT) private document: Document,
    private changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
    private store: Store<fromApp.AppState>,
    private config: SiteContextConfig,
  ) { }
  
  ngOnInit(): void {
    // to get the email and uid value
    this.route.queryParams.subscribe((params) => {
      this.email = params.email;
      this.uid = params.siebelRowId;
    });
    this.themesForScroll[0].className += " custom-modal";
    this.userSub = this.store.select('auth').subscribe(user => { 
      this.user = user && user.user!;    
    });
    this.signInSecurityForm = this.formBuilder.group(
      {
        newPassword: ['', [Validators.required,Validators.minLength(8),Validators.maxLength(20)]],
        reEnteredNewPassword: ['', [Validators.required,Validators.minLength(8),Validators.maxLength(20)]],
      },
      {
        validator: [
          this.customValidator.passwordMatchValidator(
            'newPassword',
            'reEnteredNewPassword'
          ),
          this.customValidator.validPassword('newPassword'),
        ],
      }
    ); 

    this.baseSiteName = this.config?.context?.baseSite;
    if (this.baseSiteName) {
      this.baseSiteNameVal = this.baseSiteName[0];
    }
  }

  valueChange(){
    this.errorMessage="";
  }

  saveSignInSecurityForm() {
    this.errorMessage="";
    this.newPasswordEmpty=false;
    this.reEnteredNewPasswordEmpty=false;
    if (this.signInSecurityForm.valid && this.user?.displayUid ) {
      const newPassword = this.signInSecurityForm.get('newPassword')?.value;
      let apiURL = `/orgUsers/current/updatePassword`;
      let request = {
        "email": this.email,
        "newPassword": newPassword
      };
      // let apiURL = `/orgUsers/${this.user?.displayUid}/password?new=${encodeURIComponent(newPassword)}`;

      this.customHttp.post(apiURL,request).subscribe(
        (data) => {
          this.formUpdated = true;
          this.formValid = this.signInSecurityForm.valid;
          this.signInSecurityForm.reset();
          this.signInSecurityForm.markAsPristine();
          this.signInSecurityForm.markAsUntouched();
          this.showSuccessMsg = true;
          var passwordData = {showSuccess: this.showSuccessMsg};
          this.dialogRef.close(passwordData);
         
        },
        (error) => {
          this.errorMessage=error?.error?.errors[0]?.message;       
          this.formValid = false;
          this.formUpdated = false;
        }
      );
    } else {

      if(this.signInSecurityForm.value.newPassword === '' || this.signInSecurityForm.value.newPassword === null ){
        this.newPasswordEmpty=true;
      }else{
        this.newPasswordEmpty=false;
      }

      if(this.signInSecurityForm.value.reEnteredNewPassword === '' || this.signInSecurityForm.value.reEnteredNewPassword === null ){
        this.reEnteredNewPasswordEmpty=true;
      }else{
        this.reEnteredNewPasswordEmpty=false;
      }
      this.formValid = false;
      this.formUpdated = false;
      this.signInSecurityForm.markAllAsTouched();
      const firstElementWithError = document.querySelector('.ng-invalid');

      if (firstElementWithError) {
        firstElementWithError.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  ngOnDestroy(): void {
    if(this.userSub) {
      this.userSub.unsubscribe();
    }
  }

}
