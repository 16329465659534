import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { CartService } from 'src/app/shared/services/common/cart.service';
import { GTMService } from 'src/app/shared/services/common/gtm.service';
@Component({
  selector: 'app-clear-cart',
  templateUrl: './clear-cart.component.html'
})
export class ClearCartComponent implements OnInit {
  cartId: any;
  userId: any;
  componentName: any;
  orgUnit: any;
  shipTo: any;

  constructor(@Inject(MAT_DIALOG_DATA) public cartData: any, private customHttp: BoxoutEndpointService, public cartService: CartService,
  private dialogRef: MatDialogRef<ClearCartComponent>, private gtmService: GTMService) { }

  ngOnInit(): void {
    this.cartId = this.cartData?.cartId;
    this.userId = this.cartData?.userId;
    this.componentName = this.cartData?.componentname;
    this.orgUnit =  this.cartData?.orgUnit;
    this.shipTo = { "shipToClients" : this.cartData?.shipTo};
  }

  /** Function to delete all enteries in cart */
  clearCart() {
    if(this.componentName === 'Cart') {
      this.customHttp.get(`users/current/carts/${this.cartId}/emptyCart/?fields=DEFAULT`).subscribe((data:any) => {
        if(data.isSuccess === true) {
          this.cartService.getCartPageData(this.userId , false);
          this.gtmService.removeAllProductFromCart();
        }
      })
    }
  }

  clearShipTo() {
    this.customHttp.post(`users/${this.userId}/orgUnits/${this.orgUnit}/clients/remove`, this.shipTo).subscribe(
      (data: any) => {
        if(data && data?.isSuccess) {
          let respObj= {
            status : 'success',
            data : data
          }
          this.dialogRef.close(respObj);
        }
      },
      (error: any) => {
        let respObj= {
          status : 'failure',
          data : error
        }
        this.dialogRef.close(respObj);
      })
  }
}
