import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';

@Injectable({
  providedIn: 'root'
})
export class SearchServiceService {

 private dataUrl = '../../../../../assets/mock-json/getVariant.json'; //mockJson Url
  private sharedData: Subject<any> = new Subject<any>();
  sharedData$: Observable<any> = this.sharedData.asObservable();

  constructor(private customHttp: BoxoutEndpointService,
    private http: HttpClient) { }

  setData(updatedData:any) {
    this.sharedData.next(updatedData);
  }
  priceCallApi(productCodeArray:any){
    //To be uncommented once backened price integration with mock json is completed
    let apiUrl = `orgProducts/realTimePriceForSearch?productCodes=${productCodeArray}`;
    return this.customHttp.get(apiUrl);
  }

  //get variant api call
  getVariantApi(productCode:any){
    let apiUrl = `orgProducts/search/p/${productCode}/getVariants?fields=DEFAULT`;
    return this.customHttp.post(apiUrl); 
    // return this.http.get(this.dataUrl);
  }



}
