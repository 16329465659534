<div class="ship-to-business-account-container">
  
<app-loader></app-loader>
  <div class="ship-to-business" *ngIf="!addNewShipToAddress">
    <div
      class="ship-to-business-header pb-3 d-flex flex-row justify-content-between align-items-center"
    >
      <div class="t-headerLink-normal pt-2 d-flex align-items-center" (click)="goBack()" >
        <mat-icon svgIcon="back" class="mr-2"></mat-icon> {{ "account.registerForm.back" | cxTranslate }}
      </div>
      <div class="mt-1">
        <mat-icon (click)="close()" class="close-icon dialogCloseIcon" color="white">close</mat-icon>
      </div>
    </div>


 <!--  to show the error/success  on address addtion on business account: start-->

 <div *ngIf="data?.value?.showFailureForCart" class="m-2">
  <reusable-alert-box
    [alertType]="'warningAlertBox'"
    alertMsg="{{ 'account.businessAccounts.infoUpdateError' | cxTranslate }}"
  ></reusable-alert-box>
</div>

<div *ngIf="data?.value?.showSuccessForCart" class="m-2">
  <reusable-alert-box
    [alertType]="'successAlertBox'"
    alertMsg="{{
      'account.businessAccounts.infoUpdateSuccess' | cxTranslate
    }}"
  ></reusable-alert-box>
</div>


<!--  to show the error/success on address addtion on client/patient account: start-->

<div *ngIf="apiErrorMessage" class="m-2">
  <reusable-alert-box
    [alertType]="'warningAlertBox'"
    alertMsg="{{ 'account.businessAccounts.infoUpdateError' | cxTranslate }}"
  ></reusable-alert-box>
</div>


    <div
      class="ship-to-business-add p-2 d-flex justify-content-center align-items-baseline"
    >
      <mat-icon (click)="addNewAddress()" svgIcon="addButton"></mat-icon>
      <p mat-flat-button (click)="addNewAddress()" class="t-acctno-text-regular m-0" type="button">
        <u>{{ "account.cartShipToBusiness.addNewShipTo" | cxTranslate }} </u>
      </p>
    </div>

    <div *ngIf="data" class="ship-to-business d-flex justify-content-center pt-4">
      <p class="t-headerLink-normal">
        {{ data?.value?.itemData?.name ? (data.value.itemData.name | titlecase) : "" }}
      </p>
    </div>
    <div *ngIf="viewAddresses && viewAddresses.length">
      <div class="p-2" *ngFor="let item of viewAddresses">
        <div class="ship-to-business-address p-2">
          <div class="ship-to-business-address-block">
            <p
              *ngIf="item.isPrimaryShippingAddress"
              class="outlined-badge-success t-address-type w-25 mb-2"
            >
              {{ "account.businessAccounts.primaryShipTo" | cxTranslate }}
            </p>
            <p class="t-body-s m-0">
              {{showOtherBusinessAddress(item)}}
            </p>
            <p class="t-body-s m-0 ship-to-business-address-end pb-2">
              <a class="phoneLink" href="tel:{{item?.phone }}" aria-label="Call On"> {{(item?.phone ? item.phone: '') | formatPhone}}</a>
            </p>
          </div>
          <div
            class="d-flex justify-content-between ship-to-business-address-action px-2 pt-2"
          >
            <button
              mat-flat-button
              class="t-acctno-text-regular"
              color="primary"
              (click)="selectAddress(item?.id)"
            >
              <u>{{ "account.cartShipToBusiness.select" | cxTranslate }}</u>
            </button>
            <button mat-flat-button class="d-none t-acctno-text-regular" color="white">
              <u>{{ "account.cartShipToBusiness.edit" | cxTranslate }}</u>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Load add shipto new address dialog-->
 </div>
