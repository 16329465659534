import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OutletModule, OutletRefModule, PageComponentModule, PageLayoutModule, PageSlotModule } from '@spartacus/storefront';



@NgModule({
  declarations: [],
  imports: [
    //CommonModule,
    OutletModule,
    OutletRefModule,
    PageComponentModule,
    //FeaturesConfigModule,
    PageLayoutModule,
    PageSlotModule,
    RouterModule,
    //UrlModule
  ],
  exports: [
    OutletModule,
    OutletRefModule,
    PageComponentModule,
    //FeaturesConfigModule,
    PageLayoutModule,
    PageSlotModule,
    RouterModule,
    //UrlModule
  ]
})
export class SpartacusStorefrontCustomModule { }
