<!-- <div *ngIf="flyout && node?.children.length > 1" class="back is-open d-lg-none" (click)="back()">
  <span>
    <cx-icon [type]="iconType.CARET_LEFT"></cx-icon>
    {{ 'common.back' | cxTranslate }}
  </span>
</div> -->

<ng-container *ngFor="let child of node?.children">
  <ng-container *ngTemplateOutlet="nav; context: { node: child, depth: 0}">
  </ng-container>
</ng-container>


<ng-template #nav let-node="node" let-depth="depth">

  <nav (click)="toggleOpen($event,depth, node)" (mouseenter)="onMouseEnter($event,depth,node)"
    (keydown.space)="toggleOpen($event, depth, node)" tabindex="0"
    [ngClass]="[(!node?.children || node?.children?.length === 0) ? 'is-link main-nav-' + depth : 'is-nav main-nav-'+depth , (node?.styleAttributes && node?.styleAttributes === 'true') ? 'onlyMobile' : '', (depth === 0) ? 'pt-lg-1 px-lg-3 mx-lg-1':'']">

    <cx-generic-link (click)="$event.stopPropagation()" *ngIf="!node?.children || node?.children?.length === 0;
        else heading" [url]="node?.url ? node?.url : '/'" target="_self" [style]="node?.styleAttributes"
      [class]="node?.styleClasses"
      [ngClass]="depth === 0 ? 't-title-m main-nav-span-' + depth : 't-menucategory-normal main-nav-span-'+depth"
      class="custom-nav-link">
      {{ node?.title }}
    </cx-generic-link>

    <ng-template #heading>
      <span [ngClass]="depth === 0 ? 't-title-m main-nav-span-' + depth : 't-menucategory-normal main-nav-span-'+depth"
        [attr.tabindex]="flyout && (depth === 0 || node?.url) ? 0 : -1" class="">
        <p [ngClass]="'w-100 p-0 m-0 d-flex align-items-center nav-title title-' + depth">
          <mat-icon svgIcon="back-arrow" class="back-arrow"></mat-icon>
          <cx-generic-link *ngIf="depth !== 0; else mainNav" (click)="$event.stopPropagation()"
            [url]="node?.url ? node?.url : '/'" target="_self" class="nav-node-link">{{ node?.title }}
          </cx-generic-link>
          <ng-template #mainNav>{{ node?.title }}</ng-template>
          <button *ngIf="node?.url && depth === 0 && openNodes.length" mat-flat-button color="accent" class="all explore-all-btn ml-auto d-block d-lg-none" >
            <cx-generic-link [url]="node?.url" target="_self" class="t-label-m">
              {{'header.exploreAll' | cxTranslate}}
            </cx-generic-link>
          </button>
        </p>
        <mat-icon *ngIf="flyout && node?.children?.length > 0" svgIcon="right-arrow" [ngClass]="'right-arrow-'+depth">
        </mat-icon>
      </span>
    </ng-template>

    <div class="wrapper nav-wrapper" [ngClass]="'nav-wrapper-'+depth" *ngIf="node?.children?.length > 0"
      [ngClass]="[(node?.navBanner && node?.navBanner?.length > 0 && (navBannerSection?.bannerData && navBannerSection?.bannerData?.length)) ? 'hasCoops' : 'noCoops']">
      <div class="childs nav-link-wrapper" [ngClass]="'nav-link-wrapper-'+depth"  [attr.depth]="getTotalDepth(node)"
        [attr.columns]="getColumnCount(node?.children?.length)">
        <div class="explore-all-section justify-content-between w-100 align-items-center"
          *ngIf="node?.url && depth === 0">
          <p class="explore-all-span t-headerLink-normal">{{ node?.title }}</p>
          <button mat-flat-button color="accent" class="all explore-all-btn" >
            <cx-generic-link [url]="node?.url" target="_self" class="t-label-m">
              {{'header.exploreAll' | cxTranslate}}
            </cx-generic-link>
          </button>
        </div>
        <!-- Second Nav -->
        <ng-container *ngFor="let child of node?.children">

          <ng-container *ngIf="depth < 3">
            <ng-container *ngTemplateOutlet="nav; context: { node: child, depth: depth + 1 }">
            </ng-container>
          </ng-container>

          <ng-container *ngIf="depth === 3">
            <nav [ngClass]="'main-nav-'+depth" class="">
              <cx-generic-link [url]="child.url ? child.url : '/'" target="_self"
                [style]="child.styleAttributes" [class]="child.styleClasses"
                [ngClass]="'t-menucategory-normal main-nav-span-'+depth" class="custom-nav-link">
                {{ child.title }}
              </cx-generic-link>
            </nav>
          </ng-container>

        </ng-container>
        <!-- Second Nav -->
      </div>
      <div *ngIf="navBannerSection?.bannerData && navBannerSection?.bannerData?.length > 0 && depth === 0"
        class="coops-wrapper d-none d-lg-block">
        <div *ngIf="navBannerSection?.isHorizontal; else vertical">
          <div *ngFor="let coop of navBannerSection?.bannerData; index as i" [ngClass]="i<3 ? 'section-'+i : 'd-none'" 
            class="coops-wrapper-section horizontal">
            <cx-generic-link class="t-title-m" url="{{coop?.productfeatureareaurllink}}"
              target="_self">
              <img src="{{imageUrl}}/{{coop?.productfeatureareaimage?.url}}">
            </cx-generic-link>
          </div>
        </div>
        <ng-template #vertical>
          <div *ngFor="let coop of navBannerSection?.bannerData; index as i" [ngClass]="i === 0 ? 'section-'+i : 'd-none'"
            class="coops-wrapper-section vertical">
            <cx-generic-link class="t-title-m" url="{{coop?.productfeatureareaurllink }}"
              target="_self">
              <img src="{{imageUrl}}/{{coop?.productfeatureareaimage?.url}}">
            </cx-generic-link>
          </div>
        </ng-template>

      </div>
    </div>
  </nav>

</ng-template>