import { Component, HostBinding } from '@angular/core';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-custom-banner',
  templateUrl: './custom-banner.component.html'
})
export class CustomBannerComponent{

  @HostBinding('class') styleClasses: string;

  data$ = this.component.data$.pipe(
    tap((data:any) => (this.styleClasses = data?.styleClasses))
  );

  constructor(protected component: CmsComponentData<CmsBannerComponent | any>) {}

  /**
   * Returns `_blank` to force opening the link in a new window whenever the
   * `data.external` flag is set to true.
   */
  getTarget(data: CmsBannerComponent | any): any {
    return data.external === 'true' || data.external === true ? '_blank' : null;
  }
}
