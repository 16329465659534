import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { GTMService } from 'src/app/shared/services/common/gtm.service';

@Component({
  selector: 'app-reusable-warning-modal',
  templateUrl: './reusable-warning-modal.component.html'
})
export class ReusableWarningModalComponent implements OnInit {

  totalItemToBeRemoved:any;
  entries:any;

  constructor(@Inject(MAT_DIALOG_DATA) public data:any, private customHttp: BoxoutEndpointService,
  public dialogRef: MatDialogRef<ReusableWarningModalComponent>, private gtmService: GTMService) { }

  ngOnInit(): void {
    if(this.data){
      this.totalItemToBeRemoved=this.data?.totalItemToBeRemoved;
      this.entries=this.data?.entries;
    }
  }

// this method is used to remove single or multiple  products
  removeFavoriteProducts(){
    let entries=this.entries;

    let apiURL = `users/${this.data?.displayUid}/wishlists/deleteWishlistentries/${encodeURIComponent(this.data?.wishListName)}?fields=DEFAULT`;
    this.customHttp.post(apiURL, { entries }).subscribe(
      (resp: any) => {
        if(resp?.success === "Success"){
          if(this.data?.isGlobalRemoveSelected) {
            let gaPayload = this.data?.gaItems;
            let totalItemsPrice = 0;
            gaPayload?.forEach((element: any, index: any) => {
              totalItemsPrice = totalItemsPrice + (element?.price * parseInt(element?.quantity));
            })
            this.gtmService.removeAllProductsFromWishlist(gaPayload , totalItemsPrice);
          } else {
            this.gtmService.removeProductsFromWishlist(this.data?.gaItems);
          }
          this.dialogRef.close("productRemoved"); 
          
        }else if(resp?.success === "Fail"){
          this.dialogRef.close("productNotRemoved"); 
        }    

        },
      (error) => {}
    );
  }

}
