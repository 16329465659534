import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { CustomValidationService } from 'src/app/shared/services/common/custom-validation.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import * as fromApp from '../../../core/store/app.reducer';

@Component({
  selector: 'app-reusable-edit-email-modal',
  templateUrl: './reusable-edit-email-modal.component.html'
})
export class ReusableEditEmailModalComponent implements OnInit, OnDestroy {
  emailRegex = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[A-Za-z]{2,15}';
  validPassword: boolean = true;
  validEmail: boolean = true;
  uid: any;
  email: any;
  emptyEmail: boolean;
  emptyreEnterEmail: boolean;
  emptyPassword: boolean;
  showSuccessMsg: boolean = false;
  themesForScroll: any = this.document.getElementsByClassName('cdk-global-overlay-wrapper');
  user: User;
  userSub: Subscription;
  page: any;
  query: any;

  constructor(
    private formBuilder: FormBuilder,
    private customValidator: CustomValidationService,
    private headerService: HeaderService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private customHttp: BoxoutEndpointService,
    public dialogRef: MatDialogRef<ReusableEditEmailModalComponent>,
    @Inject(DOCUMENT) private document: Document,
    private store: Store<fromApp.AppState>
  ) { }

  editEmailForm = this.formBuilder.group(
    {
      email: ['', [Validators.required,
        Validators.pattern(this.emailRegex)
      ]],
      reEnterEmail: ['', [Validators.required,
        Validators.pattern(this.emailRegex)
      ]],
      password: ['', [Validators.required]],
    },
    {
      validator: [
        this.customValidator.emailMatchValidator(
          'email',
          'reEnterEmail'
        )
      ]
    }
  );

  ngOnInit(): void {
     // to get the email and uid value
     this.route.queryParams.subscribe((params) => {
      this.email = params.email;
      this.uid = params.siebelRowId;
      this.page = params.page;
      this.query = params.query;
    });
    this.themesForScroll[0].className += " custom-modal";
  }

  //Email change API
  emailchange(request: any) {
    let apiUrl = `orgUsers/current/updateLogin`;
    return this.customHttp.put(apiUrl, request);
  }

  valueChange(){
    this.validPassword= true;
  }
  valueChangeEmail(){
    this.validEmail= true;
  }

  //Savechange click Functionality
  onSaveChangesClick(){
    if(this.editEmailForm.value.email !== '' && this.editEmailForm.value.email !== null 
        && this.editEmailForm.value.reEnterEmail !== '' && this.editEmailForm.value.reEnterEmail !== null
        && this.editEmailForm.value.password !== '' && this.editEmailForm.value.password !== null
        && !this.editEmailForm.get('email')?.hasError('pattern') && !this.editEmailForm.get('reEnterEmail')?.hasError('pattern')
      ){
        if(this.editEmailForm.value.reEnterEmail == this.editEmailForm.value.email){
          let obj = {
            "email": this.email,  //current email id
            "newEmail": this.editEmailForm.value.reEnterEmail, // new email id
            "password": this.editEmailForm.value.password //current password
          }
          this.emailchange(obj).subscribe(
            (data:any)=>{
              this.email = this.editEmailForm.value.reEnterEmail;
              this.validEmail = true;
              this.showSuccessMsg = true;
              var emailData = {email: this.editEmailForm.value.reEnterEmail, showSuccess: this.showSuccessMsg}
              this.router.navigate(['my-account/manageUser'],
              { 
                queryParams: { 
                  email: this.email,
                  siebelRowId: this.uid,
                  page: this.page,
                  query: this.query
                }
              });
              this.dialogRef.close(emailData);
            },
            (error: any) =>{
              if(error?.error?.errors[0]?.type == "PasswordMismatchError"){
                this.validPassword = false;
              }
              else{
                this.validEmail = false;
              }
            }
          );
          this.changeDetectorRef.detectChanges();
        }
    }
    else {
      if(this.editEmailForm.value.email == '' || this.editEmailForm.value.email == null){
        this.emptyEmail = true;
      }
      else{
        this.emptyEmail = false;
      }
      if(this.editEmailForm.value.reEnterEmail == '' || this.editEmailForm.value.reEnterEmail == null){
        this.emptyreEnterEmail = true;
      } else{
        this.emptyreEnterEmail = false;
      }
      if(this.editEmailForm.value.password == '' || this.editEmailForm.value.password == null){
        this.emptyPassword = true
      } else{
        this.emptyPassword = false;
      }
    }
  }

  // Making Api call to get user data
  getDetails(userId: any){
    let apiURL = `users/${userId}/orgCustomers/${userId}`;
    return this.customHttp.get(apiURL);
  }

  ngOnDestroy(): void {    
    if(this.userSub) {
      this.userSub.unsubscribe();
    }
  }

}
