<app-header *ngIf="!isSalesRep"></app-header>

<!-- 500 Error alert box - START -->
<div *ngIf="error" class="pt-3 mx-3 rounded">
  <reusable-alert-box [alertType]="'warningAlertBox'" [alertMsg]="error500"></reusable-alert-box>
</div>
<!-- 500 Error alert box - END -->

<ng-template cxOutlet="cx-storefront">
  <div class="bg-custom">
    <div
      id="custom-backdrop"
      (mouseenter)="removeBackdrop()"
      (click)="removeBackdrop()"
      class="custom-backdrop hide-backdrop"
    ></div>

    <div id="triggenLoginDialog" (click)="openLoginDialog()" hidden></div>
    <div id="triggenForgotPwdDialog" (click)="openFrgPwdDialog()"hidden></div>
    
    <router-outlet></router-outlet>
  </div>
</ng-template>
<!--global page loader-->
<!-- <app-page-loader></app-page-loader> -->
<ng-http-loader [entryComponent]="pageLoaderComponent"  
[filteredUrlPatterns]="['doSearch','us-autocomplete-pro.api.smartystreets.com','assets','accountDropdown','getAccountsList','realTimePriceForSearch','youMayAlsoLike','recentlyViewed','cartRecommendations', 'ai.celebros-analytics.com', 'ai2.celebros-analytics.com', 'googletagmanager', 'www.google-analytics.com', 'bam.nr-data.net', 'www.avochato.com', 'nova.collect.igodigital.com']"
opacity=".6"></ng-http-loader>
<app-footer *ngIf="!isSalesRep"></app-footer>
