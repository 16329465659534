import { Component } from '@angular/core';

@Component({
  selector: 'app-rewards-credits',
  templateUrl: './rewards-credits.component.html'
})
export class RewardsCreditsComponent {

  constructor() { }
}
