import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  CmsService, PageMetaConfig,
  PageMetaService,
  UnifiedInjector
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class CustomPageMetaService extends PageMetaService {

  

  constructor(
    protected cms: CmsService,
    protected unifiedInjector: UnifiedInjector,
    protected pageMetaConfig: PageMetaConfig,
    @Inject(PLATFORM_ID) protected platformId: string,
    private util: UtilService
  ) {
    super(cms, unifiedInjector,pageMetaConfig,platformId);
  }

//set page title is returned to spartacus
  getMeta(): Observable<any> {
    return this.util.metaDataSource$;
  }
  
}
