import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OrderService {

  private pageHeading: string = '';
  private previousPage: string = '';

  constructor() {}

  setPagHeadingInstance(headingInstance: any) {
    this.pageHeading = headingInstance;
  }

  getPageHeadingInstance() {
    return this.pageHeading;
  }

  setPreviousPage(page: string) {
    this.previousPage = page;
  }

  getPreviousPage() {
    return this.previousPage;
  }
}
