<div [ngClass]="(shipToCartComponentName==='shipTocartBusinessAccount')? 'ship-to-business-account-container' :'' ">
<div class="d-flex flex-column container create-business-account-dialog">
  <div class="d-flex flex-row justify-content-between mb-4" *ngIf="shipToCartComponentName!=='shipTocartBusinessAccount'">
    <div>
      <section class="t-title-l">{{ "account.createAccount.title" | cxTranslate }}
      </section>
    </div>
    <div>
      <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon" >close</mat-icon>
    </div>
  </div>



  <div class="pb-2" *ngIf="shipToCartComponentName==='shipTocartBusinessAccount'">
      <div 
      class="ship-to-business-header pb-3 d-flex flex-row justify-content-between align-items-center"
    > <mat-icon (click)="openShipToBusinessDrawer()" svgIcon="back"></mat-icon> 
      <div class="t-headerLink-normal pt-2">{{ "account.cartShipToBusiness.selectBusiness" | cxTranslate }}</div>
      <div class="mt-1">
        <button
          mat-flat-button
          color="primary"
          (click)="openShipToBusinessDrawer()"
          class="sitelinks-close"
          aria-label="close drawer"
        >
          <mat-icon> {{ "header.close" | cxTranslate }}</mat-icon>
        </button>
      </div>
    </div>
    </div>

  <div class="mb-4">
    <section class="t-title-l">{{ "account.createAccount.businessInfo" | cxTranslate }}
    </section>
  </div>
  <div class="d-flex flex-column">
    <form [formGroup]="createBusinessAccountForm" (submit)="createBusinessAccount()">
      <div class="create-business-account-input-display mb-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "account.createAccount.firstNameTitle" | cxTranslate }}
          </mat-label>
          <input
            matInput
            type="text"
            class="create-business-account-input-outline"
            formControlName="firstName"
            maxlength="20"
            (paste)="checkSpecChar($event)" (keypress)="checkSpecChar($event)"
          />
          <mat-error
            *ngIf="
              createBusinessAccountForm.get('firstName')?.touched &&
              createBusinessAccountForm.get('firstName')?.hasError('required')
            "
            >{{ "account.createAccount.firstNameRequired" | cxTranslate }}
            </mat-error
          >
        </mat-form-field>
      </div>

      <div class="create-business-account-input-display mb-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "account.createAccount.lastNameTitle" | cxTranslate }}
          </mat-label>
          <input
            matInput
            type="text"
            class="create-business-account-input-outline"
            formControlName="lastName"
            maxlength="20"
            (paste)="checkSpecChar($event)" (keypress)="checkSpecChar($event)"
          />
          <mat-error
           *ngIf="
              createBusinessAccountForm.get('lastName')?.touched &&
              createBusinessAccountForm.get('lastName')?.hasError('required')
            "
            >{{ "account.createAccount.lastNameRequired" | cxTranslate }}
         </mat-error
          >
        </mat-form-field>
      </div>

      <div class="create-business-account-input-display mb-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "account.createAccount.businessNameTitle" | cxTranslate }}
          </mat-label>
          <input
            matInput
            type="text"
            class="create-business-account-input-outline"
            formControlName="businessName"
            maxlength="50"
            (paste)="checkSpecChar($event)" (keypress)="checkSpecChar($event)"
          />
          <mat-error
                      *ngIf="
                        createBusinessAccountForm.get('businessName')?.touched &&
                        createBusinessAccountForm.get('businessName')?.hasError('required')
                      "
                      >{{ "account.createAccount.businessNameRequired" | cxTranslate }}
                      </mat-error
                    >
        </mat-form-field>
      </div>

      <div class="create-business-account-input-display mb-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "account.createAccount.attnTitle" | cxTranslate }}
          </mat-label>
          <input
            matInput
            type="text"
            class="create-business-account-input-outline"
            formControlName="attn"
            maxlength="30"
          />
          <mat-error></mat-error>
        </mat-form-field>
      </div>

      <div>
        <app-reusable-address-form [componentNameForCart]="shipToCartComponentName"></app-reusable-address-form>
      </div>

      <div *ngIf="shipToCartComponentName!=='shipTocartBusinessAccount'" class="d-flex flex-column flex-lg-row phone-input-or-mobile-input mb-3">
        <div class="mr-0 mr-lg-4 bus-acct-phone">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ "account.createAccount.phoneNumber" | cxTranslate }}
            </mat-label>
            <input
              matInput
              type="tel" pattern="[0-9]*" inputmode="numeric"
              class="create-business-account-input-outline"
              formControlName="phoneNumber"
              formatNumber
              minlength="10"
              maxlength="10"
            />
            <mat-error
              *ngIf="
                createBusinessAccountForm.get('phoneNumber')?.touched &&
                createBusinessAccountForm
                  .get('phoneNumber')
                  ?.hasError('required')
              "
              >{{ "account.createAccount.phoneNumberRequired" | cxTranslate }}
              </mat-error
            >
            <mat-error
              *ngIf="
                createBusinessAccountForm.get('phoneNumber')?.touched &&
                createBusinessAccountForm
                  .get('phoneNumber')
                  ?.hasError('minlength')
              "
              >{{ "account.createAccount.phoneNumberValid" | cxTranslate }}
              </mat-error
            >
          </mat-form-field>
          <div class="phone-order-info t-body-m">{{ "account.createAccount.phoneNumberOrderInfo" | cxTranslate }}</div>
        </div>

        <div class="bus-acct-phone">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ "account.createAccount.mobileNumber" | cxTranslate }}
            </mat-label>
            <input
              matInput
              type="tel" pattern="[0-9]*" inputmode="numeric"
              class="create-business-account-input-outline"
              formControlName="mobileNumber"
              formatNumber
              minlength="10"
              maxlength="10"
            />
            <mat-error
                          *ngIf="
                            createBusinessAccountForm.get('mobileNumber')?.touched &&
                            createBusinessAccountForm
                              .get('mobileNumber')
                              ?.hasError('minlength')
                          "
                          >{{ "account.createAccount.mobileNumberValid" | cxTranslate }}
                          </mat-error
                       >
          </mat-form-field>
        </div>
      </div>


      <div *ngIf="shipToCartComponentName==='shipTocartBusinessAccount'" class="d-flex flex-row justify-content-between cart-shipTo-businessAccount mb-3">
        <div class="mr-0 mr-lg-4 phone-number-section">
          <mat-form-field appearance="outline">
            <mat-label>{{ "account.createAccount.phoneNumber" | cxTranslate }}
            </mat-label>
            <input
              matInput
              type="tel" pattern="[0-9]*" inputmode="numeric"
              class="create-business-account-input-outline"
              formControlName="phoneNumber"
              formatNumber
              minlength="10"
              maxlength="10"
            />
            <mat-error
              *ngIf="
                createBusinessAccountForm.get('phoneNumber')?.touched &&
                createBusinessAccountForm
                  .get('phoneNumber')
                  ?.hasError('required')
              "
              >{{ "account.createAccount.phoneNumberRequired" | cxTranslate }}
              </mat-error
            >
            <mat-error
              *ngIf="
                createBusinessAccountForm.get('phoneNumber')?.touched &&
                createBusinessAccountForm
                  .get('phoneNumber')
                  ?.hasError('minlength')
              "
              >{{ "account.createAccount.phoneNumberValid" | cxTranslate }}
              </mat-error
            >
          </mat-form-field>
          <div class="phone-order-info t-body-m">{{ "account.createAccount.phoneNumberOrderInfo" | cxTranslate }}</div>
        </div>

        <div class="mobile-number-section">
          <mat-form-field appearance="outline">
            <mat-label>{{ "account.createAccount.mobileNumber" | cxTranslate }}
            </mat-label>
            <input
              matInput
              type="tel" pattern="[0-9]*" inputmode="numeric"
              class="create-business-account-input-outline"
              formControlName="mobileNumber"
              formatNumber
              minlength="10"
              maxlength="10"
            />
            <mat-error
                          *ngIf="
                            createBusinessAccountForm.get('mobileNumber')?.touched &&
                            createBusinessAccountForm
                              .get('mobileNumber')
                              ?.hasError('minlength')
                          "
                          >{{ "account.createAccount.mobileNumberValid" | cxTranslate }}
                          </mat-error
                       >
          </mat-form-field>
        </div>
      </div>


      <div class="create-business-account-input-display">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "account.createAccount.deliverNotesTitle" | cxTranslate }}
          </mat-label>
          <input
            matInput
            type="text"
            class="create-business-account-input-outline"
            formControlName="deliverNote"
            maxlength="60"
          />
          <mat-error></mat-error>
        </mat-form-field>
      </div>
      <div class="mb-4">
          <section class="t-title-l">
            {{ "account.createAccount.businessEmail" | cxTranslate }}
          </section>
        </div>
        <div class="create-business-account-input-display mb-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ "account.registerForm.emailAddress" | cxTranslate }}</mat-label>
            <input
              matInput
              type="text"
              class="create-business-account-input-outline"
              formControlName="email"
              maxlength="60"
            />
            <mat-error
            *ngIf="
              createBusinessAccountForm.get('email')?.touched &&
              createBusinessAccountForm.get('email')?.hasError('required')
            "
            >{{ "account.registerForm.emailRequired" | cxTranslate }}
          </mat-error>
          <mat-error
          *ngIf="
          createBusinessAccountForm.get('email')?.touched &&
          createBusinessAccountForm.get('email')?.hasError('pattern')
          "
          >{{ "account.emailModal.emailInvalidError" | cxTranslate }}
        </mat-error>
          </mat-form-field>
        </div>

        <div class="create-business-account-input-display">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ "account.registerForm.reEnterEmailAddress" | cxTranslate }}</mat-label>
            <input
              matInput
              type="text"
              class="create-business-account-input-outline"
              formControlName="confirmEmail"
              maxlength="60"
            />
            <mat-error
            *ngIf="
            createBusinessAccountForm.get('confirmEmail')?.touched &&
            createBusinessAccountForm.get('confirmEmail')?.hasError('required')
            "
            >
            {{ "account.emailModal.reEnterEmailRequiredError" | cxTranslate }}
          </mat-error>
          <mat-error
          *ngIf="
          createBusinessAccountForm.get('confirmEmail')?.touched &&
          createBusinessAccountForm.get('confirmEmail')?.hasError('pattern')
          "
          >
          {{ "account.emailModal.reEnterMisMatchError" | cxTranslate }}
        </mat-error>
          </mat-form-field>
        </div>
      <div class="d-flex justify-content-end">
        <div>
          <button
            mat-flat-button mat-dialog-close
            type="button"
            
            class="mr-2"
          >
          {{ "account.createAccount.cancelA" | cxTranslate }}
          </button>
        </div>
        <div class="create-business-account-submit-button">
          <button
            mat-flat-button
            type="submit"
          >
          {{ "account.createAccount.createA" | cxTranslate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
</div>