import { Injectable } from '@angular/core';
import { SiteContextConfig, TranslationService, WindowRef } from '@spartacus/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { global } from 'src/app/shared/translations/en/global';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  
  error500 = new Subject<boolean>();
  translationSub: Subscription
  translationValue: string
  translations = global.account.breadCrumb;
  emailSupportTranslations = global.account.supportEmailForBaseSiteName;
  siteNameForCardTranslations = global.account.siteNameForCard;

  private metaData = new Subject<any>();
  metaDataSource$: Observable<any> = this.metaData?.asObservable(); 

  constructor(private translation: TranslationService,private config: SiteContextConfig,private windowRef: WindowRef
    ) {}

  // use this translate method for getting translation in .ts files only
  // need to pass mapping as param
  // when translation is received, call the unSubscribeTranslate() below for unsubscribing the subscription.
  translate(mapping: string) {
    this.translationSub = this.translation.translate(mapping).subscribe(data => {
      this.translationValue = data;
    });
    return this.translationValue;
  }

  unSubscribeTranslate() {
    if(this.translationSub){
      this.translationSub.unsubscribe();
    }
  }

  //This method is used to get the base site name
  checkBaseSite() {
     let baseSiteName: any; 
     baseSiteName = this.config?.context?.baseSite;
    switch (baseSiteName[0]) {
      case 'mmsite':
        baseSiteName = this.translations.mmsite
        break;
      case 'ptsite':
        baseSiteName = this.translations.ptsite
        break;
      case 'spasite':
        baseSiteName = this.translations.spasite
        break;
      case 'fwsite':
        baseSiteName = this.translations.fwsite
        break;
      case 'omsite':
        baseSiteName = this.translations.omsite
        break;
      case 'musite':
        baseSiteName = this.translations.musite
        break;
      case 'chsite':
        baseSiteName = this.translations.chsite
        break;
      case 'mesite':
        baseSiteName = this.translations.mesite
        break;
      case 'bxdsite':
        baseSiteName = this.translations.boxout
        break;
      case 'bxdportal':
        baseSiteName = this.translations.bxdportal
        break;
      default:
        baseSiteName = this.translations.boxout
        break;
    }
    return baseSiteName;
  }

  getBaseSiteNameForCard() {
    let baseSiteName: any; 
    baseSiteName = this.config?.context?.baseSite;
   switch (baseSiteName[0]) {
     case 'mmsite':
       baseSiteName = this.siteNameForCardTranslations.mmsite
       break;
     case 'ptsite':
       baseSiteName = this.siteNameForCardTranslations.ptsite
       break;
     case 'spasite':
       baseSiteName = this.siteNameForCardTranslations.spasite
       break;
     case 'fwsite':
       baseSiteName = this.siteNameForCardTranslations.fwsite
       break;
     case 'omsite':
       baseSiteName = this.siteNameForCardTranslations.omsite
       break;
     case 'musite':
       baseSiteName = this.siteNameForCardTranslations.musite
       break;
     case 'chsite':
       baseSiteName = this.siteNameForCardTranslations.chsite
       break;
     case 'mesite':
       baseSiteName = this.siteNameForCardTranslations.mesite
       break;
     case 'bxdsite':
       baseSiteName = this.siteNameForCardTranslations.boxout
       break;
     default:
       baseSiteName = this.siteNameForCardTranslations.boxout
       break;
   }
   return baseSiteName;
 }


  checkBaseSiteForEmailSupport() {
    let baseSiteName: any; 
    baseSiteName = this.config?.context?.baseSite;
   switch (baseSiteName[0]) {
     case 'mmsite':
       baseSiteName = this.emailSupportTranslations.serviceSupportEmail+this.emailSupportTranslations.mmsite+this.emailSupportTranslations.supportEmailTwo;
       break;
     case 'ptsite':
       baseSiteName = this.emailSupportTranslations.serviceSupportEmail+this.emailSupportTranslations.ptsite+this.emailSupportTranslations.supportEmailTwo;
       break;
     case 'spasite':
       baseSiteName = this.emailSupportTranslations.salesSupportEmail+this.emailSupportTranslations.spasite+this.emailSupportTranslations.supportEmailTwo;
       break;
     case 'fwsite':
       baseSiteName = this.emailSupportTranslations.serviceSupportEmail+this.emailSupportTranslations.fwsite+this.emailSupportTranslations.supportEmailTwo;
       break;
     case 'omsite':
       baseSiteName = this.emailSupportTranslations.genericSupportEmail+this.emailSupportTranslations.omsite+this.emailSupportTranslations.supportEmailTwo;
       break;
     case 'musite':
       baseSiteName = this.emailSupportTranslations.genericSupportEmail+this.emailSupportTranslations.musite+this.emailSupportTranslations.supportEmailTwo;
       break;
     case 'chsite':
       baseSiteName = this.emailSupportTranslations.serviceSupportEmail+this.emailSupportTranslations.chsite+this.emailSupportTranslations.supportEmailTwo;
       break;
     case 'bxdsite':
       baseSiteName = this.emailSupportTranslations.serviceSupportEmail+this.emailSupportTranslations.boxout+this.emailSupportTranslations.supportEmailTwo;
       break;
     default:
       baseSiteName = this.emailSupportTranslations.serviceSupportEmail+this.emailSupportTranslations.boxout+this.emailSupportTranslations.supportEmailTwo;
       break;
   }
   return baseSiteName;
 }
 
  //page title data is set here and sent to OOTB page meta service
  setMeta(data:any){
    if((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("contentPageTitle"))))){
      //setting page title for content page
      let contentPageData={heading:this.windowRef.sessionStorage?.getItem("boxoutTitle"), title:this.windowRef.sessionStorage?.getItem("boxoutTitle")};
      if(contentPageData?.heading === "Error Details"){
        //setting page title for Page not found page
        let pageNotFoundPageTitle={heading:"Page Not Found | " + this.checkBaseSite(), title:"Page Not Found | " +  this.checkBaseSite()};
        this.metaData.next(pageNotFoundPageTitle);
      }else{
        this.metaData.next(contentPageData);
      }
      this.windowRef.sessionStorage?.removeItem("contentPageTitle");
    }else{
      //setting page title for non content page 
      this.metaData.next(data);
    }
  }

  //getAccessToken
  getAccessToken() {
    let spartacusSession: any;
    spartacusSession = this.windowRef?.localStorage?.getItem('spartacus⚿⚿auth') ? this.windowRef?.localStorage?.getItem('spartacus⚿⚿auth') : '';
    let spartacusSessionObj = JSON.parse(spartacusSession);
    if (spartacusSessionObj && spartacusSessionObj?.token && Object.keys(spartacusSessionObj?.token)?.length !== 0) {
      return spartacusSessionObj?.token?.access_token;
    }
  }
}
