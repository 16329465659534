import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptService {

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  /**
   * Append the JS tag to the Document Body.
   * @param src The path to the script
   */
  public loadScript(src: string) {
    let body = <HTMLDivElement> this.document.head;
    let script = this.document.createElement('script');
    script.innerHTML = '';
    script.src = src;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  public loadScriptContent(src: string) {
    let body = <HTMLDivElement> this.document.head;
    let script = this.document.createElement('script');
    script.innerHTML = '';
    script.src = src;
    script.async = true;
    //script.defer = true;
    body.appendChild(script);
  }

   /**
   * Append the JS tag to the Document Body.
   * @param src The path to the script
   */
    public loadScriptWithContent(scriptContent: string) {
      let body = <HTMLDivElement> this.document.head;
      let script = this.document.createElement('script');
      script.innerHTML = scriptContent;
      //script.async = true;
      script.defer = true;
      body.appendChild(script);
    }

  /**
   * Append the JS tag to the Document Body and Head.
   * @param src The path to the script
   * @param tagName The name of tag i.e. Head or Body
   * @param ele The name of elemet
   */
  public loadGTAScript(src: string,tagName:any,ele:any) {
    let tag;
    let script;
    if(tagName === 'body'){
      tag = <HTMLDivElement>this.document.body;
    }else{
      tag = <HTMLDivElement>this.document.head;
    }
    if(ele === 'script'){
      script = this.document.createElement('script');
      script.innerHTML = src;
    }else{
      script = this.document.createElement('noscript');
      script.innerHTML = src;
    }
    tag.appendChild(script);
  }

  /**
   * This function is used to dynamically load the favicon
   */
   loadFavIcons(favicon:any) {
    this.document.getElementById('app-favicon')?.setAttribute('href', `/assets/favicons/${favicon}`);
   }
}