import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-cart-credit-modal',
  templateUrl: './cart-credit-modal.component.html',
})
export class CartCreditModalComponent implements OnInit {
  rewardsApplied: any;
  rewardsDiscount: any;
  brandHints: Array<string> = [];
  modalType: any;
  windowObj: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public modaltype: any,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.windowObj = this.document.defaultView;
    if (modaltype) {
      this.modalType = modaltype;
    }
  }

  ngOnInit() {
    this.rewardsApplied = this.modaltype?.rewardsApplied;
    this.rewardsDiscount = this.modaltype?.rewardsDiscount;

    if (this.rewardsApplied) {
      this.rewardsApplied?.brands.forEach((element: any) => {
        if (element.hint) {
          this.brandHints.push(element.brandName);
        }
      });
    }
  }
}
