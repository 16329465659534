<div class="order-detail-total" *ngIf="orderRewards?.brands?.length">
  <p class="t-productTile-orderDetailHeader p-2 order-detail-total-head">
    {{ "account.rewards.rewardsHeader" | cxTranslate }}
  </p>

  <div *ngFor="let item of orderRewards?.brands" class="m-3 pl-3 pb-3 pt-1 pr-2 d-flex justify-content-between order-reward-border">
    <div class="d-flex flex-row">
      <span class="t-rewards-order-text order-reward-credit-label pr-2">
        {{ 'account.rewards.creditOrderLabel' | cxTranslate}}
      </span>
      <span class="t-rewards-order-brand-text order-confirmation-reward-brand">
        | {{ item?.brandName }}
      </span>
    </div>

    <span>
      <p class="m-0 font-weight-bold" [innerHTML]="item?.appliedRewards?.formattedValue | formatCurrency"></p>
    </span>
  </div>

  <div class="d-flex justify-content-end pl-3 pb-4 m-3">
    <span class="">
      <div class="t-label-s"> {{ 'account.rewards.orderTotalApplied' | cxTranslate}}</div>
      <p class="m-0 font-weight-bold" [innerHTML]="orderRewards?.totalAppliedRewards?.formattedValue | formatCurrency"></p>
    </span>
  </div>
</div>