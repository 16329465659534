<div class="cart-item">
    <div class="cart-item-header d-flex">
        <div
            class="cart-item-header-number d-flex align-items-center justify-content-center t-cart-product-number-inverse">
            {{indexNum+1}}</div>
        <div class="cart-item-header-details d-flex py-1 px-2 w-75">
            <div class="cart-item-header-width pr-4">
                <div class="t-body-s">{{'cart.item' | cxTranslate}}</div>
                <div class="t-title-m cursor-pointer" (click)="navigateToSEO(cartItem)">{{cartItem?.product?.code}}</div>
            </div>
            <div class="cart-item-header-width pr-4">
                <div class="t-body-s">{{'cart.mfgItem' | cxTranslate}}</div>
                <div class="t-title-m cursor-pointer" (click)="navigateToSEO(cartItem)">{{cartItem?.product?.baseOptions.length ?
                    cartItem?.product?.baseOptions[0]?.selected?.manufacturerAid : null}}</div>
            </div>
            <div class="cart-item-header-width pr-4" *ngIf="baseSiteNameVal !== 'mesite'">
                <div class="t-body-s">{{'cart.hcpcs' | cxTranslate}}</div>
                <div class="t-title-m cursor-pointer" (click)="navigateToSEO(cartItem)">{{cartItem?.product?.baseOptions.length ?
                    cartItem?.product?.baseOptions[0]?.selected?.hcpc : null}}</div>
            </div>
        </div>
    </div>

    <div class="p-3 d-flex cart-item-main">
        <!-- Product image Section Starts -->
        <div class="cart-item-img mt-2">
            <a (click)="navigateToSEO(cartItem)" class="cursor-pointer">
                <img *ngIf="cartItem?.product?.productImage?.url" src="{{imageUrl}}{{cartItem?.product?.productImage?.url}}" alt="{{cartItem?.product?.name}}">
                <img *ngIf="cartItem?.product?.productImage?.url === undefined || cartItem?.product?.productImage?.url === '' || !(cartItem?.product?.productImage?.url)"  src="../../../../../assets/MissingImage.svg" alt="MissingImage"/>

            </a>
        </div>
        <!-- Product image Section ENds -->

        <!-- Product Name / Brand Section Starts -->
        <div class="d-flex flex-column pl-3 pr-2 mx-1 cart-item-detail w-100">
            <div class="cursor-pointer cart-item-name t-cart-product-name" (click)="navigateToSEO(cartItem)"
            [innerHTML]="cartItem?.product?.name"></div>
            <div class="cursor-pointer cart-item-brand t-label-s pt-2" (click)="navigateToSEO(cartItem)">
                {{cartItem?.product?.brandData?.name | uppercase}}
            </div>
            <div class="pt-2 mt-1">
                <div
                    *ngIf="cartItem?.product?.baseOptions.length && cartItem?.product?.baseOptions[0]?.selected?.variantOptionQualifiers.length === 1">
                    <ng-container
                        *ngIf="cartItem?.product?.selectableAtrribute?.includes(cartItem?.product?.baseOptions[0]?.selected?.variantOptionQualifiers[0]?.qualifier)">
                        <div class="t-productTile-productGroup mb-1 t-body-s">
                            {{cartItem?.product?.baseOptions[0]?.selected?.variantOptionQualifiers[0]?.name}} :
                            {{cartItem?.product?.baseOptions[0]?.selected?.variantOptionQualifiers[0]?.value}}
                        </div>
                    </ng-container>
                </div>

                <div class="d-flex-column"
                    *ngIf="cartItem?.product?.baseOptions.length && cartItem?.product?.baseOptions[0]?.selected?.variantOptionQualifiers.length > 1">
                    <div class="t-productTile-productGroup mb-1"
                        *ngFor="let attr of cartItem?.product?.baseOptions[0]?.selected?.variantOptionQualifiers; last as isLast">
                        <ng-container *ngIf="cartItem?.product?.selectableAtrribute?.includes(attr?.qualifier)">
                            <div class="t-body-s ">{{attr?.name}} : {{attr?.value}} </div>
                        </ng-container>
                    </div>
                </div>

            </div>
        </div>
        <!-- Product Name / Brand Section Ends -->

        <div class="d-flex flex-column w-100 mt-3 mt-lg-0">
            <!-- Qty and price Section Starts -->
            <div class="text-center mx-0 mt-0 mb-3 product-tile-quantity-error" *ngIf="cartItem?.exclusionItem">
              <p class="t-productTile-boldPrice m-0 text-uppercase">{{ 'account.productTile.exclusionItem' | cxTranslate }}</p>
            </div>
            <div class="text-center mx-0 mt-0 mb-3 product-tile-quantity-error" *ngIf="cartItem?.orderExclusionItem">
              <p class="t-productTile-boldPrice m-0 text-uppercase">{{ 'account.productTile.orderExclusionItem' | cxTranslate }}</p>
            </div>
            <div class="text-center mx-0 mt-0 mb-3 product-tile-multiple-order" *ngIf="componentName!=='orderConfirmation' && currentVariant?.quantityMultiplier && currentVariant?.quantityMultiplier !== 1 && multipleOrder && !maxQuantity &&
            (('inStock' === currentVariant?.stock?.stockLevelStatus || 'outOfStock' === currentVariant?.stock?.stockLevelStatus || currentVariant?.isBackOrder || currentVariant?.vendorDropShipSpecialOrder) && !currentVariant?.isDiscontinued)">
                <p class="t-productTile-boldPrice m-0">{{ 'account.productTile.orderInMultiples' | cxTranslate }}{{currentVariant?.quantityMultiplier}}</p>
            </div>
            <div class="text-center mx-0 mt-0 mb-3 product-tile-quantity-error" *ngIf="quantityError">
                <p class="t-productTile-boldPrice m-0">{{ 'account.productTile.quantityError' | cxTranslate }}</p>
            </div>
            <div class="text-center mx-0 mt-0 mb-3 product-tile-quantity-error" *ngIf="multipleOrderError">
                <p class="t-productTile-boldPrice m-0">{{ 'account.productTile.multipleError' | cxTranslate }}{{currentVariant?.quantityMultiplier}}</p>
            </div>
            <div class="text-center mx-0 mt-0 mb-3 product-tile-max-quantity" *ngIf="maxQuantity">
                <p class="t-productTile-boldPrice m-0">
                    {{ 'account.productTile.maxQuantity' | cxTranslate }}
                </p>
            </div>
            <div [ngClass]=" componentName==='orderConfirmation'  ? 'd-flex justify-content-end pr-5 mr-5 pb-4' : 'pb-4' " *ngIf="cartItem?.productLevelPromotionDetails?.status === 'Applied' && !cartItem?.productLevelPromotionDetails?.isFreeFreightPromo">
                <span class="d-block mt-2">
                    <span class="save-amount px-2 py-1 mr-2">
                        <span class="t-userid-text-bold">{{ 'account.productTile.save' | cxTranslate }}</span>
                        <span class="t-userid-text pl-1">${{ (cartItem?.basePrice?.value - cartItem?.productLevelPromotionDetails?.adjustedUnitPrice).toFixed(2).replace('.00', '')}}</span>                          
                    </span>
                    <span class="t-userid-text-bold"> {{ 'account.productTile.was' | cxTranslate }}
                        <span class="t-userid-text-bold">{{ cartItem?.basePrice?.formattedValue }}</span>    
                    </span>
                </span>
            </div>
            <div
            [ngClass]=" componentName==='orderConfirmation'  ? 'd-flex justify-content-end' : 'cart-item-qty-section' ">
                <div *ngIf="componentName!=='orderConfirmation'" class="cart-item-qty pl-2 pr-4 d-flex align-items-center flex-column">
                    <mat-form-field appearance="outline">
                        <mat-label>{{'cart.qty' | cxTranslate}}</mat-label>
                        <input matInput type="text" class="quantity__input mx-1" [attr.data-qty]="productQty" [attr.data-isupdatable]="makeUpdateCall" id="productQty{{indexNum}}" [(ngModel)]="productQty"
                            (keypress)="keyPressNumbers($event)" (blur)="inputQtyChanged()" (paste)="onPaste($event)" aria-label="quantity"/>
                    </mat-form-field>
                    <div class="t-label-s cart-item-update pt-1 cursor-pointer"
                        (click)="updateCart(cartItem, 'single' , productQty)">{{'cart.update' | cxTranslate}}</div>
                </div>
                <div
                [ngClass]=" componentName==='orderConfirmation'  ? 'mr-4 pr-4' : 'mr-lg-auto' ">
                    <div class="t-label-s">{{ "cart.price" | cxTranslate }} <span
                            *ngIf="cartItem?.product?.baseOptions?.length">/
                            {{cartItem?.product?.baseOptions[0]?.selected?.unit}}</span></div>
                    <div class="pt-1 text-center">
                        <sup class="t-price-tile-s">$</sup>
                        <span class="t-price-currenyTile-s">{{cartItem?.productLevelPromotionDetails?.status === 'Applied' ?
                            cartItem?.productLevelPromotionDetails?.adjustedUnitPrice.toString().split('.')[0].replace('$','') : cartItem.basePrice.formattedValue.toString().split('.')[0].replace('$','')}}</span>
                        <sup class="t-price-currenyFractionTile-s ml-1" *ngIf="cartItem?.product?.baseOptions?.length">
                            {{cartItem?.productLevelPromotionDetails?.status === 'Applied' ? cartItem?.productLevelPromotionDetails?.adjustedUnitPrice.toString().split('.')[1] : cartItem.basePrice.formattedValue.toString().split('.')[1]}}
                        </sup>
                    </div>
                </div>
                <div *ngIf="componentName==='orderConfirmation'" class="order-confirmation-price" >
                    <div class="t-label-s ">{{ 'cart.qty'| cxTranslate }}</div>
                    <div class="pt-1">
                        <span class="t-price-currenyTile-s">{{productQty}}</span>
                     </div>
                </div>
                <div>
                    <div class="t-label-s">{{ "account.order.ext" | cxTranslate }}</div>
                    <div class="pt-2">
                        <sup class="t-price-tile">$</sup>
                        <span class="t-price-currenyTile">{{cartItem?.totalPrice?.formattedValue?.toString().split('.')[0].replace('$','')}}</span>
                        <sup
                            class="t-price-currenyFractionTile ml-1">{{cartItem?.totalPrice?.formattedValue?.toString().split('.')[1]}}</sup>
                    </div>
                </div>
            </div>
            <!-- Qty and price Section Ends -->


            <!-- Inventory Section Starts -->
                <div [ngClass]=" componentName==='orderConfirmation'  ? 'cart-item-orderConfirm' : 'cart-item-cartInventory'">
                <div class="d-flex ml-4 mr-2 my-2 pl-2 justify-content-between">
                    <div>
                        <div class="p-1">
                            <!---->
                            <div class="pb-2 d-flex justify-content-end" *ngIf="componentName==='orderConfirmation'">
                                <p class="m-0">{{'cart.inventory' | cxTranslate}} </p>
                            </div>

                            <div *ngIf="!user && currentVariant">
                                <div class="product-tile-inStock d-flex m-0"
                                    *ngIf="'inStock' === currentVariant?.stock?.stockLevelStatus">
                                    <mat-icon svgIcon="inStock" class="product-tile-inStock-icon mr-2" aria-label="In Stock">
                                    </mat-icon>
                                    <span class="t-product-details-availability-Instock product-tile-inStock-text mr-1">
                                        {{ 'account.productTile.inStock' | cxTranslate }}
                                    </span>
                                </div>
                                <div class="product-tile-backOrdered m-0 d-flex"
                                    *ngIf="'outOfStock' === currentVariant?.stock?.stockLevelStatus">
                                    <mat-icon svgIcon="backordered" class="product-tile-backOrdered-icon mr-2"
                                        aria-label="Back Ordered"></mat-icon>
                                    <div class="d-flex flex-column">
                                        <span class="t-product-details-availability-Instock product-tile-backOrdered-text">
                                            {{ 'account.productTile.outOfStock' | cxTranslate }}
                                        </span>
                                        <span class="t-title-m"
                                            *ngIf="cartItem?.product?.outOfStockETA">
                                            {{ 'cart.eta' | cxTranslate }}{{cartItem?.product?.outOfStockETA}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="user && currentVariant">
                                <div class="product-tile-inStock d-flex m-0"
                                    *ngIf="'inStock' === currentVariant?.stock?.stockLevelStatus && !currentVariant?.isBackOrder && !currentVariant?.isDiscontinued && !currentVariant?.vendorDropShipSpecialOrder">
                                    <mat-icon svgIcon="inStock" class="product-tile-inStock-icon mr-2" aria-label="In Stock">
                                    </mat-icon>
                                    <span class="t-product-details-availability-Instock product-tile-inStock-text mr-1">
                                        {{ 'account.productTile.inStock' | cxTranslate }}
                                    </span>
                                </div>
                                <div class="product-tile-backOrdered m-0 d-flex"
                                    *ngIf="'outOfStock' === currentVariant?.stock?.stockLevelStatus && !currentVariant?.isBackOrder && !currentVariant?.isDiscontinued && !currentVariant?.vendorDropShipSpecialOrder">
                                    <mat-icon svgIcon="backordered" class="product-tile-backOrdered-icon mr-2"
                                        aria-label="Back Ordered"></mat-icon>
                                    <div class="d-flex flex-column">
                                        <span class="t-product-details-availability-Instock product-tile-backOrdered-text">
                                            {{ 'account.productTile.outOfStock' | cxTranslate }}
                                        </span>
                                        <span class="t-title-m"
                                            *ngIf="cartItem?.product?.outOfStockETA">
                                            {{ 'cart.eta' | cxTranslate }}{{cartItem?.product?.outOfStockETA}}
                                        </span>
                                    </div>
                                </div>
                                <div class="product-tile-backOrdered m-0 d-flex"
                                    *ngIf="currentVariant?.isBackOrder && !currentVariant?.isDiscontinued && !currentVariant?.vendorDropShipSpecialOrder">
                                    <mat-icon svgIcon="backordered" class="product-tile-backOrdered-icon mr-2"
                                        aria-label="Back Ordered"></mat-icon>
                                    <div class="d-flex flex-column">
                                        <span class="t-product-details-availability-Instock product-tile-backOrdered-text">
                                            {{ 'account.productTile.backOrdered' | cxTranslate }}
                                        </span>
                                        <span class="t-title-m"
                                            *ngIf="currentVariant?.mfgBackorderETA">
                                            {{ 'cart.eta' | cxTranslate }}{{currentVariant?.mfgBackorderETA}}
                                        </span>
                                    </div>
                                </div>
                                <div class="product-tile-discontinued m-0 d-flex" *ngIf="currentVariant?.isDiscontinued">
                                    <mat-icon svgIcon="discontinued" class="product-tile-discontinued-icon mr-2"
                                        aria-label="Discontinued"></mat-icon>
                                    <span class="t-product-details-availability-Instock product-tile-discontinued-text">
                                        {{ 'account.productTile.discontinued' | cxTranslate }}
                                    </span>
                                </div>
                                <div class="product-tile-specialorder m-0 d-flex"
                                    *ngIf="currentVariant?.vendorDropShipSpecialOrder && !currentVariant?.isDiscontinued">
                                    <mat-icon svgIcon="specialorder" class="product-tile-specialorder-icon mr-2"
                                        aria-label="Special Order"></mat-icon>
                                    <span class="t-product-details-availability-Instock product-tile-specialorder-text">
                                        {{ 'account.productTile.specialOrder' | cxTranslate }}
                                    </span>
                                </div>
                            </div>
                            <!---->
                        </div>
                    </div>
                    <div class="mr-2" *ngIf="componentName!=='orderConfirmation'">
                        <div class="cart-item-remove p-1 my-1">
                            <span class="p-1 d-flex">
                                <mat-icon svgIcon="remove_item" class="cursor-pointer remove_item"
                                    (click)="removeItemFromCart(cartItem)"></mat-icon>
                            </span>
                        </div>
                    </div>

                </div>
            </div>
            <!-- Inventory Section Ends -->

            <!-- Inventory Count Section Starts -->
            <div *ngIf="user?.isViewInventoryGroup && componentName!=='orderConfirmation' && cartItem?.product?.stock?.stockLevelMap && !editOrderCartId" class="d-flex justify-content-end pr-2">
                <div class="" *ngFor="let stockLevel of cartItem?.product?.stock?.stockLevelMap">
                    <div
                        [ngClass]=" stockLevel?.value > 0  ? 'cart-item-inventory-wh mx-2 green' : 'cart-item-inventory-wh mx-2 red'">
                        <span class="cart-item-inventory-wh-name p-1">{{ stockLevel?.key | formatWarehoseData}}</span>
                        <span class="cart-item-inventory-wh-count p-1">{{ stockLevel?.value }}</span>
                    </div>
                </div>
            </div>
            <!-- Inventory Count Section Ends -->
        </div>
    </div>
</div>
