<div class="d-flex flex-column container my-account-rep-dialog">
  <div class="d-flex flex-row justify-content-between mb-4">
    <div>
      <section *ngIf="data?.value?.accManagerData" class="t-title-l">
        {{ "account.myAccountRep.title" | cxTranslate }}
        {{ data?.value?.name | titlecase }}
      </section>

      <section *ngIf="!(data?.value?.accManagerData)" class="t-title-l">
        {{ "account.myAccountRep.title" | cxTranslate }}
        {{"account.accountUserInfo.customerService"| cxTranslate}}
      </section>

    </div>
    <div>
      <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon" >close</mat-icon>
    </div>
  </div>

  <div class="d-flex flex-column mb-2">
    <form [formGroup]="myAccountRepForm" (submit)="myAccountRepSubmit()">
      <div class="create-business-account-input-display mb-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label
            >{{ "account.myAccountRep.yourName" | cxTranslate }}
          </mat-label>
          <input
            matInput
            type="text"
            class="create-business-account-input-outline"
            formControlName="firstName"
            maxlength="20"
          />
          <mat-error
            *ngIf="
              myAccountRepForm.get('firstName')?.touched &&
              myAccountRepForm.get('firstName')?.hasError('required')
            "
            >{{ "account.myAccountRep.firstNameRequired" | cxTranslate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="mb-3 my-account-rep-discuss-input-display">
        <mat-form-field appearance="outline">
          <mat-label appRemoveAriaOwns>{{
            "account.myAccountRep.discussionPoint" | cxTranslate
          }}</mat-label>
          <mat-select formControlName="accountRepDiscuss">
            <mat-option
              *ngFor="let accountRepDiscuss of accountRepDiscussList"
              [value]="accountRepDiscuss?.viewValue"
            >
              {{ accountRepDiscuss.viewValue }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              myAccountRepForm.get('accountRepDiscuss')?.touched &&
              myAccountRepForm.get('accountRepDiscuss')?.hasError('required')
            "
            >{{
              "account.myAccountRep.accountRepDiscussRequired" | cxTranslate
            }}</mat-error
          >
        </mat-form-field>
      </div>

      <div class="mb-3 create-business-account-input-display">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{
            "account.myAccountRep.emailLabel" | cxTranslate
          }}</mat-label>
          <input
            matInput
            type="email"
            class="create-business-account-input-display"
            formControlName="email"
            maxlength="50"
          />
          <mat-error
            *ngIf="
              myAccountRepForm.get('email')?.touched &&
              myAccountRepForm.get('email')?.hasError('required')
            "
            >{{ "account.myAccountRep.emailRequiredError" | cxTranslate }}
          </mat-error>

          <mat-error
            class="mt-1"
            *ngIf="
              !(myAccountRepForm?.get('email')?.hasError('required')) &&
              !(myAccountRepForm?.get('email')?.hasError('emailIsInvalid'))
            "
          >{{ "account.myAccountRep.emailInvalidError" | cxTranslate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="d-flex flex-column flex-lg-row phone-input-or-mobile-input mb-3"
      >
        <div class="mr-0 mr-lg-4 w-50">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label
              >{{ "account.myAccountRep.phoneNumber" | cxTranslate }}
            </mat-label>
            <input
              matInput
              type="tel" pattern="[0-9]*" inputmode="numeric"
              class="create-business-account-input-outline"
              formControlName="phoneNumber"
              maxlength="10"
              minlength="10"
              formatNumber
            />
            <mat-error
              *ngIf="
                myAccountRepForm.get('phoneNumber')?.touched &&
                myAccountRepForm.get('phoneNumber')?.hasError('required')
              "
              >{{ "account.myAccountRep.phoneNumberRequired" | cxTranslate }}
            </mat-error>
            <mat-error
              *ngIf="
                myAccountRepForm.get('phoneNumber')?.touched &&
                myAccountRepForm.get('phoneNumber')?.hasError('minlength')
              "
              >{{ "account.myAccountRep.phoneNumberValid" | cxTranslate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="w-50">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label
              >{{ "account.myAccountRep.mobileNumber" | cxTranslate }}
            </mat-label>
            <input
              matInput
              type="tel" pattern="[0-9]*" inputmode="numeric"
              class="create-business-account-input-outline"
              formControlName="mobileNumber"
              maxlength="10"
              minlength="10"
              formatNumber
            />
            <mat-error
              *ngIf="
                myAccountRepForm.get('mobileNumber')?.touched &&
                myAccountRepForm.get('mobileNumber')?.hasError('minlength')
              "
              >{{ "account.myAccountRep.mobileNumberValid" | cxTranslate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="mt-3">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>{{
            "account.myAccountRep.discussionDetails" | cxTranslate
          }}</mat-label>
          <textarea
            matInput
            formControlName="comments"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="3"
            maxlength="240"
          ></textarea>
          <mat-error
            *ngIf="
              myAccountRepForm.get('comments')?.touched &&
              myAccountRepForm.get('comments')?.hasError('required')
            "
            >{{
              "account.myAccountRep.accountRepDiscussTextAreaRequired" | cxTranslate
            }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="d-flex justify-content-end mt-2">
        <div>
          <button mat-flat-button mat-dialog-close type="button" class="mr-2">
            {{ "account.myAccountRep.cancel" | cxTranslate }}
          </button>
        </div>
        <div class="my-account-rep-submit-button">
          <button mat-flat-button type="submit">
            {{ "account.myAccountRep.submit" | cxTranslate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
