import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { BoxoutEndpointService } from '../../services/common/boxout-endpoint.service';
import { InputValidationService } from '../../services/common/input-validation.service';
import { SmartyAddressValidationService } from '../../services/common/smarty-address-validation.service';
import { HeaderService } from '../../services/header.service';

@Component({
  selector: 'app-medical-info',
  templateUrl: './medical-info.component.html'
})
export class MedicalInfoComponent implements OnInit, OnDestroy {

  /*** DO NOT DELETE - Commented Code may be needed in future for upim and doctor's address section ***/ 

  // @ViewChild(ReusableAddressFormComponent, { static: true })
  // public addressFormComponent!: ReusableAddressFormComponent;
  medicalInfoForm: any;
  npiMailingAddress: any;
  headerServiceInstance: any;
  smartyKeyValForAddressValidation: any;
  siteData: any;
  smartyAuthInstance: any;
  apiUrl: string;
  npiRegisteryKeyForMedicalInfo: any;
  user: User;
  userSub: Subscription;
  userId: any;
  cartId: any;
  dataFromRecommendedDialogEditButton: any;
  showErrorMsg: boolean = false;
  themesForScroll: any = this.document.getElementsByClassName('cdk-global-overlay-wrapper');
  recommendedInstance: Subscription;
  showNPIError: boolean = false;

  constructor(private fb: FormBuilder,
    private http: HttpClient,
    private smartyAddressValidationService: SmartyAddressValidationService,
    private headerService: HeaderService,
    private customHttp: BoxoutEndpointService,
    public dialogRef: MatDialogRef<MedicalInfoComponent>,
    public dialog: MatDialog,
    private windowRef: WindowRef,
    private store: Store<fromApp.AppState>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) private data: any,
    @Inject(DOCUMENT) private document: Document,
    private inputValidationService: InputValidationService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    //let formAddress;
    let compRef : any = '';
    // let mailingAddress = this.data?.value?.address;
    // compRef = this.windowRef.sessionStorage?.getItem("medicalInformationFormRef");
    // let npiMailingAddress = {
    //   addressOne: mailingAddress?.line1 ? mailingAddress.line1 : "",
    //   optionalAddressTwo: mailingAddress?.line2 ? mailingAddress.line2 : "",
    //   cityName: mailingAddress?.town ? mailingAddress.town : "",
    //   stateName: mailingAddress?.region?.isocode ?  mailingAddress.region.isocode : "",
    //   zipCode: mailingAddress?.postalCode ? mailingAddress.postalCode : "",
    //   countryName: 'USA',
    // }

    this.medicalInfoForm = this.fb.group({
      //upim: [(this.data?.value?.upim ? this.data.value.upim : ''), [Validators.required , Validators.maxLength(100)]],
      npiData: [(this.data?.value?.npiData ? this.data.value.npiData : ''), [Validators.required, Validators.minLength(10), Validators.pattern("^[0-9]*$")]],
      //address : (this.data?.value?.componentName && this.data?.value?.componentName === 'checkout') ? this.addressFormComponent?.createAddressForm(npiMailingAddress ? npiMailingAddress : '') : this.addressFormComponent?.createAddressForm(this.data?.value?.address ? this.data.value.address : '')  
    });

    //getting the key for smarty address API validation 
    this.headerServiceInstance = this.headerService.localPropertiesConfigData.subscribe((resp: any) => {
      if (resp) {
        this.siteData = resp;
       // const smartyKeyVal: any = 'spartacus.config.smarty.key';
        const npiRegisteryKey: any = 'spartacus.config.npiregistry.version'
        //this.smartyKeyValForAddressValidation = this.siteData[smartyKeyVal];
        this.npiRegisteryKeyForMedicalInfo = this.siteData[npiRegisteryKey];
      }

    });


    // this.recommendedInstance = this.smartyAddressValidationService.receivedRecommendedData$.subscribe((sentData) => {

    //   this.dataFromRecommendedDialogEditButton = sentData;
    //   if (this.dataFromRecommendedDialogEditButton.editButtonClicked
    //     && this.dataFromRecommendedDialogEditButton.editAddressData.componentRef === "medicalInformationForm") {
    //     let editAddData = this.dataFromRecommendedDialogEditButton.editAddressData;
    //     editAddData.addressType = editAddData.addressType;
    //     this.medicalInfoForm.patchValue(editAddData);

    //   };

    //   if (!(JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("medicalInformationFormReopened"))))
    //     && this.dataFromRecommendedDialogEditButton.editAddressData.componentRef === "medicalInformationForm") {

    //     this.windowRef.sessionStorage?.setItem("medicalInformationFormReopened", "true");

    //     if ((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("medicalInformationFormReopened"))))) {
    //       this.dialog.open(MedicalInfoComponent,
    //         {
    //           panelClass: ['medical-info-dialog'],
    //           autoFocus: false,
    //           disableClose: true,
    //           data: this.medicalInfoForm
    //         },
    //       );
    //       if (Object.keys(this.dialogRef).length > 0) {
    //         this.dialogRef.close();
    //       }
    //     }
    //   }
    // });



    // this.smartyAuthInstance = this.smartyAddressValidationService.savedFormDataSource.subscribe((data) => {
    //   if (data && data.componentRef === "medicalInformationForm" &&
    //    !(JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("finalMedicalInfoFormSubmit"))))) {
    //       this.finalFormSubmit(data);
    //   }
    // }, (error) => {
    // });

    this.userSub = this.store.select('auth').subscribe((user: any) => {
      this.user = user && user.user!;
      this.userId = this.user?.uid;
    });

    this.cartId = this.windowRef.sessionStorage?.getItem('cartId');
    this.themesForScroll[0].className += " custom-modal";

    this.changeDetectorRef.detectChanges();
  }

  // This method is used to form request body which will be sent in create business account POST Call
  formAddressRequestBody(body: any): any {
    if (body) {
      return {
        // doctorAddress:
        // {
        //   addressType: body?.addressType,
        //   attendant: body?.attn,
        //   country: {
        //     isocode: body.address.countryName,
        //     name: "United States"
        //   },
        //   defaultAddress: true,
        //   deliveryNote: body.deliverNote,
        //   district: body.address.cityName,
        //   line1: body.address.addressOne,
        //   line2: body.address.optionalAddressTwo,
        //   postalCode: body.address.zipCode,
        //   region: {
        //     countryIso: body.address.countryName,
        //     isocode: body.address.stateName,
        //     isocodeShort: body.address.stateName,
        //     name: this.smartyAddressValidationService.getStateText(body.address.stateName)
        //   },
        //   town: body.address.cityName,
        //   visibleInAddressBook: true,
        //   id: body.address.id
        // },
        npiInformation: body.npiData,
        //patientUid: body.upim
      }

    }

  }


  //To submit the form and Add Ship To Account
  finalFormSubmit(formData: any) {
    //this.windowRef.sessionStorage?.setItem("finalMedicalInfoFormSubmit","true");

    //if((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("finalMedicalInfoFormSubmit"))))){
    let requestBody = this.formAddressRequestBody(formData.value);
    let cartId : any;
    let compRef = this.windowRef.sessionStorage?.getItem("medicalInformationFormRef");
    if((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("medicalInformationCartId"))))){
      cartId = this.windowRef.sessionStorage?.getItem("medicalInformationCartId");
    } else {
      cartId = this.windowRef.sessionStorage?.getItem('cartId');
    }
    this.apiUrl = `/carts/${cartId}/medical-dropship-info`;
    this.customHttp.post(this.apiUrl, requestBody).subscribe(
      (data: any) => {
        //this.windowRef.sessionStorage?.removeItem("finalMedicalInfoFormSubmit");
        //to close recommended address dialog
        if (data.isSuccess) {
          // if (this.smartyAddressValidationService.openedDialog) {
          //   this.smartyAddressValidationService.openedDialog.close();
          // }
          //this.windowRef.sessionStorage?.removeItem("medicalInformationFormReopened");
          this.dialogRef.removePanelClass('d-none');
          if(compRef === 'checkout') {
            this.smartyAddressValidationService.sendMedicalInfo(data);
          } else if(compRef === 'edit-order') {
            this.smartyAddressValidationService.sendEditOrder({ componentName: compRef , data});
          } else {
            this.router.navigateByUrl('/checkout/multi');
          }
        } 
        // else {
        //   //setting form data null for avoiding multiple call 
        //   this.smartyAddressValidationService.saveFormData(null);

        //   //   to close recommended address dialog
        //   if (this.smartyAddressValidationService.openedDialog) {
        //     this.smartyAddressValidationService.openedDialog.close();
        //   }
        // }
      },
      (error: any) => {
        //setting form data null for avoiding multiple call 
        //this.smartyAddressValidationService.saveFormData(null);

        //   to close recommended address dialog
        // if (this.smartyAddressValidationService.openedDialog) {
        //   this.smartyAddressValidationService.openedDialog.close();
        // }
      });
    }
  //}

  medicalInfoFormSubmit(): void {
    this.showErrorMsg = false;
    // this.medicalInfoForm.controls['address'] = this.addressFormComponent.addressForm;
    // this.medicalInfoForm.patchValue(this.addressFormComponent.addressForm);

    if (this.medicalInfoForm.valid) {
      // let addressOne = this.medicalInfoForm.get('address')?.value.addressOne;
      // let optionalAddressTwo = this.medicalInfoForm.get('address')?.value.optionalAddressTwo;
      // let cityName = this.medicalInfoForm.get('address')?.value.cityName;
      // let stateIsocode = this.medicalInfoForm.get('address')?.value.stateName;
      // let stateText = this.smartyAddressValidationService.getStateText(stateIsocode);

      // let zipCode = this.medicalInfoForm.get('address')?.value.zipCode;
      // let countryText = 'United States';

      //sending component reference so that when we subscribe subject releted to its specific component
      let componentReference = "medicalInformationForm";

      //close the create businness account form when form is valid and its going for smarty address 
      //validation which either opens recommended address dialog or submits the create businness account form
      this.finalFormSubmit(this.medicalInfoForm);
      this.dialogRef.close();

      //This is used to validate address in Edit ship To account form and submit form or it can also open
      //recommened address dialog in case if there are any recommened addresses
      //this.smartyAddressValidationService.addressValidation(addressOne, optionalAddressTwo, cityName, stateIsocode, stateText, zipCode, countryText, this.medicalInfoForm, this.smartyKeyValForAddressValidation, componentReference);
    }
    else {
      //invalid form alert
      this.medicalInfoForm.markAllAsTouched();
      const firstElementWithError = document.querySelector('.ng-invalid');

      if (firstElementWithError) {
        firstElementWithError.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  npiChanged(e: any): any {
    this.showNPIError = false;
    let apiUrl = `npi/?npiInformation=${e.target?.value}`;
    if (e.target?.value?.length >= 10) {
      this.customHttp.get(apiUrl).subscribe((data: any) => {
        let mailingAddress: any;
        if (data && data?.result_count > 0) {
          // data?.results[0]?.addresses.forEach((address: any, i: any) => {
          //   if (address?.address_purpose.toLowerCase() === 'mailing') {
          //     mailingAddress = address;
          //   }
          // })
        } else {
          this.showNPIError = true;
          e.target?.blur();
        }

        // this.npiMailingAddress = {
        //   addressOne: mailingAddress?.address_1 ? mailingAddress.address_1 : "",
        //   optionalAddressTwo: "",
        //   cityName: mailingAddress?.city ? mailingAddress.city : "",
        //   stateName: mailingAddress?.state ? "USA-" + mailingAddress.state : "",
        //   zipCode: mailingAddress?.postal_code ? mailingAddress.postal_code : "",
        //   countryName: 'USA',
        // }

        // this.medicalInfoForm.controls['address'] = this.addressFormComponent.createAddressForm(this.npiMailingAddress ? this.npiMailingAddress : "");
        // this.medicalInfoForm.patchValue(this.addressFormComponent.createAddressForm(this.npiMailingAddress ? this.npiMailingAddress : ""));
      },
        (error) => {
          this.showErrorMsg = true;
          this.showNPIError = true;
          e.target?.blur();
        })
    }
  }


  //This function will validate the user should enter only the digits in input field
  keyPressNumbers(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
     else {
      let val = event.target.value.length>=10 ?  false :  true;
      return val;
    }
  }

  onPaste(event: any) {
    this.inputValidationService.onPasteNpiData(event);
  }

  ngOnDestroy(): void {

    this.data = {};
    
    // if ((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("medicalInformationFormReopened"))))) {
    //   this.windowRef.sessionStorage?.removeItem("medicalInformationFormReopened");
    // }

    // if ((JSON.parse(JSON.stringify(this.windowRef.sessionStorage?.getItem("finalMedicalInfoFormSubmit"))))) {
    //   this.windowRef.sessionStorage?.removeItem("finalMedicalInfoFormSubmit");
    // }

    if (this.userSub) {
      this.userSub.unsubscribe();
    }

    if(this.headerServiceInstance) {
      this.headerServiceInstance.unsubscribe();
    }

  }
}
