import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-order-address-card',
  templateUrl: './order-address-card.component.html'
})
export class OrderAddressCardComponent {

  @Input() orderDetails: any
 
  constructor() { }
 
}
