import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { WindowRef } from '@spartacus/core';
import { HamburgerMenuService, ICON_TYPE, NavigationUIComponent } from '@spartacus/storefront';
import { LinkTargetService } from 'src/app/shared/services/common/link-target.service';

@Component({
  selector: 'app-custom-navigation-ui',
  templateUrl: './custom-navigation-ui.component.html'
})
export class CustomNavigationUiComponent  extends NavigationUIComponent{
  iconType = ICON_TYPE;
  @Input() node: any;
  setLinkTarget: any;

  constructor(
    private link: LinkTargetService,
    router: Router,
    renderer: Renderer2,
    elemRef: ElementRef<any>,
    hamburgerMenuService: HamburgerMenuService,
    winRef: WindowRef
  ) {
    super(router, renderer, elemRef, hamburgerMenuService, winRef); // Adding windowRef as OOTB NavigationUIComponents has it in its constructor
    this.setLinkTarget = link.getTarget;
  }


}
