import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { SiteContextConfig, WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { BoxoutEndpointService } from '../../services/common/boxout-endpoint.service';
import { GTMService } from '../../services/common/gtm.service';
import { HeaderService } from '../../services/header.service';

@Component({
  selector: 'app-reusable-mini-cart',
  templateUrl: './reusable-mini-cart.component.html',
})
export class ReusableMiniCartComponent implements OnInit,OnDestroy {
  cart: any
  totalPrice: any;
  errors: any
  imageUrl: any;
  siteConfig: any;
  userId: any;
  isCartEmpty: any = true;
  user: User;
  userSub: Subscription;
  medicalDropshipError: any;
  isOrderLevelMDIEnabled: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private config: SiteContextConfig,
    private customHttp: BoxoutEndpointService,
    private headerService: HeaderService,
    private store: Store<fromApp.AppState>,
    private windowRef: WindowRef,
    private gtmService: GTMService
  ) {
    this.siteConfig = this.config;
  }

  /**
   * This will get call as the add to cart button will get click to open the mini cart slider.
   * It'll get the error and userId from quick Order servie to show error if any.
   * If userId is not present then it'll get the userId from shared service to call the further API's
   */
  ngOnInit(): void {
    this.userSub = this.store.select('auth').subscribe(user => {
      this.user = user && user.user!;
    });

    this.imageUrl = this.siteConfig?.backend?.occ?.baseUrl
    this.errors = this.data?.cartError?.errors;
    this.userId = this.data?.userID;
    if (this.userId) {
      this.getCartDetails();
    } else {
      this.userId = this.user?.uid;
      if (this.userId) {
        this.getCartDetails();
      }
      // this.isLoggedInSub = this.headerService.isLoggedIn.subscribe((response) => {
      //   this.userData = response;
      // });
      // this.userDataSub = this.userData?.subscribe((data: any) => {
      //   this.userId = data?.uid;
      //   if (this.userId) {
      //     this.getCartDetails();
      //   }
      // });
    }
  }

  /**
   * This function will fetch the details of cart to show the products in mini cart slider.
   */
  getCartDetails() {
    let cartId = sessionStorage.getItem('cartId');
    let request = this.windowRef.sessionStorage?.getItem('promotionsMap');
    const apiUrl = `users/${this.userId}/carts/${cartId}?validateCart=true`;
    if (cartId) {
      this.customHttp.post(apiUrl,request).subscribe(
        (cart: any) => {
          this.cart = cart?.entries;
          this.medicalDropshipError = cart?.medicalDropshipError;
          this.isOrderLevelMDIEnabled = cart?.isOrderLevelMDIEnabled;
          this.errors = this.errors || cart?.errors;
          if (this.cart?.length > 0) {
            this.isCartEmpty = false;
            this.totalPrice = cart?.subTotal?.formattedValue;
          } else {
            this.isCartEmpty = true;
          }
          this.totalPrice = cart?.subTotal?.formattedValue;
          this.headerService.setAddCardCount(cart?.totalItems);

          if(this.windowRef.sessionStorage?.getItem('gaItemsForCartUpdate') && this.windowRef.sessionStorage?.getItem('totalForCartUpdate')) {
            let gaItems = this.windowRef.sessionStorage?.getItem('gaItemsForCartUpdate');
            let total = this.windowRef.sessionStorage?.getItem('totalForCartUpdate');
            this.gtmService.addProductsToCart(JSON.parse(gaItems ? gaItems : '') ,total);
            this.windowRef.sessionStorage.removeItem('gaItemsForCartUpdate');
            this.windowRef.sessionStorage.removeItem('totalForCartUpdate');
          }

          this.gtmService.setGTMProductData(this.cart, 3, cart?.subTotal?.value);
        })
    }
  }

  /**
   * This function will remove the item from cart and refresh the cart details and number of items in cart.
   */
  removeItemFromCart(item: any) {
    let cartId = sessionStorage.getItem('cartId');
    const apiUrl = `users/${this.userId}/carts/${cartId}/entries/${item.entryNumber}`;
    return this.customHttp.delete(apiUrl).subscribe(
      (resp: any) => {
        this.getCartDetails();
        let gaObj = item?.product?.gaItems;
        gaObj = { ...gaObj, discount: null, index: null , price: item?.basePrice?.value , quantity: item?.quantity, item_list_id: 'mini_cart', item_list_name: 'Mini Cart'};
        this.gtmService.removeProductFromCart(gaObj);
        gaObj={};
      })
  }

  /*
  This function will unsubscribe all the subscription.  
*/
  ngOnDestroy() {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }
}
