import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { WindowRef } from '@spartacus/core';
import { Observable } from 'rxjs';
import { AuthGuardService } from './auth-guard.service';

@Injectable({
  providedIn: 'root',
})
export class NonLoggedInUserGuard implements CanActivate {
  constructor(
    private authGuardService: AuthGuardService,
    private route: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private windowRef: WindowRef
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isAsmUserLoggedIn = this.windowRef?.localStorage?.getItem(
      'asmLoggedInUserEmail'
    );
    const hasB2bUnit = this.windowRef?.localStorage?.getItem('b2bUnit');
    const isLoggedIn = this.authGuardService.isNonLoggedInUser();

    if (!isPlatformBrowser(this.platformId)) {
      this.route.navigate(['/ssr-intermittent'], {
        skipLocationChange: true,
      });
      return false;
    } else {
      if (!isLoggedIn && !isAsmUserLoggedIn) {
        return true;
      } else if ((!isLoggedIn || !hasB2bUnit) && isAsmUserLoggedIn) {
        this.route.navigateByUrl('/sales-rep');
        return false;
      } else {
        this.route.navigateByUrl('');
        return false;
      }
    }
  }
}
