import { createReducer, on } from '@ngrx/store';
import { User } from '../../models/user.model';
import * as AuthActions from '../actions/auth.actions';

export interface UserState {
  user: User | null;
}

const initialState: UserState = {
  user: null
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.loginSuccess, (state, { user }) => {
    const updatedUser = new User(user);

    return {
      ...state,
      user: updatedUser
    };
  }),
  on(AuthActions.logout, (state) => {
    return {
      ...state,
      user: null
    };
  }),
  on(AuthActions.updateUser, (state, { displayUid }) => {
    const updatedUser = new User(state.user);
    updatedUser.displayUid = displayUid;    
    return {
      ...state,
      user: updatedUser
    };
  })
  )