import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-order-detail-revision-history',
  templateUrl: './order-detail-revision-history.component.html',
})
export class OrderDetailRevisionHistoryComponent {
  @Input() orderHistoryEntriesData:any;
  constructor() { }


}
