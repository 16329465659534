import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AuthStorageService,
  InterceptorUtil,
  OccEndpointsService,
  USE_CLIENT_TOKEN
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { IQueryParams } from '../../../custom/boxout-services/models/rest-interface';
import { User } from '../../../custom/boxout-services/models/user';
@Injectable({
  providedIn: 'root',
})
export class BaseService {
  userType = User;

  constructor(
    public httpClient: HttpClient,
    public occEndpointsService: OccEndpointsService,
    public authStorageService: AuthStorageService
  ) { }

  buildHttpParams(paramObject: any): HttpParams {
    paramObject = this.buildQueryParams(paramObject);
    let params: HttpParams = new HttpParams();

    for (let key in paramObject) {
      if (paramObject.hasOwnProperty(key) && paramObject[key]) {
        params = params.set(key, paramObject[key].toString());
      }
    }

    return params;
  }

  buildHttpHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    if (this.authStorageService.getItem('access_token')) {
      headers = headers.append(
        'Authorization',
        `Bearer ${this.authStorageService.getItem('access_token')}`
      );
    } else {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }

    return headers;
  }

  buildQueryParams(queryParams?: IQueryParams) {
    return Object.assign({}, this.getDefaultQueryParams(), queryParams);
  }

  getDefaultQueryParams() {
    return {};
  }

  getHttpOptions(
    queryParams?: IQueryParams,
    headers?: any,
    responseType?: any
  ) {
    return {
      params: this.buildHttpParams(queryParams),
      headers: headers || this.buildHttpHeaders(),
      responseType: responseType || 'json'
    };
  }

  get(
    subpath?: string,
    queryParams?: IQueryParams,
    responseType?: string
  ): Observable<any> {
    subpath = subpath || '';
    const options = this.getHttpOptions(
      queryParams,
      null,
      responseType || 'json'
    );

    return this.httpClient.get<JSON>(
      this.occEndpointsService.buildUrl(subpath),
      options
    );
  }

  post(
    subpath?: string,
    payload?: any,
    queryParams?: IQueryParams,
    headers?: any,
    responseType?: string
  ): Observable<any> {
    subpath = subpath || '';
    const options = this.getHttpOptions(
      queryParams,
      headers,
      responseType || 'json'
    );
    return this.httpClient.post<JSON>(
      this.occEndpointsService.buildUrl(subpath),
      payload || {},
      options
    );
  }

  postCSV(
    subpath?: string,
    payload?: any,
    queryParams?: IQueryParams,
    headers?: any,
    responseType?:string
  ): Observable<any> {
    subpath = subpath || '';
    const options = this.getHttpOptions(
      queryParams,
      headers,
      'blob'
    );
    return this.httpClient.post<Blob>(
      this.occEndpointsService.buildUrl(subpath),
      payload || {},
      options
    );
  }

  patch(
    subpath?: string,
    payload?: any,
    queryParams?: IQueryParams,
    headers?: any,
    responseType?: string
  ): Observable<any> {
    subpath = subpath || '';
    const options = this.getHttpOptions(
      queryParams,
      headers,
      responseType || 'json'
    );
    return this.httpClient.patch<JSON>(
      this.occEndpointsService.buildUrl(subpath),
      payload,
      options
    );
  }

  put(
    subpath?: string,
    payload?: any,
    queryParams?: IQueryParams,
    headers?: any,
    responseType?: string
  ): Observable<any> {
    subpath = subpath || '';
    const options = this.getHttpOptions(
      queryParams,
      headers,
      responseType || 'json'
    );
    return this.httpClient.put<JSON>(
      this.occEndpointsService.buildUrl(subpath),
      payload,
      options
    );
  }

  delete(
    subpath: string,
    queryParams?: IQueryParams,
    headers?: any,
    responseType?: string
  ): Observable<any> {
    subpath = subpath || '';
    const options = this.getHttpOptions(
      queryParams,
      headers,
      responseType || 'json'
    );
    return this.httpClient.delete<JSON>(
      this.occEndpointsService.buildUrl(subpath),
      options
    );
  }

  // Used this utility function to upload a file(s) only.
  postFile(subpath: string, fileData: File): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.authStorageService.getItem('access_token')}`
    });
  
    const options = {
      headers: headers,
    };
  
    return this.httpClient.post<any>(
      this.occEndpointsService.buildUrl(subpath),
      fileData,
      options
    );
  }
}
