<div class="cart-item promo cart-free-item ml-0 ml-sm-5">
  <div class="cart-item-header d-flex align-items-center">
    <div
      class="cart-item-header-number d-flex align-items-center justify-content-center t-cart-product-number p-2"
    >
      <div class="px-2 py-1 cart-item-header-num">{{ itemNumber }}</div>
    </div>
    <div class="cart-item-header-details d-flex py-1 px-2">
      <div class="cart-item-header-width">
        <div class="t-body-s">{{ "cart.item" | cxTranslate }}</div>
        <div class="t-title-m" *ngIf="freeProduct?.product">{{ freeProduct.product.code }}</div>
      </div>
      <div class="cart-item-header-width ml-3">
        <div class="t-body-s">{{ "cart.mfgItem" | cxTranslate }}</div>
        <div class="t-title-m">
          {{
            freeProduct.product.baseOptions.length
              ? freeProduct.product.baseOptions[0].selected?.manufacturerAid
              : null
          }}
        </div>
      </div>
    </div>
  </div>

  <div class="p-3 d-flex">
    <!-- Product image Section Starts -->
    <div class="cart-item-img">
      <a (click)="navigateToSEO(freeProduct.product)" class="cursor-pointer"
        >
        <img *ngIf="freeProduct?.product?.productImage?.url" src="{{ imageUrl }}{{freeProduct.product.productImage?.url }}" alt="{{freeProduct.product.name}}"
      />
      <img *ngIf="freeProduct?.product?.productImage?.url === undefined || freeProduct?.product?.productImage?.url === '' || !(freeProduct?.product?.productImage?.url)"  src="../../../../../assets/MissingImage.svg" alt="MissingImage"/>

    </a>
    </div>
    <!-- Product image Section ENds -->

    <!-- Product Name / Brand Section Starts -->
    <div
      class="d-flex flex-sm-grow-1 flex-wrap flex-sm-nowrap justify-content-sm-between"
    >
      <div class="d-flex flex-column px-2 mx-1">
        <div class="cart-item-name t-cart-product-name" *ngIf="freeProduct.product" [innerHTML]="freeProduct.product.name"></div>
        <div class="cart-item-brand t-label-s pt-1">
          {{ freeProduct.product.brandData?.name | uppercase }}
        </div>
        <div
          class="d-flex-column pt-2 mt-1"
          *ngIf="
            (freeProduct.product.baseOptions[0]?.selected
              ?.variantOptionQualifiers?.length || 0) > 1
          "
        >
          <div
            class="t-productTile-productGroup mb-1"
            *ngFor="
              let attr of freeProduct.product.baseOptions[0]?.selected
                ?.variantOptionQualifiers || [];
              last as isLast
            "
          >
            <div class="t-body-s">{{ attr?.name }} : {{ attr?.value }}</div>
          </div>
        </div>
      </div>
      <!-- Product Quantity -->
      <div
        class="qty-earned d-flex flex-sm-column px-2 pt-1 pt-sm-0 ml-1 ml-sm-0 text-left text-sm-center align-self-start align-items-center"
      >
        <div class="cart-item-name t-label-small text-capitalize">
          {{ "cart.qtyEarned" | cxTranslate
          }}<span class="pr-2 d-sm-none">:</span>
        </div>
        <div class="cart-item-brand t-cart-qty" *ngIf="freeProduct?.quantity">
          {{ freeProduct.quantity }}
        </div>
      </div>
      <!-- Product Quantity -->
    </div>
    <!-- Product Name / Brand Section Ends -->
  </div>
</div>
