<div class="sales-rep-call-me-container">
  <div
    class="sales-rep-call-me-header-border d-flex flex-row justify-content-between align-items-center"
  >
    <div class="t-headerLink-normal">
      {{ "account.salesRepCallMe.title" | cxTranslate }}
    </div>
    <div class="mt-1">
      <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon" color="white">close</mat-icon>
    </div>
  </div>

   <!--  to show the error/success on click of save button on save edit : start-->
   <div *ngIf="salesRepCallFailure" class="px-3 pt-3 rounded">
    <reusable-alert-box
      [alertType]="'warningAlertBox'"
      alertMsg="{{
        'account.signInSecurityForm.errorAlertMsg' | cxTranslate
      }}"
    ></reusable-alert-box>
  </div>


  <div *ngIf="salesRepCallSuccess" class="px-3 pt-3 rounded">
    <reusable-alert-box
      [alertType]="'successAlertBox'"
      alertMsg="{{
        'account.signInSecurityForm.successAlertMsg' | cxTranslate
      }}"
    ></reusable-alert-box>
  </div>
  <!--  to show the error/success on click of save button : end-->

  <div class="p-3">
    <div *ngIf="!(baseProductName)" class="t-productTile-description" [innerHTML]="product?.name"></div>
    <div *ngIf="baseProductName" class="t-productTile-description" [innerHTML]="baseProductName">{{ baseProductName }}</div>

    <div *ngIf="!(baseProductBrandData)" class="t-productTile-brand">{{ product?.brandData?.name }}</div>
    <div *ngIf="(baseProductBrandData)" class="t-productTile-brand">{{ baseProductBrandData }}</div>

    <div class="t-productTile-productGroup">Variant Information</div>
    <div class="t-productTile-productGroup sales-rep-product-code mb-1 d-flex flex-row align-items-center justify-content-center px-1">
      {{ "account.salesRepCallMe.sku" | cxTranslate }} {{ code }}
    </div>

    <div class="t-productTile-description">
      {{ "account.salesRepCallMe.description" | cxTranslate }}
    </div>

    <form [formGroup]="salesRepCallMeForm">
      <div class="sales-rep-call-me-input mt-3">
        <mat-form-field appearance="outline">
          <mat-label>{{
            "account.salesRepCallMe.phoneNumber" | cxTranslate
          }}</mat-label>
          <input
            matInput
            type="tel" pattern="[0-9]*" inputmode="numeric"
            formControlName="phoneNumber"
            maxlength="10"
            minlength="10"
            formatNumber
          />
          <mat-error
            *ngIf="
              salesRepCallMeForm?.get('phoneNumber')?.touched &&
              salesRepCallMeForm?.get('phoneNumber')?.hasError('required')
            "
            >{{ "account.createAccount.newUserPhoneRequired" | cxTranslate }}
          </mat-error>
          <mat-error
            *ngIf="
              salesRepCallMeForm?.get('phoneNumber')?.touched &&
              salesRepCallMeForm?.get('phoneNumber')?.hasError('minlength')
            "
            >{{ "account.createAccount.phoneNumberValid" | cxTranslate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="sales-rep-call-me-input mt-3">
        <mat-form-field appearance="outline">
          <mat-label>{{
            "account.salesRepCallMe.email" | cxTranslate
          }}</mat-label>
          <input
            matInput
            type="email"
            formControlName="email"
            maxlength="50"
          />
          <mat-error
            *ngIf="
              salesRepCallMeForm.get('email')?.touched &&
              salesRepCallMeForm.get('email')?.hasError('required')
            "
            >{{ "account.salesRepCallMe.emailRequiredError" | cxTranslate }}
          </mat-error>

          <mat-error
          class="mt-1"
          *ngIf="!(salesRepCallMeForm.get('email')?.hasError('required')) &&
            !(salesRepCallMeForm?.get('email')?.hasError('emailIsInvalid'))
          "
        >{{ "account.salesRepCallMe.emailInvalidError" | cxTranslate }} 
        </mat-error>
        </mat-form-field>
      </div>

      <div class="mt-3">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>{{
            "account.salesRepCallMe.comments" | cxTranslate
          }}</mat-label>
          <textarea
            matInput
            formControlName="comments"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="3"
            maxlength="240"
          ></textarea>
        </mat-form-field>
      </div>

      <div class="mb-3">
        <p class="t-body-s">
          {{ 240 - salesRepCallMeForm.get("comments")?.value?.length }}
          {{ "account.createFavoriteList.charactersRemaining" | cxTranslate }}
        </p>
      </div>
    </form>
  </div>

  <div
    class="sales-rep-call-me-action-style d-flex flex-row justify-content-around p-3"
  >
    <div>
      <button
        class="sales-rep-action"
        mat-flat-button
        color="white"
        type="button"
        [mat-dialog-close]="true"
      >
        {{ "account.createFavoriteList.cancelButton" | cxTranslate }}
      </button>
    </div>

    <div>
      <button
        class="sales-rep-action"
        mat-flat-button
        color="primary"
        type="button"
        (click)="submitSalesRepCallMeForm()"
        [disabled]="!salesRepCallMeForm.valid"
      >
        {{ "account.createFavoriteList.saveButton" | cxTranslate }}
      </button>
    </div>
  </div>
</div>
