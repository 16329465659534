import {
  Component, OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WindowRef } from '@spartacus/core';
import { BoxoutEndpointService } from '../../services/common/boxout-endpoint.service';
import { SsrService } from '../../services/common/ssr.service';

@Component({
  selector: 'app-default-template',
  templateUrl: './default-template.component.html',
})
export class DefaultTemplateComponent implements OnInit {
  constructor(
    private router: Router,
    private customHttp: BoxoutEndpointService,
    private route: ActivatedRoute,
    private windowRef: WindowRef,
    private SsrService: SsrService
  ) {}

  /** Below are the Smart edit related changes */

  ngOnInit(): void {
    let forwardUrlConst = this.SsrService.getForwardUrlConst();
    let ssrFlag = this.SsrService.getSSRKey();

    let routeUrl = this.router.url.replace('/', '');
    this.windowRef?.localStorage?.setItem('goToUrl', routeUrl);
    //check if page is rendered on ssr or not
    if (ssrFlag === 'true') {
      if (forwardUrlConst && forwardUrlConst !== '') {
        this.SsrService.setForwardUrlConst('');
        this.SsrService.setSSRKey('false');
        this.router.navigate([forwardUrlConst], { skipLocationChange: true });
      } else {
        this.setExtraPath();
      }
    } else {
      //no ssr implementation make api call to get equivalent url for the page
      if(!this.windowRef?.location?.href?.includes('portal.myboxout')) {
        if(routeUrl?.includes('?')){
          routeUrl=routeUrl.substring(0,routeUrl.indexOf('?'));
        }
        this.customHttp
          .get(`/orgProducts/internalUrl?sourceUrl=${routeUrl}`)
          .subscribe((data: any) => {
            if (data && data.length) {
              this.router.navigate([data], { skipLocationChange: true });
            } else {
              this.setExtraPath();
            }
          });
      } else {
        this.router.navigate(['contentpage', routeUrl], { skipLocationChange: true });
      }

    }
  }

  setExtraPath() {
    let productPath: boolean = false;
    let categoryPath: boolean = false;
    let articlePath: boolean = false;
    for (const segment of this.route.snapshot.url) {
      if (segment && segment.path) {
        if (segment.path === 'p') {
          // checking if url needs to be redirected to product PDP
          productPath = true;
        } else if (segment.path === 'c') {
          // checking if url needs to be redirected to category page
          categoryPath = true;
        } else if (segment.path === 'a') {
          // checking if url needs to be redirected to article page
          articlePath = true;
        }
      }
    }

    if (productPath) {
      // redirecting to PDP if we encounter /p in the url
      this.router.navigate(
        [
          'productDetails',
          this.route.snapshot.url[this.route.snapshot.url.length - 1].path,
        ],
        { skipLocationChange: true }
      );
    } else if (categoryPath) {
      // redirecting to category page if we encounter /c in the url
      // below code is for forming the category page url out of backend-received url
      let index1 = this.route.snapshot.url.findIndex((val) => {
        return val.path === 'c';
      });
      let index2 = this.route.snapshot.url.findIndex((val) => {
        return val.path === 'p';
      });
      index2 = index2 !== -1 ? index2 : this.route.snapshot.url.length;
      let path = '';
      for (let i = index1 + 1; i <= index2 - 1; i++) {
        path =
          path +
          this.route.snapshot.url[i].path +
          (i !== index2 - 1 ? '/' : '');
      }
      this.router.navigate(['categoryCode', path], {
        skipLocationChange: true,
      });
    } else if (articlePath) {
      // redirecting to article page if we encounter /a in the url
      // below code is for forming the article page url out of backend-received url
      let index = this.route.snapshot.url.findIndex((val) => {
        return val.path === 'a';
      });
      let path = '';
      for (let i = index + 1; i < this.route.snapshot.url.length; i++) {
        path =
          path +
          this.route.snapshot.url[i].path +
          (i !== this.route.snapshot.url.length - 1 ? '/' : '');
      }
      this.router.navigate(['contentpage', path], { skipLocationChange: true });
    } else {
      // redirecting to content page if its neither product page nor category page
      // below code is for forming the content page url out of backend-received url
      let path = '';
      for (const key of this.route.snapshot.url) {
        path = path + key.path + '/';
      }
      if (!path.includes('cx-preview'))
        this.router.navigate(['contentpage', path], {
          skipLocationChange: true,
        });
      this.windowRef.sessionStorage?.setItem("contentPageTitle", "true");
    }
    this.SsrService.setForwardUrlConst('');
    this.SsrService.setSSRKey('false');
  }
}
