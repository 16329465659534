<div class="free-freight-modal text-center" *ngIf="data?.initiator === 'deliveryMode'">
    <div class="t-headline-m p-2 d-flex justify-content-center">{{ 'cart.freeFreight.notice' | cxTranslate }}</div>
    <div class="t-title-m mt-3 pb-2">
        {{ 'cart.freeFreight.firstLine' | cxTranslate }} {{data?.mode?.name}}. {{ 'cart.freeFreight.ifYou' | cxTranslate
        }} <br>
        {{ 'cart.freeFreight.secondLine' | cxTranslate }}<br>
        {{ 'cart.freeFreight.thirdLine' | cxTranslate }}<br>
        {{ 'cart.freeFreight.charged' | cxTranslate }} {{data?.mode?.deliveryCost?.formattedValue}} {{
        'cart.freeFreight.for' | cxTranslate }} {{data?.mode?.name}}.
    </div>
    <div class="mt-3 d-flex justify-content-center">
        <button mat-flat-button class="cancel-btn" (click)="cancel()"> {{ 'cart.cancel' | cxTranslate }} </button>
        <button mat-flat-button color="primary" type="submit" class="continue-btn ml-3" role="button"
            (click)="continue()"> {{ 'cart.continue' | cxTranslate }} </button>
    </div>
</div>

<div class="free-freight-modal text-center" *ngIf="data?.initiator === 'shippingAddress'">
    <div class="t-headline-m p-2 d-flex justify-content-center">{{ 'cart.freeFreight.notice' | cxTranslate }}</div>
    <div class="t-title-m mt-3 pb-2"> {{ 'cart.freeFreight.shippingAddressChange' | cxTranslate }}</div>

    <div class="mt-3 d-flex justify-content-center">
        <button mat-flat-button class="cancel-btn" (click)="cancel()"> {{ 'cart.cancel' | cxTranslate }} </button>
        <button mat-flat-button color="primary" type="submit" class="continue-btn ml-3" role="button"
            (click)="continue()"> {{ 'cart.continue' | cxTranslate }} </button>
    </div>
</div>