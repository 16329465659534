<div class="order-history-detail d-flex flex-wrap my-3 p-3">
  <div class="d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row w-100 p-2 justify-content-between order-history-detail-status">
    <div class="d-flex flex-row">
      <div class="mr-4">
        <p *ngIf="orderType!=='RETURN'" class="t-body-s mb-0 ">{{"account.ordersHistory.orderedVia"| cxTranslate}} {{ order?.orderdVia }}</p>
        <p *ngIf="orderType==='RETURN'" class=" t-body-s mb-0">{{'cart.returnPlaced'| cxTranslate}} </p>
        <p class="t-title-m mb-0">{{order?.created | date: 'mediumDate'}} at {{order?.created | date: 'shortTime'}}</p>
      </div>
      <div class="ml-3">
        <p class="t-body-s mb-0">
          <span *ngIf="order?.deliveryMode?.code === 'vendor-dropship-overweight'">{{ estimated }}</span> 
          {{ "account.ordersHistory.total"| cxTranslate }}
        </p>
        <p class="t-title-m mb-0">{{ order?.totalPrice?.formattedValue }}</p>
      </div>
    </div>
    <div class="pt-2">
      <p  class="t-body-s font-weight-bold p-2 mb-0 d-inline-block other-statuses" [ngClass]="orderStatus">
        {{ orderStatus | titlecase }}
      </p>
    <p  *ngIf="orderType==='RETURN'" class="t-body-s ml-2 font-weight-bold p-2 mb-0 d-inline-block returnStat" >
        {{ 'cart.return' | cxTranslate }}
      </p> 
    </div>
  </div>

  <div class="d-flex flex-sm-row flex-column w-100 p-1 justify-content-between order-history-detail-bill-to">
    <div class="d-flex flex-row mb-2 mb-sm-0 mb-md-0 mb-lg-0 mb-xl-0">
      <p class="t-acctno-text-regular mb-0 mr-1">{{"account.ordersHistory.billToAcct"| cxTranslate}}</p>
      <p class="t-body-s mb-0">{{ order?.billToId || '-' }}</p>
    </div>
    <div class="d-flex flex-row">
      <p *ngIf="orderType!=='RETURN'" class="t-body-s mb-0 mr-1">{{"account.ordersHistory.order"| cxTranslate}}</p>
      <p *ngIf="orderType==='RETURN'" class="t-body-s mb-0 mr-1">{{'cart.return'| cxTranslate}}</p>
      <p class="t-body-s mb-0">
        {{ order?.code || '-' }}
        <span *ngIf="order?.siebelOrderNumber">
          {{ ' | ' + order?.siebelOrderNumber}}
        </span>
      </p>
    </div>
  </div>

  <div class="d-flex flex-sm-row flex-column w-100 px-1 justify-content-between order-history-detail-ship-to">
    <div class="d-flex flex-row mb-2 mb-sm-0 mb-md-0 mb-lg-0 mb-xl-0">
      <p class="t-body-s mb-0 mr-1">{{"account.ordersHistory.shipTo"| cxTranslate}}</p>
      <p class="t-body-s font-weight-bold mb-0">{{ order?.shipToName || '-' }}</p>
    </div>
    <div *ngIf="order?.purchaseOrderNumber" class="d-flex flex-row">
      <p class="t-body-s mb-0 mr-1">{{"account.ordersHistory.po"| cxTranslate}}</p>
      <p class="t-body-s mb-0">{{ order?.purchaseOrderNumber }}</p>
    </div>
  </div>

  <div class="d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row w-100 p-3 justify-content-between order-history-detail-address-section">
    <div class="mb-3">
      <p class="t-body-s mb-0 order-history-detail-address-details">
        <span *ngIf="order?.businessName">{{ order?.businessName }}<br></span>
        <span>
          {{"account.ordersHistory.attendant"| cxTranslate}}
          <span *ngIf="order?.deliveryAddress?.attendant">{{ order?.deliveryAddress?.attendant }}</span>
          <br>
        </span>
        <span *ngIf="order?.deliveryAddress?.line1">{{ order?.deliveryAddress?.line1 }}<br></span>
        <span *ngIf="order?.deliveryAddress?.line2">{{ order?.deliveryAddress?.line2 }}<br></span>
        <span *ngIf="order?.deliveryAddress?.district || order?.deliveryAddress?.town || order?.deliveryAddress?.postalCode">
          {{ (order?.deliveryAddress?.town) + ',' || '' }}
          {{(order?.deliveryAddress?.region?.isocode) ? ((order?.deliveryAddress?.region?.isocode).replace("USA-","")+ " ") : ""}}
          {{ order?.deliveryAddress?.postalCode || '' }}
          {{ order?.deliveryAddress?.country?.isocode }}
          <br>
        </span>
      </p>
    </div>
    <div>
      <button (click)="navigateToDetails(order?.orderType)" mat-flat-button color="primary" class="pt-2 order-history-detail-see-details">
        {{"account.ordersHistory.seeDetails"| cxTranslate}}
      </button>
    </div>
  </div>

</div>