import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  private sharedData: Subject<any> = new Subject<any>();
  sharedData$: Observable<any> = this.sharedData.asObservable();

  private removeAddress: Subject<any> = new Subject<any>();
  removeAddress$: Observable<any> = this.removeAddress.asObservable();

  private removeBusinessAddress: Subject<any> = new Subject<any>();
  removeBusinessAddress$: Observable<any> = this.removeBusinessAddress.asObservable();

  private removeClientAddress: Subject<any> = new Subject<any>();
  removeClientAddress$: Observable<any> =
    this.removeClientAddress.asObservable();

  private removeManageAcct: Subject<any> = new Subject<any>();
  removeManageAcct$: Observable<any> = this.removeManageAcct.asObservable();

  private removeManageClientAcct: Subject<any> = new Subject<any>();
  removeManageClientAcct$: Observable<any> = this.removeManageClientAcct.asObservable();

  private shipToSelectedInCart: Subject<any> = new Subject<any>();
  shipToSelectedInCart$: Observable<any> = this.shipToSelectedInCart.asObservable();

  private showErrorInCartPage: Subject<any> = new Subject<any>();
  showErrorInCartPage$: Observable<any> = this.showErrorInCartPage.asObservable();
  
  
  constructor() {}

  //data to be shared between forms
  setData(updatedData: any) {
    this.sharedData.next(updatedData);
  }

  removeBusinessAddressFromAccount(data: any) {
    this.removeBusinessAddress.next(data);
  }

  removeClientAddressFromAccount(data: any) {
    this.removeClientAddress.next(data);
  }

  removeManageAcctFromAccount(data: any) {
    this.removeManageAcct.next(data);
  }

  removeManageClientAcctFromAccount(data: any) {
    this.removeManageClientAcct.next(data);
  }

  shipToSelectedInCheckout(data: any){
    this.shipToSelectedInCart.next(data);
  }
  showErrorMessageInCartPage(data:any){
    this.showErrorInCartPage.next(data);
  }
}
