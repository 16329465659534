import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CmsParagraphComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { ScriptService } from '../../services/script.service';
@Component({
  selector: 'custom-paragraph',
  templateUrl: './custom-paragraph.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CustomParagraphComponent implements OnInit {
  htmlContent:any;
  htmlContentTemp:SafeHtml;
  constructor(private sanitizer: DomSanitizer,
    public component: CmsComponentData<CmsParagraphComponent>,
    @Inject(DOCUMENT) private document: Document,
    private scriptService: ScriptService,
    private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit(): void {
    
    this.htmlContent = this.component.data$.subscribe((data: any)=>{
      if(isPlatformBrowser(this.platformId)){
          var parser = new DOMParser();
          var scripts = parser.parseFromString(data?.content, 'text/html').getElementsByTagName('script');
          
          if(scripts.length>0){
            for(let i=0; i<scripts.length;i++){
              if(scripts[i].src!="")
              this.scriptService.loadScriptContent(scripts[i].src);
              else{
                this.scriptService.loadScriptWithContent(scripts[i]?.innerHTML);
              }
            }
          }   
      }
     
      this.htmlContentTemp = this.sanitizer.bypassSecurityTrustHtml(data?.content ? data?.content : '');
      this.cdr.detectChanges();
    })
  }

}
