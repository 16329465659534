import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject , BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { BoxoutEndpointService } from './boxout-endpoint.service';

@Injectable({
  providedIn: 'root',
})
export class ProductDetailService {

  private productdataSubject = new BehaviorSubject<any>({}); // Resolving the data refresh issue on the browser back button by changing subject to Behaviour SUbject
  private descriptionScrollSubject = new Subject<any>();
  apiURL: any =`/orgProducts/`;
  
  constructor(private customHttp: BoxoutEndpointService) {
  }
  
  getProdutDetail(productCode:any){
    return this.customHttp.get(this.apiURL+productCode).pipe();
  }
  setProdutData(data: string) {  
    this.productdataSubject.next(data);  
  }  
  getProdutData(): Observable<any> {
    return this.productdataSubject.asObservable().pipe();  
  }
  setDescScroll() {  
    this.descriptionScrollSubject.next(1);  
  }  
  getDescScroll(): Observable<any> {  
    return this.descriptionScrollSubject.asObservable();  
  }
}
