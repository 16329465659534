import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { SiteContextConfig } from '@spartacus/core';
import { HamburgerMenuService, ICON_TYPE, NavigationNode } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'app-header-navigation-ui',
  templateUrl: './header-navigation-ui.component.html',
})
export class HeaderNavigationUiComponent implements OnInit, OnDestroy {
  /**
   * The navigation node to render.
   */
  @Input() node: any;

  /**
   * The number of child nodes that must be wrapped.
   */
  @Input() wrapAfter: any;

  /**
   * Flag indicates whether to reset the state of menu navigation (ie. Collapse all submenus) when the menu is closed.
   */
  @Input() resetMenuOnClose: any;

  /**
   * the icon type that will be used for navigation nodes
   * with children.
   */
  iconType = ICON_TYPE;

  /**
   * Indicates whether the navigation should support flyout.
   * If flyout is set to true, the
   * nested child navigation nodes will only appear on hover or focus.
   */
  @Input() @HostBinding('class.flyout') flyout = true;

  @Input() @HostBinding('class.is-open') isOpen = false;

  public openNodes: HTMLElement[] = [];
  private subscriptions = new Subscription();
  private resize = new EventEmitter();
  private navLinkHovered: boolean = false;
  navBannerSection: any = {bannerData: [] , isHorizontal: false };
  previousNavBanner: any = {bannerData: [] , isHorizontal: false };
  imageUrl : any;
  siteConfig: any;
  firstNavBanner: any;
  secondNavBanner: any;
  thirdNavBanner: any;
  isMobile: boolean;

  @HostListener('window:resize')
  onResize() {
    this.resize.next([]);
  }

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private elemRef: ElementRef,
    protected hamburgerMenuService: HamburgerMenuService,
    public breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialog,
    private config: SiteContextConfig, 
    @Inject(DOCUMENT) private document: Document,

  ) {
    this.siteConfig = this.config;
    this.subscriptions.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          this.clear();
        })
    );
    this.subscriptions.add(
      this.resize.pipe(debounceTime(50)).subscribe(() => {
        this.alignWrappersToRightIfStickOut();
      })
    );
  }

  /**
   * During initialization of this component, we will check the resetMenuOnClose flag and attach a menu reset listener if needed.
   */
  ngOnInit() {
    this.imageUrl = this.siteConfig?.backend?.occ?.baseUrl;
    if (this.resetMenuOnClose) {
      this.resetOnMenuCollapse();
    }

    this.breakpointObserver
      .observe(['(max-width:992px)'])
      .subscribe((state: BreakpointState) => {
        if (state.breakpoints['(max-width:992px)']) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
          let flyout = this.document.querySelectorAll('.flyout');
          for (let i = 0; i < flyout?.length; i++) {
            flyout[i].classList.remove('d-none');
            flyout[i].classList.add('d-flex');
          }
        }
      });
  }

  /**
   * This method performs the action of resetting the menu (close all sub menus and return to main options)
   * when the menu is closed.
   */
  resetOnMenuCollapse(): void {
    this.subscriptions.add(
      this.hamburgerMenuService?.isExpanded
        .pipe(distinctUntilChanged(), filter(Boolean))
        .subscribe(() => {
          this.reinitalizeMenu();
        })
    );
  }

  /**
   * This method performs the actions required to reset the state of the menu and reset any visual components.
   */
  reinitalizeMenu(): void {
    if (this.openNodes?.length > 0) {
      this.clear();
      this.renderer.removeClass(this.elemRef.nativeElement, 'is-open');
    }
    let openedFlyout = this.document.querySelectorAll('.flyout.is-open');
    let openNav = this.document.querySelectorAll('nav.is-open');
    let openedNav = this.document.querySelectorAll('nav.is-opened');

    for (let i = 0; i < openedFlyout?.length; i++) {
      openedFlyout[i].classList.remove('is-open');
    }

    for (let i = 0; i < openedNav?.length; i++) {
      openedNav[i].classList.remove('is-opened');
    }
    for (let i = 0; i < openNav?.length; i++) {
      openNav[i].classList.remove('is-open');
    }
    this.openNodes = [];
  }

  // CUSTOM function to open the navigation nodes on click if it has child
  toggleOpen(event: any, depth: number, navNode: any): void {
    const node = <HTMLElement>event.currentTarget;

    if(event.target.classList.contains('nav-title') && navNode?.url && depth !== 0) {
      this.router.navigate(navNode?.url);
    } else {
      if (depth === 1) {
        this.renderer.removeClass(event.currentTarget.closest('.main-nav-0'), 'is-open');
        this.renderer.addClass(event.currentTarget.closest('.main-nav-0'), 'is-opened');
      } else if(depth === 2) {
        this.renderer.removeClass(event.currentTarget.closest('.main-nav-0'), 'is-open');
        this.renderer.removeClass(event.currentTarget.closest('.main-nav-1'), 'is-open');
        this.renderer.addClass(event.currentTarget.closest('.main-nav-1'), 'is-opened');
      }
  
      if (this.openNodes.includes(node)) {
        this.openNodes = this.openNodes.filter((n) => n !== node);
        this.renderer.removeClass(node, 'is-open');
        // binding the coops on click on back arrow 
        if(depth === 1) {
         this.navBannerSection = this.firstNavBanner;
        } else if( depth === 2) {
          this.navBannerSection = this.secondNavBanner;
        } 
      } else {
        this.openNodes.push(node);
        // assigning the node coops to the variable to bind at FE
        this.navBannerSection = {
          bannerData: navNode?.navBanner,
          isHorizontal: navNode?.isHorizontal
        }
        //saving the coops in vars
        if(depth === 0) {
          this.firstNavBanner = this.navBannerSection;
        } else if( depth === 1) {
          this.secondNavBanner = this.navBannerSection;
        }
      }
  
      this.updateClasses(depth);
    }

   event.stopImmediatePropagation();
    event.stopPropagation();
  }


  //OOTB 
  clear(): void {
    this.openNodes = [];
    this.updateClasses();
    if (this.dialogRef) {
      this.dialogRef.closeAll(); // Custom change to close the mobile modal on route change
    }
  }

  //CUSTOM function to open the navigation flyout on desktop
  onMouseEnter(event: MouseEvent, depth: number, node:any) {

    if (depth === 0) {
      this.reinitalizeMenu();
      this.renderer.addClass(event.target, 'is-open');
      this.updateClasses(depth);
      // Adding backdrop when node has childrens
      if( node.children?.length > 0) {
        this.document.getElementById('custom-backdrop')?.classList.remove('hide-backdrop');
        this.document.getElementById('custom-backdrop')?.classList.add('show-backdrop');
      } else {
        //removing backdrop when nav doesnot has any childrens
        this.document.getElementById('custom-backdrop')?.classList.add('hide-backdrop');
        this.document.getElementById('custom-backdrop')?.classList.remove('show-backdrop');
      }

      //generating object for coops to bind in FE
      this.navBannerSection = {
        bannerData: node?.navBanner,
        isHorizontal: node?.isHorizontal
      }

      // Saving the first opened coops
      this.firstNavBanner = this.navBannerSection;
    } 
    else {
      event.stopImmediatePropagation();
      event.stopPropagation();
    }
  }

  //OOTB
  getTotalDepth(node: NavigationNode, depth = 0): number {
    if (node?.children && node?.children.length > 0) {
      return Math.max(
        ...node?.children.map((n) => this.getTotalDepth(n, depth + 1))
      );
    } else {
      return depth;
    }
  }

  //OOTB
  getColumnCount(length: number): number {
    return Math.round(length / (this.wrapAfter || length));
  }

  //OOTB
  focusAfterPreviousClicked(event: MouseEvent) {
    const target: HTMLElement = <HTMLElement>(
      (event.target || event.relatedTarget)
    );
    return target.ownerDocument;
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  //OOTB
  private alignWrapperToRightIfStickOut(node: HTMLElement) {
    const wrapper = <HTMLElement>node?.querySelector('.wrapper');
    const body = <HTMLElement>node?.closest('body');
    if (wrapper) {
      this.renderer.removeStyle(wrapper, 'margin-left');
      if (
        wrapper?.offsetLeft + wrapper?.offsetWidth >
        body?.offsetLeft + body?.offsetWidth
      ) {
        this.renderer.setStyle(
          wrapper,
          'margin-left',
          `${node?.offsetWidth - wrapper?.offsetWidth}px`
        );
      }
    }
  }

  //OOTB
  private alignWrappersToRightIfStickOut() {
    const navs = <HTMLCollection>this.elemRef.nativeElement.childNodes;
    Array.from(navs)
      .filter((node) => node?.tagName === 'NAV')
      .forEach((nav) => this.alignWrapperToRightIfStickOut(<HTMLElement>nav));
  }

  //OOTB
  private updateClasses(depth?: any): void {
    this.openNodes.forEach((node, i) => {
      if (i + 1 < this.openNodes.length) {
        this.renderer.addClass(node, 'is-opened');
        this.renderer.removeClass(node, 'is-open');
      } else {
        this.renderer.removeClass(node, 'is-opened');
        this.renderer.addClass(node, 'is-open');
      }

      let flyout = this.document.querySelectorAll('.flyout');
      if(this.isMobile && this.openNodes.length) {
        for (let i = 0; i < flyout?.length; i++) {
          flyout[i].classList.remove('d-flex');
          flyout[i].classList.add('d-none');
        }
        this.renderer.removeClass(node.closest('.flyout'), 'd-none');
        this.renderer.addClass(node.closest('.flyout'), 'd-flex.is-open');
      }
    });

    this.isOpen = this.openNodes?.length > 0;

    if(this.openNodes.length === 0 && depth === 0 && this.isMobile) {
      let flyout = this.document.querySelectorAll('.flyout');
        for (let i = 0; i < flyout?.length; i++) {
          flyout[i].classList.remove('d-none');
          flyout[i].classList.add('d-flex');
        }
    }
   
  }
  
}
