import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'addDollarCustomCSS'
})
export class addDollarCustomCSSDataPipe implements PipeTransform {
  transform( value :string | number) {
    const num = typeof value === 'string' ? parseFloat(value) : value ;

    if( isNaN(num)) 
      return '';

    const formttedValue = num.toFixed(2);
    const [ dollar , cents ] = formttedValue.split('.');

    return `<span class="t-rewards-inverse-brand-earned-custom"> $${dollar.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span><span class="t-rewards-inverse-amount-points">.${cents}</span>`;
  }
}