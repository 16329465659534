import { Directive, HostListener, ElementRef, OnInit } from "@angular/core";
import { formatPhonePipe } from "../pipes/format-phone";


@Directive({ selector: "[formatNumber]" })
export class formatNumberDirective implements OnInit {

  private el: HTMLInputElement;
  private phoneNumberRegex:any = '@"^[0-9*#+]+$';

  constructor(
    private elementRef: ElementRef,
    private phonePipe: formatPhonePipe
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    // var removedSpecialCharsNumber = this.el.value.trim().replace(/[^a-zA-Z0-9 ]/g, '');
    this.format(this.el.value);
  }

  @HostListener("keypress", ["$event"])
  onKeyPress(value:any) {
    return new RegExp('^[0-9]').test(value.key);
  }

  @HostListener("focus", ["$event.target.value"])
  onFocus(value:any) {
    this.el.value = value.trim().slice(1).trim().replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '');
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value:any) {
    this.format(value.trim().replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, ''));
  }

  format(value:any) {
    var phone = this.phonePipe.transform(value);
    this.el.value = phone ? phone : value;
  }

}