<!--This displays a dialog to select Client Type when user clicks on Create Client Account button-->
<div class="d-flex flex-column container select-client-type-dialogs-style">
  <div class="d-flex flex-row justify-content-between mb-4">
    <div>
      <section class="t-title-l">{{ "account.createAccount.clientShippingTitle" | cxTranslate }}
      </section>
    </div>
    <div>
      <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon" >close</mat-icon>
    </div>
  </div>
  
  <div class="d-flex flex-column mb-5">
    <form [formGroup]="selectClientTypeForm" (submit)="selectClientType()">
      <div class="mb-3 select-client-type-input-display">

        <mat-form-field appearance="outline">
          <mat-label appRemoveAriaOwns>{{ "account.createAccount.clientTypeTitle" | cxTranslate }}</mat-label>
          <mat-select formControlName="clientType">
            <mat-option *ngFor="let clientType of clientTypeList" [value]="clientType.value">
              {{ clientType.viewValue }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="
          selectClientTypeForm.get('clientType')?.touched &&
          selectClientTypeForm.get('clientType')?.hasError('required')
          ">{{ "account.createAccount.clientTypeRequired" | cxTranslate }}</mat-error>
        </mat-form-field>

      </div>

    

      <div class="d-flex justify-content-end">
        <div>
          <button
            mat-flat-button mat-dialog-close
            type="button"
            
            class="mr-2"
          >
          {{ "account.createAccount.cancelA" | cxTranslate }}
          </button>
        </div>
        <div class="select-client-type-submit-button">
          <button
            mat-flat-button
            type="submit"
          >
          {{ "account.createAccount.createA" | cxTranslate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
