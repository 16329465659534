<!-- ***************This component displays sign and security Component**************-->

<!-- Below displays Security header-->
<div class="row w-100 justify-content-between m-0">
    <div>
      <img src="../../../../../assets/logo/{{baseSiteNameVal}}.svg" class="login-dialog-image" alt="BoxOut-logo">
    </div>
    <div>
      <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon" >close</mat-icon>
    </div>
  </div>

<!-- Below displays Security form-->
<div class="d-flex flex-column alig-items-start sign-in-security-container reusable-component">
    <div class="d-flex flex-column">
        <div class="p-3">
            <!--  to show the error/success on click of save button : start-->
            <div *ngIf="!formValid && !formUpdated">
                <reusable-alert-box [alertType]="'warningAlertBox'" alertMsg="{{
                        'account.signInSecurityForm.errorAlertMsg' | cxTranslate
                      }}"></reusable-alert-box>
            </div>

            <div *ngIf="formUpdated && formValid">
                <reusable-alert-box [alertType]="'successAlertBox'" alertMsg="{{
                        'account.signInSecurityForm.successAlertMsg' | cxTranslate
                      }}"></reusable-alert-box>
            </div>
            <!--  to show the error/success on click of save button : end-->

            <div class="row">
                <div class="col text-center">
                  <p class="mt-4 pt-2 t-headline-m">
                    {{"global.reusableSignInSecurity.changePassword" | cxTranslate}}
                  </p>
                </div>
              </div>
            <form [formGroup]="signInSecurityForm">
                <div class="sign-in-input-display mt-3">
                    <mat-form-field appearance="outline">
                        <mat-label>{{
                            "account.signInSecurityForm.updateUserPassword.newPassword.label"
                            | cxTranslate
                            }}</mat-label>
                        <input matInput type="password" class="sign-in-security-dialog-input-outline"
                            formControlName="newPassword" onpaste="return false;" minlength="8" maxlength="20" (keydown)="$event.keyCode!==32?$event:$event.preventDefault()" />
                        <mat-error class="mat-error-text t-body-m" *ngIf="
                            ((signInSecurityForm.get('newPassword')?.touched &&
                              signInSecurityForm
                                .get('newPassword')
                                ?.hasError('required')) ||
                              newPasswordEmpty) &&
                            signInSecurityForm
                              .get('newPassword')
                              ?.hasError('required')
                          ">{{
                            "account.signInSecurityForm.updateUserPassword.newPassword.newPasswordRequired"
                            | cxTranslate
                            }}</mat-error>
                    </mat-form-field>
                </div>

                <div class="sign-in-input-display mt-2">
                    <mat-form-field appearance="outline">
                        <mat-label>{{
                            "account.signInSecurityForm.updateUserPassword.reEnteredNewPassword.label"
                            | cxTranslate
                            }}</mat-label>
                        <input matInput type="password" class="sign-in-security-dialog-input-outline"
                            formControlName="reEnteredNewPassword" onpaste="return false;" minlength="8"
                            maxlength="20" (keydown)="$event.keyCode!==32?$event:$event.preventDefault()" />
                        <mat-error class="mat-error-text t-body-m" *ngIf="
                            ((signInSecurityForm.get('reEnteredNewPassword')
                              ?.touched &&
                              signInSecurityForm
                                .get('reEnteredNewPassword')
                                ?.hasError('required')) ||
                              reEnteredNewPasswordEmpty) &&
                            signInSecurityForm
                              .get('reEnteredNewPassword')
                              ?.hasError('required')
                          ">{{
                            "account.signInSecurityForm.updateUserPassword.reEnteredNewPassword.reEnteredNewPasswordRequired"
                            | cxTranslate
                            }}</mat-error>
                    </mat-form-field>
                </div>

                <p class="text-center mb-3">
                    <button
                      mat-flat-button
                      class="password-dialog-password-Button"
                      color="primary"
                      (click)="saveSignInSecurityForm()"
                      >
                      {{"global.reusableSignInSecurity.createPasword" | cxTranslate}}
                    </button>
                </p>

                <div *ngIf="
                        signInSecurityForm.get('reEnteredNewPassword')?.dirty &&
                        signInSecurityForm
                          .get('reEnteredNewPassword')
                          ?.hasError('passwordMismatch')
                      " class="t-body-s mt-1">
                    <div>
                        {{
                        "account.signInSecurityForm.updateUserPassword.passwordMustMatchTitle"
                        | cxTranslate
                        }}
                    </div>
                    <ul class="password-validation-list">
                        <li>
                            {{
                            "account.signInSecurityForm.updateUserPassword.passwordMustMatchSubtitle"
                            | cxTranslate
                            }}
                        </li>
                    </ul>
                </div>

                <div class="t-body-s security-text-display d-flex align-items-center" *ngIf="
                        signInSecurityForm.get('reEnteredNewPassword')?.dirty &&
                        !signInSecurityForm
                          .get('reEnteredNewPassword')
                          ?.hasError('passwordMismatch') &&
                        signInSecurityForm.get('newPassword')?.valid &&
                        !signInSecurityForm
                          .get('reEnteredNewPassword')
                          ?.hasError('required')
                      ">
                    <span>
                        <mat-icon svgIcon="green-tick-box" class="create-user-green-tick mr-1" aria-label="greenTick">
                        </mat-icon>
                    </span>
                    {{
                    "account.signInSecurityForm.updateUserPassword.passwordReady"
                    | cxTranslate
                    }}
                </div>


                <div class="t-body-s" *ngIf="!signInSecurityForm.get('newPassword')?.valid">
                    <div class="mb-2">
                        {{
                        "account.signInSecurityForm.updateUserPassword.newPassword.creteria.label"
                        | cxTranslate
                        }}
                    </div>
                    <ul class="password-validation-list">
                        <li *ngIf="
                            !signInSecurityForm
                              .get('newPassword')
                              ?.hasError('passwordHasRequiredLength')
                          " class="mb-2">
                            {{
                            "account.signInSecurityForm.updateUserPassword.newPassword.creteria.char"
                            | cxTranslate
                            }}
                        </li>
                        <div class="mb-2 security-text-display-list d-flex" *ngIf="
                            signInSecurityForm
                              .get('newPassword')
                              ?.hasError('passwordHasRequiredLength')
                          ">
                            <span>
                                <mat-icon svgIcon="green-tick-box" class="create-user-green-tick mr-1"
                                    aria-label="greenTick"></mat-icon>
                            </span>
                            {{
                            "account.signInSecurityForm.updateUserPassword.newPassword.creteria.char"
                            | cxTranslate
                            }}
                        </div>

                        <li class="mb-2" *ngIf="
                            !signInSecurityForm
                              .get('newPassword')
                              ?.hasError('passwordHasUpperCase')
                          ">
                            {{
                            "account.signInSecurityForm.updateUserPassword.newPassword.creteria.upperChar"
                            | cxTranslate
                            }}
                        </li>
                        <div class="mb-2 security-text-display-list d-flex" *ngIf="
                            signInSecurityForm
                              .get('newPassword')
                              ?.hasError('passwordHasUpperCase')
                          ">
                            <span>
                                <mat-icon svgIcon="green-tick-box" class="create-user-green-tick mr-1"
                                    aria-label="greenTick"></mat-icon>
                            </span>
                            {{
                            "account.signInSecurityForm.updateUserPassword.newPassword.creteria.upperChar"
                            | cxTranslate
                            }}
                        </div>

                        <li class="mb-2" *ngIf="
                            !signInSecurityForm
                              .get('newPassword')
                              ?.hasError('passwordHasLowerCase')
                          ">
                            {{
                            "account.signInSecurityForm.updateUserPassword.newPassword.creteria.lowerChar"
                            | cxTranslate
                            }}
                        </li>
                        <div class="mb-2 security-text-display-list d-flex" *ngIf="
                            signInSecurityForm
                              .get('newPassword')
                              ?.hasError('passwordHasLowerCase')
                          ">
                            <span>
                                <mat-icon svgIcon="green-tick-box" class="create-user-green-tick mr-1"
                                    aria-label="greenTick"></mat-icon>
                            </span>
                            {{
                            "account.signInSecurityForm.updateUserPassword.newPassword.creteria.lowerChar"
                            | cxTranslate
                            }}
                        </div>
                    </ul>

                    <div class="mb-2">
                        {{
                        "account.signInSecurityForm.updateUserPassword.newPassword.optionalCreteria.label"
                        | cxTranslate
                        }}
                    </div>

                    <ul class="password-validation-list">
                        <li class="mb-2" *ngIf="
                            !signInSecurityForm
                              .get('newPassword')
                              ?.hasError('passwordHasNumeric')
                          ">
                            {{
                            "account.signInSecurityForm.updateUserPassword.newPassword.optionalCreteria.oneNumber"
                            | cxTranslate
                            }}
                        </li>
                        <div class="mb-2 security-text-display-list d-flex" *ngIf="
                            signInSecurityForm
                              .get('newPassword')
                              ?.hasError('passwordHasNumeric')
                          ">
                            <span>
                                <mat-icon svgIcon="green-tick-box" class="create-user-green-tick mr-1"
                                    aria-label="greenTick"></mat-icon>
                            </span>
                            {{
                            "account.signInSecurityForm.updateUserPassword.newPassword.optionalCreteria.oneNumber"
                            | cxTranslate
                            }}
                        </div>

                        <li class="mb-2" *ngIf="
                            !signInSecurityForm
                              .get('newPassword')
                              ?.hasError('passwordHasSpecialChar')
                          ">
                            {{
                            "account.signInSecurityForm.updateUserPassword.newPassword.optionalCreteria.specialChar"
                            | cxTranslate
                            }}
                        </li>
                        <div class="mb-2 security-text-display-list d-flex" *ngIf="
                            signInSecurityForm
                              .get('newPassword')
                              ?.hasError('passwordHasSpecialChar')
                          ">
                            <span>
                                <mat-icon svgIcon="green-tick-box" class="create-user-green-tick mr-1"
                                    aria-label="greenTick"></mat-icon>
                            </span>
                            {{
                            "account.signInSecurityForm.updateUserPassword.newPassword.optionalCreteria.specialChar"
                            | cxTranslate
                            }}
                        </div>
                    </ul>
                </div>

                <div class="t-body-s mb-4 security-text-display d-flex align-items-center" *ngIf="signInSecurityForm.get('newPassword')?.valid">
                    <span>
                        <mat-icon svgIcon="green-tick-box" class="create-user-green-tick mr-1" aria-label="greenTick">
                        </mat-icon>
                    </span>
                    {{
                    "account.signInSecurityForm.updateUserPassword.passwordReenterAlert"
                    | cxTranslate
                    }}
                </div>

                <div *ngIf="
                        signInSecurityForm.get('reEnteredNewPassword')?.dirty &&
                        !signInSecurityForm
                          .get('reEnteredNewPassword')
                          ?.hasError('passwordMismatch') &&
                        signInSecurityForm.get('newPassword')?.valid &&
                        !signInSecurityForm
                          .get('reEnteredNewPassword')
                          ?.hasError('required')
                      " class="t-body-s mb-4 security-text-display d-flex align-items-center">
                    <span>
                        <mat-icon svgIcon="green-tick-box" class="create-user-green-tick mr-1" aria-label="greenTick">
                        </mat-icon>
                    </span>
                    {{
                    "account.signInSecurityForm.updateUserPassword.passwordMatched"
                    | cxTranslate
                    }}
                </div>
            </form>
            <div class="row">
                <div class="col text-center">
                  <p class="mt-3 mb-0 t-label-s">
                    {{"global.password.sslLabel" | cxTranslate}}
                  </p>
                  <p class="mb-0 t-label-s">{{"global.password.sslLabelOne" | cxTranslate}}</p>
                </div>
              </div>
        </div>
    </div>
</div>

<!--sign in security component ends -->
