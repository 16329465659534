import { isPlatformBrowser } from '@angular/common';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStorageService, WindowRef } from '@spartacus/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UtilService } from 'src/app/core/services/util.service';
import { PageLoaderService } from './feature/pages/page-loader/page-loader.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  sessionTimedOut: boolean = false;
  timeLeftToExpire = 0;
  accessToken: any;
  expiresAt: any;
  constructor(
    private window: WindowRef,
    private utils: UtilService,
    private router: Router,
    private windowRef: WindowRef,
    private pageLoaderService: PageLoaderService,
    private cookieService: CookieService,
    private authService: AuthStorageService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }
  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (isPlatformBrowser(this.platformId)) {
      if (httpRequest.url.indexOf('basesite') !== -1) {
        let spartacusSession: any;
        spartacusSession = this.windowRef?.localStorage?.getItem('spartacus⚿⚿auth') ? this.windowRef?.localStorage?.getItem('spartacus⚿⚿auth') : '';
        let spartacusSessionObj = JSON.parse(spartacusSession);
        if (spartacusSessionObj && spartacusSessionObj?.token && Object.keys(spartacusSessionObj?.token)?.length !== 0) {
          this.accessToken = spartacusSessionObj?.token?.access_token;
          this.expiresAt = spartacusSessionObj?.token?.expires_at;
          const currentDate = new Date();
          const timestamp = currentDate?.getTime();
          this.timeLeftToExpire = Number(this.expiresAt) - timestamp;
          if (this.timeLeftToExpire >= 500) {
            let that = this;
            setInterval(function () {
              that.windowRef.localStorage?.clear();
              that.windowRef.sessionStorage?.clear();
              (that.windowRef as any).location.reload();
              //that.cookieService.delete('ACCESS_TOKEN');
            }, that.timeLeftToExpire - 500);
          } else {
            this.windowRef.localStorage?.clear();
            this.windowRef.sessionStorage?.clear();
            (this.windowRef as any).location.reload();
          }
        }
      }
    }


    /** Http Interceptor to append the b2bUnitHeader in all the HTTP calls */
    if (
      httpRequest.url.indexOf('smartystreets') === -1 &&
      httpRequest.url.indexOf('cardconnect') === -1 &&
      httpRequest.url.indexOf('npiregistry.cms.hhs.gov') === -1
    ) {
      if (this.window.localStorage?.getItem('b2bUnit')) {
        var selectedB2BUnit = this.window.localStorage.getItem('b2bUnit');
        httpRequest = httpRequest.clone({
          setHeaders: {
            b2bUnit: selectedB2BUnit ? selectedB2BUnit : '',
          },
        });
      } else {
        httpRequest = httpRequest.clone({
          setHeaders: {
            b2bUnit: ``,
          },
        });
      }

      if (this.window.localStorage?.getItem('asmLoggedInUserEmail')) {
        var isAsmUser = this.window.localStorage.getItem('asmLoggedInUserEmail');
        httpRequest = httpRequest.clone({
          setHeaders: {
            isAsm: isAsmUser ? "true" : "false",
          },
        });
      } else {
        httpRequest = httpRequest.clone({
          setHeaders: {
            isAsm: "false",
          },
        });
      }

      if (this.window.location.hostname?.includes('portal.myboxout')) {
        if (this.window.localStorage?.getItem('mfaSuccess')) {
          httpRequest = httpRequest.clone({
            setHeaders: {
              mfaSuccess: "true",
            },
          });
        } else {
          httpRequest = httpRequest.clone({
            setHeaders: {
              mfaSuccess: "false",
            },
          });
        }
      }
    }

    let clonedHttpRequest: any = null;

    // For asm user current replacement with email
    if (this.window?.localStorage?.getItem('asmLoggedInUserEmail')) {
      let newURL = httpRequest?.url;
      const impersonatedUserEmail = this.window?.sessionStorage?.getItem(
        'impersonatedUserEmail'
      );

      // For URL having '/current/'
      if (httpRequest?.url?.includes('/current/')) {
        newURL = newURL?.replace('/current/', `/${impersonatedUserEmail}/`);
        clonedHttpRequest = httpRequest?.clone({ url: newURL });
      }

      // For URL having '/uid/'
      const uid = this.window?.sessionStorage?.getItem('impersonatedUserId');

      if (uid && httpRequest?.url?.includes(`/${uid}/`)) {
        newURL = newURL?.replace(`/${uid}/`, `/${impersonatedUserEmail}/`);
        clonedHttpRequest = httpRequest?.clone({ url: newURL });
      }
    }

    return next.handle(clonedHttpRequest || httpRequest).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          event = event.clone({ body: this.interpretStatus(event) });
        }
        return event;
      }),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          if (this.sessionExpired(error)) {
            //reset cookies on session expired
            this.cookieService.delete('ACCESS_TOKEN');

            this.router.navigateByUrl('/login');
            (this.windowRef as any).location.reload();
          }
        }
        return throwError(error);
      })
    )
  }

  private interpretStatus(body: any) {
    if (body && body.status && body.status === 500) {
      this.utils.error500.next(true);
    } else if (body && body.status && body.status === 200 && body?.body?.typeCode === "ContentPage") {
      this.window?.sessionStorage?.setItem("boxoutTitle", (body?.body?.title ? body?.body?.title : ''));
    }
  }

  sessionExpired(body: any) {
    if (
      (body &&
        body.status &&
        body.status === 400 &&
        body?.error?.error === 'invalid_grant') ||
      (body &&
        body.status &&
        body.status === 401 &&
        body?.error?.errors &&
        body?.error?.errors[0] &&
        body?.error?.errors[0]?.type === 'InvalidTokenError')
    ) {
      this.sessionTimedOut = true;
    } else {
      this.sessionTimedOut = false;
    }
    return this.sessionTimedOut;
  }
}
