import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'formatPhone'
})
export class formatPhonePipe implements PipeTransform {
  transform(rawNum:string) {
    let returnValue;
    if(rawNum?.length > 0 && rawNum?.length<=14) {
      const areaCodeStr = rawNum?.slice(0,3);
      const midSectionStr = rawNum?.slice(3,6);
      const lastSectionStr = rawNum?.slice(6);  
      returnValue = `1 (${areaCodeStr}) ${midSectionStr}-${lastSectionStr}`;
    }
    return returnValue;
  }
}