import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { CartService } from 'src/app/shared/services/common/cart.service';

interface LinePromotion {
  buyQuantity: number;
  code: string;
  description?: string;
  description2?: string;
  title?: string;
  status: 'Available' | 'Applied';
  bogoMultiplier: number;
}

@Component({
  selector: 'app-line-promotion',
  templateUrl: './line-promotion.component.html',
})
export class LinePromotionComponent {
  @Input() promotion: LinePromotion;
  @Input() canApply: boolean = false;
  @Input() productCode: any;
  @Input() cartItemQty: any;
  @Input() uid: any;
  @Output() public sendSuccess = new EventEmitter();
  @Output() public sendError = new EventEmitter();
  maxQuantity: boolean = false;

  // For handling promotion status change
  @Output() promoStatusChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor(
    private http: HttpClient, 
    private cartService: CartService,
    private customHttp: BoxoutEndpointService,
    public cdr: ChangeDetectorRef,
    private windowRef: WindowRef
  ) {}

   // Output event for promo status change
  handlePromoStatusChange = () => {
    this.promoStatusChange.emit(this.promotion?.status === 'Applied');
  };

  showMsg() {
    this.windowRef.nativeWindow?.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  hideMsg() {
    this.sendSuccess.emit(false);
    this.sendError.emit(false);
  }

  updateCart() {
    if (this.productCode && this.promotion && this.promotion.buyQuantity) {
      const cartUpdateObj = {
        orderEntries: [
          {
            product: {
              code: this.productCode
            },
            quantity: this.promotion.buyQuantity
          }
        ]
      };
      this.updateCartApi(cartUpdateObj);
    } 
  }
  
  updateCartApi(cartUpdateObj: { orderEntries: { product: { code: string; }, quantity: number; }[]; }) {
    this.hideMsg();
    let apiUrl;
    const cartId = sessionStorage.getItem('cartId');
    apiUrl = `/users/current/carts/${cartId}/entries/`;
    this.customHttp.put(apiUrl, cartUpdateObj).subscribe(
      (data: any) => {
        if (data.success) {
          this.sendSuccess.emit(true);
          this.showMsg();
          this.cartService.getCartPageData(this.uid, false);
        } else if(data.cartModifications[0]?.statusCode === 'maxOrderQuantityExceeded'){
          this.maxQuantity = true;
          this.cdr.detectChanges();
        }
      },
      (err: any) => {
        this.sendError.emit(true);
        this.showMsg();
    })
  }
}
