import { Injectable } from '@angular/core';
import { CmsLinkComponent } from '@spartacus/core';

@Injectable({
  providedIn: 'root'
})
export class LinkTargetService {

  constructor() { }

  getTarget(data: CmsLinkComponent): string | null {
    return data?.target === 'true' || data?.target === true ? '_blank' : null;
  }
}
