import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SiteContextConfig } from '@spartacus/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
 
  public isLoggedInSource: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  isLoggedIn: Observable<any> = this.isLoggedInSource.asObservable();

  public sideNavToggleSource: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  sideNavToggle: Observable<any> = this.sideNavToggleSource.asObservable();

  public accountMainDataSource: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  accountMainData: Observable<any> = this.accountMainDataSource.asObservable();
  
  public localPropertiesConfigDataSource: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  localPropertiesConfigData: Observable<any> = this.localPropertiesConfigDataSource.asObservable();

  constructor(private config: SiteContextConfig,private http: HttpClient) { }
  public headerMobileLogo: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  headerMobileLogoData: Observable<any> = this.headerMobileLogo.asObservable();

  public phoneNumberParagraph: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  phoneNumberParagraphData: Observable<any> = this.phoneNumberParagraph.asObservable();  

  public isAdminSource: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  isAdmin: Observable<any> = this.isAdminSource.asObservable();  

  public isManageAccountSource: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  isManageAccount: Observable<any> = this.isManageAccountSource.asObservable();
  
  private addCardCountSource = new BehaviorSubject<any>(null);
  addCardCount = this.addCardCountSource.asObservable();

  private copySelectedToFavoriteToNewListSavedSource = new Subject<any>();
  copySelectedToFavoriteToNewList = this.copySelectedToFavoriteToNewListSavedSource.asObservable();

  public recaptchaEnableSource: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  recaptchaEnableData: Observable<any> = this.recaptchaEnableSource.asObservable();

  recaptchaData(data: any) {
    this.recaptchaEnableSource.next(data);
  }

  sendData(data: any) {
    this.isLoggedInSource.next(data);
  }
  changeSideNavToggle(data: any) {
    this.sideNavToggleSource.next(data);
  }

  saveAccountMainData(data: any){
    this.accountMainDataSource.next(data);    
  }
  getAutoSearchData(search:any,searchApiUrl:any){
    let apiURL = `${searchApiUrl}&strPrefix=${search}`;
    return this.http.jsonp(apiURL, "callback")
  }
  getProductRecommendations(search:any,principles:any,productApiUrl:any){
    let apiURL = `${productApiUrl}${principles}&query=${search}`;
    return this.http.get(apiURL)
  }
  sendLocalPropertiesConfigData(data: any) {
    this.localPropertiesConfigDataSource.next(data);
  }
  getCurrentSite() {
    return this.config?.context?.baseSite ? this.config?.context?.baseSite : '';
  }
  saveheaderMobileLogo(data: any){
    this.headerMobileLogo.next(data);    
  }

  savephoneNumberParagraph(data: any){
    this.phoneNumberParagraph.next(data);    
  }

  saveIsAdminFlag (data:boolean) {
    this.isAdminSource.next(data);   
  }

  saveIsManageAccount(data: boolean) {
    this.isManageAccountSource.next(data);
  }
  setAddCardCount(count: any) {
    this.addCardCountSource.next(count);
  }

  setCopySelectedToFavoriteToNewList(count: any) {
    this.copySelectedToFavoriteToNewListSavedSource.next(count);
  }
}
