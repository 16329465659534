<app-breadcrumb [breadcrumbMapping]="breadcrumb"></app-breadcrumb>

<div>
  <div class="acct-page-header p-3 m-2 m-md-0 mb-md-3" *ngIf="rewardTitle">
    <span class="t-section-header m-0">
      {{ rewardTitle }}
    </span>
    <div class="t-title-m pt-2">
      {{ rewardSummary }}
    </div>
  </div>

  <div *ngIf="creditRewards">
    <div class="rewards-credits-header d-flex flex-column flex-sm-row justify-content-between m-2 m-md-0 mb-md-3">
      <div class="py-sm-4 pl-sm-3 p-3">
        <h1 class="t-rewards-total-earned-label">{{'account.rewards.totalEarnedLabel' | cxTranslate}}
        </h1>
        <h1 class="t-rewards-total-earned-val">{{ creditRewards?.totalEarnedCredits | currency:'USD' }}
        </h1>
        <h5 class="t-rewards-brand-earned-note pt-1 mt-1 mb-0">{{'account.rewards.lastUpdateLabel' | cxTranslate}}
          {{creditRewards?.lastUpdatedDate | date: "MM/dd/yyyy"}}
        </h5>
      </div>
      <div class="px-3 px-sm-5 py-3 py-sm-5 rewards-credits-total-earned d-flex-row align-items-center pt-2">
        <div class="text-right py-2" [innerHTML]="creditRewards?.totalAvailableCredits | addDollarCustomCSS"></div>
        <h5 class="t-rewards-inverse-amount-subtitle text-right ">{{'account.rewards.availabelLabel' | cxTranslate}}
        </h5>
      </div>
    </div>

    <div *ngIf="creditRewards?.rewards?.length">
      <div
      class="rewards-credits-details-wrapper justify-content-between p-3 mb-3 d-flex flex-column flex-sm-row m-2 m-md-0 mb-md-3"
      *ngFor="let item of creditRewards?.rewards">

      <div class="rewards-credits-brand-details-left">
        <div class="t-rewards-details-label">{{ 'account.rewards.brandLabel' | cxTranslate}}</div>
        <div class="t-rewards-brand-name">{{item.brandData?.name}}</div>
      </div>

      <div *ngIf="parseInteger(item?.totalEarnedCredits) > 0"
        class="rewards-credits-brand-details-right d-flex flex-column flex-sm-row pt-3 pt-sm-0 justify-content-start justify-content-sm-end">
        <div
          class="rewards-credits-details-popup d-flex align-items-center justify-content-end justify-content-sm-center pb-3 pb-sm-0 pr-sm-0  pr-md-3">
          <button (click)="navigateToDetails(item.brandData?.code, item.brandData?.name)"
            class="rewards-credits-button-class t-label-m py-1 px-2">
            {{'account.rewards.detailsLabel' | cxTranslate}} 
          </button>
        </div>
        <div class="rewards-credits-earned-total d-flex flex-row justify-content-between pr-0 pr-lg-3">
          <div class="rewards-credits-earned d-flex align-items-right flex-column px-2 py-2 mx-0 mx-sm-2">
            <div class="t-rewards-label text-right">{{'account.rewards.earnedLabel' | cxTranslate }}</div>
            <div class="t-rewards-brand-earned-value text-right">{{item?.totalEarnedCredits | currency:'USD' }}</div>
          </div>
          <div
            class="rewards-credits-total-available text-right d-flex align-items-right flex-column px-2 py-2 ml-3 ml-sm-2 ml-md-3">
            <div class="t-rewards-label text-right">{{'account.rewards.totalAvaliableLabel' | cxTranslate}}</div>
            <div class="t-rewards-value text-right">{{ item?.totalAvailableCredits | currency:'USD' }}</div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="rewards-credits-program-overview m-2 m-md-0 p-3">
      <cx-page-slot position="ProgramContent"></cx-page-slot>
    </div>
  </div>
</div>

<div *ngIf="showWarning">
  <reusable-alert-box [alertType]="'warningAlertBox'" alertMsg="{{'account.rewards.errorMsg' | cxTranslate }}">
  </reusable-alert-box>
</div>
