import { LayoutConfig } from "@spartacus/storefront";

/**
 * The layout configuration is used to define the overall layout of the storefront.
 * The configuration includes the following aspects:
 * - breakpoint layout (AKA screen layout)
 * - Page sections slot configuration (i.e. header vs footer)
 * - page template slot configuration (i.e. landing page template vs PDP page template)
 * - deferred loading configuration
 *
 * The page slot configurations is directly related to the data in the backend. If you use the
 * Spartacus sample-data, you will have an aligned setup. However, if you introduce custom page
 * templates and/or slots, you most likely need to further adjust or replace this configuration.
 */
export const CustomLayoutConfig: LayoutConfig = {
  layoutSlots: {
    LandingPage2Template: {
      slots: [
        'Section1',
        'Section2A',
        'Section2B',
        'Section2C',
        'Section3',
        'Section4',
        'Section5',
      ],
    },
    ContentPage1Template: {
      slots: ['Section2A', 'Section2B'],
    },
    ErrorDetailsPageTemplate : {
      slots: [
        'errorDetailsSummaryFlexSlot'
      ]
    },
    knowledgeTrainersListingPageTemplate: {
      slots: ['headerSocialMediaSection', 'knowledgeTrainersListingTopContentSection', 'knowledgeTrainersListingTopBannerSection', 'LeftNavTitleParagraphSection', 'LeftNavLinksSection']
    },
    contactUsPageTemplate: {
      slots: ['contactUsMainHeadingSection', 'contactUsPromotionRightSection', 'contactUsCallSection', 'LeftNavTitleParagraphSection', 'WBCNeedAssistanceInfoSection', 'MiddleLeftPromotionSection', 'topParagraphSection', 'topParagraphSection', 'LeftNavLinksSection', 'contactUsSocialMediaSection', 'contactUsMailingAddressSection', 'contactUsPromotionLeftSection']
    },
    faqPageTemplate: {
      slots: ['promotionSection', 'LeftNavTitleParagraphSection', 'WBCNeedAssistanceInfoSection', 'LeftNavLinksSection', 'faqParagraphComponentSection', 'faqHeaderSection']
    }, 
    knowledgePageTemplate: {
      slots: ['FWBlogSection', 'promotionSection1', 'promotionSection', 'knowledgeFWBlogSection', 'knowledgeHeadingSection', 'WBCKnowledgeProSection', 'WBCKnowledgePersonelSection']
    }, 
    knowledgeTrainerDetailsPageTemplate: {
      slots: ['knowledgeTrainerDetailsPromoSection', 'knowledgeTrainerDetailsTopBannerSection', 'knowledgeTrainerDetailsProductCarouselSection', 'headerSocialMediaSection', 'LeftNavTitleParagraphSection', 'knowledgeTrainerDetailsSection', 'LeftNavLinksSection']
    },
    whatHotPageTemplate: {
      slots: ['WBCWhatsHotMiddleBottomLeft2Section', 'WBCWhatsHotMiddleBottomRightSection', 'WBCWhatsHotTopSection', 'WBCWhatsHotBottomSection', 'WBCWhatsHotTopBottomRight2Section', 'WBCWhatsHotTopBottomRightSection', 'WBCWhatsHotTopBottomLeft2Section', 'WBCWhatsHotMiddleBottomLeftSection', 'WBCWhatsHotTopBottomLeftSection', 'WBCWhatsHotMiddleBottomRight2Section', 'WBCWhatsHotMiddleSection', 'whatsHotHeadingSection']
    },
    privacyPolicyPageTemplate: {
      slots: ['returnPolicyParagraphComponentSection', 'ReturnsHeaderSection', 'promotionSection']
    },
    ourCulturePageTemplate: {
      slots: ['returnPolicyParagraphComponentSection', 'ReturnsHeaderSection', 'promotionSection', 'LeftNavTitleParagraphSection', 'LeftNavLinksSection']
    }, 
    wbcArticlePage2Template: {
      slots: ['headerSocialMediaSection', 'TopBannerSection', 'wbcArticleParagraphSection', 'wbcArticlePromoSection', 'LeftNavTitleParagraphSection', 'wbcArticleImageSection', 'LeftNavLinksSection']
    },
    livingHealthyPageTemplate: {
      slots: ['headerSocialMediaSection', 'knowledgeTrainersListingTopContentSection', 'knowledgeTrainersListingTopBannerSection', 'knowledgeTrainersListingBodySection', 'knowledgeTrainersListingPromoSection', 'LeftNavTitleParagraphSection', 'LeftNavLinksSection']
    }, 
    gymsClubsCrossFitBoxesPageTemplate: {
      slots: ['headerSocialMediaSection', 'knowledgeTrainersListingTopContentSection', 'knowledgeTrainersListingTopBannerSection', 'knowledgeTrainersListingPromoSection', 'knowledgeTrainersListingBodySection', 'LeftNavTitleParagraphSection', 'LeftNavLinksSection']
    }, 
    siteMapPageTemplate: {
      slots: ['returnPolicyParagraphComponentSection', 'promotionSection', 'ReturnsHeaderSection', 'knowledgeTrainersListingBodySection', 'LeftNavLinksSection']
    }, 
    knowledgeTrainingTrendsPageTemplate: {
      slots: ['headerSocialMediaSection', 'knowledgeTrainersListingTopContentSection', 'knowledgeTrainersListingTopBannerSection', 'knowledgeTrainersListingPromoSection', 'knowledgeTrainersListingBodySection', 'LeftNavTitleParagraphSection', 'LeftNavLinksSection']
    },
    wbcArticlePage1Template: {
      slots: ['headerSocialMediaSection', 'TopBannerSection', 'wbcArticleParagraphSection', 'wbcArticlePromoSection', 'LeftNavTitleParagraphSection', 'wbcArticleImageSection', 'LeftNavLinksSection']
    }, 
    distributorPageTemplate: {
      slots: ['returnPolicyParagraphComponentSection', 'ReturnsHeaderSection', 'promotionSection', 'LeftNavTitleParagraphSection', 'LeftNavLinksSection']
    }, 
    knowledgeNewExercisesForOldEquipmentPageTemplate: {
      slots: ['headerSocialMediaSection', 'knowledgeTrainersListingTopContentSection', 'knowledgeTrainersListingTopBannerSection', 'knowledgeTrainersListingTopBannerSection', 'knowledgeTrainersListingBodySection', 'LeftNavTitleParagraphSection', 'LeftNavLinksSection']
    }, 
    returnPolicyPageTemplate: {
      slots: ['returnPolicyParagraphComponentSection', 'promotionSection', 'ReturnsHeaderSection', 'LeftNavTitleParagraphSection', 'LeftNavLinksSection']
    }, 
    trainerAdvantageProgramPageTemplate: {
      slots: ['returnPolicyParagraphComponentSection', 'promotionSection', 'ReturnsHeaderSection', 'LeftNavTitleParagraphSection', 'LeftNavLinksSection']
    }, 
    'terms&ConditionsPageTemplate': {
      slots: ['returnPolicyParagraphComponentSection', 'promotionSection', 'ReturnsHeaderSection']
    },
    knowledgeTrainingHowTosPageTemplate: {
      slots: ['headerSocialMediaSection', 'knowledgeTrainersListingTopContentSection', 'knowledgeTrainersListingTopBannerSection', 'knowledgeTrainersListingPromoSection', 'knowledgeTrainersListingBodySection', 'LeftNavTitleParagraphSection', 'LeftNavLinksSection']
    }, 
    gymsClubsYogaStudiosAndPilatePageTemplate: {
      slots: ['headerSocialMediaSection', 'knowledgeTrainersListingTopContentSection', 'knowledgeTrainersListingTopContentSection', 'knowledgeTrainersListingPromoSection', 'knowledgeTrainersListingBodySection', 'LeftNavTitleParagraphSection', 'LeftNavLinksSection']
    }, 
    aboutUsPageTemplate: {
      slots: ['ShippingHeaderSection', 'TopBannerSection', 'wbcArticlePromoSection', 
      'aboutUsPromoSection', 'WBCWhatsHotTopBottomRightSection', 'WBCWhatsHotMiddleBottomLeftSection', 
      'faqParagraphComponentSection', 'WBCWhatsHotMiddleSection', 'whatsHotHeadingSection', 'WBCWhatsHotBottomSection', 
      'wbcArticleParagraphSection', 'promotionSection', 'ReturnsHeaderSection', 'knowledgeCatLandingTrainersSection', 
      'aboutUsBodySection', 'WBCWhatsHotMiddleBottomRight2Section', 'WBCWhatsHotTopSection', 'WBCWhatsHotMiddleBottomRightSection', 
      'WBCWhatsHotMiddleBottomRightSection', 'returnPolicyParagraphComponentSection', 'shippingPolicyParagraphComponentSection', 'knowledgeTrainersPromoSection',
      'InstructionalTextArea', 'WBCWhatsHotTopBottomRight2Section', 'LeftNavTitleParagraphSection', 'WBCWhatsHotTopBottomLeftSection', 'WBCWhatsHotMiddleBottomLeft2Section', 
      'headerSocialMediaSection', 'PortalBannerArea', 'aboutUsTopBannerSection', 'knowledgeTrainersTopBannerSection', 'WBCWhatsHotTopBottomLeft2Section', 'LeftNavLinksSection', 'faqHeaderSection'
      ]
    }, 
    personalizeYourProductsPageTemplate: {
      slots: ['returnPolicyParagraphComponentSection', 'promotionSection', 'ReturnsHeaderSection', 'LeftNavTitleParagraphSection', 'LeftNavLinksSection']
    }, 
    spaAboutUsPageTemplate: {
      slots: ['ShippingHeaderSection', 'SpaAboutUsPromoBannerSection', 'SpaLeftNavTitle1ParagraphSection', 'shippingPolicyParagraphComponentSection', 'SpaLeftNavTitle1Section', 'SpaAboutUsBodySection', 'LeftNavTitleParagraphSection', 'SpaParagraph3Section', 'SpaParagraph1Section', 'SpaParagraph2Section', 'promotionSection', 'ReturnsHeaderSection', 'SpaAboutUsImage1', 'SpaAboutUsImage3', 'SpaAboutUsImage2', 'SpaLeftNavTitle2ParagraphSection', 'LeftNavLinksSection']
    }, 
    communityInvolvementPageTemplate: {
      slots: ['returnPolicyParagraphComponentSection', 'ReturnsHeaderSection', 'promotionSection', 'LeftNavTitleParagraphSection', 'LeftNavLinksSection']
    },
    MuContentPageTemplate: {
      slots: ['MuMainContentSlot', 'MuMainMainSiteLogo', 'MuMainSiteLogo']
    }, 
    gymsClubsFacilityTrendsPageTemplate: {
      slots: ['headerSocialMediaSection', 'knowledgeTrainersListingTopContentSection', 'knowledgeTrainersListingTopBannerSection', 'knowledgeTrainersListingPromoSection', 'knowledgeTrainersListingBodySection', 'LeftNavTitleParagraphSection', 'LeftNavLinksSection']
    },
    salesRepContentPageTemplate: {
      slots: ['salesRepMainContent']
    },
    knowledgeTrainingCaseStudiesPageTemplate: {
      slots: ['headerSocialMediaSection', 'knowledgeTrainersListingTopContentSection', 'knowledgeTrainersListingTopBannerSection', 'knowledgeTrainersListingPromoSection', 'knowledgeTrainersListingBodySection', 'LeftNavTitleParagraphSection', 'LeftNavLinksSection']
    }, 
    exerciseTrendsPageTemplate: {
      slots: ['headerSocialMediaSection', 'knowledgeTrainersListingTopContentSection', 'knowledgeTrainersListingTopBannerSection', 'knowledgeTrainersListingBodySection', 'knowledgeTrainersListingPromoSection', 'LeftNavTitleParagraphSection']
    },
    InterestsAndGoalsPageTemplate: {
      slots: ['InterestImage', 'IntGoalsPromoSection', 'GoalImage', 'InterestsGoalsPageHeader']
    }, 
    OmContentPageTemplate: {
      slots: ['salesRepMainContent', 'OMMainSiteLogo', 'OMMainContentSlot', 'WBCRotatingProSection']
    }, 
    HealthFitnessPageTemplate: {
      slots: ['InstructionalTextArea', 'PortalBannerArea']
    }, 
    gymsClubsFacilityListingPageTemplate: {
      slots: ['headerSocialMediaSection', 'knowledgeTrainersListingTopContentSection', 'knowledgeTrainersListingTopBannerSection', 'knowledgeTrainersListingPromoSection', 'knowledgeTrainersListingBodySection', 'LeftNavTitleParagraphSection', 'LeftNavLinksSection']
    }, 
    ProfessionalHowTosPageTemplate: {
      slots: ['headerSocialMediaSection', 'knowledgeTrainersPromoSection', 'knowledgeCatLandingTrainersSection', 'LeftNavTitleParagraphSection', 'knowledgeTrainersTopBannerSection', 'LeftNavLinksSection']
    }, 
    recipesPageTemplate: {
      slots: ['headerSocialMediaSection', 'knowledgeTrainersListingTopContentSection', 'knowledgeTrainersListingTopBannerSection', 'knowledgeTrainersListingPromoSection', 'knowledgeTrainersListingPromoSection', 'LeftNavTitleParagraphSection', 'LeftNavLinksSection']
    },
    careerPageTemplate: {
      slots: ['returnPolicyParagraphComponentSection', 'ReturnsHeaderSection', 'promotionSection', 'LeftNavTitleParagraphSection', 'LeftNavLinksSection']
    }, 
    wbcArticlePage3Template: {
      slots: ['wbcArticlepromoSection', 'headerSocialMediaSection', 'TopBannerSection', 'wbcArticleParagraphSection', 'wbcArticlePromoSection', 'LeftNavTitleParagraphSection', 'LeftNavLinksSection']
    },
    HcpcsPageTemplate: {
      slots: ['hcpcsHeaderSection']
    },
    wbcArticlePage4Template: {
      slots: ['TopBannerSection', 'wbcArticlePromoSection', 'wbcArticleParagraphSection', 'LeftNavTitleParagraphSection','wbcArticleImageSection', 'LeftNavLinksSection']
    }, 
    knowledgeTrainersPageTemplate: {
      slots: ['headerSocialMediaSection', 'knowledgeTrainersPromoSection', 'knowledgeCatLandingTrainersSection', 'LeftNavTitleParagraphSection', 'knowledgeTrainersTopBannerSection', 'LeftNavLinksSection']
    },
    shippingPolicyPageTemplate: {
      slots: ['ShippingHeaderSection', 'shippingPolicyParagraphComponentSection', 'promotionSection', 'LeftNavTitleParagraphSection' , 'LeftNavLinksSection']
    }, 
    gymsClubsPageTemplate: {
      slots: ['headerSocialMediaSection', 'knowledgeTrainersPromoSection', 'knowledgeCatLandingTrainersSection', 'LeftNavTitleParagraphSection', 'knowledgeTrainersTopBannerSection', 'LeftNavLinksSection']
    }, 
    Fit36PortalPageNewTemplate: {
      slots: ['InstructionalTextArea', 'PortalBannerArea']
    }, 
    wbcArticlePage5Template: {
      slots: ['headerSocialMediaSection', 'TopBannerSection', 'wbcArticleParagraphSection', 'wbcArticlePromoSection', 'LeftNavTitleParagraphSection', 'wbcArticleImageSection', 'LeftNavLinksSection']
    },
    LoginPageTemplate: {
      slots: ['RegisterPageFormComponentPosition', 'RegisterPageRightComponentPosition1','RegisterPageRightComponentPosition2', 'RegisterPageRightComponentPosition3']
    },
    CartPageTemplate: {
      slots: ['CartProductListSlotName-cartPage','CartSummarySlotName-cartPage']
    },
    advancedEbillReportResultsTemplate: {
      slots: ['advancedEbillReportResultsSlot']
    },
    AccountPageTemplate: {
      slots: ['SideContent', 'BodyContent']
    },
    backordersReportResultsTemplate: {
      slots: ['backordersReportResultsSlot']
    },
    BrandPageTemplate: {
      slots: ['BrandsPageFlexSlot']
    },
    SalesRepLoginPageTemplate: {
      slots: ['SalesRepRightComponentPosition']
    },
    SalesRepHomePageTemplate: {
      slots: ['SalesRepBarGraph']
    },
    SalesRepAccountSearchPageTemplate: {
      slots: ['SalesRepAccountSearchResults']
    },
    SearchPageTemplate: {
      slots: ['SearchPlaceholderContentSlot']
    },
    CategorySearchPageTemplate: {
      slots: ['CategorySearchPlaceholderContentSlot']
    },
    BrandSearchPageTemplate: {
      slots: ['BrandPlaceholderContentSlot']
    },
    RapidOrderPageTemplate: {
      slots: ['BodyContent']
    },
    muHomePageTemplate: {
      slots: ['AddSection1']
    },
    omMobileHomePageTemplate: {
      slots: ['SPAMobilePromoSection']
    },
    omHomePageTemplate: {
      slots: ['WBCAlertSection', 'salesRepMainContent', 'AddSection1']
    },
    CustomLoginPageTemplate: {
      slots: ['RightContentSlot']
    },
    BoxoutPortalPageTemplate: {
      slots: ['BoxoutPortalHomeLeftNavBar'] 
    },
    BoxoutPortalLandingPage2Template: {
      slots: ['BoxoutPortalLeftNavBar']
    }
  },
};
