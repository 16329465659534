<div class="order-promotion pt-3">
  <!-- For All Order Promotions -->
  <div class="all-promotions p-1 cursor-pointer" (click)="handlePromotionSlider()">
    <div
      class="inner-body d-flex p-3 justify-content-between align-items-center"
    >
      <div class="promotion-icon-container p-2 text-center d-flex align-items-center justify-content-center">
        <mat-icon
          class="promotion-icon m-1"
          svgIcon="order_promotion"
        ></mat-icon>
      </div>
      <div class="content-section">
        <div class="t-order_promo-heading">
          <span class="promo-text text-capitalize">{{
            (promotions?.length
              ? "cart.orderPromo.promotions"
              : "cart.orderPromo.promosAvailable"
            ) | cxTranslate
          }}</span>
        </div>
        <div class="t-order_promo-desc">
          <ng-container
            *ngIf="promotions?.length as aPromoLength; else noAppliedPromotions"
          >
            {{ "cart.orderPromo.youAreLeveraging" | cxTranslate }}
            <span class="font-weight-bold">{{ aPromoLength }}</span>
            {{
              (aPromoLength > 1
                ? "cart.orderPromo.promotions"
                : "cart.orderPromo.promotion"
              ) | cxTranslate
            }}
          </ng-container>

          <ng-template #noAppliedPromotions>
            {{ "cart.orderPromo.youHave" | cxTranslate }}
            {{ eligiblePromoCount }}
            {{
              (eligiblePromoCount > 1
                ? "cart.orderPromo.promosAvailable"
                : "cart.orderPromo.promoAvailable"
              ) | cxTranslate
            }}
          </ng-template>
        </div>
      </div>
      <div
        class="promotional-slider-cta d-flex flex-column align-items-center justify-content-center"
      >
        <button
          mat-flat-button
          class="apply-remove-promo text-capitalize t-lable-l px-2 py-1"
        >
          {{
            (promotions?.length
              ? "cart.orderPromo.edit"
              : "cart.orderPromo.view"
            ) | cxTranslate
          }}
        </button>
        <div
          *ngIf="!isApplied"
          class="arrow cursor-pointer"
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div>
  <!-- For All Order Promotions -->

  <!-- For Applied Order Promotions -->
  <div
    *ngIf="promotions?.length as aPromoLength"
    class="applied-promotions mt-4 mb-2 p-2"
  >
    <div
      class="applied-promotions-heading t-applied_order_promo-heading text-capitalize text-center mt-1"
    >
      {{
        (aPromoLength > 1
          ? "cart.orderPromo.selectedPromos"
          : "cart.orderPromo.selectedPromo"
        ) | cxTranslate
      }}
    </div>
    <ng-container *ngFor="let appliedPromo of promotions">
      <div
        class="applied-order-promo d-flex justify-content-between align-items-center p-3 mt-3"
      >
        <div
          class="promotion-icon-container d-inline-flex justify-center align-self-center p-1 text-center"
        >
          <mat-icon class="promotion-icon" svgIcon="applied_promo"></mat-icon>
        </div>

        <div
          class="content-section t-order_promo-desc"
          [innerHTML]="appliedPromo?.description"
        ></div>

        <button
          mat-flat-button
          class="apply-remove-promo text-capitalize t-lable-l align-self-center px-2 py-1"
          (click)="handlePromotionSlider(appliedPromo)"
        >
          {{ "cart.orderPromo.edit" | cxTranslate }}
        </button>
      </div>
    </ng-container>
  </div>
  <!-- For Applied Order Promotions -->
</div>
