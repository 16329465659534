import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ship-to-address-modal',
  templateUrl: './ship-to-address-modal.component.html'
})
export class ShipToAddressModalComponent {

  constructor() { }
}
