import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'maskData'
})
export class maskDataPipe implements PipeTransform {
  transform(originalData: string, visibleDataAtStart:number, visibleDataAtEnd: number): string {
    //show number of digits at last and first based on input
    if(originalData && originalData.length > 0){
      let maskedData = originalData?.slice(visibleDataAtStart, -visibleDataAtEnd);
      let firstData = originalData?.slice(0, visibleDataAtStart);
      let visibleData = originalData?.slice(-visibleDataAtEnd);
      return firstData+maskedData?.replace(/./g, '*') + visibleData;
    }else{
      return '';
    }
    
  }
}