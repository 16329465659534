import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { formatPhonePipe } from '../../pipes/format-phone';
import { BoxoutEndpointService } from '../../services/common/boxout-endpoint.service';
import { CustomValidationService } from '../../services/common/custom-validation.service';
@Component({
  selector: 'app-sales-rep-call-me-drawer',
  templateUrl: './sales-rep-call-me-drawer.component.html'
})
export class SalesRepCallMeDrawerComponent implements OnInit {

  salesRepCallMeForm:FormGroup;
  product:any;
  salesRepCallSuccess:boolean=false;
  salesRepCallFailure:boolean=false;
  baseProductName:any;
  baseProductBrandData:any;
  code:any="";
  baseProductCode: string;
  user: User;
  userSub: Subscription;

  constructor(private formBuilder: FormBuilder,@Inject(MAT_DIALOG_DATA) public data: any,
  private customHttp: BoxoutEndpointService ,private customValidator: CustomValidationService,
  private dialogRef: MatDialogRef<SalesRepCallMeDrawerComponent>, private store: Store<fromApp.AppState>,
  private formatNumber: formatPhonePipe,
  ) { }

  ngOnInit(): void {
    this.userSub = this.store.select('auth').subscribe(user => { 
      this.user = user && user.user!;
    });
    this.product=this.data?.product;
    this.baseProductName=this.data?.baseProductName; 
    this.baseProductBrandData=this.data?.baseProductBrandData;
    this.baseProductCode = this.data?.baseProductCode || this.data?.product?.code;
    this.code =  this.data?.product?.variantsSize && this.data?.product?.variantsSize === 1 ? (this.data?.product?.variantOptions ? this.data?.product?.variantOptions[0]?.code : this.data?.product?.baseOptions?.length !==0 ?  this.data?.product?.baseOptions[0]?.selected?.code:this.data?.product?.code):this.data?.product?.code;
    this.createSalesRepCallMeForm();
  }

  createSalesRepCallMeForm(){
    this.salesRepCallMeForm = this.formBuilder.group({
      phoneNumber: [( this.user?.phone ? this.user?.phone : ""), [Validators.required, Validators.minLength(10),Validators.maxLength(10)]],
      email: [( (this.data?.user?.displayUid) ? (this.data?.user?.displayUid) : ""), [Validators.required,Validators.maxLength(50)]],
      comments: [(""),[Validators.maxLength(240)]],
    
    },
    {
      validator: [
        this.customValidator.validEmail(
          'email'
        )
      ],
    });
  }

  submitSalesRepCallMeForm(){

    if(this.salesRepCallMeForm?.valid){
      this.submitFinalForm();
    }else{
      this.salesRepCallMeForm.markAllAsTouched();
    }

  }


  submitFinalForm(){

    this.salesRepCallSuccess=false;
    this.salesRepCallFailure=false;

    const req = this.salesRepCallMeForm.getRawValue();
    req.productCode = this.code ? this.code : "";
    req.siebelAccountNumber = this.user?.orgUnit?.siebelRowId ? this.user?.orgUnit?.siebelRowId : "";
    req.businessName = this.user?.orgUnit?.name ? this.user?.orgUnit?.name : "";

    req.brandName = this.baseProductBrandData || this.data?.product?.brandData?.name;
    req.productName = this.baseProductName || this.data?.product?.name;
    req.url = this.product?.url || '';
    req.family = this.baseProductCode;
    req.title = this.baseProductName || this.data?.product?.name;
    req.phoneNumber =this.formatNumber.transform(this.data?.user?.phone);

      //commented code below needed, will update while integration

   const apiURL = `/orgProducts/triggerSalesRepEmail?fields=DEFAULT`;
    
     this.customHttp.post(apiURL, req).subscribe((resp: any) => {
        if(resp?.success === "Success"){
           this.salesRepCallSuccess=true;
           this.salesRepCallFailure=false;
          this.dialogRef.close();
       }else if(resp?.success === "Fail" || resp?.isSuccess === false){
           this.salesRepCallSuccess=false;
           this.salesRepCallFailure=true;
       }
    },
    (error:any)=>{
      this.salesRepCallSuccess=false;
      this.salesRepCallFailure=true;
     
    });
  }

}
