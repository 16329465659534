import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { SectionLoaderService } from 'src/app/feature/pages/loader/section-loader.service';
import { AddressService } from '../../services/common/address.service';
import { BoxoutEndpointService } from '../../services/common/boxout-endpoint.service';
import { CurrentThemeService } from '../../services/common/current-theme.service';
import { SmartyAddressValidationService } from '../../services/common/smarty-address-validation.service';
import { AddShipToClientPatientAddressDrawerComponent } from '../add-ship-to-client-patient-address-drawer/add-ship-to-client-patient-address-drawer.component';
import { ShipToClientOrPatientDrawerComponent } from '../ship-to-client-or-patient-drawer/ship-to-client-or-patient-drawer.component';

@Component({
  selector: 'app-other-ship-to-client-or-patient-addresses-drawer',
  templateUrl: './other-ship-to-client-or-patient-addresses-drawer.component.html'
})
export class OtherShipToClientOrPatientAddressesDrawerComponent implements OnInit{
  
  currentTheme: any;

  userId:any;
  siebelRowId:any;
  user: User;
  userSub: Subscription;
  viewAddresses:any=[];
  displayUid:any;
  shippingAddressSelectedSuccess:boolean=false;
  shippingAddressSelectedFailure:boolean=false;

  constructor(public dialog: MatDialog,private theme: CurrentThemeService,
    private smartyAddressValidationService: SmartyAddressValidationService,
    @Inject(MAT_DIALOG_DATA) public data: any,private addressService: AddressService,
    private store: Store<fromApp.AppState>, private cdr: ChangeDetectorRef,
    private sectionLoaderService:SectionLoaderService,
    public dialogRef: MatDialogRef<OtherShipToClientOrPatientAddressesDrawerComponent>,
    private customHttp: BoxoutEndpointService) { }

    ngOnInit(): void {
      /** Taking themes from themeservice **/
      this.currentTheme = this.theme?.getCurrentTheme();

      this.userSub = this.store.select('auth').subscribe(user => { 
        this.user = user && user.user!;
        this.userId = this.user?.uid;
        this.displayUid = this.user?.displayUid;
        this.siebelRowId = this.user?.orgUnit?.siebelRowId;
        if (this.userId && this.siebelRowId) {
          let otherAddress=this.data?.value?.itemData;
          this.getClientViewAddress(otherAddress);
        }
      });
    }

    //This method is used to show all ship to other addresses in Other Ship Address drawer
    getClientViewAddress(item: any) {
      this.sectionLoaderService.show();
      let apiURL = `users/current/orgUnits/${this.siebelRowId}/clients/${item.id}/addresses`;
      this.addressService
        .getClientViewAddresses(apiURL)
        .subscribe((data: any) => {
          this.sectionLoaderService.hide();
          if (data) {
            //Filtering out the shipto and bill to
            
            if(item?.billTo?.id){
              data.addresses = data.addresses.filter((resp:any)=> resp.id != item?.billTo?.id);
            }
            this.viewAddresses =  data.addresses;
            
            this.cdr.detectChanges();
          }
        },(error)=>{
          this.sectionLoaderService.hide()
        });
    }

    // This method opens up add ship to address drawer
  openAddShipToDialog(item:any){
     
     //setting form data null for avoiding multiple call 
     this.smartyAddressValidationService.saveFormData(null);
     let defaultShippgTo = (!(item?.itemData?.shipTo)) ? true : false ;

     this.dialog.open(AddShipToClientPatientAddressDrawerComponent,
       {
         panelClass: [this.currentTheme, 'ship-to-client-drawer-for-all'],
         position: {
          right: '0px',
          top: '0px'
        },
         data:  {
           value:{
            action:"addingNewAddressForClientOrPatientShipToAccount",
            openedDrawerFrom:"openedAddShipToFromOtherAddress",
            itemData:this.data?.value?.itemData,
            addAddressId:item?.addAddressId,
            defaultShippgTo:defaultShippgTo
           }
        }
       });
  }

             
//This method is used to navigate back to ship -to clients addresss drawer from other addresses drawer
  backToShipToClientPatientDialogFromOtherAddress(){

    if(this.data){
      this.data={};
    }
    

    this.dialogRef.afterClosed().subscribe((res)=>{
      if(res === 'backToShipToClientPatientDialogFromOtherAddress'){

        this.dialog.open(ShipToClientOrPatientDrawerComponent, {
          position: {
            right: '0px',
            top: '0px'
          },
          panelClass: ['wishlist-drawer', this.currentTheme],
          data: {
            value:{
              showSuccessForCart:false,
              showFailureForCart:false
            }
          }
        });
       
      }
    }, (error)=>{
    })

  }

  //This method is used to select address for cart ship when user selects address from other addresses block
  selectClientPatientShipToAddressFromOtherAddresses(item:any){
    this.sectionLoaderService.show();
    this.shippingAddressSelectedSuccess=false;
    this.shippingAddressSelectedFailure=false;

    let cartId = sessionStorage.getItem('cartId');
    let requestBody =  {
                      siebelRowId:this.data?.value?.itemData?.id,
                      addressSiebelRowID:item?.id,
                      isShipTo:true
                    };

    let apiUrl=`users/current/carts/${cartId}/addressDetails/?fields=DEFAULT`;

    this.customHttp.post(apiUrl, requestBody).subscribe(
      (resp: any) => {
        this.sectionLoaderService.hide();
        if(resp?.isSuccess === true){
          this.shippingAddressSelectedSuccess=true;
          this.shippingAddressSelectedFailure=false;
          this.dialogRef.close();
          this.smartyAddressValidationService.saveAddressSelectedForShipToCartData(true);
          
        }else if(resp?.isSuccess === false){
          this.shippingAddressSelectedSuccess=false;
          this.shippingAddressSelectedFailure=true;
        }
      },
      (error:any) => {
        this.shippingAddressSelectedSuccess=false;
        this.shippingAddressSelectedFailure=true;
        this.sectionLoaderService.hide();
      }
    );
  }

  showOtherClientAddress(address: any){
    return this.smartyAddressValidationService.returnFormattedAddress(address);
  }

}
