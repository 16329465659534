import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { account } from 'src/app/feature/pages/my-account/translations/en/account';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { InputValidationService } from 'src/app/shared/services/common/input-validation.service';

@Component({
  selector: 'app-edit-po',
  templateUrl: './edit-po.component.html'
})

export class EditPoComponent implements OnInit, OnDestroy {
  orderNumber: any;
  currentPO: string;
  revisedPO: string;
  updatePOSub: Subscription;
  poConstants = account.account.po;
  message: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customHttp: BoxoutEndpointService,
    private dialogRef: MatDialogRef<EditPoComponent>,
    private inputValidationService:InputValidationService,
  ) { }

  ngOnInit(): void {
    this.currentPO = this.data?.purchaseOrderNumber;
    this.orderNumber = this.data?.orderCode;
  }

  saveChanges() {
    if (this.revisedPO && this.revisedPO.length) {
      if (this.currentPO !== this.revisedPO) {
        const req = {
          orderCode: this.data?.orderCode,
          pageType: this.data?.pageType,
          purchaseOrderNumber: this.revisedPO,
          workFlowActionCode: this.data?.workFlowActionCode

        }
        this.updatePOSub = this.updatePO(req)
          .subscribe((res: any) => {
            if(res && res.success) {
              this.dialogRef.close({
                message: 'success',
                currentPO: this.currentPO,
                revisedPO: this.revisedPO
              });
            }  
          }, (error) => {
            this.dialogRef.close({
              message: 'fail'
            });
          });
      }
    }
  }

  updatePO(req: any) {
    const apiURL = `edit-po-number`;
    return this.customHttp.post(apiURL, req);
  }
  checkSpecChar(event:any){
    this.inputValidationService.checkSpecChar(event);
  }
  ngOnDestroy(): void {
    if(this.updatePOSub) {
      this.updatePOSub.unsubscribe();
    }
  }

}