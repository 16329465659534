<div class="asm-header d-flex">
  <div
    class="asm-header-powered-by-logo t-asm-text d-flex align-items-center px-2"
  >
    <cx-page-slot
      position="FooterPoweredByBoxoutLogo"
      class="d-none d-sm-block ml-1"
    ></cx-page-slot>
    <div class="asm-text uppercase pl-1 pl-sm-3 pr-1 pr-sm-0 mr-0 mr-sm-1">
      <span class="d-none d-sm-block">{{
        "salesrep.asm.asmFull" | cxTranslate
      }}</span>
      <span class="d-block d-sm-none">{{
        "salesrep.asm.asmText" | cxTranslate
      }}</span>
    </div>
  </div>
  <div
    class="asm-header-account-details d-flex align-items-center px-1 flex-grow-1"
  >
    <div class="details-box" *ngIf="user.orgUnit">
      <div class="number t-asm-account-number px-1">
        {{ user.orgUnit.siebelRowId }}
      </div>
      <div class="name t-asm-account-name px-1">
        {{ user.orgUnit.name }}
      </div>
    </div>
  </div>
  <div
    class="asm-header-logout-button t-asm-close d-flex align-items-center px-2 cursor-pointer"
    (click)="logoutUser()"
  >
    <mat-icon class="logout-icon ml-1" svgIcon="sr_logout"></mat-icon>
    <span class="mr-1">{{ "salesrep.asm.close" | cxTranslate }}</span>
  </div>
</div>
<div class="asm-border-space"></div>
