<div class="order-detail-promotion">
  <div class="mb-4"><h2 class="order-list-header p-3 mb-3 t-productTile-orderDetailHeader">
    {{ "account.order.promotions" | cxTranslate }}
  </h2></div>
  <div *ngIf="appliedOrderPromotions && appliedOrderPromotions.length">
<div
*ngFor="let item of appliedOrderPromotions" class="order-detail-line-promotion d-flex flex-grow-0 flex-wrap flex-sm-nowrap px-3 py-4 mb-4"
>
  <mat-icon
    class="promotion-icon align-self-start"
    svgIcon="promotion"
  ></mat-icon>
  <div class="content-section">
    <div class="t-line_promo-heading mb-2">
      <span class="promo-text text-capitalize">{{"cart.linePromo.promo" | cxTranslate}}</span>
      | <span class="t-product-details-availability-Instock-description"><span *ngIf="item?.promotion.isBOGO" >{{"cart.linePromo.bogo" | cxTranslate}} - </span><span [innerHTML]="item?.promotion?.title"></span></span>
    </div>
    <div class="t-line_promo-desc mt-1" [innerHTML]="item?.description"></div>
    <div class="t-line_promo-desc mt-1" [innerHTML]="item?.description2"></div>
  </div>
</div>
</div>


<!--applied product promotions-->
<div class="order-detail-promotion-section" *ngIf="appliedProductPromotions && appliedProductPromotions.length">
  <div
  *ngFor="let item of appliedProductPromotions" class="order-detail-line-promotion d-flex flex-grow-0 flex-wrap flex-sm-nowrap px-3 py-4 mb-4"
  >
    <mat-icon
      class="promotion-icon align-self-start"
      svgIcon="promotion"
    ></mat-icon>
    <div class="content-section">
    <div class="t-line_promo-heading mb-2">
      <span class="promo-text text-capitalize">{{"cart.linePromo.promo" | cxTranslate}}</span>
      | <span class="t-product-details-availability-Instock-description"><span *ngIf="item?.promotion.isBOGO" >{{"cart.linePromo.bogo" | cxTranslate}} - </span><span [innerHTML]="item?.promotion?.title"></span></span>
    </div>
    <div class="t-line_promo-desc mt-1" [innerHTML]="item?.description"></div>
    <div class="t-line_promo-desc mt-1" [innerHTML]="item?.description2"></div>
  </div>
  </div>
  </div>
</div>
