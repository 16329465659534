<div class="d-flex">
  <div class="mr-3 d-flex position-relative" tabindex="0" role="button">
    <span class="t-title-m account" (click)="openAccountDropdown()">{{'header.account' | cxTranslate}}</span>
    <mat-icon svgIcon="down_arrow" (click)="openAccountDropdown()" class="pl-1" aria-label="Account Down Arrow"></mat-icon>
    <ng-container *ngIf="openAccount">
      <div class="p-3 mt-5 quick-order-container">
        <a [routerLink]="['my-account/orderHistory']" (click)="openAccount=false"><p class=" t-title-med">{{ 'cart.viewLastOrder' | cxTranslate }} <span class="orderData">{{recentOrderDate ? recentOrderDate : ''}}</span></p></a>
        <a [routerLink]="" (click)="openSelectClientTypeDialog()">
          <p *ngIf="userType?.toUpperCase() === 'PARENT'" class="t-title-med">{{'cart.createShip' | cxTranslate }} </p>
        </a>
        <a [routerLink]="['/my-account/businessAccounts']" (click)="openAccount=false">
          <p class="t-title-med"> {{ "account.businessAccounts.businessAccounts" | cxTranslate }}</p>
        </a>
        <a [routerLink]="['/my-account/favoriteLists']" (click)="openAccount=false">
          <p class="t-title-med">{{"account.favorites.favoritesHeading"| cxTranslate}}</p>
        </a>
        <a [routerLink]="['my-account/approvalOrders']" (click)="openAccount=false">
          <p *ngIf=" user.customerRole === 'Admin' || user.customerRole === 'Approver' " class="t-title-med">{{ 'cart.approve' | cxTranslate}}  <span class="orderData">({{orderApprovalCount ? orderApprovalCount : '' }})</span> </p>
        </a>
      </div>
    </ng-container>
  </div>
  <div class="d-none d-xl-flex mx-3" tabindex="0" role="button" *ngIf="baseSiteNameVal!=='omsite'">
    <span class="t-title-m quick-order" (click)="openQuickOrder()">{{'header.quickOrder' | cxTranslate}}</span>
    <mat-icon svgIcon="down_arrow" class="pl-1" aria-label="Quick Order Down Arrow" (click)="openQuickOrder()"></mat-icon>
    <ng-container *ngIf="isOpen">
      <form [formGroup]="quickOrderForm" (submit)="onFormSubmit()">
        <div class="quick-order-container quick-order-modal mt-5" formArrayName="quickOrder">
          <reusable-alert-box *ngIf="formError" [alertType]="'warningAlertBox'" alertMsg='{{"quickOrder.formError"| cxTranslate}}'></reusable-alert-box>
          <div class="header-searchbar-modal-container" *ngFor="let row of getArrayControls(); let i = index"
            [formGroupName]="i">
            <div class="fixed mr-1">
              <div class="mb-3 ml-3 mr-2">
                <mat-form-field appearance="outline">
                  <mat-label>{{'header.item' | cxTranslate}}</mat-label>
                  <input matInput type="text" formControlName="item" />
                </mat-form-field>
                <mat-error class="mt-1" *ngIf="row.get('qty')?.errors && row.get('qty')?.touched && row.get('qty')?.errors?.pattern">{{ "quickOrder.notNumber" | cxTranslate}}</mat-error>
                <mat-error class="mt-1" *ngIf="row.get('item')?.hasError('itemError') && row.get('item')?.touched">{{ "quickOrder.itemError" | cxTranslate}}</mat-error>
                <mat-error class="mt-1" *ngIf="row.get('qty')?.hasError('qtyError') && row.get('qty')?.touched">{{ "quickOrder.qtyError" | cxTranslate}}</mat-error>
                <mat-error class="mt-1" *ngIf="row.get('item')?.hasError('isError') && row.get('item')?.touched">{{row.get('item')?.errors?.message}}</mat-error>
            </div>
            </div>
            <div class="flex-item">
              <div class="mb-0 mr-1">
                <mat-form-field appearance="outline">
                  <mat-label>{{'header.qty' | cxTranslate}}</mat-label>
                  <input matInput type="number" formControlName="qty" (keypress)="keyPressNumbers($event)"
                  (paste)="onPaste($event)"/>
                  <mat-error></mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <button mat-flat-button type="submit" color="primary" class="t-label-l ml-3 mt-3">
            {{'header.addCart' | cxTranslate }}
          </button>
          <div class="t-body-s ml-3 my-3">
            <span (click)="expanedQuickOrder()" class="t-body-s">{{'header.tryOut' | cxTranslate }}</span>
          </div>
        </div>
      </form>
    </ng-container>
  </div>

  <div class="d-none d-xl-block mx-3" *ngIf="baseSiteNameVal==='omsite'">
    <a [routerLink]="['/rapidOrder']" (click)="openAccount=false" tabindex="0" role="button">
      <span class="t-title-m quick-order"> {{"account.rapidOrder.rapidOrderText"| cxTranslate}} </span>
    </a>
     </div>
</div>