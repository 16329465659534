import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { WindowRef } from '@spartacus/core';
// Declare gTM dataLayer array.
//declare let dataLayer :any;
declare global {
  interface Window {
    dataLayer: any; // 👈️ turn off type checking
  }
}
@Injectable({
  providedIn: 'root'
})
export class GTMService {

  gaCartItemsArray : any;
  appliedPromotion: any = '';
  updateCartTotal: any;
  lineLevelPromotion: any;

  user: any;
  userObjTemp: any;
  
  constructor(private window: WindowRef,@Inject(PLATFORM_ID) private platformId: Object,) { }

  writeToDataLayer(data: any) {
    if(isPlatformBrowser(this.platformId)){
      if (typeof (window) !== undefined) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(data);
      }
    }
  }

  // GA 4 code for making the ecommerce event null
  removeDataLayer() {
    if(isPlatformBrowser(this.platformId)){
      if (typeof (window) !== undefined) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });  
      }
    }
  }

  setGTMOnLoadData(isLoggedIn: any, user: any, pageType: any, checkoutObj?: any) {
    let dataObj;
    let userObj;
    userObj = {
      //'userID': isLoggedIn ? user?.uid : '',
      'userID': isLoggedIn ? user?.pk : '',
      'accountID': isLoggedIn ? user?.orgUnit?.siebelRowId : '',
      'subAccountID': isLoggedIn ? user?.orgUnit?.siebelRowId : '',
      'b2bCustomer': isLoggedIn ? user?.customerRoles?.join() : '',
      'email': isLoggedIn ? user?.displayUid : '',
      'businessName': isLoggedIn ? user?.orgUnit?.name : '',
      'firstName': isLoggedIn ? user?.firstName : '',
      'lastName': isLoggedIn ? user?.lastName : '',
      //'customerGroup': isLoggedIn ? user?.customerRoles?.join() : '',
      'lastTransactionDate': isLoggedIn ? this.window.sessionStorage?.getItem('recentOrderDate') : '',
      'practiceType': isLoggedIn ? user?.orgUnit?.accountType : '',
      'phone': isLoggedIn ? user?.phone : '',
      'title': isLoggedIn ? user?.title : '',
      'isAssisted': isLoggedIn ? (this.window?.localStorage?.getItem('asmLoggedInUserEmail') ? true : false) : false,
      'userIDassisted': isLoggedIn ? (this.window?.localStorage?.getItem('asmLoggedInUserEmail') ? user?.pk : '') : '',
      'emailAssisted': isLoggedIn ? (this.window?.localStorage?.getItem('asmLoggedInUserEmail') ? user?.displayUid : '') : '',
      'emailRep': isLoggedIn ? (this.window?.localStorage?.getItem('asmLoggedInUserEmail') ? this.window.localStorage.getItem('asmLoggedInUserEmail') : '') : ''
    }

    userObj = checkoutObj ? { ...userObj, ...checkoutObj } : userObj;
    dataObj = {
      'event': 'dataLayer-initialized',
      'page': {
        'pageType': pageType
      },
      'user': userObj
    }
    let homeObj = {
      'pageLayout': '3x4 grid',
    }
    dataObj = pageType === 'HOME' ? { ...dataObj, ...homeObj } : dataObj
    this.writeToDataLayer(dataObj);
    this.user = userObj;
  }
  setCartGTMData(productData: any, qty: any) {
    let dataObj = {
      'event': 'add-to-cart',
      'familyID': productData?.product?.baseProduct,
      'variantID': productData?.product?.code,
      'quantity': + qty
    }
    this.writeToDataLayer(dataObj);
  }
  setRemoveCartGTMData(productData: any, qty: any) {
    let dataObj = {
      'event': 'remove-from-cart',
      'familyID': productData?.product?.baseProduct,
      'variantID': productData?.product?.code,
      'quantity': + qty
    }
    this.writeToDataLayer(dataObj);
  }
  /**
   * Set the product cart data in GTM
   */
  setGTMProductData(cartDetails:any,step:any, cartTotal?:any, checkoutData?:any){
    let productObj: any = [];
    this.gaCartItemsArray = [];
    this.lineLevelPromotion = [];
    this.appliedPromotion = '';
    let ga4Obj:any = {};
    let ga4Promotion = {};
    this.updateCartTotal = 0;
    cartDetails?.forEach((element: any, index: any) => {
      let obj = {
        id: element?.product?.baseProduct ? element?.product?.baseProduct : element?.product?.code,
        name: element?.product?.name || '',
        brand: element?.product?.brandData?.name || '',
        category: (element?.product?.categories ? element?.product?.categories[0]?.name : '') || '',
        variant: element?.product?.baseProduct ? element?.product?.code : 'parent product',
        price: element?.basePrice?.value,
        quantity: element?.quantity,
      }
      productObj.push(obj);

      ga4Obj = element?.product?.gaItems;
      if(step === 1) {
        ga4Obj = { ...ga4Obj, discount: null, index: index , price: element?.basePrice?.value , quantity: element?.quantity,  item_list_id: 'cart', item_list_name: 'Cart'};
        this.updateCartTotal = this.updateCartTotal + (ga4Obj?.price * parseInt(ga4Obj?.quantity));
        if(element?.bogoPromotionDetails) {
          ga4Promotion = {
            'promotion_id': element?.bogoPromotionDetails?.code,
            'promotion_name': element?.bogoPromotionDetails?.title,
            'creative_name': '',
            'creative_slot': '',
            'location_id': ''
          }
          this.lineLevelPromotion.push(ga4Promotion);
          ga4Promotion = {};
        }
      } 
      else if(step === 2) {
        ga4Obj = { ...ga4Obj, discount: null, index: index , price: element?.basePrice?.value , quantity: element?.quantity,  item_list_id: 'checkout', item_list_name: 'Checkout'};
      }
      else if(step === 3) {
        ga4Obj = { ...ga4Obj, discount: null, index: index , price: element?.basePrice?.value , quantity: element?.quantity,  item_list_id: 'mini_cart', item_list_name: 'Mini Cart'};
        this.updateCartTotal = this.updateCartTotal + (ga4Obj?.price * parseInt(ga4Obj?.quantity));
      } 
      else if(step === 4) {
        ga4Obj = { ...ga4Obj, discount: null, index: index , price: element?.basePrice?.value , quantity: element?.quantity,  item_list_id: 'reorder', item_list_name: 'Reorder'};
        this.updateCartTotal = this.updateCartTotal + (ga4Obj?.price * parseInt(ga4Obj?.quantity));
        if(element?.bogoPromotionDetails) {
          ga4Promotion = {
            'promotion_id': element?.bogoPromotionDetails?.code,
            'promotion_name': element?.bogoPromotionDetails?.title,
            'creative_name': '',
            'creative_slot': '',
            'location_id': ''
          }
          this.lineLevelPromotion.push(ga4Promotion);
          ga4Promotion = {};
        }
      }
      this.gaCartItemsArray.push(ga4Obj);
      ga4Obj = {};
    })
    if(step !== 3 && step !== 4) {
      let gtmObj = {
        'event': 'checkout-step',
        'ecommerce': {
          'checkout': {
            'actionField': {
              'step': step,
              'option': ''
            },
            'products': productObj
          }
        }
      }
      this.writeToDataLayer(gtmObj);
    }

    if(step === 2) {
      checkoutData?.appliedOrderPromotions?.forEach((element: any, index: any) => {
        this.appliedPromotion = this.appliedPromotion + element?.promotion?.code + ',';
      });
      this.setBeginCheckoutData(this.gaCartItemsArray, cartTotal, this.appliedPromotion);

      this.setAddShippingInfo(this.gaCartItemsArray , cartTotal, this.appliedPromotion, checkoutData?.deliveryMode?.name);

      this.setAddPaymentInfo(this.gaCartItemsArray , cartTotal, this.appliedPromotion, checkoutData?.paymentType?.displayName)
    }


    if(step === 1 || step === 3 || step === 4) {
      this.setviewCartData(this.gaCartItemsArray , cartTotal);
    }
  }
  setGTMUserData(user:any){
    let userObj = {
      //'userID': user?.uid,
      'userID': user?.pk,
      'accountID': user?.orgUnit?.siebelRowId,
      'subAccountID': user?.orgUnit?.siebelRowId,
      'b2bCustomer': user?.customerRoles?.join(),
      'email': user?.displayUid,
      'businessName': user?.orgUnit?.name ,
      'firstName':  user?.firstName ,
      'lastName':  user?.lastName,
      //'customerGroup':  user?.customerRoles?.join(),
      'lastTransactionDate':  this.window.sessionStorage?.getItem('recentOrderDate'),
      'phone' :  user?.phone,
      'title' : user?.title,
      'practiceType': user?.orgUnit?.accountType,
    }
    let dataObj = {
      'event': 'User',
      'user': userObj
    }
    this.userObjTemp = userObj;
    this.writeToDataLayer(dataObj);
  }

  // GA4 select_item event data layer push
  setSelectItemData(data:any) {
    data.discount = null;
    let dataObj:any;
    dataObj = {
      'event': 'select_item',
      'ecommerce': {
        'item_list_id': data?.item_list_id,
        'item_list_name': data?.item_list_name,
        'items': [data]
      }
    }

    this.removeDataLayer();
    this.writeToDataLayer(dataObj);
  }

  // GA4 view_item event data layer push
  setViewItemData(data:any) {
    data.discount = null;
    let dataObj:any;
    dataObj = {
      'event': 'view_item',
      'ecommerce': {
        'currency': 'USD',
        'value': data?.price,
        'items': [data]
      }
    }

    this.removeDataLayer();
    this.writeToDataLayer(dataObj);
  }

  // GA4 add_to_cart event data layer push
  addProductToCart(data:any) {
    let dataObj = {
      'event': 'add_to_cart',
      'ecommerce': {
      'currency': 'USD',
      'value': data?.price * parseInt(data?.quantity), 
      'items': [data]
      }
    }
    this.removeDataLayer();
    this.writeToDataLayer(dataObj);
  } 

  //GA4 add_to_cart array data Layer push
  addProductsToCart(data:any , cart:any) {
    let dataObj = {
      'event': 'add_to_cart',
      'ecommerce': {
      'currency': 'USD',
      'value': cart, 
      'items': data
      }
    }
    this.removeDataLayer();
    this.writeToDataLayer(dataObj);
  }

  //GA4 remove_from_cart event data layer push
  removeProductFromCart(data:any) {
    let dataObj = {
      'event': 'remove_from_cart',
      'ecommerce': {
      'currency': 'USD',
      'value': data?.price * data?.quantity,
      'items': [data]
      }
    }
    this.removeDataLayer();
    this.writeToDataLayer(dataObj);
  }

  //GA4 remove_from_cart event data layer push
  removeAllProductFromCart() {
    let dataObj = {
      'event': 'remove_from_cart',
      'ecommerce': {
      'currency': 'USD',
      'value': this.updateCartTotal,
      'items': this.gaCartItemsArray
      }
    }
    this.removeDataLayer();
    this.writeToDataLayer(dataObj);
  }

  //GA4 view_cart event data layer push
  setviewCartData(cartItems:any, subTotal: any) {
    let dataObj = {
      'event': 'view_cart',
      'ecommerce': {
        'currency': 'USD',
        'value': subTotal,
        'items': cartItems
      }
    }
    this.removeDataLayer();
    this.writeToDataLayer(dataObj);
  }

  //GA4 begin_checkout event data layer push 
  setBeginCheckoutData(checkoutItems: any, cartTotal:any, appliedPromotion: any) {
    let dataObj = {
      'event': 'begin_checkout',
      'ecommerce': {
        'currency': 'USD',
        'value': cartTotal,
        'coupon': appliedPromotion,
        'items': checkoutItems
      }
    }
    this.removeDataLayer();
    this.writeToDataLayer(dataObj);
  }

  shippingOptionChangedData(shippingTier:any, cartTotal:any) {
    this.setAddShippingInfo(this.gaCartItemsArray, cartTotal, this.appliedPromotion, shippingTier);
  }

  //GA4 add_shipping_info event data layer push 
  setAddShippingInfo(checkoutItems: any, cartTotal:any , coupon:any, shippingTier:any) {
    let dataObj = {
      'event': 'add_shipping_info',
      'ecommerce': {
        'currency': 'USD',
        'value': cartTotal,
        'coupon': coupon,
        'shipping_tier': shippingTier,
        'items': checkoutItems
      }
    }
    this.removeDataLayer();
    this.writeToDataLayer(dataObj);
  }

  paymentOptionChangedData(paymentOption:any, cartTotal:any) {
    this.setAddPaymentInfo(this.gaCartItemsArray, cartTotal, this.appliedPromotion, paymentOption);
  }

  //GA4 add_payment_info event data layer push 
  setAddPaymentInfo(checkoutItems: any, cartTotal:any , coupon:any, paymentType:any) {
    let dataObj = {
      'event': "add_payment_info",
      'ecommerce': {
        'currency': "USD",
        'value': cartTotal, 
        'coupon': coupon,
        'payment_type': paymentType,
        'items': checkoutItems
      }
    }
    this.removeDataLayer();
    this.writeToDataLayer(dataObj);
  }

  //GA4 purchase event data layer push 
  setPurchaseData(orderedEnteries: any , orderItems:any, appliedPromotion:any) {
    let dataObj = {
      'event': 'purchase',
      'ecommerce': {
        'transaction_id': orderedEnteries?.code,
        'tax': orderedEnteries?.totalTax?.value,
        'shipping': orderedEnteries?.deliveryCost?.value,
        'currency': 'USD',
        'value': orderedEnteries?.totalPrice?.value,
        'coupon': appliedPromotion,
        'items': orderItems
      }
    }
    this.removeDataLayer();
    this.writeToDataLayer(dataObj);
  }

  //GA4 login event data layer push 
  setLoginData() {
    if(this.user?.userID) {
      let dataObj = {
        'event' : 'login',
        'user' : this.user 
      }

      this.writeToDataLayer(dataObj);
    }
  }

  //GA4 sign_up event data layer push 
  setSignUpData() {
    let dataObj = {
      'event' : 'sign_up',
      'user': this.userObjTemp
    }
    this.writeToDataLayer(dataObj);
  }

  //GA4 search event data layer push 
  setSearchData(searchTerm: any) {
    let dataObj = {
      'event' : 'search',
      'search_term' : searchTerm
    }
    this.writeToDataLayer(dataObj);
  }

  //GA4 search event data layer push 
  setEmailSignUpData(email:any) {
    let dataObj = {
      'event' : 'email_signup',
      'email' : email
    }
    this.writeToDataLayer(dataObj);
  }

  //GA4 add to wishlist event data layer push
  setAddToWishlist(wishlistItems : any) {
    let dataObj = {
      'event': 'add_to_wishlist',
      'ecommerce': {
        'currency': 'USD',
        'value': wishlistItems[0].price,
        'items': wishlistItems
      }
    }
    this.removeDataLayer();
    this.writeToDataLayer(dataObj);
  }

  removeProductsFromWishlist(wishlistItems : any) {
    let dataObj = {
      'event': 'remove_from_wishlist',
      'ecommerce': {
        'currency': 'USD',
        'value': wishlistItems[0].price * wishlistItems[0].quantity,
        'items': wishlistItems
      }
    }
    this.removeDataLayer();
    this.writeToDataLayer(dataObj);
  }

  removeAllProductsFromWishlist(wishlistItems : any , totalPrice:any) {
    let dataObj = {
      'event': 'remove_from_wishlist',
      'ecommerce': {
        'currency': 'USD',
        'value': totalPrice,
        'items': wishlistItems
      }
    }
    this.removeDataLayer();
    this.writeToDataLayer(dataObj);
  }

  sendViewPromotionsData(promotions:any) {
    let dataObj = {
      'event': 'view_promotion',
      'ecommerce': {
        items: promotions
      }
    }
    this.removeDataLayer();
    this.writeToDataLayer(dataObj);
  }

  sendSelectPromotionsData(promotions:any) {
    let dataObj = {
      'event': 'select_promotion',
      'ecommerce': {
        items: promotions
      }
    }
    this.removeDataLayer();
    this.writeToDataLayer(dataObj);
  }

  callViewPromotion() {
    this.sendViewPromotionsData(this.lineLevelPromotion);
  }
}
