import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { account } from 'src/app/feature/pages/my-account/translations/en/account';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { CurrentThemeService } from 'src/app/shared/services/common/current-theme.service';
import { global } from 'src/app/shared/translations/en/global';
import { AddEditPaymentTypeComponent } from './../add-edit-payment-type/add-edit-payment-type.component';

@Component({
  selector: 'app-edit-payment-method',
  templateUrl: './edit-payment-method.component.html'
})

export class EditPaymentMethodComponent implements OnInit, OnDestroy {

  themesForScroll: any = this.document.getElementsByClassName('cdk-global-overlay-wrapper');
  orderNumber: any;
  selectedPayment: String;
  selectedCard: any
  existingCards: any[] = [];
  currentTheme: any;
  defaultBillingAddress: any;
  userId: string;
  updatePaymentSub: Subscription;
  defaultPayment: any;
  showError: boolean = false;
  errorMsg = account.account.editPayment.errorMsg;
  account = account.account.order;
  isBillToInvoice: boolean = false;
  showInvoice: boolean = true;
  editPaymentTranslations = global.account.editPayment;
  newlyAddedCard: boolean = false;
  newlyAddedCardId: string;
  paymentDetailsSubs$: Subscription;

  constructor(
    public dialog: MatDialog,
    private theme: CurrentThemeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customHttp: BoxoutEndpointService,
    private dialogRef: MatDialogRef<EditPaymentMethodComponent>,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    //scroll theme for create business dialog
    this.themesForScroll[0].className += " custom-modal";

    this.userId = this.data?.userId;
    this.orderNumber = this.data?.orderCode;
    this.isBillToInvoice = this.data?.isBillToInvoice;
    this.currentTheme = this.theme?.getCurrentTheme();
    if(this.data?.paymentMethod) {
      if(this.data?.paymentMethod?.toLowerCase() === this.account.bill) {
        this.selectedPayment = this.editPaymentTranslations.invoice.toLowerCase();
      } else if(this.data?.paymentMethod?.toLowerCase() === this.account.creditCard) {
        this.selectedPayment = this.editPaymentTranslations.existingCard;
      }
    }

    if(this.selectedPayment === this.editPaymentTranslations.invoice.toLowerCase()) {
      this.showInvoice = true;
    } else {
      this.showInvoice = this.isBillToInvoice;
    }
    this.getPaymentTypesEntities();
  }

  setCard(card: any) {
    this.selectedCard = card;
  }

  saveChanges(newCardId?: string) {
    if(this.selectedPayment === this.editPaymentTranslations.new) {   
      this.addNewCard();
    } else {
      let req: any = {};
      if(this.selectedPayment === this.editPaymentTranslations.invoice.toLowerCase()) {
        req = {
          isBillInvoice: true,
          orderCode: this.data?.orderCode,
          pageType: this.data?.pageType
        }
      } else if(this.selectedPayment === this.editPaymentTranslations.existingCard) {
        req = {
          isBillInvoice: false,
          orderCode: this.data?.orderCode,
          pageType: this.data?.pageType,
          paymentId: newCardId || this.selectedCard?.id
        }
      }
      this.updatePaymentSub = this.savePaymentMethod(req)
          .subscribe((res: any) => {
            this.dialogRef.close({
              message: 'success',
            });
          }, (error) => {
            this.dialogRef.close({
              message: 'fail'
            });
          });
    }
  }

  savePaymentMethod(req: any) {
    const apiURL = `paymentdetails`;
    return this.customHttp.post(apiURL, req);
  }

   //method makes page load call to get payment types associated with that user
   getPaymentTypesEntities(){
    if(this.userId) {
      let apiUrl = `/users/current/paymentdetails`;
      this.paymentDetailsSubs$ = this.customHttp.get(apiUrl).subscribe(
      (data:any) => {
            this.existingCards = data?.payments?.filter((item:any)=>{return item.saved;});
            let index = this.existingCards.findIndex((val, i, arr) => { return val.id === this.data?.paymentDetails?.id});
            if(this.data?.paymentDetails && this.data?.paymentDetails?.cardNumber &&
              this.data?.paymentDetails?.cardType?.name && this.data?.paymentDetails?.expiryMonth &&
              this.data?.paymentDetails?.expiryYear && this.data?.paymentDetails?.id &&
               index === -1 ) {
                this.existingCards.push(this.data?.paymentDetails);
              }
              if(this.data?.paymentMethod && this.data?.paymentMethod.toLowerCase() === this.account.creditCard) {
                this.selectedCard = this.existingCards[this.existingCards.length - 1];
              } else if( this.data?.paymentMethod.toLowerCase() === this.account.bill) {
                this.selectedCard = this.existingCards.find((val, i, arr) => { return val.defaultPayment === true});
              }
              if(this.newlyAddedCard) {
                this.selectedCard = this.existingCards.find((val, i, arr) => { return val.id === this.newlyAddedCardId });
              }
            this.defaultBillingAddress = data?.defaultBillingAddress;
      });
    }
  };

  addNewCard() {
    const dialogRef = this.dialog.open( AddEditPaymentTypeComponent,
      {
        panelClass: [this.currentTheme, 'add-payment-card'],
        autoFocus: false,
        disableClose: true,
        data: {
          userId: this.userId,
          addCardFlag: true,
          billingAddress: this.defaultBillingAddress,
          cardData:this.existingCards
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result.dataUpdated) {
          if(result?.showSuccess) {
            this.selectedPayment = this.editPaymentTranslations.existingCard;
            this.newlyAddedCard = true;
            this.newlyAddedCardId = result?.data?.id;
            this.getPaymentTypesEntities();
            this.showError = false;
          }
          if(result?.showFailure) {
            this.showError = true;
          }
        }
      });
  }

  ngOnDestroy(): void {
    if(this.updatePaymentSub) {
      this.updatePaymentSub.unsubscribe();
    }
    if(this.paymentDetailsSubs$) {
      this.paymentDetailsSubs$.unsubscribe();
    }
  }

}