import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/core/models/user.model';
import { global } from 'src/app/shared/translations/en/global';

@Component({
  selector: 'app-order-history-card',
  templateUrl: './order-history-card.component.html'
})
export class OrderHistoryCardComponent implements OnInit{

 @Input() order: any;
 @Input() user: User;
 @Input() workflowActionCode?: any;
 orderStatus: string;
 orderType: any;
 estimated = global.account.ordersHistory.estimated;
 
  constructor(private router:Router) { }

  ngOnInit(): void {
    this.orderStatus = this.order.statusDisplay.toLowerCase().replace('.', ' ');
    this.orderType=this.order?.orderType;
  }

  //navigate user to order detail/approval page when user clicks on see details CTA
  navigateToDetails(orderType:any){
    let userRole = this.user?.customerRole;

    if(userRole === 'Purchaser') {
      this.router.navigate(['/my-account/order', this.order?.code]);
    } else if(userRole === 'Approver') {
      if(this.order?.isCreator) {
        this.router.navigate(['/my-account/order', this.order?.code]);
      } else {
        if(this.workflowActionCode && orderType!=='RETURN') {
          this.router.navigate(['/my-account/orderApprovalDetails', this.order?.code, this.workflowActionCode]);
        } else {
          this.router.navigate(['/my-account/order', this.order?.code]);
        }
      }
    } else if(userRole === 'Admin') {
      if(this.order?.statusDisplay?.toUpperCase().replace(' ', '_') === 'PENDING_APPROVAL'  && orderType!=='RETURN') {
        this.router.navigate(['/my-account/orderApprovalDetails', this.order?.code, this.workflowActionCode]);
      } else {
        this.router.navigate(['/my-account/order', this.order?.code]);
      }
    }
  }
  
}
