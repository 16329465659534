import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoginFormComponentService } from '@spartacus/user/account/components';
import { AddressService } from '../../shared/services/common/address.service';
import { BaseService } from '../../shared/services/common/base.service';
import { BoxoutEndpointService } from '../../shared/services/common/boxout-endpoint.service';
import { CustomValidationService } from '../../shared/services/common/custom-validation.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    BaseService,
    BoxoutEndpointService,
    LoginFormComponentService,
    CustomValidationService,
    AddressService
  ]
})
export class BoxoutServicesModule { }
