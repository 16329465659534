<div class="other-ship-to-client-patient-container">
    <div class="other-ship-to-address-header-border d-flex flex-row justify-content-between align-items-center">
        <div class="d-flex flex-row align-items-center justify-content-center" 
        [mat-dialog-close]="'backToShipToClientPatientDialogFromOtherAddress'">
        <div class="t-headerLink-normal" >
           {{'account.otherShipToClientPatientAddresses.back' | cxTranslate }}
        </div>
        </div>
        
        <div class="mt-1">
          <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon" >close</mat-icon>
        </div>
    </div>
  
    <!--  to show the error/success  on address addtion on ship to checkout address: start-->
    <div *ngIf="data?.value?.showFailureForCart" class="m-2">
      <reusable-alert-box
        [alertType]="'warningAlertBox'"
        alertMsg="{{ 'account.businessAccounts.infoUpdateError' | cxTranslate }}"
      ></reusable-alert-box>
    </div>
  
    <div *ngIf="data?.value?.showSuccessForCart" class="m-2">
      <reusable-alert-box
        [alertType]="'successAlertBox'"
        alertMsg="{{
          'account.businessAccounts.infoUpdateSuccess' | cxTranslate
        }}"
      ></reusable-alert-box>
    </div>

    <div *ngIf="errorMsg" class="mx-3">
      <reusable-alert-box [alertType]="'warningAlertBox'" alertMsg="{{ 'account.createFavoriteList.somethingWentWrongMsg' | cxTranslate }}"></reusable-alert-box>
    </div>
  
    <!--  to show the error/success on address addtion on ship to checkout address: end-->
  
     <!--  to show the error/success when address is selected for shipping: start-->
   <div *ngIf="shippingAddressSelectedFailure" class="m-2">
    <reusable-alert-box
      [alertType]="'warningAlertBox'"
      alertMsg="{{ 'account.businessAccounts.infoUpdateError' | cxTranslate }}"
    ></reusable-alert-box>
  </div>
  
  <div *ngIf="shippingAddressSelectedSuccess" class="m-2">
    <reusable-alert-box
      [alertType]="'successAlertBox'"
      alertMsg="{{
        'account.businessAccounts.infoUpdateSuccess' | cxTranslate
      }}"
    ></reusable-alert-box>
  </div>
  
  <!--  to show the error/success when address is selected for shipping: End-->
  
    <div class="p-2">
  
      <div class="d-flex flex-column justify-content-between align-items-center">
  
          <div class="d-flex flex-row mb-3">
            <div class="add-address-icon mr-1 cursor-pointer">+</div>
           <div (click)="openAddShipToDialog(data?.value)">
             <a class="cursor-pointer t-acctno-text-regular m-0">
               <u> {{'account.otherShipToClientPatientAddresses.addNewShipToAdd' | cxTranslate }}</u>
             </a>
            </div>
          </div>
           
    
            <div class="t-headerLink-normal mb-3">  {{ data?.value?.itemData?.name ? (data.value.itemData.name | titlecase) : "" }} Addresses</div>
      </div>
  
      
  
      <div  *ngFor="let item of viewAddresses" class="other-ship-to-address-block p-2 d-flex flex-column mb-3">
  
        <div>
          <p *ngIf="item?.isPrimaryShippingAddress" class="outlined-badge-success t-address-type w-25 mb-2">
            {{ "account.businessAccounts.primaryShipTo" | cxTranslate }}
          </p>
        </div>
  
        <div class="t-body-s mb-1">  {{ item?.formattedAddress ? item.formattedAddress.replaceAll(",", "") : ""}}</div>
  
          <div class="other-ship-to-address-block-margin mb-2"></div>
  
          <div class="d-flex flex-row justify-content-between other-ship-to-client-patient-button">
          
            <button *ngIf="freeFreight?.status !== 'Applied'"
            mat-flat-button
            class="t-acctno-text-regular other-ship-to-client-patient-action select"
            color="primary" (click)="selectAddresses(item)"
          >
            <u>{{'account.otherShipToClientPatientAddresses.select' | cxTranslate }}</u>
          </button>
          <button *ngIf="freeFreight?.status === 'Applied'"
            mat-flat-button
            class="t-acctno-text-regular other-ship-to-client-patient-action remove"
            color="primary" (click)="removeFreeFreight(item)"
          >
            <u>{{'account.otherShipToClientPatientAddresses.select' | cxTranslate }}</u>
          </button>
          <button mat-flat-button class="d-none t-acctno-text-regular other-ship-to-client-patient-action" color="white">
            <u>{{'account.otherShipToClientPatientAddresses.edit' | cxTranslate }}</u>
          </button>
          </div>
  
      </div>
    </div>
  
  </div>