<div id="print" class="hidden-print">
  <div class="print">
    <div class="print-first-page d-flex flex-column">
      <div class="print-first-page-content d-flex flex-column justify-content-center align-items-center text-center pl-1 pr-4 m-4 h-100">
        <div class="print-first-page-imp p-16 t-bxdPortal-printLabelBold">
          {{"bxdPortal.print.imp" | cxTranslate}}
        </div>
        <div class="print-first-page-notice t-bxdPortal-printLabelSmall">
          {{"bxdPortal.print.labelMsg" | cxTranslate}}
          <span>{{wroSubmitData?.warehouse?.name}}</span>
        </div>
        <div class="print-first-page-failure t-bxdPortal-printLabelBold mt-4 pt-4">
          {{"bxdPortal.print.failureMsg" | cxTranslate}}
        </div>
      </div>
      <div class="print-first-page-footer t-bxdPortal-printLabelFooter py-4 pl-1 pr-4 text-center">
        {{"bxdPortal.print.confidentialMsg" | cxTranslate}} {{programName}} on {{wroSubmitData?.creationTime | date:'M/d/yyyy h:mm a' || '-'}} -
        {{"bxdPortal.print.bxdPage1" | cxTranslate}}</div>
    </div>
    <div class="print-second-page d-flex flex-column">
      <div class="print-second-page-content d-flex flex-column py-4 pl-1 pr-4 h-100">
        <div class="print-second-page-box p-2">
          <div class="print-second-page-top-section d-flex justify-content-between">
            <div class="print-second-page-top-image">
              <img src="../../../../../assets/logo/bxdportal.svg" class="login-dialog-image" alt="BoxOut-logo">
            </div>
            <div class="print-second-page-top-barcode">
              <ngx-barcode6 [bc-value]="codeData" [bc-display-value]="false" [bc-width]="2" [bc-height]="40"></ngx-barcode6>
              <div class="print-second-page-top-text d-flex align-items-center justify-content-center">
                <span class="mr-1"> {{"bxdPortal.print.warehouseReceving" | cxTranslate}} </span>
                <span>{{codeData}}</span>
              </div>
            </div>
          </div>
          <div class="print-second-page-middle d-flex align-items-center p-2 t-bxdPortal-printLabelBold"></div>
          <div class="print-second-page-bottom d-flex justify-content-between flex-column">
            <div class="print-second-page-bottom-head t-bxdPortal-printLabelBold pl-2 pt-2">{{"bxdPortal.print.shipTo" | cxTranslate}} </div>
            <div class="print-second-page-bottom-middle d-flex justify-content-between pl-2 pt-1">
              <div class="t-bxdPortal-printLabelSmall ">
                <div>{{programName}}</div>
                <div>{{wroSubmitData?.warehouse?.address?.line1}}</div>
                <div>{{wroSubmitData?.warehouse?.address?.line2}}</div>
                <div>{{wroSubmitData?.warehouse?.address?.town}}, {{wroSubmitData?.warehouse?.address?.region?.name}},
                  {{wroSubmitData?.warehouse?.address?.postalCode}}</div>
                <div class="t-bxdPortal-printLabelFooter mt-1 pt-4">
                  <div>{{"bxdPortal.print.created" | cxTranslate}} {{wroSubmitData?.creationTime | date: 'MM/dd/y'}}</div>
                  <div>{{"bxdPortal.print.estimatedArrival" | cxTranslate}} {{wroSubmitData?.eta | date: 'MM/dd/y'}}</div>
                  <div>{{"bxdPortal.print.createdBy" | cxTranslate}}{{userEmail}}</div>
                </div>
              </div>
              <div class="qrcodeImage">
                <qrcode [qrdata]="codeData || 0" [allowEmptyString]="true"
                  [ariaLabel]="'QR Code image with the following content...'" [cssClass]="'end'"
                  [elementType]="'canvas'" [errorCorrectionLevel]="'M'"
                  [imageHeight]="75" [imageWidth]="75" [scale]="1" [width]="200">
                </qrcode>
                <div class="text-center qrcodeImage-code">{{codeData}}</div>
              </div>
            </div>
            <div class="print-second-page-bottom-warning t-bxdPortal-printLabelBold m-auto pt-3">{{"bxdPortal.print.labelUncovered" | cxTranslate}}</div>
          </div>
        </div>
      </div>
      <div class="print-first-page-footer t-bxdPortal-printLabelFooter p-4 m-4 text-center">
        {{"bxdPortal.print.confidentialMsg" | cxTranslate}} {{programName}} on {{wroSubmitData?.creationTime | date:'M/d/yyyy h:mm a' || '-'}} - {{"bxdPortal.print.bxdPage2" | cxTranslate}}
      </div>
    </div>
  </div>
</div>