<!--This Component displays Footer Contact Us contents and Social Media contents -->
<div class="contactUsSocialMediaContainer py-3 px-3">
  <div
    class="container d-flex justify-content-md-between justify-content-start"
  >
    <!--footer contact us contents-->
    <div class="d-flex flex-column align-items-start container">
      <div class="contact-us-texts">
        <cx-page-slot position="FooterContactUs"></cx-page-slot>
      </div>
    </div>
    <!--footer social media contents-->
    <div class="d-flex align-items-center">
      <div class="d-none d-lg-block">
        <cx-page-slot position="FooterSocialMedia" class="d-flex flex-row align-items-center"></cx-page-slot>
      </div>
    </div>

  </div>
</div>
