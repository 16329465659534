<div class="AccountPageTemplate container-lg container-xl px-lg-0 px-md-2 px-0">
    <div class="left-panel my-account-main-container">
        <div class="left-panel-container" [class.is-mobile]="mobileQuery.matches">
            <mat-sidenav-container autosize="true" class="sidenav-container mt-0" fixedInViewport="true"
                [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
                <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'"
                    [opened]="!mobileQuery.matches || toggleStatus" position="start"
                    [style.position]="snav.mode !== 'over' && snav.opened ? 'relative' : 'absolute'"
                    (closed)="toggleSideNav(false)" [class.is-desktop]="mobileQuery.matches && !toggleStatus">
                    <mat-card appearance="outlined" class="user-card pb-4">
                        <mat-card-header>
                            <mat-card-title class="mb-3 text-ribbon">
                                <span class="t-title-l d-none">  
                                    {{"account.accountLeftPanel.userAccountInfo.hi"|
                                    cxTranslate}}{{accountprofileinfo?.firstName}}
                                </span>
                                <img src="../../../assets/cancel.png" alt="" aria-label="Close Menu" class="cancel-img float-right"
                                [class.is-desktop]="!mobileQuery.matches" (click)="toggleSideNav(false)">
                                </mat-card-title>
                            <mat-card-title class="mb-3 d-flex position-relative">
                                <div class="user-container t-label-s w-100">
                                        {{"account.accountLeftPanel.userAccountInfo.loyaltyYear"|
                                        cxTranslate}}{{accountprofileinfo?.orgUnit?.loyaltyYear}}
                                </div>
                                <div class="triangleBorder"></div>
                            </mat-card-title>
                            <mat-card-subtitle class="mb-2 t-label-s">
                                {{"account.accountLeftPanel.userAccountInfo.account"| cxTranslate}}</mat-card-subtitle>
                            <mat-card-subtitle class="t-title-m">{{accountprofileinfo?.orgUnit?.siebelRowId}}
                            </mat-card-subtitle>
                            <mat-card-subtitle class="mb-2 t-label-s d-none">
                                {{"account.accountLeftPanel.userAccountInfo.business"| cxTranslate}}</mat-card-subtitle>
                            <mat-card-subtitle class="t-title-m d-none">{{accountprofileinfo?.orgUnit?.name}}
                            </mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-actions class="ml-0 py-0 px-3" *ngIf="showSwitchBtn || isAdmin">
                            <!-- a href To BE CHANGED ONCE ROUTING IS IMPLEMENTED -->
                            <a (click)="manageBusinessAccount()" *ngIf="isAdmin" mat-raised-button
                                class="account-action-btn manage-account-btn mr-2">
                                <img mat-card-image class="button-img" src="../../../assets/my_account_image/manage.svg"
                                    alt="">
                                <span class="t-label-l">{{"account.accountLeftPanel.buttonText.manageButton"|
                                    cxTranslate}}</span></a>
                            <button *ngIf="showSwitchBtn" mat-raised-button class="account-action-btn" (click)="openManageModal()">
                                <img mat-card-image class="button-img" src="../../../assets/my_account_image/switch.svg"
                                    alt="">
                                <span class="t-label-l">{{"account.accountLeftPanel.buttonText.switchButton"|
                                    cxTranslate}}</span></button>
                        </mat-card-actions>
                    </mat-card>
                    <ng-container *ngFor="let panelList of leftPanel">
                        <div *ngIf="panelList?.subList?.length > 0" class="order-heading t-menucategory-normal ml-2 mt-2 pt-1 mb-2 pb-1">{{panelList.title}}
                        </div>
                        <mat-nav-list class="orders-list pt-0">
                            <ng-container *ngFor="let list of panelList.subList">
                                <a mat-list-item class="t-subcategory-inverse-normal" [routerLink]="list?.redirectUrl" (click)="redirectPage(list?.redirectUrl,list)">
                                    <img mat-card-image class="button-img order-img mb-0" src="{{list.img_url}}" alt="">
                                    <div class="w-100">
                                        <span class="list-title t-subcategory-normal"
                                            [class.list-title-open]="list?.redirectUrl?.includes(currentUrl?.split('/').splice(-1)) && currentUrl !== 'boxout/en/USD/my-account'">{{list.title}}</span>
                                    </div>
                                    <div class="open-img">
                                        <img mat-card-image class="open-img button-img order-img mb-0 pull-right"
                                            src="../../../assets/my_account_image/open.svg" alt="">
                                    </div>
                                </a>
                            </ng-container>
                        </mat-nav-list>
                    </ng-container>
                </mat-sidenav>
                <mat-sidenav-content>
                    <div class="content ml-lg-4">
                        <!-- <button mat-icon-button (click)="snav.toggle()" class="float-right" [class.is-desktop]="!mobileQuery.matches">
                            <mat-icon>menu</mat-icon>
                        </button> -->
                        
                    <app-loader></app-loader>
                        <router-outlet></router-outlet>                   
                    </div>
                </mat-sidenav-content>
            </mat-sidenav-container>
        </div>
    </div>
</div>