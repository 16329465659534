import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { SmartyAddressValidationService } from 'src/app/shared/services/common/smarty-address-validation.service';
import { HeaderService } from 'src/app/shared/services/header.service';

interface Country {
  isocode: string;
  name: string;
}

interface State {
  isocode: string;
  name: string;
}
@Component({
  selector: 'app-reusable-address-form',
  templateUrl: './reusable-address-form.component.html'
})
export class ReusableAddressFormComponent implements OnInit {

  //796:ship-to client
  @Input() action:any;

  //ship to business
  @Input() componentNameForCart:any;

  addressForm: FormGroup;
  addressData:any;
  builtAddressData:any=[];
  result:any=[];
  selectedAddress:any;
  addressSecondaryData:any;
  private subjectAddressOneKeyUp= new Subject<any>();

  countryList: Country[] = [];
  stateList: State[]=[];

  siteData:any;
  smartyKeyValForAddressValidation:any;

  

  constructor(private formBuilder: FormBuilder,private http:HttpClient, private cdr: ChangeDetectorRef,
    private customHttp: BoxoutEndpointService, private headerService:HeaderService,
    private smartyAddressValidationService: SmartyAddressValidationService) { }

  ngOnInit(): void {

     let dataUrl=`/country?fields=DEFAULT&type=SHIPPING`;

     //To get diffrent countries and regions from backend and saving them to show on UI
      this.customHttp.get(dataUrl).subscribe((data:any)=>{
      if(data) {

        data.country?.countries?.forEach((d:any)=>{
          this.countryList?.push(d);
        });

        data.region?.regions?.forEach((d:any)=>{
          this.stateList?.push(d);
        });
        this.smartyAddressValidationService.sendStateList(this.stateList);
        this.cdr.detectChanges();
      }
    });

     //getting the key for smarty address API validation
     this.headerService.localPropertiesConfigData.subscribe((resp:any)=>{
      if(resp){
        this.siteData = resp;
        const smartyKeyVal: any = 'spartacus.config.smarty.key';
        this.smartyKeyValForAddressValidation = this.siteData[smartyKeyVal];
      }
      
    });
    
    //implementing debouncing on address search functionality to optimize performance
    this.subjectAddressOneKeyUp.pipe(debounceTime(500),distinctUntilChanged()).subscribe((data)=>{
      if(data.length>0){
        this.suggestedAddress(data);
      }
      
    })
  }

  //This method is used to form reusable address Form
  public createAddressForm(addressData:any) {
    this.addressForm = this.formBuilder.group({
      addressOne: [(addressData?.addressOne ? addressData?.addressOne: ""),[Validators.required,Validators.maxLength(75)]],
      optionalAddressTwo: [(addressData?.optionalAddressTwo ? addressData?.optionalAddressTwo: ""),[Validators.maxLength(75)]],
      cityName: [(addressData?.cityName ? addressData?.cityName: ""), [Validators.required,Validators.maxLength(30)]],
      stateName: [(addressData?.stateName ? addressData?.stateName: ""), [Validators.required]],
      zipCode: [(addressData?.zipCode ? addressData?.zipCode: ""), [Validators.required, Validators.minLength(5),Validators.maxLength(9), Validators.pattern("^[0-9]*$")]],
      countryName:[(addressData?.countryName ? addressData?.countryName: ""), [Validators.required]],
      id: addressData?.id ? addressData?.id: ""
    }
    );
    return this.addressForm;
  }

  //This method is used to search address when user types on address 1 field
  searchAddress($event:any){
    const addressOne = $event.target.value;
    this.subjectAddressOneKeyUp.next(addressOne);
    
  }

  //This method is used to suggest different US addresses when user types on address 1 field
  suggestedAddress(addressOne:any){

    
    let apiUrl=`https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${encodeURIComponent(this.smartyKeyValForAddressValidation)}&search=${encodeURIComponent(addressOne)}&license=us-autocomplete-pro-cloud`;

    this.http.get(apiUrl).subscribe((data)=>{
      this.result=[];
      this.builtAddressData=[];
      this.addressData=[];
      this.addressData=data;
      if(this.addressData && this.addressData.suggestions){

        this.addressData.suggestions.forEach((suggestion: any) => {

          if(suggestion.secondary && suggestion.entries>1){
            
            let urlSecondary=`https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${encodeURIComponent(this.smartyKeyValForAddressValidation)}&search=${encodeURIComponent(addressOne)}&selected=${encodeURIComponent(suggestion.street_line+" "+suggestion.secondary+" ("+suggestion.entries+") "+suggestion.city+" "+suggestion.state+" "+suggestion.zipcode)}&license=us-autocomplete-pro-cloud`;

               this.http.get(urlSecondary).subscribe((resp)=>{

                this.addressSecondaryData=[];
                this.addressSecondaryData=resp;

                if(this.addressSecondaryData && this.addressSecondaryData.suggestions){
                 this.addressSecondaryData.suggestions.forEach((suggestions: any) => {

                 this.builtAddressData?.push(this.buildAddress(suggestions));
                 this.result.push(suggestions);

                 this.cdr.detectChanges();
                  });
                }
                else{
                  this.addressSecondaryData=[];
                }
               }, (error)=>{
               })

          }else{
            this.builtAddressData?.push(this.buildAddress(suggestion));
            this.result.push(suggestion);
            
            this.cdr.detectChanges();
          }
         
          
        });
        
        
      }else{
        this.addressData=[];
        this.builtAddressData=[];
        this.result=[];
      }
      
    },(error)=>{
    })
  }

  //This method is used to build address in string format to show on UI 
  buildAddress(suggestion:any){
    let whiteSpace = "";
    if (suggestion.secondary) {
      if (suggestion.entries > 1) {
        suggestion.secondary += " (" + suggestion.entries + " entries)";
      }
      whiteSpace = " ";
    }
    return suggestion.street_line + whiteSpace + suggestion.secondary + " " + suggestion.city + ", " + suggestion.state + " " + suggestion.zipcode;
  }

  //This method is used to populate different address fields with values When user select any of the suggested address
  addressSelected(event:any){
    this.selectedAddress= event.option.value;
    let indexOfSelectedAddress= this.builtAddressData.indexOf(this.selectedAddress);
    this.addressForm.patchValue({
      addressOne: this.result[indexOfSelectedAddress].street_line,
      optionalAddressTwo: this.result[indexOfSelectedAddress].secondary,
      cityName: this.result[indexOfSelectedAddress].city,
      zipCode: this.result[indexOfSelectedAddress].zipcode,
      stateName: "USA-"+this.result[indexOfSelectedAddress].state,
      countryName: this.countryList[0].isocode
    });
  }

 

}
