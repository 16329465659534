<div class="edit-payment">
  <div class="d-flex flex-row justify-content-between mb-4">
    <div class="t-title-l mb-0">{{ "account.editPayment.header" | cxTranslate }} {{ orderNumber }}</div>
    <div class="mb-0">
      <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon" color="white">close</mat-icon>
    </div>
  </div>

  <div *ngIf="showError" class="pt-3 rounded">
    <reusable-alert-box [alertType]="'warningAlertBox'" [alertMsg]="errorMsg"></reusable-alert-box>
  </div>

  <div>
    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
      [(ngModel)]="selectedPayment">
      <div *ngIf="showInvoice" class="copy-radio-option-style">
        <mat-radio-button value="invoice" class="font-weight-medium t-body-l" color="primary" (click)="selectedPayment = 'invoice'">
          {{ "account.editPayment.invoice" | cxTranslate }}
        </mat-radio-button>
      </div>
      <div *ngIf="existingCards.length" class="copy-radio-option-style">
        <mat-radio-button value="existingCard" class="font-weight-medium t-body-l" color="primary" (click)="selectedPayment = 'existingCard'">
          <mat-form-field #materialFormField appearance="outline" class="paymentdd">
            <mat-label appRemoveAriaOwns>{{'account.editPayment.creditCard' | cxTranslate}}</mat-label>
            <mat-select panelClass="select-warehouse" [(ngModel)]="selectedCard">
              <mat-option *ngFor="let card of existingCards" [value]="card" (click)="setCard(card)">
                {{card?.cardType?.name + ' ' + card?.cardNumber?.substr(card?.cardNumber?.length - 4) + ' ' +
                card?.expiryMonth + '/' + card?.expiryYear}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-radio-button>
      </div>
      <div class="copy-radio-option-style">
        <mat-radio-button value="new" class="font-weight-medium t-body-l" color="primary" (click)="selectedPayment = 'new'">
          {{ "account.editPayment.newCard" | cxTranslate }}
        </mat-radio-button>
      </div>
    </mat-radio-group>
  </div>
  <div class="d-flex flex-row-reverse mt-3 mb-3">
    <div>
      <button mat-flat-button color="primary" (click)="saveChanges()" class="">
        {{ (selectedPayment === 'new' ? "account.editPayment.setUpNewCard" : "account.editPayment.saveChanges") |
        cxTranslate}}
      </button>
    </div>
    <div class="mr-5 mt-2 mb-5 pt-1">
      <a [mat-dialog-close]="true" class="cursor-pointer">{{"account.editPayment.cancel"| cxTranslate}}</a>
    </div>
  </div>
</div>