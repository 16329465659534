import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { BoxoutEndpointService } from '../../../../shared/services/common/boxout-endpoint.service';
import { QuickOrderService } from '../../../../shared/services/common/quick-order.service';
import { HeaderService } from '../../../../shared/services/header.service';

@Component({
  selector: 'app-header-minicart',
  templateUrl: './header-minicart.component.html'
})
export class HeaderMinicartComponent implements OnInit, OnDestroy {

  count: any;
  user: User;
  userSub: Subscription;
  userId: string;
  cartDataSub: Subscription;
  cartSub:Subscription;
  constructor(
    private headerService: HeaderService,
    private quickOrderService: QuickOrderService,
    private store: Store<fromApp.AppState>,
    private customHttp: BoxoutEndpointService,
    private windowRef: WindowRef,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.userSub = this.store.select('auth').subscribe(user => {
      this.user = user && user.user!;
      this.userId = this.user?.uid!;
      if(this.userId){
          this.showCartCount();
      }else{
        this.count = null;
      }
    });
    this.cartDataSub = this.headerService.addCardCount.subscribe((resp: any) => {
      this.count = resp;
    });

  }
  /**
   * This function will show the cart count
   */
  showCartCount(){
    const apiUrl = `users/${this.userId}/carts?currentPage=0&fields=DEFAULT&pageSize=20&savedCartsOnly=false`;
      if (this.userId) {
        this.cartSub = this.customHttp.get(apiUrl).subscribe(
          (data: any) => {
            let cartLength = data.carts.length;
            if (cartLength == 0) {
              //For funture use.
              //this.cartService.createCart(this.userId)
            } else {
              if(this.userId){
                this.windowRef.sessionStorage?.setItem('cartId', data.carts[0].code);
                this.count = data.carts[0].totalItems;
                this.headerService.setAddCardCount(data.carts[0].totalItems);
              }else{
                this.count = null;
                this.headerService.setAddCardCount(null);
              }
            }
          }, (error:any) => {
          })
      }else{
        this.count= null
      }
  }
  /**
   * It'll open mini cart.
   */
  OpenMiniCart(){
    if(this.userId) {
      this.quickOrderService.openMiniCart(this.userId);
    } else {
      this.windowRef?.localStorage?.setItem('goToUrl','/cart');
      this.router.navigate(['cart']);
    }
  }

  /**
   * Destroy all the subscription
   */
  ngOnDestroy() {
    if(this.userSub) {
      this.userSub.unsubscribe();
    }
    this.cartDataSub?.unsubscribe();
  }

}
