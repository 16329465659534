<div class="mb-3 ml-2 ml-sm-0 ml-md-0 ml-lg-0 ml-xl-0 pt-3 breadcrumb-container d-block d-lg-flex">
  <ng-container *ngFor="let crumb of breadcrumbs; last as isLast; first as isFirst;let i = index">
    <a class="mr-1 t-title-s base-site cursor-pointer d-inline"  *ngIf="isFirst" routerLink="/">{{ crumb.label }}</a>
    <a class="mx-1 t-title-s cursor-pointer d-inline" [routerLink]="buildRoutingArray(crumb,isLast)" *ngIf="!isFirst && !isLast">{{ crumb?.label }}</a>
    <span class="ml-1 t-title-s order-opacity" *ngIf="!dynamicParam && isLast">{{ crumb?.label }}</span>
    <span class="ml-1 t-title-s cursor-pointer" *ngIf="dynamicParam && isLast && !page" [routerLink]="buildRoutingArray(crumb,false)">{{ crumb?.label }}</span>
    <span class="icon" *ngIf="!isLast"> |</span>
    <span class="icon" *ngIf="isLast && dynamicParam && !page"> |</span>
  </ng-container>
  <span class="ml-1 t-title-s order-opacity" *ngIf="dynamicParam">{{ dynamicParam }}</span>
</div>