import { Component, Input } from "@angular/core";

interface OrderCredit {
    description: string;
    credit: {
        description: string;
    }
}

@Component({
    selector: 'app-order-credit',
    templateUrl: './order-credit.component.html',
})

export class OrderCreditComponent {
    @Input() cartDetails: any;
    @Input() rewardsApplied?: any;
    @Input() rewardsDiscount?: any;
}