<div class="ship-to-business-account-container">
  <div class="ship-to-business-header pb-3 d-flex flex-row justify-content-between align-items-center">
    <div class="t-headerLink-normal pt-2 d-flex align-items-center" (click)="closeDialog()">
      <mat-icon svgIcon="back" class="mr-2"></mat-icon> {{ "account.cartShipToBusiness.addNewShipTo" | cxTranslate }}
    </div>
    <div class="mt-1">
      <button mat-flat-button color="primary" (click)="closeDialog()" class="sitelinks-close">
        <mat-icon> {{ "header.close" | cxTranslate }}</mat-icon>
      </button>
    </div>
  </div>
<div class="pt-2">
  <!--add new form-->
</div>
</div>