<div class="mini-cart-container">
    <div class="mini-cart d-flex flex-row justify-content-between align-items-center">
        <div class="t-headerLink-normal"> {{'header.cart' | cxTranslate }}</div>
        <div class="mt-1">
            <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon" color="white">close</mat-icon>
        </div>
    </div>

    <!-- Warning for medical Product in Cart-->
    <div *ngIf="isOrderLevelMDIEnabled && medicalDropshipError">
        <reusable-alert-box [alertType]="'warningAlertBox'" alertMsg="{{medicalDropshipError}}">
        </reusable-alert-box>
    </div>

    <div *ngIf="errors" class="mx-2">
        <reusable-alert-box *ngFor="let error of errors" [alertType]="'warningAlertBox'" alertMsg='{{error}}' ></reusable-alert-box>
    </div>
    
    <div class="mini-cart-list" *ngIf = "!isCartEmpty" tabindex="0">
        <ng-container *ngFor="let item of cart">
            <div class="mini-cart-items p-3 d-flex">
                <div>
                    <div class="img">
                        <img *ngIf="item?.product?.productImage?.url" src="{{imageUrl}}{{item?.product?.productImage?.url}}" alt="{{item?.product?.name}} Image">
                        <img *ngIf="item?.product?.productImage?.url === undefined || item?.product?.productImage?.url === '' || !(item?.product?.productImage?.url)"
                      src="../../../../../assets/MissingImage.svg" alt="MissingImage">
                    </div>
                    <span class="m-1 pl-3 cursor-pointer" (click)="removeItemFromCart(item)"><u> {{'header.remove' | cxTranslate }}</u></span>
                </div>
                <div class="item mb-3 pt-1">
                    <p class="t-address-type mb-1 txt-color">{{item?.product?.brandData?.name}} |
                        {{item?.product?.code}}</p>
                    <p class="t-label-m mb-1" [innerHTML]="item?.product?.name"></p>
                    <div *ngIf="item?.product?.baseOptions[0]?.selected?.variantOptionQualifiers.length === 1">
                        <ng-container
                            *ngIf="item?.product?.selectableAtrribute?.includes(item?.product?.baseOptions[0]?.selected?.variantOptionQualifiers[0]?.qualifier)">
                            <div class="t-productTile-productGroup mb-1">
                                {{item?.product?.baseOptions[0]?.selected?.variantOptionQualifiers[0]?.value}}
                            </div>
                        </ng-container>
                    </div>
            
                      <div class="d-flex flex-wrap" *ngIf="item?.product?.baseOptions[0]?.selected?.variantOptionQualifiers.length > 1">
                        <div class="t-productTile-productGroup mb-1" *ngFor="let attr of item?.product?.baseOptions[0]?.selected?.variantOptionQualifiers; last as isLast">
                            <ng-container *ngIf="item?.product?.selectableAtrribute?.includes(attr?.qualifier)">
                                {{attr?.value}} <span *ngIf="!isLast">, &nbsp; </span>
                            </ng-container>
                       </div>
                      </div>

                    <div class="mini-cart-qty d-flex flex-row justify-content-between align-items-center">
                        <div class="t-headerLink-normal pt-2 cart-qty">{{item?.quantity}}</div>
                        <div class="mt-1 t-productTile-brand">
                            {{item?.totalPrice?.value | currency}}
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="checkout-cart" *ngIf = "!isCartEmpty">
        <div class="checkout-mini-cart m-3 d-flex flex-row justify-content-between align-items-center">
            <div class="t-subhead-normal">{{'header.shipping' | cxTranslate }}</div>
            <div class="t-productTile-brand">{{'header.checkout' | cxTranslate }}</div>
        </div>
        <div class="checkout-mini-cart m-3 d-flex flex-row justify-content-between align-items-center">
            <div class="t-subhead-normal">{{'header.subtotal' | cxTranslate }}</div>
            <div class="t-subhead-normal">{{totalPrice}}</div>
        </div>
        <div class="d-flex flex-row-reverse mt-3 mb-1 mr-3">
            <a [routerLink]="['/cart']" mat-flat-button color="primary" class="t-label-l ml-3 mt-1 view-cart-btn">
                {{'header.viewCart' | cxTranslate }}
            </a>
        </div>
        <div class="checkout-desc m-2">
            <p>{{'header.checkoutDesc' | cxTranslate }}</p>
        </div>
    </div>

    <div class="mini-cart-content t-title-s d-flex justify-content-center align-items-center mini-cart-items" *ngIf = "isCartEmpty">
        {{'header.emptyCart' | cxTranslate }}
    </div>

</div>