<div class="header-sticky justify-content-between secondToolbar" [ngClass]="{ sticky: isSticky }" #myIdentifier>
  <div class="header-hamburger d-lg-none">
      <app-custom-hamburger></app-custom-hamburger>
  </div>
  <div class="header-main-logo">
      <div id="logo-mobile" class="d-md-block d-lg-block d-none pl-2">
          <!-- <cx-generic-link class="t-title-m" url="/" target="">
              <cx-media class="main-logo-dark" [container]="mobileLogo?.media" >      
              </cx-media> 
          </cx-generic-link> -->
          <cx-page-slot position="MobileSiteLogoSlot"></cx-page-slot>
      </div>     
  </div>
  <div class="header-main-search d-lg-block">
    <div class="search-backdrop-sticky"></div>
      <app-header-searchbar [isSticky]="isSticky"></app-header-searchbar>
  </div>
  <div class="d-flex align-items-center">
      <div class="header-main-right-section d-flex">
          <a  href={{phoneNumber}} aria-label="phoneNumber"><mat-icon svgIcon="phone" role="button"></mat-icon></a>
          <mat-icon svgIcon="account" role="button" (click)="openSiteLinksDialog()" ></mat-icon>
          <div class="header-main-minicart">
            <a class="text-decoration-none" [routerLink]="['/cart']" [attr.aria-label]="'header.viewCartLabel' | cxTranslate">
                <mat-icon svgIcon="minicart" class="d-block" role="button" tabindex="-1" matBadge="{{count}}" matBadgeColor="warn">
                </mat-icon>
            </a>      
          </div>
      </div>
  </div>
</div>
