<div class="main-search">
  <div class="search-header p-0 container mx-lg-auto mx-sm-0">
    <div class="d-flex flex-column flex-xl-row justify-content-md-between px-md-3 px-lg-0 pt-md-3 pt-lg-0">
      <div class="d-flex flex-column searchResultHeader search-header-width">
        <p class="d-flex d-sm-none d-md-none d-lg-none m-0 px-3 pt-3 t-searchResults-mobile-header">
        {{ "account.searchResults.header" | cxTranslate }}
        <span class="searchItem t-searchResults-sub-text"
          >&nbsp;{{ searchParam }}</span
        >
      </p>
        <p class="d-none d-lg-none d-md-flex d-sm-flex m-0 px-3 pt-3 t-searchResults-header">
          {{ "account.searchResults.header" | cxTranslate }}
          <span class="searchItem t-searchResults-header"
            >&nbsp;{{ searchParam }}</span
          >
        </p>
        <p class="d-none d-lg-flex mt-1 mb-0 pt-4 pb-3 pl-3 t-searchResults-header"
        >
          {{ "account.searchResults.header" | cxTranslate }}
          <span class="searchItem t-searchResults-header">
            &nbsp; {{ searchParam }}</span
          >
        </p>

        <span  *ngIf="isBrandpage && brandData" class="t-title-m pl-3 pb-md-3 pt-3 pt-lg-0 d-flex align-items-center">
          <span class="search-header-brand" [ngClass]="brandData?.brandUrl ? 'show-ellipses' : '' " [innerHTML]="brandData?.content"></span>
          <a *ngIf="brandData?.brandUrl" [routerLink]="[brandData?.brandUrl]" class="search-header-brand-link pl-2">{{
            "account.searchResults.learnMore" | cxTranslate
          }}</a>
        </span>

        <p *ngIf="showBackTotop" class="d-flex m-0 pb-3">
          <mat-icon
            class="d-none d-md-block d-lg-block mr-2 ml-3 backToTop cursor-pointer"
            svgIcon="backToTop" (click)="onClickOfBackToTop()"
          ></mat-icon>
          <span class="t-title-m cursor-pointer" (click)="onClickOfBackToTop()">{{
            "account.searchResults.backTotop" | cxTranslate
          }}</span>
        </p>
       
        <span
          *ngIf="showTotalItems"
          class="t-title-m mb-2 d-none d-xl-flex pl-3 pb-3"
          >{{ totalCount }}
          {{ "account.searchResults.totalItems" | cxTranslate }}</span
        >
      </div>

      <div
        class="d-flex justify-content-between m-0 align-items-center sortKeys mr-3 mr-lg-0 mr-md-0 pl-3 pb-0 pb-lg-3 pb-xl-0"
      >
        <span class="t-title-m d-none d-sm-block d-md-block d-lg-block d-xl-none"
          >{{ totalCount }} {{ "account.searchResults.totalItems" | cxTranslate }}</span
        >
        <span class="d-flex d-xl-none align-items-center" (click)="openSideBar()">
          <mat-icon
            class="d-xl-none gridViewIcon m-2"
            svgIcon="filter-facet"
          ></mat-icon>
          {{ "account.searchResults.allFilters" | cxTranslate }}</span
        >
        <!-- Needed for future release -->
        <!-- <div class="d-none d-md-flex d-sm-flex flex-column p-3 p-lg-0 p-xl-3">
          <span class="t-label-m textCompare pb-1">{{
            "account.searchResults.compare" | cxTranslate
          }}</span>
          <div class="d-none d-md-flex d-sm-flex compareDiv">
            <span class="compare mr-2"></span>
            <span class="compare mr-2"></span>
            <span class="compare"></span>
          </div>
        </div> -->
        <div class="d-none d-lg-flex flex-column p-3 p-lg-0 p-xl-3">
          <span class="t-label-m textCompare pb-1">{{
            "account.searchResults.viewBy" | cxTranslate
          }}</span>
          <div class="d-none d-md-flex mr-3 viewIcon">
            <mat-icon
              class="gridViewIcon mr-3 cursor-pointer"
              svgIcon="grid-view"
              [ngClass]="viewBy === 'grid' ? 'selected' : 'not-selected'"
              (click)="searchOnViewByGrid($event)"
            ></mat-icon>
            <mat-icon
              class="listViewIcon m-0  cursor-pointer"
              svgIcon="list-view"
              (click)="searchOnViewByList($event)"
              [ngClass]="viewBy !== 'grid' ? 'selected' : 'not-selected'"
            ></mat-icon>
          </div>
        </div>

        <div class="sortByFields mt-4">
          <mat-form-field appearance="outline" class="mt-3 mb-md-0 mb-3">
            <mat-label appRemoveAriaOwns>{{
              "account.searchResults.sortBy" | cxTranslate
            }}</mat-label>
            <mat-select
            [(ngModel)]="selectedSortBy"
              (selectionChange)="onSortBySelected($event)"
            >
              <mat-option *ngFor="let item of sortByFields" [value]="item.code">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
