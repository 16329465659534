import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-reusable-slide-toggle',
  templateUrl: './reusable-slide-toggle.component.html'
})
export class ReusableSlideToggleComponent implements OnInit{
  @Input() public checked:boolean;
  @Input() public disabled:boolean;
  @Output() toggleCheck: EventEmitter<any> = new EventEmitter();
  toggleStatus="Off"
  constructor() { }

  ngOnInit(): void {
    if(this.checked){
      this.toggleStatus ="On"
    }else{
      this.toggleStatus ="Off"
    }
  }

onToggleChange(){
  this.checked=!this.checked;
  this.checked?this.toggleStatus="On":this.toggleStatus="Off";
  this.toggleCheck.emit(this.toggleStatus);
}


}
