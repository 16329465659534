<div *ngIf="orderHistoryEntriesData && orderHistoryEntriesData.length>0" class="revision-history mt-4">
  <p class="m-0 p-2 t-productTile-orderDetailHeader">{{"orderApproval.revision"| cxTranslate}}</p>
<table class="table table-bordered">
  <thead>
    <tr>
      <th class="t-cart-product-name">{{"orderApproval.date"| cxTranslate}}</th>
      <th class="t-cart-product-name">{{"orderApproval.user"| cxTranslate}}</th>
      <th class="t-cart-product-name">{{"orderApproval.comments"| cxTranslate}}</th>
      <th class="t-cart-product-name">{{"orderApproval.status"| cxTranslate}}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let history of orderHistoryEntriesData">
      <td class="t-label-l"> {{ history?.timeStamp? (history.timeStamp| date: "MM/dd/yyyy"): ' ' }}</td>
      <td class="t-label-l"> {{ history?.ownerData?.displayUid? history.ownerData.displayUid : history?.ownerData?.uid}}</td>
      <td class="t-label-l">{{history?.description? history.description: ''}}</td>
      <td class="t-label-l">{{history.orderStatus ? (history.orderStatus| titlecase): ''}}</td>
    </tr>
  </tbody>
</table>
</div>
