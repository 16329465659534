import { SelectionModel } from '@angular/cdk/collections';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { PortalBxdDownloadService } from 'src/app/feature/components/portal-boxout/portal-bxd-download.service';
import { FullScreenToggleService } from 'src/app/feature/components/portal-boxout/portal-bxd-fullscreen.service';
import { PortalBxdServiceService } from 'src/app/feature/components/portal-boxout/portal-bxd-service.service';
import * as fromApp from '../../../../core/store/app.reducer';
@Component({
  selector: 'app-bxd-reusable-filter',
  templateUrl: './bxd-reusable-filter.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BxdReusableFilterComponent implements OnInit, OnChanges{
  @Input() filterMenu: any;
  @Input() filterList: any;
  @Input() sortList: any;
  @Input() canExportSelected: boolean = false;
  @Input() navigatedFrom: any;
  @Input() calender: any;
  @Input() downloadPayload: any[];
  @Input() showScheduledExport: boolean;
  @Input() exportPage: string;
  @Input() hideFullScreenIcon: boolean = false;
  @Input() hideActionFilter: boolean = false;
  @Input() disableActionButtonNoData: boolean;
  @Input() navlistOrders:any;
  @Input() status: any;
  @Input() statusList: any;
  @Input() statusDropdown: any; 
  @Input() IsMfgChecked: any; 

  userEmail: string;
  chipVisible: boolean = false;
  selectedCipText: any;
  sortCode: any;
  searchText: any;
  productViewList: { route: string; name: string; index: number }[] = [
    { route: '/inventory', name: 'Inventory Status', index: 3 },
    {
      route: '/inventoryTransaction',
      name: 'Inventory Transaction History',
      index: 3,
    },
    { route: '/inventoryLotTracked', name: 'Lot-tracked Inventory', index: 3 },
    { route: '/quarantine', name: 'Quarantined Inventory', index: 3 },
  ];
  isFullScreen: boolean = false;

  selectedView: string = '';
  selectedSorting: string = '';
  selectedDateRange: string = '';
  searchPlaceholder: string = 'bxdPortal.productNav.searchPlaceholder';
  startDatePayload: any;
  endDatePayload: any;
  dateRangePayload: any;
  isLotChecked: boolean = false;
  userSub: any;
  selectedTab:any;
  @Input() navlist: any;
  @Input() selectedViewIndex: number;
  inventoryViewList: string[] = ['Location', 'Status'];
  calenderDropdown: { name: string; value: string }[] = [
    { name: 'Yesterday', value: 'YESTERDAY' },
    { name: 'Last 7 days', value: 'LAST_7_DAYS' },
    { name: 'This month', value: 'THIS_MONTH' },
    { name: 'Last month', value: 'LAST_MONTH' },
    { name: 'Last 3 months', value: 'LAST_3_MONTHS' },
    { name: 'This year', value: 'THIS_YEAR' },
    { name: 'Last year', value: 'LAST_YEAR' },
  ];
  currentTab: any;
  @Input() isSort: boolean = false;
  @Input() navlistLocation: any;
  // @Input() navlistStatus: string[] = ['Availiable', 'Incoming', 'Pending Putaway', 'Committed', 'Quarantine', 'Backordered']
  @Input() navlistStatus: { name: string; code: string, alias: string }[] = [
    { name: 'Available', code: 'available', alias: 'Available'},
    { name: 'Incoming', code: 'incoming', alias: 'Incoming'},
    { name: 'Pending Putaway', code: 'pendingPutaway', alias: 'Pending Putaway'},
    { name: 'Committed', code: 'committed', alias: 'Committed'},
    { name: 'Quarantine', code: 'quarantine', alias: 'Quarantine'},
    { name: 'Backordered', code: 'backOrdered', alias: 'Backordered'}
  ];

  lotEnableInventoryNavList: { name: string; code: string, alias: string }[] = [
    { name: 'Available', code: 'available', alias: 'Available'},
    { name: 'Pending Putaway', code: 'pendingPutaway', alias: 'Pending Putaway'},
    { name: 'Committed', code: 'committed', alias: 'Committed'},
    { name: 'Quarantine', code: 'quarantine', alias: 'Quarantine'}
  ];

  selectedStatusDropdown:any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private fullScreenToggleService: FullScreenToggleService,
    private cdr: ChangeDetectorRef,
    private bxdService: PortalBxdServiceService,
    private downloadService: PortalBxdDownloadService,
    private store: Store<fromApp.AppState>,
    private router: Router,
    private route: ActivatedRoute) {}

    today = new Date();
    month = this.today.getMonth();
    year = this.today.getFullYear();

    readonly calenderForm = new FormGroup({
      start: new FormControl(null as Date | null),
      end: new FormControl(null as Date | null)
    });

  searchByStatus(item: any) {
    this.bxdService.emitReusbaleFilter.next({state: this.navigatedFrom, selectedStatus: item , currentPageCount: 0});
  }
  toggleFilterMenu(menuIndex: any) {
    this.filterList[menuIndex].visible = !this.filterList[menuIndex].visible;
  }

  toggleFilterItem(menuIndex: any, subMenuIndex: any, active: boolean, menuSelected: any) {
    const apiQuery = menuSelected?.query?.query?.value;
    this.filterList[menuIndex].values[subMenuIndex].selected = !this.filterList[menuIndex].values[subMenuIndex].selected;
    if (apiQuery) {
      this.bxdService.emitReusbaleFilter.next({state: this.navigatedFrom, apiQuery: apiQuery, currentPageCount: 0});
    }
  }

  searchClicked() {
    if (this.searchText) {
      this.chipVisible = true;
      this.selectedCipText = this.searchText;
    } else {
      this.chipVisible = false;
    }

    // Aviod Unwanted call.
    if(this.navigatedFrom.toLowerCase() === 'order-transaction-history'){
      this.activateItem(this.navlist[0], true);
    }

    this.bxdService.emitReusbaleFilter.next({state: this.navigatedFrom, apiQuery: this.searchText, currentPageCount: 0});
  }

  searchByDate(dateType: any) {
    this.selectedDateRange = dateType.name;
    this.dateRangePayload = dateType.value;

    // this.bxdService.emitReusbaleFilter.next({
    //   state: this.navigatedFrom,
    //   dateRangePayload: this.dateRangePayload,
    //   currentPageCount: 0,
    // });

    // Todo: Technical Fix needed: Here two time search call is happening.
    // Done: Fixed the issue by calling the below method.
    this.selectDate(dateType.value);
  }

  clearSelectedValue(event: any, type: 'start' | 'end') {
    this.selectedDateRange = '';
    this.dateRangePayload = 'no_date';

    if (type === 'start') {
      this.startDatePayload = this.getDateFormat(event);
    } else if (type === 'end') {
      this.endDatePayload =  this.getDateFormat(event);
    }

    this.bxdService.emitReusbaleFilter.next({
      state: this.navigatedFrom,
      startDatePayload: this.startDatePayload,
      dateRangePayload: this.dateRangePayload,
      endDatePayload: this.endDatePayload,
      currentPageCount: 0,
    });
  }

  getDateFormat(event: any) {
    const eventDate = new Date(event?.target?.value);

    // Extracting month, day, and year from the date
    const month = eventDate.getMonth() + 1;
    const day = eventDate.getDate();
    const year = eventDate.getFullYear();

    // Formatting the date into mm/dd/yyyy
    const formattedDate = `${month.toString()?.padStart(2, '0')}/${day.toString()?.padStart(2, '0')}/${year}`;

    return formattedDate;
  }

  selectDate(value: string) {
    const today = new Date();
    let start: Date;
    let end: Date = new Date();

    switch (value) {
      case 'YESTERDAY':
        start = new Date(today.setDate(today.getDate() - 1));
        end = start;
        break;
      case 'LAST_7_DAYS':
        start = new Date(today.setDate(today.getDate() - 6));
        break;
      case 'THIS_MONTH':
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      case 'LAST_MONTH':
        start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        end = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case 'LAST_3_MONTHS':
        end = new Date(today.setDate(today.getDate() - 1));
        today.setDate(today.getDate() + 1); // setting date to current date again
        start = new Date(today.setMonth(today.getMonth() - 3));
        break;
      case 'THIS_YEAR':
        start = new Date(today.getFullYear(), 0, 1);
        end = new Date(today.getFullYear(), 11, 31);
        break;
      case 'LAST_YEAR':
        start = new Date(today.getFullYear() - 1, 0, 1);
        end = new Date(today.getFullYear() - 1, 11, 31);
        break;
      default:
        start = today;
        break;
    }

    this.startDatePayload = new Date(start)?.toISOString();
    this.endDatePayload = new Date(end)?.toISOString();

    this.calenderForm?.setValue({ start: start, end: end });
    this.bxdService.emitReusbaleFilter.next({state: this.navigatedFrom, 
      startDatePayload: "no_date", 
      endDatePayload: "no_date",
      dateRangePayload: this.dateRangePayload,
      currentPageCount: 0
    });
  }

  handleDownload(event: any, type: string) {
    event.stopPropagation();
    this.downloadService.downloadReport(this.exportPage, this.userEmail, event, this.downloadPayload, type);
    this.cdr.detectChanges(); 
  }

  addProduct() {
    // Product Section Add Product.
  }

  lotToggleChanged() {
    // Check if the tabs are status only.
    if(this.selectedStatusDropdown?.toLowerCase() === 'status') {
      if(this.isLotChecked) {
        this.navlist = this.lotEnableInventoryNavList
      } else {
        this.navlist = this.navlistStatus;
      }
    }
    this.bxdService.emitReusbaleFilter.next({ state: this.navigatedFrom, isLotChecked: this.isLotChecked, currentPageCount: 0 });
  }

  removeSearchedItem() {
    this.chipVisible = false;
    this.searchText = '';
    // this.apiQuery = '';
    this.bxdService.emitReusbaleFilter.next({state: this.navigatedFrom, apiQuery: '', currentPageCount: 0});
  }

  productSortBy(sortType: any) {
    const sortKeyCode = sortType?.code;
    this.bxdService.emitReusbaleFilter.next({state: this.navigatedFrom, sortCode: sortKeyCode, currentPageCount: 0});
  }

  redirectToInventoryFromProduct(viewItem: any, event: any) {
    this.bxdService.redirectToInventory.next({viewItem, event});
    this.router.navigate([viewItem.route]);
  }

  toggleScreen(isFull: boolean) {
    this.isFullScreen = !isFull;
    this.fullScreenToggleService.toggleScreen(isFull);
    this.isFullScreen ? this.bxdService.bxdDynamicColumn.next({ state: this.navigatedFrom, isFullScreen: true }) : this.bxdService.bxdDynamicColumn.next({ state: this.navigatedFrom, isFullScreen: false });
    this.cdr.detectChanges();
  }

  isProductFilter = false;

  // -- Inventory --
  activateItem(itemName: any, clikedForOrderPage?:any) {
    this.selectedTab = itemName;
    this.currentTab = itemName?.name;
    if(!clikedForOrderPage) {
      // Don't do : We don't want to call the api here , as we only want to set the tab selection to All.
      this.bxdService.emitReusbaleFilter.next({ selectedTab: itemName, state: this.navigatedFrom, currentPageCount: 0  });
    }

    // BCGI2-3974: Below code commented to fix the issue.
    // if (this.selectedView === this.inventoryViewList[1] && this.navigatedFrom.toLowerCase() === "inventory-by-location") {
    //   this.isLotChecked = this.currentTab === this.navlistStatus[1]?.name || this.currentTab === this.navlistStatus[5]?.name;
    //   this.lotToggleChanged();
    // }
  }

  selection = new SelectionModel<any>(true, []);
  setView(type: string) {
    this.selectedStatusDropdown = type;
    if (type === this.inventoryViewList[0]) {
      if (this.navigatedFrom === 'order-transaction-history'){
        this.navlistLocation = [...this.navlistOrders];
      }
      this.navlist = this.navlistLocation;
      this.currentTab = this.navlistLocation[0]?.name;
    } else {
      if (this.navigatedFrom === 'order-transaction-history'){
        this.navlistStatus=this.navlistOrders;
      }

      if(this.isLotChecked) {
        this.navlist = this.lotEnableInventoryNavList
      } else {
        this.navlist = this.navlistStatus;
      }
      
      this.currentTab = this.navlistStatus[0]?.name;
    }
    if(this.navigatedFrom==="order-transaction-history"){
      this.selectedDateRange = ''
      this.calenderForm?.reset();
    }
    this.selectedTab = this.navlist[0];
    this.bxdService.emitReusbaleFilter.next({state: this.navigatedFrom, selectedView: this.selectedView, apiQuery: '', currentPageCount: 0, availableWarehouse: this.navlistLocation });
    const sortColButtons = Array.from(this.document.querySelectorAll('.productTableHeader'));
    sortColButtons?.forEach(eachCol => { eachCol.classList?.remove('rotated'); })
    this.selection = new SelectionModel<any>(true, []);
    this.canExportSelected = false;

    // If we change the view from Location to Stus or vice versa then if there are any text in the searchbox clear it and remove the search chip.
    if(this.searchText) {
      this.searchText = "";
      this.chipVisible = false;
    }

    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.navigatedFrom.toLowerCase() === 'order-transaction-history') {
      if (changes.navlistOrders && changes.navlistOrders?.currentValue) {
        this.navlist = [...this.navlistOrders];
        this.selectedTab = this.navlist?.find((item:any) => item.selected === true) || this.navlist[0];
      }
    }
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.userSub = this.store.select('auth').subscribe((res: any) => {
      if (res?.user) {
        this.userEmail = res.user?.email;
        if (this.navigatedFrom === 'inventory-by-location') {
          const all = { code: 'All', name: 'All', alias: 'All' };
          this.navlistLocation = [all, ...(res.user?.warehouses || [])];
          this.navlist = this.navlistLocation;
          this.currentTab = this.navlistLocation[0]?.name;
          this.selectedTab = this.navlist[0];
        }
      }
      this.cdr.detectChanges();
    });

    this.sortList?.forEach((eachSortItem: { selected: any; name: string }) => {
      if (eachSortItem.selected) this.selectedSorting = eachSortItem.name;
    });

    if (this.navigatedFrom === 'Product') {
      this.isProductFilter = true;
    }

    if (this.navigatedFrom === 'InventoryTransaction') {
      this.isProductFilter = true;
    }

    if (this.navigatedFrom === 'OpenWro') {
      this.isProductFilter = true;
    }

    if (this.navigatedFrom === 'inventory-by-location') {
      this.selectedView = this.inventoryViewList[0];
    }

    if (this.navigatedFrom === 'order-transaction-history') {
      this.searchPlaceholder = 'bxdPortal.orders.searchPlaceholder';
      this.inventoryViewList = ['Order Transaction History', 'Order Details'];
      this.selectedView = this.inventoryViewList[this.selectedViewIndex];
      this.currentTab = 'All';
    }

    if (this.navigatedFrom === 'wroList') {
      this.searchPlaceholder = 'bxdPortal.wroList.searchPlaceholder';
    }

    if (this.route?.snapshot?.url[0]?.path === 'inventoryLotTracked') {
      this.isLotChecked = true;
    } else {
      this.isLotChecked = false;
    }

    // this.router.events
    //   .pipe(
    //     filter(
    //       (event: any): event is NavigationEnd => event instanceof NavigationEnd
    //     )
    //   )
    //   .subscribe((event: NavigationEnd) => {
    //     if (event.url === '/inventory') {
    //       this.isLotChecked = false;
    //     } else if (event.url === '/inventoryLotTracked') {
    //       this.isLotChecked = true;
    //     }
    //     this.cdr.detectChanges();
    //     this.bxdService.emitReusbaleFilter.next({
    //       state: this.navigatedFrom,
    //       isLotChecked: this.isLotChecked,
    //       currentPageCount: 0,
    //     });
    //   });
  }
}
