<div class="cart-info d-flex flex-column" *ngIf="rewardsApplied">
  <div class="d-flex modal-heading align-items-center justify-content-center">
    <p class="m-0 t-headline-m">
      {{ "cart.creditModal.headline" | cxTranslate }}
    </p>
  </div>

  <div class="modal__container d-flex flex-column">
    <div
      class="modal__sub-heading d-flex align-items-center justify-content-end"
    >
      <p class="m-0 t-userImpersonate-main">
        {{ "cart.creditModal.cartHeadline" | cxTranslate }}
      </p>
    </div>

    <div class="modal__credit-table d-flex flex-column justify-content-end">
      <div class="table-container">
        <table
          class="tableModal table-responsive d-flex flex-column justify-content-end align-items-center"
          tabindex="0"
          *ngIf="rewardsApplied"
        >
          <thead class="tableModal-head">
            <tr>
              <th class="text-center t-cart-creditModalHeading" scope="col">
                {{ "cart.creditModal.brandsEligible" | cxTranslate }}
              </th>
              <th class="text-center t-cart-creditModalHeading" scope="col">
                {{ "cart.creditModal.valueInCart" | cxTranslate }}
              </th>
              <th class="text-center t-cart-creditModalHeading" scope="col">
                {{ "cart.creditModal.creditAvailable" | cxTranslate }}
              </th>
              <th class="text-center t-cart-creditModalHeading" scope="col">
                {{ "cart.creditModal.creditApplied" | cxTranslate }}
              </th>
            </tr>
          </thead>
          <tbody class="tableModal-body" tabindex="0">
            <div *ngFor="let item of rewardsApplied?.brands">
              <tr>
                <td
                  class="justify-content-end t-cart-creditModalBody text-right"
                >
                  {{ item.brandName || "-" }}
                </td>
                <td
                  class="justify-content-end t-cart-creditModalBody text-right"
                >
                  {{ item.valueInCart?.formattedValue || "-" }}
                </td>
                <td
                  class="justify-content-end t-cart-creditModalBody text-right"
                >
                  {{ item.availableRewards?.formattedValue || "-" }}
                </td>
                <td
                  class="justify-content-end t-cart-creditModalBody text-right"
                >
                  {{ item.appliedRewards?.formattedValue || "-" }}
                </td>
              </tr>
            </div>
          </tbody>

          <!-- total amount column -->
          <tfoot class="tableModal-foot w-100">
            <tr
              class="t-userImpersonate-mainn d-flex justify-content-end w-100"
            >
              <td class="justify-content-end t-cart-creditModalTotal">Total</td>
              <td class="justify-content-end t-cart-creditModalTotal">
                {{ rewardsApplied.totalValueInCart?.formattedValue }}
              </td>
              <td class="justify-content-end t-cart-creditModalTotal">
                {{ rewardsApplied.totalAvailableRewards?.formattedValue }}
              </td>
              <td class="justify-content-end t-cart-creditModalTotal">
                {{ rewardsApplied.totalAppliedRewards?.formattedValue }}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <div
      class="modal__sub-heading d-flex align-items-center justify-content-end"
    >
      <p class="m-0 t-userImpersonate-main">
        {{ "cart.creditModal.totalCredit" | cxTranslate }}
      </p>
    </div>

    <div class="final-amount d-flex align-items-center justify-content-end">
      <p class="m-0 t-cart-creditModalFinalAmount">
        {{ rewardsApplied.totalAppliedRewards?.formattedValue }}
      </p>
    </div>

    <div
      class="modal__hint-section d-flex align-items-center justify-content-end"
      *ngIf="brandHints.length > 0"
    >
      <div class="m-0 t-userImpersonate-main text-right text-wrap">
        <p class="m-0">{{ "cart.creditModal.hintText" | cxTranslate }}<br /></p>
        <div class="text-wrap hint-section">
          <span>
            {{ "cart.creditModal.hintSubText" | cxTranslate }} &nbsp;</span
          >
          <span *ngFor="let brandName of brandHints; let index = index"
            >{{ brandName }}
            <span *ngIf="index < brandHints.length - 1">, </span>
          </span>
        </div>
      </div>
    </div>

    <div
      class="modal-learnMore t-line_promo-desc d-flex align-items-center justify-content-end text-right"
    >
      <span class="text-right"
        >{{ "cart.creditModal.creditAutoApplied" | cxTranslate }}
        <a
          routerLink="/richowitworks"
          target="_blank"
          class="learnMore-link cursor-pointer"
          >{{ "cart.creditModal.learnMore" | cxTranslate }}</a
        ></span
      >
    </div>
  </div>

  <div class="modal-closeBtn d-flex align-items-center justify-content-center">
    <button
      mat-flat-button
      color="primary"
      type="button"
      class="t-cart-creditModalBtn"
      [mat-dialog-close]="true"
    >
      Close
    </button>
  </div>
</div>
