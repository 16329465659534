<div class="mix-match-promotion py-5 my-4 d-flex flex-column">
  <section
    class="mix-match-promotion-header d-flex align-items-center px-3 w-100"
  >
    <div class="promotion-icon-container p-2 rounded-circle">
      <mat-icon class="promotion-icon" svgIcon="promo_green"></mat-icon>
    </div>
    <div class="content-section">
      <div class="promo-text text-capitalize t-mixMatch-heading">
        {{ "cart.mixMatch.applyPromo" | cxTranslate }}
      </div>
      <div class="promo-desc t-mixMatch-desc mt-1">
        {{ "cart.mixMatch.youAreViewingPromo" | cxTranslate }}:
        {{ mmPromoData.promoTitle }}
      </div>
    </div>
    <!-- <mat-icon
      [mat-dialog-close]="true"
      class="close-btn ml-auto mr-0"
      svgIcon="close_white"
    ></mat-icon> -->
    <mat-icon [mat-dialog-close]="true" class="close-icon ml-auto mr-0 dialogCloseIcon" color="white">close</mat-icon>
  </section>

  <section
    class="mix-match-promotion-description d-flex flex-wrap flex-sm-nowrap justify-content-between align-items-center p-3"
  >
    <div class="promotion-description t-mixMatch-desc-2" *ngIf="mmPromoData?.description" [innerHTML]="mmPromoData.description"></div>
    <button
      [mat-dialog-close]="true"
      mat-flat-button
      color="white"
      class="t-label-l ml-auto ml-sm-0"
    >
      {{ "cart.mixMatch.backToPromoList" | cxTranslate }}
    </button>
  </section>

  <section
    *ngIf="offersCount > 1"
    class="mix-match-promotion-offers-heading t-mixMatch-offer-heading p-3 my-0 my-sm-3 text-center"
  >
    {{ "cart.mixMatch.youAreEligibleFor" | cxTranslate }}
    {{ offersCount }}
    {{
      (offersCount > 1 ? "cart.mixMatch.setsOffer" : "cart.mixMatch.setOffer")
        | cxTranslate
    }}
  </section>

  <section
    *ngIf="offersCount > 1"
    class="mix-match-promotion-stepper d-flex justify-content-between align-items-center px-4 pt-2 pb-3"
  >
    <ng-container *ngFor="let item of mmPromoData?.offers; index as idx">
      <div *ngIf="idx" class="separator"></div>
      <ng-container
        [ngTemplateOutlet]="step"
        [ngTemplateOutletContext]="{ $implicit: idx }"
      ></ng-container>
    </ng-container>
  </section>

  <section
    class="mix-match-promotion-quantity d-flex align-items-center justify-content-center px-3 mt-4 mb-1"
  >
    <div
      class="selected-quantity d-flex align-items-center justify-content-center px-2"
    >
      <div class="quantity py-2 text-center">
        {{ selectedPoints ? selectedPoints : "-" }}
      </div>
      <div class="text p-2" [class.highlight]="earnedPoints === selectedPoints">
        {{ "cart.mixMatch.selected" | cxTranslate }}
      </div>
    </div>
    <div
      class="earned-quantity d-flex align-items-center justify-content-center px-2"
    >
      <div class="quantity py-2 py-2 text-center">{{ earnedPoints }}</div>
      <div class="text p-2" [class.highlight]="earnedPoints !== selectedPoints">
        {{ "cart.mixMatch.earned" | cxTranslate }}
      </div>
    </div>
  </section>

  <section
    class="mix-match-promotion-offers-count t-mixMatch-product-name p-3 text-center"
  >
    {{ "cart.mixMatch.selectUpto" | cxTranslate }} {{ earnedPoints }}
    {{ "cart.mixMatch.unitsFromBelowProducts" | cxTranslate }}
  </section>

  <section class="mixMatchAlert mx-4 pb-3 align-items-center justify-content-center" *ngIf="selectedPoints > earnedPoints">
    <reusable-alert-box [alertType]="'warningAlertBox'"
    alertMsg="{{ 'cart.mixMatch.For' | cxTranslate }} {{ productId }} {{ 'cart.mixMatch.promoAlert' | cxTranslate}}">
    </reusable-alert-box>
  </section>
  <section class="mix-match-promotion-products">
    <ng-container *ngFor="let product of currentProducts || []">
      <ng-container
        [ngTemplateOutlet]="freeProduct"
        [ngTemplateOutletContext]="{ $implicit: product }"
      ></ng-container>
    </ng-container>
  </section>

  <section
    class="mix-match-promotion-footer d-flex justify-content-center align-items-center p-3 w-100"
  >
    <button
      *ngIf="currentStep === 1"
      [mat-dialog-close]="true"
      mat-flat-button
      color="white"
      class="back-to-slider t-label-l"
    >
      {{ "cart.mixMatch.close" | cxTranslate }}
    </button>
    <button
      *ngIf="currentStep !== 1 && offersCount > 1"
      mat-flat-button
      color="white"
      class="back-to-slider t-label-l"
      (click)="handleStepper('PREV')"
    >
      {{ "cart.mixMatch.back" | cxTranslate }}
    </button>
    <button
      *ngIf="currentStep !== offersCount"
      mat-flat-button
      color="primary"
      class="back-to-slider t-label-l"
      (click)="handleStepper('NEXT')"
    >
      {{ "cart.mixMatch.nextStep" | cxTranslate }}
    </button>
    <button
      *ngIf="currentStep === offersCount"
      mat-flat-button
      color="primary"
      class="back-to-slider t-label-l"
      [disabled]="isLoading || earnedPoints < selectedPoints"
      (click)="applyPromotion()"
    >
      {{ "cart.mixMatch.addFreeItemsToYourOrder" | cxTranslate }}
    </button>
  </section>
</div>

<!-- This is for Step Points -->
<ng-template #step let-idx>
  <div class="step d-flex align-items-center justify-content-center">
    <span class="step-number-container rounded">
      <span
        class="step-number t-section-header rounded-circle d-inline-flex align-items-center justify-content-center"
        [class.active]="idx + 1 <= currentStep"
      >
        <mat-icon
          *ngIf="idx + 1 < currentStep"
          svgIcon="check_white"
        ></mat-icon>
        <mat-icon
          *ngIf="currentStep === idx + 1"
          svgIcon="edit_white"
        ></mat-icon>
        <span *ngIf="idx + 1 > currentStep">{{ idx + 1 }}</span>
      </span>
    </span>
    <span class="step-text t-title-m pl-2 d-none d-sm-inline"
      >{{ "cart.mixMatch.offer" | cxTranslate }} {{ idx + 1 }}</span
    >
  </div>
</ng-template>
<!-- This is for Step Points -->

<!-- This is for Product Item -->
<ng-template #freeProduct let-product>
  <div
    class="mix-match-promotion-product-description d-flex flex-wrap flex-sm-nowrap justify-content-between p-3 mx-3 mb-3"
  >
    <div class="product-body">
      <div class="brand-name t-mixMatch-brand text-uppercase">
        {{ product?.brandName }}
      </div>
      <div class="product-name t-mixMatch-product-name mb-2">
        {{ product?.itemTitle }}
      </div>
      <div class="sku px-2 t-mixMatch-brand">
        <span class="sku-key t-mixMatch-sku"
          >{{ "cart.mixMatch.sku" | cxTranslate }}:</span
        >
        {{ product?.itemNumber }}
      </div>
    </div>

    <!-- For Quantity Manipulation -->
    <div class="quantity d-flex align-items-center justify-center p-0">
      <button
        class="remove-product d-flex align-items-center justify-content-center"
        [disabled]="product.quantity === 0"
        (click)="handleQuantityChange(product, 'SUB')"
      >
        <mat-icon
          svgIcon="minusIcon"
          class="product-tile-minusIcon-icon"
          aria-label="minus"
        ></mat-icon>
      </button>
      <input
      matInput
      type="number"
      class="current-product-quanity t-body-l mx-1"
      min="0"
      (keypress)="keyPressNumbers($event)"
      #inputField
      [(ngModel)]="product.quantity"
      (ngModelChange)="onInputChange($event, product)"
    />
      <button
        class="add-product d-flex align-items-center justify-content-center"
        [disabled]="
          earnedPoints <= selectedPoints || product?.quantity === earnedPoints
        "
        (click)="handleQuantityChange(product, 'ADD')"
      >
        <mat-icon
          svgIcon="plus"
          class="product-tile-plus-icon"
          aria-label="plus"
        ></mat-icon>
      </button>
    </div>
    <!-- For Quantity Manipulation -->
  </div>
</ng-template>
<!-- This is for Product Item -->