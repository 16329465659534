import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Pipe({
  name: 'formatCurrency'
})
export class formatCurrencyPipe implements PipeTransform {
    constructor(private _sanitizer: DomSanitizer) { }
    transform(value: string, promotionValue?:'PROMO' ) {
      //if we have single variant price this condition will call
      if(value.split(' ').length == 1) { // checking length of the value after splitting
        let splitData = value?.split(".");
        let currencyData = splitData.slice(0,1)[0].split('');
        let currency = currencyData[0];
        let price = currencyData.slice(1).join('');
        price = this.formatPriceWithoutDecimal(price);
        let cent = splitData[1];
        cent = this.formatCent(cent);
        let currencyHtml = `<sup class="t-price-tile product-tile-currency  ${promotionValue==='PROMO'?'promo-color':''}">${currency}</sup><sup class="t-price-currenyTile product-tile-figure ${promotionValue==='PROMO'?'promo-color':''}">${price}</sup><sup class="t-price-currenyFractionTile ml-1 product-tile-currency  ${promotionValue==='PROMO'?'promo-color':''}">${cent}</sup>`
        return currencyHtml;
      }
      else { // else if we have family variant price range this condition will execute
        let splitData = value?.split(' ')[0].split('.');
        let currencyData = splitData.slice(0,1)[0].split('');
        let currency = currencyData[0];
        let price = currencyData.slice(1).join('');
        price = this.formatPriceWithoutDecimal(price);
        let cent = splitData[1];
        cent = this.formatCent(cent);
        let splitData1 = value?.split(" ")[2].split('.');
        let currencyData1 = splitData1.slice(0,1)[0].split('');
        let currency1 = currencyData1[0];
        let price1 = currencyData1.slice(1).join('');
        price1 = this.formatPriceWithoutDecimal(price1);
        let cent1 = splitData1[1];
        cent1 = this.formatCent(cent1);
        let currencyHtml = `<sup class="t-price-tile product-tile-currency  ${promotionValue==='PROMO'?'promo-color':''}">${currency}</sup><sup class="t-price-currenyTile product-tile-figure ${promotionValue==='PROMO'?'promo-color':''}">${price}</sup><sup class="t-price-currenyFractionTile ml-1 product-tile-currency  ${promotionValue==='PROMO'?'promo-color':''}">${cent}</sup><sup class="t-price-purchaseUomTile product-tile-dash">-</sup><sup class="t-price-tile product-tile-currency  ${promotionValue==='PROMO'?'promo-color':''}">${currency1}</sup><sup class="t-price-currenyTile product-tile-figure ${promotionValue==='PROMO'?'promo-color':''}">${price1}</sup><sup class="t-price-currenyFractionTile ml-1 product-tile-currency  ${promotionValue==='PROMO'?'promo-color':''}">${cent1}</sup>`
        return currencyHtml;
      }
    }

    // For adding commas, if not present already
    formatPriceWithoutDecimal = (price:string):string => {
      if (!price?.includes(',')) {
        if (price?.length > 9 ) {
          const beforeComma = price?.slice(0, price?.length - 9);
          const betweenComma1 = price?.slice(price?.length - 9,price?.length - 6);
          const betweenComma2 = price?.slice(price?.length - 6,price?.length - 3);
          const afterComma = price?.slice(price?.length - 3,price.length);
          price = `${beforeComma},${betweenComma1},${betweenComma2},${afterComma}`;
        } else if (price?.length > 6 && price?.length <= 9) {
          const beforeComma = price?.slice(0, price?.length - 6);
          const betweenCommas = price?.slice(price?.length - 6,price?.length - 3);
          const afterComma = price?.slice(price?.length - 3,price.length);
          price = `${beforeComma},${betweenCommas},${afterComma}`;
        } else if (price?.length > 3 && price?.length <= 6) {
          const beforeComma = price?.slice(0, price?.length - 3);
          const afterComma = price?.slice(price?.length - 3,price.length);
          price = `${beforeComma},${afterComma}`;
        }
      }
      return price;
    }

    // For showing 2 digit cent value
    formatCent = (cent:string):string => {
      if(cent?.length > 2) {
        return cent?.slice(0,2);
      } else if (cent?.length === 2) {
        return cent;
      } else if (cent?.length === 1) {
        return `${cent}0`;
      } else {
        return '00';
      }
    }
  }
