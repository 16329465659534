import { ActionReducerMap } from "@ngrx/store";
import * as fromAuth from './reducers/auth.reducer';
import * as fromAccountDetails from './reducers/my-account.reducer';

export interface AppState {
  auth: fromAuth.UserState;
  accountDetails: fromAccountDetails.State
}

export const appReducer: ActionReducerMap<AppState> = {
  auth: fromAuth.authReducer,
  accountDetails: fromAccountDetails.accountDetailsReducer
}