import { Component, Input } from '@angular/core';
import { global } from 'src/app/shared/translations/en/global';

@Component({
  selector: 'app-order-info-card',
  templateUrl: './order-info-card.component.html'
})
export class OrderInfoCardComponent {

  @Input() orderDetails: any
  @Input() componentName:any
  approvedBy = global.account.order.approvedBy;
  rejectedBy = global.account.order.rejectedBy;
 
  constructor() { }
 
}
