import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'reusable-alert-box',
  templateUrl: './reusable-alert-box.component.html',
})
export class ReusableAlertBoxComponent implements OnInit {
  alertIconSrc: string = '';
  altAlertText: string = '';
  fontColorClass: string = '';

  @Input() alertMsg: string;
  @Input() alertType: string;
  constructor() {}

  ngOnInit(): void {
    if (this.alertType === 'warningAlertBox') {
      this.alertIconSrc = 'assets/img/warningIcon.svg';
      this.altAlertText = 'warning icon link';
      this.fontColorClass = 'warningAlertBoxText';
    }
    else if(this.alertType === 'successAlertBox'){
      this.alertIconSrc = 'assets/img/successIcon.svg';
      this.altAlertText = 'success icon link';
      this.fontColorClass = 'successAlertBoxText';
    }
  }
}
