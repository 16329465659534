<div class="d-flex flex-column flex-md-row mt-2 mb-2 justify-content-md-between order-detail-address">
  <div class="order-detail-shipTo mb-3 mb-md-0">
    <div class="order-detail-address-text">
      <p class="t-title-s ml-2 py-2 mr-3 my-0">
        {{ "account.order.shipTo" | cxTranslate }}
      </p>
    </div>
    <div class="px-2">
      
      <p class="t-title-s pt-2">{{orderDetails?.shipToName? orderDetails.shipToName:''}}</p>
      <p class="t-body-s m-0">
        {{ "account.order.address" | cxTranslate }}
      </p>
      
      <p class="m-0 t-title-s">{{orderDetails?.deliveryAddress?.line1? orderDetails.deliveryAddress.line1:'' }}</p>
      <p class="m-0 t-title-s">{{orderDetails?.deliveryAddress?.line2? orderDetails.deliveryAddress.line2:''}}</p>
      <p class="m-0 t-title-s">{{orderDetails?.deliveryAddress?.town}}, 
        {{(orderDetails?.deliveryAddress?.region?.isocode) ? ((orderDetails?.deliveryAddress?.region?.isocode).replace("USA-","")+ " ") : ""}}
        {{orderDetails?.deliveryAddress?.postalCode}}
        {{orderDetails?.deliveryAddress?.country?.isocode}}
      </p>
      <p class="t-body-s mt-3 mb-0 d-lg-flex">
        <span class="width-62">{{ "account.order.shippingMethod" | cxTranslate }}</span>
        <span class="d-none d-lg-block" *ngIf="orderDetails?.futureShipDate">{{"account.order.futureShippingDate" | cxTranslate }}</span>
      </p>
      
      <p class="t-title-s d-lg-flex">
        <span class="width-62">{{orderDetails?.deliveryMode?.code| uppercase}}</span>
        <span class="d-none d-lg-block" *ngIf="orderDetails?.futureShipDate">{{orderDetails?.futureShipDate}}</span>
      </p>

      <p class="t-body-s mt-3 mb-0 d-block d-lg-none" *ngIf="orderDetails?.futureShipDate">
        <span>{{"account.order.futureShippingDate" | cxTranslate }}</span>
      </p>
      
      <p class="t-title-s d-block d-lg-none" *ngIf="orderDetails?.futureShipDate">
        <span>{{orderDetails?.futureShipDate}}</span>
      </p>
    </div>
  </div>
  <div class="order-detail-shipTo">
    <div class="order-detail-address-text">
      <p class="t-title-s ml-2 py-2 mr-3 my-0">
        {{ "account.order.billTo" | cxTranslate }}
      </p>
    </div>
    <div class="pl-2 pt-3 mb-1 mb-md-0">
      <p class="t-body-s m-0">
        {{ "account.order.account" | cxTranslate }}
      </p>
      
      <p class="m-0 t-title-s">{{orderDetails?.billToId? orderDetails.billToId:''}} | {{orderDetails?.orgUnit?.unitType}}</p>
      
      <p class="m-0 t-title-s">{{orderDetails?.billToName? orderDetails.billToName:''}}</p>
      <p class="t-body-s mt-2 mb-0">
        {{ "account.order.address" | cxTranslate }}
      </p>
      
      <p class="m-0 t-title-s">{{orderDetails?.paymentAddress?.line1? orderDetails.paymentAddress.line1:''}}</p>
      <p class="m-0 t-title-s">{{orderDetails?.paymentAddress?.line2? orderDetails.paymentAddress.line2:''}}</p>
      <p class="m-0 t-title-s">{{orderDetails?.paymentAddress?.town}}, 
        {{(orderDetails?.paymentAddress?.region?.isocode) ? ((orderDetails?.paymentAddress?.region?.isocode).replace("USA-","")+ " ") : ""}}
        {{orderDetails?.paymentAddress?.postalCode}}
        {{orderDetails?.paymentAddress?.country?.isocode}}
      </p>
    </div>
  </div>
</div>