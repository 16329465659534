<button mat-flat-button color="primary" class="d-none d-lg-flex mini-cart-btn text-decoration-none" [routerLink]="['/cart']">
  <mat-icon svgIcon="minicart" class="mr-xl-3" matBadge="{{count}}" matBadgeColor="warn"></mat-icon>
  <div class="t-body-inverse-m pr-2 d-none d-xl-flex align-items-center">
    <span>{{'header.yourCart' | cxTranslate}}</span>
  </div>
</button>

<a class="text-decoration-none" [routerLink]="['/cart']" [attr.aria-label]="'header.viewCartLabel' | cxTranslate">
  <mat-icon svgIcon="minicart" class="d-block d-lg-none mr-3 mini-cart-icon" tabindex="-1" role="button"
  matBadge="{{count}}" matBadgeColor="warn"></mat-icon>
</a>
