<!--This displays Reusable Address form which is used for Smarty US addresses Validations-->
<div class="d-flex flex-column">
  <form [formGroup]="addressForm">
    <div class="reusable-addresst-input-display mb-3">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label> {{
          "global.reusableAddressForm.addressOneTitle" | cxTranslate
        }}</mat-label>
        <input matInput type="text" class="reusable-address-input-outline" formControlName="addressOne" maxlength="75"
        (keyup)="searchAddress($event)" [matAutocomplete]="auto" />
        <mat-autocomplete #auto="matAutocomplete"  (optionSelected)="addressSelected($event)">
        
          <mat-option *ngFor="let address of builtAddressData" [value]="address">
            {{address}}
          </mat-option>
   
        </mat-autocomplete>
        <mat-error *ngIf="
        addressForm.get('addressOne')?.touched &&
        addressForm.get('addressOne')?.hasError('required')
        ">{{
          "global.reusableAddressForm.addressOneRequired" | cxTranslate
        }}</mat-error>
      </mat-form-field>
    </div>

  <div class="reusable-addresst-input-display mb-3">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label> {{
        "global.reusableAddressForm.addressTwoTitle" | cxTranslate
      }}</mat-label>
      <input
        matInput
        type="text"
        class="reusable-address-input-outline"
        formControlName="optionalAddressTwo"
        maxlength="75"
      />
      <mat-error></mat-error>
    </mat-form-field>
  </div>

   <div class="reusable-addresst-input-display mb-3">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label> {{
        "global.reusableAddressForm.cityTitle" | cxTranslate
      }}</mat-label>
      <input matInput type="text" class="reusable-address-input-outline" formControlName="cityName" maxlength="30"/>
      <mat-error  *ngIf="
      addressForm.get('cityName')?.touched &&
      addressForm.get('cityName')?.hasError('required')
      ">{{
        "global.reusableAddressForm.cityRequired" | cxTranslate
      }}</mat-error>
    </mat-form-field>
   </div>

    <div *ngIf="!(componentNameForCart==='shipTocartBusinessAccount' || action ==='addingNewAddressForClientOrPatientShipToAccount' || action ==='addingNewAddressForBusinessAccount' || action === 'addingNewClientOrPatientShipToAccount')" class="d-flex flex-column flex-lg-row state-input-or-zipcode-input mb-3">

    <div class="mr-0 mr-lg-4 state-input mb-2 mb-lg-0 w-100">
     <mat-form-field appearance="outline" class="w-100">
       <mat-label appRemoveAriaOwns>{{
         "global.reusableAddressForm.stateTitle" | cxTranslate
       }}</mat-label>
       <mat-select formControlName="stateName">
         <mat-option *ngFor="let state of stateList" [value]="state.isocode">
           {{ state.name }}
         </mat-option>
       </mat-select>
       <mat-error *ngIf="
       addressForm.get('stateName')?.touched &&
       addressForm.get('stateName')?.hasError('required')
       ">{{
         "global.reusableAddressForm.stateRequired" | cxTranslate
       }}</mat-error>
     </mat-form-field>
    </div>

    <div class="w-100">
     <mat-form-field appearance="outline" class="w-100">
       <mat-label> {{
         "global.reusableAddressForm.zipCodeTitle" | cxTranslate
       }}</mat-label>
       <input matInput type="text" class="reusable-address-input-outline" formControlName="zipCode" minlength="5" maxlength="9" />
       <mat-error *ngIf="
       addressForm.get('zipCode')?.touched &&
       addressForm.get('zipCode')?.hasError('required')
       ">{{
         "global.reusableAddressForm.zipCodeRequired" | cxTranslate
       }}</mat-error>
       <mat-error *ngIf="
       addressForm.get('zipCode')?.touched &&
       addressForm.get('zipCode')?.hasError('minlength')
       "> {{
         "global.reusableAddressForm.zipCodeLength" | cxTranslate
       }}</mat-error>
        <mat-error *ngIf="
       addressForm.get('zipCode')?.touched &&
       addressForm.get('zipCode')?.hasError('pattern')
       ">{{
         "global.reusableAddressForm.zipCodePattern" | cxTranslate
       }}</mat-error>
       
      
     </mat-form-field>
     
    </div>

   </div>

    <div *ngIf="(componentNameForCart==='shipTocartBusinessAccount'||  action ==='addingNewAddressForClientOrPatientShipToAccount' ||action ==='addingNewAddressForBusinessAccount' || action === 'addingNewClientOrPatientShipToAccount')" class="client-ship-to-state-input-or-zipcode-input mb-3">

      <div class=" state-input">
       <mat-form-field class="w-100" appearance="outline">
         <mat-label appRemoveAriaOwns>{{
           "global.reusableAddressForm.stateTitle" | cxTranslate
         }}</mat-label>
         <mat-select formControlName="stateName">
           <mat-option *ngFor="let state of stateList" [value]="state.isocode">
             {{ state.name }}
           </mat-option>
         </mat-select>
         <mat-error *ngIf="
         addressForm.get('stateName')?.touched &&
         addressForm.get('stateName')?.hasError('required')
         ">{{
           "global.reusableAddressForm.stateRequired" | cxTranslate
         }}</mat-error>
       </mat-form-field>
      </div>
 
      <div>
       <mat-form-field class="w-100" appearance="outline">
         <mat-label> {{
           "global.reusableAddressForm.zipCodeTitle" | cxTranslate
         }}</mat-label>
         <input matInput type="text" class="reusable-address-input-outline" formControlName="zipCode" minlength="5" maxlength="9" />
         <mat-error *ngIf="
         addressForm.get('zipCode')?.touched &&
         addressForm.get('zipCode')?.hasError('required')
         ">{{
           "global.reusableAddressForm.zipCodeRequired" | cxTranslate
         }}</mat-error>
         <mat-error *ngIf="
         addressForm.get('zipCode')?.touched &&
         addressForm.get('zipCode')?.hasError('minlength')
         "> {{
           "global.reusableAddressForm.zipCodeLength" | cxTranslate
         }}</mat-error>
          <mat-error *ngIf="
         addressForm.get('zipCode')?.touched &&
         addressForm.get('zipCode')?.hasError('pattern')
         ">{{
           "global.reusableAddressForm.zipCodePattern" | cxTranslate
         }}</mat-error>
         
        
       </mat-form-field>
       
      </div>
 
     </div>

    <div class="reusable-addresst-input-display mb-3">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label appRemoveAriaOwns> {{
          "global.reusableAddressForm.countryTitle" | cxTranslate
        }}</mat-label>
        <mat-select formControlName="countryName" >
          <mat-option  *ngFor="let country of countryList" [value]="country.isocode">
            {{ country.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="
        addressForm.get('countryName')?.touched &&
        addressForm.get('countryName')?.hasError('required')
        ">{{
          "global.reusableAddressForm.countryRequired" | cxTranslate
        }}</mat-error>
      </mat-form-field>
    </div>

    <input hidden type="text" formControlName="id"/>

  </form>
</div>
