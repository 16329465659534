<!--This displays Add Ship To Form Dialog when user clicks on Add Ship To button -->
<div class="d-flex flex-column container create-business-account-dialog">
  
  <div  class="d-flex flex-row justify-content-between mb-4">
    <div>
      <section class="t-title-l">{{ "account.createAccount.createClientBusinessShipToAddressTitle" | cxTranslate }}
      </section>
    </div>
    <div>
      <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon ml-2 ml-lg-0" >close</mat-icon>
    </div>
  </div>
  
  <div class="d-flex flex-column">
    <form [formGroup]="addShipToAddressForm">
      
      <div class="mb-4">
        <section class="t-title-l">{{ "account.createAccount.clientInfo" | cxTranslate }}
        </section>
      </div>


      <div class="create-business-account-input-display mb-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "account.createAccount.attnTitle" | cxTranslate }}
          </mat-label>
          <input
            matInput
            type="text"
            class="create-business-account-input-outline"
            formControlName="attn"
            maxlength="30"
          />
          <mat-error></mat-error>
        </mat-form-field>
      </div>

      <div>
        <app-reusable-address-form></app-reusable-address-form>
      </div>


      <div class="create-business-account-input-display">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "account.createAccount.deliverNotesTitle" | cxTranslate }}
          </mat-label>
          <input
            matInput
            type="text"
            class="create-business-account-input-outline"
            formControlName="deliverNote"
            maxlength="60"
          />
          <mat-error></mat-error>
        </mat-form-field>
      </div>

      <div class="d-flex flex-row mb-3">

        <div class="d-flex mr-3">
          <div class="t-body-s edit-ship-to-toggle-ship">{{ "account.createAccount.defaultShippingToTitle" | cxTranslate }}</div>
            <mat-slide-toggle formControlName="defaultShippgTo" 
            [disabled]="data && data.value && data.value.defaultShippgTo"
            [(ngModel)]="addShipToAddressForm.get('defaultShippgTo').value"
              class="edit-mat-toggle-status"
            color="primary">{{addShipToAddressForm.get('defaultShippgTo').value ? 'On' : 'Off'}}</mat-slide-toggle>      
        </div>

        <!-- Default Bill To toggle should be shown for PARENT with customer role Admin and on client pages-->
        <div class="d-flex" *ngIf="(userType?.toUpperCase() === 'PARENT' && customerRole === 'Admin' || userType?.toUpperCase() === 'DIRECT' && customerRole === 'Admin') && customerRole === 'Admin'
        && data?.value?.componentName !== 'cartShipToViewAddressComponent' && data?.value?.componentName !== 'cartProductComponent' && !(data?.value?.componentName === 'myClientAddress' || data?.value?.componentName === 'manageClientAddress')
        && !(data?.value?.accountType === 'DROPSHIP' || data?.value?.manageAccountType === 'DROPSHIP') ">
          <div class="t-body-s edit-ship-to-toggle-bill">{{ "account.createAccount.defaultBillToTitle" | cxTranslate }}</div>
            <mat-slide-toggle formControlName="defaultBillingTo"
            [(ngModel)]="addShipToAddressForm.get('defaultBillingTo').value"
            [disabled]="data && data.value && data.value.defaultBillingTo"
             class="edit-mat-toggle-status"
             color="primary">{{addShipToAddressForm.get('defaultBillingTo').value ? 'On' : 'Off'}}</mat-slide-toggle>      
        </div>

      </div>

      <div class="d-flex justify-content-end" >
        <div>
          <button
            mat-flat-button mat-dialog-close
            type="button"
            
            class="mr-2"
          >
          {{ "account.createAccount.cancelA" | cxTranslate }}
          </button>
        </div>
        <div class="create-business-account-submit-button" *ngIf="data?.value?.componentName !== 'cartShipToViewAddressComponent' && data?.value?.componentName !== 'cartShipToBusinessAccountComponent'">
          <button
            mat-flat-button
            type="button"
            (click)="addShipToaddressAccount()"
          >
          {{ "account.createAccount.createShipTo" | cxTranslate }}
          </button>
        </div>
        <div class="create-business-account-submit-button" *ngIf="data?.value?.componentName === 'cartShipToViewAddressComponent' || data?.value?.componentName === 'cartShipToBusinessAccountComponent'">
          <button
            mat-flat-button
            type="button"
            (click)="addShipToaddressAccount()"
          >
          {{ "account.cartShipToBusiness.add" | cxTranslate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
