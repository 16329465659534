import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromApp from 'src/app/core/store/app.reducer';
import { FreeFreightDialogComponent } from 'src/app/feature/components/free-freight-dialog/free-freight-dialog.component';
import { User } from '../../../core/models/user.model';
import { AddressService } from '../../services/common/address.service';
import { BoxoutEndpointService } from '../../services/common/boxout-endpoint.service';
import { CurrentThemeService } from '../../services/common/current-theme.service';
import { SmartyAddressValidationService } from '../../services/common/smarty-address-validation.service';
import { AddShipToClientPatientAddressDrawerComponent } from '../add-ship-to-client-patient-address-drawer/add-ship-to-client-patient-address-drawer.component';

@Component({
  selector: 'app-ship-to-checkout-address',
  templateUrl: './ship-to-checkout-address.component.html',
})
export class ShipToCheckoutAddressComponent implements OnInit {
  currentTheme: any;
  userId: any;
  siebelRowId: any;
  user: User;
  userSub: Subscription;
  viewAddresses: any = [];
  displayUid: any;
  shippingAddressSelectedSuccess: boolean = false;
  shippingAddressSelectedFailure: boolean = false;
  errorMsg: boolean = false;
  freeFreight:any;
  constructor(public dialog: MatDialog, private theme: CurrentThemeService,
    private smartyAddressValidationService: SmartyAddressValidationService,
    @Inject(MAT_DIALOG_DATA) public data: any, private addressService: AddressService,
    private store: Store<fromApp.AppState>, private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ShipToCheckoutAddressComponent>,
    private customHttp: BoxoutEndpointService, public dialogRefFree: MatDialogRef<FreeFreightDialogComponent>) { }

  ngOnInit(): void {
    /** Taking themes from themeservice **/
    this.currentTheme = this.theme?.getCurrentTheme();
    this.freeFreight = this.data?.value?.freeFreight;
    this.userSub = this.store.select('auth').subscribe(user => {
      this.user = user && user.user!;
      this.userId = this.user?.uid;
      this.displayUid = this.user?.displayUid;
      this.siebelRowId = this.user?.orgUnit?.siebelRowId;
      if (this.userId && this.siebelRowId) {
        let otherAddress = this.data?.value?.itemData;
        let callShipTo = this.data?.value?.callShipTo;
        this.getClientViewAddress(otherAddress, callShipTo);
      }
    });

    this.shipToCheckoutAddressAdded();
  }

  //This method is used to show all ship to other addresses in Other Ship Address drawer
  getClientViewAddress(item: any, callShipTo: any) {
    let apiURL = callShipTo ? `users/current/orgUnits/${this.siebelRowId}/clients/${this.data?.value?.shipToSiebelId}/addresses`
      : `users/current/orgUnits/${this.data?.value?.b2bSiebelId}/addresses`;
    this.addressService
      .getClientViewAddresses(apiURL)
      .subscribe((data: any) => {
        if (data) {
          //Filtering out the shipto and bill to
          data.addresses = data.addresses.filter((resp: any) => resp.id != item?.shipTo?.id);
          if (item?.billTo?.id) {
            data.addresses = data.addresses.filter((resp: any) => resp.id != item?.billTo?.id);
          }
          this.viewAddresses = data.addresses;
          this.cdr.detectChanges();
        }
      });
  }

  //This method is used to select address for cart ship when user selects address from other addresses block
  selectAddresses(item: any) {
    this.shippingAddressSelectedSuccess = false;
    this.shippingAddressSelectedFailure = false;
    let cartId = sessionStorage.getItem('cartId');
    let requestBody = {
      addressSiebelRowID: item.id,
      cartId: cartId,
      isBillTo: false,
      isShipTo: true,
      isShippingClient: this.data?.value?.callShipTo,
      siebelRowId: this.data?.value?.callShipTo ? this.data?.value?.shipToSiebelId : this.data?.value?.b2bSiebelId
    };
    let apiUrl = `users/current/carts/${cartId}/setCheckAddresses/?fields=DEFAULT`;
    this.customHttp.post(apiUrl, requestBody).subscribe(
      (resp: any) => {
        let obj= {'isShip' : true ,value : resp}
        this.dialogRef.close();
        this.addressService.setSelectShipAddressData(obj)
      },
      (error: any) => {
        this.shippingAddressSelectedSuccess = false;
        this.shippingAddressSelectedFailure = true;
      }
    );
  }

  removeFreeFreight(item: any) {
    if (this.freeFreight && this.freeFreight?.status === 'Applied') {
      let cartId = sessionStorage.getItem('cartId');
      let code = this.freeFreight?.code;
      const dialogRef = this.dialog.open(FreeFreightDialogComponent,{
        width: "380px",
        data : {initiator:'shippingAddress'}
      });
  
        dialogRef.afterClosed().subscribe(result => {
          if (result.action === 'continue' && result.initiator === 'shippingAddress') { 
            this.customHttp.post(`/users/${this.user?.uid}/carts/${cartId}/removePromotion/${code}`).subscribe(
            (data: any) => {
              this.selectAddresses(item);
              this.errorMsg = false;
            }, (error: any) => {
              this.errorMsg = true;
            })
          }
        });
    } else {
      this.selectAddresses(item);
    }
  }
  // This method opens up add ship to address drawer
  openAddShipToDialog(item: any) {
    this.dialogRef.close();
    //setting form data null for avoiding multiple call 
    this.smartyAddressValidationService.saveFormData(null);
    this.dialog.open(AddShipToClientPatientAddressDrawerComponent,
      {
        width: '368px',
        panelClass: [this.currentTheme, 'ship-to-client-drawer-for-all'],
        position: {
          right: '0px',
          top: '0px'
        },
        data: {
          value: {
            action: "addingNewAddressForClientOrPatientShipToAccount",
            openedDrawerFrom: "openedAddShipToFromCheckout",
            itemData: item,
            addAddressId: this.data?.value?.callShipTo ? this.data?.value?.shipToSiebelId : this.data?.value?.b2bSiebelId,
            callShipTo: this.data?.value?.callShipTo,
            b2bSiebelId: this.data?.value?.b2bSiebelId,
            shipToSiebelId: this.data?.value?.shipToSiebelId,
            freeFreight: this.freeFreight
          }
        }
      });
  }

  //if ship to checkout address is added on checkout page 
  shipToCheckoutAddressAdded(){
   this.smartyAddressValidationService.saveAddedAddressForCheckoutShipTOData$.subscribe((val)=>{
    
    if (!this.dialog?.openDialogs?.length){

      this.dialog.open(ShipToCheckoutAddressComponent, {
        position: {
          right: '0px',
          top: '0px'
        },
        panelClass: ['wishlist-drawer', 'business-account-dialogs-style'],
        data: {
          value:{
            showSuccessForCart:val,
            showFailureForCart:!val,
            itemData: this.data.value.item,
            callShipTo: this.data.value.callShipTo,
            b2bSiebelId:this.data.value.b2bSiebelId,
            shipToSiebelId:this.data.value.shipToSiebelId,
            freeFreight: this.freeFreight
          }
        }
      });
    }
  });
  }

}
