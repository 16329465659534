import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { SiteContextConfig, WindowRef } from '@spartacus/core';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { ReusableAddressFormComponent } from 'src/app/shared/components/reusable-address-form/reusable-address-form.component';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { CustomValidationService } from 'src/app/shared/services/common/custom-validation.service';
import { SmartyAddressValidationService } from 'src/app/shared/services/common/smarty-address-validation.service';
import { ValidateCardConnectTokenService } from 'src/app/shared/services/common/validate-card-connect-token.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { googleSiteKeys } from 'src/environments/environment';
declare let grecaptcha: any;

@Component({
  selector: 'app-add-edit-payment-type',
  templateUrl: './add-edit-payment-type.component.html'
})
export class AddEditPaymentTypeComponent implements OnInit,OnDestroy {

  
  @ViewChild(ReusableAddressFormComponent, { static: true })
  public addressFormComponent!: ReusableAddressFormComponent;

  public currentTheme: any;
  createAddEditCardForm:any;
  months:string[];
  yearRange:string[];
  showAddAddress:boolean = false;
  siteData:any;
  smartyKeyValForAddressValidation:any;
  dataFromRecommendedDialogEditButton:any;
  themesForScroll: any = this.document.getElementsByClassName('cdk-global-overlay-wrapper');
  billingAddress:string;
  toggleOn=false;
  authSubEvent:any;
  toggleStatus:string="Off";
  showSuccessMsg:boolean;
  showFailureMsg:boolean;
  smartyAuthInstance: any;
  recommendedInstance:any;
  headerServiceInstance:any;
  addressUpdated:boolean = false;
  cardToken:string='';
  paymentDetailsID:string;
  cardType:string='';
  showAddressSuccess:boolean = false;
  showAddressFailure:boolean = false;
  apiUrl: string;
  userSub: any;
  user: User;
  userId: any;
  siebelRowId: string | undefined;

  //Re-Captcha
  widgetId: undefined;
  recaptchaFlag: any;
  baseSiteName: any;
  baseSiteNameVal: any;
  siteKey: any;
  recaptchaKey: string;
  recaptchaRendered: boolean = false;
  cardToBeRemoved:any;
  cardToBeUpdated:any;
  captchaRequired:any;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddEditPaymentTypeComponent>,
    public dialog: MatDialog,
    private smartyAddressValidationService: SmartyAddressValidationService,
    private headerService:HeaderService,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private customValidator: CustomValidationService,
    private customHttp:BoxoutEndpointService,
    private tokenizeCard: ValidateCardConnectTokenService,
    @Inject(DOCUMENT) private document: Document,
    private store: Store<fromApp.AppState>,
    @Inject(PLATFORM_ID) private platformId: Object,
    private config: SiteContextConfig,
    private windowRef: WindowRef,
    private cdr: ChangeDetectorRef
  ) { 
    this.months = ['01','02','03','04','05','06','07','08','09','10','11','12'];

    let currentYear = new Date().getFullYear();
    this.yearRange = [];

    this.yearRange.push((currentYear).toString());

    for (var i = 1; i < 11; i++) {
      this.yearRange.push((currentYear + i).toString());
    }

    //Re-Captcha
    this.baseSiteName = this.config?.context?.baseSite;
    if (this.baseSiteName) {
      this.baseSiteNameVal = this.baseSiteName[0];
      // fetching and assigning site keys with the help of base site name
      this.siteKey = googleSiteKeys[this.baseSiteNameVal as keyof typeof googleSiteKeys];
    }
  }

  ngOnInit(): void {
    let localPaymentObj = this.data?.paymentData;
    if (localPaymentObj?.expiryMonth && localPaymentObj?.expiryMonth?.length == 1) {
      localPaymentObj.expiryMonth = ("0" + (localPaymentObj?.expiryMonth)).slice(-2);
    }
    let addressObj:any = {};
    if(localPaymentObj?.billingAddress){
      //create address obj in common address format
     addressObj =  this.commonAddressFormat(localPaymentObj?.billingAddress);
    }else{
      addressObj =  this.commonAddressFormat(this.data?.billingAddress);
    }
    this.createAddEditCardForm = this.formBuilder.group({
      cardNickName: [(localPaymentObj?.cardNickName ? localPaymentObj?.cardNickName:''), [Validators.maxLength(50)]],
      nameOnCard: [(localPaymentObj?.accountHolderName  ? localPaymentObj?.accountHolderName:''),[Validators.required,Validators.maxLength(60)]],
      cardNumber: [(localPaymentObj?.cardNumber  ? localPaymentObj?.cardNumber:''),[Validators.required,Validators.maxLength(20)]],
      expiryMonth: [(localPaymentObj?.expiryMonth  ? localPaymentObj?.expiryMonth:''),[Validators.required]],
      expiryYear: [(localPaymentObj?.expiryYear  ? localPaymentObj?.expiryYear:''),[Validators.required]],
      issueNumber: [(localPaymentObj?.issueNumber  ? localPaymentObj?.issueNumber:''),[Validators.required,Validators.pattern("^[0-9]*$")]],
      address: this.addressFormComponent.createAddressForm(addressObj),
      defaultPayment:[localPaymentObj?.defaultPayment ? localPaymentObj?.defaultPayment : false],
    },
    {
      validator: [
        this.customValidator.expiryCheckValidator(
          'expiryMonth',
          'expiryYear'
        )
      ]
    }
    );

    //set toggleStatus depending upon defaultPayment value
    this.toggleStatus = localPaymentObj?.defaultPayment ? 'On' : 'Off';

    //show correct billing address in case of add or edit
    this.showBillingAddress(this.data);
    //scroll theme for create business dialog
    this.themesForScroll[0].className += " custom-modal";

    const currentSite = this.headerService.getCurrentSite()[0];

     //getting the key for smarty address API validation
     this.headerServiceInstance= this.headerService.localPropertiesConfigData.subscribe((resp:any)=>{
       if(resp){
         this.siteData = resp;
         const smartyKeyVal: any = 'spartacus.config.smarty.key';
         const creditCardCaptahca: any = 'spartacus.config.recaptcha.creditcard.enabled.'+ currentSite;
         this.captchaRequired = resp[creditCardCaptahca];
         this.smartyKeyValForAddressValidation = this.siteData[smartyKeyVal];
       }
       
     });

     this.smartyAuthInstance = this.smartyAddressValidationService.savedFormDataSource.subscribe((data)=>{
      if(data && data.componentRef === "createAddEditCardForm"){
        //create addresss string and show as selected address
        //update returned address as billing address
        this.finalFormSubmit(data);
        
      }
  },(error)=>{
  });


     //when user clicks Edit This Address button on recommened address dialog then 
    //below code populates Create business account form again with prefilled values

    this.recommendedInstance = this.smartyAddressValidationService.receivedRecommendedData$.subscribe((sentData)=>{
      
     //show add edit address dialog
     this.dialogRef.removePanelClass('d-none');
  });

  this.userSub = this.store.select('auth').subscribe(user => {
    this.user = user && user.user!;
    this.userId = this.user?.uid;
    this.siebelRowId = this.user?.orgUnit?.siebelRowId;
  });

    // Google Reacptcha Intialization
    this.widgetId = undefined;
    this.headerService.recaptchaEnableData.subscribe((resp: any) => {
      if (resp) {
        this.recaptchaFlag = resp;
      }
    });

    // For getting basesite name
    this.baseSiteNameVal = (this.config as any)?.context?.baseSite[0];

    // Set the recaptcha keys
    if (isPlatformBrowser(this.platformId) && this.baseSiteNameVal !== 'bxdportal') {
      this.recaptchaKey = googleSiteKeys[(this.config as any)?.context?.baseSite[0] as keyof typeof googleSiteKeys];
    }

    // Render the recaptcha
    if (grecaptcha) {
      let that = this;
      grecaptcha.ready(function () {
        if ((that.widgetId === -1 || that.widgetId === undefined) && !that.recaptchaRendered) {
          that.recaptchaRendered = true;
          that.widgetId = grecaptcha?.render('g-recaptcha', {
            sitekey: that.recaptchaKey,
            callback: that.onSubmitRecaptcha,
            size: 'invisible',
          }, 1000);
        }
      });
    }
    
  }

  //add new billing address
  addNewBillingAddrClick(event:any){
    //
    event.preventDefault();
    this.showAddAddress = true;
    this.addressUpdated=true;
    this.showAddressSuccess = false;
    this.showAddressFailure = false;  

    //reset values of address form fields
    this.createAddEditCardForm.controls.address.reset();

  }

  //add billing address 
  //call address authentication 
  addNewAddrFormClick(event:any){
    event.preventDefault();
    
    //setting form data null 
    this.smartyAddressValidationService.saveFormData(null);
    
    if(this.createAddEditCardForm.controls.address.valid){
      let addressOne= this.createAddEditCardForm.get('address')?.value.addressOne;
      let optionalAddressTwo= this.createAddEditCardForm.get('address')?.value.optionalAddressTwo;
      let cityName= this.createAddEditCardForm.get('address')?.value.cityName;
      let stateIsocode= this.createAddEditCardForm.get('address')?.value.stateName;
      let stateText=this.smartyAddressValidationService.getStateText(stateIsocode);

      let zipCode= this.createAddEditCardForm.get('address')?.value?.zipCode;
      let countryText='United States';

      let componentReference="createAddEditCardForm";

      //close the create businness account form when form is valid and its going for smarty address 
      //validation which either opens recommended address dialog or submits the create businness account form
      this.dialogRef.addPanelClass('d-none');
      //this.renderer.addClass('cdk-overlay-container','d-none');
            //This is used to validate address in Create account form and submit form or it can also open
      //recommened address dialog in case if there are any recommened addresses
      this.smartyAddressValidationService.addressValidation(addressOne,optionalAddressTwo,cityName,stateIsocode,stateText,zipCode,countryText,this.createAddEditCardForm,this.smartyKeyValForAddressValidation,componentReference);
    }else {
      this.createAddEditCardForm.controls.address.markAllAsTouched();
      const firstElementWithError = document.querySelector('.ng-invalid');

      if (firstElementWithError) {
        firstElementWithError.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  //create disply string to show on billing address section
  createDisplayAddress(addressObj:any){
    return  `${(addressObj?.line1 ? (addressObj?.line1 + ', ') : '')}${(addressObj?.line2 ? (addressObj?.line2 + ', ') : '')}`+
    `${(addressObj?.town ? (addressObj?.town + ', ') : '')}`+
    `${(addressObj?.region?.isocode ? ((addressObj?.region?.isocode).replace("USA-","") + ' ') : '')}`+
    `${(addressObj?.postalCode ? (addressObj?.postalCode + ' ') : '')}` + 
    `${(addressObj?.country?.isocode ? (addressObj?.country?.isocode) : '')}`;
  }

  //checkToggle
  checkToggle(e:any):void{
    e.checked?this.toggleStatus="On":this.toggleStatus="Off";
  }

  //on blur of card number validate the card number against card connect
  onBlurCardNumber(e:any):void{
    //check if the field is valid if yes 
    this.cardToken = '';
    this.cardType='';
    let cardNumberControl = this.createAddEditCardForm.get('cardNumber');
    if(e.target.value!=''){
      //check if the number is of correct card type
      let cardType= this.validateCardType(e.target.value);
      if(cardType){
        this.cardType= cardType;
      }else{
        cardNumberControl.setValidators([this.customValidator.validatePaymentCardType(cardType)]);
        cardNumberControl.updateValueAndValidity();
      }
      if(cardType){
        
        this.tokenizeCard.tokenizeCardNumber(e.target.value).subscribe(
          (resp)=>{
            if(resp.errorcode!=0){
              //there is error returned from cardconnect invalidate the cardnumber
              cardNumberControl.setValidators([Validators.required,this.customValidator.validatePaymentCard(resp.token)]);
              cardNumberControl.updateValueAndValidity();
            }else{
              //save token to send it to backend
              this.cardToken = resp?.token;
              cardNumberControl.setValidators([Validators.required,this.customValidator.validatePaymentCard(resp.token)]);
              cardNumberControl.updateValueAndValidity();
            }
          },
          (err:any)=>{
            cardNumberControl.setValidators([Validators.required,this.customValidator.validatePaymentCard('')]);
            cardNumberControl.updateValueAndValidity();
          }
        );
      }

    }
  }

  //validate card type for entered number
  validateCardType(cardNumber:string){
    let amex = new RegExp('^3[47][0-9]{13}$');
    let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
    let mastercard = new RegExp('^5[1-5][0-9]{14}$');
    let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
    let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
    let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');
    let diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');
    
    if (visa.test(cardNumber)) {
      return 'visa';
    }
    if (amex.test(cardNumber)) {
      return 'amex';
    }
    if (mastercard.test(cardNumber)) {
      return 'master';
    }
    if (disco1.test(cardNumber) || disco2.test(cardNumber) || disco3.test(cardNumber)) {
      return 'discover';
    }
    if (diners.test(cardNumber)) {
      return 'diners';
    }

    return undefined;
  }

  //this function shows correct billing address epending upon add or edit selection
  showBillingAddress(dataObj:any){
    //check if add falg is true
    if(dataObj?.addCardFlag || !dataObj?.paymentData?.billingAddress){
      this.billingAddress = this.smartyAddressValidationService.returnFormattedAddress(dataObj?.billingAddress);
    }else{
      //edit card option is selected
      this.billingAddress = this.smartyAddressValidationService.returnFormattedAddress(dataObj?.paymentData?.billingAddress);
    }
  }


  //convert billinf address object to common address which can be sent to reusable address component
  commonAddressFormat(addressObj:any){

    let tempAddrObj = {
      addressOne: addressObj?.line1,
      optionalAddressTwo: addressObj?.line2,
      cityName: addressObj?.town,
      stateName: addressObj?.region?.isocode,
      zipCode: addressObj?.postalCode,
      countryName: addressObj?.country?.isocode,
      id: addressObj?.id
    };

    return tempAddrObj;
  }

  //save address / update information click function 
  updateInfoAddCardClickFunction(cardObj?: any) {
    this.cardToBeRemoved = null;
    if (this.windowRef?.location?.port === '4200') {
      this.addOrUpdateCardFunction(cardObj);
    } else {
      // Added a check if capctha is on and current site is chsite then call recaptcha function to vaidate then update the card details.
      if (this.captchaRequired === 'true') {
        this.cardToBeUpdated = cardObj;
        this.recaptchaFunction();
      } else {
        this.addOrUpdateCardFunction(cardObj);
      }
    }
  }

  addOrUpdateCardFunction(cardObj?:any) {
    let requestBody:any;
    //first check if the form is valid 
    if(this.createAddEditCardForm.controls.nameOnCard.valid && 
      this.createAddEditCardForm.controls.cardNumber.valid &&
      this.createAddEditCardForm.controls.expiryMonth.valid &&
      this.createAddEditCardForm.controls.expiryYear.valid && 
      this.createAddEditCardForm.controls.issueNumber.valid){
      //check if to add or edit the card
      if(this.data?.addCardFlag){
        //create object for add card and make post call
        if(this.data?.cardData && this.data?.cardData.length === 0) {
          this.createAddEditCardForm.value.defaultPayment = true
        }
        requestBody= this.formRequestBody(this.createAddEditCardForm.value,cardObj);
        this.saveAddPaymentCard(requestBody);
      }else{
        //create object for edit card and make post call
        requestBody=this.formRequestBody(this.createAddEditCardForm.value,cardObj);
        this.editPaymentCard(requestBody,cardObj?.id);
      }
    }else{
       //invalid form ,ask user to validate 
       this.createAddEditCardForm.markAllAsTouched();
       const firstElementWithError = document.querySelector('.ng-invalid');

      if (firstElementWithError) {
        firstElementWithError.scrollIntoView({ behavior: 'smooth' });
      }
    }
    
  }

  //add payment 
  saveAddPaymentCard(requestBody:any){
    //make the post call to save new payment method
    let apiUrl=`/users/${this.data?.userId}/paymentdetails`;
    this.customHttp.post(apiUrl,requestBody).subscribe(
      (data:any)=> {
        if(data.success){
        
          this.showSuccessMsg = true;
          this.showFailureMsg = false;
          const resData = { dataUpdated: true ,  showSuccess: this.showSuccessMsg, showFailure: this.showFailureMsg, data: data};
          this.dialogRef.close(resData);
        }else{
          this.showSuccessMsg = false;
          this.showFailureMsg = true;
          var sendData = { dataUpdated: true ,  showSuccess: this.showSuccessMsg, showFailure: this.showFailureMsg}
          this.dialogRef.close(sendData);
        }
      },
      (error:any)=>{
        this.showSuccessMsg = false;
          this.showFailureMsg = true;
          var sendData = { dataUpdated: true ,  showSuccess: this.showSuccessMsg, showFailure: this.showFailureMsg}
        this.dialogRef.close(sendData);
      }
    )
  }

  editPaymentCard(requestBody:any,paymentDetailsId:string){
    //make post call to edit payment method
    let apiUrl=`/users/${this.data?.userId}/paymentdetails/${paymentDetailsId}`;
    this.customHttp.patch(apiUrl,requestBody).subscribe(
      (data:any)=> {
        if(data?.errors?.length>0){
          this.showSuccessMsg = false;
          this.showFailureMsg = true;
          var sendData = { dataUpdated: true ,  showSuccess: this.showSuccessMsg, showFailure: this.showFailureMsg}
        
          }else{
          this.showSuccessMsg = true;
          this.showFailureMsg = false;
          var sendData = { dataUpdated: true ,  showSuccess: this.showSuccessMsg, showFailure: this.showFailureMsg}
         
        }
        this.dialogRef.close(sendData);
      },
      (error:any)=>{
        this.showSuccessMsg = false;
          this.showFailureMsg = true;
          var sendData = { dataUpdated: true ,  showSuccess: this.showSuccessMsg, showFailure: this.showFailureMsg}
        this.dialogRef.close(sendData);
      }
    )
  }

  //removePaymentCard click function to remove card
  removePaymentCard(cardObj?: any) {
    if (this.windowRef?.location?.port === '4200') {
      this.removeCardDetails(cardObj);
    } else {
      // Added a check if capctha is on and current site is chsite then call recaptcha function to vaidate then remove the card details.
      if (this.captchaRequired === 'true') {
        this.cardToBeRemoved = cardObj;
        this.recaptchaFunction();
      } else {
        this.removeCardDetails(cardObj);
      }
    }
  }


  removeCardDetails(cardObj?:any) {
    //make post call to edit payment method
    let apiUrl=`/users/${this.data?.userId}/paymentdetails/${cardObj?.id}`;
    this.customHttp.delete(apiUrl).subscribe(
      (data:any)=> {
         if(data?.errors?.length>0){
           this.showSuccessMsg = false;
           this.showFailureMsg = true;
           var sendData = { dataUpdated: true ,  showSuccess: this.showSuccessMsg, showFailure: this.showFailureMsg, cardRemoved: true}
         
           }else{
           this.showSuccessMsg = true;
           this.showFailureMsg = false;
           var sendData = { dataUpdated: true ,  showSuccess: this.showSuccessMsg, showFailure: this.showFailureMsg, cardRemoved: true}
         
         }
        this.dialogRef.close(sendData);
      },
      (error:any)=>{
        this.showSuccessMsg = false;
          this.showFailureMsg = true;
          var sendData = { dataUpdated: true ,  showSuccess: this.showSuccessMsg, showFailure: this.showFailureMsg, cardRemoved: true}
        this.dialogRef.close(sendData);
      }
    )
  }

  
  // This method is used to form request body which will be sent in create business account POST Call
 formRequestBody(formData:any,cardData?:any): any{
      return {
        accountHolderName:formData?.nameOnCard,
        cardNickName: formData?.cardNickName,
        billingAddress:{
            country: {
              isocode: (formData.address.countryName ? formData.address.countryName : this.data.billingAddress?.country?.isocode)
            },
            line1: (formData.address.addressOne ? formData.address.addressOne : this.data.billingAddress?.line1),
            line2: (formData.address.optionalAddressTwo ? formData.address.optionalAddressTwo : this.data.billingAddress?.line2),
            postalCode: (formData.address.zipCode ? formData.address.zipCode : this.data.billingAddress?.postalCode),
            region: {
              isocode: (formData.address.stateName ? formData.address.stateName : this.data.billingAddress?.region?.isocode)
            },
            town: (formData.address.cityName ? formData.address.cityName : this.data.billingAddress?.town),
            id: (formData  ? (formData.address.id ? formData.address.id : '') : this.data.billingAddress?.id) 
          },
        cardConnectToken: this.cardToken,
        cardNumber: this.data?.addCardFlag ? this.cardToken :  formData?.cardNumber,
        cardType: {
          code: (this.cardType ? this.cardType : cardData?.cardType?.code),
        },
        saved: true,
        defaultPayment: formData?.defaultPayment,
        expiryMonth: formData?.expiryMonth,
        expiryYear: formData?.expiryYear,
        issueNumber: formData?.issueNumber
      }
  
  }


  // This method is used to form request body which will be sent in create business account POST Call
  formAddressRequestBody(body:any): any{

    if(body){
    return {
      address: 
        {
          addressType: body?.addressType,
          attendant: body?.attn,
          country: {
            isocode: body.address.countryName,
            name: "United States"
          },
          defaultAddress: true,
          deliveryNote: body.deliverNote,
          district: body.address.cityName,
          line1: body.address.addressOne,
          line2: body.address.optionalAddressTwo,
          postalCode: body.address.zipCode,
          region: {
            countryIso: body.address.countryName,
            isocode: body.address.stateName,
            isocodeShort: body.address.stateName,
            name: this.smartyAddressValidationService.getStateText(body.address.stateName)
          },
          town: body.address.cityName,
          visibleInAddressBook: true,
          id: body.address.id
        }
    }
    
    }
    
    }
  

   //To submit the form and Add Ship To Account
   finalFormSubmit(formData:any){
    const requestBody=this.formAddressRequestBody(formData);
    this.apiUrl=`users/${this.userId}/orgUnits/${this.siebelRowId}/updateAddress?fields=DEFAULT`;
    this.customHttp.post(this.apiUrl, requestBody.address).subscribe(
      (data:any) => {

        //to close recommended address dialog
        if(this.smartyAddressValidationService.openedDialog){
          this.smartyAddressValidationService.openedDialog.close();
        }

        this.createAddEditCardForm.value.address.id = data.id;
        //to refresh the data  page
        this.dialogRef.removePanelClass('d-none');
        this.billingAddress =this.createDisplayAddress(data);
        this.showAddressSuccess = true;
        this.showAddressFailure = false;
        this.showAddAddress = false;
      },
      (error:any) => {

        //setting form data null for avoiding multiple call 
        this.smartyAddressValidationService.saveFormData(null);

       //   to close recommended address dialog
        if(this.smartyAddressValidationService.openedDialog){
          this.smartyAddressValidationService.openedDialog.close();
        }
        this.showAddressSuccess = false;
        this.showAddressFailure = true;
        this.showAddAddress = true;
      });
   }

  //===============Validate Recaptcha===============
  validateRecaptcha(token: any) {
    let apiURL = `recaptcha?fields=DEFAULT&recaptchaToken=${token}`;
    return this.customHttp.get(apiURL);
  }

  //===============Recaptcha Callback Function===============
  onSubmitRecaptcha = (token: any) => {
    this.validateRecaptcha(token).subscribe((resp: any) => {
      // Add the api call here after catpcha validation
      if(this.cardToBeRemoved) {
        this.removeCardDetails(this.cardToBeRemoved);
        this.cardToBeRemoved = null;
      }

      // Post Captcha validation, Call the Add or Update the card function.
      if(this.cardToBeUpdated || this.data?.addCardFlag) {
        this.addOrUpdateCardFunction(this.cardToBeUpdated);
        this.cardToBeUpdated = null;
      }
      this.recaptchaRendered = true;
      this.cdr.detectChanges();
    });
  };

  //=============== Recaptcha Function ===============
  recaptchaFunction() {
    if (grecaptcha) {
      let that = this;
      grecaptcha?.execute(that.widgetId);
    }
  }

  ngOnDestroy() {
    this.createAddEditCardForm.reset();
    this.smartyAuthInstance.unsubscribe();
    this.recommendedInstance.unsubscribe();
    this.headerServiceInstance.unsubscribe();
    this.addressUpdated=false;
    
  }

}
