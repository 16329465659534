import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { LinkTargetService } from 'src/app/shared/services/common/link-target.service';
import { HeaderService } from 'src/app/shared/services/header.service';
@Component({
  selector: 'app-footer-links-info',
  templateUrl: './footer-links-info.component.html'
})
export class FooterLinksInfoComponent implements OnInit {
  footerInfoLinkArray: any = [];
  setLinkTarget: any;
  currentSite: any = this.headerService.getCurrentSite()[0];
  releaseId: any;
  siteData: any;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private headerService: HeaderService,
    protected cmsService: CmsService,
    private link: LinkTargetService
  ) {
    this.setLinkTarget = link.getTarget;
   }

  ngOnInit(): void {
    this.headerService.localPropertiesConfigData.subscribe((data)=>{
      const siteSpecificReleaseIdKey: any = 'spartacus.config.hybris.release.id.'+ this.currentSite;
      const globalReleaseIdKey: any = 'spartacus.config.hybris.release.id';
      if(data){
        this.siteData = data; // Removing JSON.parse as the API response is changed from string to JSON in 2211
        if(this.siteData[siteSpecificReleaseIdKey]){
          this.releaseId = this.siteData[siteSpecificReleaseIdKey];
        }else{
          this.releaseId = this.siteData[globalReleaseIdKey];
        }        
        this.changeDetectorRef.detectChanges();
      }
    });
    this.getFooterInfoLinksContent();
  }

  /*This method is used to get and store footer info links content*/

  getFooterInfoLinksContent() {
    this.cmsService.getContentSlot('FooterLegalLink').subscribe((data) => {
      this.footerInfoLinkArray=[];
      data.components?.forEach((resp) => {
        this.cmsService.getComponentData(resp.uid + '').subscribe((response) => {
          this.footerInfoLinkArray?.push(response);
          //keeping only required resp into footerInfoLinkArray
          this.footerInfoLinkArray?.splice(5);
        });
      });
    });
  }

}
