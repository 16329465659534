import { Component, OnInit } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { LinkTargetService } from 'src/app/shared/services/common/link-target.service';

@Component({
  selector: 'app-footer-contact-us',
  templateUrl: './footer-contact-us.component.html'
})
export class FooterContactUsComponent implements OnInit {
  socialMediaLinkArray: any = [];
  setLinkTarget: any;
  constructor(protected cmsService: CmsService, private link: LinkTargetService) { 
    this.setLinkTarget = link.getTarget;
  }

  ngOnInit(): void {
    this.getSocialMediaContents();
  }

   /*This method id used to get and store social media contents*/

   getSocialMediaContents() {
    this.cmsService.getContentSlot('FooterSocialMedia').subscribe((data) => {
      this.socialMediaLinkArray=[];
      data.components?.forEach((resp) => {
        this.cmsService.getComponentData(resp.uid + '').subscribe((response) => {
          this.socialMediaLinkArray?.push(response);
          //keeping only required resp into socialMediaLinkArray
          this.socialMediaLinkArray?.splice(4);
        });
      });
    });
  }

}
