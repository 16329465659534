import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ship-to-new-business-account-modal',
  templateUrl: './ship-to-new-business-account-modal.component.html'
})
export class ShipToNewBusinessAccountModalComponent {

  constructor() { }
}
