import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService, WindowRef } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import * as fromApp from 'src/app/core/store/app.reducer';
import { GTMService } from 'src/app/shared/services/common/gtm.service';
import { User } from '../models/user.model';
import { UtilService } from '../services/util.service';
import { AuthGuardService } from './auth-guard.service';
@Injectable({
  providedIn: 'root',
})
export class GtmPageGuard implements CanActivate {
  user: User;
  userSub: Subscription;
  userId: any;
  accessToken: any;
  eventLoggedOutCount: any = 0;
  eventLoggedInCount: any = 0;
  title: any;
  constructor(
    private router: Router,
    protected authService: AuthService,
    private authGuardService: AuthGuardService,
    private windowRef: WindowRef,
    @Inject(PLATFORM_ID) private platformId: Object,
    private store: Store<fromApp.AppState>,
    public gtmService: GTMService,
    protected userAccountFacade: UserAccountFacade, // OOTB spartacus has changes the userService functions to UserAccountFacades in sparatcus 6
    private util: UtilService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // For navigating the route
    if (state.url.includes('contentpage')) {
      this.title = state.url.trim().split('/contentpage/')[1].trim();
    } else if (state.url.includes('categoryCode')) {
      this.title = 'Category Page'
    } else if (state.url.includes('brands')) {
      this.title = 'Brand Page'
    }
    else if (state.url.includes('brand')) {
      this.title = 'Brand Search Result Page'
    }
    else if (state.url.includes('search')) {
      this.title = 'Search Result Page'
    }
    else if ((state.url === '/') || (state.url === '/homepage')) {
      this.title = 'HOME'
    }
    else {
      this.title = state.url.trim();
    }

    // He tried to access while he was not logged in
    this.userSub = this.userAccountFacade.get().subscribe((user: any) => {
      this.accessToken = this.util.getAccessToken();
      
      if (this.windowRef.localStorage?.getItem('isLogoutClicked')) {
        if (this.title === 'HOME' && this.eventLoggedOutCount === 0 ) {
          this.gtmService.setGTMOnLoadData(false, user, decodeURIComponent(this.title));

          this.eventLoggedOutCount++;
          this.eventLoggedInCount = 0;
          this.windowRef.localStorage?.removeItem('isLogoutClicked');
          //this.userSub.unsubscribe();
        }
      } else if (this.windowRef.localStorage?.getItem('isLoggedIn')) {
        let loggedInSource : any = this.windowRef.localStorage?.getItem('loggedInFrom');
        if (this.eventLoggedInCount === 0 && this.windowRef?.location?.pathname?.includes(loggedInSource) && user) {
          this.gtmService.setGTMOnLoadData(true, user, decodeURIComponent(this.title));
          this.eventLoggedOutCount = 0;
          this.eventLoggedInCount++;
          this.windowRef.localStorage?.removeItem('isLoggedIn');
          //this.windowRef.localStorage?.removeItem('loggedInFrom');
          //this.userSub.unsubscribe();
        }
      } else {
        if (user && this.accessToken){ 
          let loggedInSource = this.windowRef.localStorage?.getItem('loggedInFrom');
          if(!loggedInSource) {
            this.gtmService.setGTMOnLoadData(true, user, decodeURIComponent(this.title));
          }
        }
        else if (!user && (!this.accessToken || this.accessToken?.length === 0)) {
          this.gtmService.setGTMOnLoadData(false, user, decodeURIComponent(this.title));
        }
      }
    });

    return true;
  }
}
