<!--This Component displays Footer Logo and copyright contents -->
<div class="d-flex flex-column footer-powered-by-container p-4">
  <div class="container">
    <div class="d-flex flex-row justify-content-sm-between justify-content-center pb-4 pl-3">
      <div class="footer-dist-logo">
        <cx-page-slot position="FooterLogo"> </cx-page-slot>
      </div>

      <div class="d-none d-sm-block footer-powered-logo">
        <cx-page-slot position="FooterPoweredByBoxoutLogo"></cx-page-slot>
      </div>
    </div>

    <div class="footer-powered-by-text pl-3">
      <cx-page-slot position="FooterPoweredByBoxout"></cx-page-slot>
    </div>
  </div>
</div>