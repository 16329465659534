<div class="order-approval-modal">
    <div *ngIf="showApiError">
        <reusable-alert-box [alertType]="'warningAlertBox'"
            [alertMsg]="showApiErrorMsg"></reusable-alert-box>
    </div>
    <div class="d-flex justify-content-end align-items-end">
        <!--commented code will be removed post testing-->
        <!-- <button mat-flat-button color="primary" [mat-dialog-close]="true" class="modal-close order-approval-modal-close">
            <mat-icon>close</mat-icon>
        </button> -->
        <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon" >close</mat-icon>

    </div>
    <div class="d-flex justify-content-center mb-3">
        <div class="t-headline-m">
            {{ 'account.order.orderApproval' | cxTranslate }}
        </div>
    </div>
    <div class="text-center mb-3">
        <div class="t-title-m">
            {{ 'account.order.modalheadingOne' | cxTranslate }}
        </div>
        <div class="t-title-m">
            {{approvalData?.email}}{{ 'account.order.modalHeadingTwo' | cxTranslate }}
        </div>
    </div>
    <div class="text-center mb-3">
        <div class="t-body-s">
            {{ 'account.order.order' | cxTranslate }}{{approvalData?.order}}
        </div>
        <div class="t-body-s">
            {{ 'account.order.po' | cxTranslate }}{{approvalData?.po}}
        </div>
        <div class="t-body-s">
            {{ 'account.order.orderDate' | cxTranslate }}{{approvalData?.date | date: 'dd/MM/yyyy'}}
        </div>
    </div>
    <hr class="order-approval-modal-width">
    <div class="text-center t-title-m mb-3">
        {{ 'account.order.anyNotes' | cxTranslate }}
    </div>
    <div class="order-approval-modal-input">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'account.order.notes' | cxTranslate }}</mat-label>
            <input matInput type="text" autocomplete="off" [(ngModel)]="comments"/>
        </mat-form-field>
    </div>
    <div class="d-flex justify-content-center mb-3">
        <button mat-flat-button type="button" [mat-dialog-close]="true" class="mr-3 order-approval-modal-cancel-button t-label-l">
            {{ 'account.cancel' | cxTranslate }}
        </button>

        <button *ngIf="!directApproval" mat-flat-button color="primary" type="button" class="ml-3 t-label-l" (click)="approval('EDITANDAPPROVE')">
            {{ 'account.order.submitApproval' | cxTranslate }}
        </button>
        <button *ngIf="directApproval" mat-flat-button color="primary" type="button" class="ml-3 t-label-l" (click)="approval('APPROVED')">
            {{ 'account.order.submitApproval' | cxTranslate }}
        </button>
    </div>
</div>