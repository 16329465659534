import { ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SiteContextConfig, WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { CurrentThemeService } from 'src/app/shared/services/common/current-theme.service';
import { GTMService } from 'src/app/shared/services/common/gtm.service';
import { InputValidationService } from 'src/app/shared/services/common/input-validation.service';
import { ProductDetailService } from 'src/app/shared/services/common/product-detail.service';
import { ProductService } from 'src/app/shared/services/common/product.service';
import { QuickOrderService } from 'src/app/shared/services/common/quick-order.service';


@Component({
  selector: 'app-recently-viewed-product',
  templateUrl: './recently-viewed-product.component.html'
})
export class RecentlyViewedProductComponent implements OnInit,OnChanges, OnDestroy {

  quantityInput:any=[];
  imageUrl:any;
  siteConfig:any;
  showInput:any;  
  displayUid: string;
  userId: string;
  userSub: Subscription;
  user: User;
  qtyRequired:boolean=false;
  maxQtyReached:boolean=false;
  orderMultiplerRequired:boolean=false;
  productCodes:any=[];
  products: any=[];
  pdpProduct:any;
  
  themeClass: any;
  productServiceSub:Subscription;
  gaProductArray: any;
  previousRecentlyViewed : any;

  constructor(private customHttp: BoxoutEndpointService,private config: SiteContextConfig,
    private router: Router ,private productService: ProductDetailService,private cdr: ChangeDetectorRef,
    private windowRef: WindowRef,private store: Store<fromApp.AppState>,
    public dialog: MatDialog,public currentTheme: CurrentThemeService,
    private productSer: ProductService,private quickOrderService: QuickOrderService,
    private inputValidationService:InputValidationService , private gtmService: GTMService) {
    this.siteConfig = this.config;
   }
  ngOnInit(): void {
    let productCodeFromRoute = '';
    this.themeClass = this.currentTheme.getCurrentTheme();
    if(this.router.url.includes('productDetails')) {
      productCodeFromRoute = this.router.url.split('/productDetails/')[1];
    }
    this.userSub = this.store.select('auth').subscribe(user => {
      this.user = user && user.user!;
      this.displayUid = this.user?.displayUid!;
      this.userId = this.user?.uid!;
      this.cdr.detectChanges();
    });

    this.imageUrl = this.siteConfig?.backend?.occ?.baseUrl;

    this.productServiceSub=this.productService.getProdutData().subscribe((resp: any) => {
      if(resp?.baseProduct){
        this.pdpProduct=resp?.baseProduct;
      }else{
        this.pdpProduct=resp?.code;
      }
      if(productCodeFromRoute === resp?.baseProduct || productCodeFromRoute === resp?.code) {
        let existingEntries:any = this.windowRef.sessionStorage?.getItem("allEntries");
      
        if(existingEntries === null || existingEntries === undefined){
          existingEntries = [];
          existingEntries?.push(this.pdpProduct);
          this.windowRef.sessionStorage?.setItem("allEntries", existingEntries);
          this.getRecentlyViewedProducts();
        }
        else{
          let existingEntriesArray=existingEntries?.split(',');
          existingEntriesArray?.push(this.pdpProduct);
          this.windowRef.sessionStorage?.setItem("allEntries", existingEntriesArray);
          this.getRecentlyViewedProducts();
        }
      }
      
    });

  }

  //This method is used to get recently viewed products
  getRecentlyViewedProducts(){

  let productCodes=this.windowRef.sessionStorage?.getItem("allEntries");
  let productCodesToArray=productCodes?.split(',');

  //reversing product code to show only recent product 
  productCodesToArray?.reverse();
  
  //removing dublicate product codes
  let removedDublicateProductCodes = productCodesToArray?.filter((c, index) => {
    return productCodesToArray?.indexOf(c) === index;
   });

   //removing opened pdp product from recently viewed products
   removedDublicateProductCodes = removedDublicateProductCodes?.filter((n) => {return n != this.pdpProduct});

   //splicing product codes to show atmost 4 recently viewed product code
   removedDublicateProductCodes?.splice(4);

   let fourRecentlyViewedProductCode=removedDublicateProductCodes?.toString();

   if(fourRecentlyViewedProductCode && fourRecentlyViewedProductCode !== this.previousRecentlyViewed){
    let apiUrl=`/orgProducts/recentlyViewed/${fourRecentlyViewedProductCode}`;
   
    this.customHttp.get(apiUrl).subscribe((res:any)=>{
      if(res){
      let gaObj = {};
      this.products=res;
      this.gaProductArray = [];
      this.products?.forEach((element:any,index :any) => {
        if(element?.variantOptions && element?.variantOptions[0] && (element?.variantOptions[0]?.quantityMultiplier)){
          this.quantityInput[index]  = element?.variantOptions[0]?.quantityMultiplier;
        }else if(element?.variantOptions && element?.variantOptions[0] && !(element?.variantOptions[0]?.quantityMultiplier)){
          this.quantityInput[index]  = 1;
        }

        gaObj = element.gaItems;
        gaObj = { ...gaObj, discount: null, index: index , price: element?.variantsSize === 1 ? element?.price?.value : null , quantity: null, item_list_id: 'featured_list', item_list_name: 'Featured List'};
        this.gaProductArray.push(gaObj);
        gaObj ={};
      });

      this.setRecentlyViewedGA4Data();
      this.cdr.detectChanges();
      }
    },
    (error:any)=>{
      
    })

    this.previousRecentlyViewed = fourRecentlyViewedProductCode;
   }

  }

  //This method is used to add selected recently viewed product to cart
  addTocart(product: any, index:any){
    this.showInput = product?.code;
    if (
      this.quantityInput[index] === 0 ||
      this.quantityInput[index] === '' ||
      this.quantityInput[index] === null ||
      this.quantityInput[index] === undefined
    ) {
      this.qtyRequired = true;
      this.maxQtyReached = false;
      this.orderMultiplerRequired = false;
    } else if ( (product?.maxAllowedQuantity) && (this.quantityInput[index] > product?.maxAllowedQuantity)) {
      this.maxQtyReached = true;
      this.qtyRequired = false;
      this.orderMultiplerRequired = false;
    } else if (
      product?.variantOptions[0]?.quantityMultiplier &&
      this.quantityInput[index] %
      product?.variantOptions[0]?.quantityMultiplier !==
        0
    ) {
      this.orderMultiplerRequired = true;
      this.maxQtyReached = false;
      this.qtyRequired = false;
    } else {
      this.orderMultiplerRequired = false;
      this.maxQtyReached = false;
      this.qtyRequired = false;

      let entries = [];
      let obj = {
        "product": {
          "code":  product?.variantsSize === 1 ? product?.variantOptions[0]?.code : product?.code
        },
        "quantity": + this.quantityInput[index]
      }
      entries.push(obj);
  
      this.windowRef?.sessionStorage?.removeItem('gaItemsForCartUpdate');
      this.windowRef.sessionStorage?.removeItem('totalForCartUpdate');
      let gaItem : any = {};
      gaItem = product?.gaItems;
      gaItem = { ...gaItem, discount: null, index: null , price: product?.variantsSize === 1 ? product?.price?.value : null , quantity: this.quantityInput[index], item_list_id: 'featured_list', item_list_name: 'Recently Viewed'};
      this.windowRef.sessionStorage?.setItem('gaItemsForCartUpdate' , JSON.stringify([gaItem]));
      this.windowRef.sessionStorage?.setItem('totalForCartUpdate' , JSON.stringify(gaItem?.price * parseInt(gaItem?.quantity)));

       //calling addProductToCart method from cart service
       this.quickOrderService.getCartIdSpecificProduct(obj, 'search', this.userId);
       this.quickOrderService.specificProduct$.subscribe((resp: any) => {
        if (resp?.statusCode == "maxOrderQuantityExceeded") {
          this.maxQtyReached = true;
          this.qtyRequired = false;
          this.orderMultiplerRequired = false;
          this.cdr.detectChanges();
        }
      })

    }
    
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.products.currentValue>changes.products.previousValue){
    this.products=this.products.filter((data:any)=>data?.code!==changes?.products?.currentValue);
    }
  }

    // quantity incremnet and decrement  functionality
    increment(index: any){
      this.productSer.increment(index, this.products,this.quantityInput);
    }
  
    decrement(index: any){
      this.productSer.decrement(index, this.products,this.quantityInput);
      if(this.quantityInput[index] == 0 ){
        this.showInput = null;
      }
    }

    //This method is used to redirect to PDP page 
    navigateToPDP(product: any) {
      // let url: string = this.windowRef.location.origin + decodeURIComponent(path);
      // this.windowRef.location.href = url;
      let gaDataForViewItem = product?.gaItems;
      gaDataForViewItem.item_list_id = 'featured_list';
      gaDataForViewItem.item_list_name = 'Featured List';
      gaDataForViewItem.index = null;
      gaDataForViewItem.price = product?.variantsSize === 1 ? product?.price?.value : null;
      this.gtmService.setSelectItemData(gaDataForViewItem);
      this.router.navigate([product?.url]);
    }

    //This method is used to add product to wishList
    addProductToWishlist(product: any) {
      if(product.variantOptions?.length && product.variantOptions[0].code) {
        let gaObj = product.variantOptions[0].gaItems;
        gaObj = { ...gaObj, discount: null, index: null , price: product?.variantsSize === 1 ? product?.price?.value : null , quantity: 1, item_list_id: 'featured_list', item_list_name: 'Featured List'};
        this.productSer.addProductToWishlist(product?.variantOptions[0]?.code, this.themeClass , gaObj);
      }
    }

    onLoginClick(){
      this.windowRef.localStorage?.setItem('loginClickedFromPDP', 'true');
      this.productSer.onLoginClick(this.themeClass);
    }
    onPaste(event: any) {
      this.inputValidationService.onPaste(event);
    }

    setRecentlyViewedGA4Data() {
      let dataObj:any;
        dataObj = {
          'event': 'view_item_list',
          'ecommerce': {
            'item_list_id': "featured_list",
            'item_list_name': "Featured List",
            items: this.gaProductArray
        }
      }
      this.gtmService.removeDataLayer();
      this.gtmService.writeToDataLayer(dataObj);
    }
    
    ngOnDestroy(): void {
      
      if(this.userSub) {
        this.userSub.unsubscribe()
      }
     
      if(this.productServiceSub){
        this.productServiceSub.unsubscribe();
      }

      this.windowRef.localStorage?.removeItem('loginClickedFromPDP');
    }

}
