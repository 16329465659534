<div class="notify-stock-container">
    <div class="notify-stock-header-border d-flex flex-row justify-content-between align-items-center">
        <div class="t-headerLink-normal">
            {{'notifyStock.title' | cxTranslate }}
        </div>
        <div class="mt-1">
          <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon" color="white">close</mat-icon>
        </div>
    </div>
    <!--  to show the error/success on click of save button on save edit : start-->
   <div *ngIf="notifyMeCallFailure" class="px-3 pt-3 rounded">
    <reusable-alert-box
      [alertType]="'warningAlertBox'"
      alertMsg="{{
        'account.signInSecurityForm.errorAlertMsg' | cxTranslate
      }}"
    ></reusable-alert-box>
  </div>


  <div *ngIf="notifyMeCallSuccess" class="px-3 pt-3 rounded">
    <reusable-alert-box
      [alertType]="'successAlertBox'"
      alertMsg="{{
        'account.signInSecurityForm.successAlertMsg' | cxTranslate
      }}"
    ></reusable-alert-box>
  </div>
  <!--  to show the error/success on click of save button : end-->
    <div class="px-3 mt-3" *ngIf="notifyStockForm">
        <div class="t-productTile-description" [innerHTML]="productData?.name"></div>
        <div class="t-productTile-brand">{{ productData?.brandData?.name }}</div>
        <div class="t-productTile-productGroup">Variant Information</div>
        <div class="t-productTile-productGroup sku-bgcolor">{{'notifyStock.sku' | cxTranslate }} 
            {{code || ''}}</div>
        <div class="t-productTile-description mt-3">{{'notifyStock.notifyYou' | cxTranslate }}</div>
        <form [formGroup]="notifyStockForm">
            <div class="notify-stock-input mt-3">
                <mat-form-field appearance="outline">
                    <mat-label>{{'notifyStock.emaiAddr' | cxTranslate }}</mat-label>
                    <input matInput type="email" class="" formControlName="email" maxlength="75" />
                    <mat-error
                        *ngIf="notifyStockForm?.get('email')?.touched && notifyStockForm?.get('email')?.hasError('required')">
                        {{ "account.emailModal.requiredEmailError" | cxTranslate }}
                    </mat-error>
                  
                  <mat-error class="mt-1" *ngIf="notifyStockForm?.get('email')?.dirty && !(notifyStockForm?.get('email')?.hasError('emailIsInvalid')) && !notifyStockForm?.get('email')?.hasError('required')">{{ "account.emailModal.emailInvalidError" | cxTranslate }}
                  </mat-error>
                </mat-form-field>
            </div>
            <div class="">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>{{'notifyStock.comments' | cxTranslate }}</mat-label>
                    <textarea matInput formControlName="comments" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="3" cdkAutosizeMaxRows="3" maxlength="240"></textarea>
                </mat-form-field>
            </div>
            <div class="mb-3">
                <p class="t-body-s ml-2">{{240 - notifyStockForm?.get('comments')?.value?.length}}
                    {{ "account.createFavoriteList.charactersRemaining" | cxTranslate }}
                </p>
            </div>
        </form>
    </div>
    <div class="notify-stock-action-style p-3">
        <div class="t-productTile-brand">
            <mat-checkbox color="primary" [(ngModel)]="checked">
                {{'notifyStock.notifyMe' | cxTranslate }}
            </mat-checkbox>
        </div>
        <div
        class="sales-rep-call-me-action-style d-flex flex-row justify-content-around p-3"
      >
        <div>
          <button
            class="sales-rep-action"
            mat-flat-button
            color="white"
            type="button"
            [mat-dialog-close]="true"
          >
            {{ "account.createFavoriteList.cancelButton" | cxTranslate }}
          </button>
        </div>
    
        <div>
          <button
            class="sales-rep-action"
            mat-flat-button
            color=""
            type="button"
            (click)="submitNotifyForm()"
            [disabled]="!notifyStockForm?.valid"
          >
            {{ "account.createFavoriteList.saveButton" | cxTranslate }}
          </button>
        </div>
      </div>
    </div>
   
</div>