<div class="promotion-slider py-5 my-4">
  <section class="promotion-slider-header d-flex align-items-center px-3 w-100">
    <div class="promotion-icon-container p-2 rounded-circle">
      <mat-icon class="promotion-icon" svgIcon="promo_green"></mat-icon>
    </div>
    <div class="content-section">
      <div class="promo-text text-capitalize t-mixMatch-heading">
        {{ "cart.promoSlider.promoAndRebate" | cxTranslate }}
      </div>
      <div class="promo-desc t-mixMatch-desc mt-1">
        {{ "cart.promoSlider.youHave" | cxTranslate }}
        {{ eligiblePromoCount || 0 }}
        {{
          ((eligiblePromoCount || 0) > 1
            ? "cart.promoSlider.promosToSelectFrom"
            : "cart.promoSlider.promoToSelectFrom"
          ) | cxTranslate
        }}
      </div>
    </div>
    <!-- <mat-icon
      [mat-dialog-close]="true"
      class="close-btn ml-auto mr-0"
      svgIcon="close_white"
    ></mat-icon> -->
    <mat-icon
      [mat-dialog-close]="true"
      class="close-icon ml-auto mr-0 dialogCloseIcon"
      color="white"
      >close</mat-icon
    >
  </section>

  <section
    class="promotion-slider-header-back d-flex justify-content-end align-items-center p-3"
  >
    <button
      [mat-dialog-close]="true"
      mat-flat-button
      color="primary"
      class="t-label-l"
    >
      {{ "cart.promoSlider.backToCart" | cxTranslate }}
    </button>
  </section>

  <section class="promotion-slider-items px-1">
    <ng-container *ngFor="let promo of orderPromotions">
      <ng-container *ngIf="!promo.promotion?.isCoachForwardPromo"
        [ngTemplateOutlet]="promoItem"
        [ngTemplateOutletContext]="{ $implicit: promo }"
      ></ng-container>
    </ng-container>
  </section>

  <section
    class="promotion-slider-footer d-flex justify-content-end align-items-center p-3 w-100"
  >
    <button
      [mat-dialog-close]="true"
      mat-flat-button
      color="primary"
      class="t-label-l backToCart"
    >
      {{ "cart.promoSlider.backToCart" | cxTranslate }}
    </button>
  </section>
</div>

<ng-template #promoItem let-promo>
  <div class="promotion-slider-item mt-3 p-3">
    <div class="d-flex align-items-center mb-1">
      <div
        class="promotion-icon-container d-inline-flex justify-content-center p-1 rounded-circle"
      >
        <mat-icon class="promotion-icon" svgIcon="applied_promo"></mat-icon>
      </div>
      <div
        class="promotion-type t-promoSlider-heading pl-2 text-capitalize"
        [innerHTML]="promo?.promotion?.promoType"
      ></div>
    </div>
    <div
      class="promotion-name t-promoSlider-name py-1"
      [innerHTML]="promo?.promotion?.title"
    ></div>
    <hr class="separator my-1" />
    <div
      class="promotion-desc t-promoSlider-desc px-2 py-1"
      [innerHTML]="promo?.promotion?.description"
    ></div>
    <hr class="separator my-1" />
    <div
      *ngIf="promo?.promotion?.discount?.formattedValue"
      class="promotion-savings-amount t-promoSlider-amount text-right mt-4"
    >
      {{ promo?.promotion?.discount?.formattedValue }}
      {{ "cart.promoSlider.savingsApplied" | cxTranslate }}
    </div>
    <div
      class="promotion-cta d-flex justify-content-end align-items-center mt-3"
    >
      <button
        *ngIf="promo?.status === 'Applied'"
        mat-flat-button
        color="white"
        class="cancel-promo t-label-l px-2 py-1"
        (click)="
          handlePromotionStatus(
            promo,
            promo?.status === 'Applied',
            'CANCEL',
            promo?.promotion?.hasMixMatchItems
          )
        "
        [disabled]="isLoading"
      >
        {{ "cart.promoSlider.cancelPromo" | cxTranslate }}
      </button>
      <button
        *ngIf="
          promo?.status === 'Applied' && promo?.promotion?.hasMixMatchItems
        "
        mat-flat-button
        color="white"
        class="edit-promo t-label-l"
        (click)="
          handlePromotionStatus(
            promo,
            promo?.status === 'Applied',
            'EDIT',
            true
          )
        "
        [disabled]="isLoading"
      >
        {{ "cart.promoSlider.editPromo" | cxTranslate }}
      </button>
      <button
        *ngIf="promo?.status === 'Available'"
        mat-flat-button
        color="primary"
        class="apply-promo t-label-l"
        (click)="
          handlePromotionStatus(
            promo,
            promo?.status === 'Applied',
            'APPLY',
            promo?.promotion?.hasMixMatchItems
          )
        "
        [disabled]="isLoading"
      >
        {{ "cart.promoSlider.applyPromo" | cxTranslate }}
      </button>
    </div>
  </div>
</ng-template>
