import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SiteContextConfig, WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import * as fromApp from 'src/app/core/store/app.reducer';
import { SelectClientTypeComponent } from 'src/app/shared/components/select-client-type/select-client-type.component';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { CurrentThemeService } from 'src/app/shared/services/common/current-theme.service';
import { InputValidationService } from 'src/app/shared/services/common/input-validation.service';
import { SmartyAddressValidationService } from 'src/app/shared/services/common/smarty-address-validation.service';
import { QuickOrderService } from '../../../../shared/services/common/quick-order.service';
@Component({
  selector: 'app-header-dropdown-links',
  templateUrl: './header-dropdown-links.component.html'
})
export class HeaderDropdownLinksComponent implements OnInit,OnDestroy {
  quickOrderForm: FormGroup;
  isOpen: any = false;
  formError: any = false;
  user: User;
  openAccount=false;
  headerDropDownData=false;
  userSub: Subscription;
  userId: string;
  currentTheme: any;
  recentOrderDate: any;
  orderApprovalCount: any;
  userType: string | undefined;
  baseSiteName: any;
  baseSiteNameVal: any;

  constructor(
    private fb: FormBuilder, 
    private router: Router, 
    private eRef: ElementRef,
    private quickOrderService : QuickOrderService,
    private smartyAddressValidationService: SmartyAddressValidationService,
    private theme: CurrentThemeService,
    public dialog: MatDialog,
    private customHttp:BoxoutEndpointService,
    private store: Store<fromApp.AppState>,
    private inputValidationService:InputValidationService,
    private config:SiteContextConfig,
    private windowRef: WindowRef,
    ) { }

  ngOnInit() {

    this.baseSiteName = this.config?.context?.baseSite;
    if(this.baseSiteName){
      this.baseSiteNameVal=this.baseSiteName[0];
    } 
    
  
    this.currentTheme = this.theme?.getCurrentTheme();
    this.quickOrderService.closeQuickOrderNav.subscribe((resp:any)=>{
      this.isOpen = resp ? false : true;
    })

    this.userSub = this.store.select('auth').subscribe(user => {
      this.user = user && user.user!;
      this.userId = this.user?.uid!;
      this.userType = this.user?.orgUnit?.unitType;
    });

    if(!(this.windowRef.sessionStorage?.getItem('recentOrderDate') && this.windowRef.sessionStorage?.getItem('orderApprovalCount'))){
    this.getOrderHistoryDetails();
    } else{
      this.recentOrderDate=this.windowRef.sessionStorage?.getItem('recentOrderDate');
      this.orderApprovalCount= this.windowRef.sessionStorage?.getItem('orderApprovalCount');
    }
  }
  
  /*
    This function will validate the form fields and if form is valid then add the item in cart or 
    return the error reason for each item.
  */
  onFormSubmit() {
    if(this.userId) {
      this.formError = this.quickOrderService.onFormSubmit(this.userId);
    }
  }
  getArrayControls() {
    return (this.quickOrderForm?.get('quickOrder') as FormArray)?.controls;
  }

  openAccountDropdown(){
    this.openAccount=true;
    this.isOpen=false;
  }


  getOrderHistoryDetails(){
    let apiUrl=`/users/${this.userId}/accountDropdown`
    this.customHttp.post(apiUrl).subscribe((data)=>{
      this.windowRef.sessionStorage?.setItem('recentOrderDate',data?.orderHistoryDate);
      this.windowRef.sessionStorage?.setItem('orderApprovalCount',data?.approvalOrdersCount);
      this.recentOrderDate=data?.orderHistoryDate;
      this.orderApprovalCount=data?.approvalOrdersCount;
      this.headerDropDownData=true;
    })

  }
  openSelectClientTypeDialog() {

    //setting form data null for avoiding multiple call 
    this.openAccount=false;
    this.smartyAddressValidationService.saveFormData(null);

    this.dialog.open(SelectClientTypeComponent, {
      panelClass: [this.currentTheme, 'select-client-type-dialogs-style'],
      autoFocus: false,
      disableClose: true,
    });
  }  
  
  openQuickOrder() {
    this.formError = false;
    this.isOpen = true;
    this.openAccount=false;
    this.quickOrderForm = this.fb.group({
      quickOrder: this.fb.array([])
    });
    this.quickOrderService.initForm(this.quickOrderForm, 5, this.userId);
  }
  expanedQuickOrder() {
    this.isOpen = false;
    this.router.navigate(['/quickOrder']);
  }
  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
      this.openAccount=false;
      this.quickOrderForm?.reset();
    }
  }
  /*
    This function will validate the user should enter only the digits in input field
  */
  keyPressNumbers(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      let val = event.target.value.length>=4 ?  false :  true;
      return val;
    }
  }
  /*
    This function will unsubscribe all the subscription.  
  */
  ngOnDestroy() {
    (this.quickOrderForm?.get('quickOrder') as FormArray)?.clear();
    this.quickOrderForm?.reset();
    if(this.userSub) {
      this.userSub.unsubscribe();
    }
  }
  onPaste(event: any) {
    this.inputValidationService.onPaste(event);
  }
}
