<footer>
  <ng-container *ngIf="showSignupBlock">
    <!-- Commenting to Fix the smart edit issues and BCGI2-115 
       <app-footer-distribution-sign-up></app-footer-distribution-sign-up> 
    -->
    <cx-page-slot position="FooterSignUP"></cx-page-slot> 

  </ng-container>

  <cx-page-slot position="Footer"></cx-page-slot>
  <app-footer-contact-us></app-footer-contact-us>
  <app-footer-powered-by-boxout></app-footer-powered-by-boxout>

  <!-- <app-footer-links-info></app-footer-links-info> -->
  <div class="footer-info-link-container">
    <div class="container p-3 d-flex justify-content-between flex-row">
      <div class="d-flex flex-row align-items-center pl-3">
        <cx-page-slot position="FooterLegalLink"></cx-page-slot>
      </div>
    </div>
  </div>
</footer>
