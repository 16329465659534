import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { SiteContextConfig, WindowRef } from '@spartacus/core';
import { LoginFormComponentService } from '@spartacus/user/account/components';
import { PageLoaderService } from 'src/app/feature/pages/page-loader/page-loader.service';
import { BoxoutEndpointService } from '../../services/common/boxout-endpoint.service';
import { GTMService } from '../../services/common/gtm.service';
import { HeaderService } from '../../services/header.service';
import { LoginService } from 'src/app/core/services/login.service';

@Component({
  selector: 'app-switch-account-dialog',
  templateUrl: './switch-account-dialog.component.html'
})
export class SwitchAccountDialogComponent implements OnInit {

  signIn = this.data?.signInForm; // Passing signing form from login dialog
  accountList = this.data?.b2bUnits?.b2bUnits?.b2bUnitList; //b2bUnitList
  selectedCode : string; // To capture selected b2bUnit
  baseSiteName: any;
  baseSiteNameVal: any;

  constructor(
    public dialogRef: MatDialogRef<SwitchAccountDialogComponent>,
    private customHttp: BoxoutEndpointService,
    protected loginService: LoginService,
    private changeDetectorRef: ChangeDetectorRef,
    private headerService: HeaderService,
    private route:Router,
    private routes: ActivatedRoute,
    private config: SiteContextConfig,
    @Inject(MAT_DIALOG_DATA) public data:any,
    @Inject(DOCUMENT) private document: Document,
    private windowRef: WindowRef,
    private pageLoaderService:PageLoaderService,
    private gtmService: GTMService
  ) { }

  themesForScroll: any = this.document.getElementsByClassName('cdk-global-overlay-wrapper');
    ngOnInit(): void {
    this.themesForScroll[0].className += " custom-modal";
    if(this.accountList.length > 1) {
     this.windowRef.localStorage?.setItem('multipleB2bUnitFlag', 'true');
    } else {
      this.windowRef.localStorage?.setItem('multipleB2bUnitFlag', 'false');
    }
    this.baseSiteName = this.config?.context?.baseSite;
    if(this.baseSiteName){
      this.baseSiteNameVal=this.baseSiteName[0];
    }
  }

    //===============Login Service===============
    onSubmit() {
      this.windowRef.localStorage?.setItem('isLoggedIn' , 'true');
      this.windowRef.localStorage?.setItem('loggedInFrom', this.windowRef?.location?.pathname ? decodeURIComponent(this.windowRef.location.pathname) : '');
      this.loginService.form = this.signIn;
      this.loginService.login();
      //this.gtmService.setLoginData(); change done to send the user obj in datalayer
      this.dialogRef.close();
      this.windowRef.sessionStorage?.setItem('loginSource', 'true');

    // save current route first
    const currentRoute = this.route.url.split('?');
    //Checking search route
    /** when we need basesite, language and locale in the site URL */
    //if(currentRoute[0] == `${this.config?.context?.baseSite}/${this.config?.context?.language}/${this.config?.context?.currency}/search`){
      
      /** when we dont need basesite, language and locale in the site URL -- this will not work in local*/
      if(currentRoute[0] == `/search`){
      //re rendering current route for price call
      let text = this.routes.snapshot.queryParamMap.get('text');
      if(!this.route.url?.includes('/login')) {

        this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.route.navigate(['/search'],{
            queryParams:{
              text: text
            }
          });
      });
      }
    } 
    //Navigate to base page if logged in user is on registerpage 
    /** when we need basesite, language and locale in the site URL */
    //else if(currentRoute[0] == `${this.config?.context?.baseSite}/${this.config?.context?.language}/${this.config?.context?.currency}/register`){

    /** when we dont need basesite, language and locale in the site URL -- this will not work in local*/
    else if(currentRoute[0] == `/register`){
      this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.route.navigate(['/']);
      });
    }

    else if(this?.data?.navigateToUrl) {
      this.route.navigateByUrl('/').then(() => {
        this.route.navigate([this?.data?.navigateToUrl]);
      });
    }

    else if(this.windowRef?.document?.getElementById('loginRedirect')) {
      this.route.navigateByUrl('/').then(() => {
        this.route.navigate([this.document?.getElementById('loginRedirect')?.getAttribute('data-loggedInUrl')]);
      });
    }
    
    else {
      const goToUrl = this.windowRef?.localStorage?.getItem('goToUrl');
      const emailUrl =this.windowRef?.localStorage?.getItem('isEmailUrl')
      if (goToUrl) {
        if(!this.windowRef?.location?.href?.includes('portal.myboxout')) {
          this.route.navigateByUrl(goToUrl, { skipLocationChange: true }).then(() => {
            this.route.navigate([goToUrl]);
          }); 
        } else {
          this.route.navigate(['/']);
        }

      }
      // Can be needed in future
      // else {
      //   if(!emailUrl){
      //   this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      //     this.route.navigate(['/']);
      //    }); 
      //   }
      // }
    }
   

    }

    //===============Select Account===============
    onSelectAccountApi(code: any) {
      let selectUrl = 'setB2bUnitId';
      this.windowRef.localStorage?.setItem("b2bUnit", code); 
      // Saving the b2bUnit in session storage to send it to BE in all http request headers
      return this.customHttp.put(selectUrl,{uid: code});

    }

    //===============On Continue Click===============
    onContinueClick(){
      if(this.selectedCode !== undefined) {
        this.onSelectAccountApi(this.selectedCode).subscribe((res:any)=>{
            if(res == null){
              this.onSubmit();
              this.changeDetectorRef.detectChanges();
            }
        },(error)=>{
          if(this.windowRef.localStorage?.getItem('loginClickedFromPDP') === 'true'  || this.windowRef.localStorage?.getItem('isPDP') === 'true') {
            this.windowRef.localStorage?.removeItem('loginClickedFromPDP');
            this.windowRef.localStorage?.removeItem('isPDP');
            (this.windowRef as any).location.reload();
          }
        }
        
        );
      }
    }
}
