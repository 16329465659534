import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SiteContextConfig, WindowRef } from '@spartacus/core';
import { SearchServiceService } from '../search-service.service';

@Component({
  selector: 'app-search-header',
  templateUrl: './search-header.component.html'
})
export class SearchHeaderComponent implements OnChanges, OnInit {
  sliderOn=false;
  @Input() totalCount: any;
  @Input() showBackTotop:any;
  @Input() showTotalItems:any;
  @Input() sortByFields:any;
  @Input() searchParam='';
  @Input() viewByInput: string;
  @Input() isBrandpage: boolean;
  @Input() brandData: any;
  @Output() selectedSortByFields: EventEmitter<any> = new EventEmitter();
  @Output() searchOnViewBy: EventEmitter<any> = new EventEmitter();
  selectedSortBy= 'Relevancy:DESCENDING';
  viewBy: any = 'grid';
  baseSiteName: any;
  imageUrl: any;
  constructor(private windowRef: WindowRef,private searchService: SearchServiceService,
    private config: SiteContextConfig,
    @Inject(DOCUMENT) private document: Document) { 
    }

  //opens facet side bar in mobile and tab view
  openSideBar(){
    this.sliderOn=true;
    this.searchService.setData(this.sliderOn);
    this.document.body.style.overflowY = 'hidden';
  }
  //on selecting sortby options from header , it emits the selected sortvalue
  onSortBySelected(e:any){
    this.selectedSortByFields.emit(e.value);
  }
  searchOnViewByGrid(e:any){
    this.searchOnViewBy.emit("grid");
    this.viewBy = 'grid';
  }
  searchOnViewByList(e:any){
    this.searchOnViewBy.emit("list");
    this.viewBy = 'list';
  }

  onClickOfBackToTop(){
    this.windowRef.nativeWindow?.scrollTo(0,0);
  }
  ngOnChanges(changes: SimpleChanges){
    if(changes?.searchParam?.currentValue){
      this.selectedSortBy = 'Relevancy:DESCENDING';
    }
    if(changes?.sortByFields?.currentValue){
      this.sortByFields=changes.sortByFields.currentValue;
      this.sortByFields.forEach((element:any)=>{
        if(element.selected){
          this.selectedSortBy = element.code;
        }
      })
    }
    if(changes?.viewByInput?.currentValue) {
      this.viewBy = changes.viewByInput.currentValue;
    }
  }

  ngOnInit() {
    this.baseSiteName = this.config?.context?.baseSite;
    if(this.baseSiteName[0] === 'mmsite'){
      this.viewBy = 'list'
    }

    if (this.windowRef.sessionStorage?.getItem('viewBy')) {
      this.viewBy= this.windowRef.sessionStorage?.getItem('viewBy');
    }
  }
}
