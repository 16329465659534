<div class="portal-bxd-product-nav reusuable__filter-container d-flex flex-column">
  <!-- -- Inventory -- -->
  <div class="section-top select-section"
    *ngIf="navigatedFrom === 'inventory-by-location' || navigatedFrom === 'order-transaction-history' ">
    <div class="nav-menu pl-0 d-flex justify-content-between align-items-baseline">

      <ul class="d-flex li-gap align-items-baseline pl-0 mr-2 flex-wrap" [ngClass]="{'hide-mobile': navlist && navlist.length > 0}">
        <li *ngFor="let item of navlist" (click)="activateItem(item)"
          class="list-unstyled d-flex align-items-center flex-column cursor-pointer">
          <span class="list-item t-bxdPortal-productNavTabBtn">{{navigatedFrom ==='order-transaction-history' ? item.name : item.alias}}</span>
          <div class="btn-color" [ngClass]="currentTab === item.name ? 'active' : ''"></div>
        </li>
      </ul>

      <mat-form-field appearance="outline" class="view-select mr-1 t-bxdPortal-productNavLocDrop portal-bxd-mat-form hide-laptop"
        *ngIf="navlist && navlist.length > 0">
        <mat-label class="t-bxdPortal-productNavLocDrop">{{selectedView}}</mat-label>
        <mat-select panelClass="portal-bxd-panel-class" [(ngModel)]="selectedTab"
          placeholder="" class="t-bxdPortal-productNavLocDrop">
          <mat-option *ngFor="let item of navlist" [value]="item" (click)="activateItem(item)"
            class="t-bxdPortal-productNavLocDrop">{{navigatedFrom ==='order-transaction-history' ? item.name : item.alias}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="view-select t-bxdPortal-productNavLocDrop portal-bxd-mat-form">
        <mat-label class="t-bxdPortal-productNavLocDrop">
          {{ "bxdPortal.productNav.view" | cxTranslate }}</mat-label>
        <mat-select panelClass="portal-bxd-panel-class" [(ngModel)]="selectedView"
          placeholder="{{ 'bxdPortal.productNav.location' | cxTranslate }}" class="t-bxdPortal-productNavLocDrop">
          <mat-option *ngFor="let viewItem of inventoryViewList" [value]="viewItem" (click)="setView(viewItem)"
            class="t-bxdPortal-productNavLocDrop">{{viewItem}}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>
  </div>
  <!-- -- Common For All -- -->
  <div class="section-mid d-flex align-items-center justify-content-between">
    <button class="nav-btn filterBtn customFilterBTNStyle d-flex align-items-center t-bxdPortal-productNavBtn" [matMenuTriggerFor]="filterMenu" [disabled]="!disableActionButtonNoData"
      *ngIf="navigatedFrom !== 'OpenWro' && navigatedFrom !== 'wroList'">
      <mat-icon svgIcon="filter" class="filter-icon"></mat-icon>
      <span class="responsiveSM"> {{ "bxdPortal.productNav.filter" | cxTranslate }} </span>
    </button>
    <mat-menu #filterMenu="matMenu" class="portal-bxd-filter-mat-menu">
      <span class="t-sales-rep-nav filter-label pl-4 d-flex align-items-center">
        {{ "bxdPortal.productNav.filters" | cxTranslate }}
      </span>
      <div *ngFor="let menu of filterList; index as i" (click)="$event.stopPropagation()">
        <button mat-flat-button class="t-bxdPortal-productNavBtn" (click)="toggleFilterMenu(i)"
          class="filter-list-item justify-content-between">
          {{menu.name}}
          <mat-icon svgIcon="chevron_down" iconPositionEnd class="filter-list-icon"></mat-icon>
        </button>
        <div *ngIf="menu.visible">
          <div *ngFor="let subMenu of menu.values; index as j"
            class="sub-menu-div px-3 py-1 d-flex justify-content-between align-items-center">
            <mat-checkbox #cb [checked]="subMenu.selected"
              (click)="toggleFilterItem(i,j,cb.checked,subMenu)">{{subMenu.name}}</mat-checkbox>
            <span class="filter-item-count t-subcategory-normal">{{subMenu.count}}</span>
          </div>
        </div>
      </div>
    </mat-menu>

    <div class="search d-flex justify-content-between pl-3 p-1">
      <input type="text" class="w-100 t-bxdPortal-productNavSearch" [(ngModel)]="searchText"
        placeholder="{{ searchPlaceholder | cxTranslate }}" aria-label="Search Bar"
        autocomplete="off" id="autoSearch" (keydown.enter)="searchClicked()" />
      <span class="header-search-icon cursor-pointer" (click)="searchClicked()"> <mat-icon
          svgIcon="header-search"></mat-icon></span>
    </div>

    <button mat-flat-button color="green" class="nav-btn t-bxdPortal-productNavBtn" [matMenuTriggerFor]="actionMenu" *ngIf="!hideActionFilter" [disabled]="!disableActionButtonNoData">
      {{ "bxdPortal.productNav.action" | cxTranslate }}
      <mat-icon svgIcon="chevron_down_white" class="action-icon" iconPositionEnd></mat-icon>
    </button>
    <mat-menu #actionMenu="matMenu" class="portal-bxd-action-mat-menu">
      <button mat-menu-item *ngIf="canExportSelected" (click)="handleDownload($event, 'exportSelected')" class="justify-content-end">
        <mat-icon svgIcon="export_selected" class="action-icon"></mat-icon>
        {{ "bxdPortal.productNav.expSelected" | cxTranslate }}</button>
      <button mat-menu-item (click)="handleDownload($event, 'exportAll')" class="justify-content-end">
        <mat-icon svgIcon="export_selected" class="action-icon"></mat-icon>
        <span *ngIf="navigatedFrom !== 'order-transaction-history'">{{ "bxdPortal.productNav.expAll" | cxTranslate }}</span>
        <span *ngIf="navigatedFrom === 'order-transaction-history'">{{ "bxdPortal.productNav.exp3Month" | cxTranslate }}</span>
      </button>
      <button *ngIf="showScheduledExport" mat-menu-item (click)="handleDownload($event, 'scheduleExport')" class="justify-content-end">
        <mat-icon svgIcon="calendar" class="action-icon"></mat-icon>
        {{ "bxdPortal.productNav.scheduleExp" | cxTranslate }}</button>
    </mat-menu>

    <button *ngIf="this.navigatedFrom === 'Product' " mat-flat-button color="primary" [disabled]="!disableActionButtonNoData"
      class="nav-btn t-bxdPortal-productNavBtn responsiveSM" [routerLink]="['/newItemSetup']">
      {{ "bxdPortal.productNav.addProduct" | cxTranslate }}
    </button>

    <button *ngIf="this.navigatedFrom === 'Product'" mat-flat-button color="lightBlue"
      class="nav-btn t-bxdPortal-productNavBtn responsiveSM" [matMenuTriggerFor]="viewMenu" aria-label="Product Navigation Button">
      <mat-icon svgIcon="chevron_down_white" class="view-icon"></mat-icon>
    </button>
    <mat-menu #viewMenu="matMenu" class="portal-bxd-view-mat-menu">
      <button mat-menu-item *ngFor="let item of productViewList" (click)="redirectToInventoryFromProduct(item, $event)"
        class="justify-content-end">{{item.name}}</button>
    </mat-menu>
  </div>
  <div class="d-flex justify-content-between align-items-center filter__item-section">
    <div class="d-flex align-items-center">
      <div class="section-bottom d-flex align-items-baseline justify-content-between select-section mr-2"
        *ngIf="navigatedFrom === 'inventory-by-location' && !IsMfgChecked">
        <div class="slide-gap d-flex align-items-baseline">
          <mat-slide-toggle [disabled]="!disableActionButtonNoData" labelPosition="before" [(ngModel)]="isLotChecked" color="primary"
            (change)="lotToggleChanged()"
            *ngIf="!(selectedView===inventoryViewList[1] && (currentTab===navlistStatus[1]?.name || currentTab===navlistStatus[5]?.name))">
            <span class="t-bxdPortal-toggleButtonLabel label">{{ "bxdPortal.inventory.lotView" | cxTranslate }}</span>
          </mat-slide-toggle>
        </div>
      </div>
      <div *ngIf="(this.navigatedFrom === 'Product' || this.navigatedFrom === 'Quarantine' || this.navigatedFrom === 'order-transaction-history' || this.navigatedFrom === 'inventory-by-location' || 
        this.navigatedFrom === 'WarehouseReceipts' || this.navigatedFrom === 'InventoryTransaction' || this.navigatedFrom === 'OpenWro') ; else emptyDiv">
        <div *ngIf="chipVisible" class="d-flex justify-content-between align-items-center searchedChip">
          <span class="t-bxdPortal-productNavTabBtn searchedTextPadding">{{selectedCipText}}</span>
          <span>
            <button (click)="removeSearchedItem()" aria-label="Remove item">
              <mat-icon class="chipRemoveIcon" svgIcon="chipClose"></mat-icon>
            </button>
          </span>
        </div>
      </div>
      <ng-template #emptyDiv>
        <div></div>
      </ng-template>
    </div>
    <div class="section-bottom d-flex align-items-baseline justify-content-between select-section sortRight">
      <mat-form-field *ngIf="isSort" appearance="outline" class="view-select t-bxdPortal-productNavLocDrop width-fit-content">
        <mat-label class="t-bxdPortal-productNavLocDrop">{{ "bxdPortal.productNav.sort" | cxTranslate }}</mat-label>
        <mat-select [disabled]="!disableActionButtonNoData" panelClass="portal-bxd-panel-class" placeholder="{{ 'bxdPortal.productNav.sku' | cxTranslate }}"
          class="t-bxdPortal-productNavLocDrop" [(value)]="selectedSorting">
          <mat-option *ngFor="let item of sortList" value="item.code" class="t-bxdPortal-productNavLocDrop" 
            (click)="productSortBy(item)" value="{{item.name}}">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="calender d-flex flex-row" *ngIf="navigatedFrom === 'wroList' && statusDropdown?.length">
        <mat-form-field appearance="outline" class="view-select t-bxdPortal-productNavLocDrop width-fit-content">
          <mat-label class="t-bxdPortal-productNavLocDrop">{{ 'bxdPortal.wroList.status' | cxTranslate }}</mat-label>
          <mat-select panelClass="portal-bxd-panel-class" placeholder="{{ 'bxdPortal.wroList.status' | cxTranslate }}"
            class="t-bxdPortal-productNavLocDrop">
            <mat-option *ngFor="let item of statusDropdown" [value]="item.code" class="t-bxdPortal-productNavLocDrop"
              (click)="searchByStatus(item)">{{item?.code}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>      

      <div class="calender d-flex flex-row" *ngIf="calender">
        <mat-form-field appearance="outline" class="view-select t-bxdPortal-productNavLocDrop">
          <mat-label class="t-bxdPortal-productNavLocDrop">{{ 'bxdPortal.inventory.date' | cxTranslate }}</mat-label>
          <mat-select panelClass="portal-bxd-panel-class" placeholder="{{ 'bxdPortal.inventory.date' | cxTranslate }}"
            class="t-bxdPortal-productNavLocDrop" [(value)]="selectedDateRange">
            <mat-option *ngFor="let item of calenderDropdown" value="item.code" class="t-bxdPortal-productNavLocDrop"
              (click)="searchByDate(item)" value="{{item?.name}}">{{item?.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="calender d-flex flex-row" *ngIf="calender">
        <div class="d-flex flex-row t-sr_search-email date-range">
          <mat-date-range-input class="d-flex justify-content-center align-items-center px-2" [formGroup]="calenderForm"
            [rangePicker]="datePicker" [comparisonStart]="calenderForm.value.start"
            [comparisonEnd]="calenderForm.value.end">
            <input matStartDate placeholder="{{ 'bxdPortal.reusuableFilter.startDate' | cxTranslate }}"
              formControlName="start" (dateChange)="clearSelectedValue($event, 'start')">
            <input matEndDate placeholder="{{ 'bxdPortal.reusuableFilter.endDate' | cxTranslate }}"
              formControlName="end" (dateChange)="clearSelectedValue($event, 'end')">
          </mat-date-range-input>

          <mat-datepicker-toggle class="d-flex justify-content-center align-items-center" matIconSuffix
            [for]="datePicker"></mat-datepicker-toggle>
          <mat-date-range-picker #datePicker></mat-date-range-picker>
        </div>
      </div>

      <mat-icon svgIcon="expand" class="expand-icon" *ngIf="isFullScreen===false && !hideFullScreenIcon"
        (click)="toggleScreen(false)"></mat-icon>
      <mat-icon svgIcon="collapse" class="expand-icon" *ngIf="isFullScreen===true && !hideFullScreenIcon"
        (click)="toggleScreen(true)"></mat-icon>
    </div>
  </div>
  <!-- -- Common For All End-- -->

</div>