export const account = {
  account: {
    cancel: 'Cancel',
    saveChanges: 'Save Changes',
    search: 'Search',
    //sign In Security component
    signInSecurityForm: {
      title: 'Sign-In & Security',
      usedId: 'userid:',
      formButtonLabel: ' Save Changes',
      errorAlertMsg: 'Error! Please fix the fields identified that have errors',
      successAlertMsg: 'Done! The information has been updated',
      successAltText: 'Success Green tick box',

      updateUserPassword: {
        title: 'Update user ID Password',
        passwordReenterAlert:
          'Your password is good please reenter in the field below',
        passwordMatched: 'Your password Matches!',
        passwordReady: 'Your password is ready to create!',
        passwordMustMatchTitle: 'Password must match:',
        passwordMustMatchSubtitle: 'Not yet',

        currentPassword: {
          label: 'Current Password *',
          currentPasswordRequired: 'Please provide the current password',
        },
        newPassword: {
          label: 'New Password *',
          newPasswordRequired: 'Please provide a new password',
          creteria: {
            label: 'Must contain:',
            char: '8-20 Characters',
            lowerChar: 'At least 1 lower case letter',
            upperChar: 'At least 1 upper case letter',
          },
          optionalCreteria: {
            label: 'And 1 of the following:',
            oneNumber: '1 Number',
            specialChar: 'Special Characters',
          },
        },
        reEnteredNewPassword: {
          label: 'Reenter New Password',
          reEnteredNewPasswordRequired: 'Please confirm your password',
        },
      },
    },
    payment: {
      buttonName: {
        addPaymentType: 'Add New Payment Method',
        paymentType: 'Payment Types',
        addPayment: '+Payment',
        paymentOfPurchases: ' Your wallet for payment of purchases',
        editCard: 'Edit Card',
        defaultPayment: 'Default Payment',
        contactUs: 'Contact Us',
        specialOrdersCard: 'Special Orders Card',
        creationDate: 'Creation Date',
        creditCard: 'Credit card ending in',
        expirationDate: 'Expiration Date',
      },
      addEditCard: {
        addTitle: 'Add Credit or Debit Card',
        editTitle: 'Edit Credit or Debit Card',
        cardInfoTitle: 'Card Information',
        nickname: 'Card Nickname',
        nameOnCard: 'Name on Card',
        nameOnCardRequired: 'Please enter the name on card',
        cardNumber: 'Card Number',
        cardNumberRequired: 'Please enter the card number',
        expDateTitle: 'Expiration Date & CVV',
        monthTitle: 'MM',
        yearTitle: 'YYYY',
        cvv: 'CVV',
        expiryRequired: 'Please select an expiry month/year of your card',
        validExpiryRequired: 'Please enter a valid expiration date',
        billingAddressTitle: 'Billing Address',
        addAddress: 'Add Address',
        defaultCardTitle: 'Set as Default Card',
        addPaymentBtn: 'Save Changes',
        editPaymentBtn: 'Update Information',
        removeCardBtn: 'Remove Card',
        cardTxt: 'Card',
        cardNumberTokenInvalid: 'Card Processing Error: Invalid Token',
        cardTypeInvalid: 'Please enter a valid card number',
        addNewAddressTxt: 'Add New Address',
      },
    },
    accountLeftPanel: {
      userAccountInfo: {
        account: 'ACCOUNT',
        business: 'BUSINESS NAME',
        hi: 'Hi, ',
        loyaltyYear: 'Loyal Account Since  ',
      },
      buttonText: {
        manageButton: 'Manage',
        switchButton: 'Switch',
        manageButtonCaps: 'MANAGE',
      },
    },
    accountUserInfo: {
      representative: 'Your Representative',
      manager: 'National Account Manager',
      contact: 'Contact ',
      contactUs: 'Contact Us',
      customerService: 'Customer Service',
    },
    orderHistoryList: {
      noData: 'You have no order history available',
    },
    ordersHistory: {
      orderHistory: 'Order History',
      orderHistoryDescription: 'Your all orders can be viewed and can be filtered based on the inputs',
      noOfHistory: 'Your 5 Most Recent Orders',
      viewButton: 'View All Order History',
      viewButtonMobile: 'View All',
      orderedVia: "ORDERED via ",
      total:"TOTAL",
      billToAcct: "BILL TO ACCT: ",
      order: "ORDER: ",
      shipTo: "SHIP TO: ",
      po: "PO: ",
      seeDetails: "See Details",
      orderNumber: "Order or PO #",
      accountName: "Account Name",
      orderStatus: "Order Status",
      account: "Account",
      dateRange: "Date Range",
      startDate: "Start Date",
      endDate: "End Date",
      search: "Search",
      cancel: "Cancel",
      advancedSearch: "Advanced Search",
      allAccountsText: "All Accounts",
      allStatusText: "All Status",
      dateRangeHeader: "Date Range",
      startDateHeader: "Start Date",
      endDateHeader: "End Date",
      dateRangeError: 'Please provide a valid date range',
      records: "Records",
      attendant: "Attn: ",
      searchForOrder: "Search For Order"
    },

    //create business account component
    createAccount: {
      clientShippingTitle: 'What type of client are you shipping to?',
      createClientBusinessShipToAddressTitle:
        'Create Client / Business Ship to Address',
      title: 'Create Business Account',
      businessInfo: 'Business Information',
      clientInfo: 'Client Information',
      clientTypeTitle: 'Select Type *',
      clientTypeRequired: 'Please select the client type',
      firstNameTitle: 'First Name *',
      firstNameRequired: 'Please enter your first name',
      lastNameTitle: 'Last Name *',
      lastNameRequired: 'Please enter your last name',
      businessNameTitle: 'Business Name *',
      businessNameRequired: 'Please enter your business name',

      titleRequired: 'Please enter title',
      certifications: 'Certifications',
      businessNumber: '',
      attnTitle: 'Attn',
      phoneNumber: 'Phone Number *',
      phoneNumberRequired: 'Please enter your phone number',
      phoneNumberValid: 'Please enter a valid phone number',
      phoneNumberOrderInfo:
        "We'll contact you in case anything comes up with your account or order.",
      mobileNumber: 'Mobile Number',
      mobileNumberValid: 'Please enter a valid mobile number',
      deliverNotesTitle: 'Deliver Notes',
      cancelA: 'Cancel',
      createA: 'Create Account',
      createShipTo: 'Create Ship To',
      defaultShippingToTitle: 'Default Shipping To',
      defaultBillToTitle: 'Default Bill To',
      saveA: 'Save Changes',
      transactionalEmailTitle: 'Send transactional emails via carrier notifier when a drop shipment ships.',
      createNewUserTitle:'Create New User Account',
      createNewUserDescription:'This user will be created and will have acess to account',
      userNameTitle:'User Name',
      newUserPhoneRequired:'Please enter your Business Number (Primary)',
      securityCredTitle:'Security Credentials',
      securityCredDescription:'Select the security and access this user should have when logging in.',
      newUserSelectRole:'Select Role',
      createUserCredButton:'Create User Credentials',
      addAddress:'ADD',
      clientPhoneNumber: 'Phone Number'
    },
    manageBusinessAcct: {
      manageAcct: 'Manage Account',
      businessInfo: 'BUSINESS INFORMATION',
      acctType: 'Account Type',
      acctNum: 'Account Number',
      businessType: 'Business Type',
      editInfo: 'Edit Information',
      phoneNo: 'PHONE NUMBER',
      phoneNumber: 'Phone Number',
      mobileNumber: 'Mobile Number',
      recieveSms: 'This mobile number is enrolled to recieve SMS Text Messages',
      licensing: 'LICENSING & TAX INFORMATION',
      taxId: 'Tax ID / EIN #',
      medicalLicense: 'Medical License #',
      expires: '|  Expires',
      acctAdd: 'ACCOUNT ADDRESSES',
      acctUsers: 'ACCOUNT USERS',
      createUser: 'Create User',
      editBusinessHead: 'Manage Business Info & Settings',
      editBusinessName: 'Business Name',
      editBusinessType: 'Business Type *',
      editPhoneHead: 'Phone Numbers',
      editOfficeNo: 'Office * ',
      editMobile: 'Mobile',
      sendTransactional:
        'Send transactional emails via carrier notifier when a drop shipment ships.',
      editTaxIdLicenceHead: 'Tax & Medical License',
      editTaxId: 'Tax ID / EIN #',
      editLicenceHead: 'Licensing & Expiration Date',
      editMedicalLicense: 'Medical License',
      editExpirationDate: 'Expiration Date',
      requiredOfficeNo: 'Please provide a office phone number',
      editAccountOwner: 'Account Owner Name',
      editFirstName: 'First Name *',
      editLastName: 'Last Name *',
      editMobileEnrolled:
        'Send transactional emails via carrier notifier when a drop shipment ships.',
      userAcctId: 'Acct ID: ',
      userSubAcctId: 'Sub Acct ID: ',
      userRole: 'Role: ',
      userLastLogin: 'LAST LOGIN: ',

      errorRequiredBusinessType: 'Please select the business type',
      errorRequiredFirstName: 'Please enter your first name',
      errorMaxName: 'Maximum 20 characters allowed',
      errorRequiredLastName: 'Please enter your last name',
      errorRequiredPhoneNumber: 'Please enter your phone number',
      errorValidPhone: 'Please enter a valid phone number',
      errorValidMobile: 'Please enter a valid mobile number',
      errorRequiredTaxId: 'Please enter your tax id',
      errorMaxTaxId: 'Maximum 30 characters allowed',
      errorMaxLicense: 'Maximum 128 characters allowed',
      errorRequiredMedicalLicense: 'Please enter your medical license',
      errorRequiredExpirationDate:
        'Please enter medical license expiration date',
      infoUpdateSuccess: 'Done!  The information has been updated',
      infoUpdateError:
        'Error!  The information was not updated. Please try again',
      // editTitle: 'Title',
      // editMM: 'MM *',
      // editDD: 'DD *',
      // editYY: 'YY *'
      editPhoneNo:'Phone *'
    },
    businessAccounts: {
      primary: 'PRIMARY BUSINESS ACCOUNT',
      businessAccount: '+ Business Account',
      addShipTo: 'Add Ship To',
      orderHistory: 'Order History',
      viewAddresses: 'View Addresses',
      hideAddresses: 'Hide Addresses',
      manageUsers: 'Manage Users',
      manageAcct: 'Manage Acct',
      acct: 'Acct',
      created: 'Created',
      id: 'ID',
      subAccountTo: 'Sub Account To',
      viewOrderHistory: 'View Order History',
      createShipment: 'Create Shipment',
      primaryShipTo: 'Primary Ship To',
      primaryBillTo: 'Primary Bill To',
      editAddress: 'EDIT ADDRESS',
      mainAccountSubHeader: 'Your Main account',
      businessAccounts: 'My Business Accounts',
      businessOwns: 'Accounts your business owns & operates',
      infoUpdateError:
        'Error!  The information was not updated. Please try again',
      infoUpdateSuccess: 'Done!  The information has been updated',
    },
    userId: {
      userId: 'User ID & Preferences',
      userIdLogin: 'UserID Login',
      userIdHeading: 'USER ID',
      edit: 'Edit',
      password: 'Password',
      changePassword: 'Change Password',
      notificationHeading: 'Transaction Email Notifications',
    },
    emailModal: {
      heading: 'Primary Email/ Username',
      subHeading: 'CURRENT EMAIL ADDRESS',
      emailLabel: 'Email *',
      emailRequiredError: 'Please provide the new email id',
      emailInvalidError: 'Please enter a valid email address',
      emailAlreadyExists: 'An account already exists for this email address',
      reEnterEmailLabel: 'Reenter Email *',
      reEnterEmailRequiredError: 'Please confirm your email',
      reEnterMisMatchError: 'Email address entered do not match',
      toolTip: 'IF YOU CHOOSE TO CHANGE YOUR EMAIL YOU',
      toolTip2: 'MUST USE YOUR PASSWORD TO SET THIS.',
      passwordLabel: 'Password',
      passwordRequired: 'Please provide your password',
      validPassword: 'Please enter your valid current password',
      saveChanges: 'Save Changes',
    },
    clientAndAddress: {
      clientAndAddressTitle: 'My Clients & Addresses',
      clientAndAddressSubTitle: 'Clients you drop ship products to',
      clientDropShipAccount: '+ Client Drop Ship Account',
      ribbenHeading: 'Client Drop Ship Account',
      searchPlaceholder: 'Search for Client, Addresses',
    },
    manageClientAccount: {
      manageAcct: 'Manage Client Account',
      acctTxt: 'Account',
      clientInfo: 'CLIENT INFORMATION',
      acctType: 'Account Type',
      phoneNumberSecTitle: 'PHONE NUMBER',
      phoneNumber: 'Phone Number',
      mobileNumber: 'Mobile Number',
      editInfoBtnTxt: 'Edit Information',
      acctAddressTxt: 'ACCOUNT ADDRESSES',
      editPhoneHead: 'Phone Numbers',
      editOfficeNo: 'Office * ',
      editMobile: 'Mobile',
      errorRequiredPhoneNumber: 'Please enter your phone number',
      errorValidPhone: 'Please enter a valid phone number',
      errorValidMobile: 'Please enter a valid mobile number',
      editMobileEnrolled:
        'Send transactional emails via carrier notifier when a drop shipment ships.',
        editPhoneNo:'Phone'
    },
    searchResults: {
      header: 'Search results for ',
      totalItems: 'total items',
      allFilters: 'All Filters',
      compare: 'Compare 3',
      viewBy: 'View By',
      featured: 'featured',
      clearFilters: 'Clear Filters',
      selectedFilters: 'Selected Filters',
      filter: 'Filters',
      showMore: 'Show More',
      showLess: 'Show Less',
      backTotop: 'Back to top',
      sortBy: 'Sort By',
    },
    productTile:{
      compare:'Compare',
      item:'Item: ',
      lastPurchased:'Last Purchased: ',
      available:'Available: ',
      inv:' Inv: ',
      inStock:'In Stock',
      readyToShip:' | Ready to Ship',
      outOfStock:'Out of Stock',
      pipe:' | ',
      backOrdered:'Back-Ordered',
      discontinued:'Discontinued',
      specialOrder:'Special Order',
      promo:'Promo',
      addtoCart:'Add to Cart',
      maxQuantity:'MAX QUANTITY REACHED',
      chooseOptions:'Choose Options',
      eta:"ETA ",
      keepMeUpdated:'Notify Me When In Stock',
      callMe:"Have My Rep Call Me",
      viewDetails:'View Product Details',
      viewHoverDetail:'View Details',
      requestConsultation:'Request Free Consultation',
      substitution:'This is no longer available Click Here for Substitutes',
      checkOutOptions:'Although MFG Back-Order Check Out These Options',
      orderInMultiples:'ORDER IN MULTIPLES OF ',
      siteSku:"{{ currentSite }} SKU: ",
      mfgSku:"Mfg SKU: ",
      hcpcs:"HCPCS: ",
      variable:"Variable: ",
      inventoryLevel:"Inventory Levels",
      tx:"TX: ",
      oh:"OH: ",
      wa:"WA: ",
      error:"Error: ",
      cart:"+ Cart",
      calculating:"Calculating...",
      proffesionalPrice:"processing your professional price",
      quantityError:"PLEASE ENTER A QTY",
      multipleError:"PLEASE ORDER IN MULTIPLES OF "
    },
    registerForm: {
      attn:'ATTN',
      headerText: 'Register & Create Account',
      businessNameTitle: 'Business Name *',
      businessOrPracticeType: 'Business / Practice Type *',
      businessOrPracticeTypeRequired: ' Please enter business/practice Type',
      title: 'Title *',
      titleRequired: 'Please enter title',
      certifications: 'Certifications',
      businessNumber: 'Business Number (Primary) *',
      businessNumberRequired: 'Please enter your Business Number (Primary)',
      emailRequired: 'Please enter your Email Address',
      emailNotMatch: 'Email addresses entered do not match',
      businessExt: 'Business Ext',
      cellPhone: 'Cell Phone',
      emailAddress: 'Email Address *',
      reEnterEmailAddress: 'Reenter Email Address *',
      password: 'Password *',
      reEnterPassword: 'Reenter Password *',
      userInfo: 'User Information',
      minMaxError:
        'Please enter minimum 10 character and maximum 14 characters are allowed',
      maxTenError: 'Maximum 10 characters are allowed',
      userID: 'UserID',
      continue: 'Continue',
      yes: 'Yes',
      no: 'No',
      userAlreadyExists: 'This user already exists',
      userRequestedName: 'User Requested :',
      twoOptions: 'Two options',
      likeTodo: 'What would you like to do?',
      fewOptions: 'You do have a few options',
      contactSales: 'I would like to contact a sales representative',
      myUserId: 'This is my User ID',
      prevScreen: 'Go back to previous screen',
      loginNow: 'I want to login now',
      forgotAndReset: 'I forgot my password need to reset it',
      setUpnewAccount: 'I need to setup another account with this userID',
      anotherAccountRequest: 'Another account request form',
      contactSupport: 'Contact Customer Support',
      yourInfo: 'Your Information',
      thankYou: 'Thank you for contacting us',
      infoSubmit:
        'The information above will be submitted to customer service to notify them that you are looking for user creation support',
      accountSubmit:
        'The information above will be submitted to customer service to notify them that you are looking to create another business account',
      notes: 'Notes :',
      supportCall: 'I would like support to call me so i can access my account',
      back: 'Back',
      submitRequest: 'Submit Request',
      closeScreen: 'Close Screen',
      recaptchaText:
        'Invisible reCAPTCHA by Google Privacy Policy and Terms of Use.',
      tel: 'Tel :',
      yourUserInfo: 'Your user Information',
      accountDetails: 'Account Details',
      accountCreated: 'Account Created',
      uploadMedical: 'Upload  Medical License or other Tax Documents',
      uploadSize:
        'Please upload any legal documents to allow us to properly setup your account.   (Limit to 256MB)',
      submitCondition:
        'By clicking the Create Account, you agree to the Terms and Conditions and Terms of Sale.',
      salesTax: 'Sales & Use Tax Exemption (Optional) ',
      taxId: 'Tax ID  (Optional) ',
      businessLicense: 'Business / License ',
      browseDoc: 'Browse My Documents',
      businessAddress: 'Business Address',
      specialServices: 'Any other types of specialty services?',
      servicesOffered: 'Service Offered',
      whyBoxoutAccount: 'Why are you creating an account with Boxout',
      bussinessInfoText:
        'In order to have access professional pricing and to purchase certain healthcare products that require a prescription and / or are restricted to only healthcare providers, please submit your license number or state issued license number for you to operate as a healthcare provider.',
      tryAgain: 'Try Again',
      taxOrEIN: 'Tax / EIN # ',
      regdate: 'Date :',
      step3Title: ' we have created an account for ',
      userIdOrEmail: 'User ID / Email Address',
      cellNo: 'Cell Number',
      firstOrLast: 'First & Last Names',
      congratulations: 'Congratulations!',
      accountUpper: 'ACCOUNT',
      busName: 'BUSINESS NAME',
      userIDupper: 'USER ID',
      regError: 'Looks like there is an issue',
      sysError:
        'Due to a system error we are unable to process your request at this time.',
      try: 'Try Again',
    },
    itemPurchaseReport: {
      itemPurchaseActHeader: 'Items Purchase Activity Report',
      itemPurchaseActDesc: 'Your items purchase activity reports can be viewed & downloaded',
      ebillHeader: 'Advanced eBill Report',
      ebillDesc: 'Your eBill reports can be viewed & downloaded',
      reportFormHeader: 'Which activity reports are needed?',
      dateRangeHeader: 'Date Range',
      startDateHeader: 'Start Date',
      endDateHeader: 'End Date',
      accountsHeader: 'Accounts (Location)',
      barndsHeader: 'Brands',
      invoiceHeader: 'Invoice, PO, Order#',
      itemHeader: 'Item #',
      dateRangeError: 'Please provide a valid date range',
      orText: 'Or',
      allText: 'All',
      naText: 'NA',
      modifySearch: 'Modify Search',
      downLoadCSV: 'Download CSV',
      reorder: 'Reorder',
      date: 'Date - ',
      account: ', Account - ',
      dateRange: 'Date Range - ',
      brands: ', Brands - ',
      Order: ', Order - ',
      Item: ', Item - ',
      thru: ' thru '
    },
    itemPurchasetable: {
      queryHeader: 'Query',
      accountHeader: 'Account',
      OrderHeader: 'Order #',
      InvoiceHeader: 'Invoice',
      noData: 'No Records Available',
      notSupported:
        'This page is not supported in mobile devices . Please use Tablet / Laptop / Desktop',
      orderDetails: 'Order Details',
      pod: 'Proof of Delivery',
      reorderPrd: 'Re-order Product',
    },
    favorites: {
      favoritesHeading: 'Favorite Lists',
      createNewList: 'Create new List',
      searchForList: 'Search for List',
      manage: 'Manage',
      addListToCart: 'Add List To Cart',
      createdBy: 'Created By:',
      createdOn: 'Created:',
      sku: 'SKU',
      quantity: 'QTY',
      brand: 'BRAND',
      productDescription: 'PRODUCT DESCRIPTION',
      remainingProducts: 'View {{x}} Additional Products',
      noLists: 'You have not setup any favorite lists',
    },
    createFavoriteList: {
      createNewList: 'Create New List',
      nolistMessage: 'You have not created a list yet. Create one below.',
      listName: 'List Name',
      description: 'Description',
      errorAlertMsg: 'Favorite list already exists.',
      successAlertMsg: 'Favorite list created.',
      editList: 'Edit List',
      createList: 'Create New List',
      somethingWentWrongMsg: 'Sorry, something went wrong.',
      cancelButton: 'Cancel',
      enterListName: 'Please enter the List Name',
      noWishListsMsg: 'You have not created a list yet. Create one below.',
      advancedListFeatures: 'Advanced List Features',
      deleteList: 'Delete List',
      share: 'Share',
      lock: 'Lock',
      saveButton: 'Save',
      charactersRemaining: ' Characters Remaining',
      warningMsgOne: 'You are requesting to remove ',
      warningMsgTwo: ' item(s). Are you sure you want to do this.',
    },
    wishLists: {
      productAlreadyExistsInList: 'Product exists in the selected list.',
      somethingWentWrongMsg: 'Sorry, something went wrong.',
      cancelButton: 'Cancel',
      saveButton: 'Save',
      createNewList: 'Create A New List',
      addToListHeader: 'Add to List',
      copyToListHeader: 'Copy to List',
      success: 'Success',
    },
    manageFavoriteList: {
      warningTitle: 'warning',
      editTitle: 'Edit',
      lastUpdateTitle: 'Last Update:',
      listCount: 'items in List',
      sortTitle: 'Sort',
      addSelectedToCart: '+ Add Selected To Cart',
      removeTitle: 'Remove |',
      copySelectedToTitle: 'Copy Selected To |',
      updateAllTitle: 'Update All',
      notes:
        'Note: Boxout Product availability is real-time basis and adjusted continuously to ensure optimal customer satisfaction. The product you have created in this list will be reserved for you when you complete your order.',
      allSelectedItemUpdatedSuccessMsg:
        'Done!  Your have successfully Updated All Your Selected Items',
      allSelectedItemRemovedSuccessMsg:
        'Done!  Your have successfully Removed All Your Selected Items',
        copySelectedToFavoriteToExistingListSavedSuccessMsg : 'Done! Your have successfully Added All Your Selected Items',
        atTheRate:' @ '
    },
    favoriteProduct: {
      priceTitle: 'Your Price',
      updateTitle: 'Update',
      inventoryStatusTitle: 'Inventory Status',
      inStockTitle: ' In Stock',
      outOfStockTitle: 'Out of Stock',
      discontinuedTitle: 'Discontinued',
      specialOrderTitle: 'Special Order',
      backOrderedTitle: 'Back-ordered',
      skuTitle: 'SKU:',
      mfgTitle: '| MFG #:',
      perItemTitle: '/ Box',
      quantityTitle: 'PLEASE ENTER A QTY',
      maxQuantityTitle: 'MAX QTY REACHED',
      quantityMultiplierTitle: 'ORDER IN MULTIPLES OF ',
      addedToMsg: 'Added to ',
      existsInMsg: 'Exists in ',
      failMsg: 'Fail',
      imageText: 'fav product',
      singleItemUpdatedSuccessMsg:
        'Done!  Your have successfully Updated Item: ',
      singleItemRemovedSuccessMsg:
        'Done!  Your have successfully Removed Item: ',
      selectTitle: 'Select',
    },
    order:{
      orderPlaced:"Order Placed, Thank you!",
      orderPlacedSub:"Your order is being processed and a confirmation has been emailed to ",
      orderInfo:"Order Information",
      orderNo:"ORDER NUMBER",
      purchaseOrder:"PURCHASE ORDER",
      clientPO:"CLIENT  PO #",
      orderedOn:"ORDERED ON",
      paymentMethod:"PAYMENT METHOD",
      clientSalesOrder:"CLIENT SALES ORDER #",
      orderedVia:"ORDERED VIA:",
      sysOrderId:"SYS ORDER ID:",
      orderedBy:"ORDERED BY",
      orderStatus:"ORDER STATUS",   
      print:"Print",
      email:"Email",
      reOrderItems:"Reorder Items",
      shipTo:"SHIP TO",
      billTo:"BILL TO",
      address:"ADDRESS",
      shippingMethod:"SHIPPING METHOD",
      account:"ACCOUNT",
      details:"Details",
      item:"ITEM#",
      hcpcs:"HCPCS",
      mfg:"MFG ITEM #",
      shipping:"Shipping Enroute",
      price:"Price / Pack",
      qty:"QTY",
      ext:"EXT PRICE",
      orderTotal:"Order Totals",
      subTotal:"Subtotal",
      items:"Items",
      shippingCap:"Shipping",
      taxes:"Taxes",
      orderTotalFinal:"ORDER TOTAL",
      startReturn:"Start Return",
      proofOfDelivery:"Proof of Delivery",
      orderAgain:"Order Again",
      edit:"EDIT",
      promotions:"promotions applied",
      rejected:"rejected",
      modifyCancel:"modified cancelled",
      unapproved:"unapproved",
      cancelled:"cancelled",
      onHold:"on hold",
      suspended:"suspended",
      backordered:"backordered",
      partiallyShipped:"partially shipped",
      approved:"approved",
      open:"open",
      orderRecieved:"order received",
      created:"created",
      processing:"processing",
      pendingApproval:"pending approval",
      shippingStat:"shipping",
      completed:"completed",
      shipped:"shipped",
      singleItemRemovedSuccessMsg:'Done!  Your have successfully Removed Item: ',
      selectTitle:'Select',
      siteId:"SITE ID:",
      lineOfBusiness:"LINE OF BUSINESS",
      remove:"Remove",
      addToOrder:"+ Add to Order",
      editInfo:"Edit Order",
      orderApproval: "Order Approval",
      modalheadingOne:"This order will be approved and the user",
      modalHeadingTwo:" will be notified",
      order:" Order ",
      po:"PO: ",
      orderDate:"Order Date: ",
      anyNotes:"Any Additional Notes?",
      notes:"Notes",
      submitApproval:"Submit Approval",
      modalheadingThree:"This order will be rejected and the user",
      rejectOrder:"Order Rejection",
      rejectClose:"Reject & Close",
      rejectAllow:"Reject & Allow Order To Be Resubmitted",
      quickOrderHeading:"Add Additional Items To Your Order",
      invoice: "Invoice",
      awaitingReturn: "awaiting return",
      reject: "Reject",
      approve: "Approve",
      cancelOrder: "Cancel Order",
      editPO: "Edit PO",
      editPayment: "Edit Payment",
      reviseOrCloseOrder: "Revise or Close Order",
      orderRequiringApproval: "Order Requiring Approval",
      purchaser: "Purchaser",
      approver: "Approver",
      admin: "Admin",
      approvalHeading:"Order has been approved",
      rejectHeading:"Order has been rejected & can be Edited",
      rejectHeadingClosed:"Order Rejected and Closed",
      editOrder: "editorder",
      reqApproval: 'reqApproval',
      close: "Close",
      resubmit: "Resubmit",
      bill: "bill",
      creditCard: "credit card",
      fail: "fail",
      success: "success",
      paymentMethodUpdated: "Payment Method Updated",
      somethingWentWrong: "Something went wrong!"
    },
    userAdmin: {
      pageHeading: 'User Admin',
      pageSubHeading: 'Those who can access your accounts',
      addUserButtonText: 'Create User',
      addUserButtonMobileText: 'User',
      searchBoxPlaceholder: 'UserID Search',
      parentTableHeaders: {
        userId: 'User ID',
        account: 'Account',
        role: 'Role',
        status: 'Status',
        manage: 'Manage',
      },
      childTableHeaders: {
        account: 'Account',
        role: 'Role',
        status: 'Status',
        manage: 'Manage',
      },
      userAccounts: 'USER ACCOUNT',
      userid: 'USERID',
      viewAccounts: 'View Accounts',
      expand: 'Expand',
      collapse: 'Collapse',
      acct: 'Acct',
      manage: 'Manage',
      accessToTheseAccounts: 'ACCESS TO THESE ACCOUNTS',
      paginationOf:'of',
      paginationRecords:'Records'
    },
    manageUser:{
    heading:"Manage User",
    userRole:"User Role Settings",
    selectRole:"Select Role",
    purchasingRestrictions:"Purchasing Restrictions",
    instructionOne:"Do you want to manage this users purchasing behavior? By turning this on you can et purchasing expenditure limits by time period and value, as well as control what products and categories can be purchased without approval",
    periodThreshold:"User Spending Period Threshold",
    instructionTwo:"Control how much a user can spend during a time period for all purchases",
    approvedAmount:"Total Approved Spend Amount:",
    threshholdTime:"Threshold Time Period",
    spendingThreshold:"Unique Order Spending Threshold",
    instructionThree:"Select how much a user is allowed to spend per order",
    approvalThresholdLabel:"Set the approval threshold for order: $",
    orderApprovedTitle:"Require all orders to be approved",
    approvedPara:"Every order submitted by this user will require approval an approving role",
    categoryApproved:"Require orders with these categories to be approved",
    categoryPara:"Any order with products that are in the following categories will require approval",
    userAccounbtAccess:"User Account Access",
    userAccountPara:"Do you want to allow this user to access other accounts that are associated with your business. Doing this will allow this user to create orders and have access to your other accounts",
    cancel:"Cancel",
    maskData:"***************",
    availableCategory:"Available Categories",
    successMessage: "Success! The user has been created"
    },
    categoryListing:{
      categoryListingTitle:'Browse Categories'
    },
    downloadHistory: {
      "downloadHeader": "Download History",
      "downloadSubHeader": "This is the place where you can find any file that you exported from item usage, ebill or POD export",
      "docType": "Document Type: ",
      "user": "USER: ",
      "queryDetails": "Query Details: ",
      "recordCount": "Record Count: ",
      "fileId": "File Id:",
      "fileExpires":"File Expires:",
      "generated": " Generated ",
      "readyToDownload": "Ready to Download",
      "ebillReport": "eBill Report",
      "podBulkExport": "POD Bulk Export",
      "itemUsageReport": "Item usage Report",
      "download": "Download",
      "noData": "No Reports downloaded",
      "status": "Status: "
    },
    youMayLikeProducts:{
      title:'You May Also Like',
      save:'SAVE',
      was:'Was',
      loginTitle:'Login To View Price',
      loginDescription:'please login to access your price'
    },
    recentlyViewedProducts:{
      title:'Products Recently Viewed',
      save:'SAVE',
      was:'Was',
      loginTitle:'Login To View Price',
      loginDescription:'please login to access your price'
    },
    rapidOrder: {
      rapidOrderText: "Rapid Order",
      rapidOrderDescription:  "The fastest way to build an order",
      sku: "SKU: ",
      mfgSku: "MFG SKU: ",
      qty: "QTY",
      rapidOrderSummary: "Rapid Order Summary",
      skuCount: "SKU Count ",
      totalUnits: "Total Units ",
      totalValue: "Total Value ",
      addSelectedToCart: "Add Selected To Cart",
      clearSelection: "Clear Selection",
      disclaimer: "Disclaimer: Your final price & promotions will be calculated in the cart and during checkout",
      yourPrice: "YOUR PRICE",
      extPrice: "EXT PRICE",
      itemsAddedToCart: "Items Added To Cart",
      itemsAddedToCartDescription: "The products you have selected have been added to your cart and are ready for checkout",
      goToCart: "Go to Cart",
      close: "Close",
      noProducts: "No products found for this category",
      orderInMultiples: "ORDER IN MULTIPLES OF {{multiplier}}",
      totalSpend: "TOTAL SPEND",
      plusSelectedToCart: "+ Selected To Cart",
      maxQuantityReached: "MAX QUANTITY REACHED"
    },
    salesRepCallMe:{
      title:'Have my sales rep call me',
      sku:'SKU:',
      description: 'We will notify you at this contact info:',
      phoneNumber:'Phone Number?',
      email:'Email Address?',
      comments:'comments?'
    },
    otherShipToClientPatientAddresses:{
      back:'Back',
      addNewShipToAdd: 'Add New Ship To Address',
      select: 'Select',
      edit: 'Edit'
    },
    shipToClientPatientAddresses:{
      title:'Select Account',
      addNewClientPatientAddress:'Add New Client / Patient',
      shipToThisAddress:'Ship To This Address',
      addAddress:'Address',
      otherAddresses:'Other Addresses',
      records:'Records',
      dropShip:'DROP SHIP |'
    },
    cartShipToModal:{
      title:'Who are you shipping to?',
      clientOrPatient:'Client / Patient',
      myBusiness:'My Business '
    },
    cartShipToBusiness:{
      selectBusiness:"Select Business Account",
      addnewBusiness:"Add New Business Account",
      searchfor:"Search for Client / Patient",
      shipToaddress:"Ship To This Address",
      otherAddress:"Other Addresses",
      addNewShipTo:"Add New Ship To Address",
      select:"Select",
      edit:"Edit",
      address:"Address",
      primary:"PRIMARY",
      subAccount:"SUBACCOUNT",
      add:"ADD"

    },
    po: {
      header: "Edit PO for Order {{ orderNumber }}",
      currentPO: "Current PO",
      revisedPO: "Revised PO",
      saveChanges: "Save Changes",
      cancel: "Cancel",
      equalMessage: "Current PO and Revised PO can not be same",
      currentPOMessage: "Current PO can not be empty",
      revisedPOMessage: "Revised PO can not be empty"
    },
    editPayment: {
      header: "Edit Payment Method for Order {{ orderNumber }}",
      invoice: "Invoice",
      
      creditCard: "Credit Card",
      newCard: "Setup New Card & Process Order",
      saveChanges: "Save Changes",
      cancel: "Cancel",
      setUpNewCard: "Setup New Card",
      errorMsg: "Something went wrong!"
    },
    breadCrumb: {
      advancedeBillReport: "advancedeBillReport",
      userIdAndPreferences: "userIdAndPreferences",
      signInAndSecurity: "signInAndSecurity",
      advancedEbillReport: "Advanced eBill Report",
      userIdPreferences: "User ID & Preferences",
      signInSecurity: "Sign-In & Security"
    },
    restrictedPDPWarningPage:{
      heading:"Product Restricted",
      title:"We’re sorry the product you requested could not be found.",
      descriptionOne:"If you feel this is an error please contact your sales rep or email us at ",
      descriptionTwo:" Try going back to our ",
      descriptionThree:"Homepage ",
      descriptionFour:"or click the 'live Help' button on the top right side of the page and we’ll hellp you find what you need.",
      descriptionFive:" You can also use the search box below to directly find the items you’re looking for.",
      supportEmailOne:"support@",
      supportEmailTwo:".com"
    }
  },
};
