import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SiteContextConfig, WindowRef } from '@spartacus/core';
import { GTMService } from 'src/app/shared/services/common/gtm.service';

interface Product {
  product: {
    baseOptions: [
      {
        selected?: {
          code?: string;
          manufacturerAid?: string;
          variantOptionQualifiers?: { name: string; value: string }[];
        };
      }
    ];
    brandData?: {
      name?: string;
    };
    code?: string;
    name?: string;
    url?: any;
    productImage?: {
      url?: string;
    };
  };
  quantity?: number;
}
@Component({
  selector: 'app-cart-promo',
  templateUrl: './cart-promo.component.html',
})
export class CartPromoComponent {
  @Input() freeProduct: Product;
  @Input() itemNumber: number = 0;
  baseUrl: string;
  imageUrl: string;

  constructor(private config: SiteContextConfig, private windowRef: WindowRef, private route: Router, private gtmService : GTMService) {
    this.imageUrl = (this.config as any)?.backend?.occ?.baseUrl;
    /** when we need basesite, language and locale in the site URL */
    //this.baseUrl = `${this.config?.context?.baseSite}/${this.config?.context?.language}/${this.config?.context?.currency}`;
    
    /** when we dont need basesite, language and locale in the site URL - This will not work in local */
    this.baseUrl = ``;
  }

  navigateToSEO(product: any) {
    // let url: string = this.windowRef.location.origin + path;
    // this.windowRef.location.href = url;
    let gaDataForViewItem = product?.gaItems;
    gaDataForViewItem = { ...gaDataForViewItem, discount: null, index: null , price: product?.price?.value , quantity: null, item_list_id: 'cart_promotion', item_list_name: 'Cart'};
    this.gtmService.setSelectItemData(gaDataForViewItem);
    this.route.navigate([product?.url]);
  }
}
