import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-reusable-menu',
  templateUrl: './reusable-menu.component.html'
})
export class ReusableMenuComponent{


  @Input() public menuOptions: any;
  @Input() public menuIcon: any;


}
