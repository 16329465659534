import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import { LoginDialogComponent } from 'src/app/shared/components/login-dialog/login-dialog.component';
import { CurrentThemeService } from 'src/app/shared/services/common/current-theme.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import * as fromApp from '../../../../core/store/app.reducer';
import { QuickOrderService } from '../../../../shared/services/common/quick-order.service';
import { HeaderSitelinksComponent } from '../header-sitelinks/header-sitelinks.component';

@Component({
  selector: 'app-header-stickybar',
  templateUrl: './header-stickybar.component.html'
})
export class HeaderStickybarComponent implements OnInit, OnDestroy {
  isDesktop: boolean;
  logo: any;
  mobileLogo:any;
  phoneNumber: any;
  user: User;
  themeClass:any;
  siteLinksDialog: any;
  isOpened = true;
  isSticky: boolean = false;
  count: any;
  userSub: Subscription;
  userId: string;
  cartDataSub: Subscription;

  @HostListener('window:scroll', ['$event'])

  checkScroll() {
    var navigationHeight: any = this.document?.querySelector<HTMLElement>('app-custom-category-navigation')?.offsetHeight == undefined ? 0 : this.document.querySelector<HTMLElement>('app-custom-category-navigation')?.offsetHeight;
    var topHeaderHeight: any = this.document.querySelector<HTMLElement>('app-profile-top-header')?.offsetHeight == undefined ? 0 : this.document.querySelector<HTMLElement>('app-profile-top-header')?.offsetHeight; 
    var sitelinksHeight: any = this.document.querySelector<HTMLElement>('app-header-sitelinks')?.offsetHeight == undefined ? 0 : this.document.querySelector<HTMLElement>('app-header-sitelinks')?.offsetHeight; 
    var middleBarHeight: any = this.document.querySelector<HTMLElement>('app-header-middle-bar')?.offsetHeight == undefined ? 0 : this.document.querySelector<HTMLElement>('app-header-middle-bar')?.offsetHeight;
    var height : any = navigationHeight + topHeaderHeight + sitelinksHeight + middleBarHeight;
    this.isSticky = (this.windowRef?.nativeWindow?.scrollY ? (this.windowRef?.nativeWindow?.scrollY >= height) : false);
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private headerService: HeaderService,
    public dialog: MatDialog,
    private router :Router,
    public breakpointObserver: BreakpointObserver,
    public dialogRefere:MatDialogRef<HeaderSitelinksComponent>,
    public currentTheme: CurrentThemeService,
    @Inject(DOCUMENT) private document: Document,
    private windowRef:WindowRef,
    private quickOrderService: QuickOrderService,
    private store: Store<fromApp.AppState>
    ) { }

  ngOnInit(): void {
    this.themeClass = this.currentTheme.getCurrentTheme();
    this.userSub = this.store.select('auth').subscribe(user => { 
      this.user = user?.user!;
      this.userId = this.user?.uid!;
      if(!this.user){
        this.count = null
      }
     });

    this.headerService.headerMobileLogoData.subscribe( response  => {
      this.mobileLogo = response;
    });

    this.headerService.phoneNumberParagraphData.subscribe( response =>{
      this.phoneNumber = response;
    });

    
    // this.headerService.isLoggedIn.subscribe(response => {
    //   this.userLoggedIn = response;
    //   this.cdr.detectChanges();
    // });

    // this.userLoggedIn.subscribe ((data:any) => {
    //   this.loggedInJson = data;
    // });

    this.breakpointObserver.observe(['(min-width: 1024px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isDesktop = true;    
      }
      else {
        this.isDesktop =false;
      }
    });
    this.cartDataSub = this.headerService.addCardCount.subscribe((resp: any) => {
      this.count = resp;
    });
  }

  openSiteLinksDialog() {
    if(!this.user && !this.isDesktop){
      this.siteLinksDialog = this.dialog.open(HeaderSitelinksComponent, {
        position: {
          right: '0px',
          top: '0px'
        },
        panelClass: ['header-links', this.themeClass]
      });
    }
    else if(this.user && this.isDesktop) {
      this.router.navigate(['/my-account']);
    }
    else if(!this.user && this.isDesktop) {
      this.dialog.open(LoginDialogComponent,
        {
          panelClass: [this.themeClass, 'custom-dialog-size'],
          autoFocus: false,
          disableClose: true
        });
    }
    else{
      this.document.body.style.overflowY = 'hidden';
      this.headerService.changeSideNavToggle(this.isOpened);
      this.router.navigate(['/my-account']);
    }
  }
  OpenMiniCart(){
    if(this.userId) {
      this.quickOrderService.openMiniCart(this.userId);
    }
  }

  ngOnDestroy() {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
    if (this.cartDataSub) {
      this.cartDataSub.unsubscribe();
    }
  }

}
