<div class="d-flex flex-column cart-ship-to-style">

  <div class="d-flex justify-content-end">
    <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon" color="white">close</mat-icon>
  </div>

  
  <div class="d-flex flex-column align-items-center justify-content-center text-center p-2">
    <div class="d-flex flex-row mb-4">
      <div>
        <section class="t-headline-m">{{'account.cartShipToModal.title' | cxTranslate }}
        </section>
      </div>
    </div>
  
    <div class="d-flex flex-row align-items-center justify-content-center">
    
      <div class="d-flex flex-column align-items-center justify-content-center mr-3 mr-sm-5" [mat-dialog-close]="true" (click)="openShipToBusinessDrawer()">
  
         <div class="mb-2">
          <mat-icon svgIcon="business_shipTo" class="client-patient-business-shipTo-icon" aria-label="Client or Patient ship To icon"></mat-icon>
        </div> 
        <div class="t-tableData-mobile-content">{{'account.cartShipToModal.myBusiness' | cxTranslate }}</div>
    
      </div>

      <div class="d-flex flex-column align-items-center justify-content-centerml-3 ml-sm-5" [mat-dialog-close]="true" (click)="openShipToClientOrPatientDrawer()">
  
        <div class="mb-2">
         <mat-icon svgIcon="client_patient_shipTo" class="client-patient-business-shipTo-icon" aria-label="Client or Patient ship To icon"></mat-icon>
       </div> 
       <div class="t-tableData-mobile-content" *ngIf="baseSiteNameVal !== 'mesite'" >{{'account.cartShipToModal.clientOrPatient' | cxTranslate }}</div>
       <div class="t-tableData-mobile-content" *ngIf="baseSiteNameVal === 'mesite'" >{{'account.cartShipToModal.memberOrGuest' | cxTranslate }}</div>
 
     </div>
      
    </div>
  </div>

 

</div>