import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  ConfigModule,
  I18nConfig,
  I18nModule,
  provideConfig
} from '@spartacus/core';
import {
  GenericLinkModule,
  NavigationModule,
  PageSlotModule
} from '@spartacus/storefront';
import { BoxoutMaterialModule } from 'src/app/custom/boxout-material/boxout-material.module';
import { AsmHeaderComponent } from 'src/app/shared/components/asm-header/asm-header.component';
import { SigninComponent } from '../../shared/components/signin/signin.component';
import { CustomCategoryNavigationComponent } from './components/custom-category-navigation/custom-category-navigation.component';
import { CustomHamburgerComponent } from './components/custom-hamburger/custom-hamburger.component';
import { HeaderDropdownLinksComponent } from './components/header-dropdown-links/header-dropdown-links.component';
import { HeaderMiddleBarComponent } from './components/header-middle-bar/header-middle-bar.component';
import { HeaderMinicartComponent } from './components/header-minicart/header-minicart.component';
import { HeaderNavigationUiComponent } from './components/header-navigation-ui/header-navigation-ui.component';
import { HeaderSearchbarComponent } from './components/header-searchbar/header-searchbar.component';
import { HeaderSitelinksComponent } from './components/header-sitelinks/header-sitelinks.component';
import { HeaderStickybarComponent } from './components/header-stickybar/header-stickybar.component';
import { MobileNavigationComponent } from './components/mobile-navigation/mobile-navigation.component';
import { ProfileTopHeaderComponent } from './components/profile-top-header/profile-top-header.component';
import { SupportChatComponent } from './components/support-chat/support-chat.component';
import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [
    HeaderComponent,
    CustomCategoryNavigationComponent,
    HeaderNavigationUiComponent,
    ProfileTopHeaderComponent,
    HeaderSitelinksComponent,
    HeaderMiddleBarComponent,
    MobileNavigationComponent,
    CustomHamburgerComponent,
    HeaderSearchbarComponent,
    HeaderDropdownLinksComponent,
    HeaderMinicartComponent,
    SupportChatComponent,
    HeaderStickybarComponent,
    SigninComponent,
    AsmHeaderComponent,
  ],
  imports: [
    //CommonModule,
    PageSlotModule,
    RouterModule,
    GenericLinkModule,
    BoxoutMaterialModule,
    NavigationModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    HttpClientJsonpModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CategoryNavigationComponent: {
          component: CustomCategoryNavigationComponent,
          data: {
            resetMenuOnClose: true,
          },
        },
        SiteLinkUIFlex: {
          component: SigninComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [HeaderComponent],
  // providers: [
  //   provideConfig(<I18nConfig>{
  //     i18n: {
  //       resources: globalTranslations,
  //       chunks: globalTranslationChunksConfig,
  //     },
  //   }),
  // ],
})
export class HeaderModule {}
