<div class="copy-selected-favorite-list-container wishlist-container">
  <div class="wishlist d-flex flex-row justify-content-between align-items-center">
    <div class="t-headerLink-normal">{{ header | cxTranslate }}</div>
    <div class="mt-1">
      <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon" color="white">close</mat-icon>
    </div>
  </div>

  <div>

    <!--  to show the error/success on click of save button on save copy to list : start-->
  <div *ngIf="copyToListFailure" class="px-3 pt-3 rounded">
    <reusable-alert-box
      [alertType]="'warningAlertBox'"
      alertMsg="{{
        'account.signInSecurityForm.errorAlertMsg' | cxTranslate
      }}"
    ></reusable-alert-box>
  </div>


  <div *ngIf="copyToListSuccess" class="px-3 pt-3 rounded">
    <reusable-alert-box
      [alertType]="'successAlertBox'"
      alertMsg="{{
        'account.signInSecurityForm.successAlertMsg' | cxTranslate
      }}"
    ></reusable-alert-box>
  </div>
  <!--  to show the error/success on click of save button : end-->

    <div *ngIf="errorResponse" class="px-3 pt-3 rounded">
      <reusable-alert-box [alertType]="'warningAlertBox'" alertMsg="{{
          'account.wishLists.somethingWentWrongMsg' | cxTranslate
        }}"></reusable-alert-box>
    </div>

    <div *ngIf="productExistsInList" class="px-3 pt-3 rounded">
      <reusable-alert-box [alertType]="'warningAlertBox'" alertMsg="{{
          'account.wishLists.productAlreadyExistsInList' | cxTranslate
        }}"></reusable-alert-box>
    </div>

    <div *ngIf="errors && errors.length" class="px-3 pt-3 rounded">
      <reusable-alert-box *ngFor="let error of errors" [alertType]="'warningAlertBox'" alertMsg='{{error?.message}}' ></reusable-alert-box>
    </div>

    <div class="d-block m-3 create-new-list-style">
      <button mat-flat-button color="white" type="button" class="mr-3" (click)="navigateToCreateNewList()">
        {{ "account.wishLists.createNewList" | cxTranslate }}
      </button>
    </div>

    <mat-radio-group class="example-radio-group" [(ngModel)]="selectedList">
      <div *ngFor="let list of wishlists" class="copy-radio-option-style m-3 cursor-pointer" (click)="setWishList(list.name)">
        <mat-radio-button [value]="list.name" class="ml-2" aria-label="Add to {{list.name}}">
          {{ list.name }}
        </mat-radio-button>
      </div>
    </mat-radio-group>

    <div class="d-flex flex-row justify-content-end copy-action-style ">

      <div class="m-3">
        <button mat-flat-button color="white" type="button" [mat-dialog-close]="true">
          {{ "account.wishLists.cancelButton" | cxTranslate }}
        </button>
      </div>
      <div class="m-3">
        <button mat-flat-button color="primary" type="button" (click)="saveProduct()" [disabled]="!selectedList">
          {{ "account.wishLists.saveButton" | cxTranslate }}
        </button>
      </div>
    </div>

  </div>

</div>