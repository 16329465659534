import { Injectable, OnDestroy } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { Observable, Subject, Subscribable, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { GtmPageGuard } from 'src/app/core/guards/gtm-page.guard';
import { SectionLoaderService } from 'src/app/feature/pages/loader/section-loader.service';
import { HeaderService } from '../header.service';
import { BoxoutEndpointService } from './boxout-endpoint.service';
import { GTMService } from './gtm.service';
@Injectable({
  providedIn: 'root'
})
export class CartService implements OnDestroy {
  subject = new Subject<any>();
  cartSub: any;
  orderId: any;
  editOrderMainSubs: Subscription;

  private cartDataSource = new Subject<any>();
  private editOrderDataSource = new Subject<any>();


  constructor(private customHttp: BoxoutEndpointService, private headerService: HeaderService,
    private windowRef: WindowRef,private sectionLoaderService:SectionLoaderService, private gtmService : GTMService) { }
  getCart(userId: any, isReOrder?: any): any {
    const apiUrl = `users/${userId}/carts?currentPage=0&fields=DEFAULT&pageSize=20&savedCartsOnly=false`;
    if (!this.windowRef.sessionStorage?.getItem('cartId') && userId) {
     this.getLatestCart(apiUrl,userId);
    } else if (this.windowRef.sessionStorage?.getItem('cartId') && userId) {
      if(isReOrder){
        this.getLatestCart(apiUrl,userId);
      } else {
        this.getSpecificCart(userId)
      }
    } else {
      this.headerService.setAddCardCount(null);
    }
  }

  getLatestCart(apiUrl:any,userId:any){
    this.sectionLoaderService.showLoader();
    this.cartSub = this.customHttp.get(apiUrl).subscribe(
      (data: any) => {
        this.sectionLoaderService.hideLoader();
        let cartLength = data.carts.length;
        if (cartLength == 0) {
          this.createCart(userId)
        } else {
          this.windowRef.sessionStorage?.setItem('cartId', data.carts[0].code);
          this.subject.next(data.carts[0].code);
          this.headerService.setAddCardCount(data.carts[0].totalItems);
          let count:any =0;
          if((this.windowRef.localStorage?.getItem('isEmailUrl') === (this.windowRef?.location?.pathname +''+ this.windowRef?.location?.search)) && !count && this.windowRef?.location?.pathname?.includes('cart')) {
            count = 1;
            (this.windowRef as any).location.reload();
          }
        }
      },(error)=>{
        this.sectionLoaderService.hideLoader()
      })
      return this.subject.asObservable();
  }

  /**
   * This function will create a cart for the current user
   */
  createCart(userId: any) {
    this.sectionLoaderService.showLoader();
    const apiUrl = `users/${userId}/carts?fields=DEFAULT`;
    return this.customHttp.post(apiUrl).subscribe(
      (data: any) => {
        this.sectionLoaderService.hideLoader();
        this.windowRef.sessionStorage?.setItem('cartId', data.code)
        this.subject.next(data.code);
        this.headerService.setAddCardCount(data.totalItems);
      },(error)=>{
        this.sectionLoaderService.hideLoader()
      })
  }

  /**
   * This function will return the specific cart details
   */
  getSpecificCart(userId: any) {
    this.sectionLoaderService.showLoader();
    let cartId = this.windowRef.sessionStorage?.getItem('cartId');
    let request = this.windowRef.sessionStorage?.getItem('promotionsMap');
    const apiUrl = `users/${userId}/carts/${cartId}`;
    if (cartId) {
      this.customHttp.post(apiUrl,request).subscribe(
        (data: any) => {
          this.sectionLoaderService.hideLoader();
          if(this.windowRef.sessionStorage?.getItem('gaItemsForRapidOrderUpdate') && this.windowRef.sessionStorage?.getItem('totalForCartUpdate')) {
            let gaItems = this.windowRef.sessionStorage?.getItem('gaItemsForRapidOrderUpdate');
            this.gtmService.addProductsToCart(JSON.parse(gaItems ? gaItems : '') , this.windowRef.sessionStorage?.getItem('totalForCartUpdate'));
            this.windowRef.sessionStorage.removeItem('gaItemsForRapidOrderUpdate');
          }
          this.headerService.setAddCardCount(data.totalItems)
        },(error)=>{
          this.sectionLoaderService.hideLoader()
        })
    }
  }

  /**
   * This function is to get the cart page data
   */
  getCartPageData(uid: any, isValidate:boolean, isReOrder?:boolean, orderId?: any) {
    let cartId = sessionStorage.getItem('cartId');
    if (cartId) {
      this.getCartEnteries(cartId , isValidate, isReOrder,orderId);
    } else {
      this.getCart(uid)?.subscribe((resp: any) => {
        this.getCartEnteries(resp, isValidate, isReOrder,orderId);
      });
    }
  }

  /** Api Call to fetch cart page data */
  getCartEnteries(cartId: any, isValidate: boolean, isReOrder?:boolean, orderId?:any) {
    let apiUrl;
    let isSiebelCallTrue = false;
    // this.sectionLoaderService.showLoader();
    if(isReOrder){
      apiUrl =`users/current/orders/${orderId}/reorder?fields=DEFAULT`;
      this.customHttp.get(apiUrl).subscribe(
        (cart: any) => {
          // this.sectionLoaderService.hideLoader();
          this.cartDataSource.next(cart);
        })
    }
    else{
      let request = this.windowRef.sessionStorage?.getItem('promotionsMap');
      if(!this.windowRef?.sessionStorage?.getItem('isSiebelCallNeeded')) {
        isSiebelCallTrue = true;
        this.windowRef?.sessionStorage?.setItem('isSiebelCallNeeded','true');
      }
      apiUrl = `users/current/carts/${cartId}?validateCart=${isValidate}&isSiebelCallTrue=${isSiebelCallTrue}`;
      this.customHttp.post(apiUrl,request).subscribe(
        (cart: any) => {
          // this.sectionLoaderService.hideLoader();
          this.cartDataSource.next(cart);
          if(this.windowRef.sessionStorage?.getItem('gaItemsForCartUpdate') && this.windowRef.sessionStorage?.getItem('totalForCartUpdate')) {
            let gaItems = this.windowRef.sessionStorage?.getItem('gaItemsForCartUpdate');
            let total = this.windowRef.sessionStorage?.getItem('totalForCartUpdate');
            this.gtmService.addProductsToCart(JSON.parse(gaItems ? gaItems : '') , total);
            this.windowRef.sessionStorage.removeItem('gaItemsForCartUpdate');
            this.windowRef.sessionStorage?.removeItem('totalForCartUpdate');
          }
        })
    }
    
  }

  /** Setter for Cart Page Data */
  setCartData(data: string) {
    this.cartDataSource.next(data);
  }

  /** Getter for Cart Page Data */
  getCartData(): Observable<any> {
    return this.cartDataSource.asObservable().pipe(shareReplay());
  }


  //Order Id for edit order
  setOrderIdData(data: string) {
    this.orderId = data;
  }

  getOrderIdData() {
    return this.orderId;
  }

  //
  setEditOrderData(data: string) {
    this.editOrderDataSource.next(data);
  }

  getEditOrderData(): Observable<any> {
    return this.editOrderDataSource.asObservable();
  }

  getEditOrderEnteries(uid:any, workflowActionCode: any) {
    this.sectionLoaderService.showLoader();
    const apiUrl =`/users/${uid}/edit-order/${this.orderId}/${workflowActionCode}`;
    this.editOrderMainSubs = this.customHttp.get(apiUrl).subscribe(
      (editOrder: any) => {
        this.sectionLoaderService.hideLoader();
        this.editOrderDataSource.next(editOrder);
      },(error)=>{
        this.sectionLoaderService.hideLoader()
      })
  }

  ngOnDestroy() {
    this.cartSub?.unsubscribe();
    if(this.editOrderMainSubs) {
      this.editOrderMainSubs.unsubscribe();
    }
  }
}
