import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {SiteContextConfig } from '@spartacus/core';
import { CurrentThemeService } from '../../services/common/current-theme.service';
import { ShipToBusinessAccountModalComponent } from '../cartShipto-business/ship-to-business-account-modal/ship-to-business-account-modal.component';
import { ShipToClientOrPatientDrawerComponent } from '../ship-to-client-or-patient-drawer/ship-to-client-or-patient-drawer.component';

@Component({
  selector: 'app-cart-ship-to-modal',
  templateUrl: './cart-ship-to-modal.component.html'
})
export class CartShipToModalComponent implements OnInit  {

  currentTheme: any;
  shipToClientOrPatientDrawer:any;
  baseSiteName: any;
  baseSiteNameVal: any;

  constructor(public dialog: MatDialog,private theme: CurrentThemeService, private config: SiteContextConfig,) { }

  ngOnInit(): void {

     /** Taking themes from themeservice **/
     this.currentTheme = this.theme?.getCurrentTheme();
     this.baseSiteName = this.config?.context?.baseSite;
     if (this.baseSiteName) {
       this.baseSiteNameVal = this.baseSiteName[0];
     }
  }



  openShipToClientOrPatientDrawer(){
    this.shipToClientOrPatientDrawer = this.dialog.open(ShipToClientOrPatientDrawerComponent, {
      position: {
        right: '0px',
        top: '0px'
      },
      panelClass: ['wishlist-drawer', this.currentTheme],
      data: {
        value:{
          showSuccessForCart:false,
          showFailureForCart:false
        }
      }
    });
  }

  openShipToBusinessDrawer(){
    this.shipToClientOrPatientDrawer = this.dialog.open(ShipToBusinessAccountModalComponent, {
      position: {
        right: '0px',
        top: '0px'
      },
      panelClass: ['wishlist-drawer', this.currentTheme],
      data: {
        
      }
    });
  }

}
