<div class="order-list">
  <h2 class="order-list-header p-3 mb-3 t-productTile-orderDetailHeader">
    {{ "account.order.details" | cxTranslate }}
  </h2>
  <div class="order-list-tile ml-0 mt-0 ml-md-3 mt-md-2  mb-3" *ngFor="let item of orderEntries;let i=index">
    <div class="d-flex order-list-headerInfo justify-content-between align-items-baseline">
      <div class="d-flex  order-list-container">
        <p class="order-list-slNo m-0">{{i+1}}</p>
        <div class="pl-2 pt-1 pr-4 mr-3">
          <p class="m-0 t-body-s">{{ "account.order.item" | cxTranslate }}</p>

          <p class="m-0 t-title-m cursor-pointer" (click)="navigateToSEO(item)">{{item?.product?.code?
            item.product?.code:''}}</p>
        </div>
        <div class="pt-1 pr-4 mr-3">
          <p class="m-0 t-body-s ">{{ "account.order.mfg" | cxTranslate }}</p>

          <p class="m-0 t-body-l cursor-pointer" (click)="navigateToSEO(item)">
            {{item?.product?.baseOptions[0]?.selected?.manufacturerAid?
            item.product.baseOptions[0].selected.manufacturerAid:''}}</p>
        </div>
        <div class="d-none d-md-block pt-1" *ngIf="baseSiteNameVal !== 'mesite'">
          <p class="m-0 t-body-s">{{ "account.order.hcpcs" | cxTranslate }}</p>

          <p class="m-0 t-body-l cursor-pointer" (click)="navigateToSEO(item)">
            {{item?.product?.baseOptions[0]?.selected?.hcpc? item.product.baseOptions[0].selected.hcpc:''}}</p>
        </div>
      </div>
      <span *ngIf="returnStatus!=='RETURN'" class="order-list-status t-order-status  mb-2">{{item?.status? (item.status| titlecase):'' }}</span>

    </div>
    <div class="order-list-info d-flex flex-column flex-md-row justify-content-around justify-content-md-between ">
      <div class="d-flex justify-content-between order-list-prod pb-3">
        <p class="m-2 order-list-pipe"></p>
        <div class="order-list-product pt-4">
          <div>
            <a (click)="navigateToSEO(item)" class="cursor-pointer"> 
              <img class="prodImageClass" *ngIf="item?.product?.productImage?.url" src="{{imageUrl}}{{item?.product?.productImage?.url}}" alt="mainImage">
              <img class="prodImageClass" *ngIf="item?.product?.productImage?.url === undefined || item?.product?.productImage?.url === '' || !(item?.product?.productImage?.url)"  src="../../../../../assets/MissingImage.svg" alt="MissingImage"/>
            </a>
          </div>
        </div>
        <div class="d-flex pl-0 pl-md-4 pr-3 flex-column">
          <p class="m-0 pt-3 mt-1 t-label-s cursor-pointer" (click)="navigateToSEO(item)">
            {{item?.product?.brandData?.name? item.product.brandData.name:''}}</p>
          <p class="m-0 pb-2 t-title-m cursor-pointer" (click)="navigateToSEO(item)" [innerHTML]="item?.product?.name"></p>
          <div *ngFor="let variant of item?.product?.baseOptions[0]?.selected?.variantOptionQualifiers">
            <ng-container *ngIf="item?.product?.selectableAtrribute?.includes(variant?.qualifier)">
              <p class="t-body-s pb-1 m-0 ">{{variant?.name? variant.name:''}} {{variant?.value? variant.value:''}}</p>
            </ng-container>
          </div>

        </div>
      </div>
      <div class="d-flex pt-md-3 mt-md-1 order-list-prices flex-column">
        <div class="pb-4" *ngIf="item?.productLevelPromotionDetails?.status === 'Applied' && !item?.productLevelPromotionDetails?.isFreeFreightPromo">
          <span class="d-block">
              <span class="save-amount px-2 py-1 mr-2">
                  <span class="t-userid-text-bold">{{ 'account.productTile.save' | cxTranslate }}</span>
                  <span class="t-userid-text pl-1">${{ (item?.basePrice?.value - item?.productLevelPromotionDetails?.adjustedUnitPrice).toFixed(2).replace('.00', '')}}</span>                          
              </span>
              <span class="t-userid-text-bold"> {{ 'account.productTile.was' | cxTranslate }}
                  <span class="t-userid-text-bold">{{ item?.basePrice?.formattedValue }}</span>    
              </span>
          </span>
      </div>
        <div class="d-flex justify-content-end justify-content-md-between mr-2 order-list-prices-sectionOne">
          <div class="mr-4 pr-4 mr-md-0 pr-md-0">
            <p class="t-label-s m-0 pb-1">{{ ("cart.price" | cxTranslate) }} /
              {{item?.product?.baseOptions[0]?.selected?.unit || '-'}}</p>
            <span class="pr-4 pb-4">
              <p class="m-0 px-1 font-weight-bold">
                <sup class="t-price-tile-s">$</sup>
                <span class="t-price-currenyTile">
                  {{item?.productLevelPromotionDetails?.status === 'Applied' ? item?.productLevelPromotionDetails?.adjustedUnitPrice.toString().split('.')[0].replace('$','') : item.basePrice.formattedValue.toString().split('.')[0].replace('$','') }}</span>
                <sup class="t-price-currenyFractionTile ml-1 product-tile-currency" *ngIf="item?.product?.baseOptions?.length">
                  {{item?.productLevelPromotionDetails?.status === 'Applied' ? item?.productLevelPromotionDetails?.adjustedUnitPrice.toString().split('.')[1] : item.basePrice.formattedValue.toString().split('.')[1] }}
                </sup>
              </p>
            </span>
          </div>
          <div class="mr-4 pr-4 mr-md-4 pr-md-0">
            <p class="t-label-s m-0 pb-1">{{ "account.order.qty" | cxTranslate }}</p>

            <p class="t-order-price">{{item?.quantity? item.quantity:''}}</p>
          </div>
          <div class="mr-2">
            <p class="t-label-s m-0 pb-1 pr-0 pr-md-2">{{ "account.order.ext" | cxTranslate }}</p>
            <span class="pr-4 pb-4">
              <p class="m-0 font-weight-bold" [innerHTML]="item?.totalPrice?.formattedValue | formatCurrency"></p>
            </span>
          </div>
        </div>
          <div *ngFor="let consignment of item?.consignmentTracking"  class="d-flex order-list-prices-sectionTwo mt-1">
            <p class="m-0 pl-1 pb-1 pb-md-1 t-body-s cursor-pointer" (click)="handlePOD(consignment)">
              <mat-icon class="mr-1" svgIcon="shipping_enroute"></mat-icon><u class="trackingId p-1">{{consignment?.consignmentTrackingID}}</u>
            </p>
          </div>

      </div>

    </div>
  </div>
