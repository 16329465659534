import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable } from 'rxjs';
import { filter, map, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdvanceEbillDetailsGuard implements CanActivate {
  constructor( private route:Router, private userAccountFacade: UserAccountFacade){ // OOTB spartacus has changes the userService functions to UserAccountFacades in sparatcus 6
    
  }

  canActivate(): Observable<boolean> {
    return this.userAccountFacade.get().pipe(
      filter((data: any) => data && Object.keys(data).length > 0),
      pluck('orgUnit'),
      map((report: any) => {
        const hasSuperBillReport =
        report && report.showSuperBillReport;

        if (!hasSuperBillReport) {
          this.route.navigateByUrl('');
        }
        return hasSuperBillReport;
      })
    );
  }
  
}
